import React, {useMemo, useCallback} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import Table, {migrateTableSettings} from '../../components/Table';
import {useCarChargingStatuses, createCarChargingStatusRequest} from '../../livedata/LiveCarChargingStatus';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ChargingStation} from '../../models/ChargingStation';
import {isReadOnly} from '../../models/Location';
import {getOnlineStatus} from '../../models/OnlineStatus';
import {SmartDeviceConnectionStatus} from '../../models/SmartDevice';
import {setContextLocationId} from '../../redux/actions/location';
import {None} from '../../utils/Arrays';
import {useChargingStations} from '../../utils/FunctionalData';
import {useAppSelector, useThrottled} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardChargingStationGroup, useCardColumnSettings, useUser} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {CardView, cardViewProps} from '../components/CardView';

import ChargingStationParentButtonLink from './ChargingStationParentButtonLink';
import {getTableColumns, defaultTableSettings, rowKey, IChargingStationWithLiveData} from './Columns';

type IChargingStationsSettings = ICardSettingsWithTable;

const ChargingStations = (props: ICardProps<IChargingStationsSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const {api, store} = useAppContext();
  const me = useUser();
  const modals = useModals();
  const [demoMode, currentLocationId] = useAppSelector(state => [
    state.preferences.demoMode,
    state.preferences.locationId
  ]);

  const chargingStationGroup = useCardChargingStationGroup(settings);
  const locationId = chargingStationGroup && chargingStationGroup.id;
  const [chargingStations, refresh] = useChargingStations(fetch, locationId, demoMode);
  const readOnly = isReadOnly(me, chargingStationGroup);

  const handleRefresh = () => refresh(true);

  const connectionStatuses = useMemo(
    () =>
      chargingStations.map(station => {
        const channels = station.getControllers();
        const result: SmartDeviceConnectionStatus[] = [];
        channels.forEach(channel => {
          const index = (channel.position || 1) - 1;
          while (result.length <= index) {
            result.push(SmartDeviceConnectionStatus.Unknown);
          }
          result[index] = channel.smartDevice!.carCharger!.connectionStatus;
        });
        return result;
      }),
    [chargingStations]
  );

  const chargingStationGroupUuid = chargingStationGroup && chargingStationGroup.uuid;
  const statusRequests = useMemo(() => {
    if (!chargingStationGroupUuid) return [];

    return chargingStations.map(station => createCarChargingStatusRequest(chargingStationGroupUuid, station));
  }, [chargingStations, chargingStationGroupUuid]);

  const allStatusesRaw = useCarChargingStatuses(statusRequests, true, true);
  const allStatuses = useThrottled(allStatusesRaw, 500); // reduce frequency of redraws

  const chargingStationsWithLiveData: IChargingStationWithLiveData[] = useMemo(
    () =>
      chargingStations.map((station, index) => {
        const id = station.data.trackingSerialNumber || station.data.serialNumber;
        const state = allStatuses.getState(id);
        return {
          station,
          status: state ? state.state : None,
          power: state && state.power,
          perSidePower: (state && state.perSidePower) || None,
          onlineStatus:
            station.data.trackingSerialNumber === undefined && station.data.trackingSerialNumbers === undefined
              ? undefined
              : getOnlineStatus(
                  state && (state.power || state.perSidePower?.[0]),
                  state ? state.offline : false,
                  state === undefined
                ),
          connectionStatus: connectionStatuses[index]
        };
      }),
    [chargingStations, connectionStatuses, allStatuses]
  );

  const chargingStationColumns = useMemo(() => {
    const setAvailable = async (item: ChargingStation, available: boolean) => {
      const confirmed = await modals.show<ConfirmationResult>(props => (
        <ConfirmationPromiseModal
          title={T(
            available ? 'chargingStationConfiguration.enable.title' : 'chargingStationConfiguration.disable.title'
          )}
          message={T(
            available ? 'chargingStationConfiguration.enable.message' : 'chargingStationConfiguration.disable.message'
          )}
          acceptLabel={T(
            available ? 'chargingStationConfiguration.enable.action' : 'chargingStationConfiguration.disable.action'
          )}
          rejectLabel={T('card.cancel')}
          {...props}
        />
      ));
      if (confirmed !== ConfirmationResult.Accept) return;

      await api.chargingStations.update(
        item.data.serviceLocation && item.data.serviceLocation.id,
        item.data.serialNumber,
        {available}
      );
      NotificationManager.success(
        available ? T('chargingStationConfiguration.enable.success') : T('chargingStationConfiguration.disable.success')
      );
    };

    const handleClickedEnable = (item: ChargingStation) => setAvailable(item, true);
    const handleClickedDisable = (item: ChargingStation) => setAvailable(item, false);
    return getTableColumns(currentLocationId, readOnly, handleClickedEnable, handleClickedDisable);
  }, [api, modals, readOnly, currentLocationId]);

  const handleClickedRow = useCallback(
    (station: IChargingStationWithLiveData) => {
      const locationId = station.station.serviceLocationId;
      if (locationId === undefined) return;

      setContextLocationId(store, api, locationId);
    },
    [store, api]
  );

  const customSettings = useCardColumnSettings(chargingStationColumns);

  const titleAddendum = <ChargingStationParentButtonLink location={chargingStationGroup} />;

  return (
    <CardView
      customSettings={customSettings}
      titleAddendum={titleAddendum}
      actions={() => (
        <CardActions>
          <Reload onReload={handleRefresh} />
        </CardActions>
      )}
      {...cardViewProps(props)}
    >
      <Table
        style={{flexGrow: 1}}
        items={chargingStationsWithLiveData}
        fields={chargingStationColumns}
        rowKey={rowKey}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        onClickedRow={handleClickedRow}
        noun="chargingStation"
        emptyMessage={T('card.error.noChargingStationsAvailable')}
      />
    </CardView>
  );
};

const CARD: ICardType<IChargingStationsSettings> = {
  title: 'chargingStations.title',
  description: 'chargingStations.description',
  categories: [CardCategory.EV, CardCategory.CONFIGURATION],
  rights: UserRights.User,
  type: CardTypeKey.ChargingStations,
  cardClass: ChargingStations,
  width: 4,
  height: 2,
  locationAware: CardLocationAwareness.Required,
  defaultSettings: {
    table: defaultTableSettings
  },
  upgradeSettings: migrateTableSettings('table', defaultTableSettings)
};
export default CARD;
