import React from 'react';

import {CardOrganizationInput, useCardOrganizations} from '../../components/inputs/OrganizationInput';
import {IPersistedTableSettings, SortOrder} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType, ICardWithOrganization} from '../../models/CardSettings';
import {ILocationSummary, locationToSummary} from '../../models/Location';
import {ITableField} from '../../models/Table';
import {hasPartnerFunctionality} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useCardLoader} from '../../utils/Hooks';
import {plural} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {CardActions} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {cardViewProps, ICardState} from '../components/CardView';
import {LocationsListCard} from '../Locations/LocationListCard';
import {BaseLocationsSettings} from '../Locations/LocationListCard';

type IChargingSquaresSettings = BaseLocationsSettings & ICardWithOrganization;

const ChargingSquares = (props: ICardProps<IChargingSquaresSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const [inputOrganizations, updateOrganizationInputQuery, organization] = useCardOrganizations(fetch, settings);

  const [chargingSquares, refreshSquares] = useCardLoader<ILocationSummary[]>(
    api => {
      if (organization === undefined) return Promise.resolve(None);

      return api.organizations.getCPOChargingSquares(organization.id).then(squares => squares.map(locationToSummary));
    },
    [organization?.id],
    plural('location'),
    None
  );

  const refresh = refreshSquares;

  const actions = (defaultActions: React.ReactNode, columns: ITableField<ILocationSummary>[], state: ICardState) => (
    <CardActions>
      <CardOrganizationInput
        inputOrganizations={inputOrganizations}
        organization={organization}
        updateSettings={updateSettings}
        updateOrganizationInputQuery={updateOrganizationInputQuery}
      />
      <Reload onReload={refresh} />
      {state.ready && <ExportCsv fields={columns} settings={settings.table} items={chargingSquares} />}
    </CardActions>
  );

  return (
    <LocationsListCard
      operator={organization}
      locations={chargingSquares}
      actions={actions}
      refresh={refresh}
      updateSettings={updateSettings}
      {...cardViewProps(props)}
    />
  );
};

const defaultTableSettings: IPersistedTableSettings = {
  pageSize: 20,
  sortColumn: 'from',
  sortOrder: SortOrder.DESCENDING,
  columns: [
    {name: 'stationSerial', visible: false},
    {name: 'stationName', visible: true},
    {name: 'from', visible: true},
    {name: 'to', visible: true},
    {name: 'duration', visible: true},
    {name: 'energy', visible: true},
    {name: 'paymentType', visible: true},
    {name: 'hostCost', visible: true},
    {name: 'commissionFee', visible: true},
    {name: 'side', visible: true},
    {name: 'rfid', visible: true}
  ]
};

const CARD: ICardType<IChargingSquaresSettings> = {
  title: 'cpoChargingSquares.title',
  description: 'cpoChargingSquares.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  type: CardTypeKey.CPOChargingSquares,
  isAvailable: hasPartnerFunctionality,
  cardClass: ChargingSquares,
  width: 4,
  height: 2,
  locationAware: CardLocationAwareness.Unaware,
  defaultSettings: {
    cardType: CardDisplayType.Table,
    table: defaultTableSettings,
    zoomLevel: 8,
    center: {lat: 50.822937, lng: 3.311227}
  }
};
export default CARD;
