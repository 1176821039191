import {Button as RsButton} from '../../../components/bootstrap';
import {T} from '../../../utils/Internationalization';

interface AvailabilityProps {
  isAvailable: boolean;
  isReadOnly: boolean;
  handleAvailableChanged: (available: boolean) => void;
}
export const AvailabilityField = (props: AvailabilityProps) => {
  return (
    <div style={{display: 'inline-block'}}>
      <div
        style={{
          display: 'inline-block',
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem'
        }}
      >
        {props.isAvailable
          ? T('chargingStationConfiguration.available')
          : T('chargingStationConfiguration.unavailable')}
      </div>
      {!props.isReadOnly && (
        <RsButton
          size="sm"
          style={{position: 'relative', bottom: 2}}
          onClick={() => props.handleAvailableChanged(!props.isAvailable)}
        >
          {props.isAvailable ? T('chargingStationConfiguration.disable') : T('chargingStationConfiguration.enable')}
        </RsButton>
      )}
    </div>
  );
};
