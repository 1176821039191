import dayjs from 'dayjs';

import {Interval} from '../models/UsageValue';

import {listingOf, quantity} from './Internationalization';

export function roundDown(timestamp: number, interval: Interval) {
  switch (interval) {
    case Interval.MINUTES_5:
      return timestamp - (timestamp % (5 * 60 * 1000));
    case Interval.X_MINUTES_10:
      return timestamp - (timestamp % (10 * 60 * 1000));
    case Interval.X_MINUTES_15:
      return timestamp - (timestamp % (15 * 60 * 1000));
    case Interval.X_MINUTES_20:
      return timestamp - (timestamp % (20 * 60 * 1000));
    case Interval.X_MINUTES_30:
      return timestamp - (timestamp % (30 * 60 * 1000));
    case Interval.HOUR:
      return dayjs(timestamp).startOf('hour').valueOf();
    case Interval.DAY:
      return dayjs(timestamp).startOf('day').valueOf();
    case Interval.MONTH:
      return dayjs(timestamp).startOf('month').valueOf();
    case Interval.YEAR:
      return dayjs(timestamp).startOf('year').valueOf();
    default:
      return timestamp;
  }
}

export function getStartOfYearTimestamp(timestamp: number, timezone: string) {
  return dayjs.tz(timestamp, timezone).startOf('year').valueOf();
}

export function getStartOfMonthTimestamp(timestamp: number, timezone: string) {
  return dayjs.tz(timestamp, timezone).startOf('month').valueOf();
}

export function getYearIndex(timestamp: number, timezone: string) {
  const ts = dayjs.tz(timestamp, timezone);
  return ts.year();
}

export function getMonthIndex(timestamp: number, timezone: string) {
  const ts = dayjs.tz(timestamp, timezone);
  return ts.year() * 12 + ts.month();
}

export function formatMonth(timestamp: number, timezone?: string) {
  return dayjs.tz(timestamp, timezone).format("MMM 'YY");
}

export function formatMonthIndexLong(fromMonthIndex: number, index: number) {
  const monthIndex = fromMonthIndex + index;
  const year = monthIndex / 12;
  const month = monthIndex % 12;
  const timestamp = dayjs(new Date(year, month, 1));
  return timestamp.format('MMMM YYYY');
}

export function formatWeek(timestamp: number, timezone: string) {
  const time = dayjs.tz(timestamp, timezone);
  const from = time.startOf('week').format('DD MMM');
  const to = time.endOf('week').format('DD MMM');
  return `${from} - ${to}`;
}

export function formatDay(timestamp: number, timezone: string) {
  const time = dayjs.tz(timestamp, timezone);
  return time.format('LL');
}

export function formatDayMinutes(timestamp: number, timezone: string) {
  const time = dayjs.tz(timestamp, timezone);
  return time.format('LLLL');
}

export function formatUnixTime(timestamp: number, timezone: string) {
  const time = dayjs.tz(timestamp, timezone);
  return time.format('D MMMM YYYY HH:mm');
}

export function formatDuration(seconds: number) {
  let result: string[] = [];
  if (seconds > 3600) result.push(quantity('hour', Math.floor(seconds / 3600)));
  const minutes = Math.floor(seconds / 60) % 60;
  if (minutes > 0) result.push(quantity('minute', minutes));
  return listingOf(result);
}

export function getDurationMillisToHoursStr(milliseconds: number): string {
  const totalMinutes = Math.round(milliseconds / 60000);
  const minutes = totalMinutes % 60;
  const hours = (totalMinutes / 60) | 0;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

declare global {
  interface Window {
    timezoneOffsetCalls: number;
    timezoneOffsetCacheHits: number;
  }
}
window.timezoneOffsetCalls = 0;
window.timezoneOffsetCacheHits = 0;

interface TimezoneOffsetCache {
  lastDay: string;
  lastOffset: number;
}
const offsetCache = new Map<string, TimezoneOffsetCache>();
export function getTimezoneOffset(timeZone: string, timestamp: number) {
  // NOTE: this cache assumes that DST switches at midnight
  // an incorrect assumption, but should be close enough for our purposes
  const day = dayjs(timestamp).format('DD/MM/YYYY');
  const cached = offsetCache.get(timeZone);
  if (cached && cached.lastDay === day) {
    window.timezoneOffsetCacheHits++;
    return cached.lastOffset;
  }

  window.timezoneOffsetCalls++;
  const date = new Date(timestamp);
  const target = date.toLocaleString('en-US', {timeZone});
  const offset = date.getTimezoneOffset() + Math.round((date.valueOf() - new Date(target).valueOf()) / 1000 / 60);
  offsetCache.set(timeZone, {lastDay: day, lastOffset: offset});
  return offset;
}

const INTERVAL_OFFLINE_MINUTES = 30;
export function isLastIntervalAvailable(nowUtc: dayjs.Dayjs, lastInterval?: number) {
  return lastInterval !== undefined && nowUtc.diff(dayjs.utc(lastInterval!), 'minutes') <= INTERVAL_OFFLINE_MINUTES;
}

export function getBrowserTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
