import React from 'react';

import {getLanguageCodes, Language, T} from '../../utils/Internationalization';
import {TranslationKey} from '../../utils/TranslationTerms';

import {SelectInputGroup} from './SelectInput';

interface SelectLanguageInputGroupProps {
  name: string;
  label: string;
  value: Language;
  onChange: (language: Language) => void;
  disabled?: boolean;
}

export default function SelectLanguageInputGroup(props: SelectLanguageInputGroupProps) {
  const {name, label, value, onChange, disabled} = props;

  return (
    <SelectInputGroup
      name={name}
      label={label}
      value={value}
      onChange={value => onChange(value as Language)}
      disabled={disabled}
    >
      {getLanguageCodes().map(code => (
        <option key={code} value={code}>
          {T(`language.${code}` as TranslationKey)}
        </option>
      ))}
    </SelectInputGroup>
  );
}
