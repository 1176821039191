import React, {useState} from 'react';

import {isAPIResponse} from '../../api/APIClient';
import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {Contract} from '../../models/Contract';
import {ServerErrorCode, translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';

interface ActivateSplitBillingModalProps extends IPromiseModalProps<boolean> {
  organizationId: number;
  instantAvailable: boolean;
}

export function ActivateSplitBillingModal(props: ActivateSplitBillingModalProps) {
  const {organizationId, instantAvailable} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const [error, setError] = useState<string>();

  const handleClick = () => {
    setError(undefined);
    if (instantAvailable) {
      return api.organizations
        .activate(organizationId, Contract.SplitBilling)
        .then(() => resolve(true))
        .catch((err: unknown) => {
          if (isAPIResponse(err)) {
            if (err.code === ServerErrorCode.BillingInfoMissing || err.code === ServerErrorCode.SepaMissing) {
              window.open(`/register-split-billing?organizationId=${organizationId}`);
              resolve(false);
            } else {
              setError(translateError(err, T('splitBilling.activate.failed')));
            }
          }
        });
    } else {
      window.open(`/register-split-billing?organizationId=${organizationId}`);
      resolve(false);
      return Promise.resolve();
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('splitBilling.activate.title')}
      action={handleClick}
      error={error}
      actionText={T('splitBilling.activate.action')}
    >
      <p style={{marginTop: '1rem'}}>
        {T(instantAvailable ? 'splitBilling.activate.instant' : 'splitBilling.activate.message')}
      </p>
    </SingleActionModal>
  );
}
