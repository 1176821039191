export const None: never[] = [];

export function replace<T>(array: T[], condition: (element: T) => boolean, element: T) {
  return array.map(e => (condition(e) ? element : e));
}

export function areArraysEqual<T>(a: T[], b: T[]) {
  if (a === b) return true;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;

  return true;
}

export function redim<T>(value: T[], length: number, defaultValue: () => T) {
  if (value.length === length) return [...value];
  else if (length < value.length) return value.slice(0, length);

  const result = [...value];
  while (result.length < length) result.push(defaultValue());
  return result;
}

export function mergeSortedArrays<T>(a: T[], b: T[]): T[] {
  const result: T[] = [];
  let ai = 0;
  let bi = 0;
  while (ai < a.length && bi < b.length) {
    if (a[ai] === b[bi]) {
      result.push(a[ai]);
      ai++;
      bi++;
    } else if (a[ai] < b[bi]) {
      result.push(a[ai++]);
    } else {
      result.push(b[bi++]);
    }
  }
  while (ai < a.length) result.push(a[ai++]);
  while (bi < b.length) result.push(b[bi++]);
  return result;
}

export function withoutUndefined<T>(values: (T | undefined)[]): T[] {
  return values.filter(x => x !== undefined) as T[];
}
