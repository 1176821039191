import React, {useContext, useMemo} from 'react';

import {DummyFormState, FormState, useFormState} from './FormState';

export const FormContext = React.createContext<FormState | undefined>(undefined);
export const FormContextProvider = FormContext.Provider;
export const FormContextConsumer = FormContext.Consumer;

export function useFormContext() {
  const value = useContext<FormState | undefined>(FormContext);
  if (value === undefined) {
    console.error('Attempting to use form context outside form!');
  }
  return value || DummyFormState;
}

export const FormProvider = (props: {children: React.ReactNode}) => {
  const {children} = props;
  const form = useFormState();
  return <FormContextProvider value={form}>{children}</FormContextProvider>;
};

class FormInput {
  private form: FormState;
  private name: string;

  constructor(form: FormState, name: string) {
    this.form = form;
    this.name = name;
  }

  public get shownError() {
    return this.form.getShownError(this.name);
  }

  public set error(value: string | undefined) {
    this.form.setError(this.name, value);
  }

  public onEnter() {
    if (this.form.hasErrors()) {
      this.form.setErrorVisible(this.name, this.form.getError(this.name) !== undefined);
      return false;
    } else {
      return true;
    }
  }
}

export function useFormInputState(name: string) {
  const form = useFormContext();
  return useMemo(() => new FormInput(form, name), [form, name]);
}
