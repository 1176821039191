export type SeriesElement = [number, number | null] | [number, number | null, number | null];

export function sumOfSeries(series: SeriesElement[][]): SeriesElement[] {
  const placeholder = new Map<number, number>();
  for (let values of series) {
    if (Array.isArray(values)) {
      for (let [timestamp, value] of values) {
        if (value === null) continue;

        const existing = placeholder.get(timestamp);
        if (existing !== undefined) value += existing;

        placeholder.set(timestamp, value);
      }
    }
  }

  return [...placeholder];
}

interface ISeriesOptions {
  min?: number;
  minUpperLimit?: number; // min value should not go higher than this value
  max?: number;
}
export class Series {
  static NO_DATA = new Series([]);

  static from(data: SeriesElement[], options: ISeriesOptions = {}) {
    // remove empty data at the tail - it confuses HighStock and results in empty graphs being displayed
    let tail = data.length;
    while (tail > 2 && data[tail - 1][1] === null) {
      // always leave at least 2 elements
      tail--;
    }
    if (tail < data.length) data = data.slice(0, tail);

    if (options.min && options.minUpperLimit !== undefined) {
      options.min = Math.min(options.min, options.minUpperLimit);
    }

    return new Series(data);
  }

  data: SeriesElement[];

  constructor(data: SeriesElement[]) {
    this.data = data;
  }
}
