import numeral from 'numeral';

const Symbols: {[key: string]: string} = {
  ALL: 'Lek',
  AFN: '؋',
  ARS: '$',
  AWG: 'ƒ',
  AUD: '$',
  AZN: '₼',
  BSD: '$',
  BBD: '$',
  BYN: 'Br',
  BZD: 'BZ$',
  BMD: '$',
  BOB: '$b',
  BAM: 'KM',
  BWP: 'P',
  BGN: 'лв',
  BRL: 'R$',
  BND: '$',
  KHR: '៛',
  CAD: '$',
  KYD: '$',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  HRK: 'kn',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  XCD: '$',
  EGP: '£',
  SVC: '$',
  EUR: '€',
  FKP: '£',
  FJD: '$',
  GHS: '¢',
  GIP: '£',
  GTQ: 'Q',
  GGP: '£',
  GYD: '$',
  HNL: 'L',
  HKD: '$',
  HUF: 'Ft',
  ISK: 'kr',
  INR: '',
  IDR: 'Rp',
  IRR: '﷼',
  IMP: '£',
  ILS: '₪',
  JMD: 'J$',
  JPY: '¥',
  JEP: '£',
  KZT: 'лв',
  KPW: '₩',
  KRW: '₩',
  KGS: 'лв',
  LAK: '₭',
  LBP: '£',
  LRD: '$',
  MKD: 'ден',
  MYR: 'RM',
  MUR: '₨',
  MXN: '$',
  MNT: '₮',
  MZN: 'MT',
  NAD: '$',
  NPR: '₨',
  ANG: 'ƒ',
  NZD: '$',
  NIO: 'C$',
  NGN: '₦',
  NOK: 'kr',
  OMR: '﷼',
  PKR: '₨',
  PAB: 'B/.',
  PYG: 'Gs',
  PEN: 'S/.',
  PHP: '₱',
  PLN: 'zł',
  QAR: '﷼',
  RON: 'lei',
  RUB: '₽',
  SHP: '£',
  SAR: '﷼',
  RSD: 'Дин.',
  SCR: '₨',
  SGD: '$',
  SBD: '$',
  SOS: 'S',
  ZAR: 'R',
  LKR: '₨',
  SEK: 'kr',
  CHF: 'CHF',
  SRD: '$',
  SYP: '£',
  TWD: 'NT$',
  THB: '฿',
  TTD: 'TT$',
  TRY: '',
  TVD: '$',
  UAH: '₴',
  GBP: '£',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  YER: '﷼',
  ZWD: 'Z$'
};
export function getCurrencySymbol(currency: string) {
  return Symbols[currency] || currency;
}

const Digits: {[key: string]: number} = {
  BYN: 0,
  BYR: 0,
  BIF: 0,
  XPF: 0,
  CLP: 0,
  KMF: 0,
  BHD: 3,
  DKF: 0,
  HUF: 0,
  ISK: 0,
  IQD: 3,
  JPY: 0,
  JOD: 3,
  KWD: 3,
  LYD: 3,
  MGA: 0,
  MZN: 0,
  PYG: 0,
  OMR: 3,
  RWF: 0,
  KRW: 0,
  TND: 3,
  VUV: 0
};
export function getCurrencyDigits(currency: string): number {
  const result = Digits[currency];
  return result === undefined ? 2 : result;
}

export function formatCurrency(currency: string, amount: number) {
  const decimals = getCurrencyDigits(currency);
  return `${numeral(amount).format(`0.${'0'.repeat(decimals)}`)} ${getCurrencySymbol(currency)}`;
}

export function formatCurrencyNumber(currency: string, amount: number) {
  const decimals = getCurrencyDigits(currency);
  return numeral(amount).format(`0.${'0'.repeat(decimals)}`);
}
