import {Contract, translateContract} from '../../../models/Contract';
import {T} from '../../../utils/Internationalization';

interface EditOrganizationContractsTabProps {
  contracts: Contract[];
}

export default function EditOrganizationContractsTab(props: EditOrganizationContractsTabProps) {
  const {contracts} = props;

  return (
    <div style={{marginTop: '0.5rem'}}>
      {contracts.length === 0 && <p>{T('organizations.contracts.noContracts')}</p>}
      {contracts.length > 0 && (
        <>
          <p>{T('organizations.contracts.activeContracts')}</p>
          <ul className="tw-list-disc tw-ml-[1.25rem]">
            {contracts.map(contract => (
              <li>{translateContract(contract)}</li>
            ))}
          </ul>
          <source />
        </>
      )}
    </div>
  );
}
