import {ButtonGroup} from 'reactstrap';

import {OptimizationStrategy} from '../../../api/LoadManagementAPI';
import {Button as RsButton} from '../../../components/bootstrap';
import {T} from '../../../utils/Internationalization';

interface SmartStrategyProps {
  isReadOnly: boolean;
  smartMode: OptimizationStrategy;
  isUltra: boolean;
  setSmartChargingStrategy: (strategy: OptimizationStrategy) => void;
}

export const SmartStrategyField = (props: SmartStrategyProps) => {
  return (
    <div style={{display: 'inline-block'}}>
      {props.isReadOnly ? (
        <div
          style={{
            display: 'inline-block',
            paddingTop: '0.25rem',
            paddingRight: '0.5rem',
            paddingBottom: '0.25rem'
          }}
        >
          {props.smartMode === OptimizationStrategy.SchedulesThenExcess &&
            T('chargingStationConfiguration.property.smartStrategy.schedulesThenExcess')}
          {props.smartMode === OptimizationStrategy.SchedulesOnly &&
            T('chargingStationConfiguration.property.smartStrategy.schedulesOnly')}
        </div>
      ) : (
        <ButtonGroup>
          {!props.isUltra && (
            <RsButton
              size="sm"
              onClick={() => props.setSmartChargingStrategy(OptimizationStrategy.SchedulesThenExcess)}
              active={props.smartMode === OptimizationStrategy.SchedulesThenExcess}
            >
              {T('chargingStationConfiguration.property.smartStrategy.schedulesThenExcess')}
            </RsButton>
          )}
          <RsButton
            size="sm"
            onClick={() => props.setSmartChargingStrategy(OptimizationStrategy.SchedulesOnly)}
            active={props.smartMode === OptimizationStrategy.SchedulesOnly}
          >
            {T('chargingStationConfiguration.property.smartStrategy.schedulesOnly')}
          </RsButton>
        </ButtonGroup>
      )}
    </div>
  );
};
