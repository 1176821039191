import dayjs from 'dayjs';

import {SingleActionModal} from '../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {translateContract} from '../../models/Contract';
import {IUser} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';

interface EditUserContractsProps extends IPromiseModalProps<void> {
  user: IUser;
}

export function EditUserContracts(props: EditUserContractsProps) {
  const {user} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  const [details] = useLoader(api => api.user.getDetails(user.id), [user.id]);
  const contracts = details?.contracts || None;
  const cards = details?.cards || None;

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={resolve}
      title={T('users.edit.contracts.title')}
      action={() => Promise.resolve()}
    >
      {contracts.length > 0 ? (
        <>
          <p style={{marginTop: '1rem'}}>{T('users.edit.contracts.contracts')}:</p>
          <ul>
            {contracts.map(contract => (
              <li>
                {dayjs(contract.signedAt).format('DD-MM-YYYY')}: {translateContract(contract.contract)}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p style={{marginTop: '1rem'}}>{T('users.edit.contracts.noContracts')}</p>
      )}
      {cards.length > 0 ? (
        <>
          <p>{T('users.edit.contracts.cards')}:</p>
          <ul>
            {cards.map(card => (
              <li>{card.value}</li>
            ))}
          </ul>
        </>
      ) : (
        <p>{T('users.edit.contracts.noCards')}</p>
      )}
    </SingleActionModal>
  );
}
