import React, {useState, useEffect, useMemo} from 'react';

import {useAppContext} from '../../app/context';
import {Input} from '../../components/bootstrap';
import {Button} from '../../components/ui/button';
import {Filter} from '../../components/ui-lib/icons/medium';
import {UserRights} from '../../models/AuthUser';
import {ILocationSummary, LocationFunctionType} from '../../models/Location';
import {IOrganization, ISearchField} from '../../models/Organization';
import {ITableField} from '../../models/Table';
import {None} from '../../utils/Arrays';
import {useCardLoader} from '../../utils/Hooks';
import {T, plural} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useUser} from '../CardUtils';
import {ExportCsv, Reload} from '../components/actions';
import {CardActions, Spring} from '../components/CardActions';

import {cardViewProps, ICardState} from '../components/CardView';

import {LocationFilter, ActiveInactiveFilter, FilterFields} from './LocationFilter';

import {BaseLocationsSettings, DEFAULT_BASE_LOCATION_SETTINGS, LocationsListCard} from './LocationListCard';

type LocationsSettings = BaseLocationsSettings;

const Locations = (props: ICardProps<LocationsSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {api} = useAppContext();

  const [allLocations, refresh] = useCardLoader(
    (api, force) => api.user.getLocations(force),
    [],
    plural('location'),
    None
  );
  const [queriedLocations, setQueriedLocations] = useState(allLocations);

  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState(ActiveInactiveFilter.All);
  const [filterPanelOpened, setFilterPanelOpened] = useState(false);
  const [fields, setFields] = useState<FilterFields>({organizations: [], fields: []});

  useEffect(() => setQueriedLocations(allLocations), [allLocations]);

  const me = useUser();

  const filteredLocations = useMemo(() => {
    let locations = queriedLocations.filter(location => location.functionType !== LocationFunctionType.ChargingStation);
    if (filter === ActiveInactiveFilter.Active) {
      locations = locations.filter(
        location =>
          location.obsoleteTimestamp === undefined && !location.historicDevice && location.serialNumber !== undefined
      );
    } else if (filter === ActiveInactiveFilter.Inactive) {
      locations = locations.filter(
        location =>
          (location.obsoleteTimestamp === undefined && location.historicDevice) || location.serialNumber === undefined
      );
    } else if (filter === ActiveInactiveFilter.Deleted) {
      locations = locations.filter(location => location.obsoleteTimestamp !== undefined);
    }

    if (!query) return locations;

    const queryLower = query.toLowerCase();
    return locations.filter(
      location =>
        (location.name && location.name.toLowerCase().includes(queryLower)) ||
        (location.serialNumber && location.serialNumber.includes(queryLower))
    );
  }, [query, filter, queriedLocations]);

  useEffect(() => {
    fetch('organizations', api => api.getUserOrganizations(me.userId), plural('organization')).then(organizations => {
      const fieldFetchers = organizations.map(organization => api.getOrganizationSearchFields(organization.id));
      Promise.all(fieldFetchers).then(fields => {
        const result: ISearchField[] = [];
        fields.forEach(fields => {
          if (fields === undefined) return;

          result.push(...fields);
        });
        setFields({
          organizations,
          fields: result
        });
      });
    });
  }, [me, fetch, api]);

  const handleChangedSearch = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
  };

  const handleClickedFilter = () => {
    setFilterPanelOpened(!filterPanelOpened);
  };

  const handleApplyFilter = (
    activeInactive: ActiveInactiveFilter,
    fieldFilters: string[],
    organization: IOrganization | undefined
  ) => {
    setFilterPanelOpened(false);

    if (organization !== undefined) {
      fetch('filter', api => api.organizations.getLocations(organization.id), plural('location')).then(locations => {
        setQueriedLocations(locations);
        setFilter(activeInactive);
      });
      return;
    }

    const fieldQuery: {[key: string]: string} = {};
    let count = 0;
    for (let i = 0; i < fields.fields.length; i++) {
      if (fieldFilters[i] === '') continue;

      fieldQuery[fields.fields[i].name] = fieldFilters[i];
      count++;
    }

    if (count === 0) {
      setFilter(activeInactive);
      setQueriedLocations(allLocations);
    } else {
      fetch('filter', api => api.searchLocationsBySearchField(fieldQuery), plural('location')).then(locations => {
        setQueriedLocations(locations);
        setFilter(activeInactive);
      });
    }
  };

  const handleCloseFilter = () => {
    setFilterPanelOpened(false);
  };

  const actions = (defaultActions: React.ReactNode, columns: ITableField<ILocationSummary>[], state: ICardState) => {
    // @todo: replace Filter icon with the finalized one when ready
    return (
      <CardActions justifyContent="end">
        <Reload onReload={refresh} />
        <ExportCsv name={state.title} fields={columns} items={filteredLocations} settings={settings.table} />
        <Input
          name="query"
          type="text"
          autoWidth={true}
          placeholder={T('locations.search')}
          onChange={handleChangedSearch}
          value={query}
          className={testingClasses.search}
          data-testid={testingClasses.search}
        />
        <Button
          variant="ghost"
          title={T('locations.filter')}
          onClick={handleClickedFilter}
          className="!tw-w-6 !tw-h-6 !tw-rounded-none !tw-justify-center !tw-p-0"
        >
          <Filter width={16} height={16} />
        </Button>
        <LocationFilter
          isOpen={filterPanelOpened}
          fields={fields}
          onApplyFilter={handleApplyFilter}
          onClose={handleCloseFilter}
        />
        <Spring />
        {defaultActions}
      </CardActions>
    );
  };

  return (
    <LocationsListCard
      locations={filteredLocations}
      actions={actions}
      refresh={refresh}
      updateSettings={updateSettings}
      {...cardViewProps(props)}
    />
  );
};

const CARD: ICardType<LocationsSettings> = {
  type: CardTypeKey.Locations,
  title: 'locations.title',
  description: 'locations.description',
  categories: [CardCategory.LOCATIONS],
  rights: UserRights.User,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_BASE_LOCATION_SETTINGS,
  locationAware: CardLocationAwareness.Aware,
  cardClass: Locations
};
export default CARD;
