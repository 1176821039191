export const enum BatteryManufacturerStatus {
  Available = 'AVAILABLE',
  ComingSoon = 'COMING_SOON',
  Deprecated = 'DEPRECATED',
  Support = 'SUPPORT'
}

export interface IBatteryManufacturer {
  id: number;
  name: string;
  status: BatteryManufacturerStatus;
  deviceTypes: IBatteryDeviceType[];
}

export interface IBatteryDeviceType {
  id: number;
  name: string;
}
