import {T} from '../utils/Internationalization';
import {TranslationKey} from '../utils/TranslationTerms';

import {ILoad, ILoadUpdateChannel} from './Load';

export const enum SmartDeviceConnectionStatus {
  Initial = 'INITIAL',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Unreachable = 'UNREACHABLE',
  Error = 'ERROR',
  Unknown = 'UKNOWN'
}

export function getSmartDeviceConnectionStatusLabel(status: SmartDeviceConnectionStatus, canBeInitializing: boolean) {
  switch (status) {
    case SmartDeviceConnectionStatus.Initial:
      if (canBeInitializing) return T('deviceConnectionStatus.initializing');
      else return '';
    case SmartDeviceConnectionStatus.Connected:
      return T('deviceConnectionStatus.connected');
    case SmartDeviceConnectionStatus.Disconnected:
      return T('deviceConnectionStatus.disconnected');
    case SmartDeviceConnectionStatus.Unreachable:
      return T('deviceConnectionStatus.unreachable');
    case SmartDeviceConnectionStatus.Unknown:
      return T('deviceConnectionStatus.unknown');
    default:
      return status;
  }
}

export interface ISmartDeviceAction {
  name: string;
  displayName: string;
  description: string;
  parameterSpecs: IConfigurationPropertySpec[];
}

export interface IBaseSmartDevice {
  id: string;
  name: string;
  favourite: boolean;
  configurationProperties: IConfigurationProperty[];
  type: ISmartDeviceType;
}

export interface ISmartDevice extends IBaseSmartDevice {
  uuid: string;
  connectionStatus: SmartDeviceConnectionStatus;
  connectionStatusUpdateChannel: {protocol: string; name: string};
  type: ISmartDeviceType;
  properties: IConfigurationProperty[];
  serialNumber: string;
}

export interface ICapacityProtectionConfiguration {
  active?: boolean;
  capacityMaximumPower?: number;
  capacitySuggestedPower?: number;
}

export interface ISmartDeviceType {
  category: SmartDeviceTypeCategory;
  name: string;
  displayName: string;
  logoURL: string;
  protocol: string;
  configurationProperties: IConfigurationPropertySpec[];
  actions: ISmartDeviceAction[];
}

export const enum SmartDeviceTypeCategory {
  SelectOne = '',

  CarCharger = 'CARCHARGER',
  Battery = 'BATTERY',
  Thermostat = 'THERMOSTAT',
  Lights = 'LIGHTS',
  Custom = 'CUSTOM',
  Led = 'LED',
  ChargingStation = 'CHARGINGSTATION'
}

export const SmartDeviceTypeCategories = [
  SmartDeviceTypeCategory.CarCharger,
  SmartDeviceTypeCategory.Battery,
  SmartDeviceTypeCategory.Thermostat,
  SmartDeviceTypeCategory.Lights,
  SmartDeviceTypeCategory.Custom
];
const categoryTranslationKeys: {[key: string]: TranslationKey} = {
  [SmartDeviceTypeCategory.CarCharger]: 'smartDeviceType.carCharger',
  [SmartDeviceTypeCategory.Battery]: 'smartDeviceType.battery',
  [SmartDeviceTypeCategory.Thermostat]: 'smartDeviceType.thermostat',
  [SmartDeviceTypeCategory.Lights]: 'smartDeviceType.lights',
  [SmartDeviceTypeCategory.Custom]: 'smartDeviceType.custom'
};
export function translateSmartDeviceTypeCategory(category: SmartDeviceTypeCategory): string {
  const key = categoryTranslationKeys[category];
  return key ? T(key) : category;
}

export interface IConfigurationProperty {
  values: IConfigurationPropertyValue[];
  spec: IConfigurationPropertySpec;
}

export interface IPostConfigurationProperty {
  values: IConfigurationPropertyValue[];
  spec: {name: string};
}

export interface IConfigurationPropertyValue {
  String?: string;
  List?: {String: string}[];
  bool?: boolean;
  Boolean?: boolean | null;
  int?: number;
  Integer?: number | null;
  long?: number;
  Long?: number | null;
  IPv4?: string;
  Quantity?: {value: number; unit: string};
  HighLevelMeasurementSpecification?: ILoad;
  BigDecimal?: number | null;
}

export const enum ConfigurationPropertySpecies {
  String = 'String',
  bool = 'bool',
  Boolean = 'Boolean',
  int = 'int',
  Integer = 'Integer',
  long = 'long',
  Long = 'Long',
  IPv4 = 'IPv4',
  Quantity = 'Quantity',
  HighLevelMeasurementSpecification = 'HighLevelMeasurementSpecification',
  BigDecimal = 'BigDecimal',
  Instance = 'Instance',
  Date = 'Date',
  Instant = 'Instant'
}

export interface IConfigurationPropertySpec {
  name: string;
  displayName: string;
  description: string;
  species: ConfigurationPropertySpecies;
  required: boolean;
  group?: IConfigurationPropertyGroup;
  unit?: string;
  possibleValues?: {
    values?: {
      String?: string;
      Integer?: number;
      HighLevelMeasurementSpecification?: ILoad;
    }[];
    range?: {
      from: IConfigurationPropertyValue;
      to: IConfigurationPropertyValue;
    };
    exhaustive: boolean;
  };
  updateChannel?: ILoadUpdateChannel;
}

export interface IConfigurationPropertyGroup {
  name: string;
  displayName: string;
}

function getDefaultPropertyValue(property: IConfigurationProperty): string {
  switch (property.spec.species) {
    case 'String':
      return '';
    case 'bool':
      return 'false';
    case 'Boolean':
      return '';
    case 'int':
      return '0';
    case 'Integer':
      return '';
    case 'long':
      return '0';
    case 'Long':
      return '';
    case 'IPv4':
      return '';
    case 'Quantity':
      return '';
    case 'HighLevelMeasurementSpecification':
      return '';
    case 'BigDecimal':
    case 'Instance':
    case 'Date': // TODO
    default:
      return '';
  }
}

export function getPropertyStringValue(property: IConfigurationProperty): string {
  if (property.values.length === 0) return getDefaultPropertyValue(property);

  switch (property.spec.species) {
    case 'String':
      return property.values[0].String || '';
    case 'bool':
      return property.values[0].bool!.toString();
    case 'Boolean':
      return property.values[0].Boolean!.toString();
    case 'int':
      return property.values[0].int!.toString();
    case 'Integer':
      return property.values[0].Integer!.toString();
    case 'long':
      return property.values[0].long!.toString();
    case 'Long':
      return property.values[0].Long!.toString();
    case 'IPv4':
      return property.values[0].IPv4!.toString();
    case 'Quantity':
      return property.values[0].Quantity!.value.toString();
    case 'HighLevelMeasurementSpecification':
      const value = property.values[0];
      if (value.HighLevelMeasurementSpecification) {
        return value.HighLevelMeasurementSpecification.id.toString();
      } else return '';
    case 'BigDecimal':
    case 'Instance':
    case 'Date': // TODO
    default:
      return '';
  }
}

export function getPropertyValueFromString(
  propertySpec: IConfigurationPropertySpec,
  value: string
): IConfigurationPropertyValue[] | undefined {
  if (!propertySpec.required && value === '') return undefined;

  switch (propertySpec.species) {
    case 'String':
      return [{String: value}];
    case 'bool':
      return [{bool: value === 'true'}];
    case 'Boolean':
      return [{Boolean: value === 'true'}];
    case 'int':
      return [{int: parseInt(value)}];
    case 'Integer':
      return [{Integer: parseInt(value)}];
    case 'long':
      return [{long: parseInt(value)}];
    case 'Long':
      return [{Long: parseInt(value)}];
    case 'IPv4':
      return [{IPv4: value}];
    case 'Quantity': {
      const numberValue = parseFloat(value);
      return [{Quantity: {value: numberValue, unit: propertySpec.unit!}}];
    }
    case 'HighLevelMeasurementSpecification':
      const id = parseInt(value);
      const load = propertySpec.possibleValues!.values!.find(
        value => value.HighLevelMeasurementSpecification!.id === id
      );
      return load ? [load] : undefined;
    case 'BigDecimal':
    case 'Instance':
    case 'Date': // TODO
    default:
      return undefined;
  }
}
