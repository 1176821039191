import {ButtonGroup} from 'reactstrap';

import {OptimizationStrategy} from '../../../api/LoadManagementAPI';
import {Button} from '../../../components/bootstrap';
import {
  ChargingMode,
  ChargingStation,
  ChargingStationFeature,
  SmartChargingMode
} from '../../../models/ChargingStation';
import {T} from '../../../utils/Internationalization';

interface ChargingModeFieldProps {
  isCharging: boolean;
  isReadOnly: boolean;
  station: ChargingStation;
  chargingMode: ChargingMode | undefined;
  smartMode: OptimizationStrategy | undefined;
  setSmartChargingMode: (mode: SmartChargingMode) => void;
  setChargingMode: (mode: ChargingMode) => void;
}

export const ChargingModeField = (props: ChargingModeFieldProps) => {
  const {isCharging, isReadOnly, station, chargingMode, smartMode, setSmartChargingMode, setChargingMode} = props;
  return (
    <div style={{display: 'inline-block'}}>
      {isReadOnly ? (
        <div
          style={{
            display: 'inline-block',
            paddingTop: '0.25rem',
            paddingRight: '0.5rem',
            paddingBottom: '0.25rem'
          }}
        >
          {chargingMode === ChargingMode.Normal && T('chargingStationConfiguration.property.chargingMode.normal')}
          {chargingMode === ChargingMode.Smart && T('chargingStationConfiguration.property.chargingMode.smart')}
          {chargingMode === ChargingMode.Stop && T('chargingStationConfiguration.property.chargingMode.stop')}
          {chargingMode === ChargingMode.Stop && T('chargingStationConfiguration.property.chargingMode.pause')}
        </div>
      ) : (
        <ButtonGroup style={{gap: '0.75rem'}}>
          <Button
            size="sm"
            onClick={() => setSmartChargingMode(SmartChargingMode.Standard)}
            active={chargingMode === ChargingMode.Normal}
          >
            {T('chargingStationConfiguration.property.chargingMode.normal')}
          </Button>
          {station.hasFeature(ChargingStationFeature.SmartCharging) && (
            <>
              <Button
                size="sm"
                onClick={() => setSmartChargingMode(SmartChargingMode.Smart)}
                active={chargingMode === ChargingMode.Smart && smartMode !== OptimizationStrategy.ExcessOnly}
              >
                {T('chargingStationConfiguration.property.chargingMode.smart')}
              </Button>
              <Button
                size="sm"
                onClick={() => setSmartChargingMode(SmartChargingMode.Solar)}
                active={chargingMode === ChargingMode.Smart && smartMode === OptimizationStrategy.ExcessOnly}
              >
                {T('chargingStationConfiguration.property.smartStrategy.excessOnly')}
              </Button>
            </>
          )}
          {station.hasFeature(ChargingStationFeature.StopCharging) && (
            <Button
              size="sm"
              style={{position: 'relative', bottom: 2}}
              onClick={() => setChargingMode(ChargingMode.Stop)}
              active={chargingMode === ChargingMode.Stop}
              disabled={!isCharging}
            >
              {T('chargingStationConfiguration.property.chargingMode.stop')}
            </Button>
          )}
        </ButtonGroup>
      )}
    </div>
  );
};
