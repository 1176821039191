import dayjs from 'dayjs';
import {useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../../app/context';
import {Button as RsButton, Form, SingleActionModal} from '../../../components/bootstrap';
import DateInput from '../../../components/inputs/DateInput';
import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {FORMAT_DATE_S} from '../../../core/constants';
import {useModals} from '../../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {ChargingStation, CommissioningState} from '../../../models/ChargingStation';
import {translateError} from '../../../utils/Errors';
import {T} from '../../../utils/Internationalization';

interface CommissioningFieldProps {
  locationId: number;
  station: ChargingStation;
  isServiceDesk: boolean;
  onCommissioned: () => void;
}

export default function CommissioningField(props: CommissioningFieldProps) {
  const {locationId, station, isServiceDesk, onCommissioned} = props;
  const modals = useModals();

  const handleCommissionClicked = () => {
    modals
      .show(props => <CommissionModal {...props} locationId={locationId} chargingStation={station} />)
      .then(commissioned => commissioned && onCommissioned());
  };

  return (
    <>
      <div
        style={{
          display: 'inline-block',
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem'
        }}
      >
        {translateCommissioningState(station.data.commissioning?.state || CommissioningState.Invalid)}
        {station.data.commissioning?.completedTimestamp !== undefined &&
          ` ${dayjs(station.data.commissioning.completedTimestamp).format(FORMAT_DATE_S)}`}
      </div>
      {isServiceDesk && station.isAwaitingCommissioning() && (
        <RsButton size="sm" style={{position: 'relative', bottom: 2}} onClick={handleCommissionClicked}>
          {T('chargingStationConfiguration.property.commissioning.commission')}
        </RsButton>
      )}
      {isServiceDesk && station.isCommissioned() && (
        <Button variant="ghost_action_btn" size="icon" onClick={handleCommissionClicked}>
          <Edit />
        </Button>
      )}
    </>
  );
}

function translateCommissioningState(state: CommissioningState) {
  switch (state) {
    case CommissioningState.Initiated:
      return T('commissioningState.initiated');
    case CommissioningState.Completed:
      return T('commissioningState.completed');
    case CommissioningState.Invalid:
    default:
      return T('commissioningState.invalid');
  }
}

interface CommissionModalProps extends IPromiseModalProps<boolean> {
  locationId: number;
  chargingStation: ChargingStation;
}

function CommissionModal(props: CommissionModalProps) {
  const [isOpen, resolve] = usePromiseModal(props);
  const {locationId, chargingStation} = props;
  const {api} = useAppContext();

  const [error, setError] = useState<string | undefined>();
  const [commissioningDate, setCommissioningDate] = useState<Date | null>(new Date());

  const handleCommission = () => {
    if (commissioningDate === null) return Promise.resolve(undefined);

    setError(undefined);
    return api.chargingStations
      .update(locationId, chargingStation.serialNumber, {
        commissioning: {
          completedTimestamp: commissioningDate.getTime()
        }
      })
      .then(() => {
        NotificationManager.success(T('chargingStationConfiguration.commissioning.success'));
        resolve(true);
      })
      .catch(e => setError(translateError(e, T('chargingStationConfiguration.commissioning.failed'))));
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      title={T('chargingStationConfiguration.commissioning.title')}
      action={handleCommission}
      onToggle={() => resolve(false)}
      actionText={T('chargingStationConfiguration.commissioning.commission')}
    >
      {T('chargingStationConfiguration.commissioning.message')}
      <Form>
        <DateInput
          name="from"
          label={T('chargingStationConfiguration.commissioning.date')}
          value={commissioningDate}
          onChange={setCommissioningDate}
          required
        />
      </Form>
    </SingleActionModal>
  );
}
