import {IDeviceConfiguration, IDeviceConfigurationState} from '../models/DeviceConfiguration';
import {ILocation} from '../models/Location';

import {APIClient} from './APIClient';

export class InstallationAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getDeviceConfigurationHistory(
    serialNumber: string,
    from: number = 0,
    to: number = Date.now()
  ): Promise<IDeviceConfigurationState[]> {
    const url = `/api/v10/installation/${serialNumber}/configurations?from=${from}&to=${to}`;
    // TODO: workaround: configurations returns nothing instead of an empty array if there is no data available. This will be fixed with the socketcluster branch.
    //return this.apiEndpoint.get(path, {from: from || 0, to: to || new Date().getTime() }, DeviceConfiguration.listDeserializer);
    return this.client.doGet<IDeviceConfigurationState[]>(url).then(configurations => configurations || []);
  }

  async getCurrentConfiguration(serialNumber: string): Promise<IDeviceConfiguration> {
    const results = await this.getDeviceConfigurationHistory(serialNumber);
    if (results.length === 0) return {};

    // Status enum values (search 'enum Status' in backend)
    const PENDING = 0;
    //    const SYNCED = 1;
    const ACTUAL = 2;
    //    const OBSOLETE = 3;

    let result: IDeviceConfiguration = {};
    if (results[0].status === PENDING) {
      result.pending = results[0];
      result.actual = results.find(result => result.status === ACTUAL);
    } else if (results[0].status === ACTUAL) {
      result.actual = results[0];
    }
    return result;
  }

  getUserQuestions(locationId: number): Promise<unknown> {
    const url = `/api/v7/installation/${locationId}/questions`;
    return this.client.doGet(url);
  }

  performInstallation(installationRequest: InstallationRequest): Promise<InstallationResponse> {
    const url = `/api/v10/installation`;
    return this.client.doPost(url, installationRequest);
  }
}

export interface InstallationRequest {
  serviceLocationId: number;
  serialNumber: string;
  stationSerialNumber?: string;
  macAddress?: string;
}

export interface InstallationResponse {
  success: boolean;
  failureReason?: InstallationFailureReason;
  serviceLocation?: ILocation;
}

export enum InstallationFailureReason {
  NoSetupRecord = 'NO_RECENT_METER_SETUP'
}
