import {
  IDeviceConfigurationState,
  getFriendlyWorkingMode,
  getFriendlyInputsConnected,
  getFriendlyPhaseReverser,
  getFriendlyPhaseMapping,
  getFriendlyStatus
} from '../../models/DeviceConfiguration';
import {
  BooleanField,
  CalculatedStringField,
  IntegerField,
  ITableField,
  NumberField,
  StringField,
  TimestampFieldWithTimezone
} from '../../models/Table';
import {T} from '../../utils/Internationalization';

export function getTableFields(timezone: string | undefined): ITableField<IDeviceConfigurationState>[] {
  return [
    new TimestampFieldWithTimezone(
      'createdUtcStamp',
      'createdUtcStamp',
      T('deviceConfigurationHistory.field.timestamp'),
      timezone || 'UTC',
      {width: 125}
    ),
    new NumberField('autoCommissioningAction', T('deviceConfigurationHistory.field.autoCommissioning'), {width: 100}),
    new StringField('serialNumber', T('deviceConfigurationHistory.field.serialNumber'), {width: 100}),
    new NumberField('serviceLocationId', T('deviceConfigurationHistory.field.serviceLocationId'), {width: 75}),
    new BooleanField('is60Hz', T('deviceConfigurationHistory.field.is60Hz'), {
      width: 60
    }),
    new CalculatedStringField(
      'isLoad',
      T('deviceConfigurationHistory.field.isLoad'),
      item =>
        item.isLoad === undefined
          ? undefined
          : item.isLoad
            ? T('deviceConfigurationHistory.load')
            : T('deviceConfigurationHistory.grid'),
      {width: 60}
    ),
    new CalculatedStringField(
      'isDelta',
      T('deviceConfigurationHistory.field.isDelta'),
      item =>
        item.isDelta === undefined ? undefined : item.isDelta ? T('configuration.delta') : T('configuration.star'),
      {width: 60}
    ),
    new CalculatedStringField(
      'monitorMode',
      T('deviceConfigurationHistory.field.mode'),
      item => getFriendlyWorkingMode(item.monitorMode),
      {width: 150}
    ),
    new CalculatedStringField(
      'monitorWorkingMode',
      T('deviceConfigurationHistory.field.workingMode'),
      item => getFriendlyWorkingMode(item.monitorWorkingMode),
      {width: 150}
    ),
    new CalculatedStringField(
      'phaseMapping',
      T('deviceConfigurationHistory.field.phaseMapping'),
      item => getFriendlyPhaseMapping(item.phaseMapping),
      {width: 100}
    ),
    new CalculatedStringField(
      'inputConnected',
      T('deviceConfigurationHistory.field.connectedInputs'),
      item => getFriendlyInputsConnected(item.inputsConnected),
      {width: 100}
    ),
    new CalculatedStringField(
      'phaseReverser',
      T('deviceConfigurationHistory.field.phaseReverser'),
      item => getFriendlyPhaseReverser(item.phaseReverser),
      {width: 100}
    ),
    new NumberField('singleVoltageShift', T('deviceConfigurationHistory.field.singleVoltageShift'), {width: 75}),
    new IntegerField('sequence', T('deviceConfigurationHistory.field.sequence'), {width: 75}),
    new IntegerField('createdBy', T('deviceConfigurationHistory.field.createdBy'), {width: 75}),
    new CalculatedStringField(
      'status',
      T('deviceConfigurationHistory.field.status'),
      item => getFriendlyStatus(item.status),
      {width: 100}
    ),
    new NumberField('inputMask', T('deviceConfigurationHistory.field.inputMask'), {width: 75}),
    new BooleanField('isACValidationRunning', T('deviceConfigurationHistory.field.isACValidationRunning'), {
      width: 75
    }),
    new BooleanField('isACInstantRunning', T('deviceConfigurationLegacy.acInstantRunning'), {width: 75})
  ];
}
