import {AuthUser} from '../models/AuthUser';
import {AppStore} from '../redux';
import {setMe} from '../redux/actions/me';
import {setUser} from '../utils/LocalUser';

export class APISharedLoginState {
  refreshingLogin: Promise<unknown> | null;
  me: AuthUser;
  store: AppStore | undefined;
  backend: (url: string, options: any) => Promise<Response>;
  signal?: AbortSignal;

  public constructor(store: AppStore | undefined, backend: (url: string, options: any) => Promise<Response>) {
    this.store = store;
    this.backend = backend;

    this.refreshingLogin = null;

    // Init values
    this.me = store ? store.getState().me.me : AuthUser.anonymous();

    // Subscribe to user object
    if (store) store.subscribe(this.handleStoreChange);
  }

  handleStoreChange = () => {
    if (!this.store) return;

    const {me: next} = this.store.getState().me;
    if (this.me !== next) {
      this.me = next;
    }
  };

  setUser(user: AuthUser) {
    if (this.store) {
      setUser(user.serialize());
      this.store.dispatch(setMe(user));
    } else {
      this.me = user;
    }
  }
}
