import type {SVGProps} from 'react';
const SvgCheckmarkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M12.474 3.15c.509.3.678.957.377 1.467l-4.643 7.857a1.07 1.07 0 0 1-1.78.097L3.215 8.286A1.071 1.071 0 0 1 4.93 7l2.25 3.002 3.827-6.475c.301-.51.958-.679 1.467-.378" />
  </svg>
);
export default SvgCheckmarkSmall;
