import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {CardView, cardViewProps} from '../components/CardView';

import styles from './index.module.scss';

function NotFound(props: ICardProps<ICardSettings>) {
  return (
    <CardView noHeader={false} {...cardViewProps(props)}>
      <div className={styles.notFound}>
        <span className="fal fa-question" title="" />
        {T('notFound.content.message')}
      </div>
    </CardView>
  );
}

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.NotFound,
  title: 'notFound.title',
  description: 'notFound.description',
  categories: [CardCategory.USERS],
  hidden: true,
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Unaware,
  cardClass: NotFound
};
export default CARD;
