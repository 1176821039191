import {AnyAppAction} from '../actions';
import {LocationActions} from '../actions/location';
import {ILocationState, LoadState} from '../AppState';

export default function location(
  state: ILocationState = {loading: LoadState.NOT_YET_LOADED},
  action: AnyAppAction
): ILocationState {
  switch (action.type) {
    case LocationActions.FETCHING:
      return {loading: LoadState.FETCHING, location: state.location};

    case LocationActions.LOAD_SUCCESS:
      return {loading: LoadState.LOADED, location: action.payload};

    case LocationActions.UPDATE:
      return {...state, location: action.payload};

    case LocationActions.LOAD_ERROR:
      return {loading: LoadState.ERROR, location: state.location};

    default:
      return state;
  }
}
