import {APIClient} from './APIClient';

export interface SessionPaymentSpecification {
  newPaymentType: 'SPLITBILLING';
  splitBillingAgreementId?: number;
}

export class ChargingSessionsAPI {
  client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  fix(id: number, kWh: number, stoppedAt: number) {
    return this.client.doPost(`/api/v10/chargingsessions/${id}/fix`, {
      kWh,
      stoppedAt
    });
  }

  updatePaymentSpecification(id: number, paymentSpecification: SessionPaymentSpecification) {
    return this.updatePaymentSpecificationInBulk([id], paymentSpecification);
  }

  updatePaymentSpecificationInBulk(ids: number[], paymentSpecification: SessionPaymentSpecification) {
    return this.client.doPut('/api/v10/chargingsessions/sessionpaymentspecification', {
      sessionIds: ids,
      ...paymentSpecification
    });
  }
}
