import React from 'react';

import styles from './SplitPane.module.scss';

interface SplitPaneProps {
  children: [JSX.Element | null | false, JSX.Element | null | false];
}
export const SplitPane = (props: SplitPaneProps) => {
  const {children} = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>{children[0]}</div>
      <div className={styles.right}>{children[1]}</div>
    </div>
  );
};
