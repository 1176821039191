import {
  AutomationPossibleAction,
  Automation,
  getActionSignature,
  AutomationActionType,
  IAutomationTarget
} from '../../../models/Automation';
import {
  ConfigurationPropertySpecies,
  IConfigurationProperty,
  IConfigurationPropertyValue
} from '../../../models/SmartDevice';

export interface IMultiTargetAction {
  action: AutomationPossibleAction;
  properties: IConfigurationProperty[];
  targets: IAutomationTarget[];
}

function isSameValue(
  species: ConfigurationPropertySpecies,
  a: IConfigurationPropertyValue,
  b: IConfigurationPropertyValue
) {
  switch (species) {
    case ConfigurationPropertySpecies.String:
      return a.String === b.String;
    case ConfigurationPropertySpecies.bool:
      return a.bool === b.bool;
    case ConfigurationPropertySpecies.Boolean:
      return a.Boolean === b.Boolean;
    case ConfigurationPropertySpecies.int:
      return a.int === b.int;
    case ConfigurationPropertySpecies.Integer:
      return a.Integer === b.Integer;
    case ConfigurationPropertySpecies.long:
      return a.long === b.long;
    case ConfigurationPropertySpecies.Long:
      return a.Long === b.Long;
    case ConfigurationPropertySpecies.IPv4:
      return a.IPv4 === b.IPv4;
    case ConfigurationPropertySpecies.Quantity:
      if (a.Quantity === b.Quantity) return true;
      if (a.Quantity === undefined || b.Quantity === undefined) return false;
      return a.Quantity.unit === b.Quantity.unit && a.Quantity.value === b.Quantity.value;
    case ConfigurationPropertySpecies.HighLevelMeasurementSpecification:
      if (a.HighLevelMeasurementSpecification === b.HighLevelMeasurementSpecification) {
        return true;
      }
      if (a.HighLevelMeasurementSpecification === undefined || b.HighLevelMeasurementSpecification === undefined) {
        return false;
      }
      return a.HighLevelMeasurementSpecification.id === b.HighLevelMeasurementSpecification.id;
    default:
      return false; // not yet supported
  }
}

function areSameProperties(a: IConfigurationProperty[], b: IConfigurationProperty[]) {
  if (a.length !== b.length) return false;

  a.sort((a, b) => a.spec.name.localeCompare(b.spec.name));
  b.sort((a, b) => a.spec.name.localeCompare(b.spec.name));
  for (let i = 0; i < a.length; i++) {
    const propertyA = a[i];
    const propertyB = b[i];
    if (propertyA.spec.name !== propertyB.spec.name) return false;
    if (!isSameValue(propertyA.spec.species, propertyA.values[0], propertyB.values[0])) {
      return false;
    }
  }

  return true;
}

function findAction(actions: IMultiTargetAction[], action: AutomationPossibleAction): number {
  const actionSignature = getActionSignature(action);
  return actions.findIndex(x => {
    if (getActionSignature(x.action) !== actionSignature) return false;

    if (action.type === AutomationActionType.Smart) {
      return areSameProperties(action.smart.properties || [], x.properties);
    } else {
      return true;
    }
  });
}

export function mergeActions(automation: Automation, targets: IAutomationTarget[]) {
  if (!automation.scene) return [];

  const result: IMultiTargetAction[] = [];
  for (let action of automation.scene.actions) {
    const signature = getActionSignature(action);
    const target = targets.find(t => t.id === action.target.id && t.type === action.target.type);
    if (!target) continue;

    const existing = findAction(result, action);
    if (existing < 0) {
      const actionDefinition = target.possibleActions.find(action => getActionSignature(action) === signature);
      if (!actionDefinition) continue;

      result.push({
        action: actionDefinition,
        properties: (action.type === AutomationActionType.Smart && action.smart.properties) || [],
        targets: [target]
      });
    } else {
      result[existing].targets.push(target);
    }
  }
  return result;
}

export interface ISimpleMultiTargetAction {
  action: AutomationPossibleAction;
  properties: IConfigurationProperty[];
  targets: {id: string; type: string}[];
}

export function mergeActionsSimple(automation: Automation) {
  if (!automation.scene) return [];

  const result: ISimpleMultiTargetAction[] = [];
  const indices = new Map<string, number>();
  for (let action of automation.scene.actions) {
    const signature = getActionSignature(action);
    const index = indices.get(signature);
    if (index === undefined) {
      indices.set(signature, result.length);
      result.push({
        action,
        properties: [],
        targets: [action.target]
      });
    } else {
      result[index].targets.push(action.target);
    }
  }
  return result;
}
