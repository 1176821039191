import {useEffect, useState, useRef, useCallback} from 'react';

import {useAppContext} from '../app/context';

import {useUser} from '../cards/CardUtils';
import {ILocationSummary, ILocationMetaInfo} from '../models/Location';
import {getOnlineStatus, OnlineStatus} from '../models/OnlineStatus';

import {TrackingType, IPowerMessage, IVectorMessage, IHarmonicsMessage} from './LiveDataModels';
import {MqttTopicType, MqttSubscription, MqttConnector, Tracking} from './MqttConnector';
import {SmappeeWebSocket, WebsocketMessage} from './WebsocketConnector';

export function useLiveValues(
  location: ILocationSummary | undefined,
  enabled: boolean = true
): [IPowerMessage | undefined, OnlineStatus] {
  return useLiveData(location, MqttTopicType.Power, enabled);
}

export function useLivePhasor(
  location: ILocationSummary | undefined,
  enabled: boolean = true
): [IVectorMessage | undefined, OnlineStatus] {
  return useLiveData(location, MqttTopicType.Phasor, enabled);
}

export function useLiveHarmonics(
  location: ILocationSummary | undefined,
  enabled: boolean = true
): [IHarmonicsMessage | undefined, OnlineStatus] {
  return useLiveData(location, MqttTopicType.Harmonics, enabled);
}

function getTrackingType(topic: MqttTopicType): TrackingType {
  switch (topic) {
    case MqttTopicType.Power:
      return TrackingType.Realtime;
    case MqttTopicType.Phasor:
      return TrackingType.Vectors;
    case MqttTopicType.Harmonics:
      return TrackingType.Harmonics;
    default:
      return TrackingType.Realtime;
  }
}

export function useLiveData<T>(
  location: ILocationSummary | undefined,
  topic: MqttTopicType,
  enabled: boolean
): [T | undefined, OnlineStatus] {
  const serialNumber = location && location.serialNumber;
  const uuid = location && location.uuid;
  const locationId = location && location.id;
  const {mqtt, api} = useAppContext();
  const me = useUser();

  const [lastMessage, setLastMessage] = useState<T | undefined>();
  const [offline, setOffline] = useState<boolean>(false);
  const [unavailable, setUnavailable] = useState<boolean>(false);

  const subscription = useRef<MqttSubscription | undefined>();
  const websocket = useRef<SmappeeWebSocket | undefined>();

  // clear last message after 65s
  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (lastMessage !== undefined) setOffline(false);

    timeout.current = setTimeout(() => {
      setLastMessage(undefined);
      setOffline(true);
    }, 65000);
    return () => timeout.current && clearTimeout(timeout.current);
  }, [lastMessage]);
  useEffect(() => setOffline(false), [serialNumber]);

  const resendTrackingAfter = useCallback(
    (timeoutMillis: number) => {
      if (!uuid || !serialNumber || !enabled) return;

      const tracking: Tracking = {
        type: getTrackingType(topic),
        serialNumber,
        trackingLocationUUID: uuid
      };
      const onTimeout = () => mqtt.resendTracking(tracking);
      const timeout = setInterval(onTimeout, timeoutMillis);
      return () => clearInterval(timeout);
    },
    [mqtt, uuid, serialNumber, topic, enabled]
  );

  // automatically resend tracking message if no data has been received for 20s
  useEffect(() => resendTrackingAfter(20000), [resendTrackingAfter, lastMessage]);
  // automatically resend tracking message every 20 minutes
  useEffect(() => resendTrackingAfter(20 * 60 * 1000), [resendTrackingAfter]);

  const setSubscription = useCallback(
    (value: MqttSubscription | undefined) => {
      if (subscription.current) subscription.current.close();

      subscription.current = value;
    },
    [subscription]
  );

  const setWebsocket = useCallback(
    (value: SmappeeWebSocket | undefined) => {
      if (websocket.current) websocket.current.disconnect();

      websocket.current = value;
    },
    [websocket]
  );

  useEffect(() => {
    const handleConnected = (subscription: MqttSubscription, connectedLocationId: number) => {
      if (locationId === connectedLocationId) setSubscription(subscription);
    };

    const disconnect = () => {
      setSubscription(undefined);
      setWebsocket(undefined);
      setLastMessage(undefined);
    };

    disconnect();

    if (!serialNumber || !uuid || !locationId || !enabled) {
      setUnavailable(true);
      return;
    }

    setUnavailable(false);
    const isOldDevice = serialNumber.startsWith('200');
    if (isOldDevice && topic !== MqttTopicType.Power) return; // legacy devices only offer live power

    const loadMeta: Promise<ILocationMetaInfo | undefined> = isOldDevice
      ? api.getLocationMetaInfo(locationId)
      : Promise.resolve(undefined);

    loadMeta.then(meta => {
      if (meta && meta.messagingProtocol === 'WEBSOCKET') {
        const websocket = new SmappeeWebSocket();
        websocket.onMessage = (message: WebsocketMessage) => {
          if (message.messageType === 515) {
            setLastMessage(message.content as any);
          }
        };
        websocket.connect(meta.uuid);
        setWebsocket(websocket);
      } else {
        const tracking: Tracking = {
          type: getTrackingType(topic),
          serialNumber,
          trackingLocationUUID: uuid
        };

        mqtt.subscribe(
          me.userId.toString(),
          uuid,
          uuid,
          MqttConnector.getTopic(uuid, topic),
          tracking,
          false,
          subscription => handleConnected(subscription, locationId),
          message => setLastMessage(message)
        );
      }
    });

    return disconnect;
  }, [
    api,
    mqtt,
    uuid,
    serialNumber,
    locationId,
    topic,
    enabled,
    setSubscription,
    setWebsocket,
    setLastMessage,
    me.userId
  ]);

  const status = getOnlineStatus(lastMessage, offline, unavailable);
  return [lastMessage, status];
}
