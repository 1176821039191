import {PeriodRoundingMode, Period, ActivePeriod, getPeriodRangeForTimezone} from '../../components/PeriodSelector';
import API from '../../core/api';
import {ChargingStation} from '../../models/ChargingStation';
import {IConsumptionValue, isForecastValue} from '../../models/ConsumptionValue';
import {IHighLevelConfiguration, PhaseType} from '../../models/HighLevelConfiguration';
import {
  isChargingParent as isChargingStationParent,
  hasChargingStationFunctionality,
  isEVWallLocation,
  ILocationSummary
} from '../../models/Location';
import {IRetentionPolicy} from '../../models/RetentionPolicy';
import {Interval} from '../../models/UsageValue';
import {getDualUltraConsumption, isDualUltraAssembly} from '../../utils/DualUltra';

import {IChargingStationConsumption} from './ChargingGroupGraph';
import {fixChargingStationGaps} from './ChargingStationGraph';
import {IRearrangedChargingStationGroupItem, rearrangeChargingStationGroupData} from './RearrangeStationGroupData';

export interface BaseConsumptionData {
  type: 'station' | 'group';
  period: ActivePeriod;
  highLevelConfig: IHighLevelConfiguration;
}

export interface StationConsumptionData extends BaseConsumptionData {
  type: 'station';
  stationData: IConsumptionValue[];
  empty: boolean;
}

export interface ParentConsumptionData extends BaseConsumptionData {
  type: 'group';
  stations: ChargingStation[];
  stationsData: IChargingStationConsumption[];
  stationsActive: ChargingStation[];
  stationsDataRearranged: IRearrangedChargingStationGroupItem[];
  parentConsumption: IConsumptionValue[];
  empty: boolean;
}

export type ConsumptionData = StationConsumptionData | ParentConsumptionData;

export async function fetchChargingConsumption(
  api: API,
  retentionPromise: Promise<IRetentionPolicy | undefined>,
  location: ILocationSummary,
  period: Period,
  from: number | undefined,
  to: number | undefined,
  interval: Interval,
  forceRefresh?: boolean
): Promise<ConsumptionData | undefined> {
  try {
    const isChargingStation = hasChargingStationFunctionality(location);
    const isChargingParent =
      (isChargingStationParent(location.functionType) && !isChargingStation) || isEVWallLocation(location);
    const isUltraDual = isDualUltraAssembly(location);

    let highLevelConfigPromise: Promise<IHighLevelConfiguration>;
    if (isUltraDual && location.chargingStation?.serialNumber) {
      const station = await api.chargingStations.getBySerial(location.chargingStation?.serialNumber);
      const parts = station.parts?.map(part => api.getHighLevelConfiguration(part.serviceLocation.id)) || [];
      highLevelConfigPromise = Promise.all(parts).then(parts => {
        return {
          underConstruction: false,
          locationId: location.id,
          phaseType: PhaseType.Star,
          nilm: false,
          measurements: parts.flatMap(part =>
            part.measurements.map(m => {
              m.serviceLocationId = part.locationId;
              return m;
            })
          )
        };
      });
    } else {
      highLevelConfigPromise = api.getHighLevelConfiguration(location.id, forceRefresh).catch(() => ({
        underConstruction: false,
        locationId: location.id,
        phaseType: PhaseType.Star,
        nilm: false,
        measurements: []
      }));
    }

    const retention = await retentionPromise;
    const activePeriod = getPeriodRangeForTimezone(
      {period, from, to, interval},
      location.timeZoneId,
      retention,
      PeriodRoundingMode.INCLUSIVE
    );

    if (isChargingParent) {
      const chargingStations = await api.chargingStations
        .getByLocation(location.id)
        .then(stations => stations.map(station => new ChargingStation(station)));

      const promises: Promise<IChargingStationConsumption>[] = [];
      for (let station of chargingStations) {
        if (station.data.serviceLocation === undefined) continue;

        const locationId = station.data.serviceLocation.id;
        promises.push(
          api.getElectricityConsumption(locationId, activePeriod).then(items => {
            return {
              station,
              consumption: fixChargingStationGaps(items, interval)
            };
          })
        );
      }

      const stationsData = await Promise.all(promises);

      const parentConsumptionAll = await api.getElectricityConsumption(location.id, activePeriod);
      const parentConsumption = parentConsumptionAll.filter(x => !isForecastValue(x));
      const {data: rearrangedData, stations: activeStations} = rearrangeChargingStationGroupData(
        stationsData,
        parentConsumption
      );

      return {
        type: 'group',
        period: activePeriod,
        stations: chargingStations,
        stationsData,
        stationsActive: activeStations,
        stationsDataRearranged: rearrangedData,
        parentConsumption,
        highLevelConfig: await highLevelConfigPromise,
        empty: parentConsumption.length === 0 && stationsData.every(x => x.consumption.length === 0)
      };
    } else if (isChargingStation) {
      const items =
        isUltraDual && location.chargingStation?.serialNumber
          ? await getDualUltraConsumption(api, location.id, location.chargingStation?.serialNumber, activePeriod)
          : await api.getElectricityConsumption(location.id, activePeriod);
      const filteredItems = items.filter(consumption => consumption.value !== undefined);
      return {
        type: 'station',
        period: activePeriod,
        stationData: filteredItems,
        highLevelConfig: await highLevelConfigPromise,
        empty: filteredItems.length === 0
      };
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
}
