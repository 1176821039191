import {PeriodSettings} from '../../components/PeriodSelector';
import {CardDisplayType, ICardSettingsWithTable} from '../../models/CardSettings';

export enum ChargingStationConsumptionMode {
  Regular = 'regular',
  OverloadIncludingConsumption = 'overloadIncl',
  OverloadExcludingConsumption = 'overloadExcl'
}

export interface ChargingStationConsumptionSettings extends ICardSettingsWithTable, PeriodSettings {
  cardType: CardDisplayType;
  mode: ChargingStationConsumptionMode;
}
