import React, {useMemo, useState} from 'react';

import {NotificationManager} from 'react-notifications';
import {Input} from 'reactstrap';

import {useSibelgaCampaigns} from '../../../api/SibelgaAPI';
import {useAppContext} from '../../../app/context';
import {Button as RsButton} from '../../../components/bootstrap';
import Table, {migrateTableSettings} from '../../../components/Table';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../../modals/ConfirmationPromiseModal';
import {useModals} from '../../../modals/ModalContext';
import {UserRights} from '../../../models/AuthUser';
import {ICardSettingsWithTable} from '../../../models/CardSettings';
import {
  FluviusCampaignPDFRequest,
  FluviusCampaignConfiguration,
  IFluviusCampaign,
  IFluviusCampaignFields,
  FluviusCampaignStatus
} from '../../../models/FluviusMeasurementCampaign';
import {IOrganizationRegion, IMeasuringCase, translateRegionName} from '../../../models/Organization';
import {useLoader} from '../../../utils/Hooks';
import {getCurrentLanguage, plural} from '../../../utils/Internationalization';
import {showBlobInBrowser} from '../../../utils/OpenBlob';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../../CardType';
import {useUser} from '../../CardUtils';
import {CardActions, ColumnChooser} from '../../components';
import {Reload} from '../../components/actions';
import {Spring} from '../../components/CardActions';
import {CustomSettings, cardViewProps, CardView, CustomActions} from '../../components/CardView';

import {getTableColumns, rowKey} from './Columns';
import {CreateSibelgaMeasurementCampaign} from './CreateSibelgaMeasurementCampaign';

import {SiT} from './SibelgaModels';
import translationsFR from './translations.fr.json';
import translationsNL from './translations.nl.json';

export type ISibelgaMeasurementCampaignCardSettings = ICardSettingsWithTable;

const NoRegions: IOrganizationRegion[] = [];
const NoMeasuringCases: IMeasuringCase[] = [];

const SibelgaMeasurementCampaigns = (props: ICardProps<ISibelgaMeasurementCampaignCardSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {api} = useAppContext();
  const me = useUser();

  const modals = useModals();
  const [filterStatus, setFilterStatus] = useState(FluviusCampaignStatus.Inactive);
  const [filterRegion, setFilterRegion] = useState<number>();
  const [campaigns, refreshCampaigns] = useSibelgaCampaigns(fetch, filterStatus);

  const [regions = NoRegions] = useLoader(
    () => fetch('regions', api => api.sibelga.getRegions().catch(() => []), plural('region')),
    [fetch]
  );
  const [measuringCases = NoMeasuringCases] = useLoader(
    () => fetch('sets', api => api.sibelga.getMeasuringCases(), plural('measuringSet')),
    [fetch]
  );

  const fields = useMemo(() => {
    const handleClickedView = async (item: IFluviusCampaign) => {
      modals.show<FluviusCampaignConfiguration | undefined>(props => (
        <CreateSibelgaMeasurementCampaign
          regions={regions}
          measuringCases={measuringCases}
          readOnly={true}
          existing={item.configuration}
          {...props}
        />
      ));
    };

    const startCampaign = async (item: IFluviusCampaign) => {
      const activatePromise = api.sibelga.activateMeasurementCampaign(item.id);
      const generatePDFPromiseNL = generatePDF(item, 'nl');
      const generatePDFPromiseFR = generatePDF(item, 'fr');
      try {
        const [campaign, pdfBlobNL, pdfBlobFR] = await Promise.all([
          activatePromise,
          generatePDFPromiseNL,
          generatePDFPromiseFR
        ]);
        if (!campaign || !pdfBlobNL || !pdfBlobFR) return;

        const locationIds = campaign.serviceLocationIds;
        if (!locationIds) return;

        refreshCampaigns();
        try {
          const filesNL = await api.uploadBlob(locationIds[0], pdfBlobNL, 'setup-nl.pdf');
          const fileNL = filesNL[0];
          const filesFR = await api.uploadBlob(locationIds[0], pdfBlobFR, 'setup-fr.pdf');
          const fileFR = filesFR[0];
          for (let locationId of locationIds) {
            await api.attachCustomFileToLocation(locationId, {
              url: fileNL.fileName,
              tag: translationsNL['filename.campaign']
            });
            await api.attachCustomFileToLocation(locationId, {
              url: fileFR.fileName,
              tag: translationsFR['filename.campaign']
            });
          }
          NotificationManager.success(SiT('action.start.success'));
        } catch {
          NotificationManager.warning(SiT('action.start.nopdf'));
        }
      } catch {
        NotificationManager.error(SiT('action.start.failed'));
      }
    };

    const handleClickedStart = async (item: IFluviusCampaign) => {
      if (item.confirmActivation) {
        const confirmed = await modals.show(props => (
          <ConfirmationPromiseModal
            title={SiT('start.title')}
            message={SiT('start.message')}
            acceptLabel={SiT('action.save')}
            rejectLabel={SiT('action.cancel')}
            {...props}
          />
        ));
        if (confirmed === ConfirmationResult.Accept) startCampaign(item);
      } else {
        startCampaign(item);
      }
    };

    const deleteCampaign = async (campaign: IFluviusCampaign) => {
      try {
        await api.sibelga.deleteMeasurementCampaign(campaign.id);
        refreshCampaigns();
        NotificationManager.success(SiT('action.remove.success'));
      } catch {
        NotificationManager.error(SiT('action.remove.failed'));
      }
    };

    const handleClickedRemove = async (item: IFluviusCampaign) => {
      const confirmed = await modals.show(props => (
        <ConfirmationPromiseModal
          title={SiT('remove.title')}
          message={SiT('remove.message')}
          acceptLabel={SiT('action.remove')}
          rejectLabel={SiT('action.cancel')}
          {...props}
        />
      ));
      if (confirmed === ConfirmationResult.Accept) deleteCampaign(item);
    };

    const handleClickedViewPDF = (item: IFluviusCampaign) => {
      const language = getCurrentLanguage() === 'fr' ? 'fr' : 'nl';
      generatePDF(item, language).then(result => {
        if (result === undefined) return;

        showBlobInBrowser(result, `measurements-setup-${language}.pdf`);
      });
    };

    const generatePDF = (campaign: IFluviusCampaign, language: 'nl' | 'fr'): Promise<Blob | undefined> => {
      const {configuration} = campaign;
      const region = regions.find(x => x.id === configuration.region);
      const set = measuringCases.find(x => x.id === configuration.measuringCase);
      if (!region || !set) return Promise.resolve(undefined);

      const pdfRequest: FluviusCampaignPDFRequest = {
        language,
        type: configuration.type,
        region: {
          id: region.id,
          name: region.name || (region.names || {}).en
        },
        measuringCase: {id: set.id, code: set.name || ''},
        name: configuration.name,
        cabinId: configuration.cabinId,
        voltage: configuration.voltage,
        city: configuration.city,
        address: configuration.address,
        reason: configuration.reason,
        notes: configuration.notes,
        geniusA: configuration.geniusA,
        geniusB: configuration.geniusB
      };
      const url = '/sibelga-campaign-pdf';
      return window
        .fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(pdfRequest)
        })
        .then(resp => resp.blob());
    };

    return getTableColumns(regions, measuringCases, {
      onClickedView: handleClickedView,
      onClickedViewPDF: handleClickedViewPDF,
      onClickedStart: handleClickedStart,
      onClickedRemove: handleClickedRemove
    });
  }, [api, modals, regions, measuringCases, refreshCampaigns]);

  const handleClickedCreate = async () => {
    const request = await modals.show<FluviusCampaignConfiguration | undefined>(props => (
      <CreateSibelgaMeasurementCampaign
        regions={regions}
        measuringCases={measuringCases}
        readOnly={false}
        existing={undefined}
        {...props}
      />
    ));
    if (request === undefined) return;

    const campaign: IFluviusCampaignFields = {
      name: request.name,
      configuration: request
    };
    try {
      const saved = await api.sibelga.createMeasurementCampaign(campaign);
      if (!saved) return;

      refreshCampaigns();
      NotificationManager.success(SiT('action.save.success'));
    } catch {
      NotificationManager.error(SiT('action.save.failed'));
    }
  };

  const handleFilterRegionChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setFilterRegion(value === '_' ? undefined : parseInt(value));
  };

  const handleFilterStatusChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setFilterStatus(e.currentTarget.value as FluviusCampaignStatus);
  };

  const renderSettings: CustomSettings<ISibelgaMeasurementCampaignCardSettings> = (settings, updateSettings) => (
    <ColumnChooser fields={fields} settings={settings.table} updateSettings={table => updateSettings({table})} />
  );

  const isServiceDesk = me.isServiceDesk();

  const actions: CustomActions = () => (
    <CardActions>
      <Input
        type="select"
        value={filterRegion === undefined ? '_' : filterRegion.toString()}
        onChange={handleFilterRegionChanged}
      >
        <option key="_" value="_">
          {SiT('department.any')}
        </option>
        {regions.map(region => (
          <option key={region.id} value={region.id.toString()}>
            {translateRegionName(region)}
          </option>
        ))}
      </Input>
      {isServiceDesk && (
        <Input type="select" value={filterStatus} onChange={handleFilterStatusChanged}>
          <option value={FluviusCampaignStatus.All}>{SiT('status.any')}</option>
          <option value={FluviusCampaignStatus.Inactive}>{SiT('status.inactive')}</option>
          <option value={FluviusCampaignStatus.Active}>{SiT('status.active')}</option>
        </Input>
      )}
      <Reload onReload={refreshCampaigns} />
      <Spring />
      <RsButton onClick={handleClickedCreate}>{SiT('action.new')}</RsButton>
    </CardActions>
  );

  let filteredCampaigns = campaigns;
  if (filterRegion) {
    filteredCampaigns = filteredCampaigns.filter(x => x.configuration.region === filterRegion);
  }

  return (
    <CardView actions={actions} customSettings={renderSettings} {...cardViewProps(props)}>
      <Table
        fields={fields}
        settings={settings.table}
        items={filteredCampaigns}
        rowKey={rowKey}
        updateSettings={table => updateSettings({table})}
        noun="fluviusMeasurementCampaign"
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'region', visible: true},
      {name: 'set', visible: true}
    ]
  }
};
const CARD: ICardType<ISibelgaMeasurementCampaignCardSettings> = {
  type: CardTypeKey.SibelgaMeasurementCampaigns,
  title: 'sibelgameasurementcampaign.title',
  description: 'sibelgameasurementcampaign.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.User,
  isAvailable: (user, organizations) =>
    user.isServiceDesk() || organizations.find(org => org.name.toLowerCase() === 'sibelga') !== undefined,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: SibelgaMeasurementCampaigns,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table)
};
export default CARD;
