import {useState} from 'react';
import {Card, CardBody, CardHeader, Form} from 'reactstrap';

import {Spring} from '../../../cards/components/CardActions';
import {ILocation, isChargingParent} from '../../../models/Location';
import {IPricingPolicyAssignment} from '../../../models/PricingPolicy';
import {None} from '../../../utils/Arrays';
import {useLoader} from '../../../utils/Hooks';
import {T} from '../../../utils/Internationalization';
import {Checkbox} from '../../ui/checkbox';

import styles from './AssignPricingPolicy.module.scss';
import {usePricingPolicy} from './PricingPolicyProvider';

interface SelectChargingHubProps {
  id: number | string;
  hub?: ILocation;
  name: string;
  stations: ILocation[];
  selected: IPricingPolicyAssignment[];
  onSelect: (items: IPricingPolicyAssignment[]) => void;
  onDeselect: (ids: number[]) => void;
}
function SelectChargingHub(props: SelectChargingHubProps) {
  const {id, hub, name, stations, selected, onSelect, onDeselect} = props;
  //const [expanded, setExpanded] = React.useState(selected.some(x => x.parentId === hub.id));
  const [expanded, setExpanded] = useState(true);
  const disabled = !stations.some(station => station.chargingStation?.smappeePublicChargingEnabled !== false);
  const isSelected =
    !disabled &&
    !stations.some(
      station =>
        station.chargingStation?.smappeePublicChargingEnabled !== false && !selected.some(x => x.id === station.id)
    );

  const handleChecked = (checked: boolean) => {
    if (checked) {
      onSelect(
        stations
          .filter(station => station.chargingStation?.smappeePublicChargingEnabled !== false)
          .map(station => ({
            id: station.id,
            name: station.name || '',
            parentId: station.parentId,
            functionType: station.functionType
          }))
      );
    } else {
      onDeselect(stations.map(station => station.id));
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className={styles.header}>
          <Checkbox
            id={`check-hub-${id}`}
            name={`check-hub-${id}`}
            label={name || '?'}
            checked={isSelected}
            onCheckedChange={handleChecked}
            disabled={disabled}
            className="tw-mb-0 tw-font-bold"
            testId={`check-hub-${id}`}
          />
          <Spring />
          <div className={styles.extra}>
            {hub && hub.chargingSettings?.pricingPolicy && (
              <span style={{float: 'right', color: '#888'}}>
                {T('pricingPolicies.add.assign.current', {name: hub.chargingSettings.pricingPolicy.name})}
              </span>
            )}
            <button type="button" onClick={() => setExpanded(!expanded)}>
              <i className={expanded ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
              <span className="sr-only">{expanded ? 'Collapse' : 'Expand'}</span>
            </button>
          </div>
        </div>
      </CardHeader>
      {expanded && (
        <CardBody>
          <SelectChargingStations {...props} />
        </CardBody>
      )}
    </Card>
  );
}

interface SelectChargingStationsProps {
  stations: ILocation[];
  selected: IPricingPolicyAssignment[];
  onSelect: (items: IPricingPolicyAssignment[]) => void;
  onDeselect: (ids: number[]) => void;
}
function SelectChargingStations(props: SelectChargingStationsProps) {
  const {stations, selected, onSelect, onDeselect} = props;

  const handleChange = (checked: boolean, location: ILocation) => {
    if (checked) {
      onSelect([
        {
          id: location.id,
          name: location.name || '',
          parentId: location.parentId,
          functionType: location.functionType
        }
      ]);
    } else {
      onDeselect([location.id]);
    }
  };

  return (
    <div>
      {stations.map(location => (
        <Checkbox
          id={`check-station-${location.id}`}
          name={`check-station-${location.id}`}
          label={location.name || ''}
          checked={selected.some(item => item.id === location.id)}
          onCheckedChange={checked => handleChange(checked, location)}
          disabled={location?.chargingStation && location.chargingStation.smappeePublicChargingEnabled === false}
          style={{marginBottom: 0}}
          extraLabel={
            location.chargingStation?.smappeePublicChargingEnabled === false ? (
              <span style={{color: 'gray'}}> &middot; {T('pricingPolicies.add.assign.publicChargingDisabled')}</span>
            ) : undefined
          }
          testId={`check-station-${location.id}`}
        />
      ))}
    </div>
  );
}

export function AssignPricingPolicy() {
  const {formState, updateFormState, organization} = usePricingPolicy();

  const [allChargingHubs = None] = useLoader(
    api =>
      api.organizations.getCPOChargingSquares(organization.id, true).then(squares => {
        squares.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        return squares;
      }),
    [organization.id]
  );
  const handleSelect = (items: IPricingPolicyAssignment[]) =>
    updateFormState({assignedTo: [...formState.assignedTo, ...items]});
  const handleDeselect = (ids: number[]) =>
    updateFormState({assignedTo: formState.assignedTo.filter(x => !ids.includes(x.id))});

  const hasStandaloneStations = allChargingHubs.some(x => !isChargingParent(x.functionType));
  return (
    <Form>
      <h2 className="m-0 p-0 pb-3 font-bold">{T('pricingPolicies.add.assign.title')}</h2>
      <p>{T('pricingPolicies.add.assign.info')}</p>
      <div style={{maxHeight: 500, overflowY: 'scroll'}} className={styles.items}>
        {allChargingHubs
          .filter(station => isChargingParent(station.functionType))
          .map(hub => (
            <SelectChargingHub
              id={hub.id}
              hub={hub}
              name={hub.name || ''}
              stations={hub.chargingStations || None}
              selected={formState.assignedTo}
              onSelect={handleSelect}
              onDeselect={handleDeselect}
            />
          ))}
        {hasStandaloneStations && (
          <SelectChargingHub
            id="standalone"
            name={T('pricingPolicies.add.assign.others')}
            stations={allChargingHubs.filter(x => !isChargingParent(x.functionType))}
            selected={formState.assignedTo}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
          />
        )}
      </div>
    </Form>
  );
}
