import {IPowerMessage} from './LiveDataModels';

export function getLoadPower(message: IPowerMessage, channelIndices: (number | null)[]): number {
  let total = 0;
  for (let index of channelIndices) {
    if (index === null) continue;
    total += (message.activePowerData as number[])[index];
  }
  return total;
}
