import React, {useMemo, useEffect, useState, useCallback} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Input} from '../../components/bootstrap';
import {migrateTableSettings, SortOrder} from '../../components/Table';
import {SettingsTable, PropertyEntry} from '../../components/Table/SettingsTable';
import {
  findActivationCode,
  getOrganizationName,
  IActivationCode,
  IOrganizationActivationCode
} from '../../models/ActivationCode';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IChargingStation} from '../../models/ChargingStation';
import {DeviceType, expandSerialNumber, getDeviceTypeFromSerial, isGatewayDevice} from '../../models/DeviceType';
import {IOrganization} from '../../models/Organization';
import {useDelayedEffect} from '../../utils/Hooks';
import {T, singular} from '../../utils/Internationalization';
import {ICardType, CardTypeKey, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';
import {ActivationCodeField} from '../LocationConfiguration/fields/ActivationCodeField';
import {OrganizationField} from '../LocationConfiguration/fields/OrganizationField';

enum DeviceProperty {
  ActivationCode = 'ActivationCode',
  Organization = 'Organization',
  SerialNumber = 'SerialNumber',
  GatewaySerialNumber = 'GatewaySerialNumber'
}

type DeviceConfigurationSettings = ICardSettingsWithTable;

interface PropertiesState {
  serialNumber: string;
  gatewaySerialNumber: string;
  activationCode: IActivationCode | undefined;
  organization: IOrganization | undefined;
  chargingStation: IChargingStation | undefined;
}

const DeviceConfiguration = (props: ICardProps<DeviceConfigurationSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const {api} = useAppContext();
  const [serialNumber, setSerialNumber] = useState('');
  const [state, setState] = useState<PropertiesState>({
    serialNumber: '',
    gatewaySerialNumber: '',
    activationCode: undefined,
    organization: undefined,
    chargingStation: undefined
  });

  const location = useCardLocation(settings);
  const locationSerialNumber = location && location.serialNumber;

  const searchForSerial = useCallback(
    async (enteredSerialNumber: string) => {
      const serialNumber = expandSerialNumber(enteredSerialNumber) || enteredSerialNumber;
      if (serialNumber.length !== 10) return;

      let gatewaySerialNumber = serialNumber;
      let chargingStation: IChargingStation | undefined;
      if (serialNumber.startsWith('6')) {
        chargingStation = await api.chargingStations.getBySerial(serialNumber);
        const gateway = chargingStation.modules.find(module => isGatewayDevice(module.type));
        if (gateway) gatewaySerialNumber = gateway.serialNumber;
      }

      const code = await fetch(
        'activationCode',
        api => api.getDeviceActivationCode(gatewaySerialNumber),
        singular('activationCode')
      );

      const activationCodes = await api.activationCodes.getAll();
      const [activationCode, organizationId] = code
        ? findActivationCode(activationCodes.organizations, code.id)
        : [undefined, undefined];
      const actualOrganizationId = activationCode?.subOrganizationId || organizationId;
      const organization =
        actualOrganizationId === undefined ? undefined : await api.organizations.getById(actualOrganizationId);

      setState({
        activationCode: code,
        serialNumber,
        gatewaySerialNumber,
        organization,
        chargingStation
      });
    },
    [api, fetch]
  );

  useEffect(() => {
    if (locationSerialNumber) searchForSerial(locationSerialNumber);
  }, [locationSerialNumber, searchForSerial]);

  const handleSetDeviceActivationCode = useCallback(
    async (code: IOrganizationActivationCode, withDevice: boolean) => {
      const {activationCode, serialNumber} = state;

      try {
        await api.activationCodes.setDeviceActivationCode(
          activationCode && activationCode.code,
          serialNumber,
          code.code
        );
        searchForSerial(serialNumber);
        NotificationManager.success(T('locations.moveToOrganization.deviceMoved'));
      } catch {
        NotificationManager.error(T('locations.moveToOrganization.deviceMoveFailed'));
      }
    },
    [api, searchForSerial, state]
  );

  const handleSerialNumberChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const serialNumber = e.currentTarget.value;
    setSerialNumber(serialNumber);
  };

  useDelayedEffect(
    () => {
      searchForSerial(serialNumber);
    },
    [serialNumber],
    500
  );

  const isChargingStation = state.serialNumber !== state.gatewaySerialNumber;
  const properties = useMemo<PropertyEntry<PropertiesState>[]>(() => {
    const result: PropertyEntry<PropertiesState>[] = [
      {
        key: DeviceProperty.SerialNumber,
        label: T('general.serialNumber'),
        value: state => state.serialNumber
      }
    ];
    if (isChargingStation) {
      result.push({
        key: DeviceProperty.GatewaySerialNumber,
        label: T('deviceConfiguration.gatewaySerialNumber'),
        value: state => state.gatewaySerialNumber
      });
    }
    result.push(
      {
        key: DeviceProperty.Organization,
        label: T('locationConfiguration.property.organization'),
        value: state => (
          <OrganizationField
            value={state.activationCode}
            update={code => handleSetDeviceActivationCode(code, true)}
            readOnly={false}
            displayedValue={(state.organization && getOrganizationName(state.organization)) || ''}
          />
        )
      },
      {
        key: DeviceProperty.ActivationCode,
        label: T('deviceConfiguration.activationCode'),
        value: state => (
          <ActivationCodeField
            location={undefined}
            value={state.activationCode}
            update={handleSetDeviceActivationCode}
            readOnly={false}
            isDevice={true}
          />
        )
      }
    );
    return result;
  }, [handleSetDeviceActivationCode, isChargingStation]);

  const actions: CustomActions = state => (
    <CardActions>
      <Input
        type="text"
        name="serialNumber"
        placeholder={T('deviceConfiguration.serialNumber')}
        value={serialNumber}
        onChange={handleSerialNumberChanged}
        autoWidth={true}
      />
    </CardActions>
  );

  const isGatewaySerial = useMemo(() => {
    const deviceType = getDeviceTypeFromSerial(state.serialNumber);
    return deviceType === DeviceType.GasWater || isGatewayDevice(deviceType) || state.serialNumber.startsWith('6');
  }, [state.serialNumber]);

  let error;
  if (!isGatewaySerial) {
    error = T('deviceConfiguration.gatewayOnly');
  } else if (state.activationCode === undefined) {
    error = T('deviceConfiguration.notFound');
  }

  return (
    <CardView error={error} {...cardViewProps(props)} actions={actions}>
      <SettingsTable
        filter=""
        properties={properties}
        state={state}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
};

const DEFAULT_CARD_SETTINGS: DeviceConfigurationSettings = {
  table: {
    pageSize: 10,
    sortColumn: 'order',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'order', visible: false},
      {name: 'key', visible: true}
    ]
  }
};
const CARD: ICardType<DeviceConfigurationSettings> = {
  type: CardTypeKey.DeviceConfiguration,
  title: 'deviceConfiguration.title',
  description: 'deviceConfiguration.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.Aware,
  cardClass: DeviceConfiguration,
  upgradeSettings: migrateTableSettings('table', DEFAULT_CARD_SETTINGS.table)
};
export default CARD;
