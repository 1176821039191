import React from 'react';

import MiniGraph from '../../components/graph/MiniGraph';
import {Interval} from '../../models/UsageValue';
import {T} from '../../utils/Internationalization';
import {SeriesElement} from '../ElectricityValues/Series';

export interface IMiniConnectorGraphData {
  data: SeriesElement[];
  from: number;
  to: number;
  interval: Interval;
  timezone: string;
  connector: number;
  refresh: number;
}

interface MiniConnectorGraphProps {
  data: IMiniConnectorGraphData;
  color: string;
}

export const MiniConnectorGraph = React.memo((props: MiniConnectorGraphProps) => {
  const {data, color} = props;

  const {data: chartData, from, to, timezone, connector} = data;
  return (
    <MiniGraph
      timezone={timezone}
      from={from}
      to={to}
      data={chartData}
      label={T('chargingConnectorQuickView.connector', {
        connector: connector.toString()
      })}
      unit="kWh"
      bigConsumer={true}
      color={color}
    />
  );
});
