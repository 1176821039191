import React, {useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {Form} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {SingleActionPromiseModal} from '../../components/bootstrap/SingleActionPromiseModal';
import DateInput from '../../components/inputs/DateInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {SplitBillingAgreement} from '../../models/SplitBillingAgreement';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';

interface ModalProps extends IPromiseModalProps<boolean> {
  agreement: SplitBillingAgreement;
}

export default function CloseSplitBillingModal(props: ModalProps) {
  const {agreement} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const [closingDate, setClosingDate] = useState<Date | null>(new Date());
  const {api} = useAppContext();
  const form = useFormState();

  const handleClickedClose = async () => {
    if (closingDate === null) return '';

    try {
      await api.updateSplitBillingAgreement(agreement.id, {
        period: {
          to: closingDate.valueOf()
        }
      });
      NotificationManager.success(T('splitBilling.close.success'));
    } catch {
      return T('splitBilling.close.failure');
    }
    return undefined;
  };

  return (
    <SingleActionPromiseModal
      title={T('splitBilling.close.title')}
      action={handleClickedClose}
      actionText={T('splitBilling.close.action')}
      isOpen={isOpen}
      resolve={resolve}
      disabled={closingDate === null}
    >
      <FormContextProvider value={form}>
        <Form>
          <DateInput
            form={form}
            name="to"
            label={T('splitBilling.field.to')}
            value={closingDate}
            onChange={setClosingDate}
            required={true}
          />
        </Form>
      </FormContextProvider>
    </SingleActionPromiseModal>
  );
}
