import * as React from 'react';
import {useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {APISpecificError, isAPIResponse} from '../../../api/APIClient';
import {useAppContext} from '../../../app/context';

import {TabbedModalError} from '../../../modals/TabbedModal';
import {IOrganization} from '../../../models/Organization';
import {getGroupByType} from '../../../models/PricingGroup';
import {IPricingPolicy, ISpecificPricing, PricingPolicyLimits} from '../../../models/PricingPolicy';
import {None} from '../../../utils/Arrays';
import {getCurrencySymbol} from '../../../utils/Currency';
import {ServerErrorCode, translateError} from '../../../utils/Errors';
import {useFormContext} from '../../../utils/FormContext';
import {useLoader} from '../../../utils/Hooks';
import {T} from '../../../utils/Internationalization';

import {useObjectState} from '../../../utils/ObjectState';

import {NumberValue} from '../../inputs/NumberInput';

import {PricingPolicyFormState, SpecificPricingRuleState} from './FormState';

interface PricingPolicyContextProps {
  editingPolicy?: IPricingPolicy;
  formState: PricingPolicyFormState;
  updateFormState: OnChangeHandler;
  savePolicy: () => Promise<TabbedModalError | undefined>;
  organization: Pick<IOrganization, 'id' | 'name' | 'currency' | 'cpo'>;
  currency: string;
  currencySymbol: string;
  specificPricingErrors: APISpecificError[];
  limits?: PricingPolicyLimits;
}

export type OnChangeHandler = (updates: Partial<PricingPolicyFormState>) => void;

const PricingPolicyContext = React.createContext<PricingPolicyContextProps | null>(null);
PricingPolicyContext.displayName = 'PricingPolicyContext';

interface PricingPolicyProviderProps {
  policy?: IPricingPolicy;
  children: React.ReactNode;
  organization: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  onSave?: (policy: IPricingPolicy) => void;
}

const initialState: PricingPolicyFormState = {
  creating: true,
  name: '',
  taxes: NumberValue.none(),
  startingFee: undefined,
  costPerKwh: NumberValue.none(),
  timeComponents: None,
  specificPricingRules: [],
  assignedTo: []
};

function loadSpecificPricing(rule: ISpecificPricing): SpecificPricingRuleState {
  return {
    discountGroup: {
      ...rule.discountGroup,
      name: rule.discountGroup.name ?? getGroupByType(rule.discountGroup.type)?.name
    },
    mode: rule.blocked ? 'blocked' : rule.discountPercentage ? 'discount' : 'tariff',
    discountPercentage: NumberValue.create(rule.discountPercentage),
    tariff:
      rule.tariff === undefined
        ? {
            costPerKwh: NumberValue.none(),
            timeComponents: None
          }
        : {
            startingFee: (rule.tariff.fixedCost ?? 0) > 0 ? NumberValue.create(rule.tariff.fixedCost) : undefined,
            costPerKwh: NumberValue.create(rule.tariff.energyCost),
            timeComponents:
              (rule.tariff.timeComponents?.length ?? 0) > 0
                ? rule.tariff.timeComponents!.map(component => ({
                    afterMinutes: component.afterMinutes,
                    cost: NumberValue.create(component.cost)
                  }))
                : None
          }
  };
}

function createFormState(pricingPolicy: IPricingPolicy): PricingPolicyFormState {
  const result = {
    creating: false,
    startingFee:
      pricingPolicy.current.fixedCost === 0 ? undefined : NumberValue.create(pricingPolicy.current.fixedCost),
    costPerKwh:
      pricingPolicy.current.energyCost === 0 ? undefined : NumberValue.create(pricingPolicy.current.energyCost),
    timeComponents: (pricingPolicy.current.timeComponents || None).map(component => ({
      afterMinutes: component.afterMinutes,
      cost: NumberValue.create(component.cost)
    })),
    name: pricingPolicy.name,
    taxes: NumberValue.create(pricingPolicy.current.taxes),
    specificPricingRules: (pricingPolicy.specificPricings || None).map(loadSpecificPricing),
    assignedTo: pricingPolicy.assignedTo || None
  };
  return result;
}

// import { PricingPolicyProvider } from "path-to-context/PricingPolicyContext"
// use <PricingPolicyProvider> as a wrapper around the part you need the context for
function PricingPolicyProvider({children, policy, organization, onSave}: PricingPolicyProviderProps) {
  const {api} = useAppContext();
  const [formState, updateFormState] = useObjectState(initialState);
  const form = useFormContext();
  const [specificPricingErrors, setSpecificPricingErrors] = useState<APISpecificError[]>([]);
  const [limits] = useLoader(api => api.pricingPolicies.getLimits(organization.id), [organization.id]);

  React.useEffect(() => {
    if (policy?.id) {
      api.pricingPolicies.get(organization.id, policy.id).then(p => updateFormState(createFormState(p)));
    }
  }, [updateFormState, api, organization.id, policy?.id]);

  const handleSave: () => Promise<undefined | TabbedModalError> = React.useCallback(async () => {
    const startingFee = formState.startingFee?.numberValue || 0;
    const costPerKwh = formState.costPerKwh?.numberValue || 0;
    const timeComponents = formState.timeComponents || [];
    form.clearServerErrors();
    setSpecificPricingErrors([]);

    const updatedPolicy: IPricingPolicy = {
      id: policy ? policy.id : 0,
      name: formState.name,
      current: {
        fixedCost: startingFee,
        energyCost: costPerKwh,
        timeComponents: timeComponents.map(component => ({
          afterMinutes: component.afterMinutes,
          cost: component.cost.numberValue || 0
        }))
      },
      specificPricings: formState.specificPricingRules.map(rule => {
        let specificPricing: ISpecificPricing = {
          discountGroup: {
            ...rule.discountGroup,
            name: rule.discountGroup.name ?? getGroupByType(rule.discountGroup.type)?.name
          },
          discountPercentage: rule.mode === 'discount' ? rule.discountPercentage.numberValue || 0 : undefined,
          tariff:
            rule.mode === 'tariff'
              ? {
                  fixedCost: rule.tariff.startingFee?.numberValue || 0,
                  energyCost: rule.tariff.costPerKwh?.numberValue || 0,
                  timeComponents: rule.tariff.timeComponents?.map(component => ({
                    afterMinutes: component.afterMinutes,
                    cost: component.cost.numberValue || 0
                  }))
                }
              : undefined,
          blocked: rule.mode === 'blocked'
        };
        return specificPricing;
      }),
      assignedTo: formState.assignedTo
    };
    try {
      const result = await (policy
        ? api.pricingPolicies.update(organization.id, updatedPolicy)
        : api.pricingPolicies.create(organization.id, updatedPolicy));

      if (!result) {
        // TODO: what can go wrong? Error codes from API?

        return {
          error: 'Error saving pricing policy'
        };
      }

      NotificationManager.success(T('pricingPolicies.add.success'));
      onSave?.(result as IPricingPolicy);
    } catch (error) {
      if (isAPIResponse(error)) {
        const returnedError: TabbedModalError = {
          error: translateError(error)
        };
        if (error.code === ServerErrorCode.RoamingPriceFixedTooHigh) {
          returnedError.tab = 'tariffs';
          returnedError.error = undefined;
          form.setServerError('fixedCost', T('chargingStationConfiguration.fixedTariff.tooHigh'));
        } else if (error.code === ServerErrorCode.RoamingPriceEnergyTooHigh) {
          returnedError.tab = 'tariffs';
          returnedError.error = undefined;
          form.setServerError('energyCost', T('chargingStationConfiguration.energyTariff.tooHigh'));
        } else if (error.code === ServerErrorCode.RoamingPriceHourlyTooHigh) {
          returnedError.tab = 'tariffs';
          returnedError.error = undefined;
          form.setServerError('hourlyCost', T('chargingStationConfiguration.timeTariff.tooHigh'));
        } else if (error.code === ServerErrorCode.SpecificPricingInvalid) {
          returnedError.tab = 'specific';
          setSpecificPricingErrors(error.errors || []);
        } else if (error.code === ServerErrorCode.SortTimedComponents) {
          returnedError.tab = 'tariffs';
          returnedError.error = undefined;
          form.setServerError('hourlyCost', T('pricingPolicies.error.timedComponentsSorted'));
        }
        console.log(returnedError);
        return returnedError;
      } else {
        return {
          error: 'Error saving pricing policy'
        };
      }
    }
    return undefined;
  }, [
    formState.startingFee?.numberValue,
    formState.costPerKwh?.numberValue,
    formState.timeComponents,
    formState.name,
    formState.specificPricingRules,
    formState.assignedTo,
    policy,
    api.pricingPolicies,
    organization.id,
    onSave,
    form
  ]);

  const currencySymbol = React.useMemo(
    () => getCurrencySymbol(organization.currency || 'EUR'),
    [organization.currency]
  );

  return (
    <PricingPolicyContext.Provider
      value={{
        editingPolicy: policy,
        formState,
        updateFormState,
        savePolicy: handleSave,
        organization,
        currency: organization.currency || 'EUR',
        currencySymbol,
        specificPricingErrors,
        limits
      }}
    >
      {children}
    </PricingPolicyContext.Provider>
  );
}

// import { usePricingPolicy } fron "path-to-context/PricingPolicyContext"
// within functional component
// const { sessionToken, ...PricingPolicyContext } = usePricingPolicy()
function usePricingPolicy(): PricingPolicyContextProps {
  const context = React.useContext(PricingPolicyContext);

  if (!context) {
    throw new Error('You should use usePricingPolicy within an PricingPolicyContext');
  }

  return context;
}

export {PricingPolicyProvider, usePricingPolicy};
