import React from 'react';
import {UncontrolledTooltip, Table} from 'reactstrap';

import {IconButton} from '../../components/IconButton';
import {IApplianceType} from '../../models/ApplianceType';
import {IChannelUpdates, CircuitType} from '../../models/Channel';
import {IDevice} from '../../models/Device';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {Load, LoadType, ILoadUpdates, LoadSource} from '../../models/Load';
import {ILocationSummary} from '../../models/Location';
import {T} from '../../utils/Internationalization';
import {classes} from '../../utils/Styles';
import {testingClasses} from '../../utils/TestingClasses';

import {MaxCapacitySettings} from './CapacitySettings';
import {ChannelConfigurator} from './ChannelConfigurator';
import styles from './EditConfig.module.scss';
import {HighLevelNameInput} from './inputs/HighLevelNameInput';
import {HighLevelOptionsSelector} from './inputs/HighLevelOptionsSelector';
import {HighLevelTypeSelector} from './inputs/HighLevelTypeSelector';
import {MeasuredInput} from './inputs/MeasuredInput';
import {MaximumLoadSettings} from './MaximumLoadSettings';

interface LoadConfiguratorProps {
  load: Load;
  midAddress?: number;
  groupIndex: number;
  readOnly: boolean;
  phaseType: PhaseType;
  applianceTypes: IApplianceType[];
  ctHubs: IDevice[];
  errors: {[key: string]: string};
  location: ILocationSummary;
  onClickedDelete: (load: Load) => void;
  updateChannel: (loadId: number, channelId: number, channel: IChannelUpdates) => void;
  updateLoad: (loadId: number, load: ILoadUpdates) => void;
}

function OptionsColumn({
  load,
  location,
  readOnly,
  phaseType,
  applianceTypes,
  onChange
}: {
  load: Load;
  location: ILocationSummary;
  readOnly: boolean;
  applianceTypes: IApplianceType[];
  phaseType: PhaseType;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}) {
  if (load.type === LoadType.Grid || load.type === LoadType.VirtualGrid) {
    return (
      <>
        <div>
          <MaxCapacitySettings location={location} />
        </div>
        <div>
          <MaximumLoadSettings location={location} phaseType={phaseType} />
        </div>
      </>
    );
  }

  return (
    <HighLevelOptionsSelector readOnly={readOnly} load={load} applianceTypes={applianceTypes} onChange={onChange} />
  );
}

export const LoadConfigurator = (props: LoadConfiguratorProps) => {
  const {
    load,
    midAddress,
    groupIndex,
    readOnly,
    phaseType,
    applianceTypes,
    errors,
    updateChannel,
    updateLoad,
    ctHubs,
    location,
    onClickedDelete
  } = props;

  const handleNameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    updateLoad(load.id, {name: e.currentTarget.value});
  };

  const handleOptionsChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (load.type === LoadType.Appliance) {
      updateLoad(load.id, {applianceType: e.currentTarget.value});
    } else if (load.type === LoadType.Subcircuit) {
      updateLoad(load.id, {circuitType: e.currentTarget.value as CircuitType});
    } else if (load.type === LoadType.Production) {
      updateLoad(load.id, {withHybridInverter: e.currentTarget.checked ?? false});
    }
  };

  const handleChangedMeasuredByGrid = (checked: boolean) => {
    updateLoad(load.id, {measuredByGrid: checked});
  };

  const handleClickedDelete = () => {
    onClickedDelete(load);
  };

  const {id, name, type, source} = load;
  const tooltipId = `error-${id}`;
  const errorText = errors.name || errors.type;

  const row1 = (
    <tr className={classes(styles.rowSpacing, testingClasses.loadRow)} data-testid={testingClasses.loadRow}>
      {load.type !== LoadType.VirtualGrid && (
        <>
          <td>
            {groupIndex}

            {errorText ? (
              <>
                <span
                  id={tooltipId}
                  className="fal fa-exclamation"
                  style={{display: 'inline-block', paddingLeft: 5, color: 'red'}}
                />
                <UncontrolledTooltip placement="left" target={tooltipId}>
                  {T('capacityProtection.error')}
                </UncontrolledTooltip>
              </>
            ) : null}
          </td>
          <td>
            {source === LoadSource.MID
              ? T('ctConfiguration.source.mid', {
                  midAddress: midAddress === undefined ? '' : midAddress.toString()
                })
              : T('ctConfiguration.source.ct')}
          </td>
          <td>
            <HighLevelNameInput
              readOnly={readOnly}
              value={name || ''}
              load={load}
              invalid={!!errors.name}
              onChange={handleNameChanged}
            />
          </td>
          <td>
            <HighLevelTypeSelector readOnly={readOnly} value={type} invalid={!!errors.type} />
          </td>
          <td className={styles.optionsColumn} colSpan={load.type === LoadType.Grid ? 2 : undefined}>
            <OptionsColumn
              location={location}
              readOnly={readOnly}
              load={load}
              phaseType={phaseType}
              applianceTypes={applianceTypes}
              onChange={handleOptionsChanged}
            />
          </td>
        </>
      )}
      {load.type === LoadType.VirtualGrid && (
        <>
          <td className={styles.parentWrapper}>
            <div className={styles.virtualGridParent}>{name}</div>
          </td>
          <td className={styles.optionsColumn}>
            <OptionsColumn
              location={location}
              readOnly={readOnly}
              load={load}
              phaseType={phaseType}
              applianceTypes={applianceTypes}
              onChange={handleOptionsChanged}
            />
          </td>
        </>
      )}

      {load.type !== LoadType.Grid && load.type !== LoadType.VirtualGrid && (
        <td className={styles.sensorReversed}>
          {type !== LoadType.Grid ? (
            <MeasuredInput readOnly={readOnly} value={load.isMeasuredByGrid()} onChange={handleChangedMeasuredByGrid} />
          ) : null}
        </td>
      )}
      <td>
        {!readOnly && load.type !== LoadType.VirtualGrid ? (
          <IconButton
            action="delete"
            icon="Trash"
            color="link"
            onClick={handleClickedDelete}
            style={{position: 'relative', bottom: '0.1em'}}
          />
        ) : null}
      </td>
    </tr>
  );

  // Create sub rows
  const subRows = load.channels.map(channel => (
    <ChannelConfigurator
      key={channel.id}
      source={load.source}
      channel={channel}
      phaseType={phaseType}
      readOnly={readOnly}
      updateChannel={updateChannel}
      ctHubs={ctHubs}
    />
  ));

  const hasChannels = load.source !== LoadSource.MID || load.channels.length !== 3;

  const row2 = hasChannels && (
    <tr className={testingClasses.loadCTsRow} data-load={groupIndex} data-testid={testingClasses.loadCTsRow}>
      <td>&nbsp;</td>
      <td colSpan={5}>
        <Table className={styles.subRows} size="sm">
          <thead className={styles.subRowHeader}>
            <tr>
              <th>{T('ctConfiguration.channel.ctHub')}</th>
              <th>{T('ctConfiguration.channel.location')}</th>
              <th>{T('liveElectricityValues.configuration.phase')}</th>
              <th>{T('liveElectricityValues.configuration.ctType')}</th>
              <th className={styles.sensorReversed}>{T('liveElectricityValues.configuration.reversed')}</th>
            </tr>
          </thead>
          <tbody>{subRows}</tbody>
        </Table>
      </td>
    </tr>
  );

  return (
    <>
      {row1}
      {load.type !== LoadType.VirtualGrid ? row2 : null}
    </>
  );
};
