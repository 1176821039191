export const enum MessageType {
  Custom = '__CUSTOM__',

  Dashboard = 'DASHBOARD',
  Survey = 'SURVEY',
  Usages = 'USAGES',
  Tariffs = 'TARIFFS',
  Appliances = 'APPLIANCES',
  ApplianceDetail = 'APPLIANCE_DETAIL',
  Control = 'CONTROL',
  SmartDevices = 'SMART_DEVICES',
  SmartDeviceDetail = 'SMART_DEVICE_DETAIL',
  SmartCharging = 'SMART_CHARGING',
  Automations = 'AUTOMATIONS',
  AutomationDetail = 'AUTOMATION_DETAIL',
  Events = 'EVENTS',
  Notifications = 'NOTIFICATIONS',
  Scoreboard = 'SCOREBOARD',
  LocationDetail = 'LOCATION_DETAIL',
  AppStore = 'APPSTORE',
  Subscription = 'SUBSCRIPTION',
  LoadConfiguration = 'LOAD_CONFIGURATION',
  InfinityModules = 'INFINITY_MODULES',
  InfinityModuleDetail = 'INFINITY_MODULE_DETAIL',
  Scenes = 'SCENES',
  SceneDetail = 'SCENE_DETAIL'
}

export interface IPushMessageContext {
  notificationType: MessageType;
  notificationValue: string;
  notificationValueId: string;
  notificationSerialNumber: string;
}
