import React, {useCallback, useMemo} from 'react';

import {Input} from '../../../components/bootstrap';
import FormInputGroup from '../../../components/inputs/FormInputGroup';
import {CardDisplayType} from '../../../models/CardSettings';
import {T} from '../../../utils/Internationalization';
import {testingClasses} from '../../../utils/TestingClasses';

function getDisplayTypeName(type: CardDisplayType) {
  switch (type) {
    case CardDisplayType.Chart:
      return T('card.displayType.chart');
    case CardDisplayType.Statistics:
      return T('card.displayType.statistics');
    case CardDisplayType.Table:
      return T('card.displayType.table');
    case CardDisplayType.Map:
      return T('card.displayType.map');
    default:
      return '';
  }
}

interface CardTypeSelectorProps {
  value: CardDisplayType;
  onChange: (type: CardDisplayType) => void;
  options?: CardDisplayType[];
}
const DEFAULT_OPTIONS = [CardDisplayType.Table, CardDisplayType.Chart];
export const CardTypeSelector = (props: CardTypeSelectorProps) => {
  const {value, onChange, options = DEFAULT_OPTIONS} = props;
  const handleChanged = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value as CardDisplayType);
    },
    [onChange]
  );
  const selectOptions = useMemo(
    () =>
      options.map(option => (
        <option key={option} value={option}>
          {getDisplayTypeName(option)}
        </option>
      )),
    [options]
  );

  return (
    <FormInputGroup name="display-type" label={T('card.displayType.label')}>
      <Input
        className={testingClasses.cardTypeInput}
        type="select"
        value={value}
        onChange={handleChanged}
        data-testid={testingClasses.cardTypeInput}
      >
        {selectOptions}
      </Input>
    </FormInputGroup>
  );
};
