export const enum ControllableNodeType {
  Chacon = 0,
  Elro = 1,
  Advanced = 2,
  SmappeeSwitch = 3
}

export interface IControllableNode {
  id: number;
  name: string;
  code: string;
  functionMask: number;
  type: ControllableNodeType;
  lastAction: number;
  status: number;
  monitorId: number;
  serialNumber: string;
  acceleroEnabled: false;
  category: string;
  learn: {
    status: 'ON' | 'OFF';
  };
  favourite: boolean;
}

export interface IHomeControlDevices {
  controllableNodes: IControllableNode[]; // switches and plugs
  thermostats: {id: number; name: string}[];
  batteries: {id: number; name: string}[];
  outputModuleControls: {id: number; name: string}[];
}
