import type {SVGProps} from 'react';

const SvgAreaChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    fillRule="evenodd"
    {...props}
  >
    <path d="M7.84427 8.151L5.09586 13.4016C4.108 15.2888 5.56053 15.8248 6.41028 15.8569H20.9383C23.6151 15.8296 23.1235 14.2087 22.543 13.4016L18.497 6.51424C17.2815 4.44105 16.0558 5.44593 15.5949 6.20752L14.1779 9.10574L10.3368 7.70767C9.20445 7.29636 8.28208 7.31586 7.84427 8.151Z" />
    <path d="M2.09838 2.5C1.49318 2.5 1 2.98994 1 3.59431V20.4057C1 20.7076 1.12303 20.9809 1.3216 21.1788C1.51985 21.3771 1.79353 21.5 2.0958 21.5H21.9042C22.5094 21.5 23 21.0075 23 20.4031C23 19.7988 22.5094 19.3062 21.9042 19.3062H3.19675V3.59431C3.19675 2.98994 2.70357 2.5 2.09838 2.5Z" />
  </svg>
);
export default SvgAreaChart;
