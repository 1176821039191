import {IConsumptionValue} from '../../models/ConsumptionValue';

import {IHistoricalDataChannel} from '../../models/HistoricalChannel';
import {Interval} from '../../models/UsageValue';
import {getPowerFactor} from '../../utils/Calculations';

import {electricityColumnIds} from './ElectricityValuesColumns';
import {Series, SeriesElement} from './Series';

function findChildIndex(data: IConsumptionValue[], childId: number): number | undefined {
  for (var row of data) {
    if (row.childData === undefined) continue;

    const index = row.childData.findIndex(x => x.serviceLocationId === childId);
    if (index !== undefined) return index;
  }
}

export class ElectricityChartData {
  data: IConsumptionValue[];
  bigConsumer: boolean;
  powerMultiplier: number;
  cache: Map<string, Series> = new Map<string, Series>();
  from: number;
  to: number;
  interval: Interval;

  constructor(data: IConsumptionValue[], bigConsumer: boolean, from: number, to: number, interval: Interval) {
    this.data = data;
    this.bigConsumer = bigConsumer;
    this.powerMultiplier = this.bigConsumer ? 0.001 : 1;
    this.from = from;
    this.to = to;
    this.interval = interval;
  }

  isEmpty() {
    return this.data.length === 0;
  }

  getConsumption(): Series {
    const id = electricityColumnIds.consumption;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.value === undefined ? null : row.value * this.powerMultiplier
      ]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getAlwaysOn(): Series {
    const id = electricityColumnIds.alwaysOn;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.alwaysOn === undefined ? null : row.alwaysOn * this.powerMultiplier
      ]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getSolar(): Series {
    const id = electricityColumnIds.solar;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.solar === undefined ? null : row.solar * this.powerMultiplier
      ]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getSolarForecast(): Series {
    const id = electricityColumnIds.solarForecast;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => {
        const value = row.solarForecast;
        const scaledValue = value === undefined ? null : value * this.powerMultiplier;
        return [row.timestamp, scaledValue];
      });
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getConsumptionForecast(): Series {
    const id = electricityColumnIds.consumptionForecast;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => {
        const value = row.loadForecast;
        const scaledValue = value === undefined ? null : value * this.powerMultiplier;
        return [row.timestamp, scaledValue];
      });
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getImport(): Series {
    const id = electricityColumnIds.import;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.import === undefined ? null : row.import * this.powerMultiplier
      ]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getExport(): Series {
    const id = electricityColumnIds.export;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.export === undefined ? null : row.export * this.powerMultiplier
      ]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getSelfConsumption(): Series {
    const id = electricityColumnIds.selfConsumption;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.selfConsumption === undefined ? null : row.selfConsumption
      ]);
      return Series.from(seriesData, {min: 0, max: 100});
    });
  }

  getSelfSufficiency(): Series {
    const id = electricityColumnIds.selfSufficiency;
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.selfSufficiency === undefined ? null : row.selfSufficiency
      ]);
      return Series.from(seriesData, {min: 0, max: 100});
    });
  }

  getLineCurrent(phase: number): Series {
    const id = electricityColumnIds.lineCurrent(phase);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [row.timestamp, (row.current || [])[phase]]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getActive(channel: IHistoricalDataChannel): Series {
    const id = electricityColumnIds.activeForChannel(channel.id);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [row.timestamp, channel.getActivePower(row)]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getReactive(channel: IHistoricalDataChannel): Series {
    const id = electricityColumnIds.reactiveForChannel(channel.id);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [row.timestamp, channel.getReactivePower(row)]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getCurrent(load: IHistoricalDataChannel): Series {
    const id = electricityColumnIds.currentForLoad(load.id);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [row.timestamp, load.getCurrent(row)]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getCurrentMinMaxForChannel(channel: IHistoricalDataChannel): Series {
    const id = electricityColumnIds.currentMinMaxForChannel(channel.id);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => {
        const [min, max] = channel.getCurrentMinMax(row);
        return [row.timestamp, min, max];
      });
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getApparent(channel: IHistoricalDataChannel): Series {
    const id = electricityColumnIds.apparentForChannel(channel.id);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [row.timestamp, channel.getApparentPower(row)]);
      return Series.from(seriesData, {minUpperLimit: 0});
    });
  }

  getPowerFactor(channel: IHistoricalDataChannel): Series {
    const id = electricityColumnIds.powerFactorForChannel(channel.id);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => {
        const activePower = channel.getActivePower(row);
        const reactivePower = channel.getReactivePower(row);
        if (activePower === null || reactivePower === null) {
          return [row.timestamp, null];
        }

        const pf = getPowerFactor(activePower, reactivePower);
        return [row.timestamp, pf === undefined ? null : pf];
      });
      return Series.from(seriesData);
    });
  }

  getChannelPhaseVoltage(channel: IHistoricalDataChannel): Series {
    const id = electricityColumnIds.voltageForChannel(channel.id);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [row.timestamp, channel.getPhaseVoltage(row)]);
      return Series.from(seriesData);
    });
  }

  getPhaseVoltage(phaseIndex: number): Series {
    const id = electricityColumnIds.phaseVoltage(phaseIndex);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.phaseVoltages === undefined ? null : row.phaseVoltages[phaseIndex]
      ]);
      return Series.from(seriesData);
    });
  }

  getPhaseVoltageForChild(childId: number, phaseIndex: number): Series {
    const id = electricityColumnIds.phaseVoltageForChild(childId, phaseIndex);
    return this._getCached(id, () => {
      const childIndex = findChildIndex(this.data, childId);
      const seriesData: SeriesElement[] = this.data.map(row => {
        if (childIndex === undefined) return [row.timestamp, null];

        const childData = (row.childData || [])[childIndex];
        const childPhaseVoltages = childData && childData.phaseVoltages;
        return [row.timestamp, childPhaseVoltages === undefined ? null : childPhaseVoltages[phaseIndex]];
      });
      return Series.from(seriesData);
    });
  }

  getPhaseVoltageMinMax(phaseIndex: number): Series {
    const id = electricityColumnIds.phaseVoltagesMinMax(phaseIndex);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.phaseVoltagesMin === undefined ? null : row.phaseVoltagesMin[phaseIndex],
        row.phaseVoltagesMax === undefined ? null : row.phaseVoltagesMax[phaseIndex]
      ]);
      return Series.from(seriesData);
    });
  }

  getLineVoltage(phaseIndex: number): Series {
    const id = electricityColumnIds.lineVoltage(phaseIndex);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.lineVoltages === undefined ? null : row.lineVoltages[phaseIndex]
      ]);
      return Series.from(seriesData);
    });
  }

  getLineVoltageForChild(childId: number, phaseIndex: number): Series {
    const id = electricityColumnIds.lineVoltageForChild(childId, phaseIndex);
    return this._getCached(id, () => {
      const childIndex = findChildIndex(this.data, childId);
      const seriesData: SeriesElement[] = this.data.map(row => {
        if (childIndex === undefined) return [row.timestamp, null];

        const childData = (row.childData || [])[childIndex];
        const childLineVoltages = childData && childData.lineVoltages;
        return [row.timestamp, childLineVoltages === undefined ? null : childLineVoltages[phaseIndex]];
      });
      return Series.from(seriesData);
    });
  }

  getLineVoltageMinMax(phaseIndex: number): Series {
    const id = electricityColumnIds.lineVoltageMinMax(phaseIndex);
    return this._getCached(id, () => {
      const seriesData: SeriesElement[] = this.data.map(row => [
        row.timestamp,
        row.lineVoltagesMin === undefined ? null : row.lineVoltagesMin[phaseIndex],
        row.lineVoltagesMax === undefined ? null : row.lineVoltagesMax[phaseIndex]
      ]);
      return Series.from(seriesData);
    });
  }

  _getCached(id: string, generator: () => Series): Series {
    const cached = this.cache.get(id);
    if (cached !== undefined) return cached;

    const series = generator();
    this.cache.set(id, series);
    return series;
  }

  _getScaledPower(power: number | null) {
    return power === null ? null : power * this.powerMultiplier;
  }
}
