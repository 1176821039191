import React, {useState, useMemo, useCallback} from 'react';

import {OnlineStatusIndicator} from '../../components/OnlineStatusIndicator';
import {useLiveValues} from '../../livedata/LiveData';
import {IPowerMessage, IInputSensorDailyDeltas} from '../../livedata/LiveDataModels';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {getDeviceIconFor, getInternalTypeLabelFor, getDeviceTypeFromSerial, DeviceType} from '../../models/DeviceType';
import {IGasWaterDevice, LocationFeature, getChannelTypeLabel, isReadOnly} from '../../models/Location';
import {PlugType} from '../../models/Switch';
import {None} from '../../utils/Arrays';
import {useSensors, useLocationModules, useLocationSwitches, useChildLocations} from '../../utils/FunctionalData';
import {useAppSelector} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useCardLocationDetails} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';
import {SwitchConnectionStatus} from '../components/OnlineStatus';

import {CollapseCaret} from './CollapseCaret';

import {LocationDevices} from './LocationDevices';

type DeviceOverviewSettings = ICardSettings;

function getInputModuleState(message: IPowerMessage) {
  const dailyDeltas = message.infinityInputSensorDailyDeltas as IInputSensorDailyDeltas[] | undefined;
  if (dailyDeltas === undefined) return new Map<number, number[]>();

  const inputModuleStates = new Map<number, number[]>();
  for (let deltas of dailyDeltas) {
    inputModuleStates.set(deltas.sensorId, deltas.states || []);
  }
  return inputModuleStates;
}

const NoInputModuleValues = new Map<number, number[]>();

function getInputChannelType(sensor: IGasWaterDevice, channelIndex: number) {
  const channel = sensor.inputChannels[channelIndex];
  if (channel === undefined) return T('device.channelType.notConnected');

  return getChannelTypeLabel(channel.type);
}

const DeviceOverview = (props: ICardProps<DeviceOverviewSettings>) => {
  const {fetch, settings} = props;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationDetails = useCardLocationDetails(settings);
  const isFeatureAvailable = locationDetails && locationDetails.features.includes(LocationFeature.SmappeeMonitors);
  const me = useAppSelector(state => state.me.me);
  const readOnly = isReadOnly(me, location);

  const [modules = None, refreshModules] = useLocationModules(fetch, locationId);
  const [switches, refreshSwitches] = useLocationSwitches(fetch, locationId);
  const [childs, refreshChilds] = useChildLocations(fetch, location);

  const allSensors = useSensors(fetch, locationId) || None;
  const sensors = useMemo(
    () => allSensors.filter(sensor => getDeviceTypeFromSerial(sensor.serialNumber) === DeviceType.GasWater),
    [allSensors]
  );

  const [liveValues, status] = useLiveValues(location);
  const inputModuleStates = useMemo(
    () => (liveValues ? getInputModuleState(liveValues) : NoInputModuleValues),
    [liveValues]
  );

  const [expanded, setExpanded] = useState<string[]>([]);

  const handleRefresh = useCallback(() => {
    refreshSwitches();
    refreshModules();
    refreshChilds();
  }, [refreshChilds, refreshModules, refreshSwitches]);

  const deviceType = location ? location.deviceType : undefined;
  const deviceRows: JSX.Element[] = useMemo(() => {
    if (deviceType === undefined) return [];

    const toggleCollapse = (serialNumber: string) => {
      const index = expanded.indexOf(serialNumber);
      if (index >= 0) {
        setExpanded(expanded.filter(serial => serial !== serialNumber));
      } else setExpanded([...expanded, serialNumber]);
    };

    const renderExpandedSensor = (sensor: IGasWaterDevice): JSX.Element => {
      const sensorDeviceType = getDeviceTypeFromSerial(sensor.serialNumber);
      return (
        <tr
          key={`sensor-${sensor.id}-details`}
          className={testingClasses.moduleDetails}
          data-testid={testingClasses.moduleDetails}
        >
          <td colSpan={2} style={{paddingLeft: '2em'}}>
            <table>
              <tbody>
                <tr>
                  <td>{T('deviceOverview.deviceType')}: </td>
                  <td>{getInternalTypeLabelFor(sensorDeviceType)}</td>
                </tr>
                {sensor.inputChannels.map((channel, index) => (
                  <tr key={`channel-${channel.id}`}>
                    <td>
                      {T('deviceOverview.channelX', {
                        index: (index + 1).toString()
                      })}
                      :
                    </td>
                    <td>{getInputChannelType(sensor, index)}</td>
                  </tr>
                ))}
                <tr>
                  <td>{T('deviceOverview.connectedVia')}: </td>
                  <td>
                    {sensor.standAlone
                      ? T('deviceOverview.connectedViaBluetooth')
                      : getInternalTypeLabelFor(deviceType || DeviceType.UNKNOWN)}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      );
    };

    const elements: JSX.Element[] = [];
    switches.forEach(switch_ => {
      const icon =
        switch_.type === PlugType.Switch ? getDeviceIconFor(DeviceType.Switch) : '/assets/devices/smappee_plug.svg';
      elements.push(
        <tr key={`switch-${switch_.id}`}>
          <td>
            <img src={icon} className="tw-w-6 tw-inline-flex tw-mr-2" alt="" />
            {switch_.name || getInternalTypeLabelFor(DeviceType.Switch)}
          </td>
          <td>
            {switch_.serialNumber}
            {switch_.status && <SwitchConnectionStatus status={switch_.status} />}
          </td>
        </tr>
      );
    });
    sensors.forEach(sensor => {
      const deviceType = getDeviceTypeFromSerial(sensor.serialNumber);
      const expandedId = `S${sensor.serialNumber}`;
      elements.push(
        <tr key={`sensor-${sensor.id}`}>
          <td>
            <img src={getDeviceIconFor(deviceType)} className="tw-w-6 tw-inline-flex tw-mr-2" alt="" />
            {sensor.name || getInternalTypeLabelFor(deviceType)}
          </td>
          <td>
            {sensor.serialNumber}
            &nbsp;
            <CollapseCaret expanded={expanded.includes(expandedId)} onClicked={() => toggleCollapse(expandedId)} />
          </td>
        </tr>
      );
      if (expanded.includes(expandedId)) {
        elements.push(renderExpandedSensor(sensor));
      }
    });
    return elements;
  }, [deviceType, sensors, switches, expanded]);

  const childRows = useMemo(() => {
    return childs.map(child => (
      <LocationDevices location={child} devices={child.devices || None} readOnly={readOnly} refresh={refreshChilds} />
    ));
  }, [childs, readOnly, refreshChilds]);

  const actions: CustomActions = () => (
    <CardActions>{isFeatureAvailable && <Reload onReload={handleRefresh} />}</CardActions>
  );

  let error: string | undefined;
  if (!isFeatureAvailable) {
    error = T('deviceOverview.error.noDeviceOverview');
  }

  return (
    <CardView error={error} actions={actions} ready={liveValues !== undefined} {...cardViewProps(props)}>
      <div style={{overflowY: 'auto', height: '100%'}}>
        <table style={{width: '100%'}}>
          <tbody>
            {location && (
              <LocationDevices
                location={location}
                devices={modules}
                readOnly={readOnly}
                liveValues={liveValues}
                onlineStatus={<OnlineStatusIndicator status={status} />}
                refresh={refreshModules}
              />
            )}
            {childRows}
            {deviceRows}
          </tbody>
        </table>
      </div>
    </CardView>
  );
};

const CARD: ICardType<DeviceOverviewSettings> = {
  type: CardTypeKey.DeviceOverview,
  title: 'deviceOverview.title',
  description: 'deviceOverview.description',
  categories: [CardCategory.CONFIGURATION, CardCategory.LOCATIONS],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: DeviceOverview
};
export default CARD;
