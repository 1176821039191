import React from 'react';

import {Button as RsButton} from 'reactstrap';

import {Icon} from '../../../components/Icon';
import {ICustomFile} from '../../../models/CustomFile';
import {T} from '../../../utils/Internationalization';

interface ICustomFilesProps {
  files: ICustomFile[];
  readOnly: boolean;
  onClickedRemove: (file: ICustomFile) => void;
  onClickedAdd: () => void;
}
export const CustomFilesField = (props: ICustomFilesProps) => {
  const {files, readOnly, onClickedAdd, onClickedRemove} = props;
  const fileViews = files.map(file => (
    <a key={file.url} href={file.url} className="btn btn-secondary btn-sm" style={{marginRight: 10}}>
      {file.tag || T('locationConfiguration.customFile.noName')}{' '}
      {!readOnly && (
        <i
          className={Icon.SolidTimesCircle}
          onClick={e => {
            e.preventDefault();
            onClickedRemove(file);
          }}
        />
      )}
    </a>
  ));
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      {fileViews}
      {!readOnly && (
        <RsButton color="edit" size="sm" onClick={onClickedAdd}>
          {T('locationConfiguration.customFile.add')}
        </RsButton>
      )}
    </div>
  );
};
