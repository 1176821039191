import React, {useMemo} from 'react';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../components/ui/select';
import {PhaseType} from '../../../models/HighLevelConfiguration';
import {Phase, getPhaseLabel, PHASES} from '../../../models/Phase';

interface PhaseSelectorProps {
  readOnly: boolean;
  invalid: boolean;
  value: string;
  phaseType: PhaseType;
  onChange: (phase: string) => void;
}
export const PhaseSelector = (props: PhaseSelectorProps) => {
  const {readOnly, invalid, value, phaseType, onChange} = props;

  const phaseOptions = useMemo(
    () =>
      phaseType === PhaseType.Single
        ? [
            <SelectItem key={Phase.L1} value={Phase.L1}>
              {getPhaseLabel(phaseType, Phase.L1)}
            </SelectItem>
          ]
        : PHASES.map(type => (
            <SelectItem key={type} value={type}>
              {getPhaseLabel(PhaseType.Star, type)}
            </SelectItem>
          )),
    [phaseType]
  );

  return (
    <Select
      name="phase"
      value={value}
      onValueChange={onChange}
      invalid={invalid}
      disabled={readOnly || phaseType === PhaseType.Single}
    >
      <SelectTrigger className="tw-pl-1 !tw-text-base">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>{phaseOptions}</SelectGroup>
      </SelectContent>
    </Select>
  );
};
