import {useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {useNumberInput} from '../../components/inputs/NumberInput';
import {useTextInput} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IChargingPriority} from '../../models/ChargingPriorities';
import {T} from '../../utils/Internationalization';

interface EditTokenPriorityProps extends IPromiseModalProps<IChargingPriority[] | null> {
  locationId: number;
  rule: IChargingPriority;
}

export function EditTokenPriority(props: EditTokenPriorityProps) {
  const {locationId, rule} = props;
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const onToggle = () => resolve(null);

  const [addFailure, setAddFailure] = useState(false);

  const [priorityInput, priority] = useNumberInput(
    'priority',
    T('chargingStationPrivileges.column.priority'),
    rule.priority || 0,
    0,
    100,
    '%',
    {autoFocus: true}
  );

  const [commentInput, comment] = useTextInput(
    'comment',
    T('chargingStationPrivileges.column.comment'),
    rule.comment || ''
  );

  const handleClickSave = () => {
    if (priority === null) return Promise.resolve(null);

    setAddFailure(false);
    return api.chargingPriorities
      .updateChargingPriority(locationId, {
        ...rule,
        priority,
        comment
      })
      .then(updatedList => {
        NotificationManager.success(
          T('chargingStationPriorities.edit.success', {
            name: rule.token || T('chargingStationPriorities.target.location')
          })
        );
        resolve(updatedList);
      })
      .catch(() => setAddFailure(true));
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={onToggle}
      title={T('chargingStationPriorities.edit.title', {
        name: rule.token || T('chargingStationPriorities.target.location')
      })}
      action={handleClickSave}
      actionText={T('chargingStationPriorities.edit.save')}
      error={addFailure ? T('chargingStationPriorities.edit.failed') : undefined}
    >
      <div>
        {priorityInput}
        {commentInput}
      </div>
    </SingleActionModal>
  );
}
