import {useState} from 'react';

import {getLastSelectedPrinter} from '../models/Printer';

import printers from './Printers.json';

export interface IPrinter {
  name: string;
  displayName: string;

  host: string;
  port: number;
  username: string;
  password: string;
  directory: string;
}

export const Printers: IPrinter[] = printers;

export function getPrinter(name: string) {
  return printers.find(p => p.name === name);
}

export function usePrinter(): [string, (printer: string) => void] {
  return useState(getLastSelectedPrinter() || 'jan');
}
