import React, {useMemo} from 'react';

import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {useModals} from '../../../modals/ModalContext';
import {Address, Countries} from '../../../models/Countries';
import {T} from '../../../utils/Internationalization';

import {EditAddressModal} from './EditAddressModal';

interface AddressEditorProps {
  value: Address | undefined;
  onChanged: (address: Address | undefined) => Promise<unknown>;
  readOnly: boolean;
}
export function AddressEditor(props: AddressEditorProps) {
  const {value, onChanged, readOnly} = props;
  const modals = useModals();

  const country = useMemo(() => value && Countries.find(c => c.code === value.countryCode), [value]);
  const address = country && value ? `${country.formatAddressSingleLine(value)}, ${country.translate()}` : '';

  const handleClickedEdit = () => {
    modals.show(props => (
      <EditAddressModal address={value || null} onChange={v => onChanged(v || undefined)} {...props} />
    ));
  };

  return (
    <span>
      {address || T('generic.na')}
      &nbsp;
      {!readOnly && (
        <Button variant="ghost_action_btn" size="icon" onClick={handleClickedEdit}>
          <Edit />
        </Button>
      )}
    </span>
  );
}
