type Fetch = (url: string, options?: any) => Promise<any>;

type Options = {
  baseUrl: string;
  cookie?: string;
  token?: string;
  signal?: AbortSignal;
};

/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
function createFetch(fetch: Fetch, {baseUrl}: Options): (url: string, options: any) => Promise<any> {
  // NOTE: Tweak the default options to suite your application needs
  const defaults = {
    method: 'POST', // handy with GraphQL backends
    mode: baseUrl ? 'cors' : 'same-origin',
    credentials: baseUrl ? 'include' : 'same-origin',
    headers: {
      Accept: 'application/json'
    }
  };

  return (url: string, options: any) => {
    if (url.startsWith('/api') || url.startsWith('/metering') || url.startsWith('/login')) {
      const newOptions = {
        ...defaults,
        ...options,
        headers: {
          ...defaults.headers,
          ...(options && options.headers)
        }
        //signal
      };
      return fetch(`${baseUrl}${url}`, newOptions);
    } else {
      return fetch(url, options);
    }
  };
}

export default createFetch;
