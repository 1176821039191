import * as React from 'react';

import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {TabbedModal} from '../../../modals/TabbedModal';
import {IOrganization} from '../../../models/Organization';
import {IPricingGroup} from '../../../models/PricingGroup';
import {T} from '../../../utils/Internationalization';

import {Alert} from '../../bootstrap';

import {BasicGroupConfig} from './BasicGroupConfig';
import {DetailGroupConfig} from './DetailGroupConfig';
import {PricingGroupProvider, usePricingGroup} from './PricingGroupProvider';

function Disclaimer() {
  return (
    <Alert color="info">
      <p>{T('pricingGroups.add.disclaimer')}</p>
    </Alert>
  );
}

interface ConfigurePricingGroupContentProps {
  isOpen: boolean;
  close: () => void;
}
function ConfigurePricingGroupContent(props: ConfigurePricingGroupContentProps) {
  const {isOpen, close} = props;

  const {pricingGroup, saveGroup} = usePricingGroup();
  const isEditing = !!pricingGroup?.id;

  const tabs = React.useMemo(
    () => [
      {
        id: 'details',
        title: T('pricingGroups.add.details'),
        render: () => (
          <>
            <Disclaimer />
            <DetailGroupConfig />
          </>
        )
      },
      {
        id: 'general',
        title: T('pricingGroups.add.basic'),
        render: () => (
          <>
            <Disclaimer />
            <BasicGroupConfig />
          </>
        )
      }
    ],
    []
  );

  return (
    <TabbedModal
      isOpen={isOpen}
      toggle={close}
      wizard={!isEditing}
      title={isEditing ? pricingGroup.name : T('pricingGroups.add.title')}
      tabs={tabs}
      saveButtonLabel={isEditing ? T('pricingGroups.edit.save') : T('pricingGroups.add.save')}
      save={saveGroup}
    />
  );
}

interface ConfigurePricingGroupModalProps extends IPromiseModalProps<IPricingGroup | null> {
  organization: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  group?: IPricingGroup;
}

export function ConfigurePricingGroupModal(props: ConfigurePricingGroupModalProps) {
  const [isOpen, resolve] = usePromiseModal(props);
  const {organization, group} = props;
  return (
    <PricingGroupProvider group={group} organization={organization} onSave={resolve}>
      <ConfigurePricingGroupContent isOpen={isOpen} close={() => resolve(null)} />
    </PricingGroupProvider>
  );
}

interface ConfigurePricingGroupProps {
  isOpen: boolean;
  resolve: (group: IPricingGroup | null) => void;
  organization: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  group?: IPricingGroup;
}

export function ConfigurePricingGroup(props: ConfigurePricingGroupProps) {
  const {isOpen, resolve, organization, group} = props;
  return (
    <PricingGroupProvider group={group} organization={organization} onSave={resolve}>
      <ConfigurePricingGroupContent isOpen={isOpen} close={() => resolve(null)} />
    </PricingGroupProvider>
  );
}
