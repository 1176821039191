import React, {useMemo, useState} from 'react';

import {SingleActionModal} from '../../components/bootstrap';
import {Checkbox} from '../../components/ui/checkbox';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {getLoadName, ILoad, LoadType} from '../../models/Load';
import {IChildLocation, ILocationSummary} from '../../models/Location';
import {T} from '../../utils/Internationalization';

import {LoadWithStation} from './LoadWithStation';

interface AddLoadsModalProps extends IPromiseModalProps<Set<number> | false> {
  target: ILoad;
  loads: LoadWithStation[];
  parent?: ILocationSummary;
  childs: IChildLocation[];
  all: boolean;
}

interface LoadGroup {
  id: string;
  name: string;
  disabled: boolean;
  station: boolean;
  loads: LoadWithStation[];
}

function getIncludedGroups(loads: ILoad[], groups: LoadGroup[]): Set<string> {
  const includedGroups = groups.filter(g =>
    g.loads.some(
      l =>
        l.type === LoadType.Grid ||
        l.type === LoadType.VirtualGrid ||
        (l.parentId !== undefined && loads.some(x => x.id === l.parentId))
    )
  );
  return new Set(includedGroups.map(g => g.id));
}

function expandSelectedGroups(groups: LoadGroup[], selected: Set<string>): Set<number> {
  const result = new Set<number>();
  for (let group of groups) {
    if (!selected.has(group.id)) continue;

    for (let load of group.loads) result.add(load.id);
  }
  return result;
}

export function AddLoadsModal(props: AddLoadsModalProps) {
  const {target, loads, childs, all} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  const groups = useMemo(() => {
    const result: LoadGroup[] = [];
    for (let load of loads) {
      if (load.chargingStation === undefined) {
        result.push({
          id: `load-${load.id}`,
          name: getLoadName(load),
          disabled:
            load.type === LoadType.Grid ||
            load.type === LoadType.VirtualGrid ||
            (!all && load.parentId !== undefined && load.parentId !== target.id),
          station: false,
          loads: [load]
        });
      } else {
        const id = `station-${load.chargingStation.serialNumber}`;
        const existing = result.find(x => x.id === id);
        const disabled = !all && load.parentId !== undefined && load.parentId !== target.id;
        if (existing) {
          existing.loads.push(load);
          existing.disabled = existing.disabled && disabled;
        } else {
          result.push({
            id,
            name: load.chargingStation.name,
            disabled,
            station: true,
            loads: [load]
          });
        }
      }
    }
    return result;
  }, [loads, all, target]);

  const [includedGroups, setIncludedGroups] = useState(() => getIncludedGroups(loads, groups));

  const handleGroupChecked = (group: LoadGroup, checked: boolean) => {
    const newGroups = new Set(includedGroups);
    if (checked) {
      newGroups.add(group.id);
    } else {
      newGroups.delete(group.id);
    }
    setIncludedGroups(newGroups);
  };

  const loadGroups = groups.filter(group => !group.station).sort((a, b) => a.name.localeCompare(b.name));
  const stationGroups = groups.filter(group => group.station).sort((a, b) => a.name.localeCompare(b.name));
  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('overloadProtection.addLoad.title')}
      action={async () => resolve(expandSelectedGroups(groups, includedGroups))}
    >
      <p>{T('overloadProtection.addLoad.message')}</p>
      {loadGroups.length > 0 && stationGroups.length > 0 && <h3>{T('overloadProtection.addLoad.loads')}</h3>}
      {loadGroups.map(group => (
        <Checkbox
          id={group.id}
          name={group.id}
          label={group.name}
          checked={includedGroups.has(group.id)}
          onCheckedChange={checked => handleGroupChecked(group, checked)}
          testId={group.id}
        />
      ))}
      {stationGroups.length > 0 && <h3 style={{marginTop: '1rem'}}>{T('overloadProtection.addLoad.stations')}</h3>}
      {stationGroups.map(group => (
        <Checkbox
          id={group.id}
          name={group.id}
          label={group.name}
          checked={includedGroups.has(group.id)}
          onCheckedChange={checked => handleGroupChecked(group, checked)}
          testId={group.id}
        />
      ))}
    </SingleActionModal>
  );
}
