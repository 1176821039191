import React from 'react';

import {Checkbox} from '../../../components/ui/checkbox';
import {IChannelSensor} from '../../../models/Channel';

interface ReversedInputProps {
  readOnly: boolean;
  sensor: IChannelSensor | undefined;
  onChange: (checked: boolean) => void;
}
export const ReversedInput = (props: ReversedInputProps) => {
  const {readOnly, sensor, onChange} = props;
  const reversed = sensor ? sensor.reversed : false;

  return (
    <Checkbox
      name="sensor.reversed"
      id="sensor.reversed"
      checked={reversed}
      onCheckedChange={onChange}
      disabled={readOnly}
      wrapperClassName="!tw-mb-0 tw-justify-center"
      testId="sensor.reversed"
    />
  );
};
