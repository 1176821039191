import type {SVGProps} from 'react';

const SvgPieChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    fillRule="evenodd"
    {...props}
  >
    <path d="M10.625 23a9.634 9.634 0 0 0 9.625-9.625.459.459 0 0 0-.458-.458h-8.25a.458.458 0 0 1-.459-.459v-8.25a.459.459 0 0 0-.458-.458 9.625 9.625 0 0 0 0 19.25Z" />
    <path d="M13.375 1a.458.458 0 0 0-.458.458v8.709a.916.916 0 0 0 .916.916h8.709a.459.459 0 0 0 .458-.458A9.634 9.634 0 0 0 13.375 1Z" />
  </svg>
);
export default SvgPieChart;
