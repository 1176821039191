import React, {useEffect, useMemo} from 'react';

import {Label, Input} from '../../components/bootstrap';
import {useLiveHarmonics} from '../../livedata/LiveData';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {DeviceType} from '../../models/DeviceType';
import {IHighLevelConfiguration, PhaseType} from '../../models/HighLevelConfiguration';
import {getLoadName, ILoadChannel} from '../../models/Load';
import {OnlineStatus} from '../../models/OnlineStatus';
import {getPathIndex} from '../../models/TrackingPath';
import {useHighLevelConfiguration, useLocationActivationCode} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {Spring} from '../components/CardActions';

import {cardViewProps, CardView, CustomActions} from '../components/CardView';

import {LiveHarmonicsChart} from './LiveHarmonicsChart';
import {ILoadWithChannelIndices} from './Models';

const HARMONICS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 17, 19];

interface LiveHarmonicsSettings extends ICardSettings {
  loadId?: number;
}

const LiveHarmonics = (props: ICardProps<LiveHarmonicsSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {loadId} = settings;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const [highLevelConfiguration] = useHighLevelConfiguration(fetch, locationId);
  const [activationCode] = useLocationActivationCode(fetch, locationId);

  const hasHarmonics =
    activationCode !== undefined &&
    ((activationCode.currentHarmonics || []).length > 0 || (activationCode.voltageHarmonics || []).length > 0);

  const [values, status] = useLiveHarmonics(location, hasHarmonics);
  const phaseType = highLevelConfiguration?.phaseType || PhaseType.Star;
  const loads = useMemo(() => getLoads(highLevelConfiguration), [highLevelConfiguration]);

  let error: string | undefined;
  if (location) {
    const {serialNumber, deviceType} = location;
    if (!serialNumber || !deviceType) {
      error = T('phasorDisplay.notInstalled');
    } else if (deviceType !== DeviceType.Genius) {
      error = T('phasorDisplay.notSupported');
    } else if (!hasHarmonics) {
      error = T('liveHarmonics.notEnabled');
    }
  }
  if (status === OnlineStatus.Offline) {
    error = T('card.error.noLiveValues');
  }

  // switch load, if necessary
  useEffect(() => {
    if (loads.length === 0) return;

    let load = loads.find(load => load.id === loadId);
    if (!load) updateSettings({loadId: loads[0].id});
  }, [updateSettings, loads, loadId]);

  const translatedMessage = useMemo(() => {
    if (!activationCode || !values) return values;

    const result = {...values};
    const enabledCurrentHarmonics = activationCode.currentHarmonics;
    const enabledVoltageHarmonics = activationCode.voltageHarmonics;
    if (enabledCurrentHarmonics) {
      result.currentHarmonics = values.currentHarmonics.map(load =>
        load ? load.filter((_, index) => index === 0 || enabledCurrentHarmonics.includes(HARMONICS[index])) : null
      );
    }
    if (enabledVoltageHarmonics) {
      result.phaseVoltageHarmonics = values.phaseVoltageHarmonics.map(load =>
        load ? load.filter((_, index) => index === 0 || enabledVoltageHarmonics.includes(HARMONICS[index])) : null
      );
    }
    return result;
  }, [values, activationCode]);

  const handleSelectedLoadChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    updateSettings({loadId: parseInt(e.currentTarget.value)});
  };

  const loadOptions = useMemo(() => {
    const {measurements: loads = []} = highLevelConfiguration || {};
    return loads
      .filter(load => load.updateChannels)
      .map(load => (
        <option key={`board-icon-${load.id}`} value={load.id}>
          {getLoadName(load)}
        </option>
      ));
  }, [highLevelConfiguration]);

  const actions: CustomActions = () => (
    <CardActions>
      <Spring />
      <Label>{T('phasorDisplay.load')}</Label>
      <Input
        type="select"
        name="load"
        style={{width: 300, maxWidth: 'inherit'}}
        value={loadId || ''}
        onChange={handleSelectedLoadChanged}
      >
        {loadOptions}
      </Input>
    </CardActions>
  );

  const enabledCurrentHarmonics = (activationCode && activationCode.currentHarmonics) || [];
  const enabledVoltageHarmonics = (activationCode && activationCode.voltageHarmonics) || [];

  const isInfinity = location && location.deviceType ? location.deviceType === DeviceType.Genius : false;

  return (
    <CardView
      actions={isInfinity ? actions : undefined}
      ready={translatedMessage !== undefined}
      error={error}
      {...cardViewProps(props)}
    >
      {isInfinity && translatedMessage && loadId !== undefined && (
        <LiveHarmonicsChart
          currentHarmonics={enabledCurrentHarmonics}
          voltageHarmonics={enabledVoltageHarmonics}
          values={translatedMessage}
          phaseType={phaseType}
          loads={loads}
          loadId={loadId}
        />
      )}
    </CardView>
  );
};

function getChannelIndex(actual: ILoadChannel): number | undefined {
  if (!actual.updateChannels) return undefined;

  const channel = actual.updateChannels.current;
  if (!channel.aspectPaths) return undefined;

  return getPathIndex(channel.aspectPaths[0]);
}

function getLoads(config?: IHighLevelConfiguration): ILoadWithChannelIndices[] {
  if (!config) return [];

  return config.measurements
    .filter(load => load.updateChannels)
    .map(load => {
      return {
        id: load.id,
        actuals: load.actuals.map(actual => ({
          index: getChannelIndex(actual),
          ...actual
        }))
      };
    });
}

const CARD: ICardType<LiveHarmonicsSettings> = {
  type: CardTypeKey.LiveHarmonics,
  title: 'liveHarmonics.title',
  description: 'liveHarmonics.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: LiveHarmonics
};
export default CARD;
