import type {SVGProps} from 'react';
const SvgSortDescending = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path d="M5.00003 15.1C4.44775 15.1 4.00003 14.6523 4.00003 14.1V3.65564L2.77784 4.87782C2.34826 5.3074 1.65178 5.30739 1.22221 4.87782C0.79263 4.44824 0.792631 3.75176 1.22221 3.32218L4.22222 0.322182C4.42851 0.115892 4.7083 -9.53669e-07 5.00004 0C5.29178 9.5368e-07 5.57157 0.115895 5.77786 0.322186L8.77784 3.32218C9.20742 3.75176 9.20742 4.44824 8.77784 4.87782C8.34826 5.3074 7.65178 5.30739 7.22221 4.87782L6.00003 3.65563V14.1C6.00003 14.6523 5.55232 15.1 5.00003 15.1Z" />
    <path d="M8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13Z" />
    <path d="M8 9C8 8.44772 8.44772 8 9 8H15C15.5523 8 16 8.44772 16 9C16 9.55228 15.5523 10 15 10H9C8.44772 10 8 9.55228 8 9Z" />
    <path d="M11 5C11 4.44772 11.4477 4 12 4H15C15.5523 4 16 4.44772 16 5C16 5.55228 15.5523 6 15 6H12C11.4477 6 11 5.55228 11 5Z" />
  </svg>
);
export default SvgSortDescending;
