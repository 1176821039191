import type {SVGProps} from 'react';
const SvgSortAscending = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path d="M5.00003 0C4.44775 0 4.00003 0.447715 4.00003 1V11.4444L2.77784 10.2222C2.34826 9.79263 1.65178 9.79263 1.22221 10.2222C0.79263 10.6518 0.792631 11.3483 1.22221 11.7778L4.22222 14.7778C4.42851 14.9841 4.7083 15.1 5.00004 15.1C5.29178 15.1 5.57157 14.9841 5.77786 14.7778L8.77784 11.7778C9.20742 11.3483 9.20742 10.6518 8.77784 10.2222C8.34826 9.79263 7.65178 9.79263 7.22221 10.2222L6.00003 11.4444V1C6.00003 0.447715 5.55232 0 5.00003 0Z" />
    <path d="M8 2C8 1.44772 8.44772 1 9 1H15C15.5523 1 16 1.44772 16 2C16 2.55228 15.5523 3 15 3H9C8.44772 3 8 2.55228 8 2Z" />
    <path d="M8 6C8 5.44772 8.44772 5 9 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H9C8.44772 7 8 6.55228 8 6Z" />
    <path d="M11 10C11 9.44772 11.4477 9 12 9H15C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H12C11.4477 11 11 10.5523 11 10Z" />
  </svg>
);
export default SvgSortAscending;
