import {useMemo, useReducer} from 'react';

import {SmartDeviceTypeCategory} from '../../models/SmartDevice';

export interface CreateSmartDeviceState {
  category: SmartDeviceTypeCategory;
  categoryError?: string;
  type: string;
  typeError?: string;
  name: string;
  properties: {[key: string]: string};
}

const initialState: CreateSmartDeviceState = {
  category: SmartDeviceTypeCategory.SelectOne,
  type: '',
  name: '',
  properties: {}
};

interface BaseAction {
  action: 'SET_CATEGORY' | 'SET_TYPE' | 'SET_NAME' | 'SET_PROPERTY' | 'SET_ERRORS';
}

interface SetCategoryAction extends BaseAction {
  action: 'SET_CATEGORY';
  category: SmartDeviceTypeCategory;
}

interface SetTypeAction extends BaseAction {
  action: 'SET_TYPE';
  type: string;
}

interface SetNameAction extends BaseAction {
  action: 'SET_NAME';
  name: string;
}

interface SetPropertyAction extends BaseAction {
  action: 'SET_PROPERTY';
  name: string;
  value: string;
}

interface SetErrorsAction extends BaseAction {
  action: 'SET_ERRORS';
  categoryError?: string;
  typeError?: string;
}

type Action = SetCategoryAction | SetTypeAction | SetNameAction | SetPropertyAction | SetErrorsAction;

function reducer(state: CreateSmartDeviceState, action: Action): CreateSmartDeviceState {
  switch (action.action) {
    case 'SET_CATEGORY':
      return {
        ...state,
        category: action.category,
        categoryError: undefined,
        type: '',
        properties: {}
      };
    case 'SET_TYPE':
      return {
        ...state,
        type: action.type,
        typeError: undefined,
        properties: {}
      };
    case 'SET_NAME':
      return {...state, name: action.name};
    case 'SET_PROPERTY':
      return {
        ...state,
        properties: {...state.properties, [action.name]: action.value}
      };
    case 'SET_ERRORS':
      return {
        ...state,
        categoryError: action.categoryError,
        typeError: action.typeError
      };
    default:
      return state;
  }
}

export class CreateSmartDeviceStateActor {
  private dispatch: React.Dispatch<Action>;

  constructor(dispatch: React.Dispatch<Action>) {
    this.dispatch = dispatch;
  }

  setCategory(category: SmartDeviceTypeCategory) {
    this.dispatch({action: 'SET_CATEGORY', category});
  }

  setType(type: string) {
    this.dispatch({action: 'SET_TYPE', type});
  }

  setName(name: string) {
    this.dispatch({action: 'SET_NAME', name});
  }

  setProperty(name: string, value: string) {
    this.dispatch({action: 'SET_PROPERTY', name, value});
  }

  setErrors(errors: {categoryError?: string; typeError?: string}) {
    this.dispatch({action: 'SET_ERRORS', ...errors});
  }
}

export function useCreateSmartDeviceState(): [CreateSmartDeviceState, CreateSmartDeviceStateActor] {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actor = useMemo(() => new CreateSmartDeviceStateActor(dispatch), [dispatch]);
  return [state, actor];
}
