import {Fragment} from 'react';

const railOuterStyle: React.CSSProperties = {
  position: 'absolute',
  height: '100%',
  width: 124, // 42
  transform: 'translate(-50%, 0%)',
  borderRadius: 0, // 7
  cursor: 'pointer'
};

const railInnerStyle: React.CSSProperties = {
  position: 'absolute',
  height: '100%',
  width: 124, // 14
  transform: 'translate(-50%, 0%)',
  borderRadius: 0, // 7
  pointerEvents: 'none',
  backgroundColor: 'rgb(245,237,233)' // 155,155,155
};

interface SliderRailProps {
  getRailProps: () => React.HTMLProps<HTMLDivElement>;
}

export const SliderRail: React.FC<SliderRailProps> = ({getRailProps}) => (
  <Fragment>
    <div style={railOuterStyle} {...getRailProps()} />
    <div style={railInnerStyle} />
  </Fragment>
);
