import React, {useMemo} from 'react';

import SelectLocationIdButton from '../../components/SelectLocationIdButton';
import {useLiveValues} from '../../livedata/LiveData';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {isBigConsumer, optionalLocationToSummary} from '../../models/Location';
import {OnlineStatus} from '../../models/OnlineStatus';
import {NO_POWER_CHANNEL} from '../../models/TrackingChannel';
import {getUnitLabel} from '../../models/UsageValue';
import {useAppDashboard} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationDetails, useCardLocationId} from '../CardUtils';

import {CardView, cardViewProps} from '../components/CardView';

import {getChannelsFromDashboard} from './Data';
import {Summary} from './Summary';

const LiveValues = (props: ICardProps<ICardSettings>) => {
  const {fetch, settings} = props;

  const locationId = useCardLocationId(settings);
  const location = useCardLocationDetails(settings);
  const isChild = location && location.parentId !== undefined;
  const [message, status] = useLiveValues(optionalLocationToSummary(location));
  const [dashboard] = useAppDashboard(fetch, locationId);

  const bigConsumer = location ? isBigConsumer(location) : false;
  const electricityUnit = bigConsumer ? 'kW' : 'W';
  const {
    consumptionChannel = NO_POWER_CHANNEL,
    solarChannel = NO_POWER_CHANNEL,
    alwaysOnChannel = NO_POWER_CHANNEL,
    gasChannel = NO_POWER_CHANNEL,
    waterChannel = NO_POWER_CHANNEL
  } = useMemo(() => getChannelsFromDashboard(dashboard, bigConsumer), [dashboard, bigConsumer]);

  const consumption = message && consumptionChannel.extract(message);
  const solar = message && solarChannel.extract(message);
  const alwaysOn = message && alwaysOnChannel.extract(message);
  const water = message && waterChannel.extract(message);
  const gas = message && gasChannel.extract(message);

  let error: string | JSX.Element | undefined;
  if (isChild) {
    error = (
      <>
        {T('liveValues.notSupportedForLocalityChild')}
        <br />
        <SelectLocationIdButton fetch={fetch} locationId={location!.parentId!} />
      </>
    );
  } else if (status === OnlineStatus.Offline) {
    error = T('card.error.noLiveValues');
  }

  return (
    <CardView {...cardViewProps(props)} error={error}>
      <Summary
        location={location}
        consumption={consumption}
        solar={solar}
        alwaysOn={alwaysOn}
        water={water}
        gas={gas}
        electricityUnit={electricityUnit}
        waterUnit={getUnitLabel(waterChannel.unit)}
        gasUnit={getUnitLabel(gasChannel.unit)}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: ICardSettings = {};
const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.LiveValues,
  title: 'liveValues.title',
  description: 'liveValues.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: LiveValues
};
export default CARD;
