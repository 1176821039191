import type {SVGProps} from 'react';
const SvgStandby = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    fillRule="evenodd"
    {...props}
  >
    <path d="M12 0a1.5 1.5 0 0 0-1.5 1.5v9a1.5 1.5 0 0 0 3 0v-9A1.5 1.5 0 0 0 12 0M6.544 3.618C7.675 2.881 9 3.832 9 5.183V10.5a3 3 0 1 0 6 0V5.183c0-1.351 1.325-2.302 2.456-1.565A9.99 9.99 0 0 1 22 12c0 5.523-4.477 10-10 10S2 17.523 2 12a9.99 9.99 0 0 1 4.544-8.382" />
  </svg>
);
export default SvgStandby;
