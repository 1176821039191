import goodEnergyBackground from '../assets/backgrounds/goodenergy.jpg';

import {T} from './Internationalization';
import goodenergyClasses from './Styles-goodenergy.module.scss';
import standardClasses from './Styles-standard.module.scss';

export function classes(...classes: (string | false | null | undefined)[]) {
  return classes.filter(cls => typeof cls === 'string').join(' ');
}

export interface IDashboardStyleClasses {
  active: string;
  withoutPadding: string;
  disabled: string;

  boardMenu: string;
  boardMenuTitle: string;
  boardMenuItem: string;
  boardMenuAddBoard: string;
  boardNavigationActions: string;

  primaryButton: string;
  secondaryButton: string;
  lightButton: string;
  linkButton: string;
  editButton: string;

  userMenu: string;
  userMenuToggle: string;

  dropdownItem: string;
  itemsPerPageSelect: string;
}

export interface IDashboardStyle {
  loginBackground?: string;
  menuLogo?: string; // if absent, the default logo will be shown
  menuLogoWidth: number;
  showPartnerInfo: boolean;
  showPoweredBy: boolean;
  welcomeTitle: string;
  loginLogoHeight?: number;

  classes: IDashboardStyleClasses;
}

const styles: {[key: string]: IDashboardStyle} = {
  standard: {
    menuLogoWidth: 200,
    showPartnerInfo: true,
    showPoweredBy: false,
    welcomeTitle: T('welcome.title'),
    classes: standardClasses as unknown as IDashboardStyleClasses
  },
  goodEnergy: {
    menuLogo: '/assets/goodenergy.png',
    menuLogoWidth: 150,
    showPartnerInfo: true,
    showPoweredBy: false,
    welcomeTitle: '',
    classes: goodenergyClasses as unknown as IDashboardStyleClasses,
    loginBackground: `url(${goodEnergyBackground})`,
    loginLogoHeight: 60
  }
};

function selectStyle() {
  if (window.location.host === 'goodenergy.smappee.net' /* || window.location.host === 'localhost:3000'*/) {
    return styles.goodEnergy;
  } else return styles.standard;
}

export function cx(...classes: (string | false | null | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}
export const dashboardStyle = selectStyle();
export const dashboardClasses = dashboardStyle.classes;
