import {IPowerMessage} from '../livedata/LiveDataModels';

import {ILoadUpdateChannel, UpdateChannelAggregation} from './Load';
import {IConfigurationPropertySpec} from './SmartDevice';
import {TrackingPath} from './TrackingPath';
import {UsageUnit} from './UsageValue';

export interface IPowerChannel {
  unit: UsageUnit;

  extract(message: IPowerMessage): number | undefined;
}

class SumPowerChannel implements IPowerChannel {
  unit: UsageUnit;
  paths: TrackingPath[];

  constructor(paths: TrackingPath[], unit: UsageUnit) {
    this.unit = unit;
    this.paths = paths;
  }

  extract(message: IPowerMessage): number | undefined {
    let total: number | undefined;
    for (var path of this.paths) {
      const value = path.extract(message);
      if (value !== undefined) total = (total || 0) + value;
    }
    return total;
  }
}

export class AbsentPowerChannel implements IPowerChannel {
  unit: UsageUnit;

  constructor(unit: UsageUnit) {
    this.unit = unit;
  }

  extract(message: IPowerMessage): number | undefined {
    return undefined;
  }
}

export function createPowerChannelFromSpec(
  spec: IConfigurationPropertySpec,
  multiplier: number = 1,
  name: string
): IPowerChannel {
  if (spec.updateChannel === undefined) {
    return new AbsentPowerChannel(UsageUnit.None);
  }

  return createPowerChannel(spec.updateChannel, UsageUnit.None, multiplier, name);
}

export function createPowerChannel(
  channel: ILoadUpdateChannel,
  unit: UsageUnit,
  multiplier: number = 1,
  name: string
): IPowerChannel {
  switch (channel.aggregation || UpdateChannelAggregation.Sum) {
    case UpdateChannelAggregation.Sum:
      const paths = (channel.aspectPaths || []).map(
        path => new TrackingPath(path.path, path.multiplier * multiplier, name)
      );
      return new SumPowerChannel(paths, unit);
    default:
      throw new Error(`unsupported aggregation: ${channel.aggregation}`);
  }
}

export const NO_POWER_CHANNEL = new AbsentPowerChannel(UsageUnit.None);
