import type {SVGProps} from 'react';
const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="currentColor"
    fillRule="evenodd"
    {...props}
  >
    <path d="M13 6C13.5523 6 14 5.55228 14 5V1C14 0.447715 13.5523 0 13 0C12.4477 0 12 0.447715 12 1V2.25485C10.8664 1.46435 9.48683 1 8 1C4.13401 1 1 4.13401 1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 5.23858 5.23858 3 8 3C9.12631 3 10.1645 3.37152 11.0006 4L9 4C8.44772 4 8 4.44771 8 5C8 5.55228 8.44772 6 9 6L13 6ZM14 7C14.5523 7 15 7.44772 15 8C15 11.866 11.866 15 8 15C6.51312 15 5.13361 14.5356 4 13.7451V15C4 15.5523 3.55228 16 3 16C2.44772 16 2 15.5523 2 15V11C2 10.4477 2.44772 10 3 10H7C7.55228 10 8 10.4477 8 11C8 11.5523 7.55228 12 7 12H4.99935C5.83546 12.6285 6.87373 13 8 13C10.7614 13 13 10.7614 13 8C13 7.44772 13.4477 7 14 7Z" />
  </svg>
);
export default SvgRefresh;
