import {OptimizationStrategy} from '../../../api/LoadManagementAPI';
import {Button as RsButton} from '../../../components/bootstrap';
import {ChargingMode} from '../../../models/ChargingStation';
import {T} from '../../../utils/Internationalization';

interface PauseResumeFieldProps {
  isCharging: boolean;
  isReadOnly: boolean;
  chargingMode: ChargingMode | undefined;
  smartMode: OptimizationStrategy;
  isUltra: boolean;
  setChargingMode: (mode: ChargingMode) => void;
}

function getResumeModeForStrategy(strategy: OptimizationStrategy): ChargingMode {
  switch (strategy) {
    case OptimizationStrategy.None:
      return ChargingMode.Normal;
    case OptimizationStrategy.ExcessOnly:
    case OptimizationStrategy.SchedulesThenExcess:
      return ChargingMode.Smart;
    default:
      return ChargingMode.Normal;
  }
}

export const PauseResumeField = (props: PauseResumeFieldProps) => {
  const {chargingMode, smartMode, isUltra} = props;
  return (
    <div style={{display: 'inline-block'}}>
      {props.isReadOnly ? (
        <div
          style={{
            display: 'inline-block',
            paddingTop: '0.25rem',
            paddingRight: '0.5rem',
            paddingBottom: '0.25rem'
          }}
        >
          {chargingMode === ChargingMode.Normal && T('chargingStationConfiguration.property.chargingMode.normal')}
          {chargingMode === ChargingMode.Smart && T('chargingStationConfiguration.property.chargingMode.smart')}
          {chargingMode === ChargingMode.Stop &&
            isUltra &&
            T('chargingStationConfiguration.property.chargingMode.stop')}
          {chargingMode === ChargingMode.Stop &&
            !isUltra &&
            T('chargingStationConfiguration.property.chargingMode.pause')}
        </div>
      ) : (
        <RsButton
          size="sm"
          style={{position: 'relative', bottom: 2}}
          onClick={() =>
            props.setChargingMode(
              chargingMode === ChargingMode.Stop ? getResumeModeForStrategy(smartMode) : ChargingMode.Stop
            )
          }
          active={chargingMode === ChargingMode.Stop}
          disabled={!props.isCharging}
        >
          {isUltra
            ? T('chargingStationConfiguration.property.chargingMode.stop')
            : chargingMode === ChargingMode.Stop
              ? T('chargingStationConfiguration.action.resume')
              : T('chargingStationConfiguration.action.pause')}
        </RsButton>
      )}
    </div>
  );
};
