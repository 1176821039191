import React from 'react';

import {Input} from '../../../components/bootstrap';
import {CTType, CT_TYPES, CT_TYPE_LABEL_CLOSED, getCTTypeLabel} from '../../../models/CTType';
import {IDevice} from '../../../models/Device';
import {DeviceType} from '../../../models/DeviceType';

interface CTSelectorProps {
  readOnly: boolean;
  value: CTType;
  device: IDevice;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}
export const CTSelector = (props: CTSelectorProps) => {
  const {readOnly, value, onChange, device} = props;
  if (device && device.type === DeviceType.ClosedCTHub) {
    return (
      <Input type="select" name="ctType" value={3} disabled={true}>
        <option value="3">{CT_TYPE_LABEL_CLOSED}</option>
      </Input>
    );
  }

  return (
    <Input type="select" name="ctType" value={value} onChange={onChange} disabled={readOnly}>
      {CTSelector.OPTIONS}
    </Input>
  );
};
CTSelector.OPTIONS = CT_TYPES.map(type => (
  <option key={type} value={type}>
    {getCTTypeLabel(type)}
  </option>
));
