import {useMemo, useState, useEffect} from 'react';

import {NotificationManager} from 'react-notifications';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {Input} from '../../components/bootstrap';
import {buttonColors} from '../../components/bootstrap/Button';

import {LocationOnlineStatusIndicator} from '../../components/OnlineStatusIndicator';
import {SortOrder, ISelectedRow} from '../../components/Table';
import {PropertyEntry, SettingsTable} from '../../components/Table/SettingsTable';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IChargingStation} from '../../models/ChargingStation';
import {ICustomFile} from '../../models/CustomFile';
import {Device} from '../../models/Device';
import {LocationFunctionType, isChargingParent, isReadOnly} from '../../models/Location';
import {LocationUpgradeStatus} from '../../models/LocationUpgradeStatus';
import {DynamicTariff, TariffType} from '../../models/Tariff';
import {UserType} from '../../models/User';
import {
  useLocation,
  useHistoricalDeviceInfo,
  useOrganizationSearchFields,
  useLocationSearchFieldValues,
  useLocationActivationCode,
  useDeviceActivationCode
} from '../../utils/FunctionalData';
import {useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationId, useCardLocation, useUser} from '../CardUtils';
import {CardActions, Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import ClearDataModal from './ClearDataModal';
import styles from './index.module.scss';
import {PropsState, buildProperties} from './properties';

type LocationConfigurationSettings = ICardSettingsWithTable;

const notUpgrading: LocationUpgradeStatus = {
  gatewayUpgrading: false,
  gatewayFunctionTypeUpgrading: false
};

const LocationConfiguration = (props: ICardProps<LocationConfigurationSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const {api, store} = useAppContext();
  const modals = useModals();

  const me = useUser();
  const locationId = useCardLocationId(settings);
  const locationSummary = useCardLocation(settings);
  const deviceType = locationSummary && locationSummary.deviceType;

  const [filter, setFilter] = useState('');
  const [actionsOpened, setActionsOpened] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chargingParkWithUltraV2, setChargingParkWithUltraV2] = useState(false);

  const [location, refreshLocation] = useLocation(fetch, locationId);
  const {type: historicalDeviceType = undefined, serialNumber = undefined} =
    useHistoricalDeviceInfo(fetch, locationSummary) || {};
  const organizationId = location && location.organization && location.organization.id;
  const parentId = locationSummary && locationSummary.parentId;

  const searchFields = useOrganizationSearchFields(fetch, organizationId);
  const [activationCode, refreshActivationCode] = useLocationActivationCode(fetch, locationId);
  const [deviceActivationCode, refreshDeviceActivationCode] = useDeviceActivationCode(fetch, serialNumber);
  const [parent] = useLocation(fetch, parentId);
  const [upgrading = notUpgrading, refreshUpgrading] = useLoader(
    api =>
      locationId ? api.locations.getUpgradeStatus(locationId).catch(() => notUpgrading) : Promise.resolve(notUpgrading),
    [locationId]
  );

  const readOnly = isReadOnly(me, locationSummary);
  const isServiceDesk = me.isServiceDesk();
  const canChangeActivationCode = me.isServiceDesk() || me.role === UserType.PartnerAdmin;

  const [searchFieldValues, refreshSearchFieldValues] = useLocationSearchFieldValues(api, locationId);

  const [installationPictures, setInstallationPictures] = useState<any[]>();
  useEffect(() => {
    locationId && api.getInstallationPictures(locationId).then(setInstallationPictures);
  }, [api, locationId]);

  const [dynamicTariff, refreshDynamicTariff] = useLoader<DynamicTariff | undefined>(
    api => {
      if (!location) return Promise.resolve(undefined);

      if (location.calendarType === TariffType.Dynamic) {
        return api.tariffs.getDynamicTariff(location.id);
      } else return Promise.resolve(undefined);
    },
    [api, location?.id, location?.calendarType]
  );

  const [customFiles, setCustomFiles] = useState<ICustomFile[]>([]);
  useEffect(() => {
    locationId && api.getCustomFiles(locationId).then(setCustomFiles);
  }, [api, locationId]);

  const [nilmVersion, setNilmVersion] = useState<number>();
  useEffect(() => {
    if (historicalDeviceType === undefined) return;
    if (!Device.isLegacy(historicalDeviceType)) {
      setNilmVersion(3);
      return;
    }

    locationId && api.getNilmVersion(locationId).then(result => setNilmVersion(result.version));
  }, [api, locationId, historicalDeviceType]);

  useEffect(() => {
    if (upgrading.gatewayUpgrading) {
      const interval = setInterval(refreshUpgrading, 20000);
      return () => clearInterval(interval);
    }
  }, [refreshUpgrading, upgrading.gatewayUpgrading]);

  const propsState = {
    location,
    locationSummary,
    historicalDeviceType,
    nilmVersion,
    searchFieldValues,
    activationCode,
    deviceActivationCode,
    installationPictures,
    customFiles,
    upgrading,
    dynamicTariff
  };

  const properties = useMemo<PropertyEntry<PropsState>[]>(
    () =>
      buildProperties(
        api,
        store,
        modals,
        me,
        readOnly,
        searchFields,
        deviceType,
        isServiceDesk,
        canChangeActivationCode,
        parent,
        chargingParkWithUltraV2,
        refreshActivationCode,
        refreshDeviceActivationCode,
        refreshLocation,
        refreshSearchFieldValues,
        refreshUpgrading,
        refreshDynamicTariff,
        setLoading,
        setNilmVersion,
        setCustomFiles
      ),
    [
      api,
      store,
      modals,
      me,
      readOnly,
      searchFields,
      deviceType,
      isServiceDesk,
      canChangeActivationCode,
      parent,
      chargingParkWithUltraV2,
      refreshActivationCode,
      refreshDeviceActivationCode,
      refreshLocation,
      refreshSearchFieldValues,
      refreshUpgrading,
      refreshDynamicTariff
    ]
  );

  const canDelete =
    (isServiceDesk || me.role === UserType.PartnerAdmin || me.role === UserType.BasicAdmin) &&
    location !== undefined &&
    location.functionType !== LocationFunctionType.ChargingStation;
  const isAdmin = isServiceDesk || me.role === UserType.PartnerAdmin;

  const isChargingPark = location && isChargingParent(location.functionType);
  const hasUltraV2Stations = async (locationId: number) => {
    const checkForUltraV2 = (station: IChargingStation) =>
      station.model.startsWith('ULTRA') && station.version === 'V2';
    const response = await api.chargingStations.getByLocation(locationId).then(result => result.some(checkForUltraV2));
    setChargingParkWithUltraV2(response);
  };

  if (location && isChargingPark) {
    hasUltraV2Stations(location.id);
  }

  const handleClickedClearData = () => {
    if (!locationSummary) return <div />;

    modals.show<void>(props => <ClearDataModal location={locationSummary} {...props} />);
  };

  const handleClickedDeactivate = async () => {
    if (!location) return;

    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locations.deactivate.title')}
        message={T('locations.deactivate.message')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      await api.deactivateLocation(location.id);
      NotificationManager.success(T('locations.deactivate.success'));
    } catch {
      NotificationManager.error(T('locations.deactivate.failed'));
    }
  };

  const handleClickedReactivate = async () => {
    if (!location) return;

    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locations.reactivate.title')}
        message={T('locations.reactivate.message')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      await api.reactivateLocation(location.id);
      NotificationManager.success(T('locations.reactivate.success'));
    } catch {
      NotificationManager.error(T('locations.reactivate.failed'));
    }
  };

  const handleClickedDelete = async () => {
    if (!location) return;

    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locations.deleteLocation.title', {name: location.name || ''})}
        message={T('locations.deleteLocation.message')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      await api.deleteLocation(location.id);
      NotificationManager.success(T('locations.deleteLocation.deleted'));
      setDeleted(true);
    } catch {
      NotificationManager.error(T('locations.deleteLocation.failed'));
    }
  };

  const handleClickedRestore = async () => {
    if (!location) return;

    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locations.restoreLocation.title', {
          name: location.name || ''
        })}
        message={T('locations.restoreLocation.message')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      await api.restoreLocation(location.id);
      NotificationManager.success(T('locations.restoreLocation.restored'));
    } catch {
      NotificationManager.error(T('locations.restoreLocation.failed'));
    }
  };

  const actions: CustomActions = state => (
    <CardActions>
      {state.ready && (
        <Input
          type="text"
          name="search"
          placeholder={T('locationConfiguration.search')}
          value={filter}
          onChange={e => setFilter(e.currentTarget.value)}
          autoWidth={true}
        />
      )}
      {state.ready && locationSummary && <LocationOnlineStatusIndicator location={locationSummary} />}
      <Spring />
      {state.ready && canDelete && (
        <Dropdown
          isOpen={actionsOpened}
          toggle={() => setActionsOpened(!actionsOpened)}
          className={styles.actionsRestart}
          color="secondary"
        >
          <DropdownToggle caret className={buttonColors.secondary}>
            {T('locationConfiguration.actions')}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={handleClickedClearData}>{T('locationConfiguration.clearData.title')}</DropdownItem>
            {location && location.closingDate === undefined && (
              <DropdownItem onClick={handleClickedDeactivate}>{T('locations.deactivate')}</DropdownItem>
            )}
            {isAdmin && location && location.closingDate !== undefined && (
              <DropdownItem onClick={handleClickedReactivate}>{T('locations.reactivate')}</DropdownItem>
            )}
            {location && location.obsoleteTimestamp === undefined && (
              <DropdownItem onClick={handleClickedDelete}>{T('locations.deleteLocation')}</DropdownItem>
            )}
            {isServiceDesk && location && location.obsoleteTimestamp !== undefined && (
              <DropdownItem onClick={handleClickedRestore}>{T('locations.restoreLocation')}</DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      )}
    </CardActions>
  );

  const error = deleted ? T('locationConfiguration.deleted') : undefined;

  return (
    <CardView error={error} actions={actions} {...cardViewProps(props)} loading={loading || props.loading}>
      <SettingsTable
        filter={filter}
        properties={properties}
        state={propsState}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
};

const DEFAULT_CARD_SETTINGS: LocationConfigurationSettings = {
  table: {
    pageSize: 10,
    sortColumn: 'order',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'order', visible: false},
      {name: 'key', visible: true}
    ]
  }
};

function normalizeSettings(settings: Partial<LocationConfigurationSettings>) {
  if (settings.table && settings.table.columns.some(c => (c as ISelectedRow).name === 'value')) {
    const newColumns = settings.table.columns.filter(c => (c as ISelectedRow).name !== 'value');
    const newTable = {...settings.table, columns: newColumns};
    return {table: newTable};
  }
}

const CARD: ICardType<LocationConfigurationSettings> = {
  type: CardTypeKey.LocationConfiguration,
  title: 'locationConfiguration.title',
  description: 'locationConfiguration.description',
  categories: [CardCategory.CONFIGURATION, CardCategory.LOCATIONS],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  //  locationAware: CardLocationAwareness.Required,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: LocationConfiguration,
  upgradeSettings: normalizeSettings
};
export default CARD;
