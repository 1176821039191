import React, {useEffect} from 'react';

import {TextInputGroup} from '../../../components/inputs/TextInput';
import {IBessPowerMessage} from '../../../livedata/LiveDataModels';
import {FormProvider} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';

type LiveSectionProps = {
  liveData: IBessPowerMessage | undefined;
  maxCapacity: number | null | undefined;
  data: {
    capacity: number;
    mode: string;
    stateOfHealth: number;
    cycleCount: number;
    stateOfCharge: number;
    batteryPower: number;
    gridPower: number;
    productionPower: number;
  };
  onChange: (field: keyof LiveSectionProps['data'], value: any) => void;
  markFormDirty: () => void;
};

export default function LiveSection(props: LiveSectionProps) {
  const {liveData, maxCapacity, data, onChange} = props;
  const [isCharging, setIsCharging] = React.useState(false);

  const handleInputChange = (field: keyof LiveSectionProps['data'], value: any) => {
    onChange(field, value);
  };
  // const isBatteryCharging = liveData?.batteryPower && liveData?.batteryPower < 1;

  // Styles
  const labelStyle = 'tw-font-bold tw-text-base tw-mb-0';
  const fieldStyle = 'tw-flex tw-flex-col tw-mb-4';

  useEffect(() => {
    if (!liveData || !liveData.batteryPower) return;
    const batteryIsCharging = liveData.batteryPower < 0;
    setIsCharging(batteryIsCharging);
  }, [liveData]);

  return (
    <div>
      <FormProvider>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 lg:tw-gap-8">
          <div>
            <TextInputGroup
              name="totalACPower"
              label={T('bess.property.totalACPower')}
              className={fieldStyle}
              value={liveData === undefined ? '--' : (liveData.totalACPower / 1000).toFixed(1)}
              readOnly
              suffix="kW"
              onChange={() => {}}
            />
            <TextInputGroup
              name="batteryPower"
              label={`${T('bess.property.batteryPower')} ${isCharging ? T('bess.live.charging.status.charging') : T('bess.live.charging.status.discharging')}`}
              className={fieldStyle}
              value={liveData === undefined ? '--' : (liveData.batteryPower / 1000).toFixed(1)}
              readOnly
              suffix="kW"
              onChange={() => {}}
            />
            <TextInputGroup
              name="gridPower"
              label={T('bess.property.gridPower')}
              className={fieldStyle}
              value={liveData === undefined ? '--' : (liveData.gridPower / 1000).toFixed(1)}
              readOnly
              suffix="kW"
              onChange={() => {}}
            />
            <TextInputGroup
              name="productionPower"
              label={T('bess.property.productionPower')}
              className={fieldStyle}
              value={liveData === undefined ? '--' : (liveData.solarACPower / 1000).toFixed(1)}
              readOnly
              suffix="kW"
              onChange={() => {}}
            />
            <TextInputGroup
              name="stateOfCharge"
              label={T('bess.property.stateOfCharge')}
              className={fieldStyle}
              value={liveData === undefined ? '--' : liveData.stateOfCharge.toFixed(1)}
              readOnly
              suffix="%"
              onChange={() => {}}
            />
          </div>
          <div>
            <TextInputGroup
              name="capacity"
              label={T('bess.property.capacity')}
              className={fieldStyle}
              value={maxCapacity && liveData ? ((liveData.stateOfCharge * maxCapacity) / 100000).toFixed(1) : '--'}
              readOnly
              suffix="kWh"
              onChange={() => {}}
            />
            <TextInputGroup
              name="mode"
              label={T('bess.property.mode')}
              className={fieldStyle}
              value={liveData?.mode || '--'}
              readOnly
              onChange={() => {}}
            />
            <TextInputGroup
              name="stateOfHealth"
              label={T('bess.property.stateOfHealth')}
              className={fieldStyle}
              value={liveData ? liveData.stateOfHealth.toFixed(1) : '--'}
              readOnly
              suffix="%"
              onChange={() => {}}
            />
            <TextInputGroup
              name="cycleCount"
              label={T('bess.property.cycleCount')}
              className={fieldStyle}
              value={liveData ? liveData.cycleCount.toString() : '--'}
              readOnly
              suffix="cycles"
              onChange={() => {}}
            />
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
