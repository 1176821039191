import React, {useCallback} from 'react';

import {Button} from '../../components/bootstrap';
import {Logout} from '../../components/ui-lib/icons/medium';
import {Clear, Edit} from '../../components/ui-lib/icons/small';
import {AuthUser} from '../../models/AuthUser';
import {ILocationSummary} from '../../models/Location';
import {UserType} from '../../models/User';
import {T} from '../../utils/Internationalization';

interface LocationActionsProps {
  item: ILocationSummary;
  isReadOnly: boolean;
  me: AuthUser;
  onClickedEdit: (location: ILocationSummary) => void;
  onClickedMove: (location: ILocationSummary) => void;
  onClickedDelete: (location: ILocationSummary) => void;
}
export const LocationActions = (props: LocationActionsProps) => {
  const {item, isReadOnly, me, onClickedEdit, onClickedMove, onClickedDelete} = props;

  const handleClickedEdit = useCallback(() => onClickedEdit(item), [onClickedEdit, item]);
  const handleClickedMove = useCallback(() => onClickedMove(item), [onClickedMove, item]);
  const handleClickedDelete = useCallback(() => onClickedDelete(item), [onClickedDelete, item]);
  const isServiceDesk = me.isServiceDesk();
  const isPartnerAdmin = me.role === UserType.PartnerAdmin;
  return (
    <>
      {!isReadOnly && (
        <Button size="sm" color="link" withoutPadding title={T('locations.editLocation')} onClick={handleClickedEdit}>
          <Edit className="tw-w-4 tw-h-4" />
        </Button>
      )}
      {isServiceDesk && (
        <Button
          size="sm"
          color="link"
          withoutPadding
          title={T('locations.moveToOrganization')}
          onClick={handleClickedMove}
          style={{paddingLeft: 8}}
        >
          <Logout width={16} height={16} />
        </Button>
      )}
      {(isServiceDesk || isPartnerAdmin) && (
        <Button
          size="sm"
          color="link"
          withoutPadding
          title={T('locations.deleteLocation')}
          onClick={handleClickedDelete}
          style={{paddingLeft: 8}}
        >
          <Clear />
        </Button>
      )}
    </>
  );
};
