export const enum OnlineStatus {
  Loading = 'loading',
  Online = 'online',
  Offline = 'offline',
  Unavailable = 'unavailable'
}

export function getOnlineStatus<T>(message: T | undefined, offline: boolean, unavailable: boolean) {
  if (unavailable) return OnlineStatus.Unavailable;
  else if (offline) return OnlineStatus.Offline;
  else if (message === undefined) return OnlineStatus.Loading;
  else return OnlineStatus.Online;
}
