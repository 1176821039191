import {Language} from '../utils/Internationalization';

import {IOrganization} from './Organization';
import {IUser} from './User';

export interface SplitBillingAgreement {
  id: number;
  rfid: string;
  confirmed: boolean;
  user?: IUser;
  organization: IOrganization;
  employeeNumber?: string;
  deletable?: boolean;
  period: {
    from: number;
    to?: number;
  };
  chargingStation?: {
    serialNumber: string;
  };
  refund: {
    rate: {
      value: number;
      currency: string;
    };
    bank: {
      bic: string;
      iban: string;
    };
    invoice: {
      language: Language;
      email: string;
      companyName?: string;
      vat?: string;
      title?: string;
      firstName?: string;
      lastName?: string;
      address: {
        streetAndNumber: string;
        postalCode: string;
        municipality: string;
        country?: string;
      };
    };
  };
  pendingChanges?: {
    refund?: {
      value: number;
      currency: string;
    };
  };
}

export function isClosed(agreement: SplitBillingAgreement) {
  if (agreement.period.to === undefined) return false;

  return agreement.period.to < new Date().valueOf();
}

export interface SplitBillingAgreementCreateRequest {
  rfid: string;
  user?: {
    // at least one of below must be provided
    id?: number;
    userName?: string;
  };
  organization?: {id: number};
  period: {
    from?: number;
    to?: number;
  };
  chargingStation: {
    serialNumber: string;
  };
  employeeNumber: string;
  refund: {
    rate: {
      value: number;
      currency: string;
    };
    bank: {
      bic: string;
      iban: string;
    };
    invoice: {
      language: Language;
      email: string;
      companyName?: string;
      vat?: string;
      title?: string;
      firstName?: string;
      lastName?: string;
      address: {
        streetAndNumber: string;
        postalCode: string;
        municipality: string;
        country?: string;
      };
    };
  };
}

export interface SplitBillingAgreementUpdateRequest {
  period: {
    to?: number;
  };
}
