import {formatCurrencyNumber, getCurrencySymbol} from '../../../utils/Currency';
import {T, singular} from '../../../utils/Internationalization';
import {TariffState, TimedInput} from '../configurePricingPolicy/FormState';
import {formatMinutes} from '../configurePricingPolicy/utils';

import styles from './DiscountedTariffView.module.scss';

interface DiscountedTariffViewProps {
  currency: string;
  baseTariff: TariffState;
  discount: number | null;
}

export function DiscountedTariffView(props: DiscountedTariffViewProps) {
  const {currency, baseTariff, discount} = props;
  const currencySymbol = getCurrencySymbol(currency);

  const multiplier = 1 - (discount || 0) * 0.01;
  return (
    <div>
      {baseTariff.startingFee && baseTariff.startingFee.numberValue !== null && (
        <>
          <div className={styles.header}>{T('pricingPolicies.add.tariffs.fixedCost')}</div>
          <div>
            <span className={styles.newPrice}>
              {currencySymbol} {formatCurrencyNumber(currency, baseTariff.startingFee.numberValue * multiplier)}
            </span>
            <span className={styles.oldPrice}>
              <span className={styles.strikethrough}>
                {currencySymbol} {formatCurrencyNumber(currency, baseTariff.startingFee.numberValue)}
              </span>{' '}
              / {T('pricingPolicies.add.tariffs.fixedCost.suffix')}
            </span>
          </div>
        </>
      )}
      {baseTariff.costPerKwh && baseTariff.costPerKwh.numberValue !== null && (
        <>
          <div className={styles.header}>{T('pricingPolicies.add.tariffs.energyCost')}</div>
          <div>
            <span className={styles.newPrice}>
              {currencySymbol} {formatCurrencyNumber(currency, baseTariff.costPerKwh.numberValue * multiplier)}
            </span>
            <span className={styles.oldPrice}>
              <span className={styles.strikethrough}>
                {currencySymbol} {formatCurrencyNumber(currency, baseTariff.costPerKwh.numberValue)}
              </span>{' '}
              / kWh
            </span>
          </div>
        </>
      )}
      {baseTariff.timeComponents && baseTariff.timeComponents.length > 0 && (
        <>
          <div className={styles.header}>{T('pricingPolicies.add.tariffs.hourlyCost')}</div>
          {baseTariff.timeComponents.map((component, index) => (
            <div>
              <span className={styles.prefix}>{getTimeComponentPrefix(baseTariff.timeComponents!, index)}</span>
              <span className={styles.newPrice}>
                {currencySymbol} {formatCurrencyNumber(currency, (component.cost.numberValue || 0) * multiplier)}
              </span>
              <span className={styles.oldPrice}>
                <span className={styles.strikethrough}>
                  {currencySymbol} {formatCurrencyNumber(currency, component.cost.numberValue || 0)}
                </span>{' '}
                / {singular('hour')}
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export function getTimeComponentPrefix(components: TimedInput[], index: number): string | null {
  if (components.length === 1) {
    return null;
  }

  if (index === 0) {
    return `${T('pricingPolicies.timeTariffFirst', {minutes: formatMinutes(components[1].afterMinutes)})}: `;
  } else {
    return `${T('pricingPolicies.timeTariffAfter', {minutes: formatMinutes(components[index].afterMinutes)})}: `;
  }
}

export function formatTimeComponentPricing(currency: string, components: TimedInput[], index: number): string {
  const prefix = getTimeComponentPrefix(components, index);
  return `${prefix || ''} ${getCurrencySymbol(currency)} ${formatCurrencyNumber(
    currency,
    components[index].cost.numberValue || 0
  )} / ${singular('hour')}`;
}
