import React, {useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import Table, {migrateTableSettings, SortOrder} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Plus} from '../../components/ui-lib/icons/small';
import {ConfirmationResult, ConfirmationPromiseModal} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {Automation} from '../../models/Automation';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {isReadOnly, LocationFeature} from '../../models/Location';
import {useCardLoader} from '../../utils/Hooks';
import {T, plural} from '../../utils/Internationalization';
import {ICardType, CardTypeKey, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardChargingStationGroup, useCardLocationDetails, useUser} from '../CardUtils';
import {CardActions, ColumnChooser} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import {getAutomationsColumns} from './Columns';

import EditAutomationModal from './edit/EditAutomationModal';

type ChargingStationAutomationSettings = ICardSettingsWithTable;

const rowKey = (value: Automation) => value.id;

function MyCardView(props: ICardProps<ChargingStationAutomationSettings>) {
  const {settings, updateSettings} = props;
  const modals = useModals();
  const {api} = useAppContext();
  const me = useUser();

  const parent = useCardChargingStationGroup(settings);
  const parentId = parent && parent.id;
  const readOnly = isReadOnly(me, parent);
  const locationDetails = useCardLocationDetails(settings);
  const isFeatureAvailable = locationDetails && locationDetails.features.includes(LocationFeature.HomeControl);

  const [automations, refresh] = useCardLoader<Automation[]>(
    api => (parentId ? api.getAutomationsForSmart(parentId, ['CHARGINGSTATION', 'LED']) : Promise.resolve([])),
    [parentId],
    plural('automation'),
    []
  );

  const columns = useMemo(() => {
    const handleClickedEdit = async (automation: Automation) => {
      if (!parentId) return;

      const updatedAutomation = await modals.show<Automation | undefined>(props => (
        <EditAutomationModal automation={automation} locationId={parentId} {...props} />
      ));
      if (updatedAutomation) {
        try {
          await api.updateAutomationIncludingActions(parentId, automation.id, automation, updatedAutomation);
          refresh();
          NotificationManager.success(T('chargingStationAutomations.editAutomation.success'));
        } catch {
          NotificationManager.error(T('chargingStationAutomations.editAutomation.failed'));
        }
      }
    };

    const handleClickedRemove = async (automation: Automation) => {
      if (!parentId) return;

      const confirmed = await modals.show<ConfirmationResult>(props => (
        <ConfirmationPromiseModal
          title={T('chargingStationAutomations.deleteAutomation.title')}
          message={T('chargingStationAutomations.deleteAutomation.message')}
          {...props}
        />
      ));
      if (confirmed === ConfirmationResult.Accept) {
        try {
          await api.deleteAutomation(parentId, automation.id);
          refresh();
          NotificationManager.success(T('chargingStationAutomations.deleteAutomation.success'));
        } catch {
          NotificationManager.error(T('chargingStationAutomations.deleteAutomation.failed'));
        }
      }
    };

    return getAutomationsColumns(
      {
        onClickedEdit: handleClickedEdit,
        onClickedRemove: handleClickedRemove
      },
      readOnly
    );
  }, [api, modals, parentId, readOnly, refresh]);

  const handleClickedAdd = async () => {
    if (!parentId) return;

    const automation = await modals.show<Automation | undefined>(props => (
      <EditAutomationModal locationId={parentId} {...props} />
    ));
    if (automation) {
      try {
        await api.createAutomation(parentId, automation);
        NotificationManager.success(T('chargingStationAutomations.addAutomation.success'));
        refresh();
      } catch {
        NotificationManager.error(T('chargingStationAutomations.addAutomation.failed'));
      }
    }
  };

  const customSettings: CustomSettings<ChargingStationAutomationSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser fields={columns} settings={settings.table} updateSettings={table => updateSettings({table})} />
    );
  };

  const actions: CustomActions = state => (
    <CardActions>
      {isFeatureAvailable && <Reload onReload={refresh} />}
      {/* <Spring /> */}
      {state.ready && (
        <Button variant="secondary_default" size="lg" onClick={handleClickedAdd} className="tw-gap-2">
          <Plus width={16} height={16} />
          {T('chargingStationAutomations.addAutomation')}
        </Button>
      )}
    </CardActions>
  );

  let error: string | undefined;
  if (!isFeatureAvailable) {
    error = T('chargingStationAutomations.error.noChargingStationAutomations');
  }

  return (
    <CardView error={error} actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      <Table
        rowKey={rowKey}
        items={automations}
        fields={columns}
        settings={settings.table}
        noun="automation"
        updateSettings={table => updateSettings({table})}
        emptyMessage={T('chargingStationAutomations.noData')}
      />
    </CardView>
  );
}

const DEFAULT_CARD_SETTINGS: ChargingStationAutomationSettings = {
  table: {
    pageSize: 10,
    sortColumn: 'id',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'id', visible: false},
      {name: 'name', visible: true},
      {name: 'trigger', visible: true},
      {name: 'actions', visible: true}
    ]
  }
};

const CARD: ICardType<ChargingStationAutomationSettings> = {
  type: CardTypeKey.ChargingStationAutomations,
  title: 'chargingStationAutomations.title',
  description: 'chargingStationAutomations.description',
  categories: [CardCategory.CONFIGURATION, CardCategory.EV],
  rights: UserRights.User,
  width: 3,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.Required,
  upgradeSettings: migrateTableSettings('table', DEFAULT_CARD_SETTINGS.table),
  cardClass: MyCardView
};
export default CARD;
