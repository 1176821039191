import {IPowerMessage} from '../livedata/LiveDataModels';

import {IChannelAspectPath} from './Load';

export class TrackingPath {
  evaluator: TrackingPathEvaluator;
  multiplier: number;

  constructor(path: string, multiplier: number, name: string) {
    this.multiplier = multiplier;
    this.evaluator = parseTrackingPath(path, name);
  }

  extract(message: IPowerMessage): number | undefined {
    if (this === NO_TRACKING_PATH) return undefined;

    const result = this.evaluator(message);
    return result === undefined ? undefined : result * this.multiplier;
  }
}
export const NO_TRACKING_PATH: TrackingPath = new TrackingPath('undefined', 0, 'undefined');

export interface IPathObject {
  property: string;
  index: number;
  multiplier: number;
}

export interface IChannelPaths {
  activeConfig: TrackingPath;
  reactiveConfig: TrackingPath;
  currentConfig: TrackingPath;
  phaseConfig: TrackingPath;
  lineConfig: TrackingPath;
}

export function createOptionalPathObject(config: IChannelAspectPath | undefined, name: string): TrackingPath {
  if (!config) return NO_TRACKING_PATH;

  return createPathObject(config, undefined, name);
}

export function createPathObject(config: IChannelAspectPath, scale: number = 1, name: string): TrackingPath {
  let {path, multiplier} = config;
  return new TrackingPath(path, multiplier * scale, name);
}

// for finding the index
const PARTS = /\$\.(.*)\[(\d*)\]/g;
export function getPathIndex(config: IChannelAspectPath): number {
  let {path} = config;
  let matches;
  let index = 0;

  while ((matches = PARTS.exec(path)) !== null) {
    index = parseInt(matches[2]);
  }

  return index;
}

type TrackingPathEvaluator = (message: IPowerMessage) => number | undefined;
type TrackingPathPartial = (message: IPowerMessage) => any;

function parseTrackingPath(path: string, name: string): TrackingPathEvaluator {
  if (!path.startsWith('$.')) {
    console.log(`Warning: invalid tracking path for ${name}: ${path}`);
    return () => undefined;
  }
  let parsing = path.substr(1);

  let result: TrackingPathPartial = message => message;
  while (parsing.length > 0) {
    const expression = result;
    if (parsing.startsWith('.')) {
      parsing = parsing.substr(1);
      const match = parsing.match(/^[a-zA-Z_][a-zA-Z_0-9]*/);
      if (match === null) {
        console.log(`Warning: invalid tracking path for ${name}: ${path}`);
        return () => undefined;
      }

      const member = match[0];
      result = message => {
        const base = expression(message);
        return base === undefined ? undefined : base[member];
      };

      parsing = parsing.substr(member.length);
    } else if (parsing.startsWith('[?(@.')) {
      const match = parsing.match(/\[\?\(@\.([a-zA-Z_]+)==([0-9]+)\)\]/);
      if (match === null) {
        console.log(`Warning: invalid tracking path for ${name}: ${path}`);
        return () => undefined;
      }

      parsing = parsing.substr(match[0].length);
      const queryField = match[1];
      const queryValue = parseInt(match[2]);
      result = message => {
        const array: any[] | undefined = expression(message);
        if (array === undefined) return undefined;

        const index = array.findIndex(element => element[queryField] === queryValue);
        if (index === -1) return undefined;

        return array[index];
      };
    } else if (parsing.startsWith('[')) {
      const match = parsing.match(/\[([0-9]+)\]/);
      if (match === null) {
        console.log(`Warning: invalid tracking path for ${name}: ${path}`);
        return () => undefined;
      }

      parsing = parsing.substr(match[0].length);
      const index = parseInt(match[1]);
      return message => {
        const array = expression(message);
        return array === undefined ? undefined : array[index];
      };
    } else {
      console.log(`Warning: invalid tracking path for ${name}: ${path}`);
      return () => undefined;
    }
  }

  return result;
}
