import {IDailyMeterReading} from '../../models/DailyMeterReading';
import {IHighLevelConfiguration} from '../../models/HighLevelConfiguration';
import {getLoadName, LoadSource} from '../../models/Load';
import {CalculatedNumberField, ITableField, TimestampFieldWithTimezone} from '../../models/Table';
import {T} from '../../utils/Internationalization';

export function getColumns(timezone: string, config: IHighLevelConfiguration | undefined) {
  const result: ITableField<IDailyMeterReading>[] = [
    new TimestampFieldWithTimezone('timestamp', 'timestamp', T('electricityValues.field.timestamp'), timezone, {
      autoInsert: 'start',
      alwaysVisible: true
    })
  ];

  if (config === undefined) return result;

  for (var load of config.measurements) {
    if (load.sourceType !== LoadSource.MID) continue;

    const loadId = load.id;
    const name = getLoadName(load);
    result.push(
      new CalculatedNumberField(
        `import_${load.id}`,
        T('dailyMeterReadings.columns.import', {load: name}),
        item => {
          const value = item.loadValues.find(x => x.id === loadId);
          return value === undefined ? undefined : value.activeEnergyImport;
        },
        {unit: 'kWh', digitsAfterComma: 2, visibleByDefault: true}
      ),
      new CalculatedNumberField(
        `export_${load.id}`,
        T('dailyMeterReadings.columns.export', {load: name}),
        item => {
          const value = item.loadValues.find(x => x.id === loadId);
          return value === undefined ? undefined : value.activeEnergyExport;
        },
        {unit: 'kWh', digitsAfterComma: 2, visibleByDefault: true}
      ),
      new CalculatedNumberField(
        `total_${load.id}`,
        T('dailyMeterReadings.columns.total', {load: name}),
        item => {
          const value = item.loadValues.find(x => x.id === loadId);
          return value === undefined ? undefined : value.activeEnergyTotal;
        },
        {unit: 'kWh', digitsAfterComma: 2, visibleByDefault: true}
      )
    );
  }

  return result;
}
