import React from 'react';

import {classes} from '../utils/Styles';
import {testingClasses} from '../utils/TestingClasses';

import styles from './CenteredErrorView.module.scss';
import {Placeholder} from './ui-lib/icons/medium';

interface ErrorViewProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export default function CenteredErrorView(props: ErrorViewProps) {
  const {style, children} = props;

  return (
    <div className={classes(styles.error, testingClasses.cardError)} data-testid={testingClasses.cardError}>
      <span className="tw-mb-4">
        <Placeholder className="tw-w-12 tw-h-12" />
      </span>
      <p style={style}>{children}</p>
    </div>
  );
}
