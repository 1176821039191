import {CSSProperties, useEffect, useMemo, useRef, useState} from 'react';
import {Table as RsTable} from 'reactstrap';

import {cn} from '../../lib/utils';

import {ICardSettings} from '../../models/CardSettings';
import {ITableField, ITableCellProps, ColumnGroupKey, Field} from '../../models/Table';
import {None} from '../../utils/Arrays';
import {useRefCallback} from '../../utils/Hooks';
import {plural, PluralKey, T} from '../../utils/Internationalization';
import {classes} from '../../utils/Styles';
import CenteredErrorView from '../CenteredErrorView';
import Pagination from '../Pagination';

import styles from './index.module.scss';
import TableColumnHeader from './TableColumnHeader';
import UnknownValue from './UnknownValue';

export enum SortOrder {
  ASCENDING = 'A',
  DESCENDING = 'D'
}
export type ISelectedColumn = ISelectedRow | ISelectedGroup;

export interface ISelectedRow {
  name: string;
  visible: boolean;
  fromGroup?: boolean;
}
export interface ISelectedGroup {
  group: ColumnGroupKey;
  visible: boolean;
}

export function isSelectedGroup(column: ISelectedColumn): column is ISelectedGroup {
  return (column as ISelectedGroup).group !== undefined;
}

export interface IPersistedTableSettings {
  pageSize: number;
  columns: ISelectedColumn[];
  sortColumn?: string;
  sortOrder?: SortOrder;
  grouped?: boolean;
  columnSizes?: {[key: string]: number};
}

export function upgradeTableSettings(
  current: IPersistedTableSettings,
  defaults: IPersistedTableSettings
): IPersistedTableSettings {
  const missing = defaults.columns.filter(column => current.columns.find(c => isSameColumn(c, column)) === undefined);
  if (missing.length > 0) {
    return {
      ...current,
      columns: [...current.columns, ...missing]
    };
  }
  return current;
}

export function migrateTableSettings<T extends ICardSettings>(
  field: Field<T, IPersistedTableSettings>,
  defaultTableSettings: IPersistedTableSettings
) {
  return (settings: Partial<T>) => {
    const oldValue = settings[field] as unknown as IPersistedTableSettings | undefined;
    if (oldValue) {
      const upgradedColumnSettings = upgradeTableSettings(oldValue, defaultTableSettings);
      return {...settings, table: upgradedColumnSettings};
    } else {
      return settings;
    }
  };
}

export function isSameColumn(a: ISelectedColumn, b: ISelectedColumn) {
  if (a === b) return true;
  if ((a as ISelectedGroup).group !== (b as ISelectedGroup).group) {
    return false;
  }
  if ((a as ISelectedRow).name !== (b as ISelectedRow).name) {
    return false;
  }
  return true;
}

export function areTableColumnsEqual(a: ISelectedColumn[], b: ISelectedColumn[]) {
  if (a === b) return true;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    const ca = a[i];
    const cb = b[i];
    if (ca === cb) continue;
    if (ca.visible !== cb.visible) return false;
    if ((ca as ISelectedGroup).group !== (cb as ISelectedGroup).group) {
      return false;
    }
    if ((ca as ISelectedRow).name !== (cb as ISelectedRow).name) return false;
  }
  return true;
}

export function collapseGroups(settings: IPersistedTableSettings, fields: ITableField<unknown>[]): ISelectedColumn[] {
  const selectedGroups = new Set<string>();
  const result: ISelectedColumn[] = [];
  settings.columns.forEach(column => {
    if (isSelectedGroup(column)) {
      result.push(column);
      return;
    }

    const field = fields.find(field => field.name === column.name);
    if (!field) return;

    if (field.options.group) {
      if (column.visible && !selectedGroups.has(field.options.group)) {
        result.push({group: field.options.group, visible: true});
        selectedGroups.add(field.options.group);
      }
    } else {
      result.push(column);
    }
  });
  return result;
}

export function expandGroups(settings: IPersistedTableSettings, fields: ITableField<unknown>[]): ISelectedRow[] {
  return getEffectiveColumns(settings, fields);
}

export function normalizeTableSettings(
  settings: IPersistedTableSettings,
  fields: ITableField<unknown>[]
): IPersistedTableSettings {
  const {columns, grouped} = settings;
  const normalizedColumns = normalizeTableColumns(columns, grouped, fields);
  return {...settings, columns: normalizedColumns};
}

export function normalizeTableColumns(
  columns: ISelectedColumn[],
  grouped: boolean | undefined,
  fields: ITableField<unknown>[]
): ISelectedColumn[] {
  const handledGroups = new Set<string>();
  const handledColumns = new Set<string>();
  const result: ISelectedColumn[] = [];

  columns.forEach(column => {
    if (isSelectedGroup(column)) {
      if (!fields.some(field => field.options.group === column.group)) return;

      if (!handledGroups.has(column.group)) {
        result.push(column);
        handledGroups.add(column.group);
      }
    } else {
      const field = fields.find(field => field.name === column.name);
      if (!field) return;

      if (grouped && field.options.group) {
        if (column.visible && !handledGroups.has(field.options.group)) {
          result.push({group: field.options.group, visible: true});
          handledGroups.add(field.options.group);
        }
      } else {
        result.push(column);
        handledColumns.add(column.name);
      }
    }
  });
  fields.forEach(field => {
    if (grouped && field.options.group) {
      if (!handledGroups.has(field.options.group)) {
        handledGroups.add(field.options.group);
        result.push({group: field.options.group, visible: false});
      }
    } else if (!handledColumns.has(field.name)) {
      result.push({
        name: field.name,
        visible: field.options.visibleByDefault || false
      });
    }
  });
  return result;
}

export function getEffectiveColumns<T>(
  settings: IPersistedTableSettings,
  fields: ITableField<T>[],
  groupUnrolling: boolean = true
): ISelectedRow[] {
  const result: ISelectedRow[] = [];
  const groupsToUnroll = new Map<string, [ITableField<T>[], boolean][]>();
  let splitIndex: number = -1;
  settings.columns.forEach(column => {
    if (isSelectedGroup(column)) {
      const groupFields = fields.filter(field => field.options.group === column.group);
      if (groupUnrolling) {
        if (groupFields.length > 0) {
          const sectionKey = column.group.substr(0, column.group.indexOf('.'));
          let section = groupsToUnroll.get(sectionKey);
          if (section === undefined) {
            groupsToUnroll.set(sectionKey, (section = []));
          }

          section.push([groupFields, column.visible]);
          splitIndex = result.length;
        }
      } else {
        groupFields.forEach(field =>
          result.push({
            name: field.name,
            visible: column.visible,
            fromGroup: true
          })
        );
      }
    } else {
      const field = fields.find(field => field.name === column.name);
      if (field) result.push(column);
    }
  });
  for (var field of fields) {
    if (field.options.visibleByDefault && !settings.columns.some(x => !isSelectedGroup(x) && x.name === field.name)) {
      result.push({name: field.name, visible: true});
    }
  }
  if (groupsToUnroll.size > 0) {
    const unrolled: ISelectedRow[] = [];
    [...groupsToUnroll.values()].forEach(section => {
      const limit = section.reduce((max, element) => Math.max(max, element[0].length), 0);
      for (let i = 0; i < limit; i++) {
        section.forEach(([fields, visible]) => {
          if (i >= fields.length) return;

          unrolled.push({name: fields[i].name, visible, fromGroup: true});
        });
      }
    });
    const insertAt = splitIndex < 0 ? result.length : splitIndex;
    result.splice(insertAt, 0, ...unrolled);
  }
  return result;
}

export function clearOrdering<T>(columns: ISelectedColumn[], fields: ITableField<T>[]) {
  const columnsById = columns.reduce((result, column) => {
    if (!isSelectedGroup(column)) result.set(column.name, column);
    return result;
  }, new Map<string, ISelectedColumn>());
  return fields.map(column => columnsById.get(column.name) || {name: column.name, visible: false});
}

export function getVisibleColumnNames(fields: ITableField<any>[], settings: IPersistedTableSettings): string[] {
  const fixedColumnsStart = fields
    .filter(field => field.options.alwaysVisible && field.options.autoInsert === 'start')
    .map(field => field.name);
  const selectedColumns = getEffectiveColumns<any>(settings, fields);
  const visibleColumns = [...selectedColumns.values()]
    .filter(column => column.visible && fields.some(field => field.name === column.name))
    .map(column => column.name);
  const fixedColumnsEnd = fields
    .filter(field => field.options.alwaysVisible && field.options.autoInsert === 'end')
    .map(field => field.name);

  const columns = [...fixedColumnsStart, ...visibleColumns, ...fixedColumnsEnd];

  for (let i = fields.length - 1; i >= 0; i--) {
    const field = fields[i];
    if (field.options.follows) {
      const index = columns.indexOf(field.options.follows);
      if (index >= 0) columns.splice(index + 1, 0, field.name);
    }
  }

  return columns;
}

const defaultRowKey = (row: any, index: number) => index;
const defaultRowClass = () => '';

interface ITableProps<T> {
  className?: string;
  fields: ITableField<T>[];
  items: T[];
  rowKey?: (item: T, index: number) => string | number;
  noun?: PluralKey;
  hideNounCountLabel?: boolean;
  firstLastNav?: boolean;
  hasPaging?: boolean;
  hidePagingLabel?: boolean;
  fillHeight?: boolean;
  rowClass?: (item: T, index: number) => string;
  style?: CSSProperties;
  settings: IPersistedTableSettings;
  updateSettings: (settings: IPersistedTableSettings) => void;
  onClickedRow?: (item: T) => void;
  noDefaultSort?: boolean;
  emptyMessage?: string;
  selected?: number;
}

export function sortTableItems<T>(
  items: T[],
  sortColumn: ITableField<T> | undefined,
  sortOrder: SortOrder | undefined
) {
  if (sortColumn === undefined) {
    return items;
  } else {
    return items.sort(sortOrder === SortOrder.ASCENDING ? sortColumn.sort : (a, b) => sortColumn.sort(b, a));
  }
}

function Table<T>(props: ITableProps<T>) {
  const {
    items: rawItems,
    fields,
    hasPaging = true,
    hideNounCountLabel = false,
    hidePagingLabel = false,
    ...otherProps
  } = props;

  const [page, setPage] = useState<number>(0);
  const {pageSize, sortColumn, sortOrder} = props.settings;

  const items: ITableQueryResult<T> = useMemo(() => {
    const sortField = fields.find(field => field.name === sortColumn);
    const sortedItems = sortTableItems(rawItems, sortField, sortOrder);
    const offset = page * pageSize;
    const pagedItems = hasPaging ? sortedItems.slice(offset, offset + pageSize) : sortedItems;
    return {items: pagedItems, total: rawItems.length};
  }, [rawItems, fields, hasPaging, page, pageSize, sortColumn, sortOrder]);

  return (
    <ServerPagedTable
      fields={fields}
      items={items}
      page={page}
      onPaginate={hasPaging ? setPage : undefined}
      {...otherProps}
    />
  );
}

interface ILoadedItems<T> {
  items: T[];
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
  hasMore: boolean;
}

interface ILoadingItems<T> {
  promise: Promise<T[]>;
  loadPage: (page: number, pageSize: number, sortColumn?: ITableField<T>, sortOrder?: SortOrder) => Promise<T[]>;
  sortColumn: ITableField<T> | undefined;
  sortOrder: SortOrder | undefined;
}

export function useInfiniteTable<T>(
  settings: IPersistedTableSettings,
  columns: ITableField<T>[],
  loadPage: (page: number, pageSize: number, sortColumn?: ITableField<T>, sortOrder?: SortOrder) => Promise<T[]>
): [number, ITableQueryResult<T>, (page: number) => void, () => void] {
  const [page, setPage] = useState(0);
  const {pageSize, sortColumn: sortColumnName, sortOrder} = settings;
  const [loadedItems, setLoadedItems] = useState<ILoadedItems<T>>({
    items: None,
    sortColumn: sortColumnName,
    sortOrder,
    hasMore: true
  });
  const loadingItems = useRef<ILoadingItems<T>>();

  const items: ITableQueryResult<T> = useMemo(() => {
    const offset = page * pageSize;
    const pagedItems = loadedItems.items.slice(offset, offset + pageSize);
    return {items: pagedItems, hasMore: loadedItems.hasMore};
  }, [loadedItems, page, pageSize]);

  const sortColumn = columns.find(column => column.name === sortColumnName);
  useEffect(() => {
    const loading = loadingItems.current;
    if (
      loading === undefined ||
      sortColumn !== loading.sortColumn ||
      sortOrder !== loading.sortOrder ||
      loadPage !== loading.loadPage
    ) {
      const promise = loadPage(0, (page + 1) * pageSize, sortColumn, sortOrder);
      loadingItems.current = {promise, loadPage, sortColumn, sortOrder};
      promise.then(result =>
        setLoadedItems({
          items: result,
          sortColumn: sortColumn?.name,
          sortOrder,
          hasMore: result.length > (page + 1) * pageSize
        })
      );
    } else if ((page + 1) * pageSize > loadedItems.items.length && loadedItems.hasMore) {
      const promise = loadPage(page, pageSize, sortColumn, sortOrder);
      loadingItems.current = {promise, loadPage, sortColumn, sortOrder};
      promise.then(result =>
        setLoadedItems({
          items: [...loadedItems.items.slice(0, page * pageSize), ...result],
          sortColumn: sortColumn?.name,
          sortOrder,
          hasMore: result.length > pageSize
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPage, page, pageSize, columns, sortColumn?.name, sortOrder]);

  const refresh = useRefCallback(() => {
    loadPage(0, (page + 1) * pageSize, sortColumn, sortOrder).then(items => {
      setLoadedItems({items, sortColumn: sortColumn?.name, sortOrder, hasMore: items.length > page * pageSize});
      if (page * pageSize > items.length) {
        setPage((items.length / pageSize) | 0);
      }
    });
  });

  return [page, items, setPage, refresh];
}

interface IServerPagedTableProps<T> {
  className?: string;

  fields: ITableField<T>[];
  items: ITableQueryResult<T>;
  page: number;
  onPaginate?: (page: number) => void;
  rowKey?: (item: T, index: number) => string | number;
  noun?: PluralKey;
  hideNounCountLabel?: boolean;
  firstLastNav?: boolean;
  fillHeight?: boolean;
  rowClass?: (item: T, index: number) => string;
  style?: CSSProperties;
  settings: IPersistedTableSettings;
  updateSettings: (settings: IPersistedTableSettings) => void;
  onClickedRow?: (item: T) => void;
  noDefaultSort?: boolean;

  emptyMessage?: string;
  selected?: number;
}

interface ITableQueryResult<T> {
  items: T[];
  total?: number;
  hasMore?: boolean;
}

export function ServerPagedTable<T>(props: IServerPagedTableProps<T>) {
  const {
    className,
    fields,
    items,
    page,
    onPaginate,
    rowKey = defaultRowKey,
    noun = 'row',
    hideNounCountLabel = false,
    firstLastNav = true,
    fillHeight = true,
    rowClass = defaultRowClass,
    style,
    settings,
    updateSettings,
    onClickedRow,
    noDefaultSort,
    emptyMessage,
    selected
  } = props;
  const {pageSize} = settings;

  const formatColumnHeader = <T,>(field: ITableField<T>): JSX.Element => {
    const {label, options} = field;
    const {unit} = options;
    const tooltip = options.tooltip || (unit ? `${label} [${unit}]` : label);

    if (field.options.header) {
      const props: ITableCellProps = {
        key: field.name,
        className: classes(styles.columnHeaderWrapper, styles[field.options.align]),
        title: tooltip,
        'data-name': field.name
      };
      return field.options.header(props);
    }
    let style: CSSProperties | undefined;
    if (field.options.width !== undefined) {
      style = {
        width: field.options.width
      };
    }

    return (
      <th
        key={field.name}
        className={classes(styles.columnHeaderWrapper, styles[field.options.align])}
        title={tooltip}
        style={style}
        data-name={field.name}
      >
        <span className={styles.columnHeader}>{label}</span>
        {unit && <span>&nbsp;[{unit}]</span>}
      </th>
    );
  };

  const indexedFields = useMemo(() => new Map(fields.map(field => [field.name, field])), [fields]);

  const [resizing, setResizing] = useState<{
    field: string;
    width: number | undefined;
  }>();

  const defaultSortColumn = useMemo(() => {
    if (noDefaultSort) return undefined;

    const field = fields.find(field => field.options.sortable);
    return field ? field.name : undefined;
  }, [noDefaultSort, fields]);

  const sortOrder = settings.sortOrder || SortOrder.ASCENDING;

  useEffect(() => {
    if (items.total !== undefined && onPaginate) {
      const offset = page * pageSize;
      if (items && offset > items.total) {
        onPaginate((items.total / pageSize) | 0);
      }
    }
  }, [onPaginate, page, pageSize, items]);

  const visibleColumns = useMemo(() => getVisibleColumnNames(fields, settings), [fields, settings]);

  const rows = useMemo(() => {
    const {columnSizes = {}} = settings;
    const visibleItems = items?.items || None;

    const result = visibleItems.map((item, index) => {
      const handleClicked = onClickedRow === undefined ? undefined : () => onClickedRow && onClickedRow(item);
      const className = `${rowClass(item, index)} ${onClickedRow === undefined ? styles.row : styles.clickableRow}`;
      return (
        <tr key={rowKey(item, index)} className={className} onClick={handleClicked}>
          {visibleColumns.map(column => {
            const field = indexedFields.get(column);
            if (!field) return <td key={column} />;

            const style: CSSProperties = {};
            if (field.options.noWrap) style.whiteSpace = 'nowrap';
            if (columnSizes[field.name]) {
              style.width = columnSizes[field.name];
              style.overflow = 'hidden';
            }
            if (field.options.overflow) style.overflow = 'visible';

            let content = field.renderCellContent(item);
            if (content === undefined) {
              return <UnknownValue key={field.name} className={styles[field.options.align] as string} />;
            } else {
              let customWidth: number | undefined = columnSizes[field.name];
              if (resizing && resizing.field === field.name) {
                customWidth = resizing.width;
              }
              if (customWidth !== undefined) {
                const tooltip = typeof content === 'string' ? content : undefined;
                content = (
                  <div
                    style={{
                      width: customWidth,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                    title={tooltip}
                  >
                    {content}
                  </div>
                );
              }

              return (
                <td key={field.name} className={styles[field.options.align] as string} style={style}>
                  {content}
                </td>
              );
            }
          })}
        </tr>
      );
    });
    return result;
  }, [settings, items?.items, onClickedRow, rowClass, rowKey, visibleColumns, indexedFields, resizing]);

  const header = useMemo(() => {
    const handleSortClicked = (field: ITableField<T>, order: SortOrder) => {
      let sortColumn: string | undefined = field.name;
      if (noDefaultSort && sortOrder === SortOrder.DESCENDING) {
        sortColumn = undefined;
      }

      updateSettings({...settings, sortColumn, sortOrder: order});
    };

    const handleResize = (field: ITableField<T>, width: number | undefined) => {
      const columnSizes = settings.columnSizes || {};
      if (width === undefined) {
        const newCustomSizes: {[key: string]: number} = {...columnSizes};
        delete newCustomSizes[field.name];
        updateSettings({...settings, columnSizes: newCustomSizes});
      } else {
        const newCustomSizes: {[key: string]: number} = {
          ...columnSizes,
          [field.name]: width
        };
        updateSettings({...settings, columnSizes: newCustomSizes});
      }
    };

    const handleResizing = (field: ITableField<T>, width: number | undefined) => {
      setResizing({field: field.name, width});
    };

    const renderColumnHeader = (field: ITableField<T>) => {
      const {sortable} = field.options;
      if (sortable) {
        return (
          <TableColumnHeader
            key={field.name}
            field={field}
            settings={settings}
            defaultSortColumn={defaultSortColumn}
            onSortClicked={handleSortClicked}
            onResizing={handleResizing}
            onResize={handleResize}
          />
        );
      } else return formatColumnHeader(field);
    };

    const cells = visibleColumns.map(column => {
      const field = indexedFields.get(column);
      return field ? (
        renderColumnHeader(field)
      ) : (
        <th key={column} data-name={column}>
          {column}
        </th>
      );
    });
    return (
      <thead key="header">
        <tr>{cells}</tr>
      </thead>
    );
  }, [defaultSortColumn, indexedFields, noDefaultSort, settings, sortOrder, updateSettings, visibleColumns]);

  const handlePaginate = (newOffset: number, limit: number) => {
    const offset = page * pageSize;
    if (newOffset !== offset && onPaginate) onPaginate(newOffset / pageSize);
    if (limit !== pageSize) updateSettings({...settings, pageSize: limit});
  };

  return (
    <div className={classes(styles.wrapper, fillHeight && styles.fillHeight)} style={style}>
      {items === undefined ? (
        <span />
      ) : items.items.length === 0 ? (
        <CenteredErrorView>{emptyMessage || T('generic.noDataOfType', {entity: plural(noun)})}</CenteredErrorView>
      ) : (
        <>
          <div className={styles.tableContainer}>
            <RsTable className={classes(styles.table, className)}>
              {header}
              <tbody key="body">{rows}</tbody>
            </RsTable>
          </div>
          {onPaginate && (
            <Pagination
              className={cn(styles.pagination, 'tw-gap-3')}
              onPaginate={handlePaginate}
              noun={noun}
              hideNounCountLabel={hideNounCountLabel}
              firstLastNav={firstLastNav}
              limit={pageSize}
              offset={page * pageSize}
              total={items.total}
              hasMore={items.hasMore || false}
              selected={selected}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Table;
