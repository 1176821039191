import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {RFIDCard} from '../../models/RFIDCard';
import {translateError} from '../../utils/Errors';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {useObjectState} from '../../utils/ObjectState';
import {validateSpecialChars} from '../../utils/Validation';

interface EditChargingTokenModalProps extends IPromiseModalProps<boolean> {
  card: RFIDCard;
  organizationId: number;
}

export function EditChargingTokenModal(props: EditChargingTokenModalProps) {
  const {card, organizationId} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const form = useFormState();
  const [state, updateFormState] = useObjectState({name: card.name, employeeNumber: card.employeeNumber || ''});

  const handleClickedSave = async () => {
    if (form.hasErrors()) {
      return '';
    }

    const cardId = parseInt(card.id.substring(3)); // RF-${id}
    return api.organizations
      .updatePublicChargingToken(organizationId, cardId, {
        name: state.name,
        employeeNumber: state.employeeNumber
      })
      .then(() => {
        resolve(true);
        return undefined;
      })
      .catch((err: unknown) => translateError(err));
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('publicChargingTokens.edit.title')}
      action={handleClickedSave}
      actionText={T('publicChargingTokens.edit.save')}
    >
      <FormContextProvider value={form}>
        <TextInputGroup
          form={form}
          name="rfid"
          label={T('publicChargingTokens.field.value')}
          value={card.value}
          onChange={() => {}}
          disabled
        />
        <TextInputGroup
          form={form}
          name="name"
          label={T('publicChargingTokens.field.name')}
          value={state.name}
          onChange={name => updateFormState({name})}
          validate={validateSpecialChars}
        />
        <TextInputGroup
          form={form}
          name="employeeNumber"
          label={T('publicChargingTokens.field.employeeNumber')}
          value={state.employeeNumber}
          onChange={employeeNumber => updateFormState({employeeNumber})}
        />
        <TextInputGroup
          form={form}
          name="email"
          label={T('publicChargingTokens.user.email')}
          value={card.userEmailAddress || ''}
          disabled
          onChange={() => {}}
        />
      </FormContextProvider>
    </SingleActionModal>
  );
}
