import React, {useState, useMemo} from 'react';

import {useAppContext} from '../../app/context';
import {
  Button as RsButton,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormText
} from '../../components/bootstrap';

import FormInputGroup from '../../components/inputs/FormInputGroup';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {PrinterInput} from '../../components/inputs/PrinterInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IOrganizationActivationCode, getAvailableActivationCodesForOrganization} from '../../models/ActivationCode';
import {IOrganization} from '../../models/Organization';
import {usePrinter} from '../../server/Printers';
import {None} from '../../utils/Arrays';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {useOrganizationActivationCodes} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {validateOrderNr} from '../../utils/Validation';

import {getErrorForAssignmentResponse} from './ErrorHandling';
import {printLabels} from './printlabels';

// String constants
const INVALID_TEXT = '[INVALID]';
const SERIAL_NUMBER_REGEX = /^[0-9]{10}$/;
const GENIUS_SERIAL_REGEX = /^SSN([0-9]{10})MOD-GW-[0-9]$/;
const LEGACY_SERIAL_REGEX = /^SSN([0-9]{10})EMONITOR1$/;
const SM_REGEX = /^SW_([0-9]1{10})_[0-9]{2}$/;
const SOLAR = /^(110[0-9]{7})S$/;
const EVLINE_REGEX = /^[A-Z0-9-]+_(6[0-9]{9})$/;
const P1S1_REGEX = /^SNL([0-9]{10})$/;
const KIT_REGEX = /^KIT_([0-9]{10})$/;

function getVariant(organization: IOrganization | undefined) {
  if (organization && organization.name.toLowerCase() === 'evbox') {
    return 'evbox';
  }

  return 'default';
}

interface AssignActivationCodePromiseModalProps extends IPromiseModalProps<boolean> {
  organization: IOrganization | undefined;
  activationCode: IOrganizationActivationCode | undefined;
  onAssigned: () => void;
  onSaved: (organization: IOrganization, activationCode: IOrganizationActivationCode) => void;
}
export const AssignActivationCodesPromiseModal = (props: AssignActivationCodePromiseModalProps) => {
  const {onAssigned, onSaved, fetch} = props;
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const [orderNr, setOrderNr] = useState('');
  const form = useFormState();

  const handleToggle = () => resolve(false);
  const handleSaved = (organization: IOrganization, activationCode: IOrganizationActivationCode) => {
    onSaved(organization, activationCode);
    resolve(true);
  };

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState(props.organization);

  const [ownActivationCodes] = useOrganizationActivationCodes(fetch, organization?.id);
  const [parentActivationCodes] = useOrganizationActivationCodes(fetch, organization?.parentId);
  const activationCodes = useMemo(() => {
    return organization
      ? getAvailableActivationCodesForOrganization(ownActivationCodes, parentActivationCodes, organization)
      : None;
  }, [ownActivationCodes, parentActivationCodes, organization]);

  const [activationCode = activationCodes[0], setActivationCode] = useState<IOrganizationActivationCode | undefined>(
    props.activationCode
  );
  const [serialsText, setSerialsText] = useState('');
  const [serials, setSerials] = useState<string[]>([]);
  const [assigned, setAssigned] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [printer, setPrinter] = usePrinter();

  const activationCodeOptions = useMemo(
    () =>
      activationCodes.map(({name, code}) => (
        <option key={code} value={code}>
          {name || code}
        </option>
      )),
    [activationCodes]
  );

  const handleChangeCode = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const {value} = event.currentTarget;
    setActivationCode(activationCodes.find(x => x.code === value));
  };

  const handleChangeSerialNumbers = (event: React.SyntheticEvent<HTMLInputElement>) => {
    let {value} = event.currentTarget;
    const serialsText = value.trim().split(/\r?\n/);
    const hasTrailingEmptyLine = value.endsWith('\r\n');
    const lastI = serialsText.length - 1;
    let lastElement = serialsText[lastI];
    const items = [];

    // Remove invalid text if changing lines to be valid
    for (let i = 0; i < serialsText.length; i++) {
      let serial = serialsText[i];
      const rawSerial = serial.replace(INVALID_TEXT, '').trim();
      const isValidSerial = SERIAL_NUMBER_REGEX.test(rawSerial);

      if (serial.endsWith(INVALID_TEXT) && isValidSerial) {
        serial = rawSerial;
      }

      items.push(serial);
    }

    // Strip last entered element of text
    if (!hasTrailingEmptyLine) {
      const isGenius = GENIUS_SERIAL_REGEX.exec(lastElement);
      if (isGenius) items[items.length - 1] = isGenius[1];

      const isLegacy = LEGACY_SERIAL_REGEX.exec(lastElement);
      if (isLegacy) items[items.length - 1] = isLegacy[1];

      const isSolar = SOLAR.exec(lastElement);
      if (isSolar) items[items.length - 1] = isSolar[1];

      const isEVLine = EVLINE_REGEX.exec(lastElement);
      if (isEVLine) items[items.length - 1] = isEVLine[1];

      const isP1S1 = P1S1_REGEX.exec(lastElement);
      if (isP1S1) items[items.length - 1] = isP1S1[1];

      const isKit = KIT_REGEX.exec(lastElement);
      if (isKit) items[items.length - 1] = isKit[1];

      const isNewSerialNumber = SM_REGEX.exec(lastElement);
      if (isNewSerialNumber) items[items.length - 1] = isNewSerialNumber[1];

      if (SERIAL_NUMBER_REGEX.test(lastElement)) items.push('');
    }

    const newValue = items.join('\r\n');
    setSerialsText(newValue);
    setErrorMessage(undefined);
  };

  const handleClickAssign = async () => {
    const isValid = validateForm();
    if (!isValid) {
      console.log('Form validation error');
      return;
    }
    if (!activationCode) {
      console.log('No activation code selected');
      return;
    }

    const {code} = activationCode;
    let serialNumbers = serialsText.split(/\r?\n/);
    setLoading(true);
    setErrorMessage(undefined);

    // Filter out empty items and duplicates
    serialNumbers = serialNumbers.filter(item => item !== '');
    serialNumbers = [...new Set(serialNumbers)];

    // Assign activation codes
    try {
      const result = await api.activationCodes.assign(code, serialNumbers, orderNr);
      onAssigned();
      setLoading(false);
      const error = getErrorForAssignmentResponse(result);
      setErrorMessage(error);
      if (error === undefined) {
        setSerials(serialNumbers);
        setAssigned(true);
      }
    } catch {
      setLoading(false);
      setErrorMessage('Failed to assign activation codes');
    }
  };

  const handleClickPrint = async () => {
    if (!organization || !activationCode) return;

    const printableSerials = serials.filter(serial => !serial.startsWith('6'));
    const success = await printLabels(api.getToken(), getVariant(organization), printableSerials, printer);
    if (success) handleSaved(organization, activationCode);
  };

  const validateForm = () => {
    if (form.hasErrors()) {
      form.showErrors();
      return;
    }
    const serialsTextSplit = serialsText.trim().split(/\r?\n/);
    const items = [];

    // Check if serial numbers are valid syntactically
    let isValidForm = true;
    for (let serial of serialsTextSplit) {
      serial = serial.replace(INVALID_TEXT, '').trim();
      const isValid = SERIAL_NUMBER_REGEX.test(serial);

      // Append invalid text to the serial
      if (serial === '') {
        continue;
      } else if (!isValid) {
        isValidForm = false;
        serial = `${serial} ${INVALID_TEXT}`;
      }

      items.push(serial);
    }

    // Join serial numbers again on separate lines
    const joined = items.join('\r\n');
    setSerialsText(joined);
    return isValidForm;
  };

  const renderOrganizationInput = () => {
    return (
      <FormInputGroup name="organization" label="Organization">
        <OrganizationInput
          organizations={inputOrganizations.organizations}
          value={organization}
          onChange={setOrganization}
          onUpdateQuery={updateOrganizationInputQuery}
        />
        <FormFeedback>No organizations with available activation codes</FormFeedback>
      </FormInputGroup>
    );
  };

  const renderActivationCodeInput = () => {
    const id = activationCode !== undefined ? activationCode.code : undefined;
    const supportedTypes = activationCode ? activationCode.supportedTypes.join(', ') : '';

    return (
      <FormInputGroup
        name="activation-code"
        label="Activation code"
        info={supportedTypes && `Valid for ${supportedTypes}`}
      >
        <Input
          type="select"
          name="activationCode"
          value={id || ''}
          invalid={activationCodeOptions.length === 0}
          onChange={handleChangeCode}
          disabled={organization === undefined}
        >
          {activationCodeOptions}
        </Input>
        <FormFeedback>{T('shipment.assign.noneForOrganization')}</FormFeedback>
      </FormInputGroup>
    );
  };

  const renderSerialNumbers = () => {
    const escapedText = INVALID_TEXT.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const matchInvalids = new RegExp(escapedText, 'g');
    const errors = (serialsText.match(matchInvalids) || []).length;
    const count = serialsText.trim().split(/\r?\n/).length;

    return (
      <FormGroup>
        <Label for="serialsText">Serial numbers {count > 1 && ` (${count})`}</Label>
        <Input
          key="serialsText"
          name="serialsText"
          type="textarea"
          invalid={errors > 0}
          value={serialsText || ''}
          onChange={handleChangeSerialNumbers}
          rows={10}
        />
        <FormFeedback>
          {errors} validation error{errors !== 1 && 's'} marked {INVALID_TEXT}
        </FormFeedback>
        <FormText>Enter one valid serial number on each line</FormText>
      </FormGroup>
    );
  };

  const renderAssigning = () => {
    return (
      <>
        {renderOrganizationInput()}
        {renderActivationCodeInput()}
        <TextInputGroup
          name="orderNr"
          label={T('shipment.orderNr')}
          info={T('shipment.orderNr.info')}
          value={orderNr}
          onChange={setOrderNr}
          validate={validateOrderNr}
        />
        {renderSerialNumbers()}
      </>
    );
  };

  const renderPrinting = () => {
    const count = serials.length;

    return (
      <>
        <FormGroup>
          <Label for="serialsText">
            Activated {count} device{count !== 1 ? 's' : ''}
          </Label>
        </FormGroup>
        <FormInputGroup name="printer" label="Printer">
          <PrinterInput value={printer} onChange={setPrinter} />
        </FormInputGroup>
      </>
    );
  };

  const matchInvalids = new RegExp(INVALID_TEXT, 'g');
  const errors = (serialsText.match(matchInvalids) || []).length;
  const hasSerials = Array.isArray(serials) && serials.length > 0;
  const printableSerials = serials.filter(serial => !serial.startsWith('6'));
  const hasPrintableSerials = printableSerials.length > 0;
  const serialsParam = hasPrintableSerials ? printableSerials.join(',') : '';

  return (
    <FormContextProvider value={form}>
      <Modal isOpen={isOpen} toggle={handleToggle} size="md" autoFocus={false} loading={loading}>
        <ModalHeader toggle={handleToggle}>Assign activation codes</ModalHeader>
        <ModalBody>
          {!assigned && renderAssigning()}
          {assigned && renderPrinting()}
        </ModalBody>
        <ModalFooter error={errorMessage}>
          {!assigned && (
            <RsButton onClick={handleClickAssign} disabled={serialsText.trim() === '' || errors > 0}>
              Assign activation codes
            </RsButton>
          )}

          {assigned && hasPrintableSerials && (
            <>
              <RsButton
                href={`/device-labels/${serialsParam}?variant=${getVariant(organization)}&token=${api.getToken()}`}
                target="_blank"
                disabled={!hasSerials}
              >
                Preview labels
              </RsButton>
              <RsButton onClick={handleClickPrint} disabled={!hasSerials}>
                Send labels to printer
              </RsButton>
            </>
          )}
        </ModalFooter>
      </Modal>
    </FormContextProvider>
  );
};
