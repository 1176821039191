import {SeriesBoxplotOptions} from 'highcharts';

import {SeriesLineOptions} from 'highcharts';
import {Series} from 'highcharts/highstock';
import numeral from 'numeral';
import React, {useCallback, useState} from 'react';

import {customFormatPoints, ChartConfig, customFormatTimestamp, getDateTimeLabelFormats} from '../../components/Chart';

import HighStockChart from '../../components/HighStockChart';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {ILoad} from '../../models/Load';
import {Phase, getPhaseLabel} from '../../models/Phase';
import {None} from '../../utils/Arrays';
import {createPointFormatter} from '../../utils/GraphUtils';
import {T, rank} from '../../utils/Internationalization';

import {HarmonicsHistoryLoadData, HarmonicsStatistics, HarmonicsStatisticsEntry} from './HarmonicsStatistics';
import styles from './index.module.scss';

export const HARMONICS = ['THD', 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 17, 19];
export const USED_HARMONICS: boolean[] = [];
for (let i = 0; i <= 19; i++) USED_HARMONICS.push(HARMONICS.includes(i));

function createBoxFormatter(name: string, unit: string, decimals: number): (this: any) => string {
  const format = decimals === 0 ? '0' : `0.${'0'.repeat(decimals)}`;
  return function () {
    const point = this.point as {
      low: number;
      q1: number;
      median: number;
      q3: number;
      high: number;
    };
    if (!point) {
      return `<span style="color:${this.color}">\u25CF</span> ${name}: --`;
    }

    return (
      `<span style="color:${this.color}">\u25CF</span> <span>${name}</span>:<br />` +
      `<span>-- Minimum</span>: <b>${numeral(point.low).format(format)} ${unit}</b><br/>` +
      `<span>-- 5th percentile</span>: <b>${numeral(point.q1).format(format)} ${unit}</b><br />` +
      `<span>-- Median</span>: <b>${numeral(point.median).format(format)} ${unit}</b><br />` +
      `<span>-- 95th percentile</span>: <b>${numeral(point.q3).format(format)} ${unit}</b><br />` +
      `<span>-- Maximum</span>: <b>${numeral(point.high).format(format)} ${unit}</b><br />`
    );
  };
}

function harmonicsTooltipFormatter(this: Highcharts.TooltipFormatterContextObject): string {
  let label =
    this.x === 0
      ? T('liveHarmonics.totalHarmonicDistortion')
      : T('liveHarmonics.xAxisLabel', {
          rank: rank(HARMONICS[this.x as number] as number)
        });
  //let label = T('liveHarmonics.xAxisLabel', { rank: rank(HARMONICS[this.x] as number) });
  let result = `<span style="font-size: 10px">${label}</span><br/>`;
  if (this.points) result += customFormatPoints(this.points);
  return result;
}

function xAxisFormatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
  return (HARMONICS[this.value as number] || `!${this.value}`).toString();
}

const VoltageColors = {
  [Phase.L1]: '#5F3711', // brown
  [Phase.L2]: '#000000', // black
  [Phase.L3]: '#A9B0B3' // gray
};
const CurrentColors = {
  [Phase.L1]: '#f28f43', // orange
  [Phase.L2]: '#c42525', // red
  [Phase.L3]: '#1aadce' // turquise
};

function createCurrentStatisticsSeries(
  stats: (HarmonicsStatisticsEntry | undefined)[],
  phaseType: PhaseType,
  phase: Phase,
  hiddenSeries: string[]
): [SeriesBoxplotOptions, SeriesBoxplotOptions] {
  const label = T('liveHarmonics.series.current', {
    phase: getPhaseLabel(phaseType, phase)
  });
  const processedStats = stats
    .filter((item, index) => index === 0 || USED_HARMONICS[index])
    .map((entry, index) =>
      entry === undefined
        ? [index, null, null, null, null, null]
        : [index, entry.min, entry.percentile5, entry.median, entry.percentile95, entry.max]
    );

  const tdi: SeriesBoxplotOptions = {
    id: `${phase}TDI`,
    name: label,
    color: CurrentColors[phase],
    type: 'boxplot',
    data: [processedStats[0]],
    visible: !hiddenSeries.includes(`${phase}I`),
    showInLegend: false,
    tooltip: {
      pointFormatter: createBoxFormatter(label, '%', 2)
    },
    yAxis: 0
  };
  processedStats[0] = [0];

  const harmonics: SeriesBoxplotOptions = {
    id: `${phase}I`,
    name: label,
    color: CurrentColors[phase],
    type: 'boxplot',
    data: processedStats,
    tooltip: {
      pointFormatter: createBoxFormatter(label, 'A', 1)
    },
    yAxis: 1
  };
  return [tdi, harmonics];
}

function createVoltageStatisticsSeries(
  stats: (HarmonicsStatisticsEntry | undefined)[],
  phaseType: PhaseType,
  phase: Phase
): SeriesBoxplotOptions {
  const label = T('liveHarmonics.series.voltage', {
    phase: getPhaseLabel(phaseType, phase)
  });
  const processedStats = stats
    .filter((item, index) => index === 0 || USED_HARMONICS[index])
    .map((entry, index) =>
      entry === undefined
        ? [index, null, null, null, null, null]
        : [index, entry.min, entry.percentile5, entry.median, entry.percentile95, entry.max]
    );
  return {
    id: `${phase}V`,
    name: label,
    color: VoltageColors[phase],
    type: 'boxplot',
    data: processedStats,
    tooltip: {
      pointFormatter: createBoxFormatter(label, '%', 2)
    },
    yAxis: 0
  };
}

interface HarmonicsStatisticsProps {
  statistics: HarmonicsStatistics;
  load: ILoad;
  showVoltages: boolean;
  showCurrents: boolean;
  phaseType: PhaseType;
  availableHarmonics: number[];
}

export const HarmonicsStatisticsChart = (props: HarmonicsStatisticsProps) => {
  const {statistics, showVoltages, showCurrents, load, phaseType, availableHarmonics} = props;

  const [hiddenSeries, setHiddenSeries] = useState<string[]>(None);

  const phases = load.actuals.map(channel => channel.phase);
  const series: SeriesBoxplotOptions[] = [];
  if (showCurrents) {
    const currentData = [
      [Phase.L1, statistics.harmonicsPhaseACurrent],
      [Phase.L2, statistics.harmonicsPhaseBCurrent],
      [Phase.L3, statistics.harmonicsPhaseCCurrent]
    ] as [Phase, (HarmonicsStatisticsEntry | undefined)[]][];

    currentData.forEach(([phase, data]) => {
      if (phases.includes(phase) && data.length > 0) {
        createCurrentStatisticsSeries(data, phaseType, phase, hiddenSeries).forEach(serie => series.push(serie));
      }
    });
  }
  if (showVoltages) {
    const voltageData = [
      [Phase.L1, statistics.harmonicsPhaseAVoltage],
      [Phase.L2, statistics.harmonicsPhaseBVoltage],
      [Phase.L3, statistics.harmonicsPhaseCVoltage]
    ] as [Phase, (HarmonicsStatisticsEntry | undefined)[]][];

    voltageData.forEach(([phase, data]) => {
      if (phases.includes(phase) && data.length > 0) {
        series.push(createVoltageStatisticsSeries(data, phaseType, phase));
      }
    });
  }

  const handleHideSeries = useCallback(function (this: Series) {
    setHiddenSeries(series => [...series, this.userOptions.id!]);
  }, []);
  const handleShowSeries = useCallback(function (this: Series) {
    setHiddenSeries(series => series.filter(x => x !== this.userOptions.id));
  }, []);

  const config: ChartConfig = {
    series,
    navigator: {
      enabled: false
    },
    plotOptions: {
      series: {
        events: {
          show: handleShowSeries,
          hide: handleHideSeries
        }
      }
    },
    scrollbar: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      dateTimeLabelFormats: getDateTimeLabelFormats(),
      labels: {
        formatter: xAxisFormatter
      }
    },
    yAxis: [
      {
        title: {text: '%'},
        opposite: true
      },
      {
        title: {text: 'A'},
        visible: showCurrents
      }
    ],
    tooltip: {
      formatter: harmonicsTooltipFormatter,
      shared: true,
      split: false,
      enabled: true
    }
  };
  return <HighStockChart className={styles.stretch} config={config} from={0} to={availableHarmonics.length} />;
};

function createAmpereSeries(
  interval: [number, number][],
  name: string,
  digits: number,
  color: string | undefined
): SeriesLineOptions {
  return {
    name,
    color,
    type: 'line',
    data: interval,
    tooltip: {
      pointFormatter: createPointFormatter(name, 'A', digits)
    }
  };
}

function createPercentSeries(
  interval: [number, number][],
  name: string,
  digits: number,
  color: string | undefined
): SeriesLineOptions {
  return {
    name,
    color,
    type: 'line',
    data: interval,
    yAxis: 1,
    tooltip: {
      pointFormatter: createPointFormatter(name, '%', digits)
    }
  };
}

export interface HarmonicHistoryColorScheme {
  getCurrentTHDColor: (phase: Phase) => string | undefined;
  getVoltageTHDColor: (phase: Phase) => string | undefined;
  getCurrentColor: (phase: Phase, harmonic: number, index: number) => string | undefined;
  getVoltageColor: (phase: Phase, harmonic: number, index: number) => string | undefined;
}

interface HarmonicsHistoryProps {
  phaseType: PhaseType;
  load: ILoad;
  harmonics: HarmonicsHistoryLoadData;
  showHarmonics: number[];
  showCurrents: boolean;
  showVoltages: boolean;
  colorScheme: HarmonicHistoryColorScheme;
  timezone: string;
}
export const HarmonicsHistoryChart = (props: HarmonicsHistoryProps) => {
  const {phaseType, harmonics, showHarmonics, showCurrents, showVoltages, load, colorScheme, timezone} = props;
  const {seriesIndices} = harmonics;
  const series: SeriesLineOptions[] = [];
  const phases = load.actuals.map(channel => channel.phase);

  if (showCurrents) {
    if (phases.includes(Phase.L1)) {
      series.push(
        createPercentSeries(
          harmonics.currentsForL1[0],
          T('harmonics.currentSeriesTHDLabel', {
            phase: getPhaseLabel(phaseType, Phase.L1)
          }),
          1,
          colorScheme.getCurrentTHDColor(Phase.L1)
        )
      );
    }
    if (phases.includes(Phase.L2)) {
      series.push(
        createPercentSeries(
          harmonics.currentsForL2[0],
          T('harmonics.currentSeriesTHDLabel', {
            phase: getPhaseLabel(phaseType, Phase.L2)
          }),
          1,
          colorScheme.getCurrentTHDColor(Phase.L2)
        )
      );
    }
    if (phases.includes(Phase.L3)) {
      series.push(
        createPercentSeries(
          harmonics.currentsForL3[0],
          T('harmonics.currentSeriesTHDLabel', {
            phase: getPhaseLabel(phaseType, Phase.L3)
          }),
          1,
          colorScheme.getCurrentTHDColor(Phase.L3)
        )
      );
    }
  }
  if (showVoltages) {
    if (phases.includes(Phase.L1)) {
      series.push(
        createPercentSeries(
          harmonics.voltagesForL1[0],
          T('harmonics.voltageSeriesTHDLabel', {
            phase: getPhaseLabel(phaseType, Phase.L1)
          }),
          1,
          colorScheme.getVoltageTHDColor(Phase.L1)
        )
      );
    }
    if (phases.includes(Phase.L2)) {
      series.push(
        createPercentSeries(
          harmonics.voltagesForL2[0],
          T('harmonics.voltageSeriesTHDLabel', {
            phase: getPhaseLabel(phaseType, Phase.L2)
          }),
          1,
          colorScheme.getVoltageTHDColor(Phase.L2)
        )
      );
    }
    if (phases.includes(Phase.L3)) {
      series.push(
        createPercentSeries(
          harmonics.voltagesForL3[0],
          T('harmonics.voltageSeriesTHDLabel', {
            phase: getPhaseLabel(phaseType, Phase.L3)
          }),
          1,
          colorScheme.getVoltageTHDColor(Phase.L3)
        )
      );
    }
  }

  showHarmonics.forEach((harmonicIndex, i) => {
    const index = seriesIndices[harmonicIndex];
    if (index === undefined) return;

    if (showCurrents) {
      const currentL1 = harmonics.currentsForL1[index];
      const currentL2 = harmonics.currentsForL2[index];
      const currentL3 = harmonics.currentsForL3[index];
      if (currentL1.length > 0) {
        series.push(
          createAmpereSeries(
            currentL1,
            T('harmonics.currentSeriesLabel', {
              rank: rank(harmonicIndex),
              phase: getPhaseLabel(phaseType, Phase.L1)
            }),
            1,
            colorScheme.getCurrentColor(Phase.L1, harmonicIndex, i)
          )
        );
      }
      if (currentL2.length > 0) {
        series.push(
          createAmpereSeries(
            currentL2,
            T('harmonics.currentSeriesLabel', {
              rank: rank(harmonicIndex),
              phase: getPhaseLabel(phaseType, Phase.L2)
            }),
            1,
            colorScheme.getCurrentColor(Phase.L2, harmonicIndex, i)
          )
        );
      }
      if (currentL3.length > 0) {
        series.push(
          createAmpereSeries(
            currentL3,
            T('harmonics.currentSeriesLabel', {
              rank: rank(harmonicIndex),
              phase: getPhaseLabel(phaseType, Phase.L3)
            }),
            1,
            colorScheme.getCurrentColor(Phase.L3, harmonicIndex, i)
          )
        );
      }
    }

    if (showVoltages) {
      const voltageL1 = harmonics.voltagesForL1[index];
      const voltageL2 = harmonics.voltagesForL2[index];
      const voltageL3 = harmonics.voltagesForL3[index];
      if (voltageL1.length > 0) {
        series.push(
          createPercentSeries(
            voltageL1,
            T('harmonics.voltageSeriesLabel', {
              rank: rank(harmonicIndex),
              phase: getPhaseLabel(phaseType, Phase.L1)
            }),
            1,
            colorScheme.getVoltageColor(Phase.L1, harmonicIndex, i)
          )
        );
      }
      if (voltageL2.length > 0) {
        series.push(
          createPercentSeries(
            voltageL2,
            T('harmonics.voltageSeriesLabel', {
              rank: rank(harmonicIndex),
              phase: getPhaseLabel(phaseType, Phase.L2)
            }),
            1,
            colorScheme.getVoltageColor(Phase.L2, harmonicIndex, i)
          )
        );
      }
      if (voltageL3.length > 0) {
        series.push(
          createPercentSeries(
            voltageL3,
            T('harmonics.voltageSeriesLabel', {
              rank: rank(harmonicIndex),
              phase: getPhaseLabel(phaseType, Phase.L3)
            }),
            1,
            colorScheme.getVoltageColor(Phase.L3, harmonicIndex, i)
          )
        );
      }
    }
  });

  const config: ChartConfig = {
    series,
    yAxis: [
      {
        title: {text: 'A'},
        visible: showCurrents
      },
      {
        title: {text: '%'},
        opposite: true
      }
    ],
    tooltip: {
      shared: true,
      split: false,
      formatter: function (this: any) {
        return customFormatTimestamp(this, timezone);
      }
    }
  };
  return <HighStockChart className={styles.stretch} config={config} />;
};
