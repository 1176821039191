import {T} from '../utils/Internationalization';
import {TranslationKey} from '../utils/TranslationTerms';

export interface IDeviceConfiguration {
  pending?: IDeviceConfigurationState;
  actual?: IDeviceConfigurationState;
}

export interface IDeviceConfigurationState {
  autoCommissioningAction: number;
  serialNumber: string;
  serviceLocationId: number;
  is60Hz: boolean;
  isLoad: boolean;
  isDelta: boolean;
  monitorMode: number;
  monitorWorkingMode: number;
  phaseMapping: number;
  phaseReverser: number;
  inputsConnected: number;
  isACValidationRunning: boolean;
  isACInstantRunning: boolean;
  singleVoltageShift: number;
  createdUtcStamp: number;
  createdBy: number;
  status: number;
  inputMask: number;

  sequence?: number; // does this still exist?
}

class WorkingMode {
  id: number;
  name: TranslationKey;
  mappingMask: number;
  mappingCheck: number;

  constructor(id: number, name: TranslationKey, mappingMask: number, mappingCheck: number) {
    this.id = id;
    this.name = name;
    this.mappingMask = mappingMask;
    this.mappingCheck = mappingCheck;
  }
}

const INPUT_CT1 = 1;
const INPUT_CT2 = 2;
const INPUT_CT3 = 4;
const INPUT_CT4 = 8;
const INPUT_CT5 = 16;
const INPUT_CT6 = 32;
const INPUT_CT7 = 64;
const INPUT_CT8 = 128;
const INPUT_CT9 = 256;
const INPUT_ALL = 63;
const INPUT_ALL_PRO = 511;
const INPUT_NONE = 0;
const INPUT_3PHASE = INPUT_CT1 + INPUT_CT2 + INPUT_CT3;
const INPUT_3SOLAR = INPUT_CT4 + INPUT_CT5 + INPUT_CT6;

const MODES = [
  new WorkingMode(0, 'workingMode.singlePhase', INPUT_ALL, INPUT_CT1),
  new WorkingMode(1, 'workingMode.singlePhaseSolar', INPUT_ALL, INPUT_CT1 + INPUT_CT4),
  new WorkingMode(2, 'workingMode.splitPhaseUs', INPUT_ALL, INPUT_CT1 + INPUT_CT3),
  new WorkingMode(3, 'workingMode.solarOnly1Phase', INPUT_ALL, INPUT_CT4),
  new WorkingMode(4, 'workingMode.3phase', INPUT_ALL, INPUT_CT1 + INPUT_CT2 + INPUT_CT3),
  new WorkingMode(5, 'workingMode.3single', INPUT_3SOLAR, INPUT_NONE),
  new WorkingMode(6, 'workingMode.solarOnly3Single', INPUT_3PHASE, INPUT_NONE),
  new WorkingMode(7, 'workingMode.solarOnly3Phase', INPUT_ALL, INPUT_3SOLAR),
  new WorkingMode(8, 'workingMode.solarOnlySplitPhase', INPUT_ALL, INPUT_CT4 + INPUT_CT6),
  new WorkingMode(9, 'workingMode.autodetect', INPUT_NONE, INPUT_NONE),
  new WorkingMode(10, 'workingMode.6single', INPUT_NONE, INPUT_NONE),
  new WorkingMode(11, 'workingMode.3phaseSolar', INPUT_ALL, INPUT_ALL),
  new WorkingMode(12, 'workingMode.2x3phase', INPUT_ALL, INPUT_ALL),
  new WorkingMode(13, 'workingMode.9single', INPUT_NONE, INPUT_NONE),
  new WorkingMode(14, 'workingMode.3x3phase', INPUT_ALL_PRO, INPUT_ALL_PRO),
  new WorkingMode(15, 'workingMode.custom', INPUT_NONE, INPUT_NONE),
  new WorkingMode(16, 'workingMode.splitPhaseSolar', INPUT_ALL, INPUT_CT1 + INPUT_CT3 + INPUT_CT4 + INPUT_CT6),
  new WorkingMode(17, 'workingMode.singlePhaseSolar', INPUT_ALL, INPUT_CT1 + INPUT_CT4)
];
const MODE_UNKNOWN = new WorkingMode(-1, 'generic.na', INPUT_NONE, INPUT_NONE);

const MODES_BY_ID: {[key: number]: WorkingMode} = {};
for (let i = 0; i < MODES.length; i++) MODES_BY_ID[MODES[i].id] = MODES[i];

export function getWorkingMode(mode: number | undefined): WorkingMode | undefined {
  if (mode === undefined) return undefined;
  if (!mode && mode !== 0) return MODE_UNKNOWN;

  let result = MODES_BY_ID[mode];
  if (!result) {
    result = new WorkingMode(mode, 'generic.unknown', INPUT_NONE, INPUT_NONE);
  }
  return result;
}

export function getFriendlyWorkingMode(mode: number | undefined) {
  // Monitor working mode:
  // 0 = single phase
  // 1 = single phase + solar
  // 2 = 2 phase 180* (US)
  // 3 = solar only single phase
  // 4 = 3 phase 120*
  // 5 = 3 single phases
  // 6 = solar only max 3 single phase
  // 7 = solar only 3 phase 120*
  // 8 = solar only 2 phase 180*
  // 9 = auto detect (never appears in this field)
  // 10 = max 6 single phase
  // 11 = 3 phase + solar 120*
  // 12 = max 2 x 3 phase 120*
  // 13 = pro: max 9 single phase
  // 14 = pro: max 3 x 3 phase 120*
  // 15 = pro: with channels config
  // 16 = dual phase 180* + solar (CT1+3 grid, CT4+6 solar)
  // 17 = single phase + solar (CT1 grid, CT4 solar)
  const modeObject = getWorkingMode(mode);
  return modeObject === undefined ? undefined : T(modeObject.name);
}

export function getFriendlyInputsConnected(inputs: number | undefined): string | undefined {
  if (inputs === undefined) return undefined;

  let result = [];
  result.push(inputs & INPUT_CT1 ? '1' : '_');
  result.push(inputs & INPUT_CT2 ? '2' : '_');
  result.push(inputs & INPUT_CT3 ? '3' : '_');
  result.push(inputs & INPUT_CT4 ? '4' : '_');
  result.push(inputs & INPUT_CT5 ? '5' : '_');
  result.push(inputs & INPUT_CT6 ? '6' : '_');
  result.push(inputs & INPUT_CT7 ? '7' : '_');
  result.push(inputs & INPUT_CT8 ? '8' : '_');
  result.push(inputs & INPUT_CT9 ? '9' : '_');
  let str = result.join('');
  if (str.endsWith('___')) str = str.substring(0, 6);
  return str;
}

export function getFriendlyPhaseReverser(flags: number | undefined): string | undefined {
  if (flags === undefined) return undefined;

  const flagsString = flags.toString();
  let result = [];
  result.push(flagsString.startsWith('1') ? '1' : '_');
  result.push(flagsString[1] === '1' ? '2' : '_');
  result.push(flagsString[2] === '1' ? '3' : '_');
  result.push(flagsString[4] === '1' ? '4' : '_');
  result.push(flagsString[5] === '1' ? '5' : '_');
  result.push(flagsString[6] === '1' ? '6' : '_');
  result.push(flagsString[7] === '1' ? '7' : '_');
  result.push(flagsString[8] === '1' ? '8' : '_');
  result.push(flagsString[9] === '1' ? '9' : '_');
  let str = result.join('');
  if (str.endsWith('___')) str = str.substring(0, 6);
  return str;
}

export function getFriendlyPowerReversed(flags: number[] | undefined): string | undefined {
  if (flags === undefined) return undefined;

  return T.generic.yesNo(flags[3] === 1);
}

export function getFriendlyPhaseMapping(value: number): string {
  switch (value) {
    case 210:
      return '123';
    case 21:
      return '312';
    case 102:
      return '231';
    case 12:
      return '321';
    case 201:
      return '213';
    case 120:
      return '132';
    default:
      return `${T('generic.unknown')} (${value})`;
  }
}

export function getFriendlyStatus(status: number): string {
  switch (status) {
    case 0:
      return T('deviceConfigurationStatus.pending');
    case 1:
      return T('deviceConfigurationStatus.synced');
    case 2:
      return T('deviceConfigurationStatus.actual');
    case 3:
      return T('deviceConfigurationStatus.obsolete');
    default:
      return status.toString();
  }
}
