import React, {useContext} from 'react';

import {Board} from '../models/Board';

export interface IBoardContext {
  board?: Board;
  boardName?: string;
}

export const BoardContext = React.createContext<IBoardContext | undefined>(undefined);
export const BoardContextProvider = BoardContext.Provider;
export const BoardContextConsumer = BoardContext.Consumer;

export function useBoardContext() {
  return useContext<IBoardContext | undefined>(BoardContext) || {};
}
