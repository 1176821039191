import React, {useMemo, useReducer} from 'react';

import {IUser} from '../../models/User';
import {None} from '../../utils/Arrays';
import {
  AppendToListAction,
  CommonActionType,
  ErrorAction,
  FinishLoadingAction,
  ResetAction,
  StartLoadingAction,
  UpdateListAction
} from '../../utils/CommonActions';
import {T} from '../../utils/Internationalization';

export interface UsersState {
  query: string;
  items: IUser[];
  loading: boolean;
  waiting: boolean;
  error?: string;
  notYetLoaded: boolean;
}

const initialState: UsersState = {
  query: '',
  items: None,
  loading: false,
  waiting: false,
  notYetLoaded: true
};

enum UsersActionType {
  SetQuery = 'users.setQuery',
  UpdateUser = 'users.update'
}

interface IUsersAction {
  type: UsersActionType;
}

interface SetQueryAction extends IUsersAction {
  type: UsersActionType.SetQuery;
  payload: string;
}

interface UpdateUserAction extends IUsersAction {
  type: UsersActionType.UpdateUser;
  payload: IUser;
}

type UsersAction =
  | SetQueryAction
  | UpdateUserAction
  | ResetAction
  | StartLoadingAction
  | FinishLoadingAction<IUser[]>
  | ErrorAction
  | AppendToListAction<IUser>
  | UpdateListAction<IUser>;

function usersReducer(state: UsersState, action: UsersAction): UsersState {
  switch (action.type) {
    case UsersActionType.SetQuery:
      return {...state, waiting: true, query: action.payload};
    case CommonActionType.Reset:
      return initialState;
    case CommonActionType.StartLoading:
      return {...state, error: undefined, loading: true, waiting: false};
    case CommonActionType.FinishLoading:
      return {
        ...state,
        items: action.payload,
        loading: false,
        waiting: false,
        notYetLoaded: false
      };
    case CommonActionType.Error:
      return {
        ...state,
        loading: false,
        waiting: false,
        error: action.payload.error
      };
    case CommonActionType.Append:
      return {...state, items: [...state.items, action.payload]};
    case CommonActionType.Update:
      return {...state, items: state.items.map(action.payload)};
    default:
      return state;
  }
}

class UsersActor {
  private dispatch: React.Dispatch<UsersAction>;

  constructor(dispatch: React.Dispatch<UsersAction>) {
    this.dispatch = dispatch;
  }

  reset() {
    this.dispatch({type: CommonActionType.Reset, payload: {}});
  }

  startLoading() {
    this.dispatch({type: CommonActionType.StartLoading, payload: {}});
  }

  finishLoading(items: IUser[]) {
    this.dispatch({type: CommonActionType.FinishLoading, payload: items});
  }

  error(error: string) {
    this.dispatch({type: CommonActionType.Error, payload: {error}});
  }

  errorLoading(entity: string) {
    this.error(T('card.couldNotLoad', {entity}));
  }

  append(user: IUser) {
    this.dispatch({type: CommonActionType.Append, payload: user});
  }

  update(user: IUser) {
    this.dispatch({type: UsersActionType.UpdateUser, payload: user});
  }

  updateQuery(query: string) {
    this.dispatch({type: UsersActionType.SetQuery, payload: query});
  }
}

export function useUsersState(): [UsersState, UsersActor] {
  const [state, dispatch] = useReducer<typeof usersReducer>(usersReducer, initialState);
  const list = useMemo(() => new UsersActor(dispatch), [dispatch]);
  return [state, list];
}
