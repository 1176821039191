import {IChargingRule} from '../models/ChargingPriorities';

const KnownTags: {[key: string]: string} = {
  '0413D262756880': 'CEO',
  '0436D262756880': 'Product manager',
  '0453CA62756881': 'CFO',
  //  0471EFE2FC6280	Ilse (Ustunsoy)	100	N/B
  //  04832D820C6A84	Geert Haegens (Amplifino)	100	N/B
  '0484C662756881': 'CMO',
  '0487D962756881': 'Service desk engineer',
  //  0491AC820C6A81	Rudi (The Object Smith - Amplifino)	100	N/B
  '04B071E2FC6280': 'CTO',
  '04E2ED62756880': 'Sales Director'
};
let employeeCounter = 1;

export function processWhitelist(rules: IChargingRule[], demoMode: boolean): IChargingRule[] {
  if (!demoMode) return rules;

  return rules.map(rule => {
    if (rule.rfid) {
      if (!KnownTags[rule.rfid.value]) {
        KnownTags[rule.rfid.value] = `Employee ${employeeCounter++}`;
      }

      rule.rfid.comment = KnownTags[rule.rfid.value];
      return rule;
    } else {
      return rule;
    }
  });
}
