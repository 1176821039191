import React from 'react';

import {RowActions} from '../../components/bootstrap';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import {IActivationCode, getFeatureDisplayName} from '../../models/ActivationCode';
import {IOrganization} from '../../models/Organization';
import {
  CalculatedStringField,
  CheckboxField2,
  ComponentField,
  FieldAlignment,
  IntegerField,
  ITableField,
  StringField
} from '../../models/Table';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';

interface EventHandlers {
  onClickedEdit: (code: IActivationCode) => void;
  onClickedRemove: (code: IActivationCode) => void;
  onClickedCreateChild: (code: IActivationCode) => void;
  onClickedSetDefault: (code: IActivationCode) => void;
}

export function getColumns(
  isServiceDesk: boolean,
  events: EventHandlers,
  organization?: IOrganization
): ITableField<IActivationCode>[] {
  return [
    new CalculatedStringField(
      'name',
      T('activationCodes.field.name'),
      code => code.name && code.name + (code.subOrganizationName ? ` (${code.subOrganizationName})` : '')
    ),
    new StringField('code', T('activationCodes.table.code')),
    new CalculatedStringField('organization', T('activationCodes.table.organization'), code =>
      code.subOrganizationName
        ? `${organization?.parentName || organization?.name} / ${code.subOrganizationName}`
        : organization?.name
    ),
    new IntegerField('contractYears', T('activationCodes.field.contractYears'), {unit: T('unit.years')}),
    new CheckboxField2(
      'isDefault',
      T('activationCodes.table.isDefault'),
      code => code.isDefault || false,
      (row, checked) => {
        if (checked) {
          events.onClickedSetDefault(row);
        }
      },
      {alwaysVisible: false}
    ),
    new CalculatedStringField('features', T('activationCodes.field.features'), code =>
      (code.supportedFeatures || []).map(feature => getFeatureDisplayName(feature)).join(', ')
    ),
    new CalculatedStringField('voltageHarmonics', T('activationCodes.field.voltageHarmonics'), code =>
      code.voltageHarmonics ? code.voltageHarmonics.join(', ') : ''
    ),
    new CalculatedStringField('currentHarmonics', T('activationCodes.field.currentHarmonics'), code =>
      code.currentHarmonics ? code.currentHarmonics.join(', ') : ''
    ),
    new ComponentField(
      'action',
      T('activationCodes.table.actions'),
      row => (
        <RowActions>
          {row.subOrganizationId === undefined && (
            <RowActionButton
              title={T('activationCodes.tooltip.createChild')}
              onClick={() => events.onClickedCreateChild(row)}
              icon="SolidSitemap"
            />
          )}
          {isServiceDesk && row.subOrganizationId === undefined && (
            <RowActionButton
              title={T('activationCodes.tooltip.editActivationCode')}
              onClick={() => events.onClickedEdit(row)}
              icon="Pencil"
            />
          )}
          {(isServiceDesk || row.subOrganizationId !== undefined) && (
            <RowActionButton
              action="delete"
              title={T('activationCodes.tooltip.removeActivationCode')}
              onClick={() => events.onClickedRemove(row)}
              className={testingClasses.remove}
              data-testid={testingClasses.remove}
            />
          )}
        </RowActions>
      ),
      {align: FieldAlignment.Right, autoInsert: 'end'}
    )
  ];
}

export const rowKey = (row: IActivationCode) => row.id;
