import React, {ReactNode, useState, CSSProperties, useCallback} from 'react';

import {useFormContext} from '../../utils/FormContext';
import {FieldValidator} from '../../utils/Validation';
import {Input, FormGroup, Label, FormFeedback} from '../bootstrap';

import styles from './index.module.scss';

const STYLE_GRAY: CSSProperties = {color: 'gray'};

interface SelectInputGroupProps {
  label?: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  children: ReactNode;
  inputRef?: (element: HTMLInputElement | null) => void;
  disabled?: boolean;
  info?: string;
  error?: string;
  invalid?: boolean;
  description?: string;
  validate?: FieldValidator;
}

export const SelectInputGroup = (props: SelectInputGroupProps) => {
  const {
    label,
    name,
    value,
    onChange,
    children,
    validate,
    disabled,
    error: customError,
    description,
    invalid,
    info
  } = props;

  const form = useFormContext();

  const validationError = validate && validate(value, label ?? name, false);
  form.setError(name, validationError);
  const error = customError || validationError;

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return (
    <FormGroup className={styles.formGroup}>
      {label && <Label>{label}</Label>}
      <Input
        name={name}
        type="select"
        value={value}
        onChange={handleChange}
        invalid={invalid || error !== undefined}
        ref={useCallback((ref: HTMLInputElement) => form.setRef(name, ref), [form, name])}
        disabled={disabled}
      >
        {children}
      </Input>
      {description && <span style={STYLE_GRAY}>{description}</span>}
      {error && <FormFeedback valid={false}>{error}</FormFeedback>}
      {!error && info && <span style={STYLE_GRAY}>{info}</span>}
    </FormGroup>
  );
};

interface SelectInputProps {
  value: string;
  onChange: (value: string) => void;
  children: ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
  className?: string;
}
export const SelectInput = (props: SelectInputProps) => {
  const {value, onChange, children, style, disabled, className} = props;

  return (
    <Input
      type="select"
      value={value}
      onChange={useCallback(e => onChange(e.currentTarget.value), [onChange])}
      style={style}
      disabled={disabled}
      className={className}
    >
      {children}
    </Input>
  );
};

export function useSelectInputGroup(
  name: string,
  label: string,
  initialValue: string | (() => string),
  options: JSX.Element[],
  validate?: (value: string) => string | undefined
): [JSX.Element, string, () => void] {
  const [value, setValue] = useState(initialValue);
  const clear = () => setValue(initialValue);

  const component = (
    <SelectInputGroup
      key={name}
      name={name}
      label={label}
      value={value}
      onChange={setValue}
      children={options}
      validate={validate}
    />
  );
  return [component, value, clear];
}
