import React from 'react';
import {FormFeedback as RsFormFeedback, FormFeedbackProps as RsFormFeedbackProps} from 'reactstrap';

import './FormFeedback.scss';
import {classes} from '../../utils/Styles';

interface IFormFeedbackProps extends RsFormFeedbackProps {
  size?: string;
}

export function FormFeedback(props: IFormFeedbackProps) {
  const {size, className, children, ...otherProps} = props;

  return (
    <RsFormFeedback size={size} className={classes('dash-form-feedback', size, className)} {...otherProps}>
      {children}
    </RsFormFeedback>
  );
}
