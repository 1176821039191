import React from 'react';
import {ModalFooter as RsModalFooter} from 'reactstrap';

import {classes} from '../../utils/Styles';

import {ModalErrorMessage} from '../ModalErrorMessage';

import styles from './ModalFooter.module.scss';

interface ModalFooterProps {
  className?: string;
  error?: string | null;
  children: React.ReactNode;
}

export function ModalFooter(props: ModalFooterProps) {
  const {className, error, children} = props;

  return (
    <RsModalFooter className={classes(styles.footer, className)}>
      {error && <ModalErrorMessage message={error} />}
      {children}
    </RsModalFooter>
  );
}
