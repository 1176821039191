import React from 'react';
import {DropdownItem as RsDropdownItem, DropdownItemProps as RsDropdownItemProps} from 'reactstrap';

import {classes, dashboardClasses} from '../../utils/Styles';

interface DropdownItemProps extends RsDropdownItemProps {
  color?: string;
  size?: string;
  disabled?: boolean;
  className?: string;
}

export const DropdownItem = React.memo((props: DropdownItemProps) => {
  let {color, size, disabled, className, children, ...otherProps} = props;

  const fullClassName = classes(
    dashboardClasses.dropdownItem,
    color,
    size,
    disabled ? dashboardClasses.disabled : '',
    className
  );

  return (
    <RsDropdownItem color={color} size={size} disabled={disabled} className={fullClassName} {...otherProps}>
      {children}
    </RsDropdownItem>
  );
});
