import type {SVGProps} from 'react';
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M13 9.1a1.1 1.1 0 0 0 0-2.2H9.1V3a1.1 1.1 0 0 0-2.2 0v3.9H3a1.1 1.1 0 1 0 0 2.2h3.9V13a1.1 1.1 0 0 0 2.2 0V9.1z" />
  </svg>
);
export default SvgPlus;
