import React from 'react';

import {Button} from '../../components/ui/button';
import {Dots} from '../../components/ui-lib/icons/small';
import {cn} from '../../lib/utils';

import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';

interface CardSettingsHandleProps {
  className: string;
  color?: string;
  onClick: () => void;
}

export default function CardSettingsHandle(props: CardSettingsHandleProps) {
  const {className, color, onClick} = props;
  return (
    <Button
      variant="secondary_default"
      size="lg"
      title={T('card.settings.tooltip')}
      onClick={onClick}
      className={cn(`${testingClasses.cardSettingsHandle}`, className)}
      data-testid={testingClasses.cardSettingsHandle}
    >
      <Dots width={16} height={16} className="tw-h-4 tw-w-4 !tw-overflow-visible" />
    </Button>
  );
}
