import {tr} from 'date-fns/locale';
import React, {useRef, useState, useEffect} from 'react';

import {Button} from '../../components/ui/button';
import {TrackingType} from '../../livedata/LiveDataModels';
import {MqttConnector, MqttSubscription, Tracking} from '../../livedata/MqttConnector';
import {ILocationSummary} from '../../models/Location';
import {T} from '../../utils/Internationalization';

interface DevicePingerProps {
  mqtt: MqttConnector;
  location: ILocationSummary;
  mqttSubscriptionIdentifier: string;
}

export const DevicePinger = (props: DevicePingerProps) => {
  const {mqtt, location, mqttSubscriptionIdentifier} = props;

  const [pinging, setPingingState] = useState(false);
  const [pingReceived, setPingReceived] = useState(false);

  const subscriptionRef = useRef<MqttSubscription>();
  const sendTrackingInterval = useRef<NodeJS.Timeout>();

  const stopPinging = () => {
    setPingingState(false);
    if (subscriptionRef.current) {
      subscriptionRef.current.close();
      subscriptionRef.current = undefined;
    }
    if (sendTrackingInterval.current) {
      clearInterval(sendTrackingInterval.current);
    }
  };

  const startPinging = () => {
    setPingingState(true);
    setPingReceived(false);

    if (!location.serialNumber) return;

    const tracking: Tracking = {
      type: TrackingType.Realtime,
      serialNumber: location.serialNumber,
      trackingLocationUUID: location.uuid
    };
    mqtt.subscribe(
      mqttSubscriptionIdentifier,
      location.uuid,
      location.uuid,
      MqttConnector.getPowerTopic(location.uuid),
      tracking,
      false,
      subscription => (subscriptionRef.current = subscription),
      () => {
        setPingReceived(true);
        stopPinging();
      }
    );
    sendTrackingInterval.current = setInterval(() => mqtt.resendTracking(tracking), 5000);
  };

  useEffect(() => {
    setPingReceived(false);
    stopPinging();
  }, [location.id]);

  const handleClickedPing = () => (pinging ? stopPinging() : startPinging());

  return (
    <Button
      size="lg"
      variant="secondary_default"
      onClick={handleClickedPing}
      active={pinging ?? false}
      disabled={location.serialNumber === undefined}
      className="tw-flex tw-py-[0.5rem]"
    >
      {pingReceived ? T('firmware.ping.received') : pinging ? T('firmware.ping.pinging') : T('firmware.ping.ping')}
    </Button>
  );
};
