import React, {useState, useMemo, useEffect} from 'react';

import {NotificationManager} from 'react-notifications';
import {Modal, ModalFooter, Form} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {ModalHeader, ModalBody} from '../../components/bootstrap';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {useNumberInput} from '../../components/inputs/NumberInput';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {useTextInput} from '../../components/inputs/TextInput';
import {Checkbox} from '../../components/ui/checkbox';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {BatteryManufacturerStatus, IBatteryDeviceType, IBatteryManufacturer} from '../../models/BatteryModels';
import {CircuitType, ProductionExport} from '../../models/Channel';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {
  ILoad,
  LoadSource,
  LoadType,
  LOAD_TYPES,
  getLoadTypeLabel,
  ILoadCreationSpec,
  Load,
  LOAD_TYPES_WITHOUT_GRID
} from '../../models/Load';
import {None} from '../../utils/Arrays';
import {FormProvider} from '../../utils/FormContext';
import {useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';

import {testingClasses} from '../../utils/TestingClasses';

import ApplianceTypeSelector from './inputs/ApplianceTypeSelector';

interface AddLoadModalProps extends IPromiseModalProps<ILoad | undefined> {
  locationId: number;
  phaseType: PhaseType;
  supportsMID: boolean;
  existingLoads: Load[];
}

export default (props: AddLoadModalProps) => {
  const {locationId, phaseType, supportsMID, existingLoads} = props;
  const {api} = useAppContext();
  const hasGrid = existingLoads.some(load => load.type === LoadType.Grid);

  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(undefined);

  const [billableLoad, setBillableLoad] = useState(false);
  const [type, setType] = useState<LoadType>(LoadType.Subcircuit);
  const [phases, setPhases] = useState(1);
  const [circuitType, setCircuitType] = useState(CircuitType.Outlets);
  const [measuredByGrid, setMeasuredByGrid] = useState(true);
  const [exportType, setExportType] = useState(ProductionExport.All);
  const [applianceTypes] = useLoader(api => api.getApplianceTypes(), []);
  const [applianceType, setApplianceType] = useState(applianceTypes ? applianceTypes[0].id : '');

  const [batteryManufacturers = None] = useLoader(api => api.getBatteryManufacturers(locationId), [locationId]);
  const [batteryManufacturer, setBatteryManufacturer] = useState<IBatteryManufacturer>();
  const [batteryManufacturerError, setBatteryManufacturerError] = useState<string>();
  const [batteryModel, setBatteryModel] = useState<IBatteryDeviceType>();
  const [batteryModelError, setBatteryModelError] = useState<string>();

  useEffect(() => applianceTypes && setApplianceType(applianceTypes[0].id), [applianceTypes]);
  useEffect(() => {
    if (type === LoadType.Grid) {
      if (phaseType === PhaseType.Split) setPhases(2);
      else if (phaseType === PhaseType.Star || phaseType === PhaseType.Delta) {
        setPhases(3);
      }
    }
  }, [phaseType, type]);

  const defaultName = useMemo(() => {
    switch (type) {
      case LoadType.Grid:
        return T('defaultNames.gridLoad');
      case LoadType.Production:
        return T('defaultNames.productionLoad');
      case LoadType.Storage:
        return T('defaultNames.storageLoad');
      case LoadType.Appliance:
        const applianceTypeInfo =
          (applianceTypes || []).find(t => t.id === applianceType) || (applianceTypes && applianceTypes[0]);
        return applianceTypeInfo ? applianceTypeInfo.translation : '';
      case LoadType.Subcircuit:
        return circuitType === CircuitType.Outlets ? T('circuitType.outlets') : T('circuitType.lights');
      default:
        return '';
    }
  }, [type, applianceTypes, circuitType, applianceType]);

  const [nameInput, name, resetName] = useTextInput(
    'name',
    T('ctConfiguration.addLoad.name'),
    defaultName,
    (value, label) => {
      if (value.length === 0) return T('validator.required', {name: label});
      if (existingLoads.some(load => load.name === value)) {
        return T('liveElectricityValues.configuration.loadNameNotUnique');
      }

      return undefined;
    }
  );
  const [maxCurrentInput, maxCurrent] = useNumberInput(
    'maxCurrent',
    T('ctConfiguration.addLoad.maxCurrent'),
    50,
    undefined,
    undefined,
    'A'
  );

  useEffect(() => resetName(), [defaultName, resetName]);

  const handleTypeChanged = (value: string) => setType(value as LoadType);
  const handleCircuitTypeChanged = (value: string) => setCircuitType(value as CircuitType);
  const handlePhasesChanged = (value: string) => setPhases(parseInt(value));
  const handleExportTypeChanged = (value: string) => setExportType(value as ProductionExport);

  const handleBatteryManufacturerChanged = (value: string) => {
    setBatteryManufacturer(batteryManufacturers.find(m => m.id.toString() === value));
    setBatteryManufacturerError(undefined);
    setBatteryModel(undefined);
    setBatteryModelError(undefined);
  };

  const handleBatteryModelChanged = (value: string) => {
    if (!batteryManufacturer) return;

    setBatteryModel(batteryManufacturer.deviceTypes.find(m => m.id.toString() === value));
    setBatteryModelError(undefined);
  };

  const typeOptions = useMemo(
    () =>
      (hasGrid ? LOAD_TYPES_WITHOUT_GRID : LOAD_TYPES).map(type => (
        <option key={type} value={type}>
          {getLoadTypeLabel(type)}
        </option>
      )),
    [hasGrid]
  );

  const handleClickedSave = async () => {
    if (maxCurrent === null) return;

    if (type === LoadType.Storage) {
      if (batteryManufacturer === undefined) {
        setBatteryManufacturerError(
          T('validator.required', {
            name: T('ctConfiguration.addLoad.batteryManufacturer')
          })
        );
        return;
      } else if (batteryManufacturer.deviceTypes.length > 0 && batteryModel === undefined) {
        setBatteryModelError(
          T('validator.required', {
            name: T('ctConfiguration.addLoad.batteryModel')
          })
        );
        return;
      }
    }

    const spec: ILoadCreationSpec = {
      name,
      sourceType: billableLoad ? LoadSource.MID : LoadSource.CT,
      type,
      numberOfPhasesUsed: phases,
      maximumAmpere: maxCurrent
    };

    if (type === LoadType.Subcircuit) {
      spec.subcircuit = {
        type: circuitType,
        alreadyMeasuredByGrid: measuredByGrid
      };
    } else if (type === LoadType.Appliance) {
      spec.appliance = {
        type: applianceType,
        alreadyMeasuredByGrid: measuredByGrid
      };
    } else if (type === LoadType.Storage) {
      if (batteryModel) {
        spec.storage = {
          alreadyMeasuredByGrid: measuredByGrid,
          manufacturer: batteryManufacturer!.id,
          deviceType: batteryModel.id
        };
      }
    } else if (type === LoadType.Production) {
      spec.production = {
        export: exportType
      };
    }
    const load = await api.createHighLevelMeasurement(locationId, spec);
    resolve(load);
    NotificationManager.success(T('ctConfiguration.addLoad.create.success'));
  };

  return (
    <FormProvider>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <ModalHeader toggle={handleClose}>{T('ctConfiguration.addLoad.title')}</ModalHeader>
        <ModalBody>
          <Form>
            {supportsMID && (
              <Checkbox
                id="billable-load"
                name="billable-load"
                label={T('ctConfiguration.addLoad.billableLoad')}
                checked={billableLoad}
                onCheckedChange={setBillableLoad}
                className="!tw-mb-0"
                testId="billable-load"
                wrapperClassName="!tw-mb-4"
              />
            )}
            <SelectInputGroup name="type" label="Type" value={type} onChange={handleTypeChanged}>
              {typeOptions}
            </SelectInputGroup>
            {type === LoadType.Subcircuit && (
              <SelectInputGroup
                name="circuitType"
                label={T('ctConfiguration.addLoad.circuitType')}
                value={circuitType}
                onChange={handleCircuitTypeChanged}
              >
                <option value={CircuitType.Outlets}>{T('circuitType.outlets')}</option>
                <option value={CircuitType.Lights}>{T('circuitType.lights')}</option>
              </SelectInputGroup>
            )}
            {type === LoadType.Appliance && (
              <ApplianceTypeSelector
                applianceTypes={applianceTypes || []}
                type={applianceType}
                onSelected={setApplianceType}
              />
            )}
            {type === LoadType.Production && (
              <SelectInputGroup
                name="exportType"
                label={T('ctConfiguration.addLoad.export')}
                value={exportType}
                onChange={handleExportTypeChanged}
              >
                <option value={ProductionExport.All}>{T('export.all')}</option>
                <option value={ProductionExport.Surplus}>{T('export.surplus')}</option>
              </SelectInputGroup>
            )}
            {nameInput}
            {phaseType !== PhaseType.Single && type !== LoadType.Grid && (
              <SelectInputGroup
                name="numberOfPhases"
                label={T('ctConfiguration.addLoad.numberOfPhases')}
                value={phases.toString()}
                onChange={handlePhasesChanged}
              >
                <option value="1">{T('phaseType.single')}</option>
                {phaseType === PhaseType.Split && <option value="2">{T('phaseType.split')}</option>}
                {phaseType === PhaseType.Star && <option value="3">{T('phaseType.star')}</option>}
                {phaseType === PhaseType.Delta && <option value="2">{T('phaseType.delta')}</option>}
              </SelectInputGroup>
            )}
            {(type === LoadType.Subcircuit || type === LoadType.Storage || type === LoadType.Appliance) && (
              <Checkbox
                id="measured-by-grid"
                name="measured-by-grid"
                label={T('ctConfiguration.addLoad.measuredByGrid')}
                checked={measuredByGrid}
                onCheckedChange={setMeasuredByGrid}
                className="tw-mb-0"
                testId="measured-by-grid"
              />
            )}
            {type === LoadType.Grid && maxCurrentInput}
            {type === LoadType.Storage && (
              <SelectInputGroup
                name="batteryManufacturer"
                label={T('ctConfiguration.addLoad.batteryManufacturer')}
                value={batteryManufacturer ? batteryManufacturer.id.toString() : ''}
                onChange={handleBatteryManufacturerChanged}
                error={batteryManufacturerError}
                info={
                  batteryManufacturer && batteryManufacturer.status === BatteryManufacturerStatus.Support
                    ? T('ctConfiguration.addLoad.batteryManufacturer.contactSupport')
                    : undefined
                }
              >
                <option value="">{T('ctConfiguration.addLoad.batteryManufacturer.selectOne')}</option>
                {batteryManufacturers
                  .filter(
                    m =>
                      m.status === BatteryManufacturerStatus.Available || m.status === BatteryManufacturerStatus.Support
                  )
                  .map(manufacturer => (
                    <option key={manufacturer.id} value={manufacturer.id}>
                      {manufacturer.name}
                    </option>
                  ))}
              </SelectInputGroup>
            )}
            {type === LoadType.Storage && batteryManufacturer && batteryManufacturer.deviceTypes.length > 0 && (
              <SelectInputGroup
                name="batteryModel"
                label={T('ctConfiguration.addLoad.batteryModel')}
                value={batteryModel ? batteryModel.id.toString() : ''}
                onChange={handleBatteryModelChanged}
                error={batteryModelError}
              >
                <option value="">{T('ctConfiguration.addLoad.batteryModel.selectOne')}</option>
                {batteryManufacturer.deviceTypes.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </SelectInputGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormSaveButton
            onSave={handleClickedSave}
            className={testingClasses.confirmButton}
            data-testid={testingClasses.confirmButton}
          >
            {T('ctConfiguration.addLoad.create')}
          </FormSaveButton>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
