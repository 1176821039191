import React from 'react';
import {Label as RsLabel, LabelProps} from 'reactstrap';

import {classes} from '../../utils/Styles';

import styles from './Label.module.scss';

export const Label = React.memo((props: LabelProps) => {
  const {size, check, className, children, ...otherProps} = props;
  const fullClassName = classes(styles.label, size, check ? styles.check : '', className);

  return (
    <RsLabel size={size} check={check} className={fullClassName} {...otherProps}>
      {children}
    </RsLabel>
  );
});
