import {Parser, HtmlRenderer} from 'commonmark';
import {useMemo} from 'react';

interface MarkdownViewProps {
  content: string;
  className?: string;
}

export default function MarkdownView(props: MarkdownViewProps) {
  const {content, className} = props;

  const html = useMemo(() => {
    var reader = new Parser();
    var writer = new HtmlRenderer();
    var parsed = reader.parse(content.replaceAll('\\n', '\n'));
    var result = writer.render(parsed);
    return {__html: result};
  }, [content]);

  return <div dangerouslySetInnerHTML={html} className={className} />;
}
