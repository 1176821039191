import React from 'react';

import {Checkbox} from '../../../components/ui/checkbox';

interface MeasuredInputProps {
  readOnly: boolean;
  value: boolean;
  onChange: (checked: boolean) => void;
}
export const MeasuredInput = (props: MeasuredInputProps) => {
  const {readOnly, onChange} = props;
  let value = !!props.value;

  return (
    <Checkbox
      id="measuredByGrid"
      name="measuredByGrid"
      checked={value}
      onCheckedChange={onChange}
      disabled={readOnly}
      wrapperClassName="!tw-mb-0 tw-justify-center"
      testId="measuredByGrid"
    />
  );
};
