import {APIClient} from './APIClient';

export class OAuthAPI {
  client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getAppInfo(clientId: string): Promise<OAuthAppInfo> {
    const url = `/dev/v3/oauth2/apps/${clientId}`;
    return this.client.doGet(url);
  }

  grantAppAccess(
    clientId: string,
    responseType: string,
    redirectUri: string | undefined,
    state: string | undefined,
    scope: string | undefined,
    serviceLocationIds: number[],
    organizationId?: number
  ): Promise<GrantAppAccessResponse> {
    const url = `/dev/v3/oauth2/grantappaccess`;
    return this.client.doPost(url, {
      clientId,
      responseType,
      redirectUri,
      state,
      scope,
      serviceLocationIds,
      organizationId
    });
  }
}

export interface OAuthAppInfo {
  name: string;
  loginInfo: string;
  selectLocationsInfo: string;
  permissions: 'LOCATIONS' | 'ORGANIZATION';
}

export interface GrantAppAccessResponse {
  redirectUri: string;
}
