import React from 'react';

//import { Alert } from 'reactstrap';
import {classes} from '../utils/Styles';
import {testingClasses} from '../utils/TestingClasses';

import styles from './ModalErrorMessage.module.scss';

interface ModalErrorMessageProps {
  message: string;
}
export const ModalErrorMessage = (props: ModalErrorMessageProps) => {
  const {message} = props;
  return (
    <>
      <div className={styles.exclamation}>
        <span className="fal fa-exclamation" />
      </div>
      <div
        className={classes(styles.errorMessage, testingClasses.errorMessage)}
        data-testid={testingClasses.errorMessage}
      >
        {message}
      </div>
      <div style={{flex: 1}} />
    </>
  );
};

/* old style:
    <Alert fixed={false} color='danger' style={{ marginBottom: 0 }}>
      {message}
    </Alert>
*/
