import React from 'react';

import {useAppContext} from '../app/context';
import {ILocationSummary} from '../models/Location';
import {setContextLocationId} from '../redux/actions/location';

import {Button} from './bootstrap/Button';

interface SelectLocationButtonProps {
  location: ILocationSummary;
  className?: string;
}

export default function SelectLocationButton(props: SelectLocationButtonProps) {
  const {location, className} = props;
  const {store, api} = useAppContext();

  const handleClicked = () => {
    setContextLocationId(store, api, location.id);
  };

  return (
    <Button color="link" onClick={handleClicked} className={className}>
      {location.name}
    </Button>
  );
}
