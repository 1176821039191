import React, {useState, useEffect} from 'react';

import {Input, InputGroup, InputGroupAddon} from '../../../components/bootstrap';

import ValueEditor from './ValueEditor';

interface NumberEditorProps {
  value?: number;
  update: (value: number) => Promise<boolean>;
  suffix?: string;
  readOnly: boolean;
  min?: number;
  max?: number;
  extra?: string;
}
const NumberEditor = (props: NumberEditorProps) => {
  const {value: numberValue, update, suffix, readOnly, min, max, extra} = props;
  const resetValue = numberValue === undefined ? '' : numberValue.toString();
  const [originalValue, setOriginalValue] = useState(resetValue);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(originalValue);

  useEffect(() => {
    setValue(resetValue);
    setOriginalValue(resetValue);
  }, [resetValue]);

  const handleSetEditing = (editing: boolean) => {
    setEditing(editing);
    setValue(originalValue);
  };

  const handleClickedSave = async () => {
    const numberValue = parseInt(value);
    if (isNaN(numberValue)) return;
    if (min !== undefined && numberValue < min) return;
    if (max !== undefined && numberValue > max) return;

    setLoading(true);

    const success = await update(numberValue);
    setLoading(false);
    if (!success) return;

    setEditing(false);
    setOriginalValue(value);
  };

  const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.currentTarget;
    const numberValue = parseInt(value);
    if (min !== undefined && !isNaN(numberValue) && numberValue < min) return;
    if (max !== undefined && !isNaN(numberValue) && numberValue > max) return;
    setValue(value);
  };

  let input: React.ReactNode;
  if (editing) {
    const inputInner = (
      <Input
        type="number"
        name="editingValue"
        value={value}
        onChange={handleChanged}
        style={{display: 'inline-block', maxWidth: 100}}
      />
    );
    input = suffix ? (
      <InputGroup style={{width: 'auto'}}>
        {inputInner}
        <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
      </InputGroup>
    ) : (
      inputInner
    );
  } else {
    input = (
      <>
        {originalValue} {suffix}
        {extra}
      </>
    );
  }

  return (
    <ValueEditor
      readOnly={readOnly}
      editing={editing}
      setEditing={handleSetEditing}
      loading={loading}
      onClickedSave={handleClickedSave}
    >
      {input}
    </ValueEditor>
  );
};

export default NumberEditor;
