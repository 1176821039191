import React, {useState, useEffect, useMemo} from 'react';

import {ModalHeader, ModalFooter, Form, Row, Col} from 'reactstrap';

import {Modal, ModalBody} from '../../../components/bootstrap';
import FormSaveButton from '../../../components/inputs/FormSaveButton';
import {SelectInputGroup} from '../../../components/inputs/SelectInput';
import {TextInputGroup} from '../../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {
  Automation,
  AutomationType,
  DayOfWeek,
  IAutomationTarget,
  listUniqueActions,
  getActionSignature,
  AutomationAction,
  AutomationActionType,
  AutomationPossibleAction
} from '../../../models/Automation';

import {IConfigurationProperty} from '../../../models/SmartDevice';
import {None} from '../../../utils/Arrays';
import {FormProvider} from '../../../utils/FormContext';
import {useLoader} from '../../../utils/Hooks';
import {T} from '../../../utils/Internationalization';
import {useObjectState} from '../../../utils/ObjectState';
import {validateRequired, validateTime} from '../../../utils/Validation';
import {DaysOfWeekInputGroup} from '../DaysOfWeekInputGroup';

import EditActionView from './EditActionView';
import {IMultiTargetAction, mergeActions} from './MultiTargetAction';

interface EditAutomationModalProps extends IPromiseModalProps<Automation | undefined> {
  locationId: number;
  automation?: Automation;
}

function getAutomationTime(automation: Automation) {
  if (automation.type === AutomationType.Schedule) {
    return `${automation.schedule.hours.toString().padStart(2, '0')}:${automation.schedule.minutes
      .toString()
      .padStart(2, '0')}`;
  } else {
    return '';
  }
}

function findAction(
  actions: AutomationAction[],
  used: Set<number>,
  action: AutomationPossibleAction,
  target: IAutomationTarget
): AutomationAction | undefined {
  return actions.find(candidate => {
    if (used.has(candidate.id)) return false;
    if (candidate.type !== action.type) return false;
    if (candidate.target.type !== target.type) return false;
    if (candidate.target.id !== target.id) return false;
    if (action.type === AutomationActionType.Smart) {
      return candidate.type === AutomationActionType.Smart && candidate.smart.name === action.smart.name;
    } else if (action.type === AutomationActionType.State) {
      return candidate.type === AutomationActionType.State && candidate.stateful.state === action.stateful.state;
    } else if (action.type === AutomationActionType.Switch) {
      return candidate.type === AutomationActionType.Switch && candidate.switchable.value === action.switchable.value;
    } else {
      return false;
    }
  });
}

function splitActions(actions: IMultiTargetAction[], reference: AutomationAction[] = []) {
  const result: AutomationAction[] = [];
  const usedActions = new Set<number>();
  for (let multiAction of actions) {
    const {action, properties} = multiAction;
    for (let target of multiAction.targets) {
      const existing = findAction(reference, usedActions, action, target);
      if (existing) usedActions.add(existing.id);

      result.push({
        id: existing ? existing.id : (undefined as unknown as number),
        type: action.type,
        smart:
          action.type === AutomationActionType.Smart
            ? {
                name: action.smart.name,
                displayName: action.smart.displayName,
                properties
              }
            : undefined,
        stateful: action.type === AutomationActionType.State ? action.stateful : undefined,
        switchable: action.type === AutomationActionType.Switch ? action.switchable : undefined,
        target: {
          id: target.id,
          type: target.type
        }
      } as AutomationAction);
    }
  }
  return result;
}

interface FormState {
  name: string;
  time: string;
  type: AutomationType;
}

const defaultFormState: FormState = {
  name: '',
  time: '',
  type: AutomationType.Schedule
};

export default (props: EditAutomationModalProps) => {
  const {locationId, automation} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleToggle = () => resolve(undefined);

  const [formState, updateFormState] = useObjectState(
    automation
      ? {
          name: automation.name,
          time: getAutomationTime(automation),
          type: automation.type
        }
      : defaultFormState
  );

  const title = automation
    ? T('chargingStationAutomations.editAutomation.title')
    : T('chargingStationAutomations.addAutomation.title');
  const [daysOfWeek, setDaysOfWeek] = useState<DayOfWeek[]>(
    (automation && automation.type === AutomationType.Schedule && automation.schedule.days) || []
  );
  const [daysOfWeekError, setDaysOfWeekError] = useState<string>();
  const [actions, setActions] = useState<IMultiTargetAction[]>([]);

  const handleDaysOfWeekChanged = (value: DayOfWeek[]) => {
    setDaysOfWeek(value);
    setDaysOfWeekError(undefined);
  };

  const [targets = None] = useLoader(
    api =>
      api
        .getAutomationTargets(locationId, ['CARCHARGER'])
        .catch(() => None as IAutomationTarget[])
        .then(t =>
          t.filter(
            target =>
              target.type === 'SMART_DEVICE' && (target.category === 'CHARGINGSTATION' || target.category === 'LED')
          )
        ),
    [locationId]
  );

  useEffect(() => automation && setActions(mergeActions(automation, targets)), [automation, targets]);

  const availableActions = useMemo(() => listUniqueActions(targets), [targets]);
  const actionOptions = useMemo(
    () =>
      availableActions.map(action => (
        <option key={getActionSignature(action)} value={getActionSignature(action)}>
          {action.description}
        </option>
      )),
    [availableActions]
  );
  const actionViews = useMemo(() => {
    const setAction = (index: number, action: IMultiTargetAction) => {
      return setActions(actions => actions.map((a, i) => (i === index ? action : a)));
    };

    const removeAction = (index: number) => {
      return setActions(actions => actions.filter((_, i) => i !== index));
    };

    return actions.map((action, index) => (
      <EditActionView
        key={index}
        index={index}
        action={action}
        setAction={setAction}
        onDelete={() => removeAction(index)}
        targets={targets}
      />
    ));
  }, [actions, targets]);

  const performValidation = () => {
    if (formState.type === AutomationType.Schedule) {
      if (daysOfWeek.length === 0) {
        setDaysOfWeekError(T('automation.error.daysRequired'));
        return false;
      } else {
        setDaysOfWeekError(undefined);
      }
    }

    return true;
  };

  const handleClickedSave = async () => {
    if (!performValidation()) return;

    switch (formState.type) {
      case AutomationType.Schedule: {
        const timeSplit = formState.time.split(':');
        const hours = parseInt(timeSplit[0]);
        const minutes = parseInt(timeSplit[1]);

        resolve({
          id: automation ? automation.id : (undefined as unknown as number),
          type: AutomationType.Schedule,
          name: formState.name,
          schedule: {
            days: daysOfWeek,
            hours,
            minutes
          },
          scene: {
            id: automation && automation.scene ? automation.scene.id : (undefined as unknown as number),
            actions: splitActions(actions, automation && automation.scene && automation.scene.actions)
          }
        });
      }
    }
  };

  const handleAddAction = (signature: string) => {
    const action = availableActions.find(a => getActionSignature(a) === signature);
    if (!action) return;

    const properties: IConfigurationProperty[] =
      action.type === AutomationActionType.Smart
        ? (action.smart.configurationProperties || []).map(property => ({
            spec: property,
            values: []
          }))
        : [];

    const newAction: IMultiTargetAction = {
      action,
      properties,
      targets: []
    };
    setActions([...actions, newAction]);
  };

  return (
    <FormProvider>
      <Modal isOpen={isOpen} toggle={handleToggle} size="xl">
        <ModalHeader toggle={handleToggle}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={4}>
              <Form>
                <TextInputGroup
                  name="name"
                  label={T('automation.name')}
                  value={formState.name}
                  onChange={name => updateFormState({name})}
                  validate={validateRequired}
                />
                <SelectInputGroup
                  name="type"
                  label={T('automation.type')}
                  value={formState.type}
                  onChange={type => updateFormState({type: type as AutomationType})}
                >
                  <option value={AutomationType.Schedule}>{T('automationType.schedule')}</option>
                </SelectInputGroup>
              </Form>
            </Col>
            <Col sm={4}>
              <Form>
                <DaysOfWeekInputGroup
                  label={T('automation.schedule.days')}
                  value={daysOfWeek}
                  onChange={handleDaysOfWeekChanged}
                  error={daysOfWeekError}
                />
                <TextInputGroup
                  name="time"
                  label={T('automation.schedule.time')}
                  value={formState.time}
                  onChange={time => updateFormState({time})}
                  validate={validateTime}
                  placeholder="hh:mm"
                />
              </Form>
            </Col>
          </Row>
          {actionViews}
          <Row>
            <Col sm={4}>
              <Form>
                <SelectInputGroup
                  name="action"
                  label={T('editAutomation.addAction')}
                  value=""
                  onChange={handleAddAction}
                >
                  <option value="">{T('editAutomation.selectAction')}</option>
                  {actionOptions}
                </SelectInputGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <FormSaveButton
            onSave={handleClickedSave}
            disabled={actions.length === 0 || actions.some(action => action.targets.length === 0)}
          >
            {T('chargingStationAutomations.addAutomation.save')}
          </FormSaveButton>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
