import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';

interface ParentFieldProps {
  label: string;
  isSetChargingGroup?: boolean;
  isSetChargingStation?: boolean;
  canEdit?: boolean;
  handleClickedParent: () => void;
  handleClickedEditParent: () => void;
}

export const ParentField = (props: ParentFieldProps) => {
  return (
    <>
      {!props.isSetChargingGroup || !props.isSetChargingStation ? (
        <Button
          variant="tertiary_link"
          onClick={props.handleClickedParent}
          className="!tw-p-0 !tw-mr-2 !tw-bg-transparent"
        >
          {props.label}
        </Button>
      ) : (
        props.label
      )}{' '}
      {props.canEdit !== false && (
        <Button variant="ghost_action_btn" size="icon" onClick={props.handleClickedEditParent}>
          <Edit />
        </Button>
      )}
    </>
  );
};
