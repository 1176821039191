import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';

interface PaymentTypesFieldProps {
  paymentTypes: string;
  isReadOnly: boolean;
  handleClickedEditPaymentTypes: () => void;
}

export const PaymentTypesField = (props: PaymentTypesFieldProps) => {
  return (
    <span>
      {props.paymentTypes}{' '}
      {!props.isReadOnly && (
        <Button variant="ghost_action_btn" size="icon" onClick={props.handleClickedEditPaymentTypes}>
          <Edit />
        </Button>
      )}
    </span>
  );
};
