export const APP_TITLE = 'Smappee Dashboard';
export const LINK_HELP = 'https://www.smappee.com/be_en/support';
export const LINK_PRIVACY = 'https://www.smappee.com/be_en/privacy';
export const LINK_TERMS = 'https://www.smappee.com/be_en/conditions';

export const DEFAULT_LOCALE = 'nl-BE';
export const FORMAT_EXCEL = 'YYYY-MM-DD HH:mm:ss';
export const FORMAT_DATE_S = 'YYYY-MM-DD';
export const FORMAT_DATE_S_PICKER = 'yyyy-MM-dd';
export const FORMAT_FILENAME_TIMESTAMP = 'YYYY-MM-DD HH_mm_ss';
export const FORMAT_DATETIME_S = 'YYYY-MM-DD, HH:mm';
export const FORMAT_TIME = 'HH:mm';

export const DEBUG = window.location.hostname === 'localhost';

export const KW_DIGITS_AFTER_COMMA = 2;
