import React, {useCallback, useMemo} from 'react';
import {Col, Row} from 'reactstrap';

import {Address, Countries} from '../../models/Countries';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {validateAtMost, validateRequired, validateSpecialChars} from '../../utils/Validation';

import {CountryInputGroup} from './CountryInput';
import FormInputGroup from './FormInputGroup';
import {TextInput} from './TextInput';

interface AddressInputProps {
  name: string;

  value: Address;
  onChange: (updates: Partial<Address>) => void;

  streetLabel: string;
  cityLabel: string;
  countryLabel: string;

  countryError?: string;

  disabled?: boolean;
  optional?: boolean;
}

export function AddressInput(props: AddressInputProps) {
  const {name, value, onChange, streetLabel, cityLabel, countryLabel, countryError, disabled, optional} = props;

  const form = useFormState();

  const streetName = `${name}_street`;
  const numberName = `${name}_number`;
  const postalCodeName = `${name}_postalcode`;
  const cityName = `${name}_city`;
  const countryName = `${name}_country`;

  const country = useMemo(() => Countries.find(x => x.code === value.countryCode), [value.countryCode]);
  const cityFirst = country ? country.cityFirst : false;

  const postalCodeInput = (
    <Col md={4}>
      <TextInput
        name={postalCodeName}
        label={T('addressInput.placeholder.postalCode')}
        value={value.postalCode}
        onChange={value => onChange({postalCode: value})}
        placeholder={T('addressInput.placeholder.postalCode')}
        validate={validateAtMost(16)}
        disabled={disabled}
        optional={optional}
      />
    </Col>
  );

  const cityInput = (
    <Col md={8}>
      <TextInput
        name={cityName}
        label={T('addressInput.placeholder.city')}
        value={value.city}
        onChange={value => onChange({city: value})}
        placeholder={T('addressInput.placeholder.city')}
        validate={validateSpecialChars}
        disabled={disabled}
        optional={optional}
      />
    </Col>
  );

  return (
    <>
      <FormInputGroup
        name={name}
        label={streetLabel}
        error={form.getShownError(streetName) || form.getShownError(numberName)}
      >
        <TextInput
          name={streetName}
          label={streetLabel}
          value={value.streetAndNumber || ''}
          onChange={value => onChange({streetAndNumber: value})}
          placeholder={T('addressInput.placeholder.street')}
          validate={validateSpecialChars}
          disabled={disabled}
          optional={optional}
        />
      </FormInputGroup>
      <FormInputGroup
        name="city"
        label={cityLabel}
        error={form.getShownError(cityName) || form.getShownError(postalCodeName)}
      >
        <Row>
          {cityFirst ? cityInput : postalCodeInput}
          {cityFirst ? postalCodeInput : cityInput}
        </Row>
      </FormInputGroup>
      <CountryInputGroup
        form={form}
        name={countryName}
        label={countryLabel}
        value={value.countryCode}
        onChange={useCallback(value => onChange({countryCode: (value && value.code) || ''}), [onChange])}
        disabled={disabled}
        error={countryError}
      />
    </>
  );
}
