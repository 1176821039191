import React from 'react';

import {useAppContext} from '../app/context';
import {setContextLocationId} from '../redux/actions/location';
import {Fetcher} from '../utils/Fetcher';
import {useLocation} from '../utils/FunctionalData';

import {Button} from './bootstrap/Button';

interface SelectLocationIdButtonProps {
  fetch: Fetcher;
  locationId: number;
}

export default function SelectLocationButton(props: SelectLocationIdButtonProps) {
  const {fetch, locationId} = props;
  const {store, api} = useAppContext();
  const [location] = useLocation(fetch, locationId);

  const handleClicked = () => {
    if (!location) return;

    setContextLocationId(store, api, location.id);
  };

  return (
    <Button color="link" onClick={handleClicked}>
      {location ? location.name : '...'}
    </Button>
  );
}
