import {cva, type VariantProps} from 'class-variance-authority';
import * as React from 'react';

import {cn} from '../../lib/utils';

const badgeVariants = cva(
  'tw-inline-flex tw-items-center tw-rounded-full tw-border tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-semibold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2',
  {
    variants: {
      variant: {
        default: 'tw-border-transparent tw-bg-green-200 tw-text-primary hover:tw-bg-green-300',
        success: 'tw-border-transparent tw-bg-green-200 tw-text-primary hover:tw-bg-green-300',
        warning: 'tw-border-transparent tw-bg-yellow-200 tw-text-primary hover:tw-bg-yellow-300',
        destructive: 'tw-border-transparent tw-bg-red-200 tw-text-primary hover:tw-bg-red-300',
        danger: 'tw-border-transparent tw-bg-red-200 tw-text-primary hover:tw-bg-red-300',
        info: 'tw-border-transparent tw-bg-blue-200 tw-text-primary hover:tw-bg-blue-300',
        secondary: 'tw-border-transparent tw-bg-blue-200 tw-text-primary hover:tw-bg-blue-300'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({className, variant, ...props}: BadgeProps) {
  return <div className={cn(badgeVariants({variant}), className)} {...props} />;
}

export {Badge, badgeVariants};
