import {AuthUser} from '../models/AuthUser';
import {IBoard} from '../models/Board';
import {Channel} from '../models/Channel';
import {Load} from '../models/Load';
import {ILocation, ILocationSummary} from '../models/Location';

export interface IAppState {
  me: IMeState;
  boards: IBoardsState;
  location: ILocationState;
  locations: ILocationsState;
  preferences: IPreferencesState;
  uiState: IUIState;
}

export interface IMeState {
  me: AuthUser;
}

export enum LoadState {
  NOT_YET_LOADED,
  FETCHING,
  LOADED,
  ERROR
}

export enum BoardsState {
  NOT_YET_LOADED,
  FETCHING,
  INITIALIZING,
  LOADED
}

export interface IBoardsState {
  state: BoardsState;
  initializingBoard?: string;
  boards: IBoard[];
}

export type CardStates = {[cardId: string]: ICardState};

export interface ICardState {
  ctConfig?: ICTConfigState;
  query?: string;
}

export interface ICTConfigState {
  loads: Load[];
  channels: Channel[];
  changedLoadIds: Set<number>;
  changedChannelIds: Set<number>;
  newLoads: Load[];
}

export interface ILocationState {
  loading: LoadState;
  location?: ILocation;
}

export interface ILocationsState {
  loading: LoadState;
  locations: ILocationSummary[];
  fetchedLocations: ILocationSummary[];
}

export enum HourFormat {
  Auto = 'auto',
  AmPm = '12',
  Hours24 = '24'
}

export interface IPreferencesState {
  loadState: LoadState;
  batchCounter: number;
  decimalSeparator: string;
  thousandSeparator: string;
  delimiter: string;
  locationId: number | undefined;
  dashboardDefaultsVersion: number;
  evBoardAdded: boolean;
  shownHints: OnboardingHint[];
  demoMode: boolean;
  navigationWidth?: number;
  hourFormat?: HourFormat;
}

export interface IUIState {
  presenting: boolean;
  organizing: boolean;
  inMaintenance: boolean;
  organizingState: {};
  organizingLastSaved: Date | null;
  menuCollapsed: boolean;
  initialNow: number;
  availableLocales: string[];
}

export enum OnboardingHint {
  AddBoard = 'ADD_BOARD',
  AddingStandardBoard = 'ADD_STANDARD_BOARD',
  AddCard = 'ADD_CARD',
  EditCardSettings = 'EDIT_CARD_SETTINGS'
}
