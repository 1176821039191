import React from 'react';

import {T} from '../../utils/Internationalization';

export interface ErrorPageProps {
  error?: {
    name: string;
    message: string;
    stack: string;
  };
}

export class ErrorPage extends React.Component<ErrorPageProps> {
  render() {
    if (/*__DEV__ && */ this.props.error) {
      return (
        <div>
          <h1>{this.props.error.name}</h1>
          <pre>{this.props.error.stack}</pre>
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 2rem',
          height: '100%',
          fontFamily: 'sans-serif',
          textAlign: 'center',
          color: '#888'
        }}
      >
        <h1>{T('error.title')}</h1>
        <p>{T('error.message')}</p>
      </div>
    );
  }
}
