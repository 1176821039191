import {useState} from 'react';

import {Fetcher} from '../utils/Fetcher';

export interface IPromiseModalProps<T> {
  key: number;
  fetch: Fetcher;
  resolve: (value: T) => void;
  reject: () => void;
  onClosed: () => void;
}

export interface IPromiseModalTarget {
  show<T>(render: (props: IPromiseModalProps<T>) => JSX.Element): Promise<T>;
}

export function usePromiseModal<T>(props: IPromiseModalProps<T>): [boolean, (result: T) => void] {
  const [isOpen, setOpen] = useState(true);
  const resolve = (result: T) => {
    props.resolve(result);
    setOpen(false);
    props.onClosed();
  };
  return [isOpen, resolve];
}
