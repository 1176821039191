import dayjs from 'dayjs';
import React from 'react';

import {RowActionButton} from '../../components/bootstrap/RowActions';
import RowActions from '../../components/Table/RowActions';
import {isClosed, SplitBillingAgreement} from '../../models/SplitBillingAgreement';
import {CalculatedStringField, ComponentField, ITableField, StringField} from '../../models/Table';
import {formatCurrency} from '../../utils/Currency';
import {T} from '../../utils/Internationalization';

interface ActionCallbacks {
  canManage: boolean;
  onClickedEdit: (row: SplitBillingAgreement) => void;
  onClickedRemove: (row: SplitBillingAgreement) => void;
  onClickedClose: (row: SplitBillingAgreement) => void;
  onClickedResend: (row: SplitBillingAgreement) => void;
}

export function getStatus(agreement: SplitBillingAgreement) {
  if (!agreement.confirmed || agreement.pendingChanges !== undefined) {
    return T('splitBilling.status.pending');
  } else if (isClosed(agreement)) return T('splitBilling.status.closed');
  else return T('splitBilling.status.active');
}

export function getColumns(callbacks: ActionCallbacks): ITableField<SplitBillingAgreement>[] {
  const {canManage} = callbacks;
  return [
    new StringField('rfid', T('splitBilling.field.rfid')),
    new CalculatedStringField('status', T('splitBilling.field.status'), getStatus),
    new CalculatedStringField(
      'chargingStationSerial',
      T('splitBilling.field.chargingStation'),
      row => row.chargingStation?.serialNumber
    ),
    new CalculatedStringField('from', T('splitBilling.field.from'), row => dayjs(row.period.from).format('YYYY-MM-DD')),
    new CalculatedStringField('to', T('splitBilling.field.to'), row =>
      row.period.to !== undefined && row.period.to !== null ? dayjs(row.period.to).format('YYYY-MM-DD') : undefined
    ),
    new CalculatedStringField(
      'organization',
      T('splitBilling.field.refund.invoice.companyName'),
      row => row.organization.name
    ),
    new CalculatedStringField(
      'firstName',
      T('splitBilling.field.refund.invoice.firstName'),
      row => row.refund.invoice.firstName
    ),
    new CalculatedStringField(
      'lastName',
      T('splitBilling.field.refund.invoice.lastName'),
      row => row.refund.invoice.lastName
    ),
    new StringField('employeeNumber', T('splitBilling.field.employeeNumber')),
    new CalculatedStringField('rate', T('splitBilling.field.refund.rate'), row =>
      formatCurrency(row.refund.rate.currency, row.refund.rate.value)
    ),
    new ComponentField('actions', T('generic.actions'), row => (
      <RowActions style={{justifyContent: 'flex-end'}}>
        <RowActionButton
          title={T('splitBilling.actions.edit')}
          icon="Pencil"
          onClick={() => callbacks.onClickedEdit(row)}
        />
        {canManage &&
          row.confirmed &&
          (row.deletable === false || row.deletable === undefined) &&
          row.period.to === undefined && (
            <RowActionButton
              title={T('splitBilling.actions.close')}
              icon="Lock"
              onClick={() => callbacks.onClickedClose(row)}
            />
          )}
        {canManage && (!row.confirmed || row.pendingChanges !== undefined) && (
          <RowActionButton
            title={T('splitBilling.actions.resend')}
            icon="Redo"
            onClick={() => callbacks.onClickedResend(row)}
          />
        )}
        {canManage && (row.deletable === true || row.deletable === undefined) && (
          <RowActionButton
            action="delete"
            title={T('splitBilling.actions.remove')}
            onClick={() => callbacks.onClickedRemove(row)}
          />
        )}
      </RowActions>
    ))
  ];
}
