import {useMemo, useReducer} from 'react';

import {FluviusMeasurementCampaignType} from '../../../models/FluviusMeasurementCampaign';
import {IMeasuringCase, IOrganizationRegion} from '../../../models/Organization';

import {Template, TemplateSubmeter} from './Models';

export interface MeasurementCampaignFormState {
  name: string;
  type: FluviusMeasurementCampaignType;
  cabinId: string;
  transfoAId: string;
  transfoBId: string;
  vpkId: string;
  set?: IMeasuringCase;
  region?: IOrganizationRegion;
  city: string;
  streetAndNumber: string;
  reason: string;
  notes: string;
  voltage: '400V' | '230V' | '';
  templateA?: Template;
  templateB?: Template;
  templateABoardNumber: string;
  templateABoardName: string;
  templateBBoardNumber: string;
  templateBBoardName: string;
  templateASubmeters: TemplateSubmeter[];
  templateBSubmeters: TemplateSubmeter[];
}

export const defaultFormState: MeasurementCampaignFormState = {
  name: '',
  type: FluviusMeasurementCampaignType.__unset__,
  cabinId: '',
  transfoAId: '',
  transfoBId: '',
  vpkId: '',
  city: '',
  streetAndNumber: '',
  reason: '__',
  notes: '',
  voltage: '',
  templateABoardName: '',
  templateABoardNumber: '',
  templateASubmeters: [],
  templateBBoardName: '',
  templateBBoardNumber: '',
  templateBSubmeters: []
};

export class MeasurementCampaignFormActor {
  private dispatch: React.Dispatch<MeasurementCampaignFormAction>;

  constructor(dispatch: React.Dispatch<MeasurementCampaignFormAction>) {
    this.dispatch = dispatch;
  }

  set(value: MeasurementCampaignFormState) {
    this.dispatch({type: 'set', payload: value});
  }

  update(updates: Partial<MeasurementCampaignFormState>) {
    this.dispatch({type: 'update', payload: updates});
  }
}

interface FormBaseAction {
  type: string;
}
interface FormSetAction {
  type: 'set';
  payload: MeasurementCampaignFormState;
}
interface FormUpdateAction extends FormBaseAction {
  type: 'update';
  payload: Partial<MeasurementCampaignFormState>;
}

type MeasurementCampaignFormAction = FormSetAction | FormUpdateAction;

function measurementFormReducer(state: MeasurementCampaignFormState, action: MeasurementCampaignFormAction) {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'update':
      return {...state, ...action.payload};
    default:
      return state;
  }
}

export function useMeasurementCampaignFormState(
  initialState: MeasurementCampaignFormState
): [MeasurementCampaignFormState, MeasurementCampaignFormActor] {
  const [state, dispatch] = useReducer<typeof measurementFormReducer>(measurementFormReducer, initialState);
  const actor = useMemo(() => new MeasurementCampaignFormActor(dispatch), [dispatch]);
  return [state, actor];
}

export interface FormErrors {
  typeRequired: boolean;
  cabinId?: string;
  transfoAId?: string;
  transfoBId?: string;
  vpkId?: string;
  voltage?: string;
  cityRequired: boolean;
  streetAndNumber?: string;
  notes?: string;
  name?: string;
  regionRequired: boolean;
  reasonRequired: boolean;
  setRequired: boolean;
  templateA?: string;
  templateABoardName?: string;
  templateABoardNumber?: string;
  templateASubmeters: TemplateSubmeterErrors[];
  templateB?: string;
  templateBBoardName?: string;
  templateBBoardNumber?: string;
  templateBSubmeters: TemplateSubmeterErrors[];
}

export const defaultFormErrors: FormErrors = {
  typeRequired: false,
  cityRequired: false,
  regionRequired: false,
  reasonRequired: false,
  setRequired: false,
  templateASubmeters: [],
  templateBSubmeters: []
};

export interface TemplateSubmeterErrors {
  numberError?: string;
  nameError?: string;
  phaseInvalid: boolean;
}

export const noSubmeterErrors: TemplateSubmeterErrors = {phaseInvalid: false};
