import React, {CSSProperties} from 'react';

import styles from './RowActions.module.scss';

interface IRowActionsProps {
  style?: CSSProperties;
  children: JSX.Element | string | boolean | null | undefined | (JSX.Element | string | boolean | null | undefined)[];
}

export default function RowActions(props: IRowActionsProps) {
  return (
    <div className={styles.rowActions} style={props.style}>
      {props.children}
    </div>
  );
}
