import React, {useEffect, useState, useMemo, useCallback} from 'react';

import {useAppContext} from '../../app/context';
import {FormGroup, Label, Input} from '../../components/bootstrap';

import {Icon} from '../../components/Icon';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {useTextInput} from '../../components/inputs/TextInput';
import {SearchableSelectInput} from '../../components/SearchableSelectInput';
import {IHighLevelConfiguration} from '../../models/HighLevelConfiguration';
import {BrandColors} from '../../utils/BrandColors';
import {FormProvider} from '../../utils/FormContext';
import {T} from '../../utils/Internationalization';
import {TranslationKey} from '../../utils/TranslationTerms';
import {validateColor} from '../../utils/Validation';
import {SettingsPanelProps} from '../CardType';

import {ILiveValuesMiniSettings, LiveValuesMiniMode} from './LiveValuesMiniModels';

const colors: {color: BrandColors; translation: TranslationKey; hidden?: boolean}[] = [
  {color: BrandColors.GreenAtlantis, translation: 'brandColor.greenAtlantis'},
  {color: BrandColors.GreenEden, translation: 'brandColor.greenEden', hidden: true},
  {
    color: BrandColors.OrangeClementine,
    translation: 'brandColor.orangeClementine',
    hidden: true
  },
  {color: BrandColors.Blue, translation: 'brandColor.blue'},
  {color: BrandColors.GreenSushi, translation: 'brandColor.greenSushi'},
  {color: BrandColors.OrangeSunglow, translation: 'brandColor.orangeSunglow'},
  {color: BrandColors.GreenDeepSea, translation: 'brandColor.greenDeepSea', hidden: true},
  {color: BrandColors.White, translation: 'brandColor.white'},
  {color: BrandColors.Red, translation: 'brandColor.red'}
];

export const LiveValuesMiniSettings = (props: SettingsPanelProps<ILiveValuesMiniSettings>) => {
  const {editingSettings, updateSettings, locationId} = props;
  const {mode, loadId, color} = editingSettings;

  const {api} = useAppContext();
  const handleModeChanged = (value: string) => {
    updateSettings({mode: value as LiveValuesMiniMode});
  };
  const handleLoadChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    updateSettings({loadId: parseInt(e.currentTarget.value)});
  };
  const handleColorChanged = useCallback(
    (value: string) => {
      updateSettings({color: value === '' ? undefined : value});
    },
    [updateSettings]
  );
  const isCustomColor = color === 'custom';

  const [customColorInput, customColorValue] = useTextInput(
    'customColor',
    T('liveValuesMini.customColor.label'),
    color || '',
    validateColor,
    undefined,
    {info: T('liveValuesMini.customColor.info'), optional: true}
  );
  useEffect(() => updateSettings({customColor: customColorValue}), [updateSettings, customColorValue]);

  const [configuration, setConfiguration] = useState<IHighLevelConfiguration | undefined>();
  const submeterOptions = useMemo(() => {
    if (!configuration) return [];

    return configuration.measurements.map(item => (
      <option key={item.id} value={item.id}>
        {item.name || T('defaultNames.gridLoad')}
      </option>
    ));
  }, [configuration]);

  useEffect(() => {
    if (mode !== LiveValuesMiniMode.Submetering || locationId === undefined) {
      return;
    }

    api.getHighLevelConfiguration(locationId).then(config => setConfiguration(config));
  }, [api, locationId, mode]);

  const options = useMemo(
    () => [
      {
        value: '',
        label: T('brandColor.default'),
        icon: Icon.SolidSquare,
        color: 'white'
      },
      ...colors
        .filter(c => !c.hidden)
        .map(color => ({
          value: color.color,
          label: T(color.translation),
          icon: Icon.SolidSquare,
          color: color.color
        })),
      {value: 'custom', label: T('brandColor.custom'), icon: Icon.SolidTint}
    ],
    []
  );

  return (
    <FormProvider>
      <SelectInputGroup name="mode" label={T('card.displayType.label')} value={mode} onChange={handleModeChanged}>
        <option value={LiveValuesMiniMode.Consumption}>{T('liveValuesMini.mode.consumption')}</option>
        <option value={LiveValuesMiniMode.Solar}>{T('liveValuesMini.mode.solar')}</option>
        <option value={LiveValuesMiniMode.AlwaysOn}>{T('liveValuesMini.mode.alwaysOn')}</option>
        <option value={LiveValuesMiniMode.Gas}>{T('liveValuesMini.mode.gas')}</option>
        <option value={LiveValuesMiniMode.Water}>{T('liveValuesMini.mode.water')}</option>
        <option value={LiveValuesMiniMode.Submetering}>{T('liveValuesMini.mode.submetering')}</option>
      </SelectInputGroup>
      {configuration && mode === LiveValuesMiniMode.Submetering && (
        <FormGroup>
          <Label for="submeter">{T('liveValuesMini.submeter.label')}</Label>
          <Input type="select" value={loadId} onChange={handleLoadChanged} name="load">
            <option value="">{T('liveValuesMini.selectSubmeter')}</option>
            {submeterOptions}
          </Input>
        </FormGroup>
      )}
      <FormGroup>
        <Label>{T('liveValuesMini.color.label')}</Label>
        <SearchableSelectInput
          placeholder={T('liveValuesMini.color.placeholder')}
          value={isCustomColor ? 'custom' : color || ''}
          options={options}
          onChange={handleColorChanged}
        />
      </FormGroup>
      {isCustomColor && customColorInput}
    </FormProvider>
  );
};
