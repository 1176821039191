import * as React from 'react';

import {Button} from '../../components/bootstrap';

import {
  OrganizationInput,
  OrganizationInputQueryState,
  useCardOrganizations
} from '../../components/inputs/OrganizationInput';
import {ConfigurePricingGroupModal} from '../../components/pricingPolicies/configurePricingGroup/ConfigurePricingGroup';
import {SortOrder} from '../../components/Table';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable, ICardWithOrganization} from '../../models/CardSettings';
import {IOrganization} from '../../models/Organization';
import {IPricingGroup} from '../../models/PricingGroup';
import {hasPartnerFunctionality} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps} from '../components/CardView';

import {PricingGroupItem} from './PricingGroupItem';

type IPricingGroupsSettings = ICardSettingsWithTable & ICardWithOrganization;

interface PricingGroupCardActionsProps {
  organizations: IOrganization[];
  currentOrganization?: IOrganization;
  onCreate: (group: IPricingGroup) => void;
  onOrganizationSelected: (organization?: number) => void;
  onClickedRefresh: () => void;
  onUpdateQuery: (updates: Partial<OrganizationInputQueryState>) => void;
}

function PricingGroupCardActions(props: PricingGroupCardActionsProps) {
  const {
    organizations,
    currentOrganization,
    onOrganizationSelected: onOrganizationChange,
    onCreate,
    onClickedRefresh,
    onUpdateQuery
  } = props;

  const modals = useModals();

  const onChange = React.useCallback(
    (o?: IOrganization) => {
      onOrganizationChange(o?.id);
    },
    [onOrganizationChange]
  );

  const handleClickedCreate = () => {
    if (!currentOrganization) {
      return;
    }

    modals
      .show<IPricingGroup | null>(props => <ConfigurePricingGroupModal organization={currentOrganization} {...props} />)
      .then(group => group && onCreate(group));
  };

  return (
    <CardActions>
      <OrganizationInput
        name="organization"
        organizations={organizations}
        value={currentOrganization}
        onChange={onChange}
        onUpdateQuery={onUpdateQuery}
        placeholder={T('organizationUsers.selectOrganization')}
        style={{marginRight: 8}}
      />
      <Reload onReload={onClickedRefresh} />
      <Spring />
      <Button type="button" onClick={handleClickedCreate}>
        <i className="fa fa-plus mr-2" />
        {T('pricingGroups.add')}
      </Button>
    </CardActions>
  );
}

function PricingGroupsCard(props: ICardProps<IPricingGroupsSettings>) {
  const {fetch, settings, updateSettings} = props;

  const [inputOrganizations, updateOrganizationInputQuery, organization] = useCardOrganizations(fetch, settings);

  const [pricingGroups, refreshPricingGroups] = useCardLoader(
    api => (organization ? api.pricingGroups.getForOrganization(organization.id) : Promise.resolve(None)),
    [organization],
    plural('pricingGroup'),
    None
  );

  let error: string | undefined;
  if (organization === undefined) {
    error = T('errors.organization.notFound');
  } else if (pricingGroups.length === 0) {
    error = T('pricingGroups.noneFound');
  }

  return (
    <CardView
      error={props.loading ? undefined : error}
      actions={() => (
        <PricingGroupCardActions
          onCreate={() => refreshPricingGroups()}
          currentOrganization={organization}
          onOrganizationSelected={orgId => updateSettings({organization: orgId})}
          organizations={inputOrganizations.organizations}
          onClickedRefresh={refreshPricingGroups}
          onUpdateQuery={updateOrganizationInputQuery}
        />
      )}
      {...cardViewProps(props)}
    >
      {organization &&
        pricingGroups.map(group => (
          <PricingGroupItem
            organization={organization}
            key={group.id}
            group={group}
            onDelete={() => refreshPricingGroups()}
            onUpdate={() => refreshPricingGroups()}
          />
        ))}
    </CardView>
  );
}

const DEFAULT_SETTINGS: IPricingGroupsSettings = {
  table: {
    sortColumn: 'alias',
    sortOrder: SortOrder.ASCENDING,
    pageSize: 10,
    columns: [{name: 'alias', visible: true}]
  }
};

const CARD: ICardType<IPricingGroupsSettings> = {
  type: CardTypeKey.PricingGroups,
  title: 'pricingGroups.title',
  description: 'pricingGroups.description',
  categories: [CardCategory.ORGANIZATIONS],
  rights: UserRights.User,
  isAvailable: hasPartnerFunctionality,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: PricingGroupsCard
};

export default CARD;
