import React from 'react';

import {PhaseType} from '../../models/HighLevelConfiguration';

import BuildSvg from './BuildSvg';
import {LoadWithChannels} from './Data';
import {Legenda} from './Legenda';
import styles from './Summary.module.scss';

import {SVGDisplayBuilder} from './SVGDisplayBuilder';

interface SummaryProps {
  phaseType: PhaseType;
  load: LoadWithChannels;
  currents: number[];
  currentAngles: number[];
  voltages: number[];
  voltageAngles: number[];
}

export const Summary = (props: SummaryProps) => {
  const {load, currents, currentAngles, voltages, voltageAngles, phaseType} = props;

  const builder = new SVGDisplayBuilder(600, 400);
  const buildSVG = new BuildSvg(builder, phaseType, false, true);
  buildSVG.process(load, voltages, voltageAngles, currents, currentAngles);
  builder.addFilledCircle('inner-circle', 'black', 4);
  const svg = builder.render();

  return (
    <div className={styles.gaugeContainer}>
      {svg}
      {load && (
        <Legenda
          load={load}
          phaseType={phaseType}
          currents={currents}
          currentAngles={currentAngles}
          voltages={voltages}
        />
      )}
    </div>
  );
};
