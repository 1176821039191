import {T} from '../utils/Internationalization';

import {DEFAULT_OPTIONS, IFieldOptions, ITableField} from './Table';

export interface BaseInvitableEntry {
  accepted: boolean;
  expired: boolean;
}

export class InvitationStatusField<T extends BaseInvitableEntry> implements ITableField<T> {
  name: string;
  label: string;
  options: IFieldOptions;

  constructor(name: string, label: string, options: Partial<IFieldOptions> = {}) {
    this.name = name;
    this.label = label;
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  renderCellContent(item: T) {
    if (item.expired) {
      return <span style={{color: 'red'}}>{T('invitation.expired')}</span>;
    } else {
      return T.generic.yesNo(item.accepted);
    }
  }

  getExportCSVValue(item: T) {
    if (item.expired) {
      return T('invitation.expired');
    } else {
      return T.generic.yesNo(item.accepted);
    }
  }

  getExportExcelValue(item: T) {
    return this.getExportCSVValue(item);
  }

  sort = (a: T, b: T): number => {
    if (a.expired !== b.expired) {
      return a.expired ? 1 : -1;
    } else if (a.accepted !== b.accepted) {
      return a.accepted ? 1 : -1;
    } else {
      return 0;
    }
  };
}
