import React, {useState, useMemo, useCallback} from 'react';
import {Form, FormFeedback, FormGroup} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {Modal, ModalHeader} from '../../components/bootstrap';
import {SearchableSelectInput} from '../../components/SearchableSelectInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IUser} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useDelayedEffect} from '../../utils/Hooks';

interface SelectUserModalProps extends IPromiseModalProps<boolean> {
  onUserSelected: (user: IUser) => Promise<string | undefined>;
}

export const SelectUserModal = (props: SelectUserModalProps) => {
  const {onUserSelected} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const [error, setError] = useState<string>();

  const [foundUsers, setFoundUsers] = useState<IUser[]>([]);
  const foundUserOptions = useMemo(
    () =>
      foundUsers.map(user => ({
        value: user.id.toString(),
        label: user.emailAddress || '?'
      })),
    [foundUsers]
  );

  const [value, setValue] = useState('');

  const {api} = useAppContext();

  useDelayedEffect(
    () => {
      if (value === '') {
        setFoundUsers(None);
      } else {
        api.findUsers(value).then(setFoundUsers);
      }
    },
    [value],
    500
  );

  const handleUserSelected = useCallback(
    async (value: string) => {
      const user = foundUsers.find(user => user.id.toString() === value);
      if (!user) return;

      const error = await onUserSelected(user);
      if (error) {
        setError(error);
      } else {
        resolve(true);
      }
    },
    [foundUsers, onUserSelected, resolve]
  );

  const handleClose = () => resolve(false);

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="sm">
      <ModalHeader toggle={handleClose}>Select user</ModalHeader>
      <Modal.Body>
        <Form style={{marginTop: '1rem'}}>
          <FormGroup>
            <SearchableSelectInput
              value=""
              placeholder="Search user"
              options={foundUserOptions}
              onChange={handleUserSelected}
              onInputChange={setValue}
            />
            {error && <FormFeedback style={{display: 'block'}}>{error}</FormFeedback>}
          </FormGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
