import {useEffect, useRef, useState} from 'react';

import {useAppContext} from '../app/context';
import {useUser} from '../cards/CardUtils';
import {ILoadUpdateChannel} from '../models/Load';

import {MqttSubscription} from './MqttConnector';

export function useLiveSwitchStatus(serialNumber: string | undefined, channel: ILoadUpdateChannel | undefined) {
  const {mqtt} = useAppContext();
  const me = useUser();

  const [message, setMessage] = useState<ILiveSwitchStatus>();
  const subscriptionRef = useRef<MqttSubscription>();

  useEffect(() => {
    if (serialNumber === undefined || channel === undefined) return;

    mqtt.subscribe(
      me.userId.toString(),
      channel.userName || '',
      channel.password || '',
      channel.name,
      undefined,
      false,
      subscription => (subscriptionRef.current = subscription),
      setMessage
    );

    return () => subscriptionRef.current && subscriptionRef.current.close();
  }, [mqtt, me.userId, serialNumber, channel]);

  return message;
}

export interface ILiveSwitchStatus {
  value: 'ON' | 'OFF';
  since: number;
}
