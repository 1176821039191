import React from 'react';

import {Button as RsButton, Input} from '../../../components/bootstrap';

import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {T} from '../../../utils/Internationalization';

interface NameEditorProps {
  value: string;
  update: (value: string) => Promise<any>;
  placeholder: string;
  readOnly: boolean;
}
interface NameEditorState {
  originalValue: string;
  editing: boolean;
  loading: boolean;
  value: string;
}
class NameEditor extends React.Component<NameEditorProps, NameEditorState> {
  constructor(props: NameEditorProps) {
    super(props);

    this.state = {
      originalValue: props.value,
      editing: false,
      loading: false,
      value: props.value
    };
  }

  componentDidUpdate(lastProps: NameEditorProps) {
    if (this.props.value !== lastProps.value) {
      this.setState({originalValue: this.props.value, value: this.props.value});
    }
  }

  handleChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({value: e.currentTarget.value});
  };

  handleClickedEdit = () => {
    this.setState({
      editing: true,
      value: this.props.value
    });
  };

  handleClickedSave = () => {
    this.setState({loading: true});
    this.props.update(this.state.value).then(() => {
      this.setState({
        editing: false,
        loading: false,
        originalValue: this.state.value
      });
    });
  };

  handleClickedCancel = () => {
    this.setState({editing: false});
  };

  render() {
    const {readOnly, placeholder} = this.props;
    const {editing, loading, originalValue, value: editingValue} = this.state;

    if (editing) {
      return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Input
            type="text"
            name="editingValue"
            placeholder={placeholder}
            value={editingValue}
            onChange={this.handleChanged}
            autoWidth={true}
            style={{display: 'inline-block'}}
          />
          <RsButton color="primary" onClick={this.handleClickedSave} style={{marginLeft: '0.3em'}}>
            {T('locationConfiguration.field.save')}
            {loading && <i style={{marginLeft: '0.3em'}} className="fas fa-circle-notch fa-spin" />}
          </RsButton>
          <RsButton onClick={this.handleClickedCancel} style={{marginLeft: '0.3em'}}>
            {T('locationConfiguration.field.cancelEdit')}
          </RsButton>
        </div>
      );
    } else {
      return (
        <span>
          {originalValue}{' '}
          {!readOnly && (
            <Button variant="ghost_action_btn" size="icon" onClick={this.handleClickedEdit}>
              <Edit />
            </Button>
          )}
        </span>
      );
    }
  }
}

export default NameEditor;
