import {T} from '../utils/Internationalization';

export const enum Interval {
  MINUTES_5 = 'MINUTES_5',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',

  INTERVAL = 'INTERVAL', // not sure why, but this value does occur when showing 5-minute data
  REALTIME = 'REALTIME', // not sure why, but I suddenly get this value too...

  // these are not supported by API's, but they can be translated to IntervalV2
  X_MINUTES_10 = 'MINUTES_10',
  X_MINUTES_15 = 'MINUTES_15',
  X_MINUTES_20 = 'MINUTES_20',
  X_MINUTES_30 = 'MINUTES_30',
  X_TWO_YEARS = 'TWO_YEARS'
}

export const TimeValues = [
  Interval.HOUR,
  Interval.MINUTES_5,
  Interval.X_MINUTES_10,
  Interval.X_MINUTES_15,
  Interval.X_MINUTES_20,
  Interval.X_MINUTES_30
];

export function unitIsPowerInsteadOfConsumption(interval: Interval) {
  return (
    interval === Interval.MINUTES_5 ||
    interval === Interval.INTERVAL ||
    interval === Interval.X_MINUTES_10 ||
    interval === Interval.X_MINUTES_15 ||
    interval === Interval.X_MINUTES_20 ||
    interval === Interval.X_MINUTES_30 ||
    interval === Interval.REALTIME
  );
}

export function showAsLineGraph(interval: Interval) {
  return unitIsPowerInsteadOfConsumption(interval);
}

export const enum IntervalV2 {
  REALTIME = 'REALTIME',
  MINUTES_10 = 'MINUTES_10',
  MINUTES_15 = 'MINUTES_15',
  MINUTES_20 = 'MINUTES_20',
  MINUTES_30 = 'MINUTES_30',
  HOUR = 'HOUR',
  ONE_DAY = 'ONE_DAY',
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  ONE_YEAR = 'ONE_YEAR',
  TWO_YEARS = 'TWO_YEARS'
}

export function convertToV2(interval: Interval): IntervalV2 {
  switch (interval) {
    case Interval.MINUTES_5:
      return IntervalV2.REALTIME;
    case Interval.X_MINUTES_10:
      return IntervalV2.MINUTES_10;
    case Interval.X_MINUTES_15:
      return IntervalV2.MINUTES_15;
    case Interval.X_MINUTES_20:
      return IntervalV2.MINUTES_20;
    case Interval.X_MINUTES_30:
      return IntervalV2.MINUTES_30;
    case Interval.HOUR:
      return IntervalV2.HOUR;
    case Interval.DAY:
      return IntervalV2.ONE_DAY;
    case Interval.WEEK:
      return IntervalV2.ONE_WEEK;
    case Interval.MONTH:
      return IntervalV2.ONE_MONTH;
    case Interval.YEAR:
      return IntervalV2.ONE_YEAR;
    default:
      return IntervalV2.REALTIME;
  }
}

export function getIntervalLabel(interval: Interval) {
  switch (interval) {
    case Interval.MINUTES_5:
      return T('interval.5minutely');
    case Interval.INTERVAL:
      return 'interval.5minutely';
    case Interval.HOUR:
      return T('interval.hourly');
    case Interval.DAY:
      return T('interval.daily');
    case Interval.WEEK:
      return T('interval.weekly');
    case Interval.MONTH:
      return T('interval.monthly');
    case Interval.YEAR:
      return T('interval.yearly');
    default:
      return T('generic.unknown');
  }
}

export function getIntervalV2Label(interval: IntervalV2) {
  switch (interval) {
    case IntervalV2.REALTIME:
      return T('interval.5minutely');
    case IntervalV2.MINUTES_10:
      return T('interval.10minutely');
    case IntervalV2.MINUTES_15:
      return T('interval.15minutely');
    case IntervalV2.MINUTES_20:
      return T('interval.20minutely');
    case IntervalV2.MINUTES_30:
      return T('interval.30minutely');
    case IntervalV2.HOUR:
      return T('interval.hourly');
    case IntervalV2.ONE_DAY:
      return T('interval.daily');
    case IntervalV2.ONE_WEEK:
      return T('interval.weekly');
    case IntervalV2.ONE_MONTH:
      return T('interval.monthly');
    case IntervalV2.ONE_YEAR:
      return T('interval.yearly');
    case IntervalV2.TWO_YEARS:
      return T('interval.2yearly');
    default:
      return T('generic.unknown');
  }
}

export function getLegacyAggregationType(interval: Interval) {
  switch (interval) {
    case Interval.MINUTES_5:
      return 1;
    case Interval.HOUR:
      return 1; // fallback to 5 minutes
    case Interval.DAY:
      return 3;
    case Interval.WEEK:
      return 3; // fallback to day
    case Interval.MONTH:
      return 4;
    case Interval.YEAR:
      return 4; // fallback to month
    default:
      return 1;
  }
}

export function getAggregationTypeFromLegacy(type: number) {
  switch (type) {
    case 1:
      return Interval.MINUTES_5;
    case 2:
      return Interval.HOUR;
    case 3:
      return Interval.DAY;
    case 4:
      return Interval.MONTH;
    default:
      return Interval.DAY;
  }
}

export const enum UsageType {
  Electricity = 'ELECTRICITY',
  AlwaysOn = 'ALWAYS_ON',
  Solar = 'SOLAR',
  GridImport = 'GRID_IMPORT',
  SolarExport = 'SOLAR_EXPORT',
  SelfSufficiency = 'SELF_SUFFICIENCY',
  SelfConsumption = 'SELF_CONSUMPTION',
  Gas = 'GAS',
  Water = 'WATER',
  Channels = 'CHANNELS'
}

export const enum UsageUnit {
  Watt = 'WATT',
  WattHour = 'WATT_HOUR',
  KiloWattHour = 'KILO_WATT_HOUR',
  KiloWatt = 'KILO_WATT',
  Ampere = 'AMPERE',
  Volt = 'VOLT',
  Var = 'VAR',
  Liter = 'LITER',
  Gallon = 'GALLON',
  CubicMeter = 'CUBIC_METER',
  CubicFoot = 'CUBIC_FOOT',
  None = 'NONE',

  Unknown = 'UNKNOWN'
}

const UNIT_LABELS = {
  [UsageUnit.Watt]: 'W',
  [UsageUnit.WattHour]: 'Wh',
  [UsageUnit.KiloWatt]: 'kW',
  [UsageUnit.KiloWattHour]: 'kWh',
  [UsageUnit.Ampere]: 'A',
  [UsageUnit.Volt]: 'V',
  [UsageUnit.Var]: 'var',
  [UsageUnit.Liter]: 'L',
  [UsageUnit.Gallon]: 'gal',
  [UsageUnit.CubicMeter]: 'm³',
  [UsageUnit.CubicFoot]: 'ft³',
  [UsageUnit.None]: ' ',

  [UsageUnit.Unknown]: '?'
};

export function getUnitLabel(unit: UsageUnit | undefined) {
  return UNIT_LABELS[unit || UsageUnit.Unknown] || unit || '';
}

export interface IUsageList {
  usages: Array<IUsageValue>;
  intervalLength: Interval;
  showItemizedBill: boolean;
  showEvents: boolean;
}

export interface IUsageValue {
  type: UsageType;
  intervals: Array<IUsageInterval>;
  total: IUsageTotal;
}

export interface IUsageInterval {
  timestamp: number;
  value?: number;
  unit?: UsageUnit;
  cost?: number;
  currency?: string;
}

export interface IUsageTotal {
  value: number;
  unit: UsageUnit;
  cost: number;
  currency: string;
  rateCosts?: {cost: number; rateId: number; rateName: string}[];
}

export interface IApplianceUsageList {
  intervalLength: Interval;
  usage: {
    intervals: IUsageInterval[];
    showEvents: boolean;
    totals: IUsageTotal;
  };
}
