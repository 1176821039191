import React, {useState} from 'react';

import {SingleActionModal} from '../../../components/bootstrap';
import {AddressInput} from '../../../components/inputs/AddressInput';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {Address} from '../../../models/Countries';
import {T} from '../../../utils/Internationalization';

interface EditAddressModalProps extends IPromiseModalProps<Address | null | undefined> {
  address: Address | null;
  onChange: (address: Address | null) => Promise<unknown>;
}
export function EditAddressModal(props: EditAddressModalProps) {
  const {address, onChange} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  // quickfix for SW-9352, go back to address || default when cloud has been fixed
  const [editingAddress, setEditingAddress] = useState<Address>({
    streetAndNumber: (address ? address.streetAndNumber : '') || '',
    city: (address ? address.city : '') || '',
    postalCode: (address ? address.postalCode : '') || '',
    countryCode: (address ? address.countryCode : '') || 'BE'
  });

  const handleClose = () => resolve(undefined);

  const handleConfirm = () => {
    return onChange(editingAddress).then(() => {
      resolve(editingAddress);
      return undefined;
    });
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      title={T('locationConfiguration.setAddress.title')}
      action={handleConfirm}
    >
      <AddressInput
        name="address"
        value={editingAddress}
        onChange={updates => setEditingAddress({...editingAddress, ...updates})}
        streetLabel={T('locationConfiguration.setAddress.street')}
        cityLabel={T('locationConfiguration.setAddress.city')}
        countryLabel={T('locationConfiguration.setAddress.country')}
      />
    </SingleActionModal>
  );
}
