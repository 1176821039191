import Blob from 'blob';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import React from 'react';
import ReactDataExport from 'react-data-export';
import {connect} from 'react-redux';
import {v1 as uuidv1} from 'uuid';

import {Button as RsButton, Popover, PopoverBody} from '../../../components/bootstrap';

import {PeriodSettings, getPeriodRangeForTimezone, PeriodRoundingMode} from '../../../components/PeriodSelector';
import {IPersistedTableSettings, getVisibleColumnNames, SortOrder} from '../../../components/Table';
import {Button} from '../../../components/ui/button';
import {Download} from '../../../components/ui-lib/icons/small';
import {ILocation, ILocationSummary} from '../../../models/Location';
import {ITableField} from '../../../models/Table';
import {getIntervalLabel} from '../../../models/UsageValue';
import {IAppState} from '../../../redux/AppState';
import {isExcelExportSupported} from '../../../utils/BrowserDetector';
import {T} from '../../../utils/Internationalization';
import {testingClasses} from '../../../utils/TestingClasses';

import styles from './ExportCsv.module.scss';

// Excel
const {ExcelFile} = ReactDataExport;
const {ExcelSheet, ExcelColumn} = ExcelFile;

interface IExportCsvStateProps {
  delimiter: string;
  presenting: boolean;
}
interface IExportCsvOwnProps<T> {
  fields: ITableField<T>[];
  settings: IPersistedTableSettings;
  items: T[];
  name?: string;
  tooltip?: string;
  range?: PeriodSettings;
  location?: ILocationSummary | ILocation;
}
type ExportCsvProps<T> = IExportCsvStateProps & IExportCsvOwnProps<T>;
interface IExportCsvState {
  popoverOpen: boolean;
  downloadExcel: boolean;
}

class ExportCsv<T> extends React.Component<ExportCsvProps<T>, IExportCsvState> {
  static defaultProps = {
    name: T('card.export.name'),
    tooltip: T('card.export.tooltip'),
    presenting: false
  };

  id: string;
  popoverId: string;
  warningId: string;
  popoverRef: Popover | null;
  isExcelSupported: boolean;

  constructor(props: ExportCsvProps<T>) {
    super(props);

    // Reuse ID
    const uuid = uuidv1();
    this.popoverId = `p${uuid}`;
    this.warningId = `w${uuid}`;
    this.popoverRef = null;
    this.id = uuidv1();
    this.isExcelSupported = isExcelExportSupported();

    this.state = {
      popoverOpen: false,
      downloadExcel: false
    };
  }

  togglePopover = () => {
    this.setState({popoverOpen: !this.state.popoverOpen});
  };

  getFileName() {
    const {name, range, location} = this.props;
    const safeName = name?.replaceAll(/[/.?&*]/g, '-');
    if (range === undefined || location === undefined) return safeName;

    const {from, to} = getPeriodRangeForTimezone(range, location.timeZoneId, undefined, PeriodRoundingMode.INCLUSIVE);
    const fromFormatted = dayjs(from).format('YYYY-MM-DD');
    const toFormatted = dayjs(to).format('YYYY-MM-DD');
    const interval = getIntervalLabel(range.interval);
    if (fromFormatted === toFormatted) {
      return `${safeName} - ${fromFormatted} - ${interval}`;
    } else {
      return `${safeName} - ${fromFormatted} - ${toFormatted} - ${interval}`;
    }
  }

  handleExportCsv = () => {
    const csv = this.convertToCSV();
    const content = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    const filename = `${this.getFileName()}.csv`;
    FileSaver.saveAs(content, filename);

    this.setState({popoverOpen: false});
  };

  handleClickedExport = () => {
    this.setState({popoverOpen: false});
  };

  getExportFields(): ITableField<T>[] {
    const {fields, settings} = this.props;
    const fieldsByName = new Map<string, ITableField<T>>(fields.map(field => [field.name, field]));
    const result = [];
    for (let column of getVisibleColumnNames(fields, settings)) {
      const field = fieldsByName.get(column);
      if (!field || !field.options.exportable) continue;

      result.push(field);
    }
    return result;
  }

  convertToCSV(): string {
    const {delimiter} = this.props;
    const items = this.getSortedItems();
    const exportFields = this.getExportFields();

    const lines = items.map(item => {
      return exportFields
        .map(field => {
          let result = field.getExportCSVValue(item);
          if (result.includes(delimiter) || result.includes('"')) {
            result = `"${result.replace('"', '""')}"`;
          }
          return result;
        })
        .join(delimiter);
    });
    const header = exportFields.map(field => this.getFieldLabel(field)).join(delimiter);
    return `${header}\n${lines.join('\n')}`;
  }

  getSortedItems(): T[] {
    const {settings} = this.props;
    const sortColumn = settings.sortColumn || this.getDefaultSortColumn();
    const sortOrder = settings.sortOrder || SortOrder.ASCENDING;
    return this.sort(sortColumn, sortOrder);
  }

  sort(column: string | undefined, order: SortOrder): T[] {
    if (!column) return this.props.items;

    const result = [...this.props.items];
    const field = this.props.fields.find(field => field.name === column);
    if (!field) return result;

    result.sort(order === SortOrder.ASCENDING ? field.sort : (a, b) => field.sort(b, a));
    return result;
  }

  getDefaultSortColumn(): string | undefined {
    const field = this.props.fields.find(field => field.options.sortable);
    return field ? field.name : undefined;
  }

  getFieldLabel(field: ITableField<unknown>) {
    if (field.options.unit !== undefined) {
      return `${field.label} [${field.options.unit}]`;
    } else {
      return field.label;
    }
  }

  renderExcelDownload() {
    const items = this.getSortedItems();
    const fields = this.getExportFields();
    const filename = this.getFileName();
    const columns = fields.map(field => (
      <ExcelColumn
        key={field.name}
        label={this.getFieldLabel(field)}
        value={(item: T) => field.getExportExcelValue(item)}
      />
    ));

    const excelButton = (
      <RsButton onClick={this.handleClickedExport} color="secondary">
        Excel
      </RsButton>
    );

    return (
      <ExcelFile element={excelButton} filename={filename}>
        <ExcelSheet data={items} name={filename}>
          {columns}
        </ExcelSheet>
      </ExcelFile>
    );
  }

  render() {
    const {tooltip, presenting} = this.props;
    const {popoverOpen} = this.state;

    if (presenting) return <div />;

    return (
      <div className={testingClasses.download} data-testid={testingClasses.download}>
        <Button id={this.popoverId} variant="secondary_default" size="lg" title={tooltip} data-testid="export-csv">
          <Download className="tw-h-4 tw-w-4" />
        </Button>

        <Popover
          id={this.id}
          placement="bottom"
          target={this.popoverId}
          isOpen={popoverOpen}
          toggle={this.togglePopover}
        >
          <PopoverBody>
            {popoverOpen && (
              <div className={styles.applyPeriod}>
                {this.isExcelSupported && this.renderExcelDownload()}

                <RsButton onClick={this.handleExportCsv}>CSV</RsButton>
              </div>
            )}
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

const mapState = (state: IAppState): IExportCsvStateProps => ({
  delimiter: state.preferences.delimiter,
  presenting: state.uiState.presenting
});

export default connect<IExportCsvStateProps, {}, IExportCsvOwnProps<any>, IAppState>(mapState)(ExportCsv);
