import type {SVGProps} from 'react';
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M4 5a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H4ZM8 5a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H8ZM11 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM4 8a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H4ZM3 12a1 1 0 0 1 1-1h1a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1ZM8 8a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H8ZM7 12a1 1 0 0 1 1-1h1a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1ZM12 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM11 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
    <path d="M4 0a1 1 0 0 0-1 1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1H6a1 1 0 0 0-1-1H4ZM2 5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5Z" />
  </svg>
);
export default SvgCalendar;
