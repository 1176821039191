export interface RFIDCard {
  id: string;
  userId: number;
  name: string;
  employeeNumber?: string;
  username: string;
  userFirstName?: string;
  userLastName?: string;
  userEmailAddress?: string;
  value: string;
  provider: RFIDCardProvider;
  organization?: {
    id: number;
    name: string;
  };
}

export const enum RFIDCardProvider {
  Smappee = 'SMAPPEE',
  Other = 'OTHER'
}
