import cn from 'classnames';
import {ChangeEvent} from 'react';

import {Button as RsButton} from 'reactstrap';

import {T} from '../../utils/Internationalization';
import {Icons} from '../Icon';

import styles from './ImageInput.module.scss';

export interface ImageInputProps {
  image: string;
  alt?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  emptyViewChildren: React.ReactNode;
  className?: string;
  showButton?: boolean;
}

const ImageInput = (props: ImageInputProps) => {
  const {image, alt = '', onChange, className, emptyViewChildren, showButton = false} = props;
  return (
    <div className={cn(styles.fileBox, className)}>
      <input
        className={cn(styles.fileInput, !showButton ? styles.notAllowed : styles.allowed)}
        type="file"
        id="file-box-input"
        accept=".jpg, .png, .jpeg, .webp"
        onChange={onChange}
        disabled={!showButton}
      />
      <div className={styles.info}>
        {!image ? (
          emptyViewChildren
        ) : (
          <div className={styles.previewContainer} data-testid="image-preview-container">
            <img alt={alt} src={image} />
            {showButton && (
              <div className={styles.actionArea}>
                <RsButton className={styles.uploadBtn}>
                  <span className={styles.icon}>{Icons.Upload}</span>
                  <span>{T('chargingStationConfiguration.manageDisplayImages.cta.uploadImage')}</span>
                </RsButton>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageInput;
