import {AppStore} from '..';
import API from '../../core/api';
import {ILocation, ILocationSummary} from '../../models/Location';

import {updatePreferences} from './preferences';

export enum LocationActions {
  FETCHING = 'LOCATION.FETCHING',
  LOAD_SUCCESS = 'LOCATION.LOAD_SUCCESS',
  LOAD_ERROR = 'LOCATION.LOAD_ERROR',
  UPDATE = 'LOCATION.UPDATE'
}

export interface ISetLocationStartAction {
  type: LocationActions.FETCHING;
}

export interface ISetLocationSuccessAction {
  type: LocationActions.LOAD_SUCCESS;
  payload: ILocation;
}

export interface ISetLocationErrorAction {
  type: LocationActions.LOAD_ERROR;
  payload: any;
}

export interface IUpdateLocationAction {
  type: LocationActions.UPDATE;
  payload: ILocation;
}

export type AnyLocationAction =
  | ISetLocationStartAction
  | ISetLocationSuccessAction
  | ISetLocationErrorAction
  | IUpdateLocationAction;

export async function loadLocation(
  store: AppStore,
  api: API,
  id: number,
  retrying: boolean = false
): Promise<ILocation | undefined> {
  store.dispatch({
    type: LocationActions.FETCHING
  });

  try {
    const location = await api.locations.get(id);
    if (location === undefined) return location;
    api.setUserPreference('locationId', id.toString());
    store.dispatch(updatePreferences({locationId: id}));
    store.dispatch({
      type: LocationActions.LOAD_SUCCESS,
      payload: location
    });
    return location;
  } catch (err) {
    // If this location can't be load, load something else
    if ((err as any).statusCode === 403 && !retrying) {
      const locations = await api.user.getLocations();
      if (locations.length > 0) {
        return loadLocation(store, api, locations[0].id, true);
      }
    }
    store.dispatch({
      type: LocationActions.LOAD_ERROR,
      payload: err
    });
    return undefined;
  }
}

export async function loadCurrentLocation(store: AppStore, api: API, id: number): Promise<ILocation | undefined> {
  store.dispatch({
    type: LocationActions.FETCHING
  });

  try {
    const location = await api.locations.get(id);
    if (location === undefined) return location;

    store.dispatch({
      type: LocationActions.LOAD_SUCCESS,
      payload: location
    });
    return location;
  } catch (err) {
    // If this location can't be load, load something else
    if ((err as any).statusCode === 403) {
      const locations = await api.user.getLocations();
      if (locations.length > 0) {
        return loadLocation(store, api, locations[0].id, true);
      }
    }
    store.dispatch({
      type: LocationActions.LOAD_ERROR,
      payload: err
    });
    return undefined;
  }
}

export function createUpdateLocation(location: ILocation): IUpdateLocationAction {
  return {
    type: LocationActions.UPDATE,
    payload: location
  };
}

export function setLocation(store: AppStore, api: API, location: ILocationSummary): Promise<any> {
  return loadLocation(store, api, location.id);
}

export function setContextLocationId(store: AppStore, api: API, locationId: number): Promise<any> {
  return loadLocation(store, api, locationId);
}
