import {Marker, MarkerClusterer, MarkerF, MarkerProps} from '@react-google-maps/api';
import React from 'react';

import {IMapProps, Map} from '../../components/Map';
import {ChargingStation, IChargingStation} from '../../models/ChargingStation';
import {ILocationSummary} from '../../models/Location';

interface LocationMarkerProps {
  location: ILocationSummary;
  isCurrent: boolean;
  onClick: () => void;
  clusterer?: MarkerProps['clusterer'];
}
const LocationMarker = (props: LocationMarkerProps) => {
  const {location, isCurrent, onClick, clusterer} = props;
  let icon;
  if (isCurrent) {
    icon = {url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'};
  } else if (!location.serialNumber) {
    icon = {url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'};
  } else {
    icon = {url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'};
  }
  if (!location.latitude || !location.longitude) return null;

  return (
    <Marker
      position={{lat: location.latitude, lng: location.longitude}}
      title={location.name}
      icon={icon}
      onClick={onClick}
      clusterer={clusterer}
    />
  );
};

interface ChargingStationMarkerProps {
  chargingStation: IChargingStation;
  isCurrent: boolean;
  onClick: () => void;
  clusterer?: MarkerProps['clusterer'];
}
const ChargingStationMarker = (props: ChargingStationMarkerProps) => {
  const {chargingStation, isCurrent, clusterer, onClick} = props;
  let icon;
  if (isCurrent) {
    icon = {url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'};
  } else if (!chargingStation.active) {
    icon = {url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'};
  } else {
    icon = {url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'};
  }

  const location = chargingStation.serviceLocation;
  if (!location || location.latitude === undefined || location.longitude === undefined) {
    return null;
  }

  return (
    <MarkerF
      position={{lat: location.latitude, lng: location.longitude}}
      title={chargingStation.name}
      icon={icon}
      onClick={onClick}
      clusterer={clusterer}
    />
  );
};

interface LocationMapProps extends IMapProps {
  locations: ILocationSummary[];
  chargingStations?: ChargingStation[];
  currentLocation: ILocationSummary | undefined;
  onClickedChargingStation?: (station: ChargingStation) => void;
  onClickedMarker?: (location: ILocationSummary) => void;
  clusterer?: MarkerProps['clusterer'];
}
export const LocationGoogleMap = (props: LocationMapProps) => {
  const {
    locations,
    chargingStations = [],
    currentLocation,
    onClickedMarker,
    clusterer,
    onClickedChargingStation = () => {}
  } = props;
  const [selectedLocation, setSelectedLocation] = React.useState<ILocationSummary | undefined>(currentLocation);

  const handleMarkerClustererClick = () => {
    setSelectedLocation(undefined);
  };

  var chargingStationMarkers = chargingStations
    .filter(station => station.data.serviceLocation !== undefined)
    .map(station => (
      <ChargingStationMarker
        key={station.data.serialNumber}
        chargingStation={station.data}
        isCurrent={currentLocation ? station.serviceLocationId === currentLocation.id : false}
        onClick={() => onClickedChargingStation(station)}
        clusterer={clusterer}
      />
    ));

  return (
    <Map {...props} options={{streetViewControl: false, fullscreenControl: false}}>
      <MarkerClusterer onClick={handleMarkerClustererClick} gridSize={30}>
        {clusterer => {
          return (
            <>
              {locations
                .filter(location => location.latitude && location.longitude)
                .map(location => (
                  <LocationMarker
                    key={location.id}
                    location={location}
                    isCurrent={selectedLocation ? location.id === selectedLocation.id : false}
                    onClick={() => {
                      onClickedMarker?.(location);
                      setSelectedLocation(location);
                    }}
                    clusterer={clusterer}
                  />
                ))}
              {chargingStationMarkers}
            </>
          );
        }}
      </MarkerClusterer>
    </Map>
  );
};
