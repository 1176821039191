import {ILocationSummary} from '../../../models/Location';
import {IOverloadProtectionConfigMultiGrid} from '../../../models/OverloadProtection';
import {ISmartDevice} from '../../../models/SmartDevice';
import {
  bessCapacity,
  bessMaxChargeSpeed,
  bessMaxDischargeSpeed,
  bessMaxInverterPower,
  bessMaxStateOfCharge,
  bessMinStateOfCharge,
  BessRate,
  BessSpecificationData,
  getSmartDeviceConfigurationPropertyValue
} from '../models/BessUnitConfiguration.model';

// Load Specification data
export function getSpecificationData(
  bessDevice: ISmartDevice,
  locationSummary: ILocationSummary,
  maximumLoadConfiguration: IOverloadProtectionConfigMultiGrid | undefined,
  capacityMaximumPowerValue: number | undefined
): BessSpecificationData {
  const serialNumber = locationSummary?.serialNumber;
  const latitude = locationSummary?.latitude;
  const longitude = locationSummary?.longitude;
  const maxCapacity = getSmartDeviceConfigurationPropertyValue(bessDevice, bessCapacity);
  const maxDischargeSpeed = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxDischargeSpeed);
  const maxChargeSpeed = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxChargeSpeed);
  const maxInverterPower = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxInverterPower);
  const maxStateOfCharge = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxStateOfCharge);
  const upperHealthStatus = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxStateOfCharge);
  const lowerHealthStatus = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMinStateOfCharge);
  const maxCurrent = maximumLoadConfiguration?.maximumLoad;
  const capacityMaximumPower = capacityMaximumPowerValue;
  const maxPeakPower = maximumLoadConfiguration?.maximumLoad
    ? (maximumLoadConfiguration?.maximumLoad * 3 * 230) / 1000
    : 0;
  const minStateOfCharge = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMinStateOfCharge);
  const gridFee = 0;
  const rate = BessRate.FixedRate;
  const customRate = 0;
  const fixedTariff = 0.1;
  const multiplicator = 2;
  const taxMultiplicator = 0;

  const specificationData: BessSpecificationData = {
    serialNumber: serialNumber ?? '',
    geographicLocation: {
      latitude: latitude ?? 0,
      longitude: longitude ?? 0
    },
    maxCapacity: maxCapacity ? (maxCapacity as number) : 0,
    maxDischargeSpeed: maxDischargeSpeed ? (maxDischargeSpeed as number) : 0,
    maxChargeSpeed: maxChargeSpeed ? (maxChargeSpeed as number) : 0,
    maxInverterPower: maxInverterPower ? (maxInverterPower as number) : 0,
    maxStateOfCharge: maxStateOfCharge ? (maxStateOfCharge as number) : 0,
    healthLimits: {
      lowerHealthStatus: lowerHealthStatus ? (lowerHealthStatus as number) : 0,
      upperHealthStatus: upperHealthStatus ? (upperHealthStatus as number) : 0
    },
    maxCurrent,
    maxPeakPower,
    capacityMaximumPower: capacityMaximumPower ? capacityMaximumPower : 0,
    minStateOfCharge: minStateOfCharge ? (minStateOfCharge as number) : 0,
    gridFee, // @todo: replace hard code value
    rate, // @todo: replace hard code value
    customRate, // @todo: replace hard code value
    fixedTariff, // @todo: replace hard code value
    multiplicator, // @todo: replace hard code value
    taxMultiplicator // @todo: replace hard code value
  };
  return specificationData;
}
