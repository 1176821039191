import {
  IActivationCode,
  IActivationCodeList,
  IActivationHistory,
  IAssignActivationCodesResponse,
  ICreateActivationCodeRequest,
  IOrganizationActivationCode,
  IUpdateActivationCodeRequest
} from '../models/ActivationCode';
import {ILocationSummary} from '../models/Location';
import {SuccessResponse} from '../models/Response';
import {DataCache} from '../utils/DataCache';

import {APIClient} from './APIClient';

export class ActivationCodeCache {
  private client: APIClient;
  private organizationActivationCodes?: Promise<IActivationCodeList>;
  private activationCodesByOrganization: DataCache<number, IOrganizationActivationCode[]>;

  constructor(client: APIClient) {
    this.client = client;
    this.activationCodesByOrganization = new DataCache<number, IOrganizationActivationCode[]>('activationCodes', id =>
      this.getActivationCodesByOrganizationInternal(id)
    );
  }

  getOrganizationActivationCodes(refresh: boolean) {
    if (refresh || this.organizationActivationCodes === undefined) {
      const url = `/api/v10/activationcodes`;
      this.organizationActivationCodes = this.client.doGet(url);
    }
    return this.organizationActivationCodes;
  }

  getActivationCodesByOrganizationId(refresh: boolean, id: number) {
    return this.activationCodesByOrganization.fetch(id, refresh);
  }

  private getActivationCodesByOrganizationInternal(id: number): Promise<IOrganizationActivationCode[]> {
    const url = `/api/v10/organizations/${id}/activationcodes`;
    return this.client.doGet(url);
  }
}

export class ActivationCodeAPI {
  private client: APIClient;
  private cache: ActivationCodeCache;

  constructor(client: APIClient, cache: ActivationCodeCache) {
    this.client = client;
    this.cache = cache;
  }

  getAll(refresh: boolean = false): Promise<IActivationCodeList> {
    return this.cache.getOrganizationActivationCodes(refresh);
  }

  getByOrganization(refresh: boolean = false, id: number): Promise<IOrganizationActivationCode[]> {
    return this.cache.getActivationCodesByOrganizationId(refresh, id);
  }

  getHistory(activationCode: string): Promise<IActivationHistory> {
    const url = `/api/v10/activationcodes/${activationCode}/history`;
    return this.client.doGet(url);
  }

  assign(
    activationCode: string,
    serialNumbers: string[],
    orderNr?: string
  ): Promise<IAssignActivationCodesResponse | undefined> {
    let url = `/api/v10/activationcodes/${activationCode}/devices`;
    if (orderNr) url += `?orderNr=${orderNr}`;
    return this.client.doPost(url, serialNumbers);
  }

  move(
    fromActivationCode: string,
    toActivationCode: string,
    serialNumbers: string[],
    orderNr?: string
  ): Promise<IAssignActivationCodesResponse | undefined> {
    let url = `/api/v10/activationcodes/${toActivationCode}/devices?allowMoveFrom=${fromActivationCode}`;
    if (orderNr) url += `&orderNr=${orderNr}`;
    return this.client.doPost(url, serialNumbers);
  }

  setDeviceActivationCode(
    oldActivationCode: string | undefined,
    serialNumber: string,
    activationCode: string,
    orderNr?: string
  ) {
    if (oldActivationCode) {
      return this.move(oldActivationCode, activationCode, [serialNumber], orderNr);
    } else {
      return this.assign(activationCode, [serialNumber], orderNr);
    }
  }

  getLocations(activationCode: string): Promise<ILocationSummary[]> {
    const url = `/api/v10/activationcodes/${activationCode}/servicelocations`;
    return this.client.doGet(url);
  }

  removeDeviceActivation(activationCode: string, serialNumber: string): Promise<{success: boolean}> {
    const url = `/api/v10/activationcodes/${activationCode}/devices/${serialNumber}`;
    return this.client.doDeleteWithStatus(url);
  }

  create(activationCode: ICreateActivationCodeRequest): Promise<IActivationCode[] | undefined> {
    const url = `/api/v10/activationcodes`;
    return this.client.doPost(url, activationCode);
  }

  update(code: string, request: IUpdateActivationCodeRequest) {
    const url = `/api/v10/activationcodes/${code}`;
    return this.client.doPatch(url, request);
  }

  delete(activationCode: string, deleteHistory?: boolean): Promise<SuccessResponse | undefined> {
    let url = `/api/v10/activationcodes/${activationCode}`;
    if (deleteHistory) url += `?deleteHistory=${deleteHistory}`;
    return this.client.doDelete(url);
  }

  createChild(activationCode: string, subOrganization: number): Promise<IActivationCode | undefined> {
    const url = `/api/v10/activationcodes/${activationCode}/childs`;
    return this.client.doPost(url, {subOrganizationId: subOrganization});
  }
}
