import {AnyAppAction} from '../actions';
import {LocationsActions} from '../actions/locations';
import {ILocationsState, LoadState} from '../AppState';

export default function locations(
  state: ILocationsState = {
    locations: [],
    fetchedLocations: [],
    loading: LoadState.NOT_YET_LOADED
  },
  action: AnyAppAction
): ILocationsState {
  switch (action.type) {
    case LocationsActions.SET_LOCATIONS_START:
      return {
        locations: state.locations,
        fetchedLocations: state.fetchedLocations,
        loading: LoadState.FETCHING
      };

    case LocationsActions.SET_LOCATIONS_SUCCESS:
      return {
        locations: action.payload,
        fetchedLocations: state.fetchedLocations,
        loading: LoadState.LOADED
      };

    case LocationsActions.SET_LOCATIONS_ERROR:
      return {
        locations: state.locations,
        fetchedLocations: state.fetchedLocations,
        loading: LoadState.ERROR
      };

    case LocationsActions.FETCH:
      return {
        locations: state.locations,
        fetchedLocations: [action.payload, ...state.fetchedLocations],
        loading: state.loading
      };

    default:
      return state;
  }
}
