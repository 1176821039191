import React from 'react';

import API from '../../core/api';
import {GPSCoordinate} from '../../models/GPSCoordinate';
import {ILocationSummary, locationToSummary} from '../../models/Location';
import {ITableField, IFieldOptions, DEFAULT_OPTIONS} from '../../models/Table';
import {AppStore} from '../../redux';
import {setLocation} from '../../redux/actions/location';
import {T} from '../../utils/Internationalization';
import {Button as RsButton} from '../bootstrap';
import {Badge as UiBadge} from '../ui/badge';
import {ExternalLink} from '../ui-lib/icons/small';

const GOOGLE_MAPS_URL = 'https://www.google.com/maps?q=loc:';

export class LocationField implements ITableField<ILocationSummary> {
  name: string;
  label: string;
  options: IFieldOptions;
  projection: (location: ILocationSummary) => string | undefined;

  currentLocationId: number | undefined;
  api: API;
  store: AppStore;

  constructor(
    name: string,
    label: string,
    projection: (location: ILocationSummary) => string | undefined,
    currentLocationId: number | undefined,
    api: API,
    store: AppStore,
    options?: Partial<IFieldOptions>
  ) {
    this.name = name;
    this.label = label;
    this.options = {...DEFAULT_OPTIONS, ...options};

    this.projection = projection;
    this.currentLocationId = currentLocationId;
    this.api = api;
    this.store = store;
  }

  handleClicked(item: ILocationSummary) {
    setLocation(this.store, this.api, item);
  }

  renderCellContent(item: ILocationSummary) {
    let isSelected = item && item.id === this.currentLocationId;
    const value = this.projection(item);
    if (value === undefined) return undefined;

    return (
      <RsButton
        color="link"
        withoutPadding
        onClick={this.handleClicked.bind(this, item)}
        style={{whiteSpace: 'nowrap'}}
      >
        {this.getStringValue(item)}
        {isSelected && (
          // Legacy: size="lg"
          <UiBadge variant="success" className="tw-ml-2">
            {T('location.selected')}
          </UiBadge>
        )}
      </RsButton>
    );
  }

  getExportCSVValue(item: ILocationSummary): string {
    return this.getStringValue(item);
  }

  getExportExcelValue(item: ILocationSummary): string | number | Date {
    return this.getStringValue(item);
  }

  sort = (a: ILocationSummary, b: ILocationSummary): number => {
    return this.getStringValue(a).localeCompare(this.getStringValue(b));
  };

  getStringValue(item: ILocationSummary) {
    return this.projection(item) || '';
  }
}

export interface LocationIDWithName {
  id: number;
  name: string;
}
export class LocationIDField<T> implements ITableField<T> {
  name: string;
  label: string;
  options: IFieldOptions;
  projection: (item: T) => LocationIDWithName | undefined;

  currentLocationId: number | undefined;
  api: API;
  store: AppStore;

  constructor(
    name: string,
    label: string,
    projection: (item: T) => LocationIDWithName | undefined,
    currentLocationId: number | undefined,
    api: API,
    store: AppStore,
    options?: Partial<IFieldOptions>
  ) {
    this.name = name;
    this.label = label;
    this.options = {...DEFAULT_OPTIONS, ...options};

    this.projection = projection;
    this.currentLocationId = currentLocationId;
    this.api = api;
    this.store = store;
  }

  handleClicked(item: LocationIDWithName) {
    this.api.locations.get(item.id).then(location => {
      setLocation(this.store, this.api, locationToSummary(location));
    });
  }

  renderCellContent(item: T) {
    const value = this.projection(item);
    if (value === undefined) return undefined;

    let isSelected = value.id === this.currentLocationId;
    return (
      <RsButton
        color="link"
        withoutPadding
        onClick={this.handleClicked.bind(this, value)}
        style={{whiteSpace: 'nowrap'}}
      >
        {value.name}
        {isSelected && (
          // Legacy: size="lg"
          <UiBadge variant="success" className="tw-ml-2">
            {T('location.selected')}
          </UiBadge>
        )}
      </RsButton>
    );
  }

  getExportCSVValue(item: T): string {
    const value = this.projection(item);
    return value ? value.name : '';
  }

  getExportExcelValue(item: T): string | number | Date {
    const value = this.projection(item);
    return value ? value.name : '';
  }

  sort = (a: T, b: T): number => {
    const valueA = this.projection(a) || {id: 0, name: ''};
    const valueB = this.projection(b) || {id: 0, name: ''};
    return valueA.name.localeCompare(valueB.name);
  };
}

export class CoordinatesField<T> implements ITableField<T> {
  name: string;
  label: string;
  options: IFieldOptions;
  projection: (value: T) => GPSCoordinate;

  constructor(name: string, label: string, projection: (value: T) => GPSCoordinate) {
    this.name = name;
    this.label = label;
    this.options = DEFAULT_OPTIONS;
    this.projection = projection;
  }

  renderCellContent(item: T) {
    const {latitude: lat, longitude: lon} = this.projection(item);

    if (!lat || !lon) return undefined;

    const coordinates = `${lat},${lon}`;

    return (
      <RsButton
        color="link"
        target="_blank"
        href={GOOGLE_MAPS_URL + coordinates}
        withoutPadding
        style={{whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center'}}
      >
        {T('field.coordinates.viewOnMap')}
        <ExternalLink width={16} height={16} className="tw-flex-inline tw-ml-2" />
      </RsButton>
    );
  }

  getExportCSVValue(item: T): string {
    const {latitude: lat, longitude: lon} = this.projection(item);
    if (!lat || !lon) return '';

    return `${lat},${lon}`;
  }

  getExportExcelValue(item: T): string {
    return this.getExportCSVValue(item);
  }

  sort = (a: T, b: T): number => 0; // no sorting
}
