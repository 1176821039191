import React, {ReactNode} from 'react';

import {Button as RsButton, Modal, ModalBody, ModalFooter, ModalHeader} from '../components/bootstrap';
import {T} from '../utils/Internationalization';

import {testingClasses} from '../utils/TestingClasses';

import {IPromiseModalProps, usePromiseModal} from './PromiseModal';

export enum ConfirmationResult {
  Accept,
  Reject,
  Cancel
}

export interface IConfirmationPromiseModalProps extends IPromiseModalProps<ConfirmationResult> {
  title: string;
  message?: string;
  acceptLabel?: string;
  rejectLabel?: string;
  children?: ReactNode;
}

export function ConfirmationPromiseModal(props: IConfirmationPromiseModalProps) {
  const {title, message, acceptLabel = T('confirmation.yes'), rejectLabel = T('confirmation.no'), children} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleToggle = () => resolve(ConfirmationResult.Cancel);
  const handleAccept = () => resolve(ConfirmationResult.Accept);
  const handleReject = () => resolve(ConfirmationResult.Reject);

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} size="md" autoFocus={false}>
      <ModalHeader toggle={handleToggle}>{title}</ModalHeader>
      <ModalBody style={{paddingTop: '1rem'}}>{children || message}</ModalBody>
      <ModalFooter>
        <RsButton
          color="primary"
          onClick={handleAccept}
          className={testingClasses.confirmButton}
          data-testid={testingClasses.confirmButton}
        >
          {acceptLabel}
        </RsButton>
        <RsButton color="secondary" onClick={handleReject} className="t-confirm-reject">
          {rejectLabel}
        </RsButton>
      </ModalFooter>
    </Modal>
  );
}
