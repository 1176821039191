import React from 'react';

import {FieldValues, Path, UseFormReturn} from 'react-hook-form';
import {InputGroup, InputGroupAddon} from 'reactstrap';

import {T} from '../../utils/Internationalization';
import {Hotkeys} from '../../utils/Keys';
import {FieldValidator} from '../../utils/Validation';
import {Input as InputCN} from '../ui/input';

import FormInputGroup from './FormInputGroup';

interface TextInputGroupRHFProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T> & string;
  label?: string;
  readOnly?: boolean;
  type?: 'text' | 'number' | 'password' | 'textarea' | 'time';
  min?: number;
  max?: number;
  validate?: FieldValidator;
  autoFocus?: boolean;
  suffix?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  info?: string;
  invalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
  error?: string;
  className?: string;
}
export function TextInputRHFGroup<T extends FieldValues>(props: TextInputGroupRHFProps<T>) {
  const {
    form,
    name,
    label,
    readOnly,
    type = 'text',
    min,
    max,
    validate,
    autoFocus,
    onKeyPress,
    onEnter,
    suffix,
    info,
    invalid,
    placeholder,
    disabled = false,
    optional = false,
    error: customError,
    className
  } = props;

  const actualInfo = info || (optional ? T('validatedInput.optional') : undefined);

  const actualOnKeyPress = onEnter
    ? (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === Hotkeys.ENTER) {
          onEnter();
        } else {
          onKeyPress && onKeyPress(e);
        }
      }
    : onKeyPress;

  const input = (
    <InputCN
      // className="form-control"
      type={type}
      readOnly={readOnly}
      min={min}
      max={max}
      autoFocus={autoFocus}
      placeholder={placeholder}
      disabled={disabled}
      onKeyPress={actualOnKeyPress}
      {...form.register(name, {required: !optional})}
    />
  );

  const error = (form.formState.errors[name]?.message as string) || customError;

  return (
    <FormInputGroup name={name} label={label} error={error} info={actualInfo} className={className}>
      {suffix ? (
        <InputGroup>
          {input}
          <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
        </InputGroup>
      ) : (
        input
      )}
    </FormInputGroup>
  );
}

interface TextInputRHFProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T> & string;
  readOnly?: boolean;
  type?: 'text' | 'number' | 'password' | 'textarea';
  min?: number;
  max?: number;
  autoFocus?: boolean;
  suffix?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  info?: string;
  invalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
}
export function TextInputRHF<T extends FieldValues>(props: TextInputRHFProps<T>) {
  const {
    form,
    name,
    readOnly,
    type = 'text',
    min,
    max,
    autoFocus,
    onKeyPress,
    onEnter,
    suffix,
    invalid,
    placeholder,
    disabled,
    optional = false
  } = props;

  const actualOnKeyPress = onEnter
    ? (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === Hotkeys.ENTER) {
          onEnter();
        } else {
          onKeyPress && onKeyPress(e);
        }
      }
    : onKeyPress;

  const input = (
    <InputCN
      type={type}
      readOnly={readOnly}
      min={min}
      max={max}
      autoFocus={autoFocus}
      onKeyPress={actualOnKeyPress}
      placeholder={placeholder}
      disabled={disabled}
      {...form.register(name, {required: !optional})}
    />
  );

  return suffix ? (
    <InputGroup>
      {input}
      <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
    </InputGroup>
  ) : (
    input
  );
}
