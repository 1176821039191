import type {SVGProps} from 'react';
const SvgUsage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <path d="M12 23.712c6.075 0 11-5.149 11-11.5S18.075.712 12 .712 1 5.86 1 12.212s4.925 11.5 11 11.5Zm-1.617-11.865-2.638 2.758a.892.892 0 0 1-.682.28.893.893 0 0 1-.666-.28L3.15 11.209a.99.99 0 0 1 .001-1.356.89.89 0 0 1 1.297-.002l2.624 2.743 2.623-2.742a.924.924 0 0 1 .178-.145.888.888 0 0 1 1.168.111l2.63 2.748 2.623-2.743a.894.894 0 0 1 .652-.28.893.893 0 0 1 .665.28l3.241 3.388a.99.99 0 0 1 0 1.356.889.889 0 0 1-1.296 0l-2.603-2.722-2.604 2.722a.895.895 0 0 1-.62.28.891.891 0 0 1-.738-.275l-2.607-2.725Z" />
  </svg>
);
export default SvgUsage;
