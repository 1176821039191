import React, {useState, useMemo, useEffect} from 'react';

import {useAppContext} from '../../app/context';
import {Button as RsButton, Form, FormGroup, Input, Label} from '../../components/bootstrap';
import FormInputGroup from '../../components/inputs/FormInputGroup';
import {IAppliance} from '../../models/Appliance';
import {UserRights} from '../../models/AuthUser';
import {Automation} from '../../models/Automation';
import {ICardSettings} from '../../models/CardSettings';
import {IDevice} from '../../models/Device';
import {getDeviceTypeLabelFor} from '../../models/DeviceType';
import {ControllableNodeType} from '../../models/HomeControlDevice';
import {MessageType, IPushMessageContext} from '../../models/PushMessage';
import {useLocationUsers} from '../../utils/FunctionalData';
import {plural} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationId} from '../CardUtils';
import {cardViewProps, CardView} from '../components/CardView';

enum DeviceType {
  SmartDevice = 'SMART_DEVICE',
  ComfortPlug = 'COMFORT_PLUG',
  SmappeeSwitch = 'SMAPPEE_SWITCH',
  CarCharger = 'CAR_CHARGER',
  Thermostat = 'THERMOSTAT',
  Battery = 'BATTERY',
  OutputModuleControl = 'OUTPUT_MODULE_CONTROL'
}

const SendPushMessage = (props: ICardProps<ICardSettings>) => {
  const {fetch, settings} = props;
  const {api} = useAppContext();
  const locationId = useCardLocationId(settings);

  const [message, setMessage] = useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const [type, setType] = useState<MessageType>(MessageType.Custom);
  const [value, setValue] = useState('');
  const [valueId, setValueId] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  const [appliances, setAppliances] = useState<IAppliance[]>();
  const [smartDevices, setSmartDevices] = useState<{id: string; name: string; type: string}[]>();
  const [modules, setModules] = useState<IDevice[]>();
  const [automations, setAutomations] = useState<Automation[]>([]);

  const [locationUsers] = useLocationUsers(fetch, locationId);

  useEffect(() => {
    if (!locationId) return;

    if (type === MessageType.ApplianceDetail) {
      fetch('appliances', api => api.getAppliances(locationId), plural('appliance')).then(appliances => {
        setAppliances(appliances);
        setValueId(appliances.length === 0 ? '' : appliances[0].id);
        setValue(appliances.length === 0 ? '' : appliances[0].type);
      });
    } else if (type === MessageType.SmartCharging || type === MessageType.SmartDeviceDetail) {
      const smartDevices = fetch('smartcharging', api => api.getSmartDevices(locationId), plural('smartDevice'));
      const homeControl = fetch('homecontrol', api => api.getHomeControlDevices(locationId), plural('switchLeaf'));
      Promise.all([smartDevices, homeControl]).then(([devices, homeControl]) => {
        const controllableNodes = homeControl.controllableNodes.map(node => ({
          id: node.id.toString(),
          name: node.name,
          type: node.type === ControllableNodeType.SmappeeSwitch ? DeviceType.SmappeeSwitch : DeviceType.ComfortPlug
        }));
        const batteries = homeControl.batteries.map(battery => ({
          id: battery.id.toString(),
          name: battery.name,
          type: DeviceType.Battery
        }));
        const thermostats = homeControl.thermostats.map(thermostat => ({
          id: thermostat.id.toString(),
          name: thermostat.name,
          type: DeviceType.Thermostat
        }));
        const outputModuleControls = homeControl.outputModuleControls.map(control => ({
          id: control.id.toString(),
          name: control.name,
          type: DeviceType.OutputModuleControl
        }));
        const smartDevices = devices.map(device => ({
          id: device.id,
          name: device.name,
          type: device.type.category as unknown as DeviceType
        }));
        const allDevices = smartDevices.concat(controllableNodes, batteries, thermostats, outputModuleControls);
        allDevices.sort((a, b) => a.name.localeCompare(b.name));

        setSmartDevices(allDevices);
        setValueId(allDevices.length === 0 ? '' : allDevices[0].id);
      });
    } else if (type === MessageType.AutomationDetail || type === MessageType.SceneDetail) {
      fetch('automations', api => api.getAutomations(locationId), plural('automation')).then(automations => {
        setAutomations(automations);
        setValueId(automations.length === 0 ? '' : automations[0].id.toString());
      });
    } else if (type === MessageType.InfinityModuleDetail) {
      fetch('infinity', api => api.locations.getModules(locationId), plural('module')).then(modules => {
        setModules(modules);
        setSerialNumber(modules.length === 0 ? '' : modules[0].serialNumber);
      });
    }
  }, [locationId, type, fetch]);

  const handleUserChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSelectedUser(e.currentTarget.value);
  };

  const handleMessageChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setMessage(e.currentTarget.value);
  };

  const handleTypeChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setType(e.currentTarget.value as MessageType);
  };

  const handleValueChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const handleValueIdChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setValueId(e.currentTarget.value);

    // move to effect?
    if (type === MessageType.ApplianceDetail) {
      const appliance = (appliances || []).find(appliance => appliance.id === valueId);
      if (appliance) {
        setValueId(appliance.id);
        setValue(appliance.type);
      }
    } else if (type === MessageType.SmartDeviceDetail) {
      const smartDevice = (smartDevices || []).find(device => device.id === valueId);
      if (smartDevice) {
        setValue(smartDevice.type);
      }
    }
  };

  const handleSerialNumberChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSerialNumber(e.currentTarget.value);
  };

  const isValid = message.trim().length !== 0;

  const handleClickedSend = () => {
    setLoadingMessage('Sending message...');

    if (!locationId) return;

    let context: IPushMessageContext | undefined;
    if (type !== MessageType.Custom) {
      context = {
        notificationType: type,
        notificationValue: value,
        notificationValueId: valueId,
        notificationSerialNumber: serialNumber
      };
    }

    api.sendInAppMessage(locationId, message, selectedUser === '' ? undefined : selectedUser, context).then(() => {
      setLoadingMessage('Message sent');
      setMessage('');
    });
  };

  const userOptions = useMemo(() => {
    const allUsersOption = (
      <option key="all" value="">
        All service location users
      </option>
    );

    const userOptions = locationUsers.map(user => {
      const value = user.id;
      const label = user.emailAddress;

      return (
        <option key={value} value={value}>
          {label}
        </option>
      );
    });
    return [allUsersOption, ...userOptions];
  }, [locationUsers]);

  return (
    <CardView {...cardViewProps(props)}>
      <Form style={{overflowY: 'scroll'}}>
        <FormInputGroup name="user" label="User">
          <Input type="select" value={selectedUser} onChange={handleUserChange}>
            {userOptions}
          </Input>
        </FormInputGroup>

        <FormInputGroup name="context" label="Context">
          <Input type="select" value={type} onChange={handleTypeChanged}>
            <option value={MessageType.Custom}>No context</option>
            <option value={MessageType.Dashboard}>Dashboard</option>
            <option value={MessageType.Survey}>Survey</option>
            <option value={MessageType.Usages}>Usages</option>
            <option value={MessageType.Tariffs}>Tariffs</option>
            <option value={MessageType.Appliances}>Appliances</option>
            <option value={MessageType.ApplianceDetail}>Appliance detail</option>
            <option value={MessageType.Control}>Control</option>
            <option value={MessageType.SmartDevices}>Smart devices</option>
            <option value={MessageType.SmartDeviceDetail}>Smart device detail</option>
            <option value={MessageType.SmartCharging}>Smart charging</option>
            <option value={MessageType.Automations}>Automations</option>
            <option value={MessageType.AutomationDetail}>Automation detail</option>
            <option value={MessageType.Events}>Events</option>
            <option value={MessageType.Notifications}>Notifications</option>
            <option value={MessageType.Scoreboard}>Scoreboard</option>
            <option value={MessageType.LocationDetail}>Location detail</option>
            <option value={MessageType.AppStore}>App store</option>
            <option value={MessageType.Subscription}>Subscription</option>
            <option value={MessageType.LoadConfiguration}>Load configuration</option>
            <option value={MessageType.InfinityModules}>Infinity modules</option>
            <option value={MessageType.InfinityModuleDetail}>Infinity module detail</option>
            <option value={MessageType.Scenes}>Scenes</option>
            <option value={MessageType.SceneDetail}>Scene detail</option>
          </Input>
        </FormInputGroup>

        {type === MessageType.ApplianceDetail && (
          <FormInputGroup name="appliance" label="Appliance">
            <Input type="select" value={valueId} onChange={handleValueIdChanged}>
              {(appliances || []).map(appliance => (
                <option key={appliance.id} value={appliance.id}>
                  {appliance.name}
                </option>
              ))}
            </Input>
          </FormInputGroup>
        )}
        {type === MessageType.SmartDeviceDetail && (
          <FormInputGroup name="smart-device" label="Smart device">
            <Input type="select" value={valueId} onChange={handleValueIdChanged}>
              {(smartDevices || []).map(device => (
                <option key={device.id} value={device.id}>
                  {device.name}
                </option>
              ))}
            </Input>
          </FormInputGroup>
        )}
        {type === MessageType.InfinityModuleDetail && (
          <FormInputGroup name="module" label="Module">
            <Input type="select" value={valueId} onChange={handleSerialNumberChanged}>
              {(modules || []).map(module => (
                <option key={module.id} value={module.id}>
                  {module.serialNumber} ({getDeviceTypeLabelFor(module.type)})
                </option>
              ))}
            </Input>
          </FormInputGroup>
        )}
        {type === MessageType.Usages && (
          <FormInputGroup name="kind" label="Kind">
            <Input type="select" value={value} onChange={handleValueChanged}>
              <option value="GAS">Gas</option>
              <option value="WATER">Water</option>
              <option value="ELECTRICITY">Electricity</option>
              <option value="SOLAR">Solar</option>
            </Input>
          </FormInputGroup>
        )}
        {type === MessageType.Notifications && (
          <FormInputGroup name="url" label="URL to open when clicked">
            <Input type="text" value={value} onChange={handleValueChanged} />
          </FormInputGroup>
        )}
        {type === MessageType.AutomationDetail && (
          <FormInputGroup name="automation" label="Automation">
            <Input type="select" value={valueId} onChange={handleValueIdChanged}>
              {automations.map(automation => (
                <option key={automation.id} value={automation.id}>
                  {automation.name}
                </option>
              ))}
            </Input>
          </FormInputGroup>
        )}

        <FormInputGroup name="message" label="Message">
          <Input
            type="textarea"
            name="message"
            rows="6"
            cols="64"
            maxLength={1000}
            value={message}
            onChange={handleMessageChanged}
          />
        </FormInputGroup>

        <FormGroup>
          <Label />
          <RsButton onClick={handleClickedSend} disabled={!isValid}>
            Send in-app message
          </RsButton>
        </FormGroup>
        {loadingMessage && <div>{loadingMessage}</div>}
      </Form>
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.SendPushMessage,
  title: 'sendPushMessage.title',
  description: 'sendPushMessage.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Aware,
  cardClass: SendPushMessage
};
export default CARD;
