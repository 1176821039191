import {useMemo} from 'react';
import {Form} from 'reactstrap';

import {ConnectPricingGroup} from '../connectPricingGroup/ConnectPricingGroup';

import {ConnectPricingGroupProvider} from '../connectPricingGroup/ConnectPricingGroupProvider';

import {usePricingPolicy} from './PricingPolicyProvider';

export function PricingGroupsPolicyConfig() {
  const {organization, formState, updateFormState, specificPricingErrors} = usePricingPolicy();

  const groupsInUse = useMemo(() => {
    return formState.specificPricingRules
      .filter(rule => rule.discountGroup.type === 'CUSTOM')
      .map(rule => rule.discountGroup.id!);
  }, [formState.specificPricingRules]);

  return (
    <Form>
      <ConnectPricingGroupProvider organization={organization} groupsInUse={groupsInUse}>
        <ConnectPricingGroup
          baseTariff={formState}
          currency={organization.currency || 'EUR'}
          rules={formState.specificPricingRules}
          updateRules={specificPricingRules => updateFormState({specificPricingRules})}
          errors={specificPricingErrors}
        />
      </ConnectPricingGroupProvider>
    </Form>
  );
}
