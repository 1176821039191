import Resizer from 'react-image-file-resizer';

import {NotificationManager} from 'react-notifications';

import API from '../../../../../core/api';
import {ChargingDisplayImage, ChargingDisplayImageType} from '../../../../../models/ChargingDisplayImage';

interface resizeProps {
  file: File;
  width: number;
  height: number;
  imageType: 'PNG' | 'JPG';
  quality: number;
  rotation: number;
  api: API;
  serialNumber: string;
  locationId: number | undefined;
  isChargingPark: boolean;
  CSDImageType: ChargingDisplayImageType;
  setImage: (image: ChargingDisplayImage) => void;
  refresh: () => void;
  errorMsg: string;
}

const downSizeAndSendImage = ({
  file,
  width,
  height,
  imageType,
  quality,
  rotation,
  api,
  serialNumber,
  locationId,
  isChargingPark,
  CSDImageType,
  setImage,
  refresh,
  errorMsg
}: resizeProps) => {
  Resizer.imageFileResizer(
    file,
    width,
    height,
    imageType,
    quality,
    rotation,
    async resizedFile => {
      // Handle the resized image here
      if (isChargingPark) {
        try {
          if (locationId === undefined) return;
          const apiResponse = await api.uploadChargingParkImage(locationId, resizedFile as File, CSDImageType);
          if (apiResponse) {
            setImage(apiResponse as unknown as ChargingDisplayImage);
            refresh();
          }
        } catch {
          NotificationManager.error(errorMsg);
        }
      } else {
        try {
          if (serialNumber === undefined) return;
          const apiResponse = await api.uploadChargingStationImage(serialNumber, resizedFile as File, CSDImageType);
          if (apiResponse) {
            setImage(apiResponse as unknown as ChargingDisplayImage);
            refresh();
          }
        } catch {
          NotificationManager.error(errorMsg);
        }
      }
    },
    'file',
    width,
    height
  );
};

export default downSizeAndSendImage;
