import * as React from 'react';

import {IconButton} from '../../components/IconButton';
import {ConfigurePricingPolicy} from '../../components/pricingPolicies/configurePricingPolicy/ConfigurePricingPolicy';
import {useModals} from '../../modals/ModalContext';
import RemoveModal from '../../modals/RemoveModal';
import {IOrganization} from '../../models/Organization';
import {IPricingPolicy} from '../../models/PricingPolicy';

import {T, quantity} from '../../utils/Internationalization';

import styles from './index.module.css';

interface PricingPolicyItemProps {
  onUpdate: (policy: IPricingPolicy) => void;
  onDelete?: (id: number) => void;
  organization: IOrganization;
  policy: IPricingPolicy;
}
export function PricingPolicyItem(props: PricingPolicyItemProps) {
  const {onUpdate, organization, onDelete, policy} = props;
  const {name, assignedToCount} = policy;
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const modals = useModals();

  const handleUpdate = React.useCallback(
    updatedPolicy => {
      onUpdate(updatedPolicy);
      setIsEditing(false);
    },
    [onUpdate]
  );

  const handleDelete = React.useCallback(async () => {
    modals
      .show(props => (
        <RemoveModal
          title={T('pricingPolicies.delete.confirm.title')}
          message={T('pricingPolicies.delete.confirm.message', {name})}
          execute={api => api.pricingPolicies.delete(organization.id, policy.id!).then(() => onDelete?.(policy.id!))}
          successMessage={T('pricingPolicies.delete.success')}
          failureMessage={T('pricingPolicies.delete.failed')}
          {...props}
        />
      ))
      .then(success => success && onDelete?.(policy.id));
  }, [onDelete, modals, name, organization.id, policy.id]);

  return (
    <>
      {isEditing && (
        <ConfigurePricingPolicy
          isOpen={isEditing}
          policy={policy}
          organization={organization}
          onClose={() => setIsEditing(false)}
          onSave={handleUpdate}
        />
      )}
      <div className={styles.pricingPolicyItem}>
        <div className={styles.pricingPolicyItemCore}>
          <h3 className={styles.pricingPolicyName}>{name}</h3>
          {assignedToCount !== undefined &&
            (assignedToCount > 0 ? (
              <span className={styles.assignedTo}>
                {T('pricingPolicies.assignedTo', {
                  count: quantity('chargingStation', assignedToCount)
                })}
              </span>
            ) : (
              <span className={styles.notAssigned}>{T('pricingPolicies.assignedTo.none')}</span>
            ))}
        </div>
        <IconButton
          action="default"
          icon="Pencil"
          color="link"
          onClick={() => setIsEditing(true)}
          className="!tw-p-0 !tw-mx-1"
        />
        <IconButton action="delete" icon="Trash" color="link" onClick={handleDelete} className="!tw-p-0 !tw-mx-1" />
      </div>
    </>
  );
}
