import cn from 'classnames';
import React, {useMemo} from 'react';

import {Button as RsButton, FormText, Label} from '../../components/bootstrap';
import {Icon, Icons} from '../../components/Icon';
import {Clear, Edit, LockLocked} from '../../components/ui-lib/icons/small';
import {useModals} from '../../modals/ModalContext';
import {Device, IDevice, IFirmwareVersion} from '../../models/Device';
import {DeviceType, getDeviceTypeInfo, isGatewayDevice} from '../../models/DeviceType';
import {T} from '../../utils/Internationalization';
import {useUser} from '../CardUtils';

import styles from './index.module.scss';
import {IUpdateFirmwareResult, UpdateFirmwareModal} from './UpdateFirmwareModal';

interface PrimaryDeviceRowProps {
  device: Device;
  upgrading: boolean;
  isServiceDesk: boolean;
  onClickedUpgrade: () => void;
  onClickedModuleType: (type: DeviceType) => void;
  onClickedClearTarget: (type: DeviceType) => void;
}

function PrimaryDeviceRow(props: PrimaryDeviceRowProps) {
  const {device, upgrading, isServiceDesk, onClickedUpgrade, onClickedModuleType, onClickedClearTarget} = props;
  let {type: deviceType, serialNumber} = device;

  const label = deviceType ? Device.getInternalTypeLabelFor(deviceType) : T.generic.na();
  serialNumber = serialNumber || T.generic.na();
  const {current: currentFirmware, target: targetFirmware} = device.firmware;
  const hasScheduledUpgrade = targetFirmware && targetFirmware !== currentFirmware;

  return (
    <>
      <tr className={styles.moduleRow}>
        <td>
          <span className={styles.moduleTitle}>1x {label}</span>
        </td>
        {isServiceDesk && (
          <td>
            <div className={styles.firmware}>
              <RsButton
                color="link"
                withoutPadding
                title={T('firmware.editFirmware')}
                onClick={() => onClickedModuleType(deviceType)}
              >
                {Icons.Edit}
              </RsButton>
              {targetFirmware && (
                <RsButton
                  color="link"
                  withoutPadding
                  title={T('firmware.clearTargetVersion')}
                  style={{marginLeft: 10}}
                  onClick={() => onClickedClearTarget(deviceType)}
                >
                  {Icons.Clear}
                </RsButton>
              )}
              {deviceType === DeviceType.Genius && hasScheduledUpgrade && !upgrading && (
                <RsButton
                  color="link"
                  withoutPadding
                  title={T('firmware.upgradeNow')}
                  style={{marginLeft: 10}}
                  onClick={onClickedUpgrade}
                >
                  {Icons.Upgrade}
                </RsButton>
              )}
              {deviceType === DeviceType.Genius && hasScheduledUpgrade && upgrading && (
                <span style={{paddingLeft: 10}}>{T('firmware.upgradeRequested')}</span>
              )}
            </div>
          </td>
        )}
      </tr>
      <tr key="primary2">
        <td>
          <FormText title={T('firmware.serialNumberX', {serial: serialNumber})}>{serialNumber}</FormText>
        </td>
        <td>
          <FormText
            title={T('firmware.firmwareX', {
              version: currentFirmware || T.generic.na()
            })}
          >
            {currentFirmware} {!hasScheduledUpgrade ? <LockLocked className="tw-inline-flex" /> : ''}
            {hasScheduledUpgrade && targetFirmware && (
              <span style={{paddingLeft: '0.3em', paddingRight: '0.3em'}} className="fal fa-long-arrow-right" />
            )}
            {hasScheduledUpgrade && targetFirmware && <span>{targetFirmware}</span>}
          </FormText>
        </td>
      </tr>
    </>
  );
}

interface DeviceRowProps {
  module: Device;
}

function DeviceRow(props: DeviceRowProps) {
  const {module} = props;
  const deviceType = getDeviceTypeInfo(module.type);
  const isLockedVersion = module.firmware.current === module.firmware.target;

  return (
    <tr key={module.serialNumber} className={styles.moduleRow}>
      <td>
        <FormText title={T('firmware.serialNumberX', {serial: module.serialNumber})}>
          {module.serialNumber}
          {module.isInactive() && <span style={{color: 'red'}}>&nbsp;({T('firmware.moduleOffline')})</span>}
        </FormText>
      </td>
      <td>
        <div className={styles.firmware}>
          {deviceType.hasFirmware && (
            <FormText
              title={T('firmware.firmwareX', {
                version: module.firmware.current
              })}
            >
              {module.firmware.current} {isLockedVersion ? <LockLocked className="tw-inline-flex" /> : ''}
            </FormText>
          )}
          {module.firmware.target && !isLockedVersion && (
            <FormText
              title={T('firmware.targetFirmwareX', {
                version: module.firmware.target
              })}
            >
              <span style={{paddingLeft: '0.3em', paddingRight: '0.3em'}} className="fal fa-long-arrow-right" />
              {module.firmware.target}
            </FormText>
          )}
        </div>
      </td>
    </tr>
  );
}

interface DeviceTypeRowProps {
  type: DeviceType;
  modules: IDevice[];
  isServiceDesk: boolean;
  gatewayUpgrading: boolean;
  onClickedModuleType: (type: DeviceType) => void;
  onClickedClearTarget: (type: DeviceType) => void;
  onClickedUpgradeGenius: () => void;
}

function DeviceTypeRow(props: DeviceTypeRowProps) {
  const {
    type,
    modules,
    isServiceDesk,
    gatewayUpgrading,
    onClickedModuleType,
    onClickedClearTarget,
    onClickedUpgradeGenius
  } = props;
  const deviceType = getDeviceTypeInfo(type);
  const label = Device.getInternalTypeLabelFor(type);
  const count = modules.length;
  const hasTarget = modules.some(module => !!(module.firmware && module.firmware.target));
  const hasScheduledUpgrade = modules.some(module => {
    if (module.firmware === undefined) return false;
    const {current: currentFirmware, target: targetFirmware} = module.firmware;
    return targetFirmware && targetFirmware !== currentFirmware;
  });

  return (
    <tr key={type} className={styles.moduleRow}>
      <td>
        <span className={styles.moduleTitle}>
          {count}x {label}
        </span>
      </td>
      <td>
        <div className={styles.firmware}>
          {isServiceDesk && deviceType.hasFirmware && (
            <RsButton
              color="link"
              withoutPadding
              title={T('firmware.editFirmware')}
              onClick={() => onClickedModuleType(type)}
            >
              <Edit />
            </RsButton>
          )}
          {isServiceDesk && hasTarget && (
            <RsButton
              color="link"
              withoutPadding
              title={T('firmware.clearTargetVersion')}
              style={{marginLeft: 10}}
              onClick={() => onClickedClearTarget(type)}
            >
              <Clear />
            </RsButton>
          )}
          {type === DeviceType.Genius && hasScheduledUpgrade && !gatewayUpgrading && (
            <RsButton
              color="link"
              withoutPadding
              title={T('firmware.upgradeNow')}
              style={{marginLeft: 10}}
              onClick={onClickedUpgradeGenius}
            >
              {Icons.Upgrade}
            </RsButton>
          )}
          {type === DeviceType.Genius && hasScheduledUpgrade && gatewayUpgrading && (
            <span style={{paddingLeft: 10}}>{T('firmware.upgradeRequested')}</span>
          )}{' '}
        </div>
      </td>
    </tr>
  );
}

interface LocationFirmwareProps {
  title?: string;
  isChild: boolean;
  locationId: number;
  devices: IDevice[];
  firmwares: {[key: string]: IFirmwareVersion[]};
  upgrading: boolean;
  onUpgrade: (locationId: number) => void;
  onUpdateFirmware: (locationId: number, type: DeviceType, version: string, restart: boolean) => void;
  onClearTarget: (locationId: number, type: DeviceType) => void;
}

export default function LocationFirmware(props: LocationFirmwareProps) {
  const {title, isChild, locationId, devices, firmwares, upgrading, onUpgrade, onUpdateFirmware, onClearTarget} = props;
  const me = useUser();
  const isServiceDesk = me.isServiceDesk();
  const modals = useModals();

  const modules = useMemo(() => {
    let modules: {[key: string]: Device[]} = {};

    for (let device of devices) {
      let {type} = device;
      const results = modules[type as string] || [];
      results.push(Device.fromJSON(device));
      modules[type] = results;
    }
    return modules;
  }, [devices]);

  const deviceRows = useMemo(() => {
    const handleClickedModuleType = async (type: DeviceType) => {
      if (!type) return;
      if (!locationId) return;

      const actualModules = modules[type];
      const firmwareVersions = firmwares[type] || [];

      const result = await modals.show<IUpdateFirmwareResult | undefined>(props => (
        <UpdateFirmwareModal type={type} modules={actualModules} firmwareVersions={firmwareVersions} {...props} />
      ));
      if (result && result.firmware) {
        onUpdateFirmware(locationId, type, result.firmware, result.restart);
      }
    };

    const rows: JSX.Element[] = [];

    for (let typeString in modules) {
      const type = typeString as DeviceType;
      if (modules.hasOwnProperty(type) && !Device.isGasWater(type)) {
        const modulesForType = modules[type];
        rows.push(
          <DeviceTypeRow
            key={type}
            type={type}
            modules={modulesForType}
            isServiceDesk={isServiceDesk}
            gatewayUpgrading={upgrading}
            onClickedModuleType={handleClickedModuleType}
            onClickedClearTarget={type => onClearTarget(locationId, type)}
            onClickedUpgradeGenius={() => onUpgrade(locationId)}
          />
        );
        for (let module of modulesForType) {
          rows.push(<DeviceRow key={module.serialNumber} module={module} />);
        }
      }
    }

    return rows;
  }, [locationId, modules, firmwares, isServiceDesk, modals, upgrading, onUpgrade, onClearTarget, onUpdateFirmware]);

  return (
    <>
      {title && <h3 style={{marginTop: 10}}>{title}</h3>}
      <table style={isChild ? {marginLeft: 10} : undefined}>
        <tbody>
          {!isChild && (
            <tr>
              <th>
                <Label style={{margin: 0}}>{T('firmware.devicesAndModules')}</Label>
              </th>
              <th>
                <Label style={{margin: 0}}>{T('firmware.firmwareVersions')}</Label>
              </th>
            </tr>
          )}
          {deviceRows}
        </tbody>
      </table>
    </>
  );
}
