import {AppStore} from '..';
import API from '../../core/api';
import {ILocationSummary, locationToSummary} from '../../models/Location';

export enum LocationsActions {
  SET_LOCATIONS_START = 'LOCATIONS.SET_LOCATIONS_START',
  SET_LOCATIONS_SUCCESS = 'LOCATIONS.SET_LOCATIONS_SUCCESS',
  SET_LOCATIONS_ERROR = 'LOCATIONS.SET_LOCATIONS_ERROR',
  FETCH = 'LOCATIONS.FETCH'
}

export interface ISetLocationsStartAction {
  type: LocationsActions.SET_LOCATIONS_START;
}

export interface ISetLocationsSuccessAction {
  type: LocationsActions.SET_LOCATIONS_SUCCESS;
  payload: ILocationSummary[];
}

export interface ISetLocationsErrorAction {
  type: LocationsActions.SET_LOCATIONS_ERROR;
  payload: any;
}

export interface ILocationsFetchAction {
  type: LocationsActions.FETCH;
  payload: ILocationSummary;
}

export type AnyLocationsAction =
  | ISetLocationsStartAction
  | ISetLocationsSuccessAction
  | ISetLocationsErrorAction
  | ILocationsFetchAction;

export function fetchLocations(api: API, store: AppStore) {
  store.dispatch({
    type: LocationsActions.SET_LOCATIONS_START
  });

  api.user
    .getLocations()
    .then(locations => {
      store.dispatch({
        type: LocationsActions.SET_LOCATIONS_SUCCESS,
        payload: locations
      });
    })
    .catch(err => {
      store.dispatch({
        type: LocationsActions.SET_LOCATIONS_ERROR,
        payload: err
      });
    });
}

export function fetchLocation(api: API, store: AppStore, locationId: number) {
  const loaded = store.getState().locations.fetchedLocations.find(location => location.id === locationId);
  if (loaded) {
    store.dispatch({
      type: LocationsActions.FETCH,
      payload: loaded
    });
    return Promise.resolve();
  } else {
    return api.locations.get(locationId).then(location =>
      store.dispatch({
        type: LocationsActions.FETCH,
        payload: locationToSummary(location)
      })
    );
  }
}
