import {T} from '../../utils/Internationalization';
import {IResolvedRoute} from '../Route';

import {ErrorPage} from './ErrorPage';

export default function action(): IResolvedRoute {
  return {
    title: T('error.title'),
    description: T('error.description'),
    component: <ErrorPage />
  };
}
