import React from 'react';

import {Input} from '../../../components/bootstrap';

import {Checkbox} from '../../../components/ui/checkbox';
import {IApplianceType} from '../../../models/ApplianceType';
import {CIRCUIT_TYPES, getCircuitTypeLabel} from '../../../models/Channel';
import {Load, LoadType, ApplianceLoad, CircuitLoad, ProductionLoad} from '../../../models/Load';
import {T} from '../../../utils/Internationalization';

interface HighLevelOptionsSelectorProps {
  readOnly: boolean;
  load: Load;
  applianceTypes: IApplianceType[];
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}
export const HighLevelOptionsSelector = (props: HighLevelOptionsSelectorProps) => {
  const {readOnly, load, applianceTypes, onChange} = props;
  const options = [];
  let name = 'unknown';
  let value = null;

  if (load.type === LoadType.Production) {
    name = 'withHybridInverter';
    value = (load as ProductionLoad).production.withHybridInverter;

    return (
      <Checkbox
        id={name}
        label={T('loadType.production.hybridInverter')}
        name={name}
        checked={value || false}
        onCheckedChange={checked => onChange({currentTarget: {name, checked}} as any)}
        disabled={readOnly}
        testId={name}
      />
    );
  }

  if (load.type === LoadType.Appliance) {
    name = 'applianceType';
    value = (load as ApplianceLoad).appliance.type;

    for (let {id, translation} of applianceTypes) {
      options.push(
        <option key={id} value={id}>
          {translation}
        </option>
      );
    }
  } else if (load.type === LoadType.Subcircuit) {
    name = 'circuitType';
    value = (load as CircuitLoad).subcircuit.type;

    for (let circuitType of CIRCUIT_TYPES) {
      options.push(
        <option key={circuitType} value={circuitType}>
          {getCircuitTypeLabel(circuitType)}
        </option>
      );
    }
  }

  return (
    <Input
      type="select"
      name={name}
      value={value || ''}
      onChange={onChange}
      placeholder={T('generic.noOptions')}
      disabled={value === null || readOnly}
    >
      {options}
    </Input>
  );
};
