import classnames from 'classnames';

import React from 'react';

import {Flex} from '../../components/Flex';

import {useModals} from '../../modals/ModalContext';
import {ICardSettings} from '../../models/CardSettings';
import {useAppSelector} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType} from '../CardType';

import {CardSettings} from '.';
import styles from './Card.module.scss';
import CardLocationNameView from './CardLocationNameView';
import CardSettingsHandle from './CardSettingsHandle';
import {CustomSettings} from './CardView';

interface CardHeaderProps<S extends ICardSettings> {
  cardType: ICardType<S>;
  settings: S;
  customSettings?: CustomSettings<S>;
  actions?: JSX.Element;
  title: string;
  titleAddendum?: JSX.Element;
}

export default function CardHeader<S extends ICardSettings>(props: CardHeaderProps<S>) {
  const {cardType, settings, customSettings, actions, title, titleAddendum} = props;
  const modals = useModals();

  const handleClickedSettings = () => {
    modals.show<boolean>(props => (
      <CardSettings settings={settings} title={title || T(cardType.title)} customSettings={customSettings} {...props} />
    ));
  };

  const presenting = useAppSelector(state => state.uiState.presenting);

  return (
    // <Flex className={styles.header}>
    <div
      className={classnames(styles.header, 'card-actions', testingClasses.cardActions)}
      data-testid={testingClasses.cardActions}
    >
      <h3 className={styles.name} title={`${title}`}>
        <span className={testingClasses.cardTitle} data-testid={testingClasses.cardTitle}>
          {title}
        </span>
        {titleAddendum}
      </h3>
      {/* <div className={styles.headerActions}>{actions || null}</div> */}
      {actions || null}
      {presenting && <div className={styles.settingsFiller} />}

      {settings.locationId !== undefined && <CardLocationNameView settings={settings} />}

      {!presenting && (
        <span className="tw-absolute tw-top-6 tw-right-6">
          <CardSettingsHandle className={styles.settings} onClick={handleClickedSettings} />
        </span>
      )}
      {/* </Flex> */}
    </div>
  );
}
