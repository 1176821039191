import {AuthUser} from '../../models/AuthUser';

export enum MeActions {
  SET_ME_START = 'ME.START',
  SET_ME_SUCCESS = 'ME.LOAD_SUCCESS',
  SET_ME_ERROR = 'ME.LOAD_ERROR'
}

export interface ISetMeStartAction {
  type: MeActions.SET_ME_START;
}

export interface ISetMeSuccessAction {
  type: MeActions.SET_ME_SUCCESS;
  payload: AuthUser;
}

export interface ISetMeErrorAction {
  type: MeActions.SET_ME_ERROR;
  payload: any;
}

export function setMe(me: AuthUser): AnyMeAction {
  return {
    type: MeActions.SET_ME_SUCCESS,
    payload: me
  };
}

export function logout(): AnyMeAction {
  return setMe(AuthUser.anonymous());
}

export type AnyMeAction = ISetMeStartAction | ISetMeSuccessAction | ISetMeErrorAction;
