import {T} from '../utils/Internationalization';

import {ILoadUpdateChannel} from './Load';

export const enum SwitchStatus {
  Disconnected = 'DISCONNECTED',
  Connected = 'CONNECTED',
  Unreachable = 'UNREACHABLE'
}

export interface ISwitch {
  id: number;
  name: string;
  code: string; // serial number
  functionMask: number;
  type: PlugType;
  lastAction?: number;
  status?: SwitchStatus;
  monitorId?: number;
  stateUpdateChannel?: ILoadUpdateChannel;
  serialNumber?: string;
  triggerIds: any[]; // ?
  acceleroEnabled?: boolean;
  category?: 'OTHER';
  learn: {
    status: 'ON' | 'OFF';
  };
  favourite: boolean;
}

export const enum PlugType {
  Undefined = '?',
  Chacon = 'CHACON',
  Elro = 'ELRO',
  Advanced = 'ADVANCED',
  Switch = 'SWITCH',
  OutputModule = 'OUTPUT_MODULE'
}

export interface ISwitchReading {
  time: number;
  activePower: number | null;
  reactivePower: number | null;
}

export function getSwitchStatusLabel(status: SwitchStatus) {
  switch (status) {
    case SwitchStatus.Connected:
      return T('switchStatus.connected');
    case SwitchStatus.Disconnected:
      return T('switchStatus.disconnected');
    case SwitchStatus.Unreachable:
      return T('switchStatus.unreachable');
    default:
      return '?';
  }
}
