import {APIClient} from './APIClient';

export class LoadManagementAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getLoadManagementSettings(locationId: number, smartDeviceId: string): Promise<LoadManagementSettings> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices/${smartDeviceId}/loadmanagement`;
    return this.client.doGet(url);
  }

  updateLoadManagementSettings(locationId: number, smartDeviceId: string, updates: Partial<LoadManagementSettings>) {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices/${smartDeviceId}/loadmanagement`;
    return this.client.doPatch(url, updates);
  }

  createLoadManagementSettings(locationId: number, smartDeviceId: string, settings: LoadManagementSettings) {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices/${smartDeviceId}/loadmanagement`;
    return this.client.doPost(url, settings);
  }
}

export const enum OptimizationStrategy {
  None = 'NONE',
  ExcessOnly = 'EXCESS_ONLY',
  SchedulesThenExcess = 'SCHEDULES_FIRST_THEN_EXCESS',
  SchedulesOnly = 'SCHEDULES_ONLY'
}

export interface LoadManagementSettings {
  active?: boolean;
  optimizationStrategy: OptimizationStrategy;
  minimumProductionPercentage?: number;
  schedules?: LoadManagementSchedule[];
}

export const enum DayType {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export interface LoadManagementSchedule {
  id: number;
  active?: boolean;
  dayTypes?: DayType[];
  hours?: number;
  minutes?: number;
  operatingWindow?: {
    from?: {hours: number; minutes: number};
    to?: {hours: number; minutes: number};
  };
  minimumDrivingRange?: number;
}
