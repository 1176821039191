import React, {useMemo} from 'react';

import PeriodSelector, {
  Period,
  PeriodSettings,
  getPeriodRangeForTimezone,
  PeriodRoundingMode,
  ActivePeriod
} from '../../components/PeriodSelector';

import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';

import {IConsumptionValue} from '../../models/ConsumptionValue';
import {Interval} from '../../models/UsageValue';

import {useCardLoader} from '../../utils/Hooks';
import {CardTypeKey, ICardType, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {CardView, CustomActions} from '../components/CardView';

import {generateData, IGeneratedDataEntry} from './GeneratedData';
import SolarOptimizationGraph from './SolarOptimizationGraph';

interface ThisSettings extends ICardSettings {
  period: PeriodSettings;
}

interface LoadedData {
  period: ActivePeriod;
  consumption: IConsumptionValue[];
  generated: IGeneratedDataEntry[];
}

const ThisCardView = (props: ICardProps<ThisSettings>) => {
  const {settings, updateSettings} = props;
  const {period} = settings;
  const {interval} = period;

  const location = useCardLocation(settings);
  const locationTimezone = location && location.timeZoneId;

  const actions: CustomActions = state => (
    <CardActions>
      {state.ready && <ExportCsv fields={[]} items={[]} settings={{columns: [], pageSize: 10}} />}
      <Reload onReload={() => {}} />
      <PeriodSelector settings={period} onChanged={period => updateSettings({period})} />
    </CardActions>
  );

  const title = 'Charging consumption of Thuis';

  const [data] = useCardLoader<LoadedData | undefined>(
    api => {
      if (locationTimezone === undefined) return Promise.resolve(undefined);

      const actualPeriod = getPeriodRangeForTimezone(
        period,
        locationTimezone,
        undefined,
        PeriodRoundingMode.ROUND_DOWN_GRAPH
      );
      return Promise.all([
        api.getElectricityConsumptionFrom(
          '/farm1api',
          /*41809*/ 57563,
          actualPeriod.from,
          actualPeriod.to,
          actualPeriod.interval
        ),
        api.getElectricityConsumptionFrom('/farm1api', 57608, actualPeriod.from, actualPeriod.to, interval)
      ]).then(([consumption, charging]) => {
        consumption.forEach(entry => {
          if (entry.value === undefined) return;

          const chargingEntry = charging.find(x => x.timestamp === entry.timestamp);
          if (chargingEntry) {
            const chargeConsumption = (chargingEntry.activePower || []).reduce<number>(
              (prev, x) => (x === null ? prev : prev + x),
              0
            );
            entry.value -= chargeConsumption;
          } else {
            console.log('Missing charging entry');
          }
        });
        return {
          period: actualPeriod,
          consumption,
          generated: generateData(actualPeriod.from, actualPeriod.to, consumption, 1)
        };
      });
    },
    [period, locationTimezone],
    'Consumption data',
    undefined
  );
  const graph = useMemo(
    () =>
      location &&
      data && (
        <SolarOptimizationGraph
          location={location}
          period={data.period}
          consumption={data.consumption}
          generatedData={data.generated}
          sides={['Car charger']}
          /*sides={[
        'Snowball01-1',
        'Snowball01-2',
        'Snowball02-1',
        'Snowball02-2',
        'Snowball03-1',
        'Snowball03-2',
        'Snowball04-1',
        'Snowball04-2',
        'Snowball05-1',
        'Snowball05-2'
      ]}*/
        />
      ),
    [location, data]
  );

  return (
    <CardView title={title} actions={actions} {...props}>
      {graph}
    </CardView>
  );
};

const DEFAULT_CARD_SETTINGS: ThisSettings = {
  period: {
    interval: Interval.MINUTES_5,
    period: Period.DAYS_7
  }
};

const CARD: ICardType<ThisSettings> = {
  type: CardTypeKey.ChargingSolarOptimization,
  title: 'chargingSolarOptimization.title',
  description: 'chargingSolarOptimization.description',
  categories: [CardCategory.ELECTRICITY, CardCategory.EV],
  rights: UserRights.ServiceDesk,
  width: 3,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.Required,
  cardClass: ThisCardView
};
export default CARD;
