interface TickProps {
  tick: {
    id: string;
    value: number;
    percent: number;
  };
  format?: (value: number) => string;
}

export const Tick: React.FC<TickProps> = ({tick, format = d => `${d}`}) => (
  <div>
    <div
      style={{
        position: 'absolute',
        marginTop: -0.5,
        marginLeft: 96, // 10
        height: 1,
        width: 6,
        backgroundColor: 'rgb(200,200,200)',
        top: `${tick.percent}%`
      }}
    />
    <div
      style={{
        position: 'absolute',
        marginTop: -10, // -5
        marginLeft: 106, // 20
        fontSize: 13,
        lineHeight: '20px',
        top: `${tick.percent}%`
      }}
    >
      {format(tick.value)}
    </div>
  </div>
);
