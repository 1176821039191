//import { getAppParameters } from '../app/appParameters';
import {RouteParams, RouteContext} from 'universal-router';

import {APP_TITLE} from '../core/constants';

import {UserRights, AuthUser} from '../models/AuthUser';

import {logout} from '../redux/actions/me';
import {setUser} from '../utils/LocalUser';

import {Path} from './Path';
//import { UserType } from '../models/User';
import {IRoute, IContext, IResolvedRoute} from './Route';

type DashboardRouteContext = IContext & RouteContext<IContext, IResolvedRoute>;

const routes: IRoute = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      name: 'home',
      path: '',
      action: () => ({title: '', description: '', redirect: Path.LOGIN})
    },
    {
      name: 'email-verification',
      path: '/api/v10/user/email/verify/:token',
      load: () => import('./verify-email')
    },
    {
      name: 'login',
      path: '/login',
      ensureLoggedOut: '/board',
      load: () => import('./login')
    },
    {
      name: 'registration',
      path: '/registration/:token',
      load: () => import('./registration')
    },
    {
      name: 'evcharging',
      path: '/ev',
      load: () => import('./evcharging')
    },
    {
      name: 'board',
      path: '/board/:name',
      ensureRights: UserRights.User,
      load: () => import('./boards')
    },
    {
      name: 'boards',
      path: '/board',
      ensureRights: UserRights.User,
      load: () => import('./boards')
    },
    {
      name: 'logout',
      path: '/logout',
      action: (context: IContext) => {
        setUser(AuthUser.anonymous().serialize());
        context.store.dispatch(logout());
        return {title: '', description: '', redirect: Path.LOGIN};
      }
    },
    {
      name: 'account-recovery',
      path: '/account-recovery',
      load: () => import('./account-recovery')
    },
    {
      name: 'username-recovery',
      path: '/username-recovery',
      load: () => import('./username-recovery')
    },
    {
      name: 'password-reset',
      path: '/reset-password',
      load: () => import('./password-reset')
    },
    {
      name: 'resetpassword',
      path: '/resetpassword/:code',
      load: () => import('./resetpassword')
    },
    {
      name: 'verification-wizard',
      path: '/verify-email',
      load: () => import('./verification-wizard')
    },
    {
      name: 'confirm-split-billing',
      path: '/confirm-split-billing',
      load: () => import('./confirm-split-billing')
    },
    {
      name: 'confirm-organization-user',
      path: '/confirm-organization-user',
      load: () => import('./confirm-organization-user')
    },
    {
      name: 'register-organization',
      path: '/register-organization',
      load: () => import('./register-organization')
    },
    {
      name: 'register-split-billing',
      path: '/register-split-billing',
      load: () => import('./register-organization')
    },
    {
      name: 'register-emsp-business',
      path: '/register-emsp-business',
      load: () => import('./register-emsp-business')
    },
    {
      name: 'register-charging-host',
      path: '/register-charging-host',
      load: () => import('./register-charging-host')
    },
    {
      name: 'register-emsp-private',
      path: '/register-emsp-private',
      load: () => import('./register-emsp-private')
    },
    {
      name: 'register-emsp-private',
      path: '/register-emsp-private/:status',
      load: () => import('./register-emsp-private')
    },
    {
      name: 'oauthoob',
      path: '/oauthoob',
      load: () => import('./oauthoob')
    },
    {
      name: 'oauthlogin',
      path: '/oauthlogin',
      load: () => import('./oauthlogin')
    },
    {
      name: 'invite',
      path: '/invite/:token',
      load: () => import('./invite')
    },
    {
      name: 'emailverification',
      path: '/emailverification/:token',
      load: () => import('./emailverification')
    },
    {
      name: 'changeemail',
      path: '/changeemail/:token',
      load: () => import('./change-email')
    },
    /* ... */

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      name: 'not-found',
      path: '(.*)',
      load: () => import('./not-found')
    }
  ] as IRoute[],

  action: async function (context: DashboardRouteContext, params: RouteParams) {
    // Execute each child route until one of them return the result
    const route = (await context.next()) as unknown as IResolvedRoute;

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled page'} - ${APP_TITLE}`;
    route.description = route.description || '';
    return route;
  } as any /* fuck this.. */
};

// The error page is available by permanent url for development mode
if (/*getAppParameters().development && */ routes.children) {
  routes.children.unshift({
    name: 'error',
    path: '/error',
    action: require('./error').default
  });
}

export default routes;
