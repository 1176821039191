import React from 'react';

import {Badge as UiBadge} from '../../components/ui/badge';

import {ITableField, IFieldOptions, DEFAULT_OPTIONS} from '../../models/Table';
import {T} from '../../utils/Internationalization';

import {IChargingStationWithLiveData} from './Columns';

export class ChargingStationNameField implements ITableField<IChargingStationWithLiveData> {
  name: string;
  label: string;
  options: IFieldOptions;

  currentLocationId: number | undefined;

  constructor(name: string, label: string, currentLocationId: number | undefined, options?: Partial<IFieldOptions>) {
    this.name = name;
    this.label = label;
    this.options = {...DEFAULT_OPTIONS, ...options};
    this.currentLocationId = currentLocationId;
  }

  renderCellContent(item: IChargingStationWithLiveData) {
    let isSelected = item && item.station.serviceLocationId === this.currentLocationId;
    const value = item.station.data.name;
    if (value === undefined) return undefined;

    /* @todo: adapt the legacy size="lg" and margingLeft: '0.5em' style from Bootstrap for the UiBadge */

    return (
      <>
        {value}
        {isSelected && (
          <UiBadge variant="success" className="tw-ml-2">
            {T('location.selected')}
          </UiBadge>
        )}
      </>
    );
  }

  getExportCSVValue(item: IChargingStationWithLiveData): string {
    return this.getStringValue(item);
  }

  getExportExcelValue(item: IChargingStationWithLiveData): string | number | Date {
    return this.getStringValue(item);
  }

  sort = (a: IChargingStationWithLiveData, b: IChargingStationWithLiveData): number => {
    return this.getStringValue(a).localeCompare(this.getStringValue(b));
  };

  getStringValue(item: IChargingStationWithLiveData) {
    return item.station.data.name;
  }
}
