import React, {ReactNode} from 'react';
import {v1 as uuidv1} from 'uuid';

interface CloseableProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  id?: string;
}

export class Closeable extends React.PureComponent<CloseableProps, {}> {
  dragStartOnMenu: boolean = false;
  id: string = uuidv1();

  constructor(props: CloseableProps) {
    super(props);

    if (props.id) this.id = props.id;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleWindowMouseDown);
    document.addEventListener('mouseup', this.handleWindowMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleWindowMouseDown);
    document.removeEventListener('mouseup', this.handleWindowMouseUp);
  }

  // drags were being handled as clicks, which caused very annoying issues in chrome
  handleWindowMouseDown = (event: MouseEvent) => {
    this.dragStartOnMenu = this.isMyself(event.target as HTMLElement);
  };

  handleWindowMouseUp = (event: MouseEvent) => {
    if (this.dragStartOnMenu || !this.props.isOpen) return;

    this.props.onClose();
  };

  isMyself(element: HTMLElement): boolean {
    if (element.id === this.id) return true;
    if (element.parentElement === null) return false;

    return this.isMyself(element.parentElement);
  }

  render() {
    const {className} = this.props;

    return (
      <div id={this.id} className={className}>
        {this.props.children}
      </div>
    );
  }
}
