import {RowActionButton} from '../../components/bootstrap/RowActions';
import {Icon} from '../../components/Icon';
import RowActions from '../../components/Table/RowActions';
import {Contract, ContractDetails, translateContract, translateContractVersion} from '../../models/Contract';
import {
  CalculatedStringField,
  ComponentField,
  DateCalculatedField,
  IntegerField,
  ITableField,
  StringField,
  TimestampField
} from '../../models/Table';
import {T} from '../../utils/Internationalization';

interface Listeners {
  onClickedNotes: (item: ContractDetails) => void;
  onClickedDownload: (item: ContractDetails) => void;
  onClickedEdit: (item: ContractDetails) => void;
  onClickedMove: (item: ContractDetails) => void;
  onClickedDelete: (item: ContractDetails) => void;
}

export function getTableColumns(listeners: Listeners): ITableField<ContractDetails>[] {
  return [
    new IntegerField('id', 'ID'),
    new CalculatedStringField('contract', T('contracts.field.contract'), item => translateContract(item.contract)),
    new DateCalculatedField('signedAt', T('contracts.field.signedAt'), '', item => item.signedAt),
    new CalculatedStringField(
      'signedByUsername',
      T('contracts.field.signedByUsername'),
      item => item.signedBy.userName
    ),
    new CalculatedStringField('signedByEmail', T('contracts.field.signedByEmail'), item => item.signedBy.emailAddress),
    new CalculatedStringField('organization', T('contracts.field.organization'), item => item.organization?.name),
    new CalculatedStringField('version', T('contracts.field.version'), item => {
      return translateContractVersion(item.contract, item.version);
    }),
    new ComponentField(
      'notes',
      T('contracts.field.notes'),
      item =>
        item.notes && (
          <i
            className={Icon.Comment}
            title={item.notes}
            style={{cursor: 'pointer'}}
            onClick={() => listeners.onClickedNotes(item)}
          />
        ),
      {alwaysVisible: false}
    ),
    new StringField('billedUntil', T('contracts.field.billedUntil')),
    new TimestampField('closed', T('contracts.field.closed')),
    new ComponentField('actions', T('generic.actions'), item => (
      <RowActions>
        <RowActionButton
          icon="Download"
          title={T('contracts.download')}
          onClick={() => listeners.onClickedDownload(item)}
          invisible={item.contract !== Contract.SEPA}
        />
        <RowActionButton icon="Edit" title={T('contracts.edit.title')} onClick={() => listeners.onClickedEdit(item)} />
        <RowActionButton
          icon="MoveInto"
          title={T('contracts.move.title')}
          onClick={() => listeners.onClickedMove(item)}
          invisible={item.contract !== Contract.eMSPPersonal && item.contract !== Contract.eMSPBusiness}
        />
        <RowActionButton
          action="delete"
          title={T('contracts.delete.title')}
          onClick={() => listeners.onClickedDelete(item)}
        />
      </RowActions>
    ))
  ];
}
