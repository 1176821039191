import {ContractDetails} from '../models/Contract';

import {APIClient, Method} from './APIClient';

export class ContractsAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  search(query: string): Promise<ContractDetails[]> {
    const url = `/api/v10/contracts/search?q=${encodeURIComponent(query)}`;
    return this.client.doGet(url);
  }

  getForOrganization(id: number): Promise<ContractDetails[]> {
    const url = `/api/v10/organizations/${id}/contracts`;
    return this.client.doGet(url);
  }

  update(contractId: number, updates: {}): Promise<unknown> {
    const url = `/api/v10/contracts/${contractId}`;
    return this.client.doPatch(url, updates);
  }

  move(contractId: number, move: MoveRequest): Promise<unknown> {
    const url = `/api/v10/contracts/${contractId}/move`;
    return this.client.doPost(url, move);
  }

  canDelete(contractId: number): Promise<CanDeleteResponse> {
    const url = `/api/v10/contracts/${contractId}/deletable`;
    return this.client.doGet(url);
  }

  close(contractId: number): Promise<unknown> {
    const url = `/api/v10/contracts/${contractId}/close`;
    return this.client.doPost(url, {});
  }

  delete(contractId: number): Promise<unknown> {
    const url = `/api/v10/contracts/${contractId}`;
    return this.client.doDelete(url);
  }

  reopen(contractId: number): Promise<unknown> {
    const url = `/api/v10/contracts/${contractId}/reopen`;
    return this.client.doPost(url, {});
  }

  download(contractId: number): Promise<ArrayBuffer | undefined> {
    const url = `/api/v10/contracts/${contractId}/pdf`;
    return this.client
      .doCall(Method.GET, url, undefined, undefined, undefined, false, undefined, 'application/pdf', true)
      .then(result => {
        if (result.statusCode >= 400 && result.statusCode <= 599) {
          throw result;
        } else {
          return result.data;
        }
      });
  }
}

export const enum MoveMode {
  Choose = '',
  ContractOnly = 'contractOnly',
  ContractAndPartner = 'contractAndPartner',
  NewOrganization = 'newOrganization'
}

export interface MoveRequest {
  mode: MoveMode;
  companyName?: string;
  companyVat?: string;
  organizationId?: number;
}

export interface UpdateRequest {
  version?: number;
  notes?: string;
}

export const enum CannotDeleteCode {
  SepaRequiredByOtherContracts = 'sepa.required',
  EMSPHasCards = 'emsp.has.cards',
  EMSPHasBillableSessions = 'emsp.has.billableSessions',
  SplitBillingHasAgreements = 'splitBilling.has.agreements',
  SplitBillingHasUnbilled = 'splitBilling.has.unbilled',
  PaymentPackageHasStations = 'paymentPackage.has.stations',
  PaymentPackageHasUnbilled = 'paymentPackage.has.unbilled'
}

export interface CanDeleteResponse {
  cannotDeleteCause?: CannotDeleteCode;
  cannotCloseCause?: CannotDeleteCode;
}
