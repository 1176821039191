import React from 'react';
import {Button as RsButton, ButtonProps} from 'reactstrap';

import {classes, dashboardClasses} from '../../utils/Styles';

import styles from './Button.module.scss';

interface IButtonProps extends ButtonProps {
  withoutPadding?: boolean;
}

export const buttonColors: {[key: string]: string} = {
  primary: dashboardClasses.primaryButton,
  secondary: dashboardClasses.secondaryButton,
  light: dashboardClasses.lightButton,
  link: dashboardClasses.linkButton,
  edit: dashboardClasses.editButton
};

export const Button = React.memo((props: IButtonProps) => {
  const {color = 'secondary', size, withoutPadding, disabled, className, children, ...otherProps} = props;

  // Hook extra classes into the component
  const fullClassName = classes(
    styles.button,
    buttonColors[color],
    size,
    withoutPadding ? dashboardClasses.withoutPadding : '',
    disabled ? dashboardClasses.disabled : '',
    className
  );

  return (
    <RsButton color={color} size={size} disabled={disabled} className={fullClassName} {...otherProps}>
      {children}
    </RsButton>
  );
});
