import {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react';

import {isAPIResponse} from '../../api/APIClient';
import {useAppContext} from '../../app/context';
import {useUser} from '../../cards/CardUtils';
import {ICardWithOrganization} from '../../models/CardSettings';
import {IOrganization} from '../../models/Organization';
import {None} from '../../utils/Arrays';
import {Fetcher} from '../../utils/Fetcher';
import {useDebounce} from '../../utils/Hooks';
import {singular, T} from '../../utils/Internationalization';
import {FormFeedback} from '../bootstrap';
import {ISelectOption, SearchableSelectInput} from '../SearchableSelectInput';

import FormInputGroup from './FormInputGroup';

export interface OrganizationInputQueryState {
  focused: boolean;
  query: string;
}

export class OrganizationInputData {
  loading: boolean;
  organizations: IOrganization[];
  defaultOrganization: IOrganization | undefined;
  isServiceDesk: boolean;

  constructor(organizations: IOrganization[], loading: boolean, isServiceDesk?: boolean) {
    this.organizations = organizations;
    this.loading = loading;
    this.defaultOrganization = isServiceDesk || organizations.length === 0 ? undefined : organizations[0];
    this.isServiceDesk = isServiceDesk || false;
  }

  get showInput(): boolean {
    return this.isServiceDesk || this.organizations.length > 1;
  }

  getActualOrganizationId(organizationId?: number) {
    if (!this.isServiceDesk && !this.organizations.some(org => org.id === organizationId)) {
      return this.defaultOrganization?.id;
    } else {
      return organizationId || this.defaultOrganization?.id;
    }
  }
}

export function useQueryableOrganizations(): [
  OrganizationInputData,
  (state: Partial<OrganizationInputQueryState>) => void
] {
  const [state, setState] = useState<OrganizationInputQueryState>({focused: false, query: ''});
  const {focused, query} = state;
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [loading, setLoading] = useState(false);
  const {api} = useAppContext();
  const user = useUser();
  const isServiceDesk = user.isServiceDesk();
  const userId = user.userId;

  const debouncedSearchTerm = useDebounce(query, 500);

  const refresh = useCallback(
    (refresh?: boolean) => {
      let promise: Promise<IOrganization[]>;
      if (isServiceDesk) {
        if (focused) {
          setLoading(true);
          promise = api.organizations.getOrgsBySearchTerm(debouncedSearchTerm, 1, 50);
        } else {
          setOrganizations(None);
          return;
        }
      } else {
        setLoading(true);
        promise = api.getUserOrganizations(userId, refresh);
      }

      promise.finally(() => setLoading(false)).then(setOrganizations);
    },
    [api, isServiceDesk, userId, focused, debouncedSearchTerm]
  );
  useEffect(refresh, [refresh]);
  const data = new OrganizationInputData(organizations, loading, user.isServiceDesk());

  const updateState = useCallback(
    (updates: Partial<OrganizationInputQueryState>) => setState(state => ({...state, ...updates})),
    [setState]
  );

  return [data, updateState];
}

export function useCardOrganizations(
  fetch: Fetcher,
  settings: ICardWithOrganization
): [OrganizationInputData, (state: Partial<OrganizationInputQueryState>) => void, IOrganization | undefined] {
  const [data, updateState] = useQueryableOrganizations();
  const organizationId = data.getActualOrganizationId(settings.organization);

  const [organization, setOrganization] = useState<IOrganization | undefined>(data.defaultOrganization);
  useEffect(() => {
    if (organizationId === undefined) {
      setOrganization(undefined);
      return;
    } else if (organizationId === data.defaultOrganization?.id) {
      setOrganization(data.defaultOrganization);
      return;
    }

    fetch(
      'organization',
      api =>
        api.organizations.getById(organizationId).catch(error => {
          if (isAPIResponse(error) && error.statusCode === 404) {
            return undefined;
          } else {
            throw error;
          }
        }),
      singular('organization')
    ).then(setOrganization);
  }, [fetch, data.defaultOrganization, organizationId]);

  return [data, updateState, organization];
}

interface OrganizationInputProps {
  organizations: IOrganization[];
  value: IOrganization | undefined;
  onChange: (value: IOrganization | undefined) => void;
  onUpdateQuery: (updates: Partial<OrganizationInputQueryState>) => void;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
  name?: string;
  noneLabel?: string;
  clearable?: boolean;
}

export function OrganizationInput(props: OrganizationInputProps) {
  const {
    organizations,
    value,
    onChange,
    onUpdateQuery,
    placeholder,
    disabled,
    style,
    name,
    noneLabel,
    clearable = false
  } = props;

  const options: ISelectOption[] = useMemo(() => {
    if (organizations.length === 0 && value) {
      const name = value.parentId ? `${value.parentName} / ${value.name}` : value.name;
      return [{value: value.id.toString(), label: name}];
    }
    const result = organizations.map(org => {
      const name = org.parentId ? `${org.parentName} / ${org.name}` : org.name;
      return {value: org.id.toString(), label: name};
    });
    result?.sort((a, b) => a.label.localeCompare(b.label));
    return result;
  }, [organizations, value]);

  const handleOrganizationChanged = useCallback(
    (value: string) => {
      if (value === '') {
        onChange(undefined);
      } else {
        const organizationId = parseInt(value);
        const organization = organizations?.find(organization => organization.id === organizationId);
        if (organization) onChange(organization);
      }
    },
    [organizations, onChange]
  );

  const handleInputChanged = (filter: string) => {
    onUpdateQuery({query: filter});
  };

  const handleFocus = () => {
    onUpdateQuery({focused: true});
  };

  return (
    <SearchableSelectInput
      name={name}
      value={value === undefined ? '' : value.id.toString()}
      onChange={handleOrganizationChanged}
      onInputChange={handleInputChanged}
      onFocus={handleFocus}
      options={options}
      placeholder={placeholder || T('organizationInput.placeholder')}
      disabled={disabled}
      style={style}
      noneLabel={noneLabel}
      clearable={clearable}
    />
  );
}

interface CardOrganizationInputProps {
  inputOrganizations: OrganizationInputData;
  organization: IOrganization | undefined;
  updateSettings: (settings: Partial<ICardWithOrganization>) => void;
  updateOrganizationInputQuery: (updates: Partial<OrganizationInputQueryState>) => void;
  style?: CSSProperties;
}

export function CardOrganizationInput(props: CardOrganizationInputProps) {
  const {inputOrganizations, organization, updateSettings, updateOrganizationInputQuery, style} = props;

  const handleChangeOrganization = useCallback(
    (org: IOrganization | undefined) => {
      updateSettings({organization: org ? org.id : undefined});
    },
    [updateSettings]
  );

  if (!inputOrganizations.showInput) {
    return null;
  }

  return (
    <OrganizationInput
      organizations={inputOrganizations.organizations}
      value={organization}
      onChange={handleChangeOrganization}
      onUpdateQuery={updateOrganizationInputQuery}
      placeholder=""
      name="organization"
      style={style}
    />
  );
}

interface OrganizationInputGroupProps {
  label: string;
  name: string;
  value: IOrganization | undefined;
  organizations: IOrganization[];
  onChange: (organization: IOrganization | undefined) => void;
  onUpdateQuery: (updates: Partial<OrganizationInputQueryState>) => void;
  inputRef?: (element: HTMLInputElement | null) => void;
  disabled?: boolean;
  info?: string;
  placeholder?: string;
  error?: string;
  noneLabel?: string;
}

export const OrganizationInputGroup = (props: OrganizationInputGroupProps) => {
  const {name, label, error, info, noneLabel, ...inputProps} = props;

  return (
    <FormInputGroup name={name} label={label} error={error} info={info}>
      <OrganizationInput name={name} {...inputProps} />
      <FormFeedback>{noneLabel || T('activationCodes.noOrganizations')}</FormFeedback>
    </FormInputGroup>
  );
};
