import React from 'react';

import {RowActionButton} from '../../components/bootstrap/RowActions';
import {IOrganization} from '../../models/Organization';
import {
  ITableField,
  StringField,
  CalculatedStringField,
  URLField,
  ComponentField,
  IntegerField
} from '../../models/Table';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';

interface Listeners {
  onClickedEdit: (organization: IOrganization) => void;
  onClickedCreateAPIUser: (organization: IOrganization) => void;
  onClickedManageFields: (organization: IOrganization) => void;
  onClickedRemove: (organization: IOrganization) => void;
}
function renderActionColumn(organization: IOrganization, serviceDesk: boolean, listeners: Listeners) {
  const {onClickedEdit, onClickedCreateAPIUser, onClickedManageFields, onClickedRemove} = listeners;

  // TODO: what if this is a suborganization partner admin?
  // (they actually shouldn't even have this card)
  const canEdit = true; /// serviceDesk || organization.parentId !== undefined;

  return (
    <>
      {canEdit && (
        <RowActionButton icon="Pencil" title="Edit organization" onClick={() => onClickedEdit(organization)} />
      )}
      {serviceDesk && (
        <RowActionButton
          title={T('apiUser.create')}
          icon="ChartNetwork"
          onClick={() => onClickedCreateAPIUser(organization)}
        />
      )}
      {serviceDesk && (
        <RowActionButton icon="List" title="Manage custom fields" onClick={() => onClickedManageFields(organization)} />
      )}
      {canEdit && (
        <RowActionButton
          action="delete"
          title="Remove organization"
          className={testingClasses.remove}
          onClick={() => onClickedRemove(organization)}
          data-testid={testingClasses.remove}
        />
      )}
    </>
  );
}

export function getTableColumns(serviceDesk: boolean, listeners: Listeners): ITableField<IOrganization>[] {
  const columns: ITableField<IOrganization>[] = [];

  if (serviceDesk) {
    columns.push(new IntegerField('id', 'ID'));
    columns.push(
      new CalculatedStringField('internal', T('organizations.table.field.Internal'), row =>
        row.internal ? 'Yes' : 'No'
      )
    );
  }

  columns.push(
    new CalculatedStringField('name', T('organizations.field.name'), row => {
      if (row.parentName) {
        return `${row.parentName} / ${row.name}`;
      } else {
        return row.name;
      }
    }),
    new URLField('website', T('organizations.field.website')),
    new URLField('logo', T('organizations.field.logo'), {}, 'Logo'),
    new CalculatedStringField('backgroundColor', T('organizations.table.field.backgroundColor'), row =>
      row.colors ? row.colors.background : undefined
    ),
    new CalculatedStringField('fontColor', T('organizations.table.field.fontColor'), row =>
      row.colors ? row.colors.font : undefined
    ),
    new StringField('supportEmailAddress', T('organizations.table.field.supportEmail')),
    new StringField('supportPhoneNumber', T('organizations.table.field.supportPhone')),
    new URLField('supportWebsite', T('organizations.table.field.supportWebsite')),
    new ComponentField(
      'actions',
      T('generic.actions'),
      organization => renderActionColumn(organization, serviceDesk, listeners),
      {
        autoInsert: 'end'
      }
    )
  );
  if (serviceDesk) {
    columns.splice(0, 0, new IntegerField('id', 'ID'));
  }

  return columns;
}

export const rowKey = (row: IOrganization) => row.id;
