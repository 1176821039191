interface TrackProps {
  source: {
    id: string;
    value: number;
    percent: number;
  };
  target: {
    id: string;
    value: number;
    percent: number;
  };
  getTrackProps: () => React.HTMLProps<HTMLDivElement>;
}

export const Track: React.FC<TrackProps> = ({source, target, getTrackProps}) => (
  <div
    style={{
      position: 'absolute',
      zIndex: 1,
      backgroundColor: '#99c031', // #b28900
      borderRadius: 0, // 7
      cursor: 'pointer',
      width: 124, // 14
      transform: 'translate(-50%, 0%)',
      top: `${source.percent}%`,
      height: `${target.percent - source.percent}%`
    }}
    {...getTrackProps()}
  />
);
