import {T} from '../utils/Internationalization';

export const enum ChargingPriorityRuleType {
  ServiceLocation = 'SERVICELOCATION',
  User = 'USER',
  RFIDTag = 'RFIDTAG'
}

export interface IChargingRule {
  targetType: ChargingPriorityRuleType;
  rfid?: {
    value: string;
    expirationTimestamp?: number;
    comment?: string;
  };
  serviceLocationId?: number;
  userId?: number;
  userName?: string;
  priority?: number;
  discount?: number;
}

export function getRuleName(item: IChargingRule) {
  if (item.userId) return item.userName || `#${item.userId}`;
  else if (item.rfid) return item.rfid.value;
  else return T('chargingStationPrivilegeRule.default');
}

export interface IChargingPriority {
  id: number;
  serviceLocationId: number;
  token?: string;
  priority: number;
  comment?: string;
}
