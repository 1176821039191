import React, {useState, useMemo} from 'react';

import {SingleActionModal, Form, Input} from '../../components/bootstrap';
import FormInputGroup from '../../components/inputs/FormInputGroup';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IOrganization} from '../../models/Organization';
import {T} from '../../utils/Internationalization';

interface ISelectOrganizationModalProps extends IPromiseModalProps<IOrganization | undefined> {
  title: string;
  organizations: IOrganization[];
}

export const SelectOrganizationModal = (props: ISelectOrganizationModalProps) => {
  const {title, organizations} = props;

  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(undefined);

  const [organization, setOrganization] = useState<IOrganization | undefined>(organizations[0]);

  const organizationOptions = useMemo(
    () =>
      organizations.map(org => (
        <option key={org.id} value={org.id}>
          {org.name}
        </option>
      )),
    [organizations]
  );

  const handleChangedOrganization = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const id = parseInt(e.currentTarget.value);
    const organization = organizations.find(organization => organization.id === id);
    setOrganization(organization);
  };

  const handleClickedMove = async () => {
    if (!organization) return;

    resolve(organization);
  };

  return (
    <SingleActionModal isOpen={isOpen} onToggle={handleClose} title={title} action={handleClickedMove}>
      <Form>
        <FormInputGroup name="organization" label={T('locations.moveToOrganization.organization')}>
          <Input type="select" value={organization ? organization.id : ''} onChange={handleChangedOrganization}>
            {organizationOptions}
          </Input>
        </FormInputGroup>
      </Form>
    </SingleActionModal>
  );
};
