import {Button as RsButton} from '../../../components/bootstrap';
import {T} from '../../../utils/Internationalization';

interface OfflineChargingFieldProps {
  isOfflineChargingAllowed: boolean;
  isReadOnly: boolean;
  handleOfflineChargingAllowed: (allowed: boolean) => void;
}

export const OfflineChargingField = (props: OfflineChargingFieldProps) => {
  return (
    <div style={{display: 'inline-block'}}>
      <div
        style={{
          display: 'inline-block',
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem'
        }}
      >
        {props.isOfflineChargingAllowed
          ? T('chargingStationConfiguration.offlineCharging.enabled')
          : T('chargingStationConfiguration.offlineCharging.disabled')}
      </div>
      {!props.isReadOnly && (
        <RsButton
          size="sm"
          style={{position: 'relative', bottom: 2}}
          onClick={() => props.handleOfflineChargingAllowed(!props.isOfflineChargingAllowed)}
        >
          {props.isOfflineChargingAllowed
            ? T('chargingStationConfiguration.offlineCharging.disable')
            : T('chargingStationConfiguration.offlineCharging.enable')}
        </RsButton>
      )}
    </div>
  );
};
