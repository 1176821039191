import {T} from '../utils/Internationalization';

import {Contract} from './Contract';

export enum BillingType {
  Invoice = 'BILL',
  SelfBilling = 'SELFBILL'
}

export enum BillingStatus {
  Draft = 'DRAFT',
  Posted = 'POSTED',
  Sent = 'SENT',
  Deleted = 'DELETED'
}

export enum PaymentState {
  NotPaid = 'NOT_PAID',
  InPayment = 'IN_PAYMENT',
  Paid = 'PAID',
  Partial = 'PARTIAL',
  Reversed = 'REVERSED'
}

export interface IBillingAmount {
  value: number;
  currency: string;
}

export interface IBillingLinks {
  bill: string;
  sessions: string;
  csv: string;
}

export interface IBilling {
  id: number;
  type: BillingType;
  invoiceNumber: string;
  status: BillingStatus;
  paymentState: PaymentState;
  netAmount?: IBillingAmount;
  totalAmount?: IBillingAmount;
  contractType: Contract;
  date: string;
  reference?: string;
  links: IBillingLinks;
}

export function translatePaymentState(paymentState: PaymentState): string {
  switch (paymentState) {
    case PaymentState.NotPaid:
      return T('invoices.paymentStatus.notPaid');
    case PaymentState.InPayment:
      return T('invoices.paymentStatus.inPayment');
    case PaymentState.Paid:
      return T('invoices.paymentStatus.paid');
    case PaymentState.Partial:
      return T('invoices.paymentStatus.partial');
    case PaymentState.Reversed:
      return T('invoices.paymentStatus.reversed');
    default:
      return paymentState;
  }
}
