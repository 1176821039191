import React, {useEffect, useReducer} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {Icons} from '../../components/Icon';
import {NumberInputGroup, NumberValue} from '../../components/inputs/NumberInput';
import {Button} from '../../components/ui/button';
import {Checkbox} from '../../components/ui/checkbox';
import {Edit} from '../../components/ui-lib/icons/small';
import {useModals} from '../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ILocationSummary} from '../../models/Location';
import {ICapacityProtectionConfiguration} from '../../models/SmartDevice';
import {FormProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {useLocationIdWithGrid} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {useCardContext} from '../CardContext';

import styles from './EditConfig.module.scss';

const capacityProtectionConfigReducer = (
  state: ICapacityProtectionConfiguration,
  newState: Partial<ICapacityProtectionConfiguration>
) => {
  return {...state, ...newState};
};

function CapacitySettingsModal(
  props: IPromiseModalProps<boolean> &
    ICapacityProtectionConfiguration & {
      locationId: number;
      onSave?: (configuration: ICapacityProtectionConfiguration) => void;
    }
) {
  const [isOpen, resolve] = usePromiseModal(props);
  const form = useFormState();
  const [error, setError] = React.useState<string | undefined>();
  const {api} = useAppContext();
  const [loadingState, setLoadingState] = React.useState<'saving' | 'idle'>('idle');

  const [config, setCapacityConfiguration] = useReducer(capacityProtectionConfigReducer, props);

  async function handleSave() {
    if (form.hasErrors()) {
      return form.showErrors();
    }

    setLoadingState('saving');

    try {
      await api.updateCapacityProtection(props.locationId, config);
      setLoadingState('idle');

      NotificationManager.success('Maximum capacity settings saved', 'Success', 5000);
    } catch (error: any) {
      setLoadingState('idle');
      setError(error.error);

      throw error.error;
    }

    props.onSave?.(config);

    resolve(true);
  }

  const {active = false, capacityMaximumPower} = config;

  return (
    <FormProvider>
      <SingleActionModal
        error={error}
        title={T('capacityProtection.header')}
        action={handleSave}
        isOpen={isOpen}
        onToggle={() => resolve(false)}
        size="md"
        loading={loadingState === 'saving'}
      >
        <Checkbox
          id="capacity-active"
          name="capacity-active"
          checked={active}
          label={T('capacityProtection.enable')}
          onCheckedChange={() => setCapacityConfiguration({active: !active})}
          className="tw-mb-0"
          testId="capacity-active"
        />
        {active && (
          <>
            <p>
              {!!props.capacitySuggestedPower
                ? T('capacityProtection.suggested', {
                    peak: props.capacitySuggestedPower.toString()
                  })
                : T('capacityProtection.contact-supplier')}
            </p>

            <p>{T('capacityProtection.sidenote')}</p>
            <NumberInputGroup
              name="capacity-maximum-power"
              label={T('capacityProtection.header')}
              suffix="kW"
              value={capacityMaximumPower ? NumberValue.create(capacityMaximumPower) : NumberValue.create(0)}
              onChange={value => setCapacityConfiguration({capacityMaximumPower: value.numberValue ?? undefined})}
            />
          </>
        )}
      </SingleActionModal>
    </FormProvider>
  );
}

const initialCapacityProtectionConfiguration: ICapacityProtectionConfiguration = {
  active: false,
  capacityMaximumPower: 0,
  capacitySuggestedPower: 0
};

function MaxCapacitySettings({location}: {location: ILocationSummary}) {
  const modals = useModals();
  const {api} = useAppContext();
  const {fetch} = useCardContext();
  const [loadingState, setLoadingState] = React.useState<'loading' | 'loaded'>('loading');
  const locationIdWithGrid = useLocationIdWithGrid(fetch, location);

  const [{active, capacityMaximumPower, capacitySuggestedPower}, setCapacityConfiguration] = useReducer(
    capacityProtectionConfigReducer,
    initialCapacityProtectionConfiguration
  );

  useEffect(() => {
    async function fetchCapacitySettings() {
      const configuration = await api.getCapacityProtection(location?.id);

      setCapacityConfiguration(configuration);
      setLoadingState('loaded');
    }

    fetchCapacitySettings();
  }, [api, location?.id]);

  async function onUpdateCapacitySettings(configuration: ICapacityProtectionConfiguration) {
    setCapacityConfiguration(configuration);
  }

  async function onEdit() {
    await modals.show<boolean>(props => {
      return (
        <CapacitySettingsModal
          locationId={location?.id}
          active={active}
          capacityMaximumPower={capacityMaximumPower}
          capacitySuggestedPower={capacitySuggestedPower}
          onSave={onUpdateCapacitySettings}
          {...props}
        />
      );
    });
  }

  return locationIdWithGrid ? (
    <div className={styles.maxCapacity}>
      {loadingState === 'loading' && <div className={styles.center}>{Icons.Loading}</div>}
      {loadingState === 'loaded' && (
        <>
          {!active ? (
            <span>{T('ctConfiguration.maxCapacityNotSet')}</span>
          ) : (
            <div className={styles.capacityQuickView}>
              <span>
                {T('ctConfiguration.maxCapacity')}: {capacityMaximumPower} kW
              </span>
            </div>
          )}

          <Button variant="ghost" size="icon" onClick={onEdit}>
            <Edit className="tw-h-4 tw-w-4" />
          </Button>
        </>
      )}
    </div>
  ) : (
    <span>{''}</span>
  );
}

export {MaxCapacitySettings};
