import {getCurrentLanguage} from '../utils/Internationalization';

import countriesJson from './countries.json';

export interface Address {
  streetAndNumber: string;
  postalCode: string;
  city: string;
  countryCode: string;
}

export class Country {
  code: string;
  streetNumberFirst: boolean;
  streetComma: boolean;
  cityFirst: boolean;

  private translations: {[key: string]: string};

  constructor(
    code: string,
    translations: {[key: string]: string},
    streetNumberFirst: boolean = false,
    streetComma: boolean = false,
    cityFirst: boolean = false
  ) {
    this.code = code;
    this.translations = translations;
    this.streetNumberFirst = streetNumberFirst;
    this.streetComma = streetComma;
    this.cityFirst = cityFirst;
  }

  formatAddressSingleLine(address: Address) {
    const streetParts = [address.streetAndNumber];
    const street = streetParts.join(this.streetComma ? ', ' : ' ');
    const cityParts = this.cityFirst ? [address.city, address.postalCode] : [address.postalCode, address.city];
    const city = cityParts.join(' ');
    return `${street}, ${city}`;
  }

  translate() {
    return this.translations[getCurrentLanguage()] || this.translations.en;
  }
}

export const Countries = countriesJson.map(
  country =>
    new Country(country.code, country.translations, country.streetNumberFirst, country.streetComma, country.cityFirst)
);
