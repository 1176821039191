import {UsageUnit} from './UsageValue';

export interface IUpdateApplianceRequest {
  name: string;
  type: string;
}

export const enum ApplianceSource {
  CT = 'CT',
  NILM = 'NILM',
  SWITCH = 'SWITCH'
}

export interface IAppliance {
  id: string;
  name: string;
  type: string;
  source: ApplianceSource;
  creationTimestamp: number;
  stats?: IApplianceStats;
  recent: boolean;
  supportsScenes: boolean;
  currency?: string;
}

export interface IApplianceStats {
  frequency: number;
  timeOn: number;
  power?: IUnitValue;
  usage?: IUnitValue;
  cost?: ICostValue;
}

export interface IUnitValue {
  value: number;
  unit: UsageUnit;
}

export interface ICostValue {
  currency: string;
  value: number;
}
