import {T} from './Internationalization';

export type OpeningHours = {
  from: {
    hour: number;
    minute: number;
  };
  to: {
    hour: number;
    minute: number;
  };
};

export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export const WeekDays: DayOfWeek[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export type TimeRange = {
  dayOfWeek: DayOfWeek;
  openingHours?: OpeningHours[];
};

export function translateDayOfWeek(dayOfWeek: DayOfWeek): string {
  switch (dayOfWeek) {
    case 'MONDAY':
      return T('dayOfWeek.monday');
    case 'TUESDAY':
      return T('dayOfWeek.tuesday');
    case 'WEDNESDAY':
      return T('dayOfWeek.wednesday');
    case 'THURSDAY':
      return T('dayOfWeek.thursday');
    case 'FRIDAY':
      return T('dayOfWeek.friday');
    case 'SATURDAY':
      return T('dayOfWeek.saturday');
    case 'SUNDAY':
      return T('dayOfWeek.sunday');
  }
}

export function stringToHoursMinutes(time: string): OpeningHours['from'] {
  let [hours, mins] = time.split(':');

  // if hours is not defined or it's not parsable to int, return 0
  if (!hours || isNaN(parseInt(hours))) hours = '0';
  if (!mins || isNaN(parseInt(mins))) mins = '0';

  return {
    hour: parseInt(hours),
    minute: parseInt(mins)
  };
}

export function hourMinutestoString(time: OpeningHours['from']): string {
  const hour = time.hour < 10 ? `0${time.hour}` : time.hour;
  const minute = time.minute < 10 ? `0${time.minute}` : time.minute;

  return `${hour}:${minute}`;
}
