import React, {useMemo, useEffect} from 'react';

import {Input, Label} from '../../components/bootstrap';

import {useLivePhasor} from '../../livedata/LiveData';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {DeviceType} from '../../models/DeviceType';
import {PhaseType, getPhaseTypeLabel} from '../../models/HighLevelConfiguration';
import {getLoadName} from '../../models/Load';
import {OnlineStatus} from '../../models/OnlineStatus';
import {useChildLocations, useHighLevelConfiguration} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';

import {Reload} from '../components/actions';
import {CardActions, Spring} from '../components/CardActions';
import {cardViewProps, CardView, CustomActions} from '../components/CardView';

import {getLoadsWithChannels} from './Data';

import {Summary} from './Summary';

interface IPhasorDisplaySettings extends ICardSettings {
  loadId?: number;
}

const PhasorDisplay = (props: ICardProps<IPhasorDisplaySettings>) => {
  const {fetch, settings, updateSettings, fetching} = props;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const [configuration, refreshConfiguration] = useHighLevelConfiguration(fetch, locationId);
  const loads = useMemo(() => (configuration ? getLoadsWithChannels(configuration) : []), [configuration]);
  const phaseType = (configuration && configuration.phaseType) || PhaseType.Star;
  const [childs] = useChildLocations(fetch, location);

  const {loadId} = settings;
  const load = loads.find(load => load.id === loadId);
  const loadLocation =
    load && load.serviceLocationId && load.serviceLocationId !== locationId
      ? childs.find(x => x.id === load.serviceLocationId)
      : location;
  const [message, status] = useLivePhasor(loadLocation);

  const loadOptions = useMemo(
    () =>
      loads.map(load => (
        <option key={`board-icon-${load.id}`} value={load.id}>
          {getLoadName(load)}
        </option>
      )),
    [loads]
  );

  const titleAddendum = (
    <span style={{paddingLeft: '1em'}}>{getPhaseTypeLabel(phaseType) || T('configuration.phaseType.unknown')}</span>
  );

  const {serialNumber = undefined, deviceType = undefined} = location || {};
  const isInfinity = deviceType === DeviceType.Genius; // Device.isInfinity(deviceType) : false;

  let error;
  if (!serialNumber || !deviceType) {
    error = T('phasorDisplay.notInstalled');
  } else if (!isInfinity) {
    error = T('phasorDisplay.notSupported');
  } else if (fetching.length === 0 && !load) {
    error = T('phasorDisplay.notYetConfigured');
  } else if (status === OnlineStatus.Offline) {
    error = T('card.error.noLiveValues');
  } else if (phaseType === PhaseType.Delta) {
    error = T('phasorDisplay.error.notSupportedForDelta');
  }

  useEffect(() => {
    if (!load && loads.length > 0) updateSettings({loadId: loads[0].id});
  }, [load, loads, updateSettings]);

  const handleSelectedLoadChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    updateSettings({loadId: parseInt(e.currentTarget.value)});
  };

  const currents = message ? message.currentMagnitudeData.map((current: number) => current / 1000) : [];
  const currentAngles = message ? message.currentAngleData.map(angle => angle / 1000) : [];
  const voltages = message ? message.voltageMagnitudeData.map(voltage => voltage / 1000) : [];
  const voltageAngles = message ? message.voltageAngleData.map(angle => angle / 1000) : [];

  const summary = load && (
    <Summary
      phaseType={phaseType}
      load={load}
      currents={currents}
      currentAngles={currentAngles}
      voltages={voltages}
      voltageAngles={voltageAngles}
    />
  );

  const actions: CustomActions = () => (
    <CardActions>
      <Reload onReload={refreshConfiguration} />
      <Spring />
      <Label>{T('phasorDisplay.load')}</Label>
      <Input
        type="select"
        name="load"
        style={{width: 300, maxWidth: 'inherit'}}
        value={loadId || ''}
        onChange={handleSelectedLoadChanged}
      >
        {loadOptions}
      </Input>
    </CardActions>
  );

  return (
    <CardView
      titleAddendum={titleAddendum}
      actions={isInfinity ? actions : undefined}
      error={error}
      ready={message !== undefined}
      {...cardViewProps(props)}
    >
      {isInfinity && summary}
    </CardView>
  );
};

const CARD: ICardType<IPhasorDisplaySettings> = {
  type: CardTypeKey.PhasorDisplay,
  title: 'phasorDisplay.title',
  description: 'phasorDisplay.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: PhasorDisplay
};
export default CARD;
