import React, {useMemo} from 'react';

import {Row, Col} from 'reactstrap';

import {Button} from '../../../components/ui/button';
import {Checkbox} from '../../../components/ui/checkbox';
import TimesCircle from '../../../components/ui-lib/legacy-icons/TimesCircle';
import {IAutomationTarget, getTargetsWithAction, AutomationActionType} from '../../../models/Automation';
import {getPropertyStringValue, getPropertyValueFromString} from '../../../models/SmartDevice';
import {FormProvider} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';
import SmartDeviceField from '../../SmartDevices/SmartDeviceField';

import {IMultiTargetAction} from './MultiTargetAction';

interface EditActionViewProps {
  index: number;
  action: IMultiTargetAction;
  targets: IAutomationTarget[];
  setAction: (index: number, action: IMultiTargetAction) => void;
  onDelete: () => void;
}
export default (props: EditActionViewProps) => {
  const {index, action, targets, setAction, onDelete} = props;
  const {action: actionSpec, properties, targets: actionTargets} = action;

  const availableTargets = useMemo(() => getTargetsWithAction(targets, actionSpec), [targets, actionSpec]);
  const targetCheckboxes = useMemo(() => {
    const handleTargetChecked = (target: IAutomationTarget, checked: boolean) => {
      let newTargets: IAutomationTarget[];
      if (checked) {
        newTargets = [...actionTargets, target];
      } else {
        newTargets = actionTargets.filter(t => t.id !== target.id);
      }

      const newAction: IMultiTargetAction = {
        action: actionSpec,
        properties,
        targets: newTargets
      };
      setAction(index, newAction);
    };

    return availableTargets.map((target, index) => (
      <Col sm={4} key={index}>
        <Checkbox
          id={`target-${target.id}`}
          name={`target-${target.id}`}
          label={target.name}
          checked={actionTargets.includes(target)}
          onCheckedChange={checked => handleTargetChecked(target, checked)}
          testId={`target-${target.id}`}
        />
      </Col>
    ));
  }, [availableTargets, actionTargets, actionSpec, properties, setAction, index]);

  const handleClickedSelectAll = () => {
    const newAction: IMultiTargetAction = {
      action: actionSpec,
      properties,
      targets: availableTargets
    };
    setAction(index, newAction);
  };

  const handleClickedDeselectAll = () => {
    const newAction: IMultiTargetAction = {
      action: actionSpec,
      properties,
      targets: []
    };
    setAction(index, newAction);
  };

  const fields = useMemo(() => {
    if (action.action.type === AutomationActionType.Smart) {
      return (action.properties || []).map((property, propertyIndex) => {
        const updateValue = (name: string, value: string) => {
          const propertyValue = getPropertyValueFromString(property.spec, value) || [];
          const newProperty = {...property, values: propertyValue};
          const newProperties = action.properties.map((p, i) => (i === propertyIndex ? newProperty : p));
          const newAction = {...action, properties: newProperties};
          setAction(index, newAction);
        };

        return (
          <Col sm={4} key={property.spec.name} style={{paddingLeft: 0}}>
            <SmartDeviceField property={property} value={getPropertyStringValue(property)} updateValue={updateValue} />
          </Col>
        );
      });
    } else {
      return [];
    }
  }, [index, action, setAction]);

  return (
    <FormProvider>
      <div>
        <h4 className="tw-inline-flex tw-items-center tw-gap-2">
          {action.action.description}
          <Button variant="ghost_action_btn" onClick={onDelete}>
            <TimesCircle width={16} height={16} />
          </Button>
        </h4>
        <Row>{targetCheckboxes}</Row>
        <Button variant="tertiary_default" size="lg" onClick={handleClickedSelectAll}>
          {T('chargingStationAutomations.selectAll')}
        </Button>
        <Button variant="tertiary_default" size="lg" onClick={handleClickedDeselectAll}>
          {T('chargingStationAutomations.deselectAll')}
        </Button>
        {fields}
      </div>
    </FormProvider>
  );
};
