import type {SVGProps} from 'react';
const SvgInfoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    fillRule="evenodd"
    {...props}
  >
    <path d="M0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12ZM13.2 13.2C13.2 12.8817 13.0736 12.5765 12.8485 12.3515C12.6235 12.1264 12.3183 12 12 12C11.6817 12 11.3765 12.1264 11.1515 12.3515C10.9264 12.5765 10.8 12.8817 10.8 13.2V18C10.8 18.3183 10.9264 18.6235 11.1515 18.8485C11.3765 19.0736 11.6817 19.2 12 19.2C12.3183 19.2 12.6235 19.0736 12.8485 18.8485C13.0736 18.6235 13.2 18.3183 13.2 18V13.2ZM12 6.072C12.2364 6.072 12.4704 6.11856 12.6888 6.20902C12.9072 6.29948 13.1056 6.43206 13.2728 6.59921C13.4399 6.76635 13.5725 6.96478 13.663 7.18317C13.7534 7.40156 13.8 7.63562 13.8 7.872C13.8 8.10838 13.7534 8.34244 13.663 8.56083C13.5725 8.77922 13.4399 8.97765 13.2728 9.14479C13.1056 9.31194 12.9072 9.44452 12.6888 9.53498C12.4704 9.62544 12.2364 9.672 12 9.672C11.5226 9.672 11.0648 9.48236 10.7272 9.14479C10.3896 8.80723 10.2 8.34939 10.2 7.872C10.2 7.39461 10.3896 6.93677 10.7272 6.59921C11.0648 6.26164 11.5226 6.072 12 6.072Z" />
  </svg>
);
export default SvgInfoCircle;
