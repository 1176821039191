import classnames from 'classnames';

import React, {useState, useRef, CSSProperties, useMemo} from 'react';
import {Dropdown, DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';

import {Closeable} from './Closeable';
import {Icons, Icon, BOARD_ICON_MAP} from './Icon';
import styles from './SearchableSelectInput.module.scss';

export interface ISelectOption {
  value: string;
  label: string;
  icon?: Icon;
  color?: string;
}

interface ISearchableSelectInputProps {
  options: ISelectOption[];
  value: string;
  onChange: (value: string) => void;
  onInputChange?: (value: string) => void;
  invalid?: boolean;
  placeholder: string;
  disabled?: boolean;
  keepValueOnFocus?: boolean;
  style?: CSSProperties;
  name?: string;
  noneLabel?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  autoSelect?: boolean;
  clearable?: boolean;
}

export const SearchableSelectInput = (props: ISearchableSelectInputProps) => {
  const {
    name,
    options,
    value,
    onChange,
    onInputChange,
    invalid,
    placeholder,
    disabled,
    keepValueOnFocus = false,
    style,
    noneLabel,
    onBlur,
    onFocus,
    autoSelect = false,
    clearable = false
  } = props;

  const selected = options?.find(option => option.value === value);
  const actualStyle = useMemo<CSSProperties>(
    () => (style ? {position: 'relative', ...style} : {position: 'relative'}),
    [style]
  );
  const [isOpen, setOpen] = useState(false);
  const [filter, setFilter] = useState(selected && value ? selected.label : '');
  const [focused, setFocused] = useState(false);
  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.value);
    onInputChange && onInputChange(e.currentTarget.value);
  };
  const ref = useRef<HTMLInputElement>(null);
  const handleClickedClear = () => {
    setFilter('');
    onChange('');
    if (ref.current) ref.current.focus();
  };

  const handleClickedClearValue = () => {
    onChange('');
  };

  const handleToggle = () => {
    const newIsOpen = focused || !isOpen;
    if (!keepValueOnFocus) {
      setFilter('');
    }
    setOpen(newIsOpen);
  };

  const handleClickedDropdown = () => setOpen(true);

  const handleFocus = () => {
    if (!keepValueOnFocus) setFilter('');
    setFocused(true);
    setOpen(true);
    onFocus?.();
  };

  const handleClose = () => {
    setFocused(false);
    setOpen(false);
  };

  const handleBlur = () => {
    if (autoSelect && filter.length > 3) {
      const filterLower = filter.toLocaleLowerCase();
      const item = options.find(x => x.label.toLocaleLowerCase().includes(filterLower));
      if (item) onChange(item.value);
    }
    if (onBlur) {
      onBlur();
    }
  };

  const optionItems = useMemo(() => {
    const filterLower = filter.toLocaleLowerCase();
    const filteredOptions = options?.filter(option => option.label.toLocaleLowerCase().includes(filterLower));
    if (noneLabel !== undefined && filter === '') {
      filteredOptions.splice(0, 0, {value: '', label: noneLabel});
    }

    const handleClickedOption = (option: ISelectOption) => {
      setFilter(option.label);
      onChange(option.value);
      setTimeout(() => setOpen(false));
    };

    return filteredOptions?.map(option => (
      <DropdownItem key={option.value} onClick={() => handleClickedOption(option)} data-value={option.value}>
        <div className="!tw-flex !tw-justify-start !tw-items-center !tw-w-full !tw-py-2 !-tw-my-1 !-tw-mx-2.5">
          {option.icon && <span className="!tw-mr-2">{BOARD_ICON_MAP[option.value]}</span>}
          {option.label}
        </div>
      </DropdownItem>
    ));
  }, [options, filter, noneLabel, onChange]);

  return (
    <Closeable isOpen={isOpen} onClose={handleClose}>
      <Dropdown isOpen={isOpen} toggle={handleToggle} style={actualStyle} className="!tw-min-w-44">
        {filter && isOpen ? (
          <div onClick={handleClickedClear} className={styles.clear}>
            {Icons.Delete}
          </div>
        ) : (
          <div className={styles.downToggleButton} onClick={handleToggle}>
            {Icons.Dropdown}
          </div>
        )}
        {clearable && !isOpen && value && (
          <div onClick={handleClickedClearValue} className={styles.clearValue}>
            {Icons.Delete}
          </div>
        )}
        <DropdownToggle tag="div" onClick={handleClickedDropdown}>
          <input
            name={name}
            className={classnames(
              `form-control${invalid ? ' is-invalid' : ''}`,
              '!tw-text-base !tw-leading-[1.375rem] !tw-pl-0 !tw-py-[0.5rem] !tw-h-[2.5rem] !tw-max-h-[2.5rem]'
            )}
            autoComplete="off"
            type="text"
            ref={ref}
            value={isOpen ? filter : selected ? selected.label : placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </DropdownToggle>
        <DropdownMenu
          className={styles.dropdown}
          flip={false}
          modifiers={{
            preventOverflow: {padding: 50}
          }}
        >
          {optionItems}
        </DropdownMenu>
      </Dropdown>
    </Closeable>
  );
};
