import React from 'react';

import {T} from '../../utils/Internationalization';

import styles from './UnknownValue.module.scss';

interface IUnknownValueProps {
  className?: string;
}
const UnknownValue = (props: IUnknownValueProps) => {
  let {className, ...otherProps} = props;

  // Hook extra classes into the component
  className = [styles.unknownValue, className || ''].join(' ');

  return (
    <td className={className} {...otherProps}>
      {T.generic.na()}
    </td>
  );
};

export default UnknownValue;
