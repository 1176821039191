import React, {useMemo} from 'react';

import {Printers} from '../../server/Printers';

import {SelectInput} from './SelectInput';

interface PrinterInputProps {
  value: string;
  onChange: (value: string) => void;
}

export function PrinterInput(props: PrinterInputProps) {
  const {value, onChange} = props;

  const options = useMemo(
    () =>
      Printers.map(printer => (
        <option key={printer.name} value={printer.name}>
          {printer.displayName}
        </option>
      )),
    []
  );

  return (
    <SelectInput value={value} onChange={onChange}>
      {options}
    </SelectInput>
  );
}
