import React from 'react';

import {useBoardContext} from '../../app/boardcontext';
import {useAppContext} from '../../app/context';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {T} from '../../utils/Internationalization';
import {CardTypeKey} from '../CardType';
import {addCardToBoard} from '../CardUtils';

type EditConfigMovedModalProps = IPromiseModalProps<void>;

export default (props: EditConfigMovedModalProps) => {
  const [isOpen, resolve] = usePromiseModal(props);
  const {api, store} = useAppContext();
  const {board} = useBoardContext();

  const handleToggle = () => resolve();
  const handleClickedAddCard = () => {
    if (!board) return;

    addCardToBoard(api, store, board, CardTypeKey.CTConfiguration, {});
    resolve();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>{T('liveElectricityValues.configMoved.title')}</ModalHeader>
      <ModalBody>{T('liveElectricityValues.configMoved.message')}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleClickedAddCard}>
          {T('liveElectricityValues.configMoved.addCard')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
