import React from 'react';

import {Button as RsButton} from '../../components/bootstrap';
import FormInputGroup from '../../components/inputs/FormInputGroup';

import {DayOfWeek} from '../../models/Automation';

import {classes} from '../../utils/Styles';

import styles from './DaysOfWeekInputGroup.module.scss';

interface DaysOfWeekInputGroupProps {
  label: string;
  value: DayOfWeek[];
  error?: string;
  onChange: (value: DayOfWeek[]) => void;
}

interface DayButtonProps {
  value: DayOfWeek[];
  day: DayOfWeek;
  toggle: (day: DayOfWeek) => void;
  children: string;
}
const DayButton = (props: DayButtonProps) => {
  const {value, day, toggle, children} = props;

  const active = value.includes(day);
  return (
    <RsButton
      outline
      active={active}
      color="outline"
      onClick={() => toggle(day)}
      className={classes(styles.dayButton, active ? styles.active : undefined)}
    >
      {children}
    </RsButton>
  );
};

export const DaysOfWeekInputGroup = (props: DaysOfWeekInputGroupProps) => {
  const {label, value, onChange, error} = props;

  const toggle = (day: DayOfWeek) => {
    if (value.includes(day)) {
      onChange(value.filter(x => x !== day));
    } else {
      onChange([...value, day]);
    }
  };

  return (
    <FormInputGroup name="days" label={label} error={error}>
      <div>
        <DayButton value={value} day={DayOfWeek.Monday} toggle={toggle}>
          M
        </DayButton>
        <DayButton value={value} day={DayOfWeek.Tuesday} toggle={toggle}>
          T
        </DayButton>
        <DayButton value={value} day={DayOfWeek.Wednesday} toggle={toggle}>
          W
        </DayButton>
        <DayButton value={value} day={DayOfWeek.Thursday} toggle={toggle}>
          T
        </DayButton>
        <DayButton value={value} day={DayOfWeek.Friday} toggle={toggle}>
          F
        </DayButton>
        <DayButton value={value} day={DayOfWeek.Saturday} toggle={toggle}>
          S
        </DayButton>
        <DayButton value={value} day={DayOfWeek.Sunday} toggle={toggle}>
          S
        </DayButton>
      </div>
    </FormInputGroup>
  );
};
