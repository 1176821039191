import {Label} from '../../../components/ui/label';
import {RadioGroupItem} from '../../../components/ui/radio-group';

interface RadioGroupFieldProps {
  key: string;
  name: string;
  value: string;
  checked: boolean | undefined;
  label: string;
  fieldWrapperClass?: string;
  radioGroupClass?: string;
  radioLabelClass?: string;
}

const RadioGroupField = (props: RadioGroupFieldProps) => {
  const {
    key,
    name,
    value,
    label,
    checked,
    fieldWrapperClass = 'tw-flex tw-items-start tw-gap-4 tw-mb-2',
    radioGroupClass = 'tw-mt-1',
    radioLabelClass = 'tw-flex tw-flex-col'
  } = props;
  return (
    <div className={fieldWrapperClass} key={key}>
      <RadioGroupItem id={name} value={value} checked={checked} className={radioGroupClass} />
      <div className={radioLabelClass}>
        <Label htmlFor={name}>{label}</Label>
      </div>
    </div>
  );
};

export default RadioGroupField;
