import {getCurrentLanguage, Language} from '../utils/Internationalization';

import {Contract} from './Contract';
import {Address} from './Countries';
import {InstallationPartner} from './InstallationPartner';
import {IUser} from './User';

export const enum OrganizationLanguage {
  English = 'en',
  Dutch = 'nl',
  French = 'fr',
  German = 'de',
  Italian = 'it',
  Spanish = 'es',
  Portuguese = 'pt',
  Turkish = 'tr'
}
export const OrganizationLanguages: string[] = [
  OrganizationLanguage.English,
  OrganizationLanguage.Dutch,
  OrganizationLanguage.French,
  OrganizationLanguage.German,
  OrganizationLanguage.Italian,
  OrganizationLanguage.Spanish,
  OrganizationLanguage.Portuguese,
  OrganizationLanguage.Turkish
];

export interface IOrganization {
  currency?: string;
  id: number;
  name: string;
  multilingual: boolean;
  description: string;
  descriptions: {[key: string]: string};
  logo: string;
  privacyPolicy?: string;
  colors?: IOrganizationColors;
  website?: string;
  supportWebsite?: string;
  supportEmailAddress?: string;
  supportPhoneNumber?: string;
  gdpr: boolean;
  internal: boolean;
  selfBillFooterDetails?: string[];
  linkedToOdoo: boolean;
  role?: string;

  parentId?: number;
  parentName?: string;
  contracts?: Contract[];
  inherits?: Contract[];
  odooCompany?: IOdooCompany;
  installationPartner?: InstallationPartner;

  cpo?: {
    selfBillFooterDetails?: string[];
    cpoFeePercentage?: number;
    billReference?: string;
    defaultVATPercentage?: number;
    acConnectorFee?: number;
    dcConnectorFee?: number;
    paymentTerminalFee?: number;
    vatExempt?: boolean;
    vatExemptNote?: string;
    roamingBeta?: boolean;
  };
  chargingCustomer?: {
    perSessionFee?: number;
    billReference?: string;
  };
  splitBillingEmployer?: {
    billReference?: string;
  };
}

export interface IOdooCompany {
  name: string;
  vat: string;
  address: Address;
  contact?: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    language: Language;
  };
  billingContact?: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    language: Language;
    address: Address;

    iban?: string;
    bic?: string;
    accountHolder?: string;
  };
}

export interface IOrganizationColors {
  background: string;
  font: string;
  buttonBackground: string;
  buttonFont: string;
}

export interface ICreateOrganizationRequest {
  parentId?: number;
  name: string;
  internal: boolean;
  description?: string;
  descriptions?: IOrganizationDescriptions;
  logo: string;
  privacyPolicy: string;
  colors: IOrganizationColors;
  website: string;
  supportWebsite: string;
  supportPhoneNumber: string;
  supportEmailAddress: string;
  gdpr: boolean;

  odooCompany?: IOdooCompany;
  installationPartner?: InstallationPartner;
}

export interface IUpdateOrganizationRequest {
  id: number;
  internal?: boolean;
  name?: string;
  description?: string;
  descriptions?: IOrganizationDescriptions;
  logo?: string;
  privacyPolicy?: string;
  colors?: IOrganizationColors;
  website?: string;
  supportWebsite?: string;
  supportPhoneNumber?: string;
  supportEmailAddress?: string;
  gdpr?: boolean;
  selfBillFooterDetails?: string[];
  hasWhitelistedTokens?: boolean;
  hasSplitBillingAgreements?: boolean;

  odooCompany?: IOdooCompany;
  installationPartner?: InstallationPartner | {id: 0};

  cpo?: {
    selfBillFooterDetails?: string[];
    cpoFeePercentage?: number;
    billReference?: string;
    acConnectorFee?: number;
    dcConnectorFee?: number;
    paymentTerminalFee?: number;
    vatExempt?: boolean;
    vatExemptNote?: string;
    roamingBeta?: boolean;
  };
  chargingCustomer?: {
    perSessionFee?: number;
    billReference?: string;
  };
  splitBillingEmployer?: {
    billReference?: string;
  };
}

export interface IOrganizationDescriptions {
  [language: string]: string;
}

export enum SearchFieldSpecies {
  String = 'String',
  BigDecimal = 'BigDecimal',
  Boolean = 'bool',
  BooleanOptional = 'Boolean',
  Timestamp = 'Date',
  Integer = 'int',
  IntegerOptional = 'Integer',
  Long = 'long',
  LongOptional = 'Long',
  UTCTimestamp = 'Instant'
}

export interface ISearchField {
  name: string;
  displayName?: string;
  displayNames?: {[language: string]: string};
  description?: string;
  descriptions?: {[language: string]: string};
  species: SearchFieldSpecies;
  sequenceNumber: number;
  possibleValues?: {
    values: string[];
    exhaustive: boolean;
  };
}

export function translateFieldName(field: ISearchField) {
  if (field.displayNames) {
    return field.displayNames[getCurrentLanguage()] || field.displayNames.en || '';
  } else {
    return field.displayName || field.name;
  }
}

export function translateFieldDescription(field: ISearchField) {
  if (field.descriptions) {
    return field.descriptions[getCurrentLanguage()] || field.descriptions.en || '';
  } else {
    return field.description || '';
  }
}

export interface ISearchFieldValue {
  spec: ISearchField;
  value: string | number | undefined;
}

export interface IOrganizationRegion {
  id: number;
  name?: string;
  names?: {[language: string]: string};
  organization: IOrganization;
  users: IUser[];
}

export function translateRegionName(region: IOrganizationRegion): string {
  if (region.names) {
    return region.names[getCurrentLanguage()] || region.names.en;
  } else {
    return region.name || `#${region.id}`;
  }
}

export interface IMeasuringCase {
  id: number;
  name: string;
  region?: IOrganizationRegion;
  serialNumbers: string[];
}

export interface IUpdateRegionRequest {
  name?: string;
  names?: {[language: string]: string};
  users?: {id: number}[];
}

export function getLabelVariant(organization: IOrganization | undefined) {
  if (organization && organization.name.toLowerCase() === 'evbox') {
    return 'evbox';
  }

  return 'default';
}
