import React, {useMemo, useEffect} from 'react';

import {Input, Label, FormGroup} from '../../components/bootstrap';
import PeriodSelector, {
  Period,
  PeriodSettings,
  getPeriodRangeForTimezone,
  PeriodRoundingMode
} from '../../components/PeriodSelector';
import Table, {SortOrder} from '../../components/Table';

import {UserRights} from '../../models/AuthUser';
import {CardDisplayType, ICardSettingsWithTable} from '../../models/CardSettings';
import {getDeviceTypeFromSerial, DeviceType} from '../../models/DeviceType';
import {IGasWaterReading} from '../../models/GasWaterReading';
import {optionalLocationToSummary} from '../../models/Location';
import {Interval} from '../../models/UsageValue';

import {hasPartnerFunctionality} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useSensors, useRetentionPolicy} from '../../utils/FunctionalData';

import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationDetails} from '../CardUtils';
import {ColumnChooser} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {CardActions} from '../components/CardActions';
import {CustomSettings, CardView, cardViewProps, CustomActions} from '../components/CardView';
import {CardTypeSelector} from '../components/settings/CardTypeSelector';

import {getTableColumns} from './Columns';

import {GasWaterReadingsChart} from './GasWaterReadingsChart';

interface IGasWaterReadingsSettings extends ICardSettingsWithTable, PeriodSettings {
  cardType: CardDisplayType;
  sensorId?: number;
}

const rowKey = (row: IGasWaterReading) => row.time;

const legacyIntervals = [Interval.MINUTES_5, Interval.HOUR, Interval.DAY, Interval.MONTH];

function GasWaterReadings(props: ICardProps<IGasWaterReadingsSettings>) {
  const {fetch, settings, updateSettings} = props;

  const {cardType, sensorId} = settings;

  const location = useCardLocationDetails(settings);
  const available = location && (location.hasGas || location.hasWater);
  const locationId = available ? location && location.id : undefined;
  const locationTimezone = location && location.timeZoneId;

  const sensorsRaw = useSensors(fetch, locationId);
  const [retentionPolicy] = useRetentionPolicy(fetch, locationId);

  const sensors = useMemo(() => {
    const whitelist = (sensorsRaw || None).filter(
      sensor => sensor.id !== undefined && getDeviceTypeFromSerial(sensor.serialNumber) === DeviceType.GasWater
    );
    return whitelist;
  }, [sensorsRaw]);

  const sensor = useMemo(() => sensors.find(sensor => sensor.id === sensorId), [sensors, sensorId]);

  const fields = getTableColumns(location, sensor);

  useEffect(() => {
    if (!sensor) {
      const newSensorId = sensors.length > 0 ? sensors[0].id : undefined;
      if (newSensorId !== sensorId) updateSettings({sensorId: newSensorId});
    }
  }, [updateSettings, sensors, sensorId, sensor]);

  const [data, refreshData] = useCardLoader(
    api => {
      if (
        locationId === undefined ||
        locationTimezone === undefined ||
        retentionPolicy === undefined ||
        sensorId === undefined
      ) {
        return Promise.resolve(undefined);
      }

      const actualPeriod = getPeriodRangeForTimezone(
        settings,
        locationTimezone,
        retentionPolicy,
        PeriodRoundingMode.EXCLUSIVE
      );
      return api
        .getSensorReadingsLegacy(locationId, sensorId, actualPeriod.interval, actualPeriod.from, actualPeriod.to)
        .then(readings => ({
          readings: readings.readings,
          period: {...actualPeriod, interval: readings.interval}
        }));
    },
    [
      locationId,
      locationTimezone,
      retentionPolicy,
      sensorId,
      settings.interval,
      settings.period,
      settings.from,
      settings.to
    ],
    plural('sensorReading'),
    undefined
  );

  const handleChangeSensor = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const {value: sensorId} = event.currentTarget;
    updateSettings({sensorId: parseInt(sensorId)});
  };

  const handleChangePeriod = (settings: PeriodSettings) => {
    updateSettings(settings);
  };

  const sensorOptions = useMemo(
    () =>
      sensors
        .filter(sensor => sensor.id !== undefined)
        .map(sensor => {
          const {id, serialNumber, name} = sensor;
          return (
            <option key={id} value={id}>
              {serialNumber} {name && `- ${name}`}
            </option>
          );
        }),
    [sensors]
  );

  const hasOptions = sensorOptions.length > 0;
  const placeholder = hasOptions ? '' : T('gasOrWaterCard.error.noSensors.short');
  const hasMultipleSensors = Array.isArray(sensors) && sensors.length > 1;

  const actions: CustomActions = state => (
    <CardActions>
      {hasMultipleSensors && (
        <Input
          type="select"
          name="sensorId"
          value={sensorId}
          onChange={handleChangeSensor}
          disabled={!hasOptions}
          placeholder={placeholder}
        >
          {sensorOptions}
        </Input>
      )}
      <PeriodSelector
        settings={settings}
        activeInterval={data?.period.interval}
        onChanged={handleChangePeriod}
        allowedIntervals={legacyIntervals}
      />
      <Reload onReload={refreshData} />
      {state.ready && data && (
        <ExportCsv
          name={state.title}
          fields={fields}
          items={data.readings}
          range={settings}
          location={optionalLocationToSummary(location)}
          settings={settings.table}
        />
      )}
    </CardActions>
  );

  const customSettings: CustomSettings<IGasWaterReadingsSettings> = (settings, updateSettings) => {
    const cardType = settings.cardType;
    const hasMultipleSensors = sensors.length > 1;

    const handleCardTypeChanged = (cardType: CardDisplayType) => {
      updateSettings({cardType});
    };

    const handleChangeSensor = (event: React.SyntheticEvent<HTMLInputElement>) => {
      const {value: sensorId} = event.currentTarget;
      updateSettings({sensorId: parseInt(sensorId)});
    };

    return (
      <div>
        <CardTypeSelector value={cardType} onChange={handleCardTypeChanged} />
        {hasMultipleSensors && (
          <FormGroup>
            <Label for="sensorId">{T('gasOrWaterCard.sensor.label')}</Label>
            <Input
              type="select"
              name="sensorId"
              value={sensorId}
              onChange={handleChangeSensor}
              disabled={!hasOptions}
              placeholder={placeholder}
            >
              {sensorOptions}
            </Input>
          </FormGroup>
        )}
        <ColumnChooser settings={settings.table} fields={fields} updateSettings={table => updateSettings({table})} />
      </div>
    );
  };

  const error = data && data.readings.length === 0 ? T('generic.noData') : undefined;

  return (
    <CardView
      ready={sensorsRaw !== undefined}
      actions={actions}
      customSettings={customSettings}
      error={error}
      {...cardViewProps(props)}
    >
      {data &&
        (cardType === CardDisplayType.Chart ? (
          <GasWaterReadingsChart
            items={data.readings}
            period={data.period}
            tableSettings={settings.table}
            sensor={sensor}
          />
        ) : (
          <Table
            fields={fields}
            items={data.readings}
            rowKey={rowKey}
            noun="gasValue"
            settings={settings.table}
            updateSettings={table => updateSettings({table})}
          />
        ))}
    </CardView>
  );
}

const DEFAULT_SETTINGS: IGasWaterReadingsSettings = {
  cardType: CardDisplayType.Chart,
  period: Period.DAYS_7,
  interval: Interval.HOUR,
  table: {
    pageSize: 10,
    sortColumn: 'time',
    sortOrder: SortOrder.DESCENDING,
    columns: [
      {name: 'tmp', visible: true},
      {name: 'hum', visible: true},
      {name: 'bat', visible: true}
    ]
  }
};
const CARD: ICardType<IGasWaterReadingsSettings> = {
  type: CardTypeKey.GasWaterReadings,
  title: 'gasWaterReadings.title',
  description: 'gasWaterReadings.description',
  categories: [CardCategory.GAS, CardCategory.WATER],
  rights: UserRights.User,
  isAvailable: hasPartnerFunctionality,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: GasWaterReadings
};
export default CARD;
