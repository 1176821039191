import React from 'react';

import {Button as RsButton} from '../../../components/bootstrap';
import {T} from '../../../utils/Internationalization';

interface NilmVersionFieldProps {
  value: number | undefined;
  upgrade: () => void;
  readOnly: boolean;
}
export class NilmVersionField extends React.PureComponent<NilmVersionFieldProps, {}> {
  render() {
    const {value, readOnly, upgrade} = this.props;
    if (value === undefined) return T.generic.na();

    return (
      <>
        {value}
        {value === 1 && !readOnly && (
          <RsButton withoutPadding onClick={upgrade} style={{marginLeft: '0.3em', position: 'relative', bottom: 1}}>
            {T('locationConfiguration.field.upgradeNilm')}
          </RsButton>
        )}
      </>
    );
  }
}
