import fnsDe from 'date-fns/locale/de';
import fnsFr from 'date-fns/locale/fr';
import fnsIt from 'date-fns/locale/it';
import fnsNl from 'date-fns/locale/nl';
import {registerLocale, setDefaultLocale} from 'react-datepicker';

import de from '../translations/de.json';
import en from '../translations/en.json';
import fr from '../translations/fr.json';
import internal_en from '../translations/internal.en.json';
import it from '../translations/it.json';

import nl from '../translations/nl.json';

import {autodetectLanguage} from './LanguageDetection';
import {TranslationCategory as C} from './TranslationCategory';
import {synonyms, TranslationKey} from './TranslationTerms';

registerLocale('nl', fnsNl);
registerLocale('fr', fnsFr);
registerLocale('de', fnsDe);
registerLocale('it', fnsIt);

const languages = {
  en: {...en, ...internal_en} as {[key: string]: string},
  it: {...it, ...internal_en} as {[key: string]: string},
  nl: {...nl, ...internal_en} as {[key: string]: string},
  fr: {...fr, ...internal_en} as {[key: string]: string},
  de: {...de, ...internal_en} as {[key: string]: string}
};
export type Language = keyof typeof languages;

export function hasLanguage(language: string) {
  return languages[language as Language] !== undefined;
}

export function getLanguageCodes() {
  const result = [];
  for (var key in languages) result.push(key);
  result.sort((a, b) => a.localeCompare(b));
  return result as Language[];
}

var currentLanguage: Language = 'en';

export function setCurrentLanguage(language: Language) {
  currentLanguage = language;
  setDefaultLocale(language);
}

export function getCurrentLanguage() {
  return currentLanguage;
}

for (let lang in languages) {
  const language = languages[lang as Language];
  for (let synonym in synonyms) {
    language[synonym] = getTranslation(synonyms[synonym], lang as Language);
  }
}

const formulas = {
  and: (a: string, b: string) => T('formula.a_and_b').replace('{{a}}', a).replace('{{b}}', b),
  noneOf: (item: string) => T('formula.none_of').replace('{{item}}', item),
  pageXofY: (a: number, b: number) =>
    T('formula.page_x_of_y').replace('{{a}}', a.toString()).replace('{{b}}', b.toString()),
  pageX: (a: number) => T('formula.page_x').replace('{{a}}', a.toString())
};

export const languageFormulas = formulas;

class PluralizedNoun {
  category: C;
  context?: string;
  exportSingularAs?: string;
  exportPluralAs?: string;

  constructor(category: C, context?: string, exportSingularAs?: string, exportPluralAs?: string) {
    this.category = category;
    this.context = context;
    this.exportSingularAs = exportSingularAs;
    this.exportPluralAs = exportPluralAs;
  }
}

export const pluralTerms = {
  activationCode: new PluralizedNoun(C.General),
  appliance: new PluralizedNoun(C.General),
  applianceType: new PluralizedNoun(C.General),
  automation: new PluralizedNoun(C.General),
  channel: new PluralizedNoun(C.General),
  chargingSession: new PluralizedNoun(C.EVLine),
  chargingStation: new PluralizedNoun(C.EVLine),
  chargingDisplayImage: new PluralizedNoun(C.EVLine),
  chargingStationDisplayImage: new PluralizedNoun(C.EVLine),
  chargingParkDisplayImage: new PluralizedNoun(C.EVLine),
  contract: new PluralizedNoun(C.EVLine),
  configuration: new PluralizedNoun(C.General),
  dailyMeterReading: new PluralizedNoun(C.General),
  deviceActivation: new PluralizedNoun(C.General),
  electricityValue: new PluralizedNoun(C.General),
  event: new PluralizedNoun(C.General),
  fluviusMeasurementCampaign: new PluralizedNoun(C.General),
  reading: new PluralizedNoun(C.General),
  gasValue: new PluralizedNoun(C.General),
  gasWaterSensor: new PluralizedNoun(C.General),
  hour: new PluralizedNoun(C.General),
  incident: new PluralizedNoun(C.General),
  inputModuleReading: new PluralizedNoun(C.General),
  invoice: new PluralizedNoun(C.EVLine),
  location: new PluralizedNoun(C.General),
  load: new PluralizedNoun(C.General),
  measuringSet: new PluralizedNoun(C.General),
  message: new PluralizedNoun(C.General),
  minute: new PluralizedNoun(C.General),
  module: new PluralizedNoun(C.General),
  organization: new PluralizedNoun(C.General),
  organizationUser: new PluralizedNoun(C.General),
  picture: new PluralizedNoun(C.General),
  pricingPolicy: new PluralizedNoun(C.EVLine),
  pricingGroup: new PluralizedNoun(C.EVLine),
  privilege: new PluralizedNoun(C.General, 'privilege assigned to a specific user or RFID tag'),
  property: new PluralizedNoun(C.General, 'property as in property of an object, NOT a premise or possession'),
  publicChargingToken: new PluralizedNoun(C.General, 'RFID card for public charging'),
  region: new PluralizedNoun(C.General, 'organization region (geographic subdivision of an organization)'),
  rfidCard: new PluralizedNoun(C.EVLine),
  row: new PluralizedNoun(C.General, 'generic table row'),
  searchField: new PluralizedNoun(C.General),
  sensor: new PluralizedNoun(C.General, 'used for Gas & Water sensors and input modules'),
  sensorReading: new PluralizedNoun(C.General),
  serialNumber: new PluralizedNoun(C.General),
  smartDevice: new PluralizedNoun(C.General),
  splitBillingAgreement: new PluralizedNoun(C.EVLine),
  switchLeaf: new PluralizedNoun(C.General, 'used for Smappee Switch devices'),
  switchReading: new PluralizedNoun(
    C.General,
    'used for Smappee Switch device readings',
    '{product} reading',
    '{product} readings'
  ),
  temperatureValue: new PluralizedNoun(C.EVLine),
  thirdPartyCharger: new PluralizedNoun(C.EVLine),
  user: new PluralizedNoun(C.General)
};

export type PluralKey = keyof typeof pluralTerms;

function TD(key: string) {
  return (languages[currentLanguage] || {})[key] || `$${key}`;
}

export function TDL(key: string, language: Language) {
  return (languages[language] || {})[key] || `$${key}`;
}

export function T(key: TranslationKey, args?: {[key: string]: string}) {
  let translation = (languages[currentLanguage] || {})[key] || languages.en[key] || `$${key}`;

  if (args) {
    for (const key in args) {
      translation = translation.replace(`{{${key}}}`, args[key]);
    }
  }
  return translation;
}
T.generic = {
  yes: () => T('generic.yes'),
  no: () => T('generic.no'),
  na: () => T('generic.na'),
  yesNo: (value: boolean) => (value ? T.generic.yes() : T.generic.no())
};

export function getTranslation(key: TranslationKey, language: Language) {
  return (languages[language] || {})[key];
}

export function pluralize(key: PluralKey, quantity: number): string {
  if (quantity === 1) return TD(`noun.${key}.singular`);
  else return TD(`noun.${key}.plural`);
}

export function quantity(key: PluralKey, quantity: number): string {
  if (quantity === 0) return formulas.noneOf(plural(key));

  return `${quantity} ${pluralize(key, quantity)}`;
}

export function singular(key: PluralKey): string {
  return TD(`noun.${key}.singular`);
}

export function plural(key: PluralKey): string {
  return TD(`noun.${key}.plural`);
}

export function singularForLanguage(key: PluralKey, language: Language): string {
  return TDL(`noun.${key}.singular`, language);
}

export function pluralForLanguage(key: PluralKey, language: Language): string {
  return TDL(`noun.${key}.plural`, language);
}

export function rank(index: number): string {
  // TODO: expand to other languages
  switch (getCurrentLanguage()) {
    case 'nl':
      return `${index}e`;
    default:
      if (index !== 11 && index % 10 === 1) return `${index}st`;
      else if (index !== 12 && index % 10 === 2) return `${index}nd`;
      else if (index !== 13 && index % 10 === 3) return `${index}rd`;
      else return `${index}th`;
  }
}

export function listingOf(items: string[]): string {
  if (items.length === 0) return '';
  if (items.length === 1) return items[0];

  const withCommas = items.slice(0, items.length - 1).join(', ');
  return formulas.and(withCommas, items[items.length - 1]);
}

export function getActualLanguage(language?: string) {
  let result = language === undefined || language === 'auto' ? autodetectLanguage() : language;
  if (!getLanguageCodes().includes(result as Language)) {
    result = 'en';
  }
  return result as Language;
}
