import {NotificationManager} from 'react-notifications';

import {IAuthUser} from '../models/AuthUser';
import {UserType} from '../models/User';

import {setGAField} from './GoogleAnalytics';

export function setUser(user: IAuthUser) {
  if (!window) return;

  if (user.userType === UserType.ServiceDesk && !user.isServiceDesk) {
    NotificationManager.error(
      'Could not obtain service desk user rights - you are not at an authorized service desk location'
    );
  }

  setGAField('user_id', user.userId.toString());
  localStorage.setItem('login', JSON.stringify(user));
}

export function getUser(): IAuthUser | undefined {
  if (!window) return undefined;

  const login = window.localStorage.getItem('login');
  if (!login) return undefined;

  return JSON.parse(login);
}

export function clearUser() {
  window.localStorage.removeItem('login');
}
