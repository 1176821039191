import dayjs from 'dayjs';
import numeral, {Numeral} from 'numeral';
import React, {CSSProperties} from 'react';

import 'dayjs/plugin/timezone';
import {Button as RsButton} from '../components/bootstrap';

import {formatDate} from '../components/DateFormatter';
import {SMAPPEE_ICON_MAP} from '../components/Icon';
import {Checkbox} from '../components/ui/checkbox';
import {FORMAT_EXCEL, FORMAT_TIME} from '../core/constants';
import {formatCurrency, formatCurrencyNumber} from '../utils/Currency';
import {T} from '../utils/Internationalization';
import {compareOptionalNumbers, smartFormatNumeral} from '../utils/NumberUtils';
import {getDurationMillisToHoursStr} from '../utils/TimestampUtils';
import {TranslationKey} from '../utils/TranslationTerms';

import styles from './Table.module.scss';

export type Field<T, FieldType> = {
  [P in keyof T]-?: T[P] extends FieldType ? P : never;
}[keyof T];

export const enum ExportFormat {
  CSV,
  EXCEL
}

export type TableCellRenderer<T> = (item: T) => React.ReactNode;

export interface ITableCellProps {
  key: string;
  className: string;
  style?: CSSProperties;
  title?: string;
  'data-name'?: string;
}
export interface ITableField<T> {
  name: string;
  label: string;
  options: IFieldOptions;

  renderCellContent(item: T): React.ReactNode;
  getExportCSVValue(item: T): string;
  getExportExcelValue(item: T): string | number | Date;
  sort(a: T, b: T): number; // NOTE: must be bound
}

export const enum FieldAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export interface IFieldOptions {
  sortable: boolean;
  exportable: boolean;
  unit?: string;
  unitLong?: string;
  align: FieldAlignment;
  tooltip?: string;
  group?: ColumnGroupKey;
  alwaysVisible: boolean;
  digitsAfterComma?: number;
  byline: string;
  width?: number | string;
  title?: string | JSX.Element;
  header?: (props: ITableCellProps) => JSX.Element;
  autoInsert: 'start' | 'end';
  cellClassName?: string;
  follows?: string;
  format?: string;
  noWrap: boolean;
  visibleByDefault?: boolean;
  overflow?: boolean;
}

export const DEFAULT_OPTIONS: IFieldOptions = {
  sortable: true,
  exportable: true,
  unit: undefined,
  align: FieldAlignment.Left,
  alwaysVisible: false,
  byline: '',
  autoInsert: 'end',
  noWrap: true
};

export const enum ColumnGroupKey {
  ChannelsActive = 'channels.active',
  ChannelsReactive = 'channels.reactive',
  ChannelsPowerFactor = 'channels.powerFactor',
  ChannelsApparent = 'channels.apparent',
  ChannelsCurrent = 'channels.current',
  LoadsActive = 'loads.active',
  LoadsReactive = 'loads.reactive',
  LoadsApparent = 'loads.apparent',
  LoadsCurrent = 'loads.current',
  PhaseVoltage = 'phase.voltage',
  LineVoltage = 'line.voltage'
}

export interface IGroupDefinition {
  key: ColumnGroupKey;
  translationKey: TranslationKey;
}
const COLUMNGROUPS: IGroupDefinition[] = [
  {
    key: ColumnGroupKey.ChannelsActive,
    translationKey: 'electricityValues.group.channels.active'
  },
  {
    key: ColumnGroupKey.ChannelsReactive,
    translationKey: 'electricityValues.group.channels.reactive'
  },
  {
    key: ColumnGroupKey.ChannelsPowerFactor,
    translationKey: 'electricityValues.group.channels.powerFactor'
  },
  {
    key: ColumnGroupKey.ChannelsApparent,
    translationKey: 'electricityValues.group.channels.apparent'
  },
  {
    key: ColumnGroupKey.ChannelsCurrent,
    translationKey: 'electricityValues.group.channels.current'
  },
  {
    key: ColumnGroupKey.LoadsActive,
    translationKey: 'electricityValues.group.loads.active'
  },
  {
    key: ColumnGroupKey.LoadsReactive,
    translationKey: 'electricityValues.group.loads.reactive'
  },
  {
    key: ColumnGroupKey.LoadsApparent,
    translationKey: 'electricityValues.group.loads.apparent'
  },
  {
    key: ColumnGroupKey.LoadsCurrent,
    translationKey: 'electricityValues.group.loads.current'
  },
  {
    key: ColumnGroupKey.PhaseVoltage,
    translationKey: 'electricityValues.group.phase.voltage'
  },
  {
    key: ColumnGroupKey.LineVoltage,
    translationKey: 'electricityValues.group.line.voltage'
  }
];
const COLUMNGROUPS_BY_KEY: Map<string, IGroupDefinition> = COLUMNGROUPS.reduce((result, group) => {
  result.set(group.key, group);
  return result;
}, new Map<string, IGroupDefinition>());

export function getGroup(key: ColumnGroupKey): IGroupDefinition | undefined {
  return COLUMNGROUPS_BY_KEY.get(key);
}

const NO_WRAP: CSSProperties = {whiteSpace: 'nowrap'};

export class StringField<T> implements ITableField<T> {
  name: string;
  label: string;
  property: Field<T, string | undefined>;
  options: IFieldOptions;

  constructor(property: Field<T, string | undefined> & string, label: string, options: Partial<IFieldOptions> = {}) {
    this.name = property;
    this.label = label;
    this.property = property;
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  renderCellContent(item: T) {
    return this.getValue(item);
  }

  getExportCSVValue(item: T): string {
    return this.getValue(item) || '';
  }

  getExportExcelValue(item: T): string {
    return this.getValue(item) || '';
  }

  getValue(item: T): string | undefined {
    // TypeScript isn't *that* smart that it knows that property always refers to a string
    return item[this.property] as unknown as string | undefined;
  }

  sort = (a: T, b: T): number => {
    const aValue = this.getValue(a);
    const bValue = this.getValue(b);
    if (aValue === bValue) return 0;
    if (aValue === undefined) return -1;
    if (bValue === undefined) return 1;
    return aValue.localeCompare(bValue);
  };
}

export class CalculatedStringField<T> implements ITableField<T> {
  name: string;
  label: string;
  value: (item: T) => string | undefined;
  options: IFieldOptions;

  constructor(
    name: string,
    label: string,
    value: (item: T) => string | undefined,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.value = value;
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  renderCellContent(item: T) {
    return this.value(item);
  }

  getExportCSVValue(item: T) {
    return this.value(item) || '';
  }

  getExportExcelValue(item: T) {
    return this.value(item) || '';
  }

  sort = (a: T, b: T): number => {
    const aValue = this.value(a) || '';
    const bValue = this.value(b) || '';
    return aValue.localeCompare(bValue);
  };
}

export class CalculatedDurationField<T> implements ITableField<T> {
  name: string;
  label: string;
  getter: (item: T) => number | string | undefined;
  options: IFieldOptions;
  chargingStatus: (item: T) => string;

  constructor(
    name: string,
    label: string,
    getter: (item: T) => number | string | undefined,
    options: Partial<IFieldOptions> = {},
    chargingStatus: (item: T) => string
  ) {
    this.name = name;
    this.label = label;
    this.getter = getter;
    this.options = {
      ...DEFAULT_OPTIONS,
      format: FORMAT_TIME,
      ...options
    };
    this.chargingStatus = chargingStatus;
  }

  renderCellContent(item: T) {
    const timestampValue = this.getter(item);
    const isNumber = typeof timestampValue == 'number';
    if (timestampValue === undefined) return undefined;
    const toRenderValue = isNumber ? getDurationMillisToHoursStr(timestampValue) : timestampValue;
    return toRenderValue;
  }

  getExportCSVValue(item: T) {
    const numberValue = this.getter(item);
    if (numberValue === undefined) return '';

    return `${formatDate(numberValue, {format: FORMAT_TIME})} ${this.chargingStatus(item)}`;
  }

  getExportExcelValue(item: T) {
    return this.getExportCSVValue(item);
  }

  sort = (a: T, b: T): number => {
    const aValue = this.getter(a);
    const bValue = this.getter(b);
    if (aValue === bValue) return 0;
    if (aValue === undefined) return -1;
    if (bValue === undefined) return 1;
    return +aValue - +bValue;
  };
}

export class CurrencyField<T> implements ITableField<T> {
  name: string;
  label: string;
  property: Field<T, number | undefined>;
  currency: (item: T) => string;
  options: IFieldOptions;

  constructor(
    property: Field<T, number | undefined> & string,
    label: string,
    currency: (item: T) => string,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = property;
    this.label = label;
    this.property = property;
    this.currency = currency;
    this.options = {
      ...DEFAULT_OPTIONS,
      align: FieldAlignment.Right,
      ...options
    };
  }

  renderCellContent(item: T) {
    const value = item[this.property] as unknown as number | undefined;
    return value === undefined ? undefined : formatCurrency(this.currency(item), value);
  }

  getExportCSVValue(item: T) {
    const value = item[this.property] as unknown as number | undefined;
    return value === undefined ? '' : formatCurrencyNumber(this.currency(item), value);
  }

  getExportExcelValue(item: T) {
    const value = item[this.property] as unknown as number | undefined;
    return value === undefined ? '' : value;
  }

  sort = (a: T, b: T): number => {
    const aValue = a[this.property] as unknown as number | undefined;
    const bValue = b[this.property] as unknown as number | undefined;
    if (aValue === bValue) return 0;
    if (aValue === undefined) return -1;
    if (bValue === undefined) return 1;

    return aValue - bValue;
  };
}

export class CalculatedCurrencyField<T> implements ITableField<T> {
  name: string;
  label: string;
  value: (item: T) => number | undefined;
  currency: (item: T) => string;
  options: IFieldOptions;

  constructor(
    name: string,
    label: string,
    value: (item: T) => number | undefined,
    currency: (item: T) => string,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.value = value;
    this.currency = currency;
    this.options = {
      ...DEFAULT_OPTIONS,
      align: FieldAlignment.Right,
      ...options
    };
  }

  renderCellContent(item: T) {
    const value = this.value(item);
    return value === undefined ? undefined : formatCurrency(this.currency(item), value);
  }

  getExportCSVValue(item: T) {
    const value = this.value(item);
    return value === undefined ? '' : formatCurrencyNumber(this.currency(item), value);
  }

  getExportExcelValue(item: T) {
    const value = this.value(item);
    return value === undefined ? '' : value;
  }

  sort = (a: T, b: T): number => {
    const aValue = this.value(a);
    const bValue = this.value(b);
    if (aValue === bValue) return 0;
    if (aValue === undefined) return -1;
    if (bValue === undefined) return 1;

    return aValue - bValue;
  };
}

export class URLField<T> implements ITableField<T> {
  name: string;
  label: string;
  property: Field<T, string | undefined>;
  options: IFieldOptions;
  urlLabel?: string;

  constructor(
    property: Field<T, string | undefined> & string,
    label: string,
    options: Partial<IFieldOptions> = {},
    urlLabel?: string
  ) {
    this.name = property;
    this.label = label;
    this.property = property;
    this.options = {...DEFAULT_OPTIONS, ...options};
    this.urlLabel = urlLabel;
  }

  renderCellContent(item: T) {
    const value = item[this.property] as unknown as string;
    if (!value) return undefined;

    // Remove HTTP suffix
    const display = this.urlLabel || value.replace(/^(https?:)\/\//, '');
    const ExternalLinkIcon = SMAPPEE_ICON_MAP.ExternalLink;
    return (
      <RsButton
        href={value}
        target="_blank"
        color="link"
        withoutPadding
        style={NO_WRAP}
        className="!tw-flex !tw-items-center !tw-rounded-none"
      >
        {display} <span className="tw-ml-2 tw-inline-flex tw-items-center">{ExternalLinkIcon}</span>
      </RsButton>
    );
  }

  getExportCSVValue(item: T) {
    return item[this.property] as unknown as string;
  }

  getExportExcelValue(item: T) {
    return item[this.property] as unknown as string;
  }

  sort = (a: T, b: T) => {
    const aValue = a[this.property] as unknown as string;
    const bValue = b[this.property] as unknown as string;
    if (aValue === bValue) return 0;
    if (aValue === undefined) return -1;
    if (bValue === undefined) return 1;

    return aValue.localeCompare(bValue);
  };
}

export class IntegerField<T> implements ITableField<T> {
  name: string;
  label: string;
  property: Field<T, number | undefined>;
  options: IFieldOptions;

  constructor(property: Field<T, number | undefined> & string, label: string, options: Partial<IFieldOptions> = {}) {
    this.name = property;
    this.label = label;
    this.property = property;
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  renderCellContent(item: T) {
    const value = this.getValue(item);
    return value === undefined ? undefined : value.format();
  }

  getExportCSVValue(item: T) {
    const value = this.getValue(item);
    return value === undefined ? '' : value.format('0,0[.][000]');
  }

  getExportExcelValue(item: T) {
    const value = item[this.property] as unknown as number | undefined;
    return value === undefined ? '' : value;
  }

  getValue(item: T): Numeral | undefined {
    const value = item[this.property] as unknown as number | undefined;
    return value === undefined ? undefined : numeral(value);
  }

  sort = (a: T, b: T): number => {
    const aValue = a[this.property] as unknown as number;
    const bValue = b[this.property] as unknown as number;
    return aValue - bValue;
  };
}

export class CalculatedIntegerField<T> implements ITableField<T> {
  name: string;
  label: string;
  value: (item: T) => number | undefined;
  options: IFieldOptions;

  constructor(
    name: string,
    label: string,
    value: (item: T) => number | undefined,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.value = value;
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  renderCellContent(item: T) {
    return this.value(item)?.toString();
  }

  getExportCSVValue(item: T) {
    return this.value(item)?.toString() || '';
  }

  getExportExcelValue(item: T) {
    return this.value(item) || '';
  }

  sort = (a: T, b: T): number => {
    return (this.value(a) || 0) - (this.value(b) || 0);
  };
}

function getNumberFormatString(digitsAfterComma: number | undefined) {
  if (digitsAfterComma === undefined) return undefined;
  if (digitsAfterComma === 0) return '0';

  return `0.${'0'.repeat(digitsAfterComma)}`;
}

export class NumberField<T> implements ITableField<T> {
  name: string;
  label: string;
  property: Field<T, number | null | undefined>;
  options: IFieldOptions;
  formatString: string | undefined;
  suffix: string;

  constructor(
    property: Field<T, number | null | undefined> & string,
    label: string,
    options: Partial<IFieldOptions> = {},
    suffix?: string
  ) {
    this.name = property;
    this.label = label;
    this.property = property;
    this.options = {
      ...DEFAULT_OPTIONS,
      align: FieldAlignment.Right,
      ...options
    };
    this.formatString = getNumberFormatString(this.options.digitsAfterComma);
    this.suffix = suffix ? ` ${suffix}` : '';
  }

  renderCellContent(item: T) {
    const value = this.getFormattedValue(item);
    return value === undefined ? value : value + this.suffix;
  }

  getFormattedValue(item: T) {
    const value = item[this.property] as unknown as number | null | undefined;
    if (value === null || value === undefined) return undefined;

    if (this.formatString === undefined) {
      return smartFormatNumeral(value);
    } else {
      return numeral(value).format(this.formatString);
    }
  }

  getExportCSVValue(item: T) {
    const value = item[this.property] as unknown as number | null | undefined;
    return numeral(value).format('0,0[.][000]');
  }

  getExportExcelValue(item: T) {
    return item[this.property] as unknown as number;
  }

  sort = (a: T, b: T): number => {
    const valueA = a[this.property] as unknown as number | null | undefined;
    const valueB = b[this.property] as unknown as number | null | undefined;
    return compareOptionalNumbers(valueA, valueB);
  };
}

export class CalculatedNumberField<T> implements ITableField<T> {
  name: string;
  label: string;
  value: (item: T) => number | undefined;
  options: IFieldOptions;
  formatString: string | undefined;

  constructor(
    name: string,
    label: string,
    value: (item: T) => number | undefined,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.value = value;
    this.options = {
      ...DEFAULT_OPTIONS,
      align: FieldAlignment.Right,
      ...options
    };
    this.formatString = getNumberFormatString(this.options.digitsAfterComma);
  }

  renderCellContent(item: T) {
    const value = this.value(item);
    return value === undefined ? undefined : this.format(value);
  }

  format(value: number) {
    if (this.formatString === undefined) {
      return smartFormatNumeral(value);
    } else {
      return numeral(value).format(this.formatString);
    }
  }

  getExportCSVValue(item: T) {
    const value = this.value(item);
    return numeral(value).format('0,0[.][000]');
  }

  getExportExcelValue(item: T) {
    const value = this.value(item);
    return value === undefined ? '' : value;
  }

  sort = (a: T, b: T): number => {
    const aValue = this.value(a);
    const bValue = this.value(b);
    return compareOptionalNumbers(aValue, bValue);
  };
}

export class BooleanField<T> implements ITableField<T> {
  name: Field<T, boolean | undefined> & string;
  label: string;
  options: IFieldOptions;

  constructor(name: Field<T, boolean | undefined> & string, label: string, options: Partial<IFieldOptions> = {}) {
    this.name = name;
    this.label = label;
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  renderCellContent(item: T) {
    const value = item[this.name] as unknown as boolean | undefined;
    return value === undefined ? undefined : T.generic.yesNo(value);
  }

  getExportCSVValue(item: T) {
    const value = item[this.name] as unknown as boolean | undefined;
    return value === undefined ? '' : T.generic.yesNo(value);
  }

  getExportExcelValue(item: T) {
    const value = item[this.name] as unknown as boolean | undefined;
    return value === undefined ? '' : T.generic.yesNo(value);
  }

  sort = (a: T, b: T): number => {
    const aValue = a[this.name] as unknown as boolean | undefined;
    const bValue = b[this.name] as unknown as boolean | undefined;
    if (aValue === bValue) return 0;
    if (aValue === undefined) return 1;
    if (bValue === undefined) return -1;
    if (aValue === false) return 1;
    if (bValue === false) return -1;
    return aValue ? 1 : -1;
  };
}

export class TimestampField<T> implements ITableField<T> {
  name: Field<T, number | undefined> & string;
  label: string;
  options: IFieldOptions;

  constructor(name: Field<T, number | undefined> & string, label: string, options: Partial<IFieldOptions> = {}) {
    this.name = name;
    this.label = label;
    this.options = {
      ...DEFAULT_OPTIONS,
      format: 'L LTS',
      ...options
    };
  }

  renderCellContent(item: T) {
    const rawValue = item[this.name] as unknown as number | undefined;
    if (rawValue === undefined) return undefined;

    const value = dayjs(rawValue);
    return value.format(this.options.format);
  }

  getExportCSVValue(item: T) {
    const numberValue = item[this.name] as unknown as number | undefined;
    if (numberValue === undefined) return '';

    const value = dayjs(numberValue);
    return value.format(FORMAT_EXCEL);
  }

  getExportExcelValue(item: T) {
    return this.getExportCSVValue(item);
  }

  sort = (a: T, b: T): number => {
    return (
      ((a[this.name] as unknown as number | undefined) || 0) - ((b[this.name] as unknown as number | undefined) || 0)
    );
  };
}

export class TimestampCalculatedField<T> implements ITableField<T> {
  name: string;
  label: string;
  options: IFieldOptions;
  getter: (item: T) => number | undefined;

  constructor(
    name: string,
    label: string,
    getter: (item: T) => number | undefined,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.getter = getter;
    this.options = {
      ...DEFAULT_OPTIONS,
      format: 'L LTS',
      ...options
    };
  }

  renderCellContent(item: T) {
    const timestampValue = this.getter(item);
    if (timestampValue === undefined) return undefined;

    return formatDate(timestampValue, {format: this.options.format});
  }

  getExportCSVValue(item: T) {
    const numberValue = this.getter(item);
    if (numberValue === undefined) return '';

    return formatDate(numberValue, {format: FORMAT_EXCEL});
  }

  getExportExcelValue(item: T) {
    return this.getExportCSVValue(item);
  }

  sort = (a: T, b: T): number => {
    const aValue = this.getter(a) || Number.POSITIVE_INFINITY;
    const bValue = this.getter(b) || Number.POSITIVE_INFINITY;
    return aValue - bValue;
  };
}

export class DateCalculatedField<T> implements ITableField<T> {
  name: string;
  label: string;
  options: IFieldOptions;
  intl: string;
  getter: (item: T) => number | undefined;

  constructor(
    name: string,
    label: string,
    intl = '',
    getter: (item: T) => number | undefined,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.getter = getter;
    this.options = {
      ...DEFAULT_OPTIONS,
      format: 'L',
      noWrap: true,
      ...options
    };
    this.intl = intl;
  }

  renderCellContent(item: T) {
    const timestampValue = this.getter(item);
    if (timestampValue === undefined) return undefined;
    if (this.intl !== '' && this.intl.length == 5) {
      const result = new Date(timestampValue).toLocaleDateString(this.intl, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      return result;
    }
    const value = dayjs(timestampValue);
    return value.format(this.options.format);
  }

  getExportCSVValue(item: T) {
    const numberValue = this.getter(item);
    if (numberValue === undefined) return '';

    const value = dayjs(numberValue);
    return value.format('L');
  }

  getExportExcelValue(item: T) {
    return this.getExportCSVValue(item);
  }

  sort = (a: T, b: T): number => {
    const aValue = this.getter(a) || Number.POSITIVE_INFINITY;
    const bValue = this.getter(b) || Number.POSITIVE_INFINITY;
    return aValue - bValue;
  };
}

export class TimestampFieldWithTimezone<T> implements ITableField<T> {
  name: string;
  field: keyof T;
  label: string;
  options: IFieldOptions;
  timeZoneId: string;

  constructor(name: string, field: keyof T, label: string, timeZoneId: string, options: Partial<IFieldOptions> = {}) {
    this.name = name;
    this.field = field;
    this.label = label;
    this.options = {...DEFAULT_OPTIONS, noWrap: true, ...options};
    this.timeZoneId = timeZoneId;
  }

  renderCellContent(item: T) {
    const value = dayjs(item[this.field] as unknown as number);
    return value.tz(this.timeZoneId).format('L LTS');
  }

  getExportCSVValue(item: T) {
    const value = dayjs(item[this.field] as unknown as number);
    return value.tz(this.timeZoneId).format(FORMAT_EXCEL);
  }

  getExportExcelValue(item: T) {
    const value = dayjs(item[this.field] as unknown as number);
    return value.tz(this.timeZoneId).format(FORMAT_EXCEL);
  }

  sort = (a: T, b: T): number => {
    return (a[this.field] as unknown as number) - (b[this.field] as unknown as number);
  };
}

export class ComponentField<T> implements ITableField<T> {
  name: string;
  label: string;
  renderer: TableCellRenderer<T>;
  options: IFieldOptions;

  constructor(name: string, label: string, renderer: TableCellRenderer<T>, options: Partial<IFieldOptions> = {}) {
    this.name = name;
    this.label = label;
    this.renderer = renderer;
    this.options = {
      ...DEFAULT_OPTIONS,
      exportable: false,
      sortable: false,
      alwaysVisible: true,
      ...options
    };
  }

  renderCellContent(item: T) {
    return this.renderer(item);
  }

  getExportCSVValue(item: T) {
    return '';
  }

  getExportExcelValue(item: T) {
    return '';
  }

  sort = (a: T, b: T): number => 0;
}

export class CheckboxField<T> implements ITableField<T> {
  name: string;
  label: string;
  isChecked: (row: T) => boolean;
  onCheck: (row: T, checked: boolean) => void;
  options: IFieldOptions;

  constructor(
    name: string,
    label: string,
    isChecked: (row: T) => boolean,
    onCheck: (row: T, checked: boolean) => void,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.isChecked = isChecked;
    this.onCheck = onCheck;
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options,
      exportable: false,
      sortable: false,
      alwaysVisible: true
    };
  }

  renderCellContent(item: T) {
    return (
      <div className={styles.checkboxCell}>
        <Checkbox wrapperClassName="tw-ml-0 !tw-mb-0" onCheckedChange={checked => this.onCheck(item, checked)} />
      </div>
    );
  }

  getExportCSVValue(item: T) {
    return '';
  }

  getExportExcelValue(item: T) {
    return '';
  }

  sort = (a: T, b: T) => 0;
}

export class CheckboxField2<T> implements ITableField<T> {
  name: string;
  label: string;
  isChecked: (row: T) => boolean;
  onCheck: (row: T, checked: boolean) => void;
  options: IFieldOptions;

  constructor(
    name: string,
    label: string,
    isChecked: (row: T) => boolean,
    onCheck: (row: T, checked: boolean) => void,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.isChecked = isChecked;
    this.onCheck = onCheck;
    this.options = {
      ...DEFAULT_OPTIONS,
      exportable: false,
      sortable: false,
      alwaysVisible: true,
      ...options
    };
  }

  renderCellContent(item: T) {
    return (
      <div className={styles.checkboxCell}>
        <Checkbox
          wrapperClassName="tw-ml-0 !tw-mb-0"
          checked={this.isChecked(item)}
          onCheckedChange={checked => this.onCheck(item, checked)}
        />
      </div>
    );
  }

  getExportCSVValue(item: T) {
    return T.generic.yesNo(this.isChecked(item));
  }

  getExportExcelValue(item: T) {
    return T.generic.yesNo(this.isChecked(item));
  }

  sort = (a: T, b: T) => 0;
}
