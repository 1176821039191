import React, {CSSProperties} from 'react';

export interface IFlexProps {
  justifyContent: string;
  alignItems: string;
  className?: string;
  style?: CSSProperties;
}

export class Flex extends React.Component<IFlexProps, {}> {
  static defaultProps = {
    // Justify content values: start, end, center, between, around
    justifyContent: 'between',
    // Align items values: start, end, center, baseline, stretch
    alignItems: 'center'
  };

  render() {
    let {justifyContent, alignItems, className, ...props} = this.props;

    // Hook extra classes into the component
    className = [
      'd-flex',
      `justify-content-${justifyContent}`,
      `align-items-${alignItems}`,
      className ? className : ''
    ].join(' ');

    return (
      <div className={className} {...props}>
        {this.props.children}
      </div>
    );
  }
}
