import {None} from '../utils/Arrays';
import {T} from '../utils/Internationalization';

import {TranslationKey} from '../utils/TranslationTerms';

import {Channel, IChannelSensor, IChannelUpdates, ProductionExport, CircuitType} from './Channel';
import {CTType} from './CTType';
import {Phase} from './Phase';

export function getLoadName(load: ILoad) {
  return load.name === undefined ? T('defaultNames.gridLoad') : load.name.trim();
}

export const NoLoads: ILoad[] = [];

export const enum LoadSource {
  CT = 'CT',
  MID = 'MID'
}

export interface ILoad {
  id: number;
  serviceLocationId?: number;
  name?: string; // absent for grids
  type: LoadType;
  sourceType?: LoadSource;
  numberOfPhasesUsed: number;
  parentId?: number; // complex overload protection
  maximumAmpere?: number;
  overloadEnabled?: boolean;
  overloadMaxAmpere?: number;
  actuals: ILoadChannel[];
  production?: IProduction;
  storage?: IStorage;
  appliance?: IAppliance;
  subcircuit?: ICircuit;
  updateChannels?: ILoadUpdateChannels; // can be absent if under construction
}

export interface ILoadChannel {
  id: number;
  publishIndex: number;
  underConstruction: boolean;
  serviceLocationId?: number;
  name?: string; // absent for grids
  type: LoadType;
  phase: Phase;
  ctType: CTType;
  export?: ProductionExport;
  category?: string;
  circuitType?: CircuitType;
  sensor?: IChannelSensor;
  updateChannels?: ILoadUpdateChannels; // can be absent if under construction
  midBusAddress?: number; // corresponds to connector position, for charging station mid meters
}

export interface ILoadUpdateChannels {
  activePower: ILoadUpdateChannel;
  reactivePower: ILoadUpdateChannel;
  current: ILoadUpdateChannel;
  lineVoltage: ILoadUpdateChannel;
  phaseVoltage: ILoadUpdateChannel;
}

export const enum ChannelProtocol {
  MQTT = 'MQTT',
  Websockets = 'WEBSOCKET'
}

export const enum UpdateChannelAggregation {
  Sum = 'SUM',
  ForEach = 'FOR_EACH'
}

export interface ILoadUpdateChannel {
  protocol?: ChannelProtocol;
  name: string;
  aggregation?: UpdateChannelAggregation;
  aspectPaths?: IChannelAspectPath[];
  userName?: string;
  password?: string;
}

export interface IChannelAspectPath {
  path: string;
  multiplier: number;
}

export interface ILoadUpdates {
  name?: string;
  channels?: Channel[];
  measuredByGrid?: boolean;
  applianceType?: string;
  circuitType?: CircuitType;
  withHybridInverter?: boolean;
}

export interface ILoadUpdateResult {
  name?: string;
  type?: LoadType;
  production?: IProduction;
  storage?: IStorage;
  appliance?: IAppliance;
  subcircuit?: ICircuit;
  maximumAmpere?: number; // set to -1
  overloadEnabled?: boolean;
  overloadMaxAmpere?: number;
  parentId?: number | null;
}

interface IProduction {
  export: ProductionExport;
  withHybridInverter?: boolean;
}

interface IStorage {
  alreadyMeasuredByGrid: boolean;
  optimizeStateOfCharge?: boolean;
  manufacturer?: number;
  deviceType?: number;
  ipAddress?: string;
  ipPort?: number;
}

interface IAppliance {
  alreadyMeasuredByGrid: boolean;
  type: string;
}

interface ICircuit {
  type: CircuitType;
  alreadyMeasuredByGrid: boolean;
}

export const enum LoadType {
  Grid = 'GRID',
  Production = 'PRODUCTION',
  Storage = 'STORAGE',
  Appliance = 'APPLIANCE',
  Subcircuit = 'SUBCIRCUIT',
  VirtualGrid = 'VIRTUAL_GRID'
}

export const LOAD_TYPES = [
  LoadType.Grid,
  LoadType.Production,
  LoadType.Storage,
  LoadType.Appliance,
  LoadType.Subcircuit
];

export const LOAD_TYPES_WITHOUT_GRID = [LoadType.Production, LoadType.Storage, LoadType.Appliance, LoadType.Subcircuit];

const LOAD_LABELS: {[key: string]: TranslationKey} = {
  [LoadType.Grid]: 'loadType.grid',
  [LoadType.Production]: 'loadType.production',
  [LoadType.Storage]: 'loadType.storage',
  [LoadType.Appliance]: 'loadType.appliance',
  [LoadType.Subcircuit]: 'loadType.circuit'
};
export function getLoadTypeLabel(type: LoadType) {
  return T(LOAD_LABELS[type]);
}

export interface ILoadCreationSpec {
  name: string;
  type: LoadType;
  sourceType: LoadSource;
  numberOfPhasesUsed: number;
  maximumAmpere: number;
  production?: IProduction;
  storage?: IStorage;
  appliance?: IAppliance;
  subcircuit?: ICircuit;
}

export class Load {
  static fromJSON(data: ILoad) {
    const {name, type, id, sourceType = LoadSource.CT, actuals = None} = data;
    const channels = actuals.map(actual => new Channel(id, actual));
    switch (type) {
      case LoadType.Grid:
      case LoadType.VirtualGrid:
        return new GridLoad(id, name || T('defaultNames.gridLoad'), type, channels, sourceType);
      case LoadType.Production:
        return new ProductionLoad(id, name || '', type, channels, sourceType, data.production);
      case LoadType.Storage:
        return new StorageLoad(id, name || '', type, channels, sourceType, data.storage);
      case LoadType.Appliance:
        return new ApplianceLoad(id, name || '', type, channels, sourceType, data.appliance);
      case LoadType.Subcircuit:
        return new CircuitLoad(id, name || '', type, channels, sourceType, data.subcircuit);
      default:
        console.error('Deserializing unknown type of Load:', type, data);
        return new Load(id, name || '', type, [], sourceType);
    }
  }

  static create(id: number, source: LoadSource) {
    return new CircuitLoad(id, '', LoadType.Subcircuit, [], source);
  }

  id: number;
  name: string;
  type: LoadType;
  channels: Channel[];
  source: LoadSource;
  midAddress?: number;

  constructor(id: number, name: string, type: LoadType, channels: Channel[], source: LoadSource) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.channels = channels || [];
    this.source = source;
  }

  addChannel(channel: Channel): void {
    this.channels.push(channel);
  }

  isGrid(): boolean {
    return false;
  }

  isMeasuredByGrid(): boolean {
    return false;
  }

  updateWith(updates: ILoadUpdates): Load {
    return new Load(
      this.id,
      updates.name === undefined ? this.name : updates.name,
      this.type,
      updates.channels || this.channels,
      this.source
    );
  }

  updateChannel(channel: Channel): Channel {
    if (channel.name === this.name) return channel;

    return channel.updateWith({name: this.name});
  }

  updateChannelWith(id: number, updates: IChannelUpdates): Load {
    const newChannels = this.channels.map(channel => (channel.id === id ? channel.updateWith(updates) : channel));
    return this.updateWith({channels: newChannels});
  }

  getChannel(id: number): Channel | undefined {
    return this.channels.find(channel => channel.id === id);
  }

  getUpdates(): ILoadUpdateResult {
    return {
      name: this.name,
      type: this.type
    };
  }
}

class GridLoad extends Load {
  isGrid(): boolean {
    return true;
  }

  updateWith(updates: ILoadUpdates): GridLoad {
    return new GridLoad(
      this.id,
      updates.name === undefined ? this.name : updates.name,
      this.type,
      updates.channels || this.channels,
      this.source
    );
  }
}

export class ProductionLoad extends Load {
  production: IProduction;

  constructor(
    id: number,
    name: string,
    type: LoadType,
    channels: Array<Channel>,
    source: LoadSource,
    production?: IProduction
  ) {
    super(id, name, type, channels, source);

    this.production = production || {export: ProductionExport.All};
  }

  isMeasuredByGrid() {
    return this.production.export !== ProductionExport.All;
  }

  updateWith(updates: ILoadUpdates): ProductionLoad {
    let production = {
      ...this.production,
      withHybridInverter:
        updates.withHybridInverter === undefined ? this.production.withHybridInverter : updates.withHybridInverter
    };

    if (updates.measuredByGrid !== undefined) {
      production = {
        ...production,
        export: updates.measuredByGrid ? ProductionExport.Surplus : ProductionExport.All
      };
    }

    return new ProductionLoad(
      this.id,
      updates.name === undefined ? this.name : updates.name,
      this.type,
      updates.channels || this.channels,
      this.source,
      production
    );
  }

  updateChannel(actual: Channel) {
    actual = super.updateChannel(actual);
    if (actual.export === this.production.export) return actual;

    return actual.updateWith({export: this.production.export});
  }

  getUpdates() {
    const result = super.getUpdates();
    result.production = this.production;
    return result;
  }
}

export class StorageLoad extends Load {
  storage: IStorage;

  constructor(
    id: number,
    name: string,
    type: LoadType,
    channels: Array<Channel>,
    sourceType: LoadSource,
    storage?: IStorage
  ) {
    super(id, name, type, channels, sourceType);

    this.storage = storage || {
      alreadyMeasuredByGrid: true,
      manufacturer: 1,
      deviceType: 1
    };
  }

  isMeasuredByGrid() {
    return this.storage.alreadyMeasuredByGrid === true;
  }

  updateWith(updates: ILoadUpdates) {
    return new StorageLoad(
      this.id,
      updates.name === undefined ? this.name : updates.name,
      this.type,
      updates.channels || this.channels,
      this.source,
      this.storage
    );
  }

  getUpdates(): ILoadUpdateResult {
    const result = super.getUpdates();
    result.storage = this.storage;
    return result;
  }
}

export class ApplianceLoad extends Load {
  appliance: IAppliance;

  constructor(
    id: number,
    name: string,
    type: LoadType,
    channels: Array<Channel>,
    sourceType: LoadSource,
    appliance?: IAppliance
  ) {
    super(id, name, type, channels, sourceType);

    this.appliance = appliance || {alreadyMeasuredByGrid: true, type: 'TODO'};
  }

  isMeasuredByGrid(): boolean {
    return this.appliance.alreadyMeasuredByGrid;
  }

  updateWith(updates: ILoadUpdates): ApplianceLoad {
    let appliance = this.appliance;
    if (updates.measuredByGrid !== undefined) {
      appliance.alreadyMeasuredByGrid = updates.measuredByGrid;
    }
    if (updates.applianceType !== undefined) {
      appliance.type = updates.applianceType;
    }

    let channels = updates.channels || this.channels;
    if (updates.applianceType !== this.appliance.type) {
      channels = channels.map(channel => channel.updateWith({category: updates.applianceType}));
    }

    return new ApplianceLoad(
      this.id,
      updates.name === undefined ? this.name : updates.name,
      this.type,
      channels || this.channels,
      this.source,
      appliance
    );
  }

  updateChannel(channel: Channel): Channel {
    channel = super.updateChannel(channel);
    if (channel.category === this.appliance.type) return channel;

    return channel.updateWith({category: this.appliance.type});
  }

  getUpdates(): ILoadUpdateResult {
    const result = super.getUpdates();
    result.appliance = this.appliance;
    return result;
  }
}

export class CircuitLoad extends Load {
  subcircuit: ICircuit;

  constructor(
    id: number,
    name: string,
    type: LoadType,
    channels: Array<Channel>,
    sourceType: LoadSource,
    subcircuit?: ICircuit
  ) {
    super(id, name, type, channels, sourceType);

    this.subcircuit = subcircuit || {
      type: CircuitType.Outlets,
      alreadyMeasuredByGrid: true
    };
  }

  isMeasuredByGrid(): boolean {
    return this.subcircuit.alreadyMeasuredByGrid;
  }

  updateWith(updates: ILoadUpdates): CircuitLoad {
    let subcircuit = this.subcircuit;
    if (updates.measuredByGrid !== undefined) {
      subcircuit.alreadyMeasuredByGrid = updates.measuredByGrid;
    }
    if (updates.circuitType !== undefined) {
      subcircuit.type = updates.circuitType;
    }

    return new CircuitLoad(
      this.id,
      updates.name === undefined ? this.name : updates.name,
      this.type,
      updates.channels || this.channels,
      this.source,
      subcircuit
    );
  }

  updateChannel(channel: Channel): Channel {
    channel = super.updateChannel(channel);
    if (this.subcircuit.type === channel.circuitType) return channel;

    return channel.updateWith({circuitType: this.subcircuit.type});
  }

  getUpdates(): ILoadUpdateResult {
    const result = super.getUpdates();
    result.subcircuit = this.subcircuit;
    return result;
  }
}
