import React from 'react';

import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {useModals} from '../../../modals/ModalContext';
import {IActivationCode, IOrganizationActivationCode} from '../../../models/ActivationCode';
import {T} from '../../../utils/Internationalization';

import MoveToOrganizationModal from './MoveToOrganizationModal';

interface OrganizationFieldProps {
  value: IActivationCode | undefined;
  update: (value: IOrganizationActivationCode) => Promise<unknown>;
  readOnly: boolean;
  displayedValue?: string;
}

export function OrganizationField(props: OrganizationFieldProps) {
  const {value, update, readOnly, displayedValue} = props;
  const modals = useModals();
  const handleClickedEdit = async () => {
    if (!value) return;

    const activationCode = await modals.show<IOrganizationActivationCode | undefined>(props => (
      <MoveToOrganizationModal activationCode={value} {...props} />
    ));
    if (!activationCode) return;

    update(activationCode);
  };

  const displayValue = displayedValue || (value === undefined ? T.generic.na() : value.name || value.code);

  return (
    <span>
      {displayValue}{' '}
      {!readOnly && (
        <Button variant="ghost_action_btn" size="icon" onClick={handleClickedEdit}>
          <Edit />
        </Button>
      )}
    </span>
  );
}
