import {Icon} from '../../components/Icon';
import {Board} from '../../models/Board';
import {CardDisplayType} from '../../models/CardSettings';
import {ILocation} from '../../models/Location';
import {BrandColors} from '../../utils/BrandColors';
import {T} from '../../utils/Internationalization';
import AppliancesCard from '../Appliances';
import {ICardType, CardTypeKey} from '../CardType';
import ElectricityValuesCard from '../ElectricityValues';
import {IElectricityCardSettings} from '../ElectricityValues/Settings';
import GasValuesCard from '../GasValues';
import WaterValuesCard from '../WaterValues';

export interface ICardSuggestion {
  id: string;
  icon: Icon;
  cardType: ICardType<any>;
  settings?: any;
  title?: string;
  color: string;
  textColor?: string;
}

export function getCardSuggestions(board: Board, location: ILocation): ICardSuggestion[] {
  const {cards} = board;
  const suggestions: ICardSuggestion[] = [];
  if (!cards.some(card => card.type === CardTypeKey.ElectricityValues)) {
    suggestions.push({
      id: 'electricity',
      icon: Icon.Electricity,
      cardType: ElectricityValuesCard,
      color: BrandColors.OrangeSunglow
    });
  } else if (
    !cards.some(
      card =>
        card.type === CardTypeKey.ElectricityValues &&
        (card.settings || ({} as Partial<IElectricityCardSettings>)).cardType === CardDisplayType.Table
    )
  ) {
    suggestions.push({
      id: 'electricityTable',
      icon: Icon.Electricity,
      cardType: ElectricityValuesCard,
      settings: {cardType: CardDisplayType.Table},
      title: T('suggestionsMini.electricityTable'),
      color: BrandColors.OrangeSunglow
    });
  }
  if (location.hasGas && !cards.some(card => card.type === CardTypeKey.GasValues)) {
    suggestions.push({
      id: 'gas',
      icon: Icon.Gas,
      cardType: GasValuesCard,
      color: BrandColors.OrangeClementine
    });
  }
  if (location.hasWater && !cards.some(card => card.type === CardTypeKey.WaterValues)) {
    suggestions.push({
      id: 'water',
      icon: Icon.Water,
      cardType: WaterValuesCard,
      color: BrandColors.Blue
    });
  }
  if (!cards.some(card => card.type === CardTypeKey.AppliancesMini || card.type === CardTypeKey.Appliances)) {
    suggestions.push({
      id: 'appliances',
      icon: Icon.Appliances,
      cardType: AppliancesCard,
      color: BrandColors.White,
      textColor: 'black'
    });
  }
  return suggestions;
}
