import * as React from 'react';

import {ILocation} from '../../../models/Location';
import {IOrganization} from '../../../models/Organization';
import {IPricingPolicy} from '../../../models/PricingPolicy';
import {T} from '../../../utils/Internationalization';
import {classes, dashboardClasses} from '../../../utils/Styles';
import {Alert, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label} from '../../bootstrap';
import {buttonColors} from '../../bootstrap/Button';
import {Closeable} from '../../Closeable';

import {Bin} from '../../ui-lib/icons/small';

import styles from './ConnectPricingPolicy.module.scss';
import {ConnectPricingPolicyProvider, useConnectPricingPolicy} from './ConnectPricingPolicyProvider';

function SelectPricingPolicyItem({
  pricingPolicy,
  ...props
}: React.ComponentProps<typeof DropdownItem> & {
  pricingPolicy: IPricingPolicy;
}) {
  const {handleEditPolicy} = useConnectPricingPolicy();

  return (
    <div className={styles.selectPricingPolicyItem}>
      <DropdownItem {...props} type="button" className={styles.connectPricingPolicyItem}>
        {pricingPolicy.name}
      </DropdownItem>
      <button
        type="button"
        onClick={() => handleEditPolicy(pricingPolicy)}
        className={styles.connectPricingPolicyAction}
      >
        <i className="fas fa-edit" />
      </button>
    </div>
  );
}

function SelectPricingPolicyButton({pricingPolicy, disabled}: {pricingPolicy?: IPricingPolicy; disabled?: boolean}) {
  const [open, setOpen] = React.useState<boolean>(false);
  const {pricingPolicies} = useConnectPricingPolicy();
  const {handleSelectPricingPolicy, handleAddNewPolicy} = useConnectPricingPolicy();

  const toggleOpen = React.useCallback(() => {
    setOpen(o => !o);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Closeable isOpen={open} onClose={handleClose}>
      <Dropdown isOpen={open} toggle={toggleOpen} style={{position: 'relative'}} disabled={disabled}>
        {pricingPolicy ? (
          <ChangePricingPolicyButton disabled={disabled} pricingPolicy={pricingPolicy} />
        ) : (
          <AddPricingPolicyButton pricingPolicy={pricingPolicy} />
        )}
        <DropdownMenu>
          {pricingPolicies.map(policy => (
            <SelectPricingPolicyItem
              onClick={!disabled ? () => handleSelectPricingPolicy?.(policy) : undefined}
              key={policy.id}
              pricingPolicy={policy}
            />
          ))}
          <div className={styles.selectPricingPolicyItem}>
            <DropdownItem onClick={handleAddNewPolicy} type="button" className={styles.connectPricingPolicyItem}>
              <i className="fas fa-plus mr-2" />
              Create New Pricing Policy
            </DropdownItem>
          </div>
        </DropdownMenu>
      </Dropdown>
    </Closeable>
  );
}

function SelectPricingPolicy({
  stationName,
  pricingPolicy,
  onSelect,
  disabled,
  className,
  organization,
  onRemove,
  active,
  ...props
}: {
  stationName?: string;
  pricingPolicy?: IPricingPolicy;
  disabled?: boolean;
  organization?: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  onRemove?(): void;
  active?: boolean;
} & JSX.IntrinsicElements['div']) {
  return (
    <div
      {...props}
      className={`${styles.selectedPricingPolicyItem} ${className}`}
      style={{opacity: disabled ? 0.5 : 1}}
    >
      {!active ? <div className={styles.disabledLineCross} /> : null}
      <div className={styles.connectPricingPolicyNameLocationGroup}>
        {stationName ? <div className={styles.connectPricingPolicyServiceLocationName}>{stationName}</div> : null}
        <SelectPricingPolicyButton disabled={disabled} pricingPolicy={pricingPolicy} />
      </div>
      <div className={styles.pricingPolicyActions}>
        {active ? <span className={styles.pricingPolicyActiveBadge}>Active</span> : null}
        {onRemove && !disabled && (
          <button type="button" className={styles.connectPricingPolicyDeleteAction} onClick={onRemove}>
            <Bin className="!tw-h-4 !tw-w-4" />
          </button>
        )}
      </div>
    </div>
  );
}

function AddPricingPolicyButton({pricingPolicy}: {pricingPolicy?: IPricingPolicy}) {
  return (
    <DropdownToggle caret className={buttonColors.secondary}>
      {pricingPolicy?.name || T('pricingPolicies.select')}
    </DropdownToggle>
  );
}

function ChangePricingPolicyButton({pricingPolicy, disabled}: {pricingPolicy?: IPricingPolicy; disabled?: boolean}) {
  return (
    <DropdownToggle
      disabled={disabled}
      caret={!disabled}
      className={classes(styles.selectPricingPolicyDropdown, dashboardClasses.secondaryButton)}
    >
      <span>{pricingPolicy?.name || T('pricingPolicies.select')}</span>
    </DropdownToggle>
  );
}

function ConnectPricingPolicyContent({
  serviceLocation,
  parentLocation
}: {
  serviceLocation: {
    name: string;
    type: 'ChargingSquare' | 'ChargingStation';
  };
  parentLocation?: ILocation;
}) {
  const {chargingSettings, handleSelectPricingPolicy, selectedPricingPolicy} = useConnectPricingPolicy();

  return (
    <>
      <Label>Pricing Policy</Label>
      {chargingSettings?.parentPricingPolicy && parentLocation ? (
        <>
          <SelectPricingPolicy
            pricingPolicy={chargingSettings?.parentPricingPolicy}
            stationName="Charging Square"
            disabled
            active={!selectedPricingPolicy}
            className="mb-2"
          />
          {!selectedPricingPolicy && (
            <Alert color="info">
              <p>
                There is currently a pricing policy active on the charging square. You can pick a different pricing
                policy for this charging station.
              </p>
            </Alert>
          )}
        </>
      ) : null}
      {!!selectedPricingPolicy ? (
        <SelectPricingPolicy
          onRemove={() => handleSelectPricingPolicy(undefined)}
          pricingPolicy={selectedPricingPolicy}
          active={!!selectedPricingPolicy}
        />
      ) : (
        <SelectPricingPolicyButton pricingPolicy={selectedPricingPolicy} />
      )}
    </>
  );
}

export function ConnectPricingPolicy({
  serviceLocation,
  parentLocation,
  organization,
  chargingSettings,
  onSelect
}: {
  serviceLocation: {
    name: string;
    type: 'ChargingSquare' | 'ChargingStation';
  };
  parentLocation?: ILocation;
  organization?: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  chargingSettings?: {
    pricingPolicy?: IPricingPolicy;
    parentPricingPolicy?: IPricingPolicy;
  };
  onSelect?(policy: IPricingPolicy): void;
}) {
  return (
    <ConnectPricingPolicyProvider organization={organization} chargingSettings={chargingSettings} onSelect={onSelect}>
      <ConnectPricingPolicyContent serviceLocation={serviceLocation} parentLocation={parentLocation} />
    </ConnectPricingPolicyProvider>
  );
}

interface PricingPolicySelectorProps {
  organization: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  pricingPolicy?: IPricingPolicy;
  onSelect?: (policy: IPricingPolicy) => void;
}
export function PricingPolicySelector(props: PricingPolicySelectorProps) {
  const {organization, pricingPolicy, onSelect} = props;
  return (
    <ConnectPricingPolicyProvider organization={organization} chargingSettings={{pricingPolicy}} onSelect={onSelect}>
      <SelectPricingPolicyButton pricingPolicy={pricingPolicy} />
    </ConnectPricingPolicyProvider>
  );
}
