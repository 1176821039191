import type {SVGProps} from 'react';

const SvgCompress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    fillRule="evenodd"
    {...props}
  >
    <path d="M10.731 2.269a1.269 1.269 0 1 0-2.538 0v5.923H2.269a1.269 1.269 0 1 0 0 2.538h7.192c.701 0 1.269-.568 1.269-1.269V2.269Zm0 19.462a1.269 1.269 0 1 1-2.538 0v-5.923H2.269a1.269 1.269 0 1 1 0-2.538h7.192c.701 0 1.269.568 1.269 1.269v7.192ZM14.538 1c-.701 0-1.269.568-1.269 1.269v7.192c0 .701.568 1.269 1.269 1.269h7.192a1.269 1.269 0 1 0 0-2.538h-5.923V2.269c0-.701-.568-1.269-1.269-1.269Zm-1.269 20.731a1.269 1.269 0 1 0 2.538 0v-5.923h5.923a1.269 1.269 0 1 0 0-2.538h-7.192c-.701 0-1.269.568-1.269 1.269v7.192Z" />
  </svg>
);
export default SvgCompress;
