import React from 'react';

import {useBoardContext} from '../../app/boardcontext';
import {useAppContext} from '../../app/context';
import {MiniCardHeader} from '../../components/MiniCardHeader';
import {VerticalLayout} from '../../components/VerticalLayout';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {BrandColors} from '../../utils/BrandColors';
import {T} from '../../utils/Internationalization';
import {CardTypeKey, CardCategory, ICardType, CardLocationAwareness, ICardProps} from '../CardType';
import {addCardToBoard, useCardLocationDetails} from '../CardUtils';
import {MiniCard} from '../MiniCard';

import {getCardSuggestions, ICardSuggestion} from './CardSuggestions';
import {CardSuggestionView} from './CardSuggestionView';

import styles from './CardSuggestionView.module.scss';

const SuggestionsMiniCard = (props: ICardProps<ICardSettings>) => {
  const {settings} = props;
  const location = useCardLocationDetails(settings);
  const {board} = useBoardContext();
  const {api, store} = useAppContext();

  const suggestions = (board && location && getCardSuggestions(board, location)) || [];

  const handleClickedSuggestion = (suggestion: ICardSuggestion) => {
    if (!board) return;

    addCardToBoard(api, store, board, suggestion.cardType.type, suggestion.settings);
  };

  const suggestionViews = suggestions.map(suggestion => (
    <CardSuggestionView
      key={suggestion.id}
      suggestion={suggestion}
      onClick={() => handleClickedSuggestion(suggestion)}
    />
  ));
  return (
    <MiniCard borderColor={BrandColors.Pumice} {...props}>
      <VerticalLayout>
        <MiniCardHeader title={T('suggestionsMini.title')} subtitle={T('suggestionsMini.description')} />
        <div className={styles.listOuter}>
          <div className={styles.listInner}>{suggestionViews}</div>
        </div>
      </VerticalLayout>
    </MiniCard>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.SuggestionsMini,
  title: 'suggestionsMini.title',
  description: 'suggestionsMini.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 1,
  height: 1,
  minWidth: 1,
  minHeight: 1,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Aware,
  cardClass: SuggestionsMiniCard
};
export default CARD;
