import type {SVGProps} from 'react';
const SvgCube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <path d="M12.2488 1.03908C12.0864 0.986974 11.9117 0.986974 11.7492 1.03908C8.16155 2.18986 5.4714 3.53456 2.31766 5.60578C2.0822 5.76041 1.879 5.95294 1.71424 6.17266C1.79337 6.18945 1.87175 6.21599 1.94782 6.25279L11.9997 11.1163L22.0488 6.25828C22.1267 6.22059 22.2071 6.19369 22.2883 6.177C22.123 5.95558 21.9187 5.76163 21.6817 5.60604C18.5279 3.53476 15.8364 2.18985 12.2488 1.03908ZM1.24108 16.9114C0.93569 13.7829 0.927079 11.1958 1.17763 8.14468L10.9795 12.8873V23C7.76556 21.9035 5.23903 20.6065 2.31272 18.6843C1.70544 18.2853 1.31157 17.6336 1.24108 16.9114ZM13.0209 22.9993C16.234 21.903 18.7618 20.6062 21.6878 18.6843C22.2949 18.2853 22.6888 17.6336 22.7594 16.9114C23.0646 13.784 23.0726 11.1979 22.8221 8.14858L13.0209 12.8869V22.9993Z" />
  </svg>
);
export default SvgCube;
