import React from 'react';

export class SVGGraphics {
  width: number;
  height: number;
  defs: JSX.Element[];
  items: JSX.Element[];

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.defs = [];
    this.items = [];
  }

  addArrowMarkerDef(id: string, color: string) {
    this.addMarkerDef(id, 6, 6, 2, 3, 'auto', 'userSpaceOnUse', <path d="M0,0 L0,6 L6,3 z" fill={color} />);
  }

  addOuterArrowMarkerDef(id: string, color: string) {
    this.addMarkerDef(id, 10, 10, 4, 5, 'auto', 'userSpaceOnUse', <path d="M0,0 L0,10 L10,5 z" fill={color} />);
  }

  addMarkerDef(
    id: string,
    width: number,
    height: number,
    refX: number,
    refY: number,
    orient: string,
    markerUnits: string,
    content: React.ReactNode
  ) {
    this.defs.push(
      <marker
        key={id}
        id={id}
        markerWidth={width}
        markerHeight={height}
        refX={refX}
        refY={refY}
        orient={orient}
        markerUnits={markerUnits}
      >
        {content}
      </marker>
    );
  }

  addGraphicsItem(item: JSX.Element) {
    this.items.push(item);
  }

  render() {
    return (
      <svg width={this.width} height={this.height}>
        <defs>{this.defs}</defs>

        {this.items}
      </svg>
    );
  }
}
