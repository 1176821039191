import Highcharts from 'highcharts';
import React, {ReactNode, useEffect, useState} from 'react';

import {useAppContext} from './app/context';
import {HourFormat} from './redux/AppState';
import {useAppSelector} from './utils/Hooks';
import {setCurrentLanguage, getActualLanguage} from './utils/Internationalization';
import {setLocale} from './utils/Locale';

interface LanguageRefresherOwnProps {
  children: ReactNode;
}

export const AutoLanguageRefresher = (props: LanguageRefresherOwnProps) => {
  const {children} = props;

  const {api} = useAppContext();
  const [me, hourFormat = HourFormat.Auto, thousandSeparator, decimalSeparator] = useAppSelector(state => [
    state.me.me,
    state.preferences.hourFormat,
    state.preferences.thousandSeparator,
    state.preferences.decimalSeparator
  ]);
  const language = getActualLanguage(me.language);

  useEffect(() => {
    setCurrentLanguage(language);

    if (language !== me.language) {
      // refresh token with newly selected language
      api.doRefreshLogin(language);
    }
  }, [api, language, me.language]);

  useEffect(() => {
    setLocale(language, hourFormat || HourFormat.Auto, thousandSeparator, decimalSeparator);
  }, [language, hourFormat, thousandSeparator, decimalSeparator]);

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        decimalPoint: decimalSeparator,
        thousandsSep: thousandSeparator
      }
    });
  }, [decimalSeparator, thousandSeparator]);

  return <div style={{minHeight: '100vh'}}>{children}</div>;
};
