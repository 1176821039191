import {IPricingGroup} from '../models/PricingGroup';

import {APIClient} from './APIClient';

export class PricingGroupAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getForOrganization(organizationId: number): Promise<IPricingGroup[]> {
    const url = `/api/v10/organizations/${organizationId}/discountgroups`;
    return this.client.doGet(url);
  }

  get(organizationId: number, groupId: number): Promise<IPricingGroup> {
    const url = `/api/v10/organizations/${organizationId}/discountgroups/${groupId}`;
    return this.client.doGet(url);
  }

  create(organizationId: number, group: IPricingGroup) {
    const url = `/api/v10/organizations/${organizationId}/discountgroups`;
    return this.client.doPost(url, group);
  }

  update(organizationId: number, group: IPricingGroup) {
    const url = `/api/v10/organizations/${organizationId}/discountgroups/${group.id}`;
    return this.client.doPatch(url, group);
  }

  delete(organizationId: number, groupId: number) {
    const url = `/api/v10/organizations/${organizationId}/discountgroups/${groupId}`;
    return this.client.doDelete(url);
  }
}
