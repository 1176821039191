import {ChargingStation} from '../../models/ChargingStation';
import {IConsumptionValue} from '../../models/ConsumptionValue';

import {IChargingStationConsumption} from './ChargingGroupGraph';

export interface IRearrangedChargingStationGroupItem {
  timestamp: number;
  parent?: IConsumptionValue;
  data: {[key: string]: IConsumptionValue};
}

export interface ChargingGroupData {
  stations: ChargingStation[];
  data: IRearrangedChargingStationGroupItem[];
}

export function rearrangeChargingStationGroupData(
  data: IChargingStationConsumption[],
  parent: IConsumptionValue[]
): ChargingGroupData {
  let result: IRearrangedChargingStationGroupItem[] = parent.map(consumption => ({
    timestamp: consumption.timestamp,
    parent: consumption,
    data: {}
  }));
  let stations: ChargingStation[] = [];
  let stationIndexes: {[key: string]: number} = {};

  for (var item of data) {
    if (!item.station || item.consumption.length === 0) continue;

    stationIndexes[item.station.serialNumber] = stations.length;
    stations.push(item.station);

    if (result.length === 0) {
      result = item.consumption.map(x => ({
        timestamp: x.timestamp,
        station: item.station,
        parent: x,
        data: {[item.station.serialNumber]: x}
      }));
      continue;
    }

    let index = 0;
    for (var consumptionItem of item.consumption) {
      let dataItem = result[index];
      while (dataItem && dataItem.timestamp < consumptionItem.timestamp && index < result.length) {
        index++;
        dataItem = result[index];
      }

      if (dataItem) {
        if (dataItem.timestamp === consumptionItem.timestamp) {
          dataItem.data[item.station.serialNumber] = consumptionItem;
          index++;
        } else {
          const newItem: IRearrangedChargingStationGroupItem = {
            timestamp: consumptionItem.timestamp,
            parent: consumptionItem,
            data: {}
          };
          newItem.data[item.station.serialNumber] = consumptionItem;
          result.splice(index, 0, newItem);
          index++;
        }
      }
    }
  }
  return {data: result, stations};
}
