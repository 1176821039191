import {AnyAppAction} from '../actions';
import {PreferencesActions} from '../actions/preferences';
import {IPreferencesState, LoadState} from '../AppState';

const defaultPreferences: IPreferencesState = {
  loadState: LoadState.NOT_YET_LOADED,
  batchCounter: 0,
  decimalSeparator: ',',
  thousandSeparator: ' ',
  delimiter: ',',
  locationId: undefined,
  dashboardDefaultsVersion: 0,
  evBoardAdded: false,
  shownHints: [],
  demoMode: false
};

export default function update(state: IPreferencesState = defaultPreferences, action: AnyAppAction): IPreferencesState {
  switch (action.type) {
    case PreferencesActions.UPDATE:
    case PreferencesActions.LOAD: {
      const updates = action.payload;
      if (action.type === PreferencesActions.LOAD) {
        updates.loadState = LoadState.LOADED;
      }

      return {...state, ...updates};
    }
    case PreferencesActions.FETCHING:
      return {...state, loadState: LoadState.FETCHING};
    default:
      return state;
  }
}
