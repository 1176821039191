import React from 'react';

import {IConsumptionValue} from '../../models/ConsumptionValue';
import {DEFAULT_OPTIONS, IFieldOptions, ITableField} from '../../models/Table';
import {T} from '../../utils/Internationalization';

export class DelayColumn implements ITableField<IConsumptionValue> {
  name: string = 'delay';
  label: string;
  options: IFieldOptions;

  constructor(options: Partial<IFieldOptions> = {}) {
    this.label = T('electricityValues.field.delay');
    this.options = {...DEFAULT_OPTIONS, unit: 'min', ...options};
  }

  renderCellContent(item: IConsumptionValue) {
    if (item.delta === undefined) return undefined;

    const delaySeconds = item.delta;
    const delayMinutes = Math.round(delaySeconds / 60);

    if (delaySeconds > 15 * 60) {
      return <span style={{color: 'red'}}>{delayMinutes}</span>;
    } else {
      return delayMinutes;
    }
  }

  getExportCSVValue(item: IConsumptionValue) {
    if (item.delta === undefined) return '';

    const delaySeconds = item.delta;
    const delayMinutes = Math.round(delaySeconds / 60);
    return delayMinutes.toString();
  }

  getExportExcelValue(item: IConsumptionValue) {
    if (item.delta === undefined) return '';

    const delaySeconds = item.delta;
    const delayMinutes = Math.round(delaySeconds / 60);
    return delayMinutes.toString();
  }

  sort = (a: IConsumptionValue, b: IConsumptionValue): number => {
    const deltaA = a.delta || 0;
    const deltaB = b.delta || 0;
    return deltaA - deltaB;
  };
}
