import classnames from 'classnames';
import * as React from 'react';

import {T} from '../utils/Internationalization';
import {
  hourMinutestoString,
  OpeningHours,
  stringToHoursMinutes,
  TimeRange,
  translateDayOfWeek,
  WeekDays
} from '../utils/OpeningHours';

import styles from './Schedule.module.scss';
import {Button} from './ui/button';
import {Checkbox} from './ui/checkbox';
import {Bin, Plus} from './ui-lib/icons/small';

const DEFAULT_DAY_RANGE: OpeningHours = {
  from: {hour: 9, minute: 0},
  to: {hour: 17, minute: 0}
};

function TimeRangeField({
  className,
  value,
  onChange,
  disabled
}: {className?: string; disabled?: boolean} & {
  value: OpeningHours;
  onChange?: (value: OpeningHours) => void;
}) {
  const [error, setError] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (value.from.hour > value.to.hour) {
      setError(T('invalid.timeRange'));
    } else if (value.from.hour === value.to.hour && value.from.minute > value.to.minute) {
      setError(T('invalid.timeRange'));
    } else {
      setError(undefined);
    }
  }, [value]);

  return (
    <div>
      <div className={classnames(styles.timeRangeField, className)}>
        <input
          type="time"
          className={`${styles.timeRangeField__input} form-control`}
          disabled={disabled}
          max={hourMinutestoString(value.to)}
          value={hourMinutestoString(value.from)}
          onChange={option => {
            onChange?.({...value, from: stringToHoursMinutes(option.target.value)});
          }}
        />
        <span className={styles.timeRangeField__spacer}> - </span>
        <input
          type="time"
          className={`${styles.timeRangeField__input} form-control`}
          disabled={disabled}
          min={`${hourMinutestoString(value.from)}`}
          value={`${hourMinutestoString(value.to)}`}
          onChange={option => {
            onChange?.({...value, to: stringToHoursMinutes(option.target.value)});
          }}
        />
      </div>
      {error && <div className="text-danger">{error}</div>}
    </div>
  );
}

function RemoveTimeButton({
  index,
  onClick,
  disabled,
  className
}: {
  index: number;
  onClick: (index: number) => void;
  className?: string;
  disabled?: boolean;
}) {
  return (
    // Round IconButton
    <Button
      variant="secondary_icon_btn"
      size="icon"
      disabled={disabled}
      onClick={() => onClick(index)}
      className={classnames('tw-ml-2 tw-mr-2 tw-border-none tw-text-[#e74c3c]', className)}
    >
      <Bin />
      <span className="sr-only">add time range</span>
    </Button>
  );
}

function DayRanges({
  fields,
  disabled,
  onChange
}: {
  fields: OpeningHours[];
  disabled?: boolean;
  onChange?: (fields: OpeningHours[]) => void;
}) {
  return (
    <div>
      {fields.map((field, index: number) => (
        <React.Fragment key={index}>
          <div className={styles.dayRanges__container}>
            <TimeRangeField
              disabled={disabled}
              value={field}
              onChange={value => {
                const newFields = [...fields];
                newFields[index] = value;
                onChange?.(newFields);
              }}
            />
            {index === 0 && (
              <Button
                variant="secondary_icon_btn"
                size="icon"
                disabled={disabled}
                onClick={() => {
                  onChange?.([...fields, DEFAULT_DAY_RANGE]);
                }}
                className={classnames('tw-ml-2 tw-mr-2')}
              >
                <Plus />
                <span className="sr-only">add time range</span>
              </Button>
            )}
            {index !== 0 && (
              <RemoveTimeButton
                disabled={disabled}
                index={index}
                onClick={i => {
                  const newFields = [...fields];
                  newFields.splice(i, 1);
                  onChange?.(newFields);
                }}
              />
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

function ScheduleDay({
  name,
  disabled,
  dayOfWeek,
  openingHours,
  onChange
}: TimeRange & {
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (timeRange: TimeRange) => void;
}) {
  const checked = openingHours && openingHours.length > 0;

  return (
    <div className={styles.scheduleDay}>
      {/* Label & switch container */}
      <div className={styles.scheduleDay__day}>
        <Checkbox
          id={name}
          name={name}
          label={translateDayOfWeek(dayOfWeek)}
          disabled={disabled}
          checked={checked ?? false}
          onCheckedChange={() => {
            onChange?.({
              dayOfWeek,
              openingHours: checked ? [] : [DEFAULT_DAY_RANGE]
            });
          }}
          labelClassName={styles.scheduleDay__day__label}
          wrapperClassName="!tw-mb-0"
          testId={name}
        />
      </div>
      {checked ? (
        <div className={styles.dayRanges__wrapper}>
          <DayRanges
            fields={openingHours ?? []}
            disabled={!checked || disabled}
            onChange={fields => {
              onChange?.({
                dayOfWeek,
                openingHours: fields
              });
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export function Schedule({
  name,
  disabled,
  timeRange = [],
  onChange,
  className
}: {
  name: string;
  disabled?: boolean;
  timeRange?: TimeRange[];
  className?: string;
  onChange?: (timeRange: TimeRange[]) => void;
}) {
  const handleChange = React.useCallback(
    (update: TimeRange) => {
      const index = timeRange.findIndex(time => time.dayOfWeek === update.dayOfWeek);

      if (index === -1) {
        const newTimeRange = [...timeRange, update];
        return onChange?.(newTimeRange);
      } else {
        const newTimeRange = [...timeRange];

        newTimeRange[index] = {
          ...newTimeRange[index],
          ...update
        };

        return onChange?.(newTimeRange);
      }
    },
    [timeRange, onChange]
  );

  return (
    <div className={classnames(styles.schedule, className)}>
      {WeekDays.map((weekday, num) => {
        const weekdayIndex = num % 7;
        const dayRangeName = `${name}.${weekdayIndex}`;
        const value = timeRange.find(time => time.dayOfWeek === weekday);

        return (
          <ScheduleDay
            disabled={disabled}
            name={dayRangeName}
            key={weekday}
            dayOfWeek={weekday}
            onChange={handleChange}
            openingHours={value?.openingHours}
          />
        );
      })}
    </div>
  );
}
