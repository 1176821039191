import React from 'react';

import {Flex} from '../../components/Flex';
import {classes} from '../../utils/Styles';
import {testingClasses} from '../../utils/TestingClasses';

import styles from './CardActions.module.scss';

interface ICardActionsProps {
  children: React.ReactNode;
  justifyContent?: string;
  className?: string;
}

export const CardActions = (props: ICardActionsProps) => (
  // <Flex
  //   justifyContent={props.justifyContent ?? 'start'}
  //   className={classes(styles.cardActions, testingClasses.cardActions)}
  //   data-testid={testingClasses.cardActions}
  // >

  // eslint-disable-next-line prettier/prettier, react/jsx-no-useless-fragment
  <>{props.children}</>
  // {/* </Flex> */}
);

export const Spring = () => <div style={{flex: 1}} />;
