import {IOrganization} from '../models/Organization';

import {APIClient} from './APIClient';

export class OperatorAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getAll(): Promise<IOrganization[]> {
    return this.client.doGet('/api/v10/operators');
  }
}
