import React from 'react';

import {Input} from '../../../components/bootstrap';

import {Load, LoadType} from '../../../models/Load';
import {T} from '../../../utils/Internationalization';

interface HighLevelNameInputProps {
  readOnly: boolean;
  value: string;
  load: Load;
  invalid: boolean;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}
export const HighLevelNameInput = (props: HighLevelNameInputProps) => {
  const {readOnly, value, load, invalid, onChange} = props;
  const {type} = load;
  const isGrid = type === LoadType.Grid;

  return (
    <Input
      type="text"
      name="name"
      value={value || (isGrid ? T('defaultNames.gridLoad') : '')}
      disabled={readOnly}
      onChange={onChange}
      invalid={invalid}
    />
  );
};
