import type {SVGProps} from 'react';
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    fillRule="evenodd"
    {...props}
  >
    <path d="M14.87 22v-5.566a2 2 0 0 0-2-2h-1.74a2 2 0 0 0-2 2V22a52 52 0 0 1-4.617-.46c-.74-.11-1.317-.684-1.41-1.403a88 88 0 0 1-.667-8.821 3.53 3.53 0 0 1 1.253-2.77l7.204-6.141a1.72 1.72 0 0 1 2.214 0l7.204 6.141a3.53 3.53 0 0 1 1.253 2.77 88 88 0 0 1-.666 8.82c-.093.721-.668 1.294-1.41 1.403a52 52 0 0 1-4.619.46Z" />
  </svg>
);
export default SvgHome;
