import type {SVGProps} from 'react';
const SvgEyeVisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M3.329 3.613C4.609 2.588 6.217 1.714 8 1.714s3.39.874 4.671 1.899c1.287 1.03 2.305 2.262 2.92 3.089l.006.006c.266.369.403.83.403 1.292s-.137.923-.403 1.292l-.005.007c-.616.826-1.634 2.058-2.921 3.088-1.28 1.025-2.888 1.899-4.671 1.899s-3.39-.874-4.671-1.899C2.042 11.357 1.024 10.125.409 9.3l-.006-.007A2.2 2.2 0 0 1 0 8c0-.462.137-.923.403-1.292l.005-.006c.616-.827 1.634-2.059 2.921-3.09M10.571 8A2.571 2.571 0 1 1 5.43 8a2.571 2.571 0 0 1 5.142 0" />
  </svg>
);
export default SvgEyeVisible;
