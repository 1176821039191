import {Layouts, Layout} from 'react-grid-layout';

import {getCardType} from '../cards';

import {ICardType} from '../cards/CardType';

import {BoardIconKey} from './BoardIcon';
import {ICardSettings} from './CardSettings';

const COLS_LG = 4;
const COLS_XS = 2;
function generateCompactSubLayout(cards: ICard<any>[], cols: number): Layout[] {
  const layouts: Layout[] = [];
  let x = 0;
  let y = 0;
  for (let i = 0; i < cards.length; i++) {
    const type = getCardType(cards[i].type);
    const id = `card-${cards[i].id}`;
    layouts.push({
      x,
      y,
      w: type.width || 2,
      h: type.height || 2,
      minW: type.minWidth || 2,
      minH: type.minHeight || 2,
      i: id
    });

    x += type.width || 2;
    if (x >= cols) {
      y += type.height || 2;
      x = 0;
    }
  }
  return layouts;
}
export function generateCompactLayout(cards: ICard<any>[]): Layouts {
  return {
    lg: generateCompactSubLayout(cards, COLS_LG),
    xs: generateCompactSubLayout(cards, COLS_XS)
  };
}

function getLastCardPosition(layouts: Layout[]): Layout | undefined {
  return layouts.reduce((result: Layout | undefined, layout: Layout) => {
    if (result === undefined) return layout;

    if (result.y < layout.y) return layout;
    if (result.y > layout.y) return result;
    if (result.x > layout.x) return result;
    else return layout;
  }, undefined);
}

function getNextPosition(layouts: Layout[], card: ICard<any>, type: ICardType<any>, cols: number): Layout {
  const lastCardPosition = getLastCardPosition(layouts)!;

  const cardId = `card-${card.id}`;
  const x = lastCardPosition.x + lastCardPosition.w;
  if (x + (type.width || 2) > cols) {
    return {
      x: 0,
      y: lastCardPosition.y + lastCardPosition.h,
      w: type.width || 2,
      h: type.height || 2,
      minW: type.minWidth || 2,
      minH: type.minHeight || 2,
      i: cardId
    };
  } else {
    return {
      x,
      y: lastCardPosition.y,
      w: type.width || 2,
      h: type.height || 2,
      minW: type.minWidth || 2,
      minH: type.minHeight || 2,
      i: cardId
    };
  }
}

export function addCardToLayout(board: IBoard, card: ICard<any>): void {
  const type = getCardType(card.type);
  if (board.settings === undefined) {
    board.settings = {layouts: undefined, custom: true, version: 2};
  }
  if (board.settings.layouts === undefined || board.settings.layouts.lg === undefined || board.cards.length === 1) {
    board.settings.layouts = generateCompactLayout(board.cards);
    return;
  }

  const lgLayouts = board.settings.layouts.lg;
  const xsLayouts = board.settings.layouts.xs || generateCompactSubLayout(board.cards, COLS_XS);
  lgLayouts.push(getNextPosition(lgLayouts, card, type, COLS_LG));
  xsLayouts.push(getNextPosition(xsLayouts, card, type, COLS_XS));
  board.settings.layouts.xs = xsLayouts;
}

export interface IBoardSettings {
  custom: boolean;
  layouts: Layouts | undefined;
  version?: number;
  template?: string;
  templateRevision?: number;
}

export interface IBoard {
  id: string;
  name: string;
  icon: BoardIconKey;
  settings?: IBoardSettings;
  version: number;
  order: number;
  cards: ICard<any>[];
}

export interface ICreateBoardRequest {
  name: string;
  icon: BoardIconKey;
  settings: IBoardSettings;
  version: number;
}

export interface IUpdateBoardRequest {
  id: string;
  name: string;
  icon: BoardIconKey;
  settings: IBoardSettings;
  order: number;
}

export interface ICard<S extends ICardSettings> {
  id: string;
  type: string;
  name: string | null;
  settings?: Partial<S>;
  order: number;
}

export class Board {
  id: string;
  name: string;
  icon: BoardIconKey;
  settings: IBoardSettings;
  order: number;
  cards: ICard<any>[];

  constructor(board: IBoard) {
    this.id = board.id;
    this.name = board.name;
    this.icon = board.icon;
    this.settings = board.settings || {
      layouts: undefined,
      custom: true,
      version: 2
    };
    this.order = board.order;
    this.cards = board.cards;
  }

  serialize(): IBoard {
    return {
      id: this.id,
      name: this.name,
      icon: this.icon,
      settings: this.settings,
      order: this.order,
      cards: this.cards,
      version: 1
    };
  }
}
