import React, {useState, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button} from '../../components/bootstrap';
import {Icons} from '../../components/Icon';
import {useCardOrganizations} from '../../components/inputs/OrganizationInput';
import Table from '../../components/Table';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import RemoveModal from '../../modals/RemoveModal';
import {IActivation, IOrganizationActivationCode} from '../../models/ActivationCode';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable, ICardWithOrganization} from '../../models/CardSettings';
import {getLabelVariant, IOrganization} from '../../models/Organization';
import {getLastSelectedPrinter} from '../../models/Printer';
import {ITableField} from '../../models/Table';
import {useActivationCodeHistory, useOrganizationActivationCodes} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {CardActions, ColumnChooser} from '../components';

import {Reload, ExportCsv} from '../components/actions';
import {ActivationCodeSelector} from '../components/actions/ActivationCodeSelector';
import {Spring} from '../components/CardActions';

import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import {SetActivationCodePromiseModal, SetActivationCodeResult} from '../Locations/SetActivationCodePromiseModal';

import {AssignActivationCodesPromiseModal} from './AssignActivationCodes';

import {getColumns, ISelectedSerial} from './Columns';
import {getErrorForAssignmentResponse} from './ErrorHandling';
import {printLabels} from './printlabels';

interface IShipmentSettings extends ICardSettingsWithTable, ICardWithOrganization {
  activationCodeId?: number;
}

const rowKey = (item: IActivation) => `${item.serialNumber}#${item.timestamp}`;
const Shipment = (props: ICardProps<IShipmentSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const modals = useModals();
  const {api} = useAppContext();
  const {activationCodeId} = settings;

  const [inputOrganizations, updateOrganizationInputQuery, organization] = useCardOrganizations(fetch, settings);
  const [activationCodes, refreshActivationCodes] = useOrganizationActivationCodes(fetch, organization?.id);
  const activationCode = activationCodes.find(code => code.id === activationCodeId) || activationCodes[0];

  const [items, refreshItems] = useActivationCodeHistory(fetch, activationCode && activationCode.code);
  const [selected, setSelected] = useState<ISelectedSerial[]>([]);
  const [printed, setPrinted] = useState(false);
  const [printError, setPrintError] = useState<string | undefined>();
  const [tableKey, setTableKey] = useState(1);

  const handleClickedRefresh = () => {
    refreshItems();
    refreshActivationCodes();
  };

  const handleAssignCodesAdded = (organization: IOrganization, activationCode: IOrganizationActivationCode) => {
    const wasAdded = organization && activationCode;
    if (wasAdded) {
      updateSettings({
        organization: organization.id,
        activationCodeId: activationCode.id
      });
      refreshItems();
    }
  };

  const handleAssigned = () => refreshItems();

  const handleShowAssignCodes = () => {
    modals.show<boolean>(props => (
      <AssignActivationCodesPromiseModal
        onAssigned={handleAssigned}
        onSaved={handleAssignCodesAdded}
        organization={organization}
        activationCode={activationCode}
        {...props}
      />
    ));
  };

  const handleChangeOrganization = (organization?: IOrganization) => {
    updateSettings({organization: organization?.id});
  };

  const handleChangeCode = (activationCode: IOrganizationActivationCode) => {
    updateSettings({activationCodeId: activationCode.id});
  };

  const handleDeleteSelected = async () => {
    const result = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal title={T('shipment.unassign.title')} {...props}>
        <p>{T('shipment.unassign.confirmation')}</p>
        <pre>{selected.map(item => item.serial).join('\n')}</pre>
      </ConfirmationPromiseModal>
    ));
    if (result !== ConfirmationResult.Accept) return;
    if (!activationCode) return;

    const promises = selected.map(item => api.activationCodes.removeDeviceActivation(item.code, item.serial));
    await Promise.all(promises);
    setSelected([]);
    refreshItems();
  };

  const handleMoveSelected = async () => {
    if (!activationCode) return;
    if (selected.some(device => device.code !== activationCode.code)) {
      NotificationManager.error('All devices must belong to the same activation code');
      return;
    }

    const result = await modals.show<SetActivationCodeResult | undefined>(props => (
      <SetActivationCodePromiseModal
        organization={organization}
        activationCode={activationCode}
        location={undefined}
        content={selected.map(item => item.serial).join(', ')}
        withOrderNumber
        {...props}
      />
    ));
    if (!result) return;

    const fromActivationCode = activationCode;
    const toActivationCode = result.activationCode;

    const moveResponse = await api.activationCodes.move(
      fromActivationCode.code,
      toActivationCode.code,
      selected.map(item => item.serial),
      result.orderNr
    );
    const error = getErrorForAssignmentResponse(moveResponse);
    if (error === undefined) {
      setSelected([]);
      refreshItems();
    } else {
      NotificationManager.error(error);
    }
  };

  const handlePrintSelected = async () => {
    setPrinted(false);
    setPrintError(undefined);
    const success = await printLabels(
      api.getToken(),
      getLabelVariant(organization),
      selected.map(item => item.serial),
      getLastSelectedPrinter() || 'jan'
    );
    setPrinted(success);
    setPrintError(success ? undefined : T('shipment.error.printFailed'));
  };

  const handleClearSelection = () => {
    setSelected([]);
    setTableKey(tableKey + 1);
  };

  const tableColumns: ITableField<IActivation>[] = useMemo(() => {
    const setChecked = (row: IActivation, checked: boolean) => {
      const currentlyChecked = selected.some(item => item.serial === row.serialNumber);
      if (checked === currentlyChecked) return;

      setPrinted(false);
      setPrintError(undefined);
      const selectedFiltered = selected.filter(item => item.serial !== row.serialNumber);
      if (checked) {
        if (!activationCode) return;

        selectedFiltered.push({
          code: activationCode.code,
          serial: row.serialNumber
        });
      }
      setSelected(selectedFiltered);
    };

    const handleClickedRemove = async (activation: IActivation) => {
      if (!activationCode) return;

      const success = await modals.show(props => (
        <RemoveModal
          title={T('shipment.remove.title')}
          message={T('shipment.remove.confirmation', {serial: activation.serialNumber})}
          execute={api => api.activationCodes.removeDeviceActivation(activationCode.code, activation.serialNumber)}
          successMessage="Activation code removed successfully"
          failureMessage={T('shipment.error.couldNotRemove')}
          {...props}
        />
      ));
      if (success) refreshItems();
    };

    return getColumns(selected, {
      onChecked: setChecked,
      onClickedRemove: handleClickedRemove
    });
  }, [modals, selected, activationCode, refreshItems]);

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={handleClickedRefresh} />
      {state.ready && (
        <ExportCsv fields={tableColumns} settings={settings.table} items={items || []} name="shipped_devices" />
      )}
      <ActivationCodeSelector
        organizations={inputOrganizations.organizations}
        activationCodes={activationCodes}
        organization={organization}
        activationCode={activationCode}
        searchable={true}
        onChangeOrganization={handleChangeOrganization}
        onChangeCode={handleChangeCode}
        updateQuery={updateOrganizationInputQuery}
      />
      <Spring />
      {selected.length > 0 ? (
        <>
          <Button onClick={handleClearSelection} title={T('shipment.actions.clear')}>
            {Icons.Clear}
          </Button>
          <Button onClick={handleDeleteSelected} title={T('shipment.actions.delete')}>
            {Icons.Trash}
          </Button>
          <Button onClick={handleMoveSelected} title={T('shipment.actions.move')}>
            {Icons.Move}
          </Button>
          <Button onClick={handlePrintSelected} title={T('shipment.actions.print')}>
            {printed ? T('shipment.actions.printed') : printError ? printError : Icons.Print}
          </Button>
        </>
      ) : (
        <Button onClick={handleShowAssignCodes}>{T('shipment.actions.assign')}</Button>
      )}
    </CardActions>
  );

  const customSettings: CustomSettings<IShipmentSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser
        fields={tableColumns}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    );
  };

  return (
    <CardView actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      <Table
        fields={tableColumns}
        items={items || []}
        rowKey={rowKey}
        noun="deviceActivation"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        key={tableKey}
        emptyMessage={T('shipment.noDevices')}
        selected={selected.length}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IShipmentSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'serialNumber', visible: true},
      {name: 'orderNumber', visible: true}
    ]
  }
};
const CARD: ICardType<IShipmentSettings> = {
  type: CardTypeKey.Shipment,
  title: 'shipment.title',
  description: 'shipment.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: Shipment
};
export default CARD;
