import React, {useState, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Alert, Input, Label, FormGroup, SingleActionModal} from '../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ILocationSummary} from '../../models/Location';
import {None} from '../../utils/Arrays';
import {Fetcher} from '../../utils/Fetcher';
import {useLocationSwitches, useSensors} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {useUser} from '../CardUtils';

enum ClearType {
  ALL = 'ALL',
  ELECTRICITY = 'ELECTRICITY',
  GAS_WATER = 'GAS_WATER',
  SWITCH = 'SWITCH',
  APPLIANCES = 'APPLIANCES'
}

interface ClearDataModalProps extends IPromiseModalProps<void> {
  fetch: Fetcher;
  location: ILocationSummary;
}

export default (props: ClearDataModalProps) => {
  const {fetch, location} = props;
  const {api} = useAppContext();
  const me = useUser();
  const [isOpen, resolve] = usePromiseModal(props);
  const handleToggle = () => resolve();

  const [type, setType] = useState<ClearType>(ClearType.ELECTRICITY);
  const [switchId, setSwitchId] = useState('all');
  const [sensorId, setSensorId] = useState('all');
  const [confirmation, setConfirmation] = useState('');

  const [switches] = useLocationSwitches(fetch, location.id);
  const sensors = useSensors(fetch, location.id) || None;

  const sensorOptions = useMemo(
    () =>
      sensors.map(sensor => (
        <option key={sensor.id} value={sensor.id}>
          {sensor.serialNumber}
        </option>
      )),
    [sensors]
  );

  const switchOptions = useMemo(
    () =>
      switches
        .filter(switch_ => switch_.serialNumber !== undefined)
        .map(switch_ => (
          <option key={switch_.id} value={switch_.monitorId}>
            {switch_.name}
          </option>
        )),
    [switches]
  );

  const handleSensorIdChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSensorId(e.currentTarget.value);
  };

  const handleSwitchIdChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSwitchId(e.currentTarget.value);
  };

  const handleClearTypeChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setType(e.currentTarget.value as ClearType);
  };

  const handleConfirmationChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setConfirmation(e.currentTarget.value);
  };

  const isValidInput = confirmation === me.username;

  const clearData = async () => {
    const {id} = location;

    switch (type) {
      case ClearType.ALL:
        api
          .deleteAllData(id)
          .then(() => {
            handleToggle();
            NotificationManager.success(T('locationConfiguration.clearData.all.success'));
          })
          .catch(() => {
            NotificationManager.error(T('locationConfiguration.clearData.all.failed'));
          });
        return;
      case ClearType.ELECTRICITY:
        api
          .deleteElectricityData(id)
          .then(() => {
            handleToggle();
            NotificationManager.success(T('locationConfiguration.clearData.electricity.success'));
          })
          .catch(() => {
            NotificationManager.error(T('locationConfiguration.clearData.electricity.failed'));
          });
        return;
      case ClearType.APPLIANCES:
        api
          .deleteApplianceData(id)
          .then(() => {
            handleToggle();
            NotificationManager.success(T('locationConfiguration.clearData.appliances.success'));
          })
          .catch(() => {
            NotificationManager.error(T('locationConfiguration.clearData.appliances.failed'));
          });
        return;
      case ClearType.GAS_WATER:
        if (sensorId === 'all') {
          api
            .deleteAllGasWaterData(id)
            .then(() => {
              handleToggle();
              NotificationManager.success(T('locationConfiguration.clearData.gasWater.all.success'));
            })
            .catch(() => {
              NotificationManager.error(T('locationConfiguration.clearData.gasWater.all.failed'));
            });
        } else {
          const sensorIdInt = parseInt(sensorId);
          const sensor = sensors.find(x => x.id === sensorIdInt);
          const serial = sensor ? sensor.serialNumber : sensorId;
          api
            .deleteGasWaterData(id, sensorIdInt)
            .then(() => {
              handleToggle();
              NotificationManager.success(
                T('locationConfiguration.clearData.gasWater.specific.success', {
                  serial
                })
              );
            })
            .catch(() => {
              NotificationManager.error(
                T('locationConfiguration.clearData.gasWater.specific.failed', {
                  serial
                })
              );
            });
        }
        return;
      case ClearType.SWITCH:
        if (switchId === 'all') {
          api
            .deleteAllSwitchData(id)
            .then(() => {
              handleToggle();
              NotificationManager.success(T('locationConfiguration.clearData.switch.success'));
            })
            .catch(() => {
              NotificationManager.success(T('locationConfiguration.clearData.switch.failed'));
            });
        } else {
          api
            .deleteSwitchData(id, parseInt(switchId))
            .then(() => {
              handleToggle();
              NotificationManager.success(T('locationConfiguration.clearData.switch.success'));
            })
            .catch(() => {
              NotificationManager.success(T('locationConfiguration.clearData.switch.failed'));
            });
        }
    }
  };

  const {username} = me;
  const hasSwitches = Array.isArray(switches) && switches.length > 0;

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleToggle}
      title={T('locationConfiguration.clearData.title')}
      actionText={T('locationConfiguration.clearData.confirm')}
      action={clearData}
      disabled={!isValidInput}
      cancelText={T('locationConfiguration.clearData.cancel')}
    >
      <FormGroup>
        <Label for="type">{T('locationConfiguration.clearData.dataToClear')}</Label>
        <Input type="select" name="type" value={type} onChange={handleClearTypeChanged}>
          <option value={ClearType.ALL}>{T('locationConfiguration.clearData.allData')}</option>
          <option value={ClearType.ELECTRICITY}>{T('locationConfiguration.clearData.electricity')}</option>
          <option value={ClearType.APPLIANCES}>{T('locationConfiguration.clearData.appliances')}</option>
          <option value={ClearType.GAS_WATER}>{T('locationConfiguration.clearData.gasWaterData')}</option>
          {hasSwitches && <option value={ClearType.SWITCH}>{T('locationConfiguration.clearData.switchData')}</option>}
        </Input>
      </FormGroup>

      {/* Render gas, water or Switch selections */}
      {type === ClearType.GAS_WATER && (
        <FormGroup>
          <Input type="select" name="sensorId" onChange={handleSensorIdChanged}>
            <option value="all">{T('locationConfiguration.clearData.all')}</option>
            {sensorOptions}
          </Input>
        </FormGroup>
      )}
      {type === ClearType.SWITCH && (
        <FormGroup>
          <Input type="select" name="switchId" onChange={handleSwitchIdChanged}>
            <option value="all">{T('locationConfiguration.clearData.all')}</option>
            {switchOptions}
          </Input>
        </FormGroup>
      )}

      <FormGroup>
        <Label for="confirmation">Confirmation code</Label>
        <Alert fixed={false}>
          <div
            dangerouslySetInnerHTML={{
              __html: T('locationConfiguration.clearData.alert', {username})
            }}
          />
        </Alert>
        <Input type="text" name="confirmation" onChange={handleConfirmationChanged} />
      </FormGroup>
    </SingleActionModal>
  );
};
