import React, {useMemo} from 'react';

import DatePicker from 'react-datepicker';
import {v1 as uuid_v1} from 'uuid';

import {FORMAT_DATE_S_PICKER} from '../../core/constants';
import {useFormContext} from '../../utils/FormContext';
import {FormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {classes} from '../../utils/Styles';

import FormInputGroup from './FormInputGroup';

type DatePickerProps = React.ComponentProps<typeof DatePicker>;

type DateInputProps = Omit<DatePickerProps, 'value' | 'onChange'> & {
  form?: FormState;
  name: string;
  label: string;
  info?: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
};

export default function DateInput(props: DateInputProps) {
  const {name, label, info, value, onChange, className, disabled, required = false, ...rest} = props;

  const form = useFormContext();
  const shownError = form.getShownError(name);

  const uuid = useMemo(() => uuid_v1().replace(/-/g, ''), []);

  const error = required && value === null ? T('validator.required', {name: label}) : undefined;
  form.setError(name, error);

  const handleBlur = () => {
    form.setErrorVisible(name, error !== undefined);
  };

  return (
    <FormInputGroup name={name} label={label} error={shownError} info={info}>
      <div>
        <DatePicker
          {...rest}
          id={uuid}
          name={name}
          selected={value}
          dateFormat={FORMAT_DATE_S_PICKER}
          className={classes('form-control', className)}
          onChange={onChange}
          disabled={disabled}
          onBlur={handleBlur}
        />
      </div>
    </FormInputGroup>
  );
}
