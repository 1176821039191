import React, {ReactNode} from 'react';

import {useAppContext} from '../app/context';
import {Icons} from '../components/Icon';
import {useModals} from '../modals/ModalContext';
import {ICardSettings} from '../models/CardSettings';
import {setContextLocationId} from '../redux/actions/location';
import {useAppSelector} from '../utils/Hooks';
import {T} from '../utils/Internationalization';
import {classes} from '../utils/Styles';
import {testingClasses} from '../utils/TestingClasses';

import {useCardContext} from './CardContext';
import {ICardProps} from './CardType';
import {useCardLocation, getCardLocationError, useUser} from './CardUtils';
import {CardSettings} from './components';

import CardDraggableHandle from './components/CardDraggableHandle';
import CardSettingsHandle from './components/CardSettingsHandle';
import {CustomSettings} from './components/CardView';
import styles from './MiniCard.module.scss';

interface MiniCardProps<S> {
  backgroundColor?: string;
  borderColor?: string;
  children: ReactNode;
  customSettings?: CustomSettings<S>;
  settingsHandleClass?: string;
  settingsHandleColor?: string;

  settings: S;
  loading: boolean;
  ready?: boolean;

  error?: string | JSX.Element;
}

export function MiniCard<S extends ICardSettings>(props: MiniCardProps<S>) {
  const {
    backgroundColor,
    borderColor,
    children,
    settings,
    customSettings,
    settingsHandleClass,
    settingsHandleColor,
    loading,
    ready
  } = props;
  const {card, cardType} = useCardContext<S>();
  const {store, api} = useAppContext();

  const modals = useModals();
  const me = useUser();
  const {presenting, organizing} = useAppSelector(state => ({
    organizing: state.uiState.organizing,
    presenting: state.uiState.presenting
  }));

  const handleOpenSettings = () => {
    modals.show<boolean>(props => (
      <CardSettings
        settings={settings}
        title={T(cardType.title)}
        hasName={false}
        customSettings={customSettings}
        {...props}
      />
    ));
  };
  const location = useCardLocation(settings);

  const handleNavigateToParent = () => {
    if (!location || location.parentId === undefined) return;

    setContextLocationId(store, api, location.parentId);
  };

  let error: string | JSX.Element | undefined = props.error;
  if (!loading) {
    error = getCardLocationError(cardType, location, me, handleNavigateToParent) || error;
  }

  return (
    <div
      style={{
        backgroundColor: backgroundColor || 'white',
        // border: borderColor && `2px solid ${borderColor}`,
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
      className={classes(testingClasses.card, styles.miniCard, `t-card--${card.type}`)}
      data-ready={ready}
      data-testid={classes(testingClasses.card)}
    >
      {organizing && <CardDraggableHandle />}

      {error && <div className={styles.center}>{error}</div>}

      {!presenting && (
        <CardSettingsHandle
          className={classes(backgroundColor ? styles.settings : styles.settingsGreen, settingsHandleClass)}
          color={settingsHandleColor}
          onClick={handleOpenSettings}
        />
      )}

      {!error && children}
      {loading && <div className={styles.center}>{Icons.Loading}</div>}
    </div>
  );
}

export function miniCardProperties<S extends ICardSettings>(props: ICardProps<S>) {
  const {settings, loading} = props;
  return {settings, loading};
}
