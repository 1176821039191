import React from 'react';

import {TextInputGroup, validateSerialNumber} from '../../components/inputs/TextInput';
import {DeviceType, getDeviceTypeLabelFor, getSerialPrefix} from '../../models/DeviceType';

const positions = ['0', '1', '2'];
export function getChargingStationModuleLabel(type: DeviceType, position: number | undefined) {
  let label = '?';
  switch (type) {
    case DeviceType.ACChargingController:
      label = 'Charging controller';
      break;
    case DeviceType.RCM:
      label = 'Leakage current protection (RCM)';
      break;
    case DeviceType.MIDPoly:
      label = 'MID meter';
      break;
    case DeviceType.MIDPolyISKRA:
      label = 'ISKRA MID meter';
      break;
    case DeviceType.MIDSingle:
      label = 'MID meter (single-phase)';
      break;
    case DeviceType.LED:
      label = 'LED controller';
      break;
    case DeviceType.RFID:
      label = 'LED/RFID controller';
      break;
    case DeviceType.EthernetConnect:
      label = 'Connect';
      break;
    case DeviceType.FourGConnect:
      label = '4G Connect';
      break;
    case DeviceType.CarChargerSticker:
      label = 'Top sticker QR';
      break;
    default:
      label = getDeviceTypeLabelFor(type);
  }
  if (position !== undefined) {
    label += ` ${positions[position]}`;
  }
  return label;
}

export interface ModuleSerial {
  inputName: string;
  type: DeviceType;
  serialNumber: string;
  position?: number;
  width: number;
  offset: number;
}

export function validateChargingStationModuleSerial(type: DeviceType, value: string) {
  if (type === DeviceType.CarChargerSticker) {
    return value === '' || value.startsWith('https://ev.smappee.net/A') || value.startsWith('https://ev.smappee.net/U')
      ? undefined
      : 'Invalid QR code';
  }

  const serialLength = type === DeviceType.RCM ? 17 : 10;
  const serialPrefix = getSerialPrefix(type);
  return validateSerialNumber(value, serialPrefix, serialLength);
}

const URL_PATTERN = /https:\/\/ev.smappee.net\/([AU][0-9]{8})/;
export function postProcessModuleSerial(serial: string, type: DeviceType) {
  console.log('postProcess', serial, type);
  if (type === DeviceType.CarChargerSticker) {
    if (serial === '') return '';

    const match = URL_PATTERN.exec(serial);
    if (match) return match[1];
  }

  return serial;
}

interface ModuleInputProps {
  module: ModuleSerial;
  setModule: (serial: ModuleSerial) => void;
}
export const ModuleInput = (props: ModuleInputProps) => {
  const {module, setModule} = props;
  const {inputName, type, serialNumber, position, width, offset} = module;

  const handleSerialChanged = (value: string) => {
    setModule({inputName, serialNumber: value, type, position, width, offset});
  };

  return (
    <TextInputGroup
      name={inputName}
      label={getChargingStationModuleLabel(type, position)}
      value={serialNumber}
      onChange={handleSerialChanged}
      validate={value => validateChargingStationModuleSerial(type, value)}
    />
  );
};
