import React, {useMemo} from 'react';

import PeriodSelector, {
  getPeriodRangeForTimezone,
  Period,
  PeriodRoundingMode,
  PeriodSettings
} from '../../components/PeriodSelector';
import Table from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ChargingSessionExport} from '../../models/ChargingSessionExport';
import {ChargingStationPaymentTypeFilter} from '../../models/ChargingStation';
import {Interval} from '../../models/UsageValue';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {CardActions, ColumnChooser} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import {getColumns} from './Columns';
import {SessionFilter} from './Filter';

interface IExportChargingSessionsSettings extends ICardSettingsWithTable {
  period: PeriodSettings;
  paymentTypes?: ChargingStationPaymentTypeFilter[];
}

const defaultPaymentTypes = [ChargingStationPaymentTypeFilter.App, ChargingStationPaymentTypeFilter.RFID];

function ExportChargingSessions(props: ICardProps<IExportChargingSessionsSettings>) {
  const {settings, updateSettings} = props;
  const {period, paymentTypes = defaultPaymentTypes} = settings;

  const [data, refresh] = useCardLoader<ChargingSessionExport[] | undefined>(
    api => {
      // Billing period determined by Belgian timezone
      const {from, to} = getPeriodRangeForTimezone(period, 'Europe/Brussels', undefined, PeriodRoundingMode.INCLUSIVE);
      return api.getAllChargingSessions(from, to, paymentTypes);
    },
    [period, paymentTypes],
    plural('chargingSession'),
    undefined
  );

  const handlePaymentTypeChecked = (type: ChargingStationPaymentTypeFilter, checked: boolean) => {
    if (checked) {
      updateSettings({paymentTypes: [...paymentTypes, type]});
    } else {
      updateSettings({paymentTypes: paymentTypes.filter(x => x !== type)});
    }
  };

  const actions: CustomActions = state => (
    <CardActions>
      <PeriodSelector
        settings={settings.period}
        onChanged={period => updateSettings({period})}
        withoutInterval={true}
      />
      <Reload onReload={refresh} />
      {data && <ExportCsv fields={columns} items={data} settings={settings.table} name="charging-sessions" />}
      <SessionFilter paymentTypes={paymentTypes} onChangePaymentType={handlePaymentTypeChecked} />
    </CardActions>
  );

  const columns = useMemo(() => getColumns('Europe/Brussels'), []);

  const customSettings: CustomSettings<IExportChargingSessionsSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser settings={settings.table} updateSettings={table => updateSettings({table})} fields={columns} />
    );
  };

  return (
    <CardView actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      {data && (
        <Table
          fields={columns}
          items={data}
          settings={settings.table}
          updateSettings={settings => updateSettings({table: settings})}
        />
      )}
    </CardView>
  );
}

const DEFAULT_SETTINGS: IExportChargingSessionsSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'serialNumber', visible: true},
      {name: 'from', visible: true},
      {name: 'to', visible: true},
      {name: 'duration', visible: true},
      {name: 'rfid', visible: true},
      {name: 'paymentType', visible: true},
      {name: 'paymentStatus', visible: true},
      {name: 'discount', visible: true},
      {name: 'hostCost', visible: true},
      {name: 'operatorCost', visible: true},
      {name: 'driverCost', visible: true},
      {name: 'currency', visible: true},
      {name: 'energy', visible: true},
      {name: 'organizationName', visible: true},
      {name: 'splitBillingRefund', visible: true}
    ]
  },
  period: {
    period: Period.LAST_MONTH,
    interval: Interval.REALTIME
  }
};
const CARD: ICardType<IExportChargingSessionsSettings> = {
  type: CardTypeKey.ExportChargingSessions,
  title: 'exportChargingSessions.title',
  description: 'exportChargingSessions.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: ExportChargingSessions
};
export default CARD;
