import React, {useState} from 'react';

import {isAPIResponse} from '../../api/APIClient';
import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {PricesExclVat} from '../../components/Notes';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {Contract} from '../../models/Contract';
import {ServerErrorCode, translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';

interface ActivateCPOModalProps extends IPromiseModalProps<boolean> {
  organizationId: number;
  instantAvailable: boolean;
}

export default function ActivateCPOModal(props: ActivateCPOModalProps) {
  const {organizationId, instantAvailable} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const [error, setError] = useState<string>();

  const handleClick = () => {
    setError(undefined);
    if (instantAvailable) {
      return api.organizations
        .activate(organizationId, Contract.ChargingHost)
        .then(() => resolve(true))
        .catch((err: unknown) => {
          if (isAPIResponse(err)) {
            if (err.code === ServerErrorCode.BillingInfoMissing || err.code === ServerErrorCode.SepaMissing) {
              window.open(`/register-charging-host?organizationId=${organizationId}`);
              resolve(false);
            } else {
              setError(translateError(err, T('services.activate.cpo.failed')));
            }
          }
        });
    } else {
      window.open(`/register-charging-host?organizationId=${organizationId}`);
      resolve(false);
      return Promise.resolve();
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('services.activate.cpo.title')}
      action={handleClick}
      error={error}
      actionText={instantAvailable ? T('services.activate.cpo.action.instant') : T('services.activate.cpo.action')}
    >
      <p style={{marginTop: '1rem'}}>
        {instantAvailable ? T('services.activate.cpo.instant') : T('services.activate.cpo.message')}
      </p>
      <p>{T('services.activate.cpo.price', {dcFee: '20 €', acFee: '5 €'})}</p>
      <PricesExclVat />
    </SingleActionModal>
  );
}
