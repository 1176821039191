import * as React from 'react';

import {useAppContext} from '../../../app/context';
import {IOrganization} from '../../../models/Organization';
import {IPricingGroup} from '../../../models/PricingGroup';
import {ConfigurePricingGroup} from '../configurePricingGroup/ConfigurePricingGroup';

interface ConnectPricingGroupContextProps {
  organization: ConnectPricingGroupProviderProps['organization'];
  pricingGroups: IPricingGroup[];
  groupsInUse: number[];
  createNewGroup(): Promise<IPricingGroup | null>;
  editGroup(pricingGroup: IPricingGroup): Promise<IPricingGroup | null>;
}

const ConnectPricingGroupContext = React.createContext<ConnectPricingGroupContextProps | null>(null);
ConnectPricingGroupContext.displayName = 'ConnectPricingGroupContext';

interface ConnectPricingGroupProviderProps {
  groupsInUse: number[];
  organization?: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  children: React.ReactNode;
}

// import { ConnectPricingGroupProvider } from "path-to-context/ConnectPricingGroupContext"
// use <ConnectPricingGroupProvider> as a wrapper around the part you need the context for
function ConnectPricingGroupProvider({groupsInUse, organization, children}: ConnectPricingGroupProviderProps) {
  const [pricingGroups, setPricingGroups] = React.useState<IPricingGroup[]>([]);
  const [configuringGroup, setConfiguringGroup] = React.useState<boolean>(false);
  const [editingGroup, setEditingGroup] = React.useState<IPricingGroup | undefined>(undefined);
  const resolveEditGroup = React.useRef<(group: IPricingGroup) => void | undefined>();
  const {api} = useAppContext();

  React.useEffect(() => {
    async function getPricingGroups() {
      if (!organization?.id) return;

      const groups = await api.pricingGroups.getForOrganization(organization?.id);
      setPricingGroups(groups);
    }

    getPricingGroups();
  }, [api, organization, organization?.id]);

  const createNewGroup = React.useCallback<() => Promise<IPricingGroup>>(() => {
    setEditingGroup(undefined);
    setConfiguringGroup(true);
    return new Promise((resolve, reject) => {
      resolveEditGroup.current = resolve;
    });
  }, []);

  const editGroup = React.useCallback<(group: IPricingGroup) => Promise<IPricingGroup>>((group: IPricingGroup) => {
    setEditingGroup(group);
    setConfiguringGroup(true);
    return new Promise((resolve, reject) => {
      resolveEditGroup.current = resolve;
    });
  }, []);

  const onSaveAddGroup = React.useCallback((group: IPricingGroup | null) => {
    if (group) {
      setPricingGroups(groups => [...groups.filter(p => p.id !== group.id), group]);
      if (resolveEditGroup.current) {
        resolveEditGroup.current(group);
      }
    }
    setEditingGroup(undefined);
    setConfiguringGroup(false);
  }, []);

  if (!organization) return null;

  return (
    <ConnectPricingGroupContext.Provider
      value={{
        pricingGroups,
        organization,
        groupsInUse,
        createNewGroup,
        editGroup
      }}
    >
      <ConfigurePricingGroup
        isOpen={configuringGroup}
        group={editingGroup}
        organization={organization}
        resolve={onSaveAddGroup}
      />
      {children}
    </ConnectPricingGroupContext.Provider>
  );
}

// import { useConnectPricingGroup } fron "path-to-context/ConnectPricingGroupContext"
// within functional component
// const { sessionToken, ...ConnectPricingGroupContext } = useConnectPricingGroup()
function useConnectPricingGroup(): ConnectPricingGroupContextProps {
  const context = React.useContext(ConnectPricingGroupContext);

  if (!context) {
    throw new Error('You should use useConnectPricingGroup within an ConnectPricingGroupContext');
  }

  return context;
}

export {ConnectPricingGroupProvider, useConnectPricingGroup};
