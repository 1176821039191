import React, {useEffect, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {Alert, Modal} from '../../components/bootstrap';
import LocationInputGroup from '../../components/inputs/LocationInputGroup';
import {Button} from '../../components/ui/button';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ILocation, ILocationSearchResult, LocationFunctionType, isChargingParent} from '../../models/Location';
import {useLocationUsingAPI} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';

interface EditParentModalProps extends IPromiseModalProps<number | undefined> {
  parentId: number | undefined;
  location?: ILocation;
  chargingStation: boolean;
  filter?: (location: ILocationSearchResult) => boolean;
}

export default function EditParentModal(props: EditParentModalProps) {
  const {parentId, location, filter, chargingStation} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleToggle = () => resolve(undefined);
  const {api} = useAppContext();

  const [loadedLocation] = useLocationUsingAPI(api, parentId);
  const [parentLocation, setParentLocation] = useState<ILocation | undefined>();

  useEffect(() => setParentLocation(loadedLocation), [loadedLocation]);
  const handleLocationSelected = (id: number) => api.locations.get(id).then(setParentLocation);

  const handleClickedSave = () => {
    if (!parentLocation) return;

    resolve(parentLocation.id);
  };

  let error: string | undefined;
  if (parentLocation && parentLocation.functionType === LocationFunctionType.ChargingStation) {
    error = T('chargingStationConfiguration.setParent.cannotLinkToStation');
  } else if (parentLocation && parentLocation.parentId !== undefined) {
    error = T('chargingStationConfiguration.setParent.cannotSetChildAsParent');
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>
        {chargingStation
          ? T('chargingStationConfiguration.setParent.title')
          : T('locationConfiguration.setParent.title')}
      </ModalHeader>
      <ModalBody>
        {location && isChargingParent(location.functionType) && (
          <Alert color="warning">
            <p>{T('locationConfiguration.property.parent.promote.message')}</p>
            {location.serialNumber ? <p>{T('locationConfiguration.property.parent.promote.message.connect')}</p> : null}
          </Alert>
        )}
        <LocationInputGroup
          label={T('chargingStationConfiguration.setParent.label')}
          location={parentLocation}
          onSelected={handleLocationSelected}
          error={error}
          filter={filter}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary_default" size="lg" onClick={handleClickedSave} disabled={error !== undefined}>
          {T('chargingStationConfiguration.setParent.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
