import type {SVGProps} from 'react';
const SvgChevronBackward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M10.878 2.222a1.1 1.1 0 0 1 0 1.556L6.656 8l4.222 4.222a1.1 1.1 0 1 1-1.556 1.556l-5-5a1.1 1.1 0 0 1 0-1.556l5-5a1.1 1.1 0 0 1 1.556 0" />
  </svg>
);
export default SvgChevronBackward;
