import Blob from 'blob';
import FileSaver from 'file-saver';
import {useMemo, useState} from 'react';

import {useAppContext} from '../../app/context';
import {Input} from '../../components/bootstrap';
import Table, {migrateTableSettings} from '../../components/Table';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ContractDetails} from '../../models/Contract';
import {None} from '../../utils/Arrays';
import {useDelayedLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {CardActions, ColumnChooser} from '../components';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import {getTableColumns} from './Columns';
import {DeleteContractModal} from './DeleteContractModal';
import {EditContractModal} from './EditContractModal';
import {MoveContractModal} from './MoveContractModal';
import {NotesModal} from './NotesModal';

type ContractsCardSettings = ICardSettingsWithTable;

function ContractsCard(props: ICardProps<ContractsCardSettings>) {
  const {settings, updateSettings} = props;
  const {api} = useAppContext();
  const modals = useModals();

  const [input, setInput] = useState<string>('');
  const [contracts, , refreshContracts] = useDelayedLoader<ContractDetails[]>(
    () => (input === '' ? Promise.resolve([]) : api.contracts.search(input)),
    [api, input],
    500,
    'contracts'
  );
  const columns = useMemo(() => {
    const handleClickedNotes = (row: ContractDetails) => {
      modals.show(props => <NotesModal contract={row} {...props} />);
    };

    const handleClickedDownload = (row: ContractDetails) => {
      api.contracts.download(row.id).then(data => {
        const content = new Blob([data], {type: 'application/pdf'});
        const filename = `contract-${row.id}.pdf`;
        FileSaver.saveAs(content, filename);
      });
    };

    const handleClickedEdit = (row: ContractDetails) => {
      modals
        .show(props => <EditContractModal contract={row} {...props} />)
        .then(updated => updated && refreshContracts());
    };

    const handleClickedMove = (row: ContractDetails) => {
      modals
        .show(props => <MoveContractModal contract={row} {...props} />)
        .then(updated => updated && refreshContracts());
    };

    const handleClickedDelete = (row: ContractDetails) => {
      modals
        .show(props => <DeleteContractModal contract={row} closeOnly={false} {...props} />)
        .then(updated => updated && refreshContracts());
    };

    return getTableColumns({
      onClickedNotes: handleClickedNotes,
      onClickedDownload: handleClickedDownload,
      onClickedEdit: handleClickedEdit,
      onClickedMove: handleClickedMove,
      onClickedDelete: handleClickedDelete
    });
  }, [api, modals, refreshContracts]);

  const actions: CustomActions = state => (
    <CardActions>
      <Input
        name="query"
        type="text"
        autoWidth={true}
        placeholder={T('contracts.search')}
        onChange={e => setInput(e.currentTarget.value)}
        value={input}
        className={testingClasses.searchUsers}
        data-testid={testingClasses.searchUsers}
      />
    </CardActions>
  );

  const customSettings: CustomSettings<ContractsCardSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser fields={columns} settings={settings.table} updateSettings={table => updateSettings({table})} />
    );
  };

  return (
    <CardView customSettings={customSettings} actions={actions} {...cardViewProps(props)}>
      <Table
        fields={columns}
        items={contracts || None}
        rowKey={c => c.id}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
}

const DEFAULT_SETTINGS: ContractsCardSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'id', visible: false},
      {name: 'contract', visible: true},
      {name: 'signedAt', visible: true},
      {name: 'signedByUsername', visible: true},
      {name: 'signedByEmail', visible: true},
      {name: 'organization', visible: true},
      {name: 'closed', visible: false},
      {name: 'billedUntil', visible: true},
      {name: 'notes', visible: true}
    ]
  }
};

const CARD: ICardType<ContractsCardSettings> = {
  type: CardTypeKey.Contracts,
  title: 'contracts.title',
  description: 'contracts.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: ContractsCard
};
export default CARD;
