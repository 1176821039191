import React from 'react';

import {SVGGraphics} from '../../utils/SVGGraphics';

const MAGNITUDE_PADDING = 20;

export class SVGDisplayBuilder {
  graphics: SVGGraphics;
  centerX: number;
  centerY: number;

  constructor(width: number, height: number) {
    this.graphics = new SVGGraphics(width, height);

    this.centerX = width / 2;
    this.centerY = height / 2;
  }

  addFilledCircle(id: string, color: string, radius: number) {
    this.graphics.addGraphicsItem(
      <circle id={id} key={id} cx={this.centerX} cy={this.centerY} r={radius} fill={color} />
    );
  }

  addStrokedCircle(id: string, color: string, radius: number, strokeWidth: number) {
    this.graphics.addGraphicsItem(
      <circle
        id={id}
        key={id}
        cx={this.centerX}
        cy={this.centerY}
        r={radius}
        fill="rgba(0,0,0,0)"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    );
  }

  addVector(
    id: string,
    primaryColor: string,
    secondaryColor: string,
    label: string,
    length: number,
    angleInRadians: number
  ) {
    const arrowMarkerId = `${id}Arrow`;
    const outerArrowMarkerId = `${id}ArrowOuter`;
    this.graphics.addArrowMarkerDef(arrowMarkerId, primaryColor);
    this.graphics.addOuterArrowMarkerDef(outerArrowMarkerId, secondaryColor);

    const sinAngle = Math.sin(-angleInRadians);
    const cosAngle = Math.cos(-angleInRadians);

    const toX = this.centerX + (length - 4) * cosAngle;
    const toY = this.centerY - (length - 4) * sinAngle;

    const labelX = this.centerX + (length + MAGNITUDE_PADDING) * cosAngle;
    const labelY = this.centerY - (length + MAGNITUDE_PADDING) * sinAngle;

    this.graphics.addGraphicsItem(
      <g id={id} key={id}>
        <g>
          <line
            strokeWidth="4"
            markerEnd={`url(#${outerArrowMarkerId})`}
            x1={this.centerX}
            y1={this.centerY}
            x2={toX}
            y2={toY}
            stroke={secondaryColor}
          />
          <line
            strokeWidth="2"
            markerEnd={`url(#${arrowMarkerId})`}
            x1={this.centerX}
            y1={this.centerY}
            x2={toX}
            y2={toY}
            stroke={primaryColor}
          />
        </g>

        <text dx="-2" dy="-2" x={labelX} y={labelY}>
          {label}
        </text>
      </g>
    );
  }

  render() {
    return this.graphics.render();
  }
}
