import React from 'react';
import {Alert as RsAlert, AlertProps} from 'reactstrap';

import styles from './Alert.module.scss';

export interface IAlertProps extends AlertProps {
  fixed?: boolean;
}

export function Alert(props: IAlertProps) {
  let {color, fixed = true, className, ...otherProps} = props;

  // Hook extra classes into the component
  className = [styles.alert, color, fixed ? 'fixed' : '', className ? className : ''].join(' ');

  return (
    <RsAlert color={color} className={className} {...otherProps}>
      {props.children}
    </RsAlert>
  );
}
