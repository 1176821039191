import React from 'react';
import {Navbar as RsNavbar, NavbarProps} from 'reactstrap';

import './Navbar.scss';

export const Navbar = React.memo((props: NavbarProps) => {
  let {className, ...otherProps} = props;

  // Hook extra classes into the component
  className = ['dash-navbar', className || ''].join(' ');

  return (
    <RsNavbar className={className} {...otherProps}>
      {props.children}
    </RsNavbar>
  );
});
