import React, {useState, useEffect, useCallback} from 'react';

import {Button as RsButton, Label, Modal, ModalHeader, ModalBody, ModalFooter} from '../../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {T} from '../../../utils/Internationalization';

import styles from './PicturesModal.module.scss';

interface PicturesModalProps extends IPromiseModalProps<void> {
  pictures: any[];
}
export default (props: PicturesModalProps) => {
  const {pictures} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve();

  const [pictureIndex, setPictureIndex] = useState(0);

  const showNextPicture = useCallback(() => {
    setPictureIndex(index => Math.min(pictures.length - 1, index + 1));
  }, [pictures]);

  const showPrevPicture = useCallback(() => {
    setPictureIndex(index => Math.max(0, index - 1));
  }, []);

  useEffect(() => {
    const keyDownListener = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        showNextPicture();
      } else if (event.key === 'ArrowLeft') {
        showPrevPicture();
      }
    };

    document.addEventListener('keydown', keyDownListener);
    return () => document.removeEventListener('keydown', keyDownListener);
  }, [showNextPicture, showPrevPicture]);

  const picture = pictures[pictureIndex];
  const {url} = picture;
  const title = T('locationConfiguration.installationPictures.itemTitle', {
    index: (pictureIndex + 1).toString(),
    total: pictures.length.toString()
  });

  const pictureView = (
    <div className={styles.picture}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img alt="" src={url} title={title} />
      </a>
    </div>
  );

  const isLast = pictures.length - 1 === pictureIndex;
  const isFirst = pictureIndex === 0;

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="xl" autoFocus={false}>
      <ModalHeader toggle={handleClose}>Installation pictures</ModalHeader>
      <ModalBody>{pictureView}</ModalBody>
      <ModalFooter className={styles.navigation}>
        <RsButton onClick={showPrevPicture} disabled={isFirst}>
          <span className="fal fa-chevron-left" /> {T('locationConfiguration.installationPictures.previous')}
        </RsButton>
        <div style={{flex: 1}} />
        <Label>
          {pictureIndex + 1} / {pictures.length}
        </Label>
        <div style={{flex: 1}} />
        <RsButton onClick={showNextPicture} disabled={isLast}>
          {T('locationConfiguration.installationPictures.next')} <span className="fal fa-chevron-right" />
        </RsButton>
      </ModalFooter>
    </Modal>
  );
};
