import React from 'react';

import styles from './WarningView.module.scss';

interface CompleteConfigViewProps {
  message: string;
  info: string;
  action?: JSX.Element;
  color?: 'info' | 'error';
}

export default function WarningView(props: CompleteConfigViewProps) {
  const {action, message, info, color = 'error'} = props;

  return (
    <div className={color === 'error' ? styles.errorView : styles.infoView}>
      <span className="fa-stack fa-2x">
        <i className="fas fa-square fa-stack-2x" />
        <i className="fas fa-exclamation-triangle fa-stack-1x fa-inverse" />
      </span>
      <div className={styles.info}>
        <h2>{message}</h2>
        <h3>{info}</h3>
      </div>
      {action}
    </div>
  );
}
