import dayjs from 'dayjs';
import numeral from 'numeral';

import {HourFormat} from '../redux/AppState';
import 'dayjs/plugin/updateLocale';

let globalHourFormat: HourFormat;

export function setLocale(locale: string, hourFormat: HourFormat, thousandSeparator: string, decimalSeparator: string) {
  globalHourFormat = hourFormat;
  dayjs.updateLocale(locale, {
    weekStart: 1
    // doy: 4
  });

  if (hourFormat !== HourFormat.Auto) {
    if (hourFormat === HourFormat.AmPm) {
      dayjs.updateLocale(locale, {
        formats: {
          LT: 'h:mm A',
          LTS: 'h:mm:ss A',
          L: 'DD-MM-YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY h:mm A',
          LLLL: 'dddd D MMMM YYYY h:mm A'
        }
      });
    } else {
      dayjs.updateLocale(locale, {
        formats: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD-MM-YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY HH:mm',
          LLLL: 'dddd D MMMM YYYY HH:mm'
        }
      });
    }
  }

  dayjs.locale(locale);
  (numeral as any).locales[locale.toLocaleLowerCase()] = {
    delimiters: {
      thousands: thousandSeparator === '-' ? '' : thousandSeparator,
      decimal: decimalSeparator
    }
  };

  numeral.locale(locale);
}

export function isAMPMTime() {
  return globalHourFormat === HourFormat.AmPm;
}
