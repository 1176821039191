import React, {CSSProperties} from 'react';

import {ITableField, IFieldOptions, DEFAULT_OPTIONS} from '../../models/Table';

export class LoadNameField<T> implements ITableField<T> {
  name: string;
  label: string;
  value: (item: T) => string | undefined;
  options: IFieldOptions;

  constructor(
    name: string,
    label: string,
    value: (item: T) => string | undefined,
    options: Partial<IFieldOptions> = {}
  ) {
    this.name = name;
    this.label = label;
    this.value = value;
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  renderCellContent(item: T) {
    const value = this.value(item);
    if (value === undefined) return undefined;

    if (value.length < 20) {
      return value;
    } else {
      const splitAt = value.length - 10;
      const style: CSSProperties = {
        whiteSpace: 'nowrap',
        display: 'flex',
        width: this.options.width,
        flexDirection: 'row'
      };
      return (
        <div style={style} title={value}>
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexShrink: 1
            }}
          >
            {value.substring(0, splitAt)}
          </span>
          {value.substring(splitAt)}
        </div>
      );
    }
  }

  getExportCSVValue(item: T) {
    return this.value(item) || '';
  }

  getExportExcelValue(item: T) {
    return this.value(item) || '';
  }

  sort = (a: T, b: T): number => {
    const aValue = this.value(a) || '';
    const bValue = this.value(b) || '';
    return aValue.localeCompare(bValue);
  };
}
