import {useCallback} from 'react';

import {IconButton} from '../../components/IconButton';
import {ConfigurePricingGroupModal} from '../../components/pricingPolicies/configurePricingGroup/ConfigurePricingGroup';
import {useModals} from '../../modals/ModalContext';
import RemoveModal from '../../modals/RemoveModal';
import {IOrganization} from '../../models/Organization';

import {IPricingGroup} from '../../models/PricingGroup';
import {T, quantity} from '../../utils/Internationalization';

import styles from './index.module.css';

interface PricingGroupItemProps {
  onUpdate: (group: IPricingGroup) => void;
  onDelete?: (id: number) => void;
  organization: IOrganization;
  group: IPricingGroup;
}

function PricingGroupItem(props: PricingGroupItemProps) {
  const {onUpdate, organization, onDelete, group} = props;
  const modals = useModals();
  const {name, members} = group;

  const handleDelete = useCallback(async () => {
    if (group.id === undefined) {
      return;
    }

    modals
      .show(props => (
        <RemoveModal
          title={T('pricingGroups.delete.confirm.title')}
          message={T('pricingGroups.delete.confirm.message', {name})}
          execute={api => api.pricingGroups.delete(organization.id, group.id!).then(() => onDelete?.(group.id!))}
          successMessage={T('pricingGroups.delete.success')}
          failureMessage={T('pricingGroups.delete.failed')}
          {...props}
        />
      ))
      .then(success => success && onDelete?.(group.id!));
  }, [onDelete, modals, name, organization.id, group.id]);

  const handleClickedEdit = () => {
    modals
      .show<IPricingGroup | null>(props => (
        <ConfigurePricingGroupModal group={group} organization={organization} {...props} />
      ))
      .then(updated => updated && onUpdate(updated));
  };

  return (
    <div className={styles.pricingGroupItem}>
      <div className={styles.pricingGroupCore}>
        <h3 className={styles.pricingGroupName}>{name}</h3>
        <div>
          {members &&
            (members.length == 0 ? (
              <span className={styles.pricingGroupEmpty}>{T('pricingGroups.containsMembers.empty')}</span>
            ) : (
              <span className={styles.pricingGroupMembers}>
                {T('pricingGroups.containsMembers', {
                  tokens: quantity('rfidCard', members.length)
                })}
              </span>
            ))}
          {group.policyCount !== undefined && (
            <span className={styles.pricingGroupMembers}>
              {' '}
              &middot;{' '}
              {group.policyCount === 0 ? (
                <span className={styles.pricingGroupEmpty}>{T('pricingGroups.usedInPolicies.none')}</span>
              ) : (
                T('pricingGroups.usedInPolicies', {policies: quantity('pricingPolicy', group.policyCount || 0)})
              )}
            </span>
          )}
        </div>
      </div>
      <IconButton action="default" icon="Pencil" color="link" onClick={handleClickedEdit} />
      <IconButton action="delete" color="link" onClick={handleDelete} />
    </div>
  );
}

export {PricingGroupItem};
