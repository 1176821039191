import {classes} from '../utils/Styles';

import styles from './PaperCard.module.scss';

interface PaperCardProps {
  className?: string;
  children: React.ReactNode;
}
export function PaperCard(props: PaperCardProps) {
  const {children, className} = props;
  return <div className={classes(styles.card, className)}>{children}</div>;
}
