import Blob from 'blob';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import React, {useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {v1 as uuidv1} from 'uuid';

import {withAppContext, useAppContext} from '../../app/context';
import {Button as RsButton, FormGroup, FormText, Input, Label} from '../../components/bootstrap';
import {Flex} from '../../components/Flex';
import {FORMAT_FILENAME_TIMESTAMP} from '../../core/constants';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {CardView, cardViewProps} from '../components/CardView';

import styles from './index.module.scss';

// Constants
const ERROR_HEADER = 'Error';

const FutechImporter = (props: ICardProps<ICardSettings>) => {
  const {api} = useAppContext();

  const [file, setFile] = useState<File>();
  const [contents, setContents] = useState<string[]>();
  const [fileId, setFileId] = useState(`file${uuidv1()}`);
  const [feedback, setFeedback] = useState<string>();
  const [remarks, setRemarks] = useState<string[]>();

  const isValid = file !== undefined && contents !== undefined;

  const handleFileRead = (reader: FileReader) => {
    const {result} = reader;
    const contents = typeof result === 'string' ? result.split('\n') : [];
    setContents(contents);
  };

  const handleFileChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const {files} = event.currentTarget;
    if (!files) return;

    const reader = new FileReader();
    reader.onloadend = () => handleFileRead(reader);
    reader.readAsText(files[0]);
    setFile(files[0]);
  };

  const prepareRemarks = (failures: {lineNumber: number; error: string}[]): string[] => {
    if (!contents) return [];

    const header = [contents[0].trim()];
    const remarks = [`${header},${ERROR_HEADER}`];

    // Append error after trimming invisible line breaks
    for (let failure of failures) {
      const {lineNumber, error} = failure;
      const line = contents[lineNumber].trim();
      remarks.push(`${line},${error}`);
    }

    return remarks;
  };

  const handleClickSave = () => {
    if (!file || !contents) return;

    // Notify the user of active progress
    setFeedback(T('futechImporter.importing'));

    // Import data
    api.importFutechData(file).then(result => {
      const {status, data} = result;
      const failures = data ? data.failures : null;
      const hasFailures = Array.isArray(failures) && failures.length > 0;

      if (status === 200 && !hasFailures) {
        NotificationManager.success(T('futechImporter.success'));
        setFeedback(undefined);
        setRemarks(undefined);
        handleReset(false);
      } else if (hasFailures && failures.length < contents.length - 1) {
        const remarks = prepareRemarks(failures);
        const feedback = T('futechImporter.partialSuccess', {
          failures: failures.length.toString()
        });
        setFeedback(feedback);
        setRemarks(remarks);
        handleReset(false);
      } else {
        setFeedback(T('futechImporter.failed'));
      }
    });
  };

  const handleReset = (resetFeedback: boolean) => {
    setContents(undefined);
    setFileId(`file${uuidv1()}`);
    setFeedback(resetFeedback ? undefined : feedback);
  };

  const handleClickReset = () => {
    handleReset(true);
  };

  const handleDownloadFailures = () => {
    if (!remarks) return;

    const file = `${dayjs().format(FORMAT_FILENAME_TIMESTAMP)} Import failures.csv`;
    const text = remarks.join('\r\n');

    // Save content as blob
    const content = new Blob([text], {type: 'text/csv;charset=utf-8'});

    // Initiate download that works for all browsers
    FileSaver.saveAs(content, file);
  };

  const form = (
    <FormGroup>
      <Label>{T('futechImporter.fileToImport')}</Label>
      <Input
        key={fileId}
        type="file"
        name="file"
        onChange={handleFileChanged}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <FormText>{T('futechImporter.selectCSVToImport')}</FormText>
    </FormGroup>
  );

  return (
    <CardView {...cardViewProps(props)}>
      {form}
      <Flex justifyContent="start" className={styles.footerActions}>
        <RsButton color="primary" onClick={handleClickSave} disabled={!isValid}>
          {T('futechImporter.actions.upload')}
        </RsButton>
        <RsButton onClick={handleClickReset}>{T('futechImporter.actions.reset')}</RsButton>
      </Flex>
      <p style={{marginTop: '15px'}}>{feedback}</p>

      {Array.isArray(remarks) && remarks.length > 0 && (
        <p>
          <RsButton color="link" withoutPadding onClick={handleDownloadFailures}>
            {T('futechImporter.clickToDownloadFailed')}
          </RsButton>
        </p>
      )}
    </CardView>
  );
};

const BoundFutechImporter = withAppContext(FutechImporter);
const CARD_TYPE: ICardType<ICardSettings> = {
  type: CardTypeKey.FutechImporter,
  title: 'futechImporter.title',
  description: 'futechImporter.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Unaware,
  cardClass: BoundFutechImporter
};
export default CARD_TYPE;
