import React, {useState, useMemo, useEffect} from 'react';

import {useAppContext} from '../../app/context';
import {Input} from '../../components/bootstrap';
import Table, {migrateTableSettings} from '../../components/Table';
import {LocationIDField} from '../../components/Table/Fields';
import API from '../../core/api';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IDeviceActivationHistoryEntry} from '../../models/DeviceActivationHistory';
import {ITableField, TimestampCalculatedField, CalculatedStringField} from '../../models/Table';
import {AppStore} from '../../redux';
import {useDeviceActivationHistory} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

type DeviceActivationHistorySettings = ICardSettingsWithTable;

function getTableColumns(
  api: API,
  store: AppStore,
  locationId: number | undefined
): ITableField<IDeviceActivationHistoryEntry>[] {
  return [
    new TimestampCalculatedField('from', T('deviceActivationHistory.field.from'), entry => entry.period.from),
    new TimestampCalculatedField('to', T('deviceActivationHistory.field.to'), entry => entry.period.to),
    new LocationIDField(
      'location',
      T('deviceActivationHistory.field.location'),
      entry => entry.serviceLocation,
      locationId,
      api,
      store
    ),
    new CalculatedStringField(
      'activationCode',
      T('deviceActivationHistory.field.activationCode'),
      entry => entry.activationCode.code
    ),
    new CalculatedStringField(
      'activationCodeName',
      T('deviceActivationHistory.field.activationCodeName'),
      entry => entry.activationCode.name
    ),
    new CalculatedStringField(
      'organization',
      T('deviceActivationHistory.field.organization'),
      entry => entry.organization.name
    )
  ];
}

const IndexRowKey = (item: any, index: number) => index;

const DeviceActivationHistory = (props: ICardProps<DeviceActivationHistorySettings>) => {
  const {fetch, settings, updateSettings} = props;

  const {api, store} = useAppContext();
  const location = useCardLocation(settings);
  const [serial, setSerial] = useState('');
  const history = useDeviceActivationHistory(fetch, serial);

  const locationSerial = location && location.serialNumber;
  useEffect(() => {
    locationSerial && setSerial(locationSerial);
  }, [locationSerial]);

  const handleSerialChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSerial(e.currentTarget.value);
  };

  const actions: CustomActions = state => (
    <CardActions>
      <Input
        type="text"
        value={serial}
        placeholder={T('deviceActivationHistory.serial.placeholder')}
        onChange={handleSerialChanged}
        style={{width: 200}}
      />
    </CardActions>
  );
  const locationId = location && location.id;
  const fields = useMemo(() => getTableColumns(api, store, locationId), [api, store, locationId]);

  return (
    <CardView {...cardViewProps(props)} actions={actions}>
      <Table
        items={history}
        fields={fields}
        rowKey={IndexRowKey}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noun="configuration"
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: DeviceActivationHistorySettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'from', visible: true},
      {name: 'to', visible: true},
      {name: 'location', visible: true},
      {name: 'activationCode', visible: false},
      {name: 'activationCodeName', visible: true},
      {name: 'organization', visible: true}
    ]
  }
};
const CARD: ICardType<DeviceActivationHistorySettings> = {
  type: CardTypeKey.DeviceActivationHistory,
  title: 'deviceActivationHistory.title',
  description: 'deviceActivationHistory.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Aware,
  cardClass: DeviceActivationHistory,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table)
};
export default CARD;
