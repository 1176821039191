import {IDeviceInputChannel, ChannelValueType} from '../../models/Device';
import {ITableField, TimestampFieldWithTimezone, CalculatedNumberField} from '../../models/Table';

import {T} from '../../utils/Internationalization';

import {InputModuleValue} from './Models';

export function getTableFields(
  inputChannels: IDeviceInputChannel[],
  timezone: string
): ITableField<InputModuleValue>[] {
  const fixed: ITableField<InputModuleValue>[] = [
    new TimestampFieldWithTimezone('timestamp', 'timestamp', T('inputModules.field.timestamp'), timezone, {
      alwaysVisible: true,
      autoInsert: 'start'
    })
  ];

  const channels: ITableField<InputModuleValue>[] = inputChannels
    .filter(channel => channel.valueType === ChannelValueType.Cumulative)
    .map(
      (channel, index) =>
        new CalculatedNumberField(`channel${channel.id}`, channel.name, row => {
          const value = row.values[index];
          return value === null ? undefined : value;
        })
    );
  return [...fixed, ...channels];
}

export const rowKey = (row: any, index: number) => index;
