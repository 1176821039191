import {ActivePeriod} from '../components/PeriodSelector';
import API from '../core/api';
import {IChargingStation} from '../models/ChargingStation';
import {IConsumptionValue} from '../models/ConsumptionValue';
import {IHighLevelConfiguration, PhaseType} from '../models/HighLevelConfiguration';
import {ILocationSummary} from '../models/Location';

export function isDualUltraAssembly(location: ILocationSummary) {
  return (
    location.chargingStation?.model === 'ULTRA_DUAL_CABLE' && (location.chargingStation?.serialNumber || '').length < 11
  );
}

export function getDualUltraConsumption(
  api: API,
  serviceLocationId: number,
  stationSerialNumber: string,
  period: ActivePeriod
): Promise<IConsumptionValue[]> {
  return api.chargingStations.getBySerial(stationSerialNumber).then(station => {
    const parts = station.parts!.map(part =>
      api
        .getElectricityConsumption(part.serviceLocation.id, period)
        .then(data => ({data, serviceLocationId: part.serviceLocation.id}))
    );
    return Promise.all(parts).then(parts => {
      const merged = parts.reduce((acc: MergedValue[], part) => merge(acc, part.serviceLocationId, part.data), []);
      return combine(serviceLocationId, merged);
    });
  });
}

interface MergedValue {
  timestamp: number;
  values: IConsumptionValue[];
}

function merge(existing: MergedValue[], childId: number, data: IConsumptionValue[]): MergedValue[] {
  let existingIndex = 0;
  let dataIndex = 0;
  while (existingIndex < existing.length && dataIndex < data.length) {
    const existingValue = existing[existingIndex];
    const dataValue = data[dataIndex];
    if (existingValue.timestamp === dataValue.timestamp) {
      existing[existingIndex].values[childId] = dataValue;
      existingIndex++;
      dataIndex++;
    } else if (existingValue.timestamp < dataValue.timestamp) {
      existingIndex++;
    } else {
      existing.splice(existingIndex, 0, {
        timestamp: dataValue.timestamp,
        values: [dataValue]
      });
      existingIndex++;
      dataIndex++;
    }
  }
  while (dataIndex < data.length) {
    existing.push({
      timestamp: data[dataIndex].timestamp,
      values: [data[dataIndex]]
    });
    dataIndex++;
  }
  return existing;
}

interface SummedConsumptionValue extends IConsumptionValue {
  value: number;
  solar: number;
  alwaysOn: number;
  import: number;
  export: number;
  solarForecast: number;
  loadForecast: number;

  childData: IConsumptionValue[];
}

function combine(serviceLocationId: number, merged: MergedValue[]): IConsumptionValue[] {
  return merged.map(value => {
    const values = value.values;
    const summed = values.reduce(
      (acc: SummedConsumptionValue, value: IConsumptionValue) => ({
        timestamp: acc.timestamp,
        serviceLocationId: acc.serviceLocationId,
        aggregation: acc.aggregation,
        value: acc.value! + (value.value || 0),
        solar: acc.solar! + (value.solar || 0),
        alwaysOn: acc.alwaysOn! + (value.alwaysOn || 0),
        import: acc.import! + (value.import || 0),
        export: acc.export! + (value.export || 0),

        solarForecast: acc.solarForecast! + (value.solarForecast || 0),
        loadForecast: acc.loadForecast! + (value.loadForecast || 0),

        childData: [...acc.childData, value]
      }),
      {
        timestamp: values[0].timestamp,
        serviceLocationId,
        aggregation: values[0].aggregation,

        value: 0,
        solar: 0,
        alwaysOn: 0,
        import: 0,
        export: 0,

        solarForecast: 0,
        loadForecast: 0,

        childData: []
      }
    );
    return summed;
  });
}

export function getDualUltraHighLevelConfiguration(
  api: API,
  serviceLocationId: number,
  station: IChargingStation
): Promise<IHighLevelConfiguration> {
  const parts = station.parts!.map(part => api.getHighLevelConfiguration(part.serviceLocation.id));
  return Promise.all(parts).then(parts => {
    const combined = {
      underConstruction: false,
      locationId: serviceLocationId,
      phaseType: PhaseType.Star,
      nilm: false,
      measurements: parts.flatMap(part =>
        part.measurements.map(m => {
          m.serviceLocationId = part.locationId;
          return m;
        })
      )
    };
    return combined;
  });
}
