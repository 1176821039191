import React, {useState} from 'react';

import {isAPIResponse} from '../../api/APIClient';
import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {PricesExclVat} from '../../components/Notes';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {Contract} from '../../models/Contract';
import {ServerErrorCode, translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';

interface ActivateEMSPModalProps extends IPromiseModalProps<boolean> {
  organizationId: number;
  instantAvailable: boolean;
}

export default function ActivateEMSPModal(props: ActivateEMSPModalProps) {
  const {organizationId, instantAvailable} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const [error, setError] = useState<string>();

  const handleClick = () => {
    setError(undefined);
    if (instantAvailable) {
      return api.organizations
        .activate(organizationId, Contract.eMSPBusiness)
        .then(() => {
          resolve(true);
        })
        .catch((err: unknown) => {
          if (isAPIResponse(err)) {
            if (err.code === ServerErrorCode.BillingInfoMissing || err.code === ServerErrorCode.SepaMissing) {
              window.open(`/register-emsp-business?organizationId=${organizationId}`);
              resolve(false);
            } else {
              setError(translateError(err, T('publicChargingTokens.activate.failed')));
            }
          }
        });
    } else {
      window.open(`/register-emsp-business?organizationId=${organizationId}`);
      resolve(false);
      return Promise.resolve();
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('publicChargingTokens.activate.title')}
      action={handleClick}
      error={error}
      actionText={
        instantAvailable ? T('publicChargingTokens.activate.action.instant') : T('publicChargingTokens.activate.action')
      }
    >
      <p style={{marginTop: '1rem'}}>
        {instantAvailable ? T('publicChargingTokens.activate.instant') : T('publicChargingTokens.activate.message')}
      </p>
      <p>{T('publicChargingTokens.activate.price', {perSessionFee: '0,35 €'})}</p>
      <PricesExclVat />
    </SingleActionModal>
  );
}
