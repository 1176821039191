import {v1 as uuidv1} from 'uuid';

import {log} from '../utils/Logging';

import {IFroggyDelta} from './LiveDataModels';

function getBytesFromString(x: string) {
  const bytes = [];
  if (x) {
    for (var i = 0; i < x.length; ++i) {
      bytes.push(x.charCodeAt(i));
    }
  }
  return bytes;
}

function getBytesFromInt(x: number, length: number) {
  const bytes = [];
  for (var i = length - 1; i >= 0; i--) {
    bytes[i] = x & 0xff;
    x = x >> 8;
  }
  return bytes;
}

function makeTrackingMessageForSerialNumberAndUuidSender(serialNumber: string, uuidSender: string) {
  var bTrackingMessage = [];
  bTrackingMessage.push(1); // 00: Version 1 of this Message structure
  bTrackingMessage.push(0); // 01: Flags
  bTrackingMessage.push(36); // 02: Length of the UUID of this message
  bTrackingMessage.push(10); // 03: Length of the SerialNumber (TO Address ..Smappee Monitor )
  bTrackingMessage.push(36); // 04: Length of random UUID of the Message
  bTrackingMessage.push(0); // 05: Length of Payload .. the JSON Message sent further, Big Endian
  bTrackingMessage.push(0); // 06:
  bTrackingMessage.push(0); // 07:
  bTrackingMessage.push(102); // 08: End length of Payload
  bTrackingMessage.push(0); // 09: Message Type byte 1 BigEndian
  bTrackingMessage.push(2); // 10: Type byte 2  .. Generic Message = 0x0002, Status Delivery = 0x0005, etc.

  // Make a Timestamp
  var ts = new Date().getTime();
  var bHeaderTimestampOfThisMessage = getBytesFromInt(ts, 8);
  for (var j = 0; j < bHeaderTimestampOfThisMessage.length; j++) {
    bTrackingMessage.push(bHeaderTimestampOfThisMessage[j]);
  }

  // Our UUID, the Sender UUID
  var bHeaderUUIDSender = getBytesFromString(uuidSender);
  bTrackingMessage[2] = bHeaderUUIDSender.length & 0xff; // Length of the UUID, should be 36
  for (j = 0; j < bHeaderUUIDSender.length; j++) {
    bTrackingMessage.push(bHeaderUUIDSender[j]);
  }

  // The Serial number of the Smappee Monitor we want to Track
  var bHeaderSerialNumber = getBytesFromString(serialNumber);
  bTrackingMessage[3] = bHeaderSerialNumber.length & 0xff; // Length of the SerialNumber, in case length changes over time
  for (j = 0; j < bHeaderSerialNumber.length; j++) {
    bTrackingMessage.push(bHeaderSerialNumber[j]);
  }

  // A random based UUID for the payload / Json message
  var uuidMessage = uuidv1();
  var bHeaderUUIDofMessage = getBytesFromString(uuidMessage);
  bTrackingMessage[4] = bHeaderUUIDofMessage.length & 0xff; // should be 36
  for (j = 0; j < bHeaderUUIDofMessage.length; j++) {
    bTrackingMessage.push(bHeaderUUIDofMessage[j]);
  }

  // The message to track, having code 257, adding a timestamp and
  var MESSAGE_START_TRACKING = 257;
  var payload = {
    messageType: MESSAGE_START_TRACKING,
    timestamp: ts,
    content: {}
  };
  payload.content = {jid: uuidSender}; // Send the "Tracking Message as data/content in the payload of this message
  var payloadJSONstringified = JSON.stringify(payload);

  //var messageToSend = '{"messageType":' + MESSAGE_START_TRACKING + ',"timestamp":' + ts + ',"content":{"jid":"' + "000000000001" + '"}}';
  for (j = 0; j < payloadJSONstringified.length; j++) {
    bTrackingMessage.push(payloadJSONstringified.charCodeAt(j));
  }

  // Message is known now, adapt the Length in the Header
  bTrackingMessage[5] = (payloadJSONstringified.length & 0xff000000) >> 24;
  bTrackingMessage[6] = (payloadJSONstringified.length & 0x00ff0000) >> 16;
  bTrackingMessage[7] = (payloadJSONstringified.length & 0x0000ff00) >> 8;
  bTrackingMessage[8] = payloadJSONstringified.length & 0x000000ff;

  // Type of Message: Generic= 0x0002, DeliveryStatus 0x0005
  bTrackingMessage[9] = 0x00;
  bTrackingMessage[10] = 0x02;

  // Convert Byte array to UTF-8 array
  var utf8TrackMessage = new Uint8Array(bTrackingMessage.length);
  for (var i = 0; i < bTrackingMessage.length; i++) {
    utf8TrackMessage[i] = bTrackingMessage[i];
  }

  return utf8TrackMessage;
}

export class SmappeeWebSocket {
  webSocket: WebSocket | null;
  connected: boolean;
  uuid: string;
  url: string;
  onMessage?: (message: WebsocketMessage) => void;

  constructor() {
    this.webSocket = null;
    this.connected = false;
    this.uuid = '';

    /*const protocol = window.location.host.indexOf('localhost') > -1 ? 'ws' : 'wss';
    this.url = `${protocol}://${window.location.host}/websocket`;*/
    //this.url = 'wss://farm1pub.smappee.net/websocket';
    if (window.location.host === 'dash-staging.smappee.net') {
      this.url = `wss://${window.location.host}/websocket`;
    } else this.url = `wss://${window.location.host}/websocketold`;
  }

  connect(uuid: string) {
    if (this.webSocket && this.connected) this.disconnect();

    this.webSocket = new WebSocket(this.url);
    this.webSocket.binaryType = 'arraybuffer';

    this.webSocket.onopen = event => {
      if (!this.webSocket) return;

      this.uuid = uuidv1();
      this.webSocket.send(makeTrackingMessageForSerialNumberAndUuidSender(uuid, this.uuid));
      this.connected = true;
    };

    this.webSocket.onerror = function (error) {
      log('mqttErrors', `Websocket error: ${error}`);
    };

    this.webSocket.onclose = event => {
      log('mqtt', 'WebSocket closed');
    };

    this.webSocket.onmessage = event => {
      this.onMessageReceived(event.data);
    };
  }

  disconnect() {
    if (!this.webSocket) return;

    this.webSocket.onopen = () => {};
    this.webSocket.onmessage = () => {};
    this.webSocket.close();
    this.webSocket = null;
  }

  onMessageReceived(data: any) {
    var x = new DataView(data);
    var s = '';

    var fromLength = x.getUint8(2);
    var toLength = x.getUint8(3);
    var messageIdLength = x.getUint8(4);
    var headerLength = 19 + fromLength + toLength + messageIdLength;
    var typeByte1 = x.getUint8(9);
    var typeByte2 = x.getUint8(10);
    var type = (typeByte1 << 8) | typeByte2;

    if (type !== 2) {
      if (type !== 5) {
        console.log(`Unknown type: ${type}`);
      }
      return; // only process JSON messages
    }

    // Message with JSON variable starts at position 100
    for (var i = headerLength; i < x.byteLength; i++) {
      s += String.fromCharCode(x.getUint8(i));
    }

    if (s.length === 0) return;

    var message = JSON.parse(s);
    this.onMessage && this.onMessage(message);
  }
}

export type WebsocketMessage = WebsocketPowerMessage;

export interface IWebsocketMessage {
  messageType: number;
}

export interface WebsocketPowerMessage extends IWebsocketMessage {
  messageType: 515;
  timestamp: number;
  content: {
    alwaysOn: number;
    channelData: number[];
    consumptionPower: number;
    froggySensorDailyDeltas: IFroggyDelta[];
    solarPower: number;
  };
}
