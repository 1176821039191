import React from 'react';

import {T} from '../utils/Internationalization';

import {Button} from './bootstrap';
import styles from './CrashedChart.module.scss';

interface CrashedChartProps {
  onClickedRefresh: () => void;
}

export default function CrashedChart(props: CrashedChartProps) {
  return (
    <div className={styles.crashedcard}>
      <div className={styles.body}>
        <div className={styles.broken}>
          <span className="fal fa-chart-line-down" />
          <h3>{T('chart.broken.ouch')}</h3>
          <p>{T('chart.broken.info')}</p>
          <Button variant="secondary" onClick={props.onClickedRefresh}>
            {T('chart.broken.refresh')}
          </Button>
        </div>
      </div>
    </div>
  );
}
