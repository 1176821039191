import React, {useEffect, useMemo, useState} from 'react';

import {Icon} from '../../components/Icon';
import {MiniCardHeader} from '../../components/MiniCardHeader';
import {Period, getPeriodRangeForTimezone, PeriodRoundingMode} from '../../components/PeriodSelector';
import SelectLocationIdButton from '../../components/SelectLocationIdButton';
import {VerticalLayout} from '../../components/VerticalLayout';
import {useLiveValues} from '../../livedata/LiveData';
import {IPowerMessage} from '../../livedata/LiveDataModels';
import {UserRights} from '../../models/AuthUser';
import {hasHighLevelConfig} from '../../models/DeviceType';
import {IHighLevelConfiguration} from '../../models/HighLevelConfiguration';
import {getHistoricalLoad} from '../../models/HistoricalChannel';
import {getLoadName} from '../../models/Load';
import {isBigConsumer} from '../../models/Location';
import {OnlineStatus} from '../../models/OnlineStatus';
import {SensorReadingType} from '../../models/SensorReadings';
import {createPowerChannel, IPowerChannel, NO_POWER_CHANNEL} from '../../models/TrackingChannel';
import {getUnitLabel, Interval, UsageUnit} from '../../models/UsageValue';
import {BrandColors} from '../../utils/BrandColors';
import {Fetcher} from '../../utils/Fetcher';
import {useAppDashboard, useHighLevelConfiguration} from '../../utils/FunctionalData';
import {T, plural} from '../../utils/Internationalization';
import {smartFormatNumeral} from '../../utils/NumberUtils';
import {ICardType, CardTypeKey, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CustomSettings} from '../components/CardView';
import {getChannelsFromDashboard, ILiveValuesChannels} from '../LiveValues/Data';
import {MiniCard, miniCardProperties} from '../MiniCard';

import styles from './index.module.scss';
import {ILiveValuesMiniSettings, LiveValuesMiniMode, IMiniGraphData} from './LiveValuesMiniModels';
import {LiveValuesMiniSettings} from './LiveValuesMiniSettings';
import {LiveValuesMiniGraph} from './MiniGraph';

function getMessageValue(
  message: IPowerMessage | undefined,
  settings: ILiveValuesMiniSettings,
  channels: ILiveValuesChannels,
  loadChannel: IPowerChannel = NO_POWER_CHANNEL
): [number | undefined, UsageUnit] {
  if (!message) return [undefined, UsageUnit.None];

  const {mode} = settings;

  switch (mode) {
    case LiveValuesMiniMode.Consumption: {
      const {consumptionChannel = NO_POWER_CHANNEL} = channels;
      return [consumptionChannel.extract(message), consumptionChannel.unit];
    }
    case LiveValuesMiniMode.AlwaysOn:
      const {alwaysOnChannel = NO_POWER_CHANNEL} = channels;
      return [alwaysOnChannel.extract(message), alwaysOnChannel.unit];
    case LiveValuesMiniMode.Solar:
      const {solarChannel = NO_POWER_CHANNEL} = channels;
      return [solarChannel.extract(message), solarChannel.unit];
    case LiveValuesMiniMode.Gas:
      const {gasChannel = NO_POWER_CHANNEL} = channels;
      return [gasChannel.extract(message), gasChannel.unit];
    case LiveValuesMiniMode.Water:
      const {waterChannel = NO_POWER_CHANNEL} = channels;
      return [waterChannel.extract(message), waterChannel.unit];
    case LiveValuesMiniMode.Submetering:
      return [loadChannel.extract(message), loadChannel.unit];
  }
}

function getFormattedValue(
  bigConsumer: boolean,
  message: IPowerMessage | undefined,
  settings: ILiveValuesMiniSettings,
  channels: ILiveValuesChannels,
  loadChannel: IPowerChannel = NO_POWER_CHANNEL
): string {
  const [value, unit] = getMessageValue(message, settings, channels, loadChannel);
  if (value === undefined) return '--';

  const {mode} = settings;

  switch (mode) {
    case LiveValuesMiniMode.Gas:
    case LiveValuesMiniMode.Water:
      return `${smartFormatNumeral(value)} ${getUnitLabel(unit)}`;
    default:
      const digits = bigConsumer ? 1 : 0;
      return `${value.toFixed(digits)} ${getUnitLabel(unit)}`;
  }
}

function getModeInfo(
  settings: ILiveValuesMiniSettings,
  configuration: IHighLevelConfiguration | undefined
): {title: string; icon: Icon; backgroundColor: string} {
  switch (settings.mode) {
    case LiveValuesMiniMode.Consumption:
      return {
        title: T('liveValuesMini.title.consumption'),
        icon: Icon.Electricity,
        backgroundColor: BrandColors.OrangeSunglow
      };
    case LiveValuesMiniMode.Solar:
      return {
        title: T('liveValuesMini.title.solar'),
        icon: Icon.Sun,
        backgroundColor: BrandColors.GreenAtlantis
      };
    case LiveValuesMiniMode.AlwaysOn:
      return {
        title: T('liveValuesMini.title.alwaysOn'),
        icon: Icon.AlwaysOn,
        backgroundColor: BrandColors.BlueAlwaysOn
      };
    case LiveValuesMiniMode.Gas:
      return {
        title: T('liveValuesMini.title.gas'),
        icon: Icon.Gas,
        backgroundColor: BrandColors.OrangeClementine
      };
    case LiveValuesMiniMode.Water:
      return {
        title: T('liveValuesMini.title.water'),
        icon: Icon.Water,
        backgroundColor: BrandColors.Blue
      };
    case LiveValuesMiniMode.Submetering:
      const {loadId} = settings;
      const load = configuration && configuration.measurements.find(l => l.id === loadId);
      return {
        title: T('liveValuesMini.title.submeter', {
          name: (load && load.name) || '?'
        }),
        icon: Icon.Electricity,
        backgroundColor: BrandColors.OrangeSunglow
      };
    default:
      return {
        title: '',
        icon: Icon.Electricity,
        backgroundColor: BrandColors.Red
      };
  }
}

function loadMiniGraphData(
  fetch: Fetcher,
  locationId: number,
  locationTimezone: string,
  mode: LiveValuesMiniMode
): Promise<IMiniGraphData> {
  const {from, to} = getPeriodRangeForTimezone(
    {
      period: Period.DAYS_14,
      interval: Interval.DAY
    },
    locationTimezone,
    undefined,
    PeriodRoundingMode.INCLUSIVE
  );

  switch (mode) {
    case LiveValuesMiniMode.Gas:
      return fetch(
        'data',
        api => api.getSensorReadings([SensorReadingType.Gas], locationId, from, to, Interval.DAY),
        plural('sensorReading')
      ).then(values => {
        const gasReadings = values.usages.find(x => x.type === SensorReadingType.Gas);
        return {
          from,
          to,
          gasReadings: gasReadings ? gasReadings.intervals : [],
          waterReadings: [],
          electricityData: []
        };
      });
    case LiveValuesMiniMode.Water:
      return fetch(
        'data',
        api => api.getSensorReadings([SensorReadingType.Water], locationId, from, to, Interval.DAY),
        plural('sensorReading')
      ).then(values => {
        const waterReadings = values.usages.find(x => x.type === SensorReadingType.Water);
        return {
          from,
          to,
          gasReadings: [],
          waterReadings: waterReadings ? waterReadings.intervals : [],
          electricityData: []
        };
      });
    default:
      return fetch(
        'data',
        api =>
          api.getElectricityConsumption(locationId, {
            from,
            to,
            interval: Interval.DAY,
            timezone: locationTimezone
          }),
        plural('electricityValue')
      ).then(values => {
        return {
          from,
          to,
          gasReadings: [],
          waterReadings: [],
          electricityData: values
        };
      });
  }
}

const LiveValuesMiniCard = (props: ICardProps<ILiveValuesMiniSettings>) => {
  const {fetch, settings} = props;
  const location = useCardLocation(settings);

  const [graphData, setGraphData] = useState<IMiniGraphData | undefined>();
  const [refresh, setRefresh] = useState(0);
  const isChild = location && location.parentId !== undefined;
  const locationId = location && location.id;
  const locationTimezone = location && location.timeZoneId;
  const {mode, color, customColor} = settings;
  const highLevelLocationId =
    location && location.deviceType && (hasHighLevelConfig(location.deviceType) && !isChild ? location.id : undefined);
  const [configuration] = useHighLevelConfiguration(fetch, highLevelLocationId);

  useEffect(() => {
    if (!locationId || !locationTimezone) return;

    loadMiniGraphData(fetch, locationId, locationTimezone, mode).then(data => {
      setGraphData(data);
      setRefresh(refresh => refresh + 1);
    });
  }, [fetch, setGraphData, setRefresh, locationId, locationTimezone, mode]);
  useEffect(() => setRefresh(refresh => refresh + 1), [setRefresh, color, customColor]);

  const bigConsumer = location ? isBigConsumer(location) : false;
  const [dashboard] = useAppDashboard(fetch, locationId);
  const channels = useMemo(() => getChannelsFromDashboard(dashboard, bigConsumer), [dashboard, bigConsumer]);

  const [loadChannel, historicalLoad] = useMemo(() => {
    if (
      configuration === undefined ||
      settings.loadId === undefined ||
      settings.mode !== LiveValuesMiniMode.Submetering
    ) {
      return [undefined, undefined];
    }

    const load = configuration.measurements.find(l => l.id === settings.loadId);
    if (load === undefined || load.updateChannels === undefined || locationId === undefined) {
      return [undefined, undefined];
    }

    const historicalLoad = getHistoricalLoad(locationId, load, bigConsumer ? 0.001 : 1);
    const loadChannel = createPowerChannel(
      load.updateChannels.activePower,
      bigConsumer ? UsageUnit.KiloWatt : UsageUnit.Watt,
      bigConsumer ? 0.001 : 1,
      `${getLoadName(load)}.active`
    );
    return [loadChannel, historicalLoad];
  }, [configuration, settings.loadId, settings.mode, locationId, bigConsumer]);

  const renderSettings: CustomSettings<ILiveValuesMiniSettings> = (settings, updateSettings) => (
    <LiveValuesMiniSettings editingSettings={settings} updateSettings={updateSettings} locationId={locationId} />
  );

  const [message, status] = useLiveValues(location);
  const actualColor = color === 'custom' ? customColor : color;
  const {title, icon, backgroundColor} = getModeInfo(settings, configuration);
  const locationSuffix = settings.locationId && location ? ` · ${location.name}` : '';
  const gasUnit = channels.gasChannel ? channels.gasChannel.unit : UsageUnit.CubicMeter;
  const waterUnit = channels.waterChannel ? channels.waterChannel.unit : UsageUnit.Liter;

  let error: string | JSX.Element | undefined;
  if (isChild) {
    error = (
      <>
        {T('liveValues.notSupportedForLocalityChild')}
        <br />
        <SelectLocationIdButton fetch={fetch} locationId={location!.parentId!} />
      </>
    );
  } else if (status === OnlineStatus.Offline) error = T('liveValues.timeout');

  return (
    <MiniCard
      backgroundColor={actualColor || backgroundColor}
      customSettings={renderSettings}
      ready={message !== undefined && graphData !== undefined && location !== undefined}
      error={error}
      {...miniCardProperties(props)}
    >
      <VerticalLayout>
        <MiniCardHeader
          title={getFormattedValue(bigConsumer, message, settings, channels, loadChannel)}
          subtitle={title + locationSuffix}
          icon={icon}
          color="white"
        />
        {graphData && location && (
          <LiveValuesMiniGraph
            key={mode + refresh}
            interval={Interval.DAY}
            location={location}
            seriesColor="white"
            data={graphData}
            mode={mode}
            className={styles.graph}
            gasUnit={getUnitLabel(gasUnit)}
            waterUnit={getUnitLabel(waterUnit)}
            load={historicalLoad}
          />
        )}
      </VerticalLayout>
    </MiniCard>
  );
};

const DEFAULT_CARD_SETTINGS: ILiveValuesMiniSettings = {
  mode: LiveValuesMiniMode.Consumption
};
const CARD: ICardType<ILiveValuesMiniSettings> = {
  type: CardTypeKey.LiveValuesMini,
  title: 'liveValuesMini.title',
  description: 'liveValuesMini.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 1,
  height: 1,
  minWidth: 1,
  minHeight: 1,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: LiveValuesMiniCard,
  initSettingsPanel: LiveValuesMiniSettings,
  validateSettings: settings => {
    if (settings.mode !== LiveValuesMiniMode.Submetering) return true;
    return settings.loadId !== undefined;
  }
};
export default CARD;
