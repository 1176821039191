import React, {CSSProperties, ReactNode} from 'react';

import {useAppContext} from '../../app/context';
import {Button as RsButton} from '../../components/bootstrap';
import CenteredErrorView from '../../components/CenteredErrorView';
import {ICardSettings} from '../../models/CardSettings';
import {setContextLocationId} from '../../redux/actions/location';
import {LoadState} from '../../redux/AppState';
import {IBaseFetchingItem} from '../../utils/Fetcher';
import {useAppSelector} from '../../utils/Hooks';
import {T, listingOf} from '../../utils/Internationalization';
import {classes} from '../../utils/Styles';
import {testingClasses} from '../../utils/TestingClasses';
import {useCardContext} from '../CardContext';
import {CardLocationAwareness} from '../CardType';
import {useCardLocation, getCardLocationError, useUser} from '../CardUtils';

import styles from './Card.module.scss';

import CardDraggableHandle from './CardDraggableHandle';
import CardHeader from './CardHeader';

function getFetchingErrors(fetching: IBaseFetchingItem[]): string[] {
  return fetching.filter(entry => entry.error !== undefined).map(entry => entry.error) as string[];
}

function getLoadingMessage(fetching: IBaseFetchingItem[]): string {
  const loading = fetching.filter(entry => entry.loading);
  if (loading.length === 0) {
    return '';
  } else {
    return T('card.loading', {
      entity: listingOf(loading.map(entry => entry.name))
    });
  }
}

export type CustomSettings<S> = (
  editingSettings: S,
  updateEditingSettings: (updates: Partial<S>) => void
) => JSX.Element;
export type CustomActions = (state: ICardState) => JSX.Element;

export interface ICardState {
  ready: boolean;
  hasErrors: boolean;
  loading: boolean;
  title: string;
}

interface CardViewProps<S extends ICardSettings> extends CardViewBaseProps<S> {
  error?: string | JSX.Element;
  title?: string;
  titleAddendum?: JSX.Element;
  customSettings?: CustomSettings<S>;
  actions?: CustomActions;
  style?: CSSProperties;
  noHeader?: boolean;
  ready?: boolean;

  children: ReactNode;
}

export interface CardViewBaseProps<S extends ICardSettings> {
  fetching: IBaseFetchingItem[];
  loading: boolean;
  settings: S;
}

export function cardViewProps<S extends ICardSettings>(props: CardViewBaseProps<S>): CardViewBaseProps<S> {
  const {fetching, loading, settings} = props;
  return {fetching, loading, settings};
}

export function CardView<S extends ICardSettings>(props: CardViewProps<S>) {
  const {
    title,
    titleAddendum,
    customSettings,
    actions,

    fetching,
    loading,
    settings,

    style,
    noHeader,
    ready = true,

    children
  } = props;
  let error: string | JSX.Element | undefined = props.error;
  const {store, api} = useAppContext();
  const {card, cardType, abortFetching} = useCardContext<S>();
  const location = useCardLocation(settings);
  const me = useUser();
  const {locationLoadingState, hasLocations, organizing} = useAppSelector(state => ({
    organizing: state.uiState.organizing,
    locationLoadingState: state.location.loading,
    hasLocations: state.locations.fetchedLocations.length > 0
  }));

  const handleNavigateToParent = () => {
    if (!location || location.parentId === undefined) return;

    setContextLocationId(store, api, location.parentId);
  };

  const anyLoading = loading || fetching.some(x => x.loading);
  if (!anyLoading) {
    error = getCardLocationError(cardType, location, me, handleNavigateToParent) || error;
  }

  const errors = getFetchingErrors(fetching);
  const actualTitle = settings.name || title || T(cardType.title);

  const handleClickedAbort = () => abortFetching.abort();

  const needsLocation =
    cardType.locationAware === CardLocationAwareness.Required ||
    cardType.locationAware === CardLocationAwareness.RequiresChargingStation ||
    cardType.locationAware === CardLocationAwareness.RequiresRegular;

  const loadingMessage = anyLoading ? getLoadingMessage(fetching) : undefined;

  let hasErrors = errors.length > 0 || error !== undefined;
  let errorsText = error || errors.join('\n');

  if (needsLocation && locationLoadingState === LoadState.ERROR) {
    hasErrors = true;
    if (hasLocations) errorsText = T('card.couldNotLoadLocation');
    else errorsText = T('card.userHasNoLocations');
  }

  const cardReady = ready && !anyLoading;

  return (
    <div
      className={classes(styles.card, testingClasses.card, `t-card--${card.type.replace(' ', '-')}`)}
      style={style}
      data-ready={cardReady}
    >
      {noHeader !== true && (
        <CardHeader
          cardType={cardType}
          settings={settings}
          customSettings={customSettings}
          actions={
            actions &&
            actions({
              hasErrors,
              loading: anyLoading,
              ready: !anyLoading && !hasErrors,
              title: actualTitle
            })
          }
          title={actualTitle}
          titleAddendum={titleAddendum}
        />
      )}

      <div className={styles.body}>
        {!hasErrors && children}
        {hasErrors && <CenteredErrorView>{errorsText}</CenteredErrorView>}
      </div>

      {anyLoading && !hasErrors && (
        <div className={classes(styles.center, testingClasses.cardLoading)} data-testid={testingClasses.cardLoading}>
          <span className="fal fa-sync fa-spin" title="Loading data" />
          <p>{loadingMessage}</p>
          <RsButton onClick={handleClickedAbort}>{T('card.cancel')}</RsButton>
        </div>
      )}

      {organizing && <CardDraggableHandle />}
    </div>
  );
}
