import {
  FluviusMeasurementTemplateCode,
  FluviusMeasurementCampaignType
} from '../../../models/FluviusMeasurementCampaign';

import {FormErrors, MeasurementCampaignFormState, noSubmeterErrors, TemplateSubmeterErrors} from './CampaignFormState';
import {SubmeterName, Template, TemplateName, TemplateSubmeter, TemplateSubmeterType} from './Models';

interface Reason {
  code: string;
  name: string;
}

export const FLUVIUS_CAMPAIGN_REASONS: Reason[] = [
  {code: '__', name: '(Kies reden)'},
  {code: 'SK', name: 'Spanningsklacht'},
  {code: 'BM', name: 'Belastingsmeting'},
  {code: 'PH', name: 'Profielherkenning (fraude)'},
  {code: 'CS', name: 'Controle spanningsniveau/tapstand'},
  {code: 'CO', name: 'Controle onbalans'},
  {code: 'X', name: 'Andere - geef reden in opmerkingveld'}
];

export function isBoardNameAvailable(template: Template): boolean {
  return template.code !== FluviusMeasurementTemplateCode.THREE_PHASE;
}

export const FLUVIUS_TEMPLATE_NETONLY: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net,
  code: FluviusMeasurementTemplateCode.Net,
  submeters: [{type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true}]
};
export const FLUVIUS_TEMPLATE_NET2X3F: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net2x3f,
  code: FluviusMeasurementTemplateCode.Net2x3F,
  submeters: [
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3}
  ]
};
export const FLUVIUS_TEMPLATE_NET4X3F: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net4x3f,
  code: FluviusMeasurementTemplateCode.Net4x3F,
  submeters: [
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3}
  ]
};
export const FLUVIUS_TEMPLATE_NET8X1F: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net8x1f,
  code: FluviusMeasurementTemplateCode.Net8x1F,
  submeters: [
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1}
  ]
};
export const FLUVIUS_TEMPLATE_NET16X1F: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net16x1f,
  code: FluviusMeasurementTemplateCode.Net16x1F,
  submeters: [
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1}
  ]
};
export const FLUVIUS_TEMPLATE_3FN: Template = {
  type: FluviusMeasurementCampaignType.Voetpadkast,
  name: TemplateName.T3F,
  code: FluviusMeasurementTemplateCode.THREE_PHASE,
  submeters: [{type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3}]
};
export const FLUVIUS_TEMPLATE_4X1F: Template = {
  type: FluviusMeasurementCampaignType.Voetpadkast,
  name: TemplateName.T1F,
  code: FluviusMeasurementTemplateCode.FOUR_SINGLE_PHASE,
  submeters: [
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1}
  ]
};
export const FLUVIUS_TEMPLATE_3FN_NET: Template = {
  type: FluviusMeasurementCampaignType.Voetpadkast,
  name: TemplateName.T3FNet,
  code: FluviusMeasurementTemplateCode.THREE_PHASE_N,
  submeters: [{type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true}]
};
export const FLUVIUS_TEMPLATE_3FN_VERTREK: Template = {
  type: FluviusMeasurementCampaignType.Voetpadkast,
  name: TemplateName.T3FLoad,
  code: FluviusMeasurementTemplateCode.THREE_PHASE_V,
  submeters: [{type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3}]
};

export const FLUVIUS_CAMPAIGN_TEMPLATES: Template[] = [
  FLUVIUS_TEMPLATE_NET2X3F,
  FLUVIUS_TEMPLATE_NET4X3F,
  FLUVIUS_TEMPLATE_NET8X1F,
  FLUVIUS_TEMPLATE_NET16X1F,
  FLUVIUS_TEMPLATE_3FN,
  FLUVIUS_TEMPLATE_4X1F
];
export const FLUVIUS_CAMPAIGN_ALL_TEMPLATES = [
  ...FLUVIUS_CAMPAIGN_TEMPLATES,
  FLUVIUS_TEMPLATE_NETONLY,
  FLUVIUS_TEMPLATE_3FN_NET,
  FLUVIUS_TEMPLATE_3FN_VERTREK
];

export const FLUVIUS_CAMPAIGN_CITIES = [
  'Aartselaar',
  'Antwerpen',
  'Boechout',
  'Boom',
  'Borsbeek',
  'Brasschaat',
  'Brecht',
  'Edegem',
  'Essen',
  'Hemiksem',
  'Hove',
  'Kalmthout',
  'Kapellen',
  'Kontich',
  'Lint',
  'Mortsel',
  'Niel',
  'Ranst',
  'Rumst',
  'Schelle',
  'Schilde',
  'Schoten',
  'Stabroek',
  'Wijnegem',
  'Wommelgem',
  'Wuustwezel',
  'Zandhoven',
  'Zoersel',
  'Zwijndrecht',
  'Malle',
  'Berlaar',
  'Bonheiden',
  'Bornem',
  'Duffel',
  'Heist-op-den-Berg',
  'Lier',
  'Mechelen',
  'Nijlen',
  'Putte',
  'Puurs-Sint-Amands',
  'Sint-Katelijne-Waver',
  'Willebroek',
  'Arendonk',
  'Baarle-Hertog',
  'Balen',
  'Beerse',
  'Dessel',
  'Geel',
  'Grobbendonk',
  'Herentals',
  'Herenthout',
  'Herselt',
  'Hoogstraten',
  'Hulshout',
  'Kasterlee',
  'Lille',
  'Meerhout',
  'Merksplas',
  'Mol',
  'Olen',
  'Oud-Turnhout',
  'Ravels',
  'Retie',
  'Rijkevorsel',
  'Turnhout',
  'Vorselaar',
  'Vosselaar',
  'Westerlo',
  'Laakdal',
  'Asse',
  'Beersel',
  'Bever',
  'Dilbeek',
  'Galmaarden',
  'Gooik',
  'Grimbergen',
  'Halle',
  'Herne',
  'Hoeilaart',
  'Kampenhout',
  'Kapelle-op-den-Bos',
  'Liedekerke',
  'Londerzeel',
  'Machelen',
  'Meise',
  'Merchtem',
  'Opwijk',
  'Overijse',
  'Pepingen',
  'Sint-Pieters-Leeuw',
  'Steenokkerzeel',
  'Ternat',
  'Vilvoorde',
  'Zaventem',
  'Zemst',
  'Roosdaal',
  'Drogenbos',
  'Kraainem',
  'Linkebeek',
  'Sint-Genesius-Rode',
  'Wemmel',
  'Wezembeek-Oppem',
  'Lennik',
  'Affligem',
  'Aarschot',
  'Begijnendijk',
  'Bekkevoort',
  'Bertem',
  'Bierbeek',
  'Boortmeerbeek',
  'Boutersem',
  'Diest',
  'Geetbets',
  'Haacht',
  'Herent',
  'Hoegaarden',
  'Holsbeek',
  'Huldenberg',
  'Keerbergen',
  'Kortenaken',
  'Kortenberg',
  'Landen',
  'Leuven',
  'Lubbeek',
  'Oud-Heverlee',
  'Rotselaar',
  'Tervuren',
  'Tienen',
  'Tremelo',
  'Zoutleeuw',
  'Linter',
  'Scherpenheuvel-Zichem',
  'Tielt-Winge',
  'Glabbeek',
  'Beernem',
  'Blankenberge',
  'Brugge',
  'Damme',
  'Jabbeke',
  'Oostkamp',
  'Torhout',
  'Zedelgem',
  'Zuienkerke',
  'Knokke-Heist',
  'Diksmuide',
  'Houthulst',
  'Koekelare',
  'Kortemark',
  'Lo-Reninge',
  'Ieper',
  'Mesen',
  'Poperinge',
  'Wervik',
  'Zonnebeke',
  'Heuvelland',
  'Langemark-Poelkapelle',
  'Vleteren',
  'Anzegem',
  'Avelgem',
  'Deerlijk',
  'Harelbeke',
  'Kortrijk',
  'Kuurne',
  'Lendelede',
  'Menen',
  'Waregem',
  'Wevelgem',
  'Zwevegem',
  'Spiere-Helkijn',
  'Bredene',
  'Gistel',
  'Ichtegem',
  'Middelkerke',
  'Oostende',
  'Oudenburg',
  'De Haan',
  'Hooglede',
  'Ingelmunster',
  'Izegem',
  'Ledegem',
  'Lichtervelde',
  'Moorslede',
  'Roeselare',
  'Staden',
  'Dentergem',
  'Meulebeke',
  'Oostrozebeke',
  'Pittem',
  'Ruiselede',
  'Tielt',
  'Wielsbeke',
  'Wingene',
  'Ardooie',
  'Alveringem',
  'De Panne',
  'Koksijde',
  'Nieuwpoort',
  'Veurne',
  'Aalst',
  'Denderleeuw',
  'Geraardsbergen',
  'Haaltert',
  'Herzele',
  'Lede',
  'Ninove',
  'Sint-Lievens-Houtem',
  'Zottegem',
  'Erpe-Mere',
  'Berlare',
  'Buggenhout',
  'Dendermonde',
  'Hamme',
  'Laarne',
  'Lebbeke',
  'Waasmunster',
  'Wetteren',
  'Wichelen',
  'Zele',
  'Assenede',
  'Eeklo',
  'Kaprijke',
  'Maldegem',
  'Sint-Laureins',
  'Zelzate',
  'Aalter',
  'Deinze',
  'De Pinte',
  'Destelbergen',
  'Evergem',
  'Gavere',
  'Gent',
  'Lochristi',
  'Lievegem',
  'Melle',
  'Merelbeke',
  'Moerbeke',
  'Nazareth',
  'Oosterzele',
  'Sint-Martens-Latem',
  'Wachtebeke',
  'Zulte',
  'Kruisem',
  'Oudenaarde',
  'Ronse',
  'Brakel',
  'Kluisbergen',
  'Wortegem-Petegem',
  'Horebeke',
  'Lierde',
  'Maarkedal',
  'Zwalm',
  'Beveren',
  'Kruibeke',
  'Lokeren',
  'Sint-Gillis-Waas',
  'Sint-Niklaas',
  'Stekene',
  'Temse',
  'As',
  'Beringen',
  'Diepenbeek',
  'Genk',
  'Gingelom',
  'Halen',
  'Hasselt',
  'Herk-de-Stad',
  'Leopoldsburg',
  'Lummen',
  'Nieuwerkerken',
  'Oudsbergen',
  'Sint-Truiden',
  'Tessenderlo',
  'Zonhoven',
  'Zutendaal',
  'Ham',
  'Heusden-Zolder',
  'Bocholt',
  'Bree',
  'Kinrooi',
  'Lommel',
  'Maaseik',
  'Pelt',
  'Peer',
  'Hamont-Achel',
  'Hechtel-Eksel',
  'Houthalen-Helchteren',
  'Dilsen-Stokkem',
  'Alken',
  'Bilzen',
  'Borgloon',
  'Heers',
  'Herstappe',
  'Hoeselt',
  'Kortessem',
  'Lanaken',
  'Riemst',
  'Tongeren',
  'Wellen',
  'Maasmechelen',
  'Voeren'
];
FLUVIUS_CAMPAIGN_CITIES.sort();

export function validateFormState(state: MeasurementCampaignFormState): [boolean, FormErrors] {
  let valid = true;
  const errors: FormErrors = {
    typeRequired: false,
    cityRequired: false,
    regionRequired: false,
    reasonRequired: false,
    setRequired: false,
    templateASubmeters: [],
    templateBSubmeters: []
  };

  if (state.name === '') {
    errors.name = 'Gelieve een naam in te geven';
    valid = false;
  } else if (state.name.length > 200) {
    errors.name = 'De naam is te lang (maximum 200 karakters)';
    valid = false;
  }
  if (state.type === FluviusMeasurementCampaignType.__unset__) {
    errors.typeRequired = true;
    valid = false;
  }
  if (state.type === FluviusMeasurementCampaignType.Cabine) {
    if (state.cabinId === '') {
      errors.cabinId = 'Gelieve een cabine ID in te geven';
      valid = false;
    } else if (state.cabinId.length > 10) {
      errors.cabinId = 'Cabine ID is te lang (maximum 10 karakters)';
      valid = false;
    }
  }
  if (state.type === FluviusMeasurementCampaignType.Voetpadkast) {
    if (state.vpkId === '') {
      errors.vpkId = 'Gelieve de VPK ID in te geven';
      valid = false;
    } else if (state.vpkId.length > 10) {
      errors.vpkId = 'VPK ID is te lang (maximum 10 karakters)';
      valid = false;
    }
  }
  if (state.region === undefined) {
    errors.regionRequired = true;
    valid = false;
  }
  if (state.city === '') {
    errors.cityRequired = true;
    valid = false;
  }
  if (state.streetAndNumber === '') {
    errors.streetAndNumber = 'Gelieve een adres in te geven';
    valid = false;
  } else if (state.streetAndNumber.length > 200) {
    errors.streetAndNumber = 'De straatnaam is te lang (maximum 200 karakters)';
    valid = false;
  }
  if (state.reason === '__') {
    errors.reasonRequired = true;
    valid = false;
  }
  if (state.reason === 'X' && state.notes === '') {
    errors.notes = 'Gelieve een reden op te geven';
    valid = false;
  }
  if (state.notes.length > 200) {
    errors.notes = 'De reden is te lang (maximum 200 karakters)';
    valid = false;
  }
  if (state.templateA) {
    if (state.type === FluviusMeasurementCampaignType.Cabine) {
      if (state.transfoAId === '') {
        errors.transfoAId = 'Gelieve een transformatornummer in te geven';
        valid = false;
      } else if (state.transfoAId.length > 10) {
        errors.transfoAId = 'Transfo ID is te lang (maximum 10 karakters)';
        valid = false;
      }
    }

    if (isBoardNameAvailable(state.templateA)) {
      if (!state.templateABoardName) {
        errors.templateABoardName = 'Gelieve een naam in te geven';
        valid = false;
      } else if (state.templateABoardName.length > 60) {
        errors.templateABoardName = 'Bordnaam is te lang (maximum 60 karakters)';
        valid = false;
      }
      if (!state.templateABoardNumber) {
        errors.templateABoardNumber = 'verplicht';
        valid = false;
      } else if (isNaN(parseInt(state.templateABoardNumber)) || state.templateABoardNumber.length > 2) {
        errors.templateABoardNumber = 'ongeldig';
        valid = false;
      }
    }

    const submeterNames = new Set<string>();
    for (let submeter of state.templateASubmeters) {
      const [submeterErrors, submeterValid] = validateSubmeter(submeterNames, submeter);
      valid = valid && submeterValid;
      errors.templateASubmeters.push(submeterErrors);
    }
  }
  if (state.templateB) {
    if (state.type === FluviusMeasurementCampaignType.Cabine) {
      if (state.transfoBId === '') {
        state.transfoBId = 'Gelieve een transformatornummer in te geven';
        valid = false;
      } else if (state.transfoBId.length > 10) {
        state.transfoBId = 'Transfo ID is te lang (maximum 10 karakters)';
        valid = false;
      }
    }

    if (!state.templateBBoardName) {
      state.templateBBoardName = 'Gelieve een naam in te geven';
      valid = false;
    } else if (state.templateBBoardName.length > 60) {
      state.templateBBoardName = 'Bordnaam is te lang (maximum 60 karakters)';
      valid = false;
    }
    if (!state.templateBBoardNumber) {
      state.templateBBoardNumber = 'verplicht';
      valid = false;
    } else if (isNaN(parseInt(state.templateBBoardNumber)) || state.templateBBoardNumber.length > 2) {
      state.templateBBoardNumber = 'ongeldig';
      valid = false;
    }

    const submeterNames = new Set<string>();
    for (let submeter of state.templateBSubmeters) {
      const [validated, submeterValid] = validateSubmeter(submeterNames, submeter);
      valid = valid && submeterValid;
      errors.templateBSubmeters.push(validated);
    }
  }

  return [valid, errors];
}

function validateSubmeter(submeterNames: Set<string>, submeter: TemplateSubmeter): [TemplateSubmeterErrors, boolean] {
  if (!submeter.enabled || submeter.definition.main) {
    return [noSubmeterErrors, true];
  }

  let isValid = true;
  const errors: TemplateSubmeterErrors = {phaseInvalid: false};
  if (submeter.name === '') {
    errors.nameError = 'Gelieve een naam op te geven';
    isValid = false;
  }
  if (submeter.number === '') {
    errors.numberError = 'Verplicht veld';
    isValid = false;
  }
  if (!/^[a-zA-Z0-9]\.[a-zA-Z0-9]{2}$/.test(submeter.number)) {
    errors.numberError = 'Vertreknummer volgt niet het juiste formaat (X.XX)';
    isValid = false;
  }
  if (submeter.name.length > 60) {
    errors.nameError = 'Vertreknaam is te lang (maximum 60 karakters)';
    isValid = false;
  }
  const name = `${submeter.number}@${submeter.name}`;
  if (isValid && submeterNames.has(name)) {
    errors.nameError = 'Combinatie nummer + naam moet uniek zijn';
    errors.numberError = '';
    isValid = false;
  }
  submeterNames.add(name);
  return [isValid ? noSubmeterErrors : errors, isValid];
}

const templateNames = {
  'template.net': 'Net',
  'template.net2x3f': 'Net + 2X3F',
  'template.net4x3f': 'Net + 4X3F',
  'template.net8x1f': 'Net + 8X1F (1-fasig)',
  'template.net16x1f': 'Net + 16X1F (1-fasig)',
  'template.3f': 'Vertrek 3F + N',
  'template.1f': '4x vertrek 1F (1-fasig)',
  'template.3fnet': 'Vertek 3F + N (Net)',
  'template.3fload': 'Vertrek 3F + N (Vertrek)'
};

export function translateTemplateNameFluvius(template: TemplateName) {
  return templateNames[template];
}

const submeterNames = {
  'submeter.net': 'Net',
  'submeter.load1': 'Vertrek (1-fasig)',
  'submeter.load3': 'Vertrek (3-fasig)'
};

export function translateSubmeterNameFluvius(submeter: SubmeterName) {
  return submeterNames[submeter];
}
