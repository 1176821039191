import React from 'react';

import {Button as RsButton} from '../../../components/bootstrap';

import {CurrencyInput} from '../../../components/CurrencyInput';
import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {ICurrency} from '../../../core/currencies';
import {T} from '../../../utils/Internationalization';

interface CurrencyEditorProps {
  value: string;
  update: (value: string) => Promise<void>;
  readOnly: boolean;
}
interface CurrencyEditorState {
  editing: boolean;
  selectedValue: ICurrency | undefined;
  editingValue: string;
  loading: boolean;
}
class CurrencyEditor extends React.Component<CurrencyEditorProps, CurrencyEditorState> {
  _input?: HTMLElement;

  constructor(props: CurrencyEditorProps) {
    super(props);

    this.state = {
      editing: false,
      selectedValue: undefined,
      editingValue: '',
      loading: false
    };
  }

  componentDidUpdate(oldProps: CurrencyEditorProps) {
    if (oldProps.value !== this.props.value) {
      this.setState({
        selectedValue: undefined,
        editingValue: this.props.value
      });
    }
  }

  handleClickedEdit = () => {
    this.setState({editing: true});
  };

  onSuggestionSelected = (suggestion: ICurrency) => {
    this.setState({
      selectedValue: suggestion,
      editingValue: suggestion.code
    });
  };

  handleClickedCancel = () => {
    this.setState({
      editing: false,
      editingValue: this.props.value,
      selectedValue: undefined
    });
  };

  handleClickedSave = () => {
    const {selectedValue} = this.state;
    if (selectedValue === undefined) return;

    this.setState({loading: true});
    this.props
      .update(selectedValue.code)
      .then(() => {
        this.setState({
          editing: false,
          loading: false,
          selectedValue: undefined
        });
      })
      .catch(() => this.setState({loading: false})); // I need promise cancellations to do this properly... Stan
  };

  render() {
    const {value, readOnly} = this.props;
    const {editing, editingValue, selectedValue, loading} = this.state;

    if (editing) {
      return (
        <div style={{display: 'flex'}}>
          <CurrencyInput value={editingValue} onChange={this.onSuggestionSelected} autoFocus={true} />
          <RsButton
            color="primary"
            onClick={this.handleClickedSave}
            disabled={selectedValue === undefined}
            style={{marginLeft: '0.3em'}}
          >
            {T('locationConfiguration.field.save')}
            {loading && <i style={{marginLeft: '0.3em'}} className="fas fa-circle-notch fa-spin" />}
          </RsButton>
          <RsButton onClick={this.handleClickedCancel} style={{marginLeft: '0.3em'}}>
            {T('locationConfiguration.field.cancelEdit')}
          </RsButton>
        </div>
      );
    } else {
      return (
        <span>
          {value}
          &nbsp;
          {!readOnly && (
            <Button variant="ghost_action_btn" size="icon" onClick={this.handleClickedEdit}>
              <Edit />
            </Button>
          )}
        </span>
      );
    }
  }
}

export default CurrencyEditor;
