import {IChargingPriority} from '../models/ChargingPriorities';

import {APIClient} from './APIClient';

export class ChargingPrioritiesAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getChargingPriorities(serviceLocationId: number): Promise<IChargingPriority[]> {
    return this.client.doGet(`/api/v10/servicelocation/${serviceLocationId}/chargesettings/priorities`);
  }

  updateChargingPriority(serviceLocationId: number, chargingPriority: IChargingPriority): Promise<IChargingPriority[]> {
    return this.client.doPut(
      `/api/v10/servicelocation/${serviceLocationId}/chargesettings/priorities`,
      chargingPriority
    );
  }

  delete(serviceLocationId: number, id: number): Promise<IChargingPriority[]> {
    return this.client.doDelete(`/api/v10/servicelocation/${serviceLocationId}/chargesettings/priorities/${id}`);
  }
}
