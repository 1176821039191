import {CustomApp} from '../models/CustomApp';

import {APIClient} from './APIClient';

export class AppsAPI {
  client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getApps(includeObsolete: boolean = false): Promise<CustomApp[]> {
    let url = `/api/v10/apps`;
    if (includeObsolete) url += '?includeObsolete=true';

    return this.client.doGet(url);
  }

  deleteApp(id: number): Promise<void> {
    const url = `/api/v10/apps/${id}`;
    return this.client.doDelete(url);
  }

  restoreApp(id: number): Promise<void> {
    const url = `/api/v10/apps/${id}/restore`;
    return this.client.doPost(url, {});
  }
}
