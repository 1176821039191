import {History} from 'history';
import React, {useContext} from 'react';

import {connect, MapStateToPropsParam, MapDispatchToPropsFunction} from 'react-redux';

import API from '../core/api';
import {MqttConnector} from '../livedata/MqttConnector';
import {AppStore} from '../redux';
import {IAppState} from '../redux/AppState';

export interface IAppContext {
  api: API;
  store: AppStore;
  history: History;
  pathname: string;
  mqtt: MqttConnector;
  query: URLSearchParams;
}

export const AppContext = React.createContext<IAppContext | undefined>(undefined);
export const ContextProvider = AppContext.Provider;
export const ContextConsumer = AppContext.Consumer;

export function useAppContext() {
  return useContext<IAppContext | undefined>(AppContext)!;
}

type ContextComponent<P> = React.ComponentClass<P & IAppContext> | React.FunctionComponent<P & IAppContext>;
export function withAppContext<P>(Component: ContextComponent<P>): React.FunctionComponent<P> {
  return (props: P) => {
    return (
      <ContextConsumer>
        {value => (value === undefined ? 'Context missing' : <Component {...props} {...value} />)}
      </ContextConsumer>
    );
  };
}

type ContextOnlyComponent = React.ComponentClass<IAppContext> | React.FunctionComponent<IAppContext>;
export function onlyAppContext(Component: ContextOnlyComponent): React.FunctionComponent<{}> {
  return (props: {}) => {
    return (
      <ContextConsumer>
        {value => (value === undefined ? 'Context missing' : <Component {...props} {...value} />)}
      </ContextConsumer>
    );
  };
}

type AnyComponent<P> = React.ComponentClass<P> | React.FunctionComponent<P>;
export function connectWithContext<TStateProps, TDispatchProps, TOwnProps>(
  mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, IAppState>,
  mapDispatchToProps: MapDispatchToPropsFunction<TDispatchProps, TOwnProps>
): (
  component: AnyComponent<TStateProps & TDispatchProps & IAppContext & TOwnProps>
) => React.FunctionComponent<TOwnProps> {
  const enhancer = connect<TStateProps, TDispatchProps, TOwnProps, IAppState>(mapStateToProps, mapDispatchToProps);
  return (Component: AnyComponent<TStateProps & TDispatchProps & TOwnProps & IAppContext>) => {
    const ComponentWithContext = (props: TStateProps & TDispatchProps & TOwnProps) => (
      <ContextConsumer>
        {value => (value === undefined ? 'Context missing' : <Component {...props} {...value} />)}
      </ContextConsumer>
    );
    // TS-HACK: couldn't figure it out yet
    return enhancer(ComponentWithContext as any) as unknown as React.FunctionComponent<TOwnProps>;
  };
}
