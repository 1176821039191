import React from 'react';

import {Button} from '../../components/bootstrap';
import {Badge as UiBadge} from '../../components/ui/badge';

import API from '../../core/api';
import {locationToSummary} from '../../models/Location';
import {IQueriedLocationUser} from '../../models/LocationUser';
import {ITableField, IFieldOptions, DEFAULT_OPTIONS} from '../../models/Table';
import {AppStore} from '../../redux';
import {setLocation} from '../../redux/actions/location';

export class SelectLocationField implements ITableField<IQueriedLocationUser> {
  name: string;
  label: string;
  options: IFieldOptions;

  currentLocationId: number | undefined;
  api: API;
  store: AppStore;

  constructor(name: string, label: string, currentLocationId: number | undefined, api: API, store: AppStore) {
    this.name = name;
    this.label = label;
    this.options = DEFAULT_OPTIONS;

    this.currentLocationId = currentLocationId;
    this.api = api;
    this.store = store;
  }

  handleClicked(item: IQueriedLocationUser) {
    this.api.locations.get(item.serviceLocationId).then(location => {
      if (!location) return;

      setLocation(this.store, this.api, locationToSummary(location));
    });
  }

  renderCellContent(item: IQueriedLocationUser): JSX.Element {
    let isSelected = item && item.serviceLocationId === this.currentLocationId;
    return (
      <Button color="link" withoutPadding onClick={this.handleClicked.bind(this, item)} style={{whiteSpace: 'nowrap'}}>
        {item.serviceLocationName}
        {isSelected && (
          // Legacy: size="lg"
          <UiBadge variant="success" className="tw-ml-2">
            Selected
          </UiBadge>
        )}
      </Button>
    );
  }

  getExportCSVValue(item: IQueriedLocationUser): string {
    return item.serviceLocationName;
  }

  getExportExcelValue(item: IQueriedLocationUser): string | number | Date {
    return item.serviceLocationName;
  }

  sort = (a: IQueriedLocationUser, b: IQueriedLocationUser): number => {
    return a.serviceLocationName.localeCompare(b.serviceLocationName);
  };
}
