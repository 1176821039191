import React, {useState, useMemo, useEffect} from 'react';

import Table from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ILocationSurvey, getSurveyAnswerName} from '../../models/LocationSurvey';
import {ITableField, IFieldOptions, DEFAULT_OPTIONS, CalculatedStringField} from '../../models/Table';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationId} from '../CardUtils';
import {cardViewProps, CardView} from '../components/CardView';

interface ISurveyItem {
  key: string;
  value: string | number;
}

class SurveyItemValueField implements ITableField<ISurveyItem> {
  name: string;
  label: string;
  options: IFieldOptions;

  constructor() {
    this.name = 'value';
    this.label = 'Value';
    this.options = DEFAULT_OPTIONS;
  }

  renderCellContent(item: ISurveyItem) {
    if (item.key === 'buildingType') return item.value;

    return (item.value as number).toString();
  }

  getExportCSVValue(item: ISurveyItem) {
    return item.value.toString();
  }

  getExportExcelValue(item: ISurveyItem) {
    return item.value;
  }

  sort = (a: ISurveyItem, b: ISurveyItem) => {
    // TODO: smarter sorting
    return a.key.localeCompare(b.key);
  };
}

function getTableFields(): ITableField<ISurveyItem>[] {
  return [new CalculatedStringField('key', 'Key', item => getSurveyAnswerName(item.key)), new SurveyItemValueField()];
}

const rowKey = (item: ISurveyItem) => item.key;

const SurveyResults = (props: ICardProps<ICardSettingsWithTable>) => {
  const {fetch, settings, updateSettings} = props;

  const [survey, setSurvey] = useState<ILocationSurvey>();
  const locationId = useCardLocationId(settings);

  useEffect(() => {
    if (!locationId) return;

    fetch('survey', api => api.getLocationSurvey(locationId), 'survey data').then(setSurvey);
  }, [locationId, fetch]);

  const surveyItems = useMemo(() => {
    if (!survey) return [];

    const items: ISurveyItem[] = [];
    if (survey.buildingType) {
      items.push({key: 'buildingType', value: survey.buildingType});
    }
    if (survey.numberOfAdults) {
      items.push({key: 'numberOfAdults', value: survey.numberOfAdults});
    }
    if (survey.numberOfChildren) {
      items.push({key: 'numberOfChildren', value: survey.numberOfChildren});
    }
    if (survey.answers) {
      for (let key in survey.answers) {
        items.push({key, value: survey.answers[key]});
      }
    }
    return items;
  }, [survey]);

  let error: string | undefined;
  if (surveyItems.length === 0) {
    error = 'No survey results available for this location';
  }

  return (
    <CardView error={error} {...cardViewProps(props)}>
      <Table
        items={surveyItems}
        fields={getTableFields()}
        rowKey={rowKey}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: ICardSettingsWithTable = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'key', visible: true},
      {name: 'value', visible: true}
    ]
  }
};
const CARD: ICardType<ICardSettingsWithTable> = {
  type: CardTypeKey.SurveyResults,
  title: 'surveyResults.title',
  description: 'surveyResults.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: SurveyResults
};
export default CARD;
