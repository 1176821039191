import {cva, type VariantProps} from 'class-variance-authority';
import * as React from 'react';

import {cn} from '../../lib/utils';

const alertVariants = cva(
  'tw-justify-between tw-items-center tw-relative tw-w-full tw-rounded tw-p-4 [&>svg~*]:tw-pl-10 [&>svg+div]:tw-translate-y-[-3px] [&>svg]:tw-absolute [&>svg]:tw-left-3 [&>svg]:tw-top-4 [&>svg]:tw-text-foreground',
  {
    variants: {
      variant: {
        default: 'tw-bg-background tw-text-foreground',
        info: 'tw-bg-blue-100 tw-text-foreground',
        error: 'tw-bg-red-200 tw-text-foreground',
        neutral: 'tw-bg-neutral-100 tw-text-foreground',
        success: 'tw-bg-green-100 tw-text-foreground',
        warning: 'tw-bg-yellow-100 tw-text-foreground'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({className, variant, ...props}, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({variant}), className)} {...props} />
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({className, ...props}, ref) => (
    <h5 ref={ref} className={cn('tw-mb-4 tw-font-bold tw-leading-none tw-tracking-tight', className)} {...props} />
  )
);
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({className, ...props}, ref) => (
    <div ref={ref} className={cn('tw-text-base [&_p]:tw-leading-relaxed', className)} {...props} />
  )
);
AlertDescription.displayName = 'AlertDescription';

export {Alert, AlertTitle, AlertDescription};
