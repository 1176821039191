import type {SVGProps} from 'react';

const SvgCar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    fillRule="evenodd"
    {...props}
  >
    <path d="M18.195 17.35v1.659c0 .333.252.598.592.598h2.203c.33 0 .592-.255.592-.598v-5.421c0-1.147-.155-2.147-.417-3.01h1.243c.33 0 .592-.255.592-.598v-.706a.585.585 0 0 0-.592-.598H20.89c-.068-.088-.126-.186-.184-.284a2.94 2.94 0 0 0-.184-.284c-.097-.157-1.505-2.019-2.097-2.774-.544-.676-1.242-.941-2.019-.941H7.595c-.777 0-1.475.265-2.019.941-.592.755-2 2.617-2.097 2.774-.068.088-.126.186-.184.284-.058.098-.116.196-.184.284H1.593a.585.585 0 0 0-.592.598v.706c0 .343.262.598.592.598h1.243c-.262.863-.417 1.863-.417 3.01v5.421c0 .343.262.598.592.598h2.204c.34 0 .592-.265.592-.598V17.35h12.39ZM6.909 14.025c0 .612-.491 1.108-1.097 1.108s-1.097-.496-1.097-1.108.491-1.108 1.097-1.108 1.097.496 1.097 1.108Zm10.182 0c0 .612.491 1.108 1.097 1.108s1.097-.496 1.097-1.108-.491-1.108-1.097-1.108-1.097.496-1.097 1.108Z" />
  </svg>
);
export default SvgCar;
