import React, {useState, useEffect} from 'react';

import {FormGroup, Label, Input} from 'reactstrap';

import {useTextInput} from '../../components/inputs/TextInput';
import {Checkbox} from '../../components/ui/checkbox';
import {IConfigurationProperty} from '../../models/SmartDevice';
import {T} from '../../utils/Internationalization';

function getValidator(property: IConfigurationProperty): ((value: string) => string | undefined) | undefined {
  const validatorForType = getValidatorForType(property);
  const validatorForPossibleValues = getValidatorForPossibleValues(property);
  if (validatorForType !== undefined && validatorForPossibleValues !== undefined) {
    return value => {
      const errorForType = validatorForType(value);
      const errorForPossibleValues = validatorForPossibleValues(value);
      return errorForType || errorForPossibleValues;
    };
  } else {
    return validatorForType || validatorForPossibleValues;
  }
}

function getValidatorForType(property: IConfigurationProperty): ((value: string) => string | undefined) | undefined {
  switch (property.spec.species) {
    case 'String':
      // nothing to do
      return undefined;
    case 'bool':
    case 'Boolean':
      // nothing to do
      return undefined;
    case 'int':
    case 'Integer':
    case 'long':
    case 'Long':
    case 'Quantity':
      return value =>
        /^[0-9]+$/.test(value) ? undefined : T('validator.invalidInteger', {name: property.spec.displayName});
    case 'IPv4':
      return value =>
        /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/.test(value) ? undefined : 'Not a valid IPv4 address';
    case 'BigDecimal':
    case 'Instant':
    case 'Date':
      // TODO
      return undefined;
    default:
      return undefined;
  }
}

function getValidatorForPossibleValues(
  property: IConfigurationProperty
): ((value: string) => string | undefined) | undefined {
  if (property.spec.unit === '%') {
    return value => {
      const intValue = parseInt(value);
      if (isNaN(intValue)) {
        return T('validator.invalidValue', {name: property.spec.displayName});
      }

      return intValue >= 0 && intValue <= 100
        ? undefined
        : T('validator.invalidValue', {name: property.spec.displayName});
    };
  }
  if (property.spec.possibleValues /* && property.spec.possibleValues.exhaustive*/) {
    if (property.spec.possibleValues.range) {
      const range = property.spec.possibleValues.range;
      switch (property.spec.species) {
        case 'int':
        case 'Integer':
          return value => {
            const intValue = parseInt(value);
            if (isNaN(intValue)) {
              return T('validator.invalidValue', {
                name: property.spec.displayName
              });
            }

            let valid = true;
            if (range.from.int !== undefined) {
              valid = valid && intValue >= range.from.int;
            }
            if (range.from.Integer !== undefined && range.from.Integer !== null) {
              valid = valid && intValue >= range.from.Integer;
            }
            if (range.to.int !== undefined) {
              valid = valid && intValue <= range.to.int;
            }
            if (range.to.Integer !== undefined && range.to.Integer !== null) {
              valid = valid && intValue <= range.to.Integer;
            }
            return valid ? undefined : T('validator.invalidValue');
          };
      }
    }
    if (property.spec.possibleValues.values) {
      const values = property.spec.possibleValues.values;
      switch (property.spec.species) {
        case 'String':
          // nothing to do
          return value =>
            values.some(v => v.String === value)
              ? undefined
              : T('validator.invalidValue', {name: property.spec.displayName});
        case 'bool':
        case 'Boolean':
          // nothing to do
          return undefined;
        case 'int':
        case 'Integer':
          return value => {
            const intValue = parseInt(value);
            if (isNaN(intValue)) {
              return T('validator.invalidValue', {
                name: property.spec.displayName
              });
            }

            return values.some(v => v.Integer === intValue)
              ? undefined
              : T('validator.invalidValue', {name: property.spec.displayName});
          };
        case 'long':
        case 'Long':
        case 'Quantity':
          // TODO
          return undefined;
        case 'IPv4':
        case 'BigDecimal':
        case 'Instant':
        case 'Date':
          // TODO
          return undefined;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

interface SmartDeviceFieldProps {
  property: IConfigurationProperty;
  value: string;
  updateValue: (name: string, value: string) => void;
}
export default (props: SmartDeviceFieldProps) => {
  const {property} = props;
  switch (property.spec.species) {
    case 'bool':
    case 'Boolean':
      return <CheckboxFieldComponent {...props} />;
    case 'int':
    case 'Integer':
    case 'long':
    case 'Long':
    case 'String':
    case 'IPv4':
      return <StringFieldComponent {...props} suffix={property.spec.unit} />;
    case 'Quantity':
      return <StringFieldComponent {...props} suffix={property.spec.unit} />;
    case 'HighLevelMeasurementSpecification':
      return <HighLevelFieldComponent {...props} />;
    case 'BigDecimal':
    case 'Instance':
    case 'Date': // not yet supported
    default:
      return (
        <FormGroup>
          <Label>
            {T('smartDevices.bug.unsupportedSpecies', {
              species: property.spec.species
            })}
          </Label>
        </FormGroup>
      );
  }
};

const StringFieldComponent = (props: SmartDeviceFieldProps & {suffix?: string}) => {
  const {property, updateValue, suffix} = props;
  const [input, value] = useTextInput(
    property.spec.name,
    property.spec.displayName,
    props.value,
    getValidator(property),
    undefined,
    {suffix, info: property.spec.description}
  );
  useEffect(() => {
    if (value !== props.value) updateValue(property.spec.name, value);
  }, [property, value, updateValue, props.value]);
  return input;
};

const HighLevelFieldComponent = (props: SmartDeviceFieldProps) => {
  const {property, updateValue} = props;

  const [value, setValue] = useState(props.value);

  const possibleValuesObject = property.spec.possibleValues;
  if (possibleValuesObject === undefined) return <div />;

  let possibleValues = possibleValuesObject.values;
  if (possibleValues === undefined || possibleValues.values.length === 1) {
    return <div />;
  } // hide selection

  const currentValue = property.values[0];
  const currentHighLevel = currentValue && currentValue.HighLevelMeasurementSpecification;
  if (currentHighLevel) {
    possibleValues = [{HighLevelMeasurementSpecification: currentHighLevel}, ...possibleValues];
  }

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    if (value === newValue) return;

    setValue(e.currentTarget.value);
    updateValue(property.spec.name, newValue);
  };

  return (
    <FormGroup>
      <Label>{property.spec.displayName}</Label>
      <Input type="select" value={value} onChange={handleChange}>
        {possibleValues.map(value => {
          const load = value.HighLevelMeasurementSpecification!;
          return (
            <option key={load.id} value={load.id}>
              {load.name}
            </option>
          );
        })}
      </Input>
      <span style={{color: 'gray'}}>{property.spec.description}</span>
    </FormGroup>
  );
};

const CheckboxFieldComponent = (props: SmartDeviceFieldProps) => {
  const {property, updateValue} = props;
  const [checked, setChecked] = useState(props.value === 'true');

  const handleCheck = (checked: boolean) => {
    setChecked(checked);
    updateValue(property.spec.name, checked.toString());
  };

  return (
    <Checkbox
      id={`property-${property.spec.name}`}
      name={`property-${property.spec.name}`}
      label={property.spec.description}
      checked={checked}
      onCheckedChange={handleCheck}
      testId={`property-${property.spec.name}`}
    />
  );
};
