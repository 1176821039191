import React from 'react';

import {Button as RsButton} from '../../components/bootstrap';
import {Icons} from '../../components/Icon';
import {IDevice, IDeviceInputChannel, ChannelValueType, IDeviceOutputChannel} from '../../models/Device';
import {DeviceType, getDeviceIconFor, getInternalTypeLabelFor} from '../../models/DeviceType';

import {getChannelTypeLabel} from '../../models/Location';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {OfflineStatus} from '../components/OnlineStatus';

import {CollapseCaret} from './CollapseCaret';
import styles from './DeviceOverview.module.scss';

interface ModuleDeviceRowProps {
  module: IDevice;
  expanded: string[];
  readOnly: boolean;
  onClickedDelete: () => void;
  onToggleCollapse: () => void;
}
export const ModuleDeviceRow = (props: ModuleDeviceRowProps) => {
  const {module, expanded, readOnly, onClickedDelete, onToggleCollapse} = props;
  const isExpandable = module.type === DeviceType.InputModule || module.type === DeviceType.OutputModule;
  const moduleId = `M${module.serialNumber}`;
  return (
    <tr>
      <td className={styles.tableCellDeviceChild}>
        <img src={getDeviceIconFor(module.type)} alt="" />
        {getInternalTypeLabelFor(module.type)}
      </td>
      <td className={styles.tableCellData}>
        <span className="tw-mr-2">{module.serialNumber}</span>
        {module.active === false && <OfflineStatus />}
        {module.active === false && !readOnly && (
          <RsButton color="link" onClick={onClickedDelete} style={{position: 'relative', bottom: '0.1em'}}>
            {Icons.Delete}
          </RsButton>
        )}
        {isExpandable && <CollapseCaret expanded={expanded.includes(moduleId)} onClicked={onToggleCollapse} />}
      </td>
    </tr>
  );
};

function renderModuleInputChannel(
  channel: IDeviceInputChannel,
  index: number,
  states: Map<number, number[]>
): JSX.Element[] {
  if (!channel.enabled) {
    return [
      <tr key={`channel-${channel.id}-disabled`}>
        <td>{T('deviceOverview.inputX', {index: (index + 1).toString()})}:</td>
        <td>{T('deviceOverview.channelDisabled')}</td>
      </tr>
    ];
  }

  let state: boolean | undefined;
  if (channel.valueType === ChannelValueType.State) {
    const sensorState = states.get(channel.sensor.id);
    if (sensorState) state = sensorState[index] > 0;
  }
  const type =
    channel.valueType === ChannelValueType.Cumulative
      ? getChannelTypeLabel(channel.type)
      : T('device.channelType.state');
  const onText = channel.customStateTextOn || T('deviceOverview.input.on');
  const offText = channel.customStateTextOff || T('deviceOverview.input.off');

  const result = [
    <tr key={`channel-${channel.id}`}>
      <td>{T('deviceOverview.inputX', {index: (index + 1).toString()})}:</td>
      <td>
        {channel.name}
        &nbsp;({type}){state !== undefined && <span> &middot; {state ? onText : offText}</span>}
      </td>
    </tr>
  ];
  return result;
}

function renderModuleOutputChannel(channel: IDeviceOutputChannel, index: number) {
  return (
    <tr key={`channel-${channel.id}`}>
      <td>{T('deviceOverview.outputX', {index: (index + 1).toString()})}:</td>
      <td>
        {channel.enabled ? T('deviceOverview.outputChannel.enabled') : T('deviceOverview.outputChannel.disabled')}
      </td>
    </tr>
  );
}

interface ModuleDeviceDetailsProps {
  module: IDevice;
  inputModuleStates: Map<number, number[]>;
}
export const ModuleDeviceDetails = (props: ModuleDeviceDetailsProps) => {
  const {module, inputModuleStates} = props;

  return (
    <tr
      key={`module-${module.serialNumber}-details`}
      className={testingClasses.moduleDetails}
      data-testid={testingClasses.moduleDetails}
    >
      <td colSpan={2} style={{paddingLeft: '3em'}}>
        <table>
          <tbody>
            {module.input &&
              module.input.channels.map((channel, index) =>
                renderModuleInputChannel(channel, index, inputModuleStates)
              )}
            {module.output && module.output.channels.map((channel, index) => renderModuleOutputChannel(channel, index))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};
