export enum CommonActionType {
  Reset = 'reset',
  StartLoading = 'startLoading',
  FinishLoading = 'finishLoading',
  Error = 'errorLoading',
  Append = 'append',
  Waiting = 'waiting',
  Update = 'update'
}

export interface ICommonAction {
  type: CommonActionType;
}

export interface ResetAction extends ICommonAction {
  type: CommonActionType.Reset;
  payload: {};
}

// when waiting a bit before loading (for instance, delayed search in the Users card)
export interface WaitingAction extends ICommonAction {
  type: CommonActionType.Waiting;
  payload: {};
}

export interface StartLoadingAction extends ICommonAction {
  type: CommonActionType.StartLoading;
  payload: {};
}

export interface FinishLoadingAction<T> extends ICommonAction {
  type: CommonActionType.FinishLoading;
  payload: T;
}

export interface ErrorAction extends ICommonAction {
  type: CommonActionType.Error;
  payload: {error: string};
}

export interface AppendToListAction<T> extends ICommonAction {
  type: CommonActionType.Append;
  payload: T;
}

export interface UpdateListAction<T> extends ICommonAction {
  type: CommonActionType.Update;
  payload: (item: T) => T;
}
