import React, {CSSProperties} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {Table as RsTable} from 'reactstrap';

import {ITableField} from '../models/Table';
import {T} from '../utils/Internationalization';
import {classes} from '../utils/Styles';

import {getVisibleColumnNames, IPersistedTableSettings} from './Table';
import styles from './Table/index.module.scss';
import TableColumnHeader from './Table/TableColumnHeader';
import UnknownValue from './Table/UnknownValue';

export interface LoadedPage<T> {
  items: T[];
  hasMore: boolean;
}

export interface InfiniteScrollingTableProps<T> {
  style?: CSSProperties;
  fields: ITableField<T>[];
  settings: IPersistedTableSettings;
  fillHeight?: boolean;
  loadPage: () => Promise<LoadedPage<T>>;
  rowKey: (item: T, index: number) => string | number;
  rowClass?: (item: T, index: number) => string;
  onClickedRow?: (item: T) => void;
}

interface InfiniteScrollingTableState<T> {
  items: T[];
  hasMore: boolean;
  renderedRows: JSX.Element[];
  columns: string[];
}

export class InfiniteScrollingTable<T> extends React.PureComponent<
  InfiniteScrollingTableProps<T>,
  InfiniteScrollingTableState<T>
> {
  loading?: Promise<void>;

  constructor(props: InfiniteScrollingTableProps<T>) {
    super(props);

    this.state = {
      items: [],
      renderedRows: [],
      hasMore: true,
      columns: getVisibleColumnNames(props.fields, props.settings)
    };
  }

  componentDidUpdate(lastProps: InfiniteScrollingTableProps<T>, lastState: InfiniteScrollingTableState<T>) {
    if (lastProps.settings !== this.props.settings || lastProps.fields !== this.props.fields) {
      const columns = getVisibleColumnNames(this.props.fields, this.props.settings);
      this.setState({columns}, () => this.rerender());
    }
  }

  rerender() {
    this.setState({
      renderedRows: this.state.items.map((item, index) => this.renderRow(item, index))
    });
  }

  renderRow(item: T, index: number): JSX.Element {
    const {onClickedRow, rowClass = () => '', rowKey, fields} = this.props;
    const {columns} = this.state;

    const handleClicked = onClickedRow === undefined ? undefined : () => onClickedRow && onClickedRow(item);
    const className =
      onClickedRow === undefined ? rowClass(item, index) : `${rowClass(item, index)} ${styles.clickableRow}`;
    return (
      <tr key={rowKey(item, index)} className={className} onClick={handleClicked}>
        {columns.map(column => {
          const field = fields.find(f => f.name === column);
          if (!field) return <td key={column} />;

          const content = field.renderCellContent(item);
          if (content === undefined) {
            return <UnknownValue key={field.name} className={styles[field.options.align] as string} />;
          } else {
            return (
              <td key={field.name} className={styles[field.options.align] as string}>
                {content}
              </td>
            );
          }
        })}
      </tr>
    );
  }

  loadItems = (page: number) => {
    if (this.loading) return;

    this.loadItemsInner();
  };

  loadItemsInner() {
    const {hasMore} = this.state;
    if (!hasMore) return;

    const {loadPage} = this.props;
    this.loading = loadPage().then(page => {
      const offset = this.state.items.length;
      const rendered = page.items.map((item, index) => this.renderRow(item, index + offset));
      this.loading = undefined;
      this.setState({
        items: this.state.items.concat(page.items),
        renderedRows: this.state.renderedRows.concat(rendered),
        hasMore: page.hasMore
      });
    });
  }

  renderHeader() {
    const {fields, settings} = this.props;
    const columns = getVisibleColumnNames(fields, settings);
    const cells = columns.map(column => {
      const field = fields.find(field => field.name === column);
      return field ? this.renderColumnHeader(field) : <th key={column}>{column}</th>;
    });
    return (
      <thead key="header">
        <tr>{cells}</tr>
      </thead>
    );
  }

  renderColumnHeader(field: ITableField<T>) {
    return <TableColumnHeader key={field.name} field={field} />;
  }

  renderBody() {
    return <tbody>{this.state.renderedRows}</tbody>;
  }

  render() {
    const {fillHeight, style} = this.props;
    const {hasMore} = this.state;
    const loader = <div className="loader">{T('infiniteScrollingTable.loading')}</div>;

    return (
      <div className={classes(styles.wrapper, styles.infiniteScroll, fillHeight && styles.fillHeight)} style={style}>
        <InfiniteScroll pageStart={0} loadMore={this.loadItems} hasMore={hasMore} loader={loader} useWindow={false}>
          <RsTable className={classes(styles.table)}>
            {this.renderHeader()}
            {this.renderBody()}
          </RsTable>
        </InfiniteScroll>
      </div>
    );
  }
}
