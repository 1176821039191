import React from 'react';
import 'dayjs/plugin/timezone';

import {DateFormatPreset, formatDate} from '../../components/DateFormatter';
import {FORMAT_EXCEL} from '../../core/constants';
import {IConsumptionValue} from '../../models/ConsumptionValue';
import {DEFAULT_OPTIONS, IFieldOptions, ITableField} from '../../models/Table';
import {T} from '../../utils/Internationalization';

export class CreateDateColumn implements ITableField<IConsumptionValue> {
  name: string = 'createdate';
  label: string;
  options: IFieldOptions;
  timeZoneId: string;

  constructor(timeZoneId: string, options: Partial<IFieldOptions> = {}) {
    this.label = T('electricityValues.field.createDate');
    this.options = {...DEFAULT_OPTIONS, ...options};
    this.timeZoneId = timeZoneId;
  }

  renderCellContent(item: IConsumptionValue) {
    if (item.delta === undefined) return undefined;

    const creationDate = item.timestamp + item.delta * 1000;

    const formatted = formatDate(creationDate, {
      timezoneId: this.timeZoneId,
      format: DateFormatPreset.LocaleDateWithMonthAndTimeWithTime
    });

    if (item.delta > 15 * 60) {
      return <span style={{color: 'red'}}>{formatted}</span>;
    } else {
      return formatted;
    }
  }

  getExportCSVValue(item: IConsumptionValue) {
    if (item.delta === undefined) return '';

    const creationDate = item.timestamp + item.delta * 1000;

    return formatDate(creationDate, {
      timezoneId: this.timeZoneId,
      format: FORMAT_EXCEL
    });
  }

  getExportExcelValue(item: IConsumptionValue) {
    if (item.delta === undefined) return '';

    const creationDate = item.timestamp + item.delta * 1000;

    return formatDate(creationDate, {
      timezoneId: this.timeZoneId,
      format: FORMAT_EXCEL
    });
  }

  sort = (a: IConsumptionValue, b: IConsumptionValue): number => {
    const creationDateA = a.timestamp + (a.delta || 0) * 1000;
    const creationDateB = b.timestamp + (b.delta || 0) * 1000;
    return creationDateA - creationDateB;
  };
}
