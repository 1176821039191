import React, {ReactNode} from 'react';

import styles from './CenteredText.module.scss';

interface CenteredTextProps {
  children: ReactNode;
}
export const CenteredText = (props: CenteredTextProps) => {
  const {children} = props;
  return <div className={styles.wrapper}>{children}</div>;
};
