import React from 'react';

import {Icon} from '../../components/Icon';
import {MiniCardHeader} from '../../components/MiniCardHeader';
import {migrateTableSettings, SortOrder} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {VerticalLayout} from '../../components/VerticalLayout';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {LocationFeature} from '../../models/Location';
import {BrandColors} from '../../utils/BrandColors';
import {useAppliances} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardTypeKey, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationDetails, useCardLocationId} from '../CardUtils';
import {MiniCard} from '../MiniCard';

import {ApplianceListModal} from './ApplianceListModal';

type IAppliancesMiniSettings = ICardSettingsWithTable;

const AppliancesMiniCard = (props: ICardProps<IAppliancesMiniSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const modals = useModals();
  const locationId = useCardLocationId(settings);
  const locationDetails = useCardLocationDetails(settings);
  const [appliances] = useAppliances(fetch, locationId);

  const handleClickedAppliances = () => {
    modals.show<void>(modalProps => (
      <ApplianceListModal
        settings={settings}
        onTableSettingsChanged={table => updateSettings({table})}
        appliances={appliances || []}
        {...modalProps}
      />
    ));
  };

  let error: string | undefined;
  if (locationDetails && !locationDetails.features.includes(LocationFeature.Appliances)) {
    error = T('appliances.error.noAppliances');
  }

  return (
    <MiniCard error={error} borderColor={BrandColors.Pumice} {...props}>
      <VerticalLayout>
        <MiniCardHeader
          icon={Icon.Appliances}
          title={T('appliancesMini.appliances', {
            count: appliances === undefined ? '--' : appliances.length.toString()
          })}
          subtitle={T('appliancesMini.subtitle')}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1
          }}
        >
          <Button onClick={handleClickedAppliances}>{T('appliancesMini.applianceList')}</Button>
        </div>
      </VerticalLayout>
    </MiniCard>
  );
};

const DEFAULT_SETTINGS: IAppliancesMiniSettings = {
  table: {
    pageSize: 10,
    sortColumn: 'name',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'name', visible: true},
      {name: 'type', visible: true},
      {name: 'power', visible: true},
      {name: 'consumption', visible: false},
      {name: 'cost', visible: false},
      {name: 'source', visible: false},
      {name: 'creationTimestamp', visible: true}
    ]
  }
};
const CARD: ICardType<IAppliancesMiniSettings> = {
  type: CardTypeKey.AppliancesMini,
  title: 'appliancesMini.title',
  description: 'appliancesMini.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 1,
  height: 1,
  minWidth: 1,
  minHeight: 1,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: AppliancesMiniCard
};
export default CARD;
