import React, {useMemo} from 'react';

import {SelectInputGroup} from '../../../components/inputs/SelectInput';
import {IApplianceType} from '../../../models/ApplianceType';

interface ApplianceTypeSelectorProps {
  type: string;
  applianceTypes: IApplianceType[];
  onSelected: (type: string) => void;
}

export default (props: ApplianceTypeSelectorProps) => {
  const {type, applianceTypes, onSelected} = props;

  const options = useMemo(
    () =>
      (applianceTypes || []).map(type => (
        <option key={type.id} value={type.id}>
          {type.translation}
        </option>
      )),
    [applianceTypes]
  );

  return (
    <SelectInputGroup name="applianceType" label="Appliance type" value={type} onChange={onSelected}>
      {options}
    </SelectInputGroup>
  );
};
