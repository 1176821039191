import React, {useState, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {FormGroup, Input, Label} from '../../components/bootstrap';
import Table, {IPersistedTableSettings} from '../../components/Table';
import {LocationField, CoordinatesField} from '../../components/Table/Fields';
import {Button} from '../../components/ui/button';
import {Bin} from '../../components/ui-lib/icons/small';
import API from '../../core/api';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {AuthUser} from '../../models/AuthUser';
import {ICardSettings, CardDisplayType} from '../../models/CardSettings';
import {ILocationSummary, getLocationTypeName} from '../../models/Location';
import {IOrganization} from '../../models/Organization';
import {
  CalculatedStringField,
  ComponentField,
  FieldAlignment,
  ITableField,
  StringField,
  CheckboxField,
  TimestampField
} from '../../models/Table';
import {AppStore} from '../../redux';
import {setLocation} from '../../redux/actions/location';
import {useDelayedEffect} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {useCardLocationId, useUser, useCardLocation} from '../CardUtils';
import {CustomSettings, CardView, CardViewBaseProps, cardViewProps, ICardState} from '../components/CardView';
import ColumnChooser from '../components/ColumnChooser';

import EditLocation from './EditLocation';

import {LocationActions} from './LocationActions';

import {LocationGoogleMap} from './LocationGoogleMap';
import {SetActivationCodePromiseModal, SetActivationCodeResult} from './SetActivationCodePromiseModal';

export interface BaseLocationsSettings extends ICardSettings {
  cardType: CardDisplayType;
  table: IPersistedTableSettings;
  zoomLevel: number;
  center: {lat: number; lng: number};
}

const rowKey = (item: ILocationSummary) => `${item.id}#${item.installationDate || 0}`;

interface LocationsListCardProps extends CardViewBaseProps<BaseLocationsSettings> {
  locations: ILocationSummary[];
  operator?: IOrganization;
  actions: (
    defaultActions: React.ReactNode,
    columns: ITableField<ILocationSummary>[],
    state: ICardState
  ) => JSX.Element;
  refresh: () => void;
  updateSettings: (updates: Partial<BaseLocationsSettings>) => void;
}

function getTableColumns(
  api: API,
  store: AppStore,
  me: AuthUser,
  isReadOnly: boolean,
  currentLocationId: number | undefined,
  selected: ILocationSummary[],
  setSelected: (locations: ILocationSummary[]) => void,
  listeners: {
    onClickedEditLocation: (location: ILocationSummary) => unknown;
    onClickedMove: (location: ILocationSummary) => unknown;
    onClickedDelete: (location: ILocationSummary) => unknown;
  }
): ITableField<ILocationSummary>[] {
  const {onClickedEditLocation, onClickedMove, onClickedDelete} = listeners;

  const isChecked = (row: ILocationSummary) => selected.some(item => item.id === row.id);

  const setChecked = (row: ILocationSummary, checked: boolean) => {
    const currentlyChecked = isChecked(row);
    if (checked === currentlyChecked) return;

    if (checked) {
      setSelected([...selected, row]);
    } else {
      setSelected(selected.filter(item => item.id !== row.id));
    }
  };

  return [
    new CheckboxField('selected', '', isChecked, setChecked, {
      autoInsert: 'start',
      width: 20
    }),
    new TimestampField('installationDate', T('locations.field.installationDate')),
    new TimestampField('closingDate', T('locations.field.closingDate')),
    new LocationField('name', T('locations.field.name'), location => location.name, currentLocationId, api, store, {
      alwaysVisible: true,
      autoInsert: 'start'
    }),
    new CalculatedStringField('type', T('locations.field.type'), location => getLocationTypeName(location.type)),
    new CoordinatesField('coordinates', T('locations.field.coordinates'), location => ({
      latitude: location.latitude,
      longitude: location.longitude
    })),
    new StringField('timeZoneId', T('locations.field.timezone')),
    new StringField('electricityCurrency', T('locations.field.currency')),
    new LocationField(
      'serialNumber',
      T('locations.field.serialNumber'),
      location => (location.historicDevice ? undefined : location.serialNumber),
      currentLocationId,
      api,
      store
    ),
    new ComponentField(
      'actions',
      T('locations.field.actions'),
      item => (
        <LocationActions
          item={item}
          isReadOnly={isReadOnly}
          me={me}
          onClickedEdit={onClickedEditLocation}
          onClickedMove={onClickedMove}
          onClickedDelete={onClickedDelete}
        />
      ),
      {autoInsert: 'end', align: FieldAlignment.Center}
    )
  ];
}

export const LocationsListCard = (props: LocationsListCardProps) => {
  const {locations, actions, refresh, settings, updateSettings} = props;
  const modals = useModals();
  const {api, store} = useAppContext();

  const {cardType} = settings;

  const [selected, setSelected] = useState<ILocationSummary[]>([]);
  const [currentZoom, setCurrentZoom] = useState(settings.zoomLevel);
  const [currentCenter, setCurrentCenter] = useState(settings.center);

  const handleClickedDeleteSelected = async () => {
    const confirmed = await modals.show(props => (
      <ConfirmationPromiseModal
        title={T('locations.deleteSelected.title')}
        message={T('locations.deleteSelected.message', {
          amount: selected.length.toString()
        })}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      if (!me?.isServiceDesk) {
        await Promise.all(selected.map(item => api.deleteLocation(item.id)));
      } else {
        await Promise.all(selected.map(item => api.hardDeleteLocation(item.id)));
      }
      NotificationManager.success(T('locations.deleteSelected.removed'));
      setSelected([]);
      refresh();
    } catch {
      NotificationManager.error(T('locations.deleteSelected.failed'));
    }
  };

  const locationId = useCardLocationId(settings);
  const location = useCardLocation(settings);
  const me = useUser();
  const isReadOnly = me.isReadOnly();

  const columns = useMemo(() => {
    const handleClickedEditLocation = async (location: ILocationSummary) => {
      const success = await modals.show(rest => (
        <EditLocation operator={props.operator} location={location} {...rest} />
      ));
      if (success) refresh();
    };

    const handleClickedMove = async (location: ILocationSummary) => {
      const result = await modals.show<SetActivationCodeResult | undefined>(props => (
        <SetActivationCodePromiseModal
          location={location as ILocationSummary}
          content={location.name || 'location'}
          {...props}
        />
      ));
      if (!result) return;

      const {activationCode, withDevice} = result;
      try {
        await api.setLocationActivationCode(location.id, activationCode.code);
        NotificationManager.success(T('locations.moveToOrganization.moved'));
      } catch {
        NotificationManager.error(T('locations.moveToOrganization.failed'));
      }
      if (withDevice && location.serialNumber) {
        try {
          const deviceCode = await api.getDeviceActivationCode(location.serialNumber);
          await api.activationCodes.setDeviceActivationCode(
            deviceCode && deviceCode.code,
            location.serialNumber || '',
            activationCode.code
          );
          NotificationManager.success(T('locations.moveToOrganization.deviceMoved'));
        } catch {
          NotificationManager.error(T('locations.moveToOrganization.deviceMoveFailed'));
        }
      }
    };

    const handleClickedDelete = async (location: ILocationSummary) => {
      const confirmed = await modals.show(props => (
        <ConfirmationPromiseModal
          title={T('locations.deleteLocation.title', {
            name: location.name || ''
          })}
          message={T('locations.deleteLocation.message')}
          {...props}
        />
      ));
      if (confirmed !== ConfirmationResult.Accept) return;

      try {
        await api.deleteLocation(location.id);
        refresh();
        NotificationManager.success(T('locations.deleteLocation.deleted'));
      } catch {
        NotificationManager.error(T('locations.deleteLocation.failed'));
      }
    };

    return getTableColumns(api, store, me, isReadOnly, locationId, selected, setSelected, {
      onClickedEditLocation: handleClickedEditLocation,
      onClickedMove: handleClickedMove,
      onClickedDelete: handleClickedDelete
    });
  }, [api, store, isReadOnly, locationId, me, modals, refresh, selected, props.operator]);

  const customSettings: CustomSettings<BaseLocationsSettings> = (settings, updateSettings) => {
    const {cardType, table} = settings;

    const handleChangeCardType = (e: React.SyntheticEvent<HTMLInputElement>) => {
      updateSettings({cardType: e.currentTarget.value as CardDisplayType});
    };

    return (
      <div>
        <FormGroup>
          <Label for="cardType">{T('card.displayType.label')}</Label>
          <Input type="select" name="cardType" value={cardType} onChange={handleChangeCardType} className="!tw-pl-0">
            <option value={CardDisplayType.Table}>{T('card.displayType.table')}</option>
            <option value={CardDisplayType.Map}>{T('card.displayType.map')}</option>
          </Input>
        </FormGroup>

        <ColumnChooser fields={columns} settings={table} updateSettings={table => updateSettings({table})} />
      </div>
    );
  };

  const handleClickedLocationMarker = (location: ILocationSummary) => {
    setLocation(store, api, location);
  };

  const handleZoomChanged = (zoomLevel: number) => {
    setCurrentZoom(zoomLevel);
  };

  const handleCenterChanged = (center?: {lat: number; lng: number}) => {
    if (!center) return;
    setCurrentCenter(center);
  };

  useDelayedEffect(
    () => {
      if (
        currentZoom === settings.zoomLevel &&
        currentCenter.lat === settings.center.lat &&
        currentCenter.lng === settings.center.lng
      ) {
        return;
      }

      updateSettings({
        zoomLevel: currentZoom,
        center: currentCenter
      });
    },
    [currentZoom, currentCenter],
    1000
  );

  const content =
    cardType === CardDisplayType.Map ? (
      <LocationGoogleMap
        locations={locations}
        currentLocation={location}
        zoom={settings.zoomLevel}
        center={settings.center}
        mapContainerStyle={{height: '100%'}}
        onClickedMarker={handleClickedLocationMarker}
        onZoomChanged={handleZoomChanged}
        onCenterChanged={handleCenterChanged}
      />
    ) : (
      <Table
        fields={columns}
        items={locations}
        rowKey={rowKey}
        noun="location"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        selected={selected.length}
      />
    );

  const defaultActions = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {selected.length > 0 && (
        <Button
          variant="secondary_default"
          title={T('locations.deleteSelected')}
          onClick={handleClickedDeleteSelected}
          className="tw-mx-0"
        >
          <Bin className="tw-h-4 tw-w-4" />
        </Button>
      )}
    </>
  );

  return (
    <CardView
      actions={state => actions(defaultActions, columns, state)}
      customSettings={customSettings}
      {...cardViewProps(props)}
    >
      {content}
    </CardView>
  );
};

export const DEFAULT_BASE_LOCATION_SETTINGS: BaseLocationsSettings = {
  cardType: CardDisplayType.Table,
  table: {
    pageSize: 10,
    columns: [
      {name: 'type', visible: true},
      {name: 'coordinates', visible: true},
      {name: 'timeZoneId', visible: true},
      {name: 'electricityCurrency', visible: true},
      {name: 'serialNumber', visible: true}
    ]
  },
  zoomLevel: 8,
  center: {lat: 50.822937, lng: 3.311227}
};
