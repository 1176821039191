import {AppDashboardCardType, IAppDashboard} from '../../models/AppDashboard';
import {createPowerChannelFromSpec, IPowerChannel} from '../../models/TrackingChannel';
import {UsageUnit} from '../../models/UsageValue';

export interface ILiveValuesChannels {
  consumptionChannel?: IPowerChannel;
  solarChannel?: IPowerChannel;
  alwaysOnChannel?: IPowerChannel;
  gasChannel?: IPowerChannel;
  waterChannel?: IPowerChannel;
}

export function getChannelsFromDashboard(dashboard: IAppDashboard, bigConsumer: boolean) {
  const bubblesCard = dashboard.cards.find(card => card.type === AppDashboardCardType.Bubbles);
  const properties = bubblesCard ? bubblesCard.properties : [];

  const consumptionParameter = properties.find(prop => prop.spec.name === 'electricity');
  const solarParameter = properties.find(prop => prop.spec.name === 'solar');
  const alwaysOnParameter = properties.find(prop => prop.spec.name === 'alwaysOn');
  const waterParameter = properties.find(prop => prop.spec.name === 'water');
  const gasParameter = properties.find(prop => prop.spec.name === 'gas');
  const electricityMultiplier = bigConsumer ? 0.001 : 1;

  const result: ILiveValuesChannels = {};
  if (consumptionParameter && consumptionParameter.spec.updateChannel) {
    result.consumptionChannel = createPowerChannelFromSpec(
      consumptionParameter.spec,
      electricityMultiplier,
      'consumption'
    );
    result.consumptionChannel.unit = bigConsumer ? UsageUnit.KiloWatt : UsageUnit.Watt;
  }
  if (solarParameter && solarParameter.spec.updateChannel) {
    result.solarChannel = createPowerChannelFromSpec(solarParameter.spec, electricityMultiplier, 'solar');
    result.solarChannel.unit = bigConsumer ? UsageUnit.KiloWatt : UsageUnit.Watt;
  }
  if (alwaysOnParameter && alwaysOnParameter.spec.updateChannel) {
    result.alwaysOnChannel = createPowerChannelFromSpec(alwaysOnParameter.spec, electricityMultiplier, 'always on');
    result.alwaysOnChannel.unit = bigConsumer ? UsageUnit.KiloWatt : UsageUnit.Watt;
  }
  if (waterParameter && waterParameter.spec.updateChannel) {
    result.waterChannel = createPowerChannelFromSpec(waterParameter.spec, undefined, 'water');
  }
  if (gasParameter && gasParameter.spec.updateChannel) {
    result.gasChannel = createPowerChannelFromSpec(gasParameter.spec, undefined, 'gas');
  }

  return result;
}
