import React, {useEffect, useMemo, useState} from 'react';

import {Input, ModalHeader, Table} from 'reactstrap';

import {Button as RsButton, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter} from '../components/bootstrap';

import {Icon, Icons} from '../components/Icon';
import FormSaveButton from '../components/inputs/FormSaveButton';
import {Checkbox} from '../components/ui/checkbox';
import {None} from '../utils/Arrays';
import {plural, PluralKey, T} from '../utils/Internationalization';
import {FieldValidator} from '../utils/Validation';

import styles from './ImportCSVModal.module.scss';

import {IPromiseModalProps, usePromiseModal} from './PromiseModal';

export interface ImportableField {
  field: string;
  label: string;
  required: boolean;
  validator?: FieldValidator;
}

interface ImportCSVModalProps extends IPromiseModalProps<boolean> {
  noun: PluralKey;
  info: string;
  fields: ImportableField[];
  importer: (values: {[key: string]: string}[]) => Promise<string | undefined>;
  skip?: (entry: {[key: string]: string}) => string | undefined; // return string to give the reason to skip
}

export default function ImportCSVModal(props: ImportCSVModalProps) {
  const {noun, info, fields, importer, skip} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const [error, setError] = useState<string>();

  const [rawData, setRawData] = useState<string>();
  const [separator, setSeparator] = useState(';');
  const [escapeCharacter, setEscapeCharacter] = useState('"');
  const [hasHeader, setHasHeader] = useState(true);
  const [columnAssignment, setColumnAssignment] = useState<(ImportableField | null)[]>(None);

  const [fileData, columns] = useMemo(() => {
    if (rawData === undefined) return [undefined, 0];

    const regex = new RegExp(
      `${separator}|([^${separator}${escapeCharacter}\\r\\n][^${separator}\\r\\n]*)|(${escapeCharacter}([^${escapeCharacter}]|${escapeCharacter}${escapeCharacter})*${escapeCharacter})|(\\r?\\n)`,
      'g'
    );
    const tokens = rawData.matchAll(regex);

    const rows: string[][] = [];
    let row: string[] = [];
    let emptyRow = true;
    let empty = false;
    for (let match of tokens) {
      const token = match[0];
      if (token === '\n' || token === '\r\n') {
        if (empty) row.push('');
        if (row.length > 0) rows.push(row);
        row = [];
        empty = false;
        emptyRow = true;
      } else if (token === separator) {
        if (empty || emptyRow) row.push('');
        empty = true;
        emptyRow = false;
      } else {
        if (token.startsWith(escapeCharacter)) {
          row.push(token.substr(1, token.length - 2).replaceAll(escapeCharacter + escapeCharacter, escapeCharacter));
        } else {
          row.push(token);
        }

        empty = false;
        emptyRow = false;
      }
    }
    if (empty) row.push('');
    if (row.length > 0) rows.push(row);

    const columns = rows.reduce((result, row) => Math.max(result, row.length), 0);
    return [rows, columns];
  }, [rawData, separator, escapeCharacter]);

  useEffect(() => {
    const assignment: (ImportableField | null)[] = [];
    for (let i = 0; i < columns; i++) {
      if (fileData && hasHeader) {
        const autodetected = fields.find(
          f => f.label.toLocaleLowerCase() === (fileData[0][i] || '').toLocaleLowerCase()
        );
        assignment.push(autodetected || null);
      } else {
        assignment.push(null);
      }
    }

    setColumnAssignment(assignment);
  }, [fileData, fields, columns, hasHeader]);

  const handleClose = () => resolve(false);
  const handleImport = async () => {
    setError(undefined);
    if (!fileData) return;

    for (let field of fields) {
      let occurrences = 0;
      for (let assignment of columnAssignment) {
        if (assignment === field) occurrences++;
      }

      if (field.required && occurrences === 0) {
        const error = T('validator.required', {name: field.label});
        setError(error);
        return error;
      }
      if (occurrences > 1) {
        const error = T('importCSV.duplicateColumn', {name: field.label});
        setError(error);
        return error;
      }
    }

    const rows: {[key: string]: string}[] = [];
    let hasErrors = false;
    let skipHeader = hasHeader;
    for (let inputRow of fileData) {
      const row: {[key: string]: string} = {};
      if (skipHeader) {
        skipHeader = false;
        continue;
      }
      inputRow.forEach((cell, index) => {
        const column = columnAssignment[index];
        if (!column) return;

        if (cell === '') {
          if (column.required) hasErrors = true;
        } else {
          if (column.validator) {
            const invalid = column.validator(cell, column.label, !column.required) !== undefined;
            if (invalid) {
              console.log(`Invalid data in ${column.field}: ${cell}`);
            }
            hasErrors = hasErrors || invalid;
          }

          row[column.field] = cell;
        }
      });
      if (skip && skip(row) !== undefined) continue;
      rows.push(row);
    }

    if (hasErrors) {
      const error = T('importCSV.hasErrors');
      setError(error);
      return error;
    }

    const error = await importer(rows);
    setError(error);
    if (error === undefined) resolve(true);
    return error;
  };

  const handleFileUploaded = (data: string) => {
    const commas = data.match(/,/g)?.length || 0;
    const semicolons = data.match(/;/g)?.length || 0;
    setSeparator(commas > semicolons ? ',' : ';');
    setRawData(data);
    setHasHeader(true);
  };

  const handleClickedReset = () => {
    setRawData(undefined);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} autoFocus={false} size="xl">
      <ModalHeader toggle={handleClose}>{T('importCSV.title', {entity: plural(noun)})}</ModalHeader>
      <ModalBody>
        {fileData === undefined && (
          <>
            <p>{T('importCSV.info1')}</p>
            {info && <p>{info}</p>}
            <UploadControl onUpload={handleFileUploaded} />
          </>
        )}
        {fileData !== undefined && (
          <>
            <Checkbox
              id="has-header"
              name="has-header"
              checked={hasHeader}
              label={T('importCSV.hasHeader')}
              onCheckedChange={setHasHeader}
              testId="has-header"
            />
            <p>{T('importCSV.info2')}</p>
            <ImportTable
              hasHeader={hasHeader}
              columns={columnAssignment}
              fields={fields}
              data={fileData}
              onChangeColumns={setColumnAssignment}
              skip={skip}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter error={error}>
        {fileData !== undefined && (
          <RsButton color="secondary" onClick={handleClickedReset} style={{flexShrink: 0}}>
            {T('importCSV.action.reset')}
          </RsButton>
        )}
        <FormSaveButton onSave={handleImport} disabled={fileData === undefined}>
          {T('importCSV.action.import')}
        </FormSaveButton>
      </ModalFooter>
    </Modal>
  );
}

interface ImportTableProps {
  hasHeader: boolean;
  columns: (ImportableField | null)[];
  fields: ImportableField[];
  data: string[][];
  onChangeColumns: (columns: (ImportableField | null)[]) => void;
  skip?: (entry: {[key: string]: string}) => string | undefined;
}

function ImportTable(props: ImportTableProps) {
  const {hasHeader, columns, fields, data, onChangeColumns, skip} = props;
  const headerRows = hasHeader ? [data[0]] : [];
  const dataWithoutHeader = hasHeader ? data.slice(1) : data;

  const fieldOptions = useMemo(() => {
    return fields.map(field => (
      <option key={field.field} value={field.field}>
        {field.label}
      </option>
    ));
  }, [fields]);

  const handleChangeAssignment = (index: number, fieldName: string) => {
    const selected = fields.find(x => x.field === fieldName) || null;
    onChangeColumns(columns.map((column, i) => (i === index ? selected : column)));
  };

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((column, index) => {
            const isDuplicate = !!columns.find((c, i) => c === column && i !== index);
            return (
              <th key={index}>
                <Input
                  type="select"
                  value={column?.field || ''}
                  onChange={e => handleChangeAssignment(index, e.currentTarget.value)}
                  invalid={isDuplicate}
                  title={
                    isDuplicate
                      ? T('importCSV.duplicateColumn', {
                          name: column?.label || ''
                        })
                      : undefined
                  }
                >
                  <option value="">{T('importCSV.noImport')}</option>
                  {fieldOptions}
                </Input>
              </th>
            );
          })}
        </tr>
        {headerRows.map((row, index) => (
          <tr key={index}>
            {row.map((cell, index) => (
              <th key={index}>{cell}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {dataWithoutHeader.map((row, index) => {
          const entry = columns.reduce(
            (result, column, index) => {
              if (column) {
                result[column.field] = row[index];
              }
              return result;
            },
            {} as {[key: string]: string}
          );
          const skipReason = skip ? skip(entry) : undefined;
          const skipRow = skipReason !== undefined;
          return (
            <tr key={index}>
              {row.map((cell, index) => {
                const column = columns[index];
                let error: string | undefined;
                if (column && !skipRow) {
                  if (cell === '') {
                    if (column.required) {
                      error = T('validator.required', {name: column.label});
                      cell = T('importCSV.requiredCell');
                    }
                  } else if (column.validator) {
                    error = column.validator(cell, column.label, !column.required);
                  }
                }
                return (
                  <td key={index} className={skipRow ? styles.skip : error ? styles.error : undefined} title={error}>
                    {cell}
                  </td>
                );
              })}
              {skip && <td>{skipRow && <i className={Icon.Ban} title={skipReason} />}</td>}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

interface UploadControlProps {
  onUpload: (value: string) => void;
}

function UploadControl(props: UploadControlProps) {
  const {onUpload} = props;
  const [error, setError] = useState();

  const handleFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    const file = files && files[0];
    if (!file) return;

    file.text().then(onUpload);
  };

  return (
    <FormGroup className={styles.files}>
      <input type="file" name="file" onChange={handleFileChanged} accept=".csv" />
      {error !== undefined && <FormFeedback style={{display: 'block'}}>{error}</FormFeedback>}
    </FormGroup>
  );
}
