export const enum CTType {
  SCT01_50_100_200 = 0,
  SCT01_400_800 = 1,
  SCT02_T10_50 = 3,
  SCT02_T16_100 = 4,
  SCT02_T24_200 = 5,
  SCT02_T36_400 = 6,
  SCT03_T12_50 = 8,
  SCT03_T12_100 = 9,
  SCT03_T12_200 = 10,
  RSCT_400 = 11,
  RSCT_1000 = 2,
  RSCT_4CM = 15
}

export const CT_TYPES = [
  CTType.SCT02_T10_50,
  CTType.SCT02_T16_100,
  CTType.SCT02_T24_200,
  CTType.SCT02_T36_400,
  CTType.RSCT_4CM,
  CTType.RSCT_400,
  CTType.RSCT_1000,
  CTType.SCT01_50_100_200,
  CTType.SCT01_400_800
];

const CT_TYPE_LABELS = {
  [CTType.SCT01_50_100_200]: 'SCT01 50A, 100A, 200A',
  [CTType.SCT01_400_800]: 'SCT01 400A, 800A',
  [CTType.SCT02_T10_50]: 'SCT02-T10/50A',
  [CTType.SCT02_T16_100]: 'SCT02-T16/100A',
  [CTType.SCT02_T24_200]: 'SCT02-T24/200A',
  [CTType.SCT02_T36_400]: 'SCT02-T36/400A',
  [CTType.RSCT_400]: 'RSCT-400A',
  [CTType.RSCT_1000]: 'RSCT-12CM, RSCT-19CM, RSCT-30CM, RSCT-1000A',
  // Nog niet in productie - niet tonen aan de gebruiker
  [CTType.SCT03_T12_50]: 'SCT03-T12/50A',
  [CTType.SCT03_T12_100]: 'SCT03-T12/100A',
  [CTType.SCT03_T12_200]: 'SCT03-T12/200A',
  [CTType.RSCT_4CM]: 'RSCT-4CM'
};
export function getCTTypeLabel(type: CTType) {
  return CT_TYPE_LABELS[type];
}
export const CT_TYPE_LABEL_CLOSED = 'Solid Core 3-Phase CT';
