import React, {FormEvent} from 'react';
import Autosuggest, {SuggestionsFetchRequestedParams, SuggestionSelectedEventData} from 'react-autosuggest';

import {createCurrencyOptions, ICurrency} from '../core/currencies';

import autocompleteTheme from './AutoComplete.module.scss';

interface CurrencyInputProps {
  value: string;
  onChange: (currency: ICurrency) => void;
  autoFocus: boolean;
}
interface CurrencyInputState {
  editingValue: string;
  suggestions: ICurrency[];
}
export class CurrencyInput extends React.PureComponent<CurrencyInputProps, CurrencyInputState> {
  static OPTIONS = createCurrencyOptions();

  constructor(props: CurrencyInputProps) {
    super(props);

    this.state = {
      editingValue: '',
      suggestions: []
    };
  }

  componentDidMount() {
    this.setState({editingValue: this.props.value});
  }

  componentDidUpdate(lastProps: CurrencyInputProps) {
    if (lastProps.value !== this.props.value) {
      this.setState({editingValue: this.props.value});
    }
  }

  handleChange = (e: FormEvent<HTMLElement>) => {
    const value = (e.currentTarget as HTMLInputElement).value as string | undefined;
    if (value === undefined) {
      // occurs when selecting a value in the list
      return;
    }

    this.setState({
      editingValue: value || ''
    });
  };

  onSuggestionsFetchRequested = (params: SuggestionsFetchRequestedParams) => {
    const currencies = CurrencyInput.OPTIONS;
    const queryLower = params.value.toLowerCase();
    this.setState({
      suggestions: currencies
        .filter(
          currency =>
            currency.code.toLowerCase().includes(queryLower) || currency.label.toLowerCase().includes(queryLower)
        )
        .slice(0, 5)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event: React.FormEvent<any>, data: SuggestionSelectedEventData<ICurrency>) => {
    this.props.onChange(data.suggestion);
  };

  renderCurrencySuggestion(suggestion: ICurrency) {
    return <div>{suggestion.label}</div>;
  }

  render() {
    const {editingValue, suggestions} = this.state;
    const {autoFocus} = this.props;

    const inputProps = {
      value: editingValue,
      className: 'form-control',
      onChange: this.handleChange,
      autoFocus
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={currency => currency.code}
        renderSuggestion={this.renderCurrencySuggestion}
        inputProps={inputProps}
        theme={autocompleteTheme}
      />
    );
  }
}
