import React from 'react';
import {FormGroup, Label, Input, Col, FormFeedback} from 'reactstrap';

interface BoardNameInputFormProps {
  number: string;
  name: string;
  numberError?: string;
  nameError?: string;
  readOnly: boolean;
  onNumberChanged: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  onNameChanged: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}
export const BoardNameInputForm = (props: BoardNameInputFormProps) => {
  const {number, name, numberError, nameError, onNumberChanged, onNameChanged, readOnly} = props;
  return (
    <FormGroup row>
      <Label sm={3} style={{maxWidth: 176, paddingLeft: 0}}>
        Bord identificatie:
      </Label>
      <Col sm={2}>
        <Input
          placeholder="Nr bord"
          type="number"
          value={number}
          onChange={onNumberChanged}
          invalid={numberError !== undefined}
          min={0}
          max={99}
          disabled={readOnly}
        />
        {numberError && <FormFeedback>{numberError}</FormFeedback>}
      </Col>
      <Col sm={5}>
        <Input
          placeholder="Naam bord"
          type="text"
          value={name}
          onChange={onNameChanged}
          invalid={nameError !== undefined}
          disabled={readOnly}
        />
        {nameError && <FormFeedback>{nameError}</FormFeedback>}
      </Col>
    </FormGroup>
  );
};
