import {Modal, ModalBody, ModalHeader} from '../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';

import {ChargingStationModule} from '../../models/ChargingStation';
import {T} from '../../utils/Internationalization';

import styles from './InfoMIDModal.module.scss';

interface InfoMIDModalProps extends IPromiseModalProps<boolean> {
  module: ChargingStationModule;
  message: string;
}

const InfoModal = (props: InfoMIDModalProps) => {
  const {message} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(true);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={handleClose}>{T('moduleReplacement.replace.powerBox.title')}</ModalHeader>
      <ModalBody className={styles.content}>
        <div>{message}</div>
      </ModalBody>
    </Modal>
  );
};
export default InfoModal;
