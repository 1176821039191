import {
  IBaseSmartDevice,
  IConfigurationProperty,
  IConfigurationPropertySpec,
  IConfigurationPropertyValue,
  IPostConfigurationProperty,
  ISmartDevice
} from '../models/SmartDevice';

import {APIClient} from './APIClient';

function convertProperties(
  specs: IConfigurationPropertySpec[],
  properties: {[key: string]: IConfigurationPropertyValue}
): IPostConfigurationProperty[] {
  return Object.keys(properties).map(key => {
    const property = specs.find(s => s.name === key);
    if (property === undefined) {
      throw new Error(`Property ${key} not found`);
    }
    return {
      spec: property,
      values: [properties[key]]
    };
  });
}

export class SmartDeviceAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  update(locationId: number, deviceId: string, device: Partial<ISmartDevice>): Promise<ISmartDevice | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices/${deviceId}`;
    return this.client.doPatch(url, device);
  }

  updateConfigurationProperty(
    locationId: number,
    smartDevice: IBaseSmartDevice,
    propertyName: string,
    value: IConfigurationPropertyValue
  ) {
    const configurationProperties = convertProperties(
      smartDevice.configurationProperties.map(p => p.spec),
      {[propertyName]: value}
    );
    return this.update(locationId, smartDevice.id, {
      configurationProperties: configurationProperties as IConfigurationProperty[]
    });
  }

  runActionRaw(
    serviceLocation: number,
    deviceId: string,
    action: string,
    properties: IPostConfigurationProperty[]
  ): Promise<unknown> {
    const url = `/api/v10/servicelocation/${serviceLocation}/homecontrol/smart/devices/${deviceId}/actions/${action}`;
    return this.client.doPost(url, properties);
  }

  runAction(
    locationId: number,
    device: IBaseSmartDevice,
    actionName: string,
    parameters: {[key: string]: IConfigurationPropertyValue}
  ) {
    const action = device.type.actions.find(a => a.name === actionName);
    if (action === undefined) {
      throw new Error(`Action ${actionName} not found`);
    }
    return this.runActionRaw(locationId, device.id, actionName, convertProperties(action.parameterSpecs, parameters));
  }
}
