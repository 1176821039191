import React from 'react';

import Table, {IPersistedTableSettings} from '../../components/Table';
import {IAppliance} from '../../models/Appliance';
import {IApplianceType} from '../../models/ApplianceType';
import {AuthUser} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {
  ITableField,
  StringField,
  CalculatedStringField,
  FieldAlignment,
  CalculatedNumberField,
  TimestampFieldWithTimezone
} from '../../models/Table';
import {UsageUnit} from '../../models/UsageValue';
import {T} from '../../utils/Internationalization';
import {capitalizeFirstLetter} from '../../utils/StringUtils';

/*handleRowEdit(row) {
    let rowEdits = undefined;
    if (row) {
      rowEdits = Object.assign({}, row);
    }

    this.setState({row, rowEdits});
  }

  handleChangeRow(event) {
    const {name, value} = event.target;
    const {rowEdits} = this.state;

    rowEdits[name] = value;
    this.forceUpdate();
  }

  handleClickSaveRow() {
    const {api, location} = this.props;
    const {locationId} = location;
    const {rowEdits: appliance, items} = this.state;
    const {id: applianceId} = appliance;

    // Update appliance on backend
    api.updateAppliance(
      locationId, applianceId,
      appliance,
    ).then(data => {
      // Update item after saving
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === applianceId) {
          items[i] = appliance;
          this.setState({items});
          this.forceUpdate();
          break;
        }
      }

      // Close modal
      this.handleRowEdit(undefined);
    });
  }

  renderRowModal() {
    const {rowEdits: row, applianceTypes} = this.state;
    const {name, type} = row;
    const typeOptions = applianceTypes.map(type => <option value={type.id}>{type.translation}</option>);

    return (
      <Modal
        isOpen={!!row}
        toggle={this.handleRowEdit.bind(this, undefined)}
        size="md"
        autoFocus={false}
      >
        <ModalHeader toggle={this.handleRowEdit.bind(this, undefined)}>
          {`Editing appliance`}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Appliance name</Label>
            <Input
              type="text"
              name="name"
              value={name || ''}
              onChange={this.handleChangeRow}
              autoFocus
            />
          </FormGroup>
          <FormGroup>
            <Label>Appliance type</Label>
            <Input
              type="select"
              name="type"
              value={type || ''}
              onChange={this.handleChangeRow}
            >
              {typeOptions}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{justifyContent: 'flex-end'}}>
          <Button
            color="primary"
            onClick={this.handleClickSaveRow}
          >
            Save appliance
          </Button>
        </ModalFooter>
      </Modal>
    );
  }*/

export function getApplianceListTableColumns(
  applianceTypes: IApplianceType[],
  currency: string,
  timezone: string | undefined,
  me: AuthUser
): ITableField<IAppliance>[] {
  const result: ITableField<IAppliance>[] = [];
  result.push(new StringField('name', T('appliances.field.name')));
  if (me.isServiceDesk()) {
    result.push(new StringField('id', T('appliances.field.id')));
  }

  result.push(
    new CalculatedStringField('type', T('appliances.field.type'), appliance => {
      const type = applianceTypes.find(type => type.id === appliance.type);
      return type ? type.translation : capitalizeFirstLetter(appliance.type).replace('_', ' ');
    }),
    /*new CalculatedNumberField(
      'power',
      T('appliances.field.power'),
      appliance => {
        if (appliance.stats === undefined || appliance.stats.power === undefined)
          return undefined;
        
        const { value, unit } = appliance.stats.power;
        return unit === UsageUnit.KiloWatt ? value * 1000 : value;
      },
      { unit: 'W', align: FieldAlignment.Right, digitsAfterComma: 0 }),*/
    new CalculatedNumberField(
      'consumption',
      T('appliances.field.consumption'),
      appliance => {
        if (appliance.stats === undefined || appliance.stats.usage === undefined) {
          return undefined;
        }

        const {value, unit} = appliance.stats.usage;
        return unit === UsageUnit.WattHour ? value / 1000 : value;
      },
      {
        unit: 'kWh',
        align: FieldAlignment.Right,
        digitsAfterComma: 2,
        byline: T('appliances.field.consumption.info')
      }
    ),
    new CalculatedNumberField(
      'cost',
      T('appliances.field.cost'),
      appliance =>
        appliance.stats === undefined || appliance.stats.cost === undefined ? undefined : appliance.stats.cost.value,
      {
        unit: currency,
        align: FieldAlignment.Right,
        digitsAfterComma: 2,
        byline: T('appliances.field.cost.info')
      }
    ), // TODO: shouldn't digits after comma be currency - dependent?
    new StringField('source', T('appliances.field.source')),
    new TimestampFieldWithTimezone(
      'creationTimestamp',
      'creationTimestamp',
      T('appliances.field.creationTimestamp'),
      timezone || 'UTC'
    )
  );
  return result;
}

interface ApplianceListProps {
  fields: ITableField<IAppliance>[];
  appliances: IAppliance[];
  settings: ICardSettingsWithTable;
  onTableSettingsChanged: (settings: IPersistedTableSettings) => void;
}

const rowKey = (appliance: IAppliance) => appliance.id;
export const ApplianceList = (props: ApplianceListProps) => {
  const {appliances, settings, onTableSettingsChanged, fields} = props;

  return (
    <Table
      fields={fields}
      items={appliances}
      rowKey={rowKey}
      hasPaging={true}
      settings={settings.table}
      updateSettings={onTableSettingsChanged}
      noun="appliance"
      emptyMessage={T('appliances.noAppliancesAvailable')}
    />
  );
};
