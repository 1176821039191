import type {SVGProps} from 'react';
const SvgNotificationBell2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    fillRule="evenodd"
    {...props}
  >
    <path d="M8.092 3.212a1.5 1.5 0 1 1 2.898-.777l.152.567c2.028-.174 3.854.86 4.523 3.357.464 1.732 3.507 4.807 5.88 7.001 1.19 1.1.73 3.134-.836 3.553L6.915 20.61c-1.567.42-2.982-1.111-2.501-2.66.958-3.086 2.056-7.27 1.592-9.003-.67-2.497.395-4.305 2.238-5.169l-.152-.566Zm5.979 16.515a3 3 0 0 1-5.797 1.552s5.796-1.553 5.796-1.553Z" />
  </svg>
);
export default SvgNotificationBell2;
