import {hasLanguage, Language} from './Internationalization';

export function autodetectLanguage(): Language {
  if (!window.navigator) return 'en';

  for (let language of navigator.languages) {
    const lang = language.split('-')[0].toLocaleLowerCase();
    if (hasLanguage(lang)) return lang as Language;
  }

  return 'en';
}
