import numeral from 'numeral';

import {ChargingStation} from '../models/ChargingStation';

import {IPowerMessage} from './LiveDataModels';
import {getLoadPower} from './LiveDataUtils';

function formatPowerKw(value: number) {
  return `${numeral(value / 1000).format('0.0')} kW`;
}

export function getSidePower(
  power: IPowerMessage | undefined,
  perSidePower: (IPowerMessage | undefined)[],
  station: ChargingStation,
  position: number
) {
  if (station.forcedState && station.forcedState.power !== undefined) {
    return formatPowerKw(station.forcedState.power);
  }

  const charger = station.getCarCharger(position);
  const sidePower = perSidePower.length === 0 ? power : perSidePower[position - 1];
  if (sidePower === undefined || charger === undefined) {
    return '--';
  } else {
    return formatPowerKw(getLoadPower(sidePower, charger.channelIndices));
  }
}
