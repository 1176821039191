import englishTranslations from '../translations/en.json';
import internalTranslations from '../translations/internal.en.json';

import {TranslationCategory as C} from './TranslationCategory';

export class Term {
  category: C;
  context: string;
  internal: boolean;

  constructor(category: C, context: string = '', internal: boolean = false) {
    this.category = category;
    this.context = context;
    this.internal = internal;
  }
}

export type TranslationKey = keyof typeof englishTranslations | keyof typeof internalTranslations;
export const translationKeys = Object.keys(englishTranslations).concat(
  Object.keys(internalTranslations)
) as TranslationKey[];

export const synonyms: {[key: string]: TranslationKey} = {
  'boardSettings.confirmRemove.reject': 'generic.action.cancel',
  'configuration.phaseType.unknown': 'generic.Unknown',
  'connectionType.unknown': 'generic.Unknown',
  'locationType.unknown': 'generic.Unknown',
  'automation.useractions': 'generic.actions'
};
