import React from 'react';

import {Period} from '../../components/PeriodSelector';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType} from '../../models/CardSettings';
import {SensorChannelType} from '../../models/Location';
import {SensorReadingType, ISensorReading} from '../../models/SensorReadings';
import {CalculatedNumberField, ITableField, TimestampFieldWithTimezone} from '../../models/Table';
import {Interval, UsageUnit, getUnitLabel} from '../../models/UsageValue';
import {BrandColors} from '../../utils/BrandColors';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationDetails} from '../CardUtils';
import {GasOrWaterCard} from '../GasValues/GasOrWaterCard';
import {IGasOrWaterSettings} from '../GasValues/GasOrWaterChart';

function getTableColumns(
  digitsAfterComma: number,
  unit: UsageUnit,
  timezone: string | undefined
): ITableField<ISensorReading>[] {
  return [
    new TimestampFieldWithTimezone('timestamp', 'timestamp', T('gasOrWaterCard.field.timestamp'), timezone || 'UTC'),
    new CalculatedNumberField(
      'water',
      T('gasOrWaterCard.field.water'),
      item => (item.value == null ? undefined : item.value),
      {unit: getUnitLabel(unit), digitsAfterComma}
    )
  ];
}

const WaterValues = (props: ICardProps<IGasOrWaterSettings>) => {
  const {settings} = props;
  const location = useCardLocationDetails(settings);
  const available = (location && location.hasWater) || false;

  return (
    <GasOrWaterCard
      available={available}
      type={SensorReadingType.Water}
      gasWaterType={SensorChannelType.Water}
      getTableColumns={getTableColumns}
      seriesLabel={T('gasOrWaterCard.field.water')}
      seriesColor={BrandColors.Blue}
      {...props}
    />
  );
};

const DEFAULT_SETTINGS: IGasOrWaterSettings = {
  cardType: CardDisplayType.Chart,
  period: Period.DAYS_7,
  interval: Interval.HOUR,
  table: {
    pageSize: 10,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'water', visible: true}
    ]
  }
};
const CARD: ICardType<IGasOrWaterSettings> = {
  type: CardTypeKey.WaterValues,
  title: 'waterValues.title',
  description: 'waterValues.description',
  categories: [CardCategory.WATER],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: WaterValues
};
export default CARD;
