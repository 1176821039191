export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function generateRandomString(length: number) {
  let secret = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    secret += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return secret;
}

export function kebabToSentence(value: string) {
  let newStr: string;
  if (value?.length) {
    newStr = capitalizeFirstLetter(value.trim().toLocaleLowerCase());
    return newStr.replace('_', ' ');
  }
  return value;
}
