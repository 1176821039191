import {IApplianceType} from '../../models/ApplianceType';
import {AuthUser} from '../../models/AuthUser';
import {IEvent, getSourceTypeName} from '../../models/Event';
import {CalculatedStringField, ITableField, TimestampFieldWithTimezone} from '../../models/Table';
import {T} from '../../utils/Internationalization';
import {capitalizeFirstLetter} from '../../utils/StringUtils';
import {formatDuration} from '../../utils/TimestampUtils';

export function getTableColumns(
  timeZoneId: string,
  applianceTypes: {[key: string]: IApplianceType},
  user: AuthUser
): ITableField<IEvent>[] {
  const result: ITableField<IEvent>[] = [
    new TimestampFieldWithTimezone('timestamp', 'timestamp', T('events.field.timestamp'), timeZoneId, {width: 100}),
    new CalculatedStringField('sourceName', T('events.field.name'), row => row.source.name)
  ];
  if (user.isServiceDesk()) {
    result.push(new CalculatedStringField('applianceId', T('events.field.applianceId'), row => row.source.id));
  }
  result.push(
    new CalculatedStringField('sourceCategory', T('events.field.type'), row => {
      let type = applianceTypes[row.source.category || ''];
      if (type) return type.translation;

      return capitalizeFirstLetter(row.source.category || '').replace('_', ' ') || getSourceTypeName(row.source.type);
    }),
    new CalculatedStringField('duration', T('events.field.duration'), row => {
      if (row.duration === undefined) return undefined;

      return formatDuration(row.duration);
    }),
    new CalculatedStringField('type', T('events.field.action'), row => {
      if (row.typeLabel !== undefined) return row.typeLabel;

      return row.type;
    })
  );
  return result;
}
export const tableRowKey = (row: IEvent) => row.id;
