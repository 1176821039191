import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';

import {cn} from '../../lib/utils';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({className, ...props}, ref) => {
  const value = props.value || props.defaultValue;
  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn('tw-relative tw-flex tw-w-full tw-touch-none tw-select-none tw-items-center', className)}
      {...props}
    >
      <SliderPrimitive.Track className="tw-relative tw-h-2 tw-w-full tw-grow tw-overflow-hidden tw-rounded-full tw-bg-neutral-100 dark:tw-bg-neutral-700">
        <SliderPrimitive.Range className="tw-absolute tw-h-full tw-bg-secondary" />
      </SliderPrimitive.Track>
      {value?.map((_, i) => (
        <SliderPrimitive.Thumb
          key={i}
          className="tw-block tw-h-5 tw-w-5 tw-rounded-full tw-border-2 tw-border-neutral-200 tw-bg-white tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50"
        />
      ))}
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export {Slider};
