import React from 'react';

import {DeviceType} from '../../models/DeviceType';
import {ILocation, isBigConsumer} from '../../models/Location';

import {BrandColors} from '../../utils/BrandColors';

import {Gauge} from './Gauge';
import styles from './Summary.module.scss';

import {UsageValues} from './UsageValues';

interface SummaryProps {
  location: ILocation | undefined;
  consumption: number | undefined;
  solar: number | undefined;
  alwaysOn: number | undefined;
  water: number | undefined;
  gas: number | undefined;
  electricityUnit: string;
  waterUnit: string;
  gasUnit: string;
}
export const Summary = (props: SummaryProps) => {
  const {location, consumption, solar, alwaysOn, water, gas, electricityUnit, waterUnit, gasUnit} = props;

  const hasSolar = (location && location.hasSolar && location.deviceType !== DeviceType.P1S1) || false;
  const bigConsumer = location !== undefined && isBigConsumer(location);

  return (
    <>
      <div className={styles.gaugeContainer}>
        <Gauge
          value={consumption}
          unit={electricityUnit}
          backgroundColor="#ffe9ab"
          color={BrandColors.OrangeSunglow}
          iconClassName={styles.gaugeIconElectricity}
          icon="si-electricity-filled"
          bigConsumer={bigConsumer}
        />
        {hasSolar && (
          <Gauge
            value={solar}
            unit={electricityUnit}
            backgroundColor={BrandColors.GreenAtlantis} // #7AB3A4
            color={BrandColors.GreenAtlantis}
            iconClassName={styles.gaugeIconSolar}
            icon="si-solar-filled"
            bigConsumer={bigConsumer}
          />
        )}
      </div>
      <UsageValues
        key="usage"
        location={location}
        alwaysOn={alwaysOn}
        gas={gas}
        water={water}
        electricityUnit={electricityUnit}
        gasUnit={gasUnit}
        waterUnit={waterUnit}
      />
    </>
  );
};
