import * as React from 'react';

import {useFormContext} from '../../utils/FormContext';

import {T} from '../../utils/Internationalization';

import {minutesToHoursMinutes} from '../../utils/NumberUtils';
import {classes} from '../../utils/Styles';

import styles from './DurationInput.module.scss';
import FormInputGroup from './FormInputGroup';
import baseStyles from './index.module.scss';

import {NumberInputGroup, NumberValue} from './NumberInput';
import {TextInputGroup} from './TextInput';

type DurationInputProps = Omit<
  React.ComponentProps<typeof TextInputGroup>,
  'value' | 'onChange' | 'suffix' | 'as' | 'min' | 'max'
> & {
  value: number; // minutes
  onChange: (value: number) => void; //minutes
  className?: string;
  showLabels?: boolean;
};

function DurationInput(props: DurationInputProps) {
  const {name, label, value, onChange, className, optional, disabled, showLabels = true} = props;

  const form = useFormContext();
  const info = props.info || (optional ? T('validatedInput.optional') : undefined);

  const validate = (value: string) => {
    if (value.length === 0) {
      if (optional) {
        return undefined;
      } else {
        return T('validator.required', {name: label ?? name});
      }
    }

    const numValue = parseFloat(value.replace(',', '.'));
    if (isNaN(numValue)) return T('validator.invalidNumber', {name: label ?? name});
  };

  const error = validate(value.toString());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => form.setError(name, error), [name, error]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => form.remove(name), [name]);

  const handleBlur = () => form.setErrorVisible(name, error !== undefined);

  const ref = React.useCallback((ref: HTMLInputElement | null) => form.setRef(name, ref), [form, name]);

  const shownError = props.error || form.getShownError(name);

  const hourMinutes = minutesToHoursMinutes(value ?? 0);

  const onHourChange = React.useCallback(
    (hour: NumberValue) => {
      onChange((hour.numberValue ?? 0) * 60 + hourMinutes.minutes);
    },
    [hourMinutes.minutes, onChange]
  );

  const onMinuteChange = React.useCallback(
    (minutes: NumberValue) => {
      onChange(hourMinutes.hours * 60 + (minutes.numberValue ?? 0));
    },
    [hourMinutes.hours, onChange]
  );

  return (
    <FormInputGroup
      name={name}
      label={label ?? ''}
      error={shownError}
      className={classes(className, baseStyles.noSpinner)}
      info={info}
    >
      <input type="hidden" name={name} value={value} ref={ref} />
      <div className={styles.durationInput} onBlur={handleBlur}>
        <div>
          <NumberInputGroup
            disabled={disabled}
            label={showLabels ? T('timedInput.hours') : undefined}
            name={`${name}-hours`}
            value={NumberValue.create(hourMinutes.hours)}
            onChange={onHourChange}
            min={0}
          />
        </div>
        <div>
          <NumberInputGroup
            disabled={disabled}
            label={showLabels ? T('timedInput.minutes') : undefined}
            name={`${name}-minutes`}
            value={NumberValue.create(hourMinutes.minutes)}
            onChange={onMinuteChange}
            min={0}
          />
        </div>
      </div>
    </FormInputGroup>
  );
}

export {DurationInput};
