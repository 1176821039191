import {SeriesAreaOptions, SeriesLineOptions, SeriesColumnOptions} from 'highcharts/highstock';
import React, {useMemo} from 'react';

import {createIntervalChart} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import {ActivePeriod, getReferenceLengthOfInterval} from '../../components/PeriodSelector';
import {KW_DIGITS_AFTER_COMMA} from '../../core/constants';
import {ChargingStation} from '../../models/ChargingStation';
import {IConsumptionValue, getNonNullConsumptionChannelMeasurement} from '../../models/ConsumptionValue';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {getPhaseIndex, getPhaseLabel, Phase} from '../../models/Phase';
import {Interval} from '../../models/UsageValue';

import {unitIsPowerInsteadOfConsumption} from '../../models/UsageValue';
import {createPointFormatter, getChartTypeBasedOnInterval} from '../../utils/GraphUtils';
import {T} from '../../utils/Internationalization';

const COLORS = [
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a'
];
export function getColor(index: number) {
  return COLORS[index % COLORS.length];
}

export function fixChargingStationGaps(consumptionOriginal: IConsumptionValue[], interval: Interval) {
  const fillGaps = false; /*interval === Interval.MINUTES_5
    || interval === Interval.X_MINUTES_10
    || interval === Interval.X_MINUTES_15
    || interval === Interval.X_MINUTES_20
    || interval === Interval.X_MINUTES_30;*/

  const consumption = consumptionOriginal.filter(consumption => consumption.value !== undefined);
  if (!fillGaps || consumption.length === 0) return consumption;

  const intervalLength = getReferenceLengthOfInterval(interval);
  const maxGap = intervalLength + 60 * 1000;
  let last = consumption[0];
  for (let i = 1; i < consumption.length; i++) {
    while (consumption[i].timestamp - last.timestamp > maxGap) {
      last = {...last, timestamp: last.timestamp + intervalLength};
      consumption.splice(i, 0, last);
    }
    last = consumption[i];
  }

  return consumption;
}

export function createChargingStationSeries(
  series: (SeriesAreaOptions | SeriesLineOptions | SeriesColumnOptions)[],
  chargingStation: ChargingStation,
  consumption: {timestamp: number; activePower?: (number | null)[]}[],
  unit: string,
  prefix: string,
  bigConsumer: boolean,
  interval: Interval
): string[] {
  const powerMultiplier = bigConsumer ? 0.001 : 1;
  const digitsAfterComma = bigConsumer ? KW_DIGITS_AFTER_COMMA : 0;
  const sides = chargingStation.getControllersSorted();
  const ids: string[] = [];
  let index = 0;

  for (var side of sides) {
    const childLocationId = chargingStation.data.parts?.find(part => part.position === side.position)?.serviceLocation
      ?.id;
    const connector = side.label || '';
    const label = (prefix ? `${prefix} ` : '') + T('chargingStationConsumption.series', {connector});
    ids.push(`side${side.id}`);
    series.push({
      id: `side${side.id}`,
      name: label,
      type: getChartTypeBasedOnInterval(interval, 'area'),
      stacking: 'normal',
      yAxis: 0,
      color: getColor(index++),
      data: consumption.map(item => [
        // eslint-disable-line no-loop-func
        item.timestamp,
        getNonNullConsumptionChannelMeasurement(
          item,
          childLocationId,
          (side.smartDevice && side.smartDevice.carCharger!.channelIndices) || [],
          powerMultiplier
        )
      ]),
      tooltip: {
        pointFormatter: createPointFormatter(label, unit, digitsAfterComma)
      }
    } as SeriesAreaOptions | SeriesColumnOptions);
  }
  return ids;
}

export function createChargingStationCurrentSeries(
  series: (SeriesAreaOptions | SeriesLineOptions | SeriesColumnOptions)[],
  chargingStation: ChargingStation,
  consumption: IConsumptionValue[],
  prefix: string,
  phaseType: PhaseType,
  phases: Phase[],
  interval: Interval
): string[] {
  const sides = chargingStation.getControllersSorted();
  const ids: string[] = [];

  for (var side of sides) {
    const connector = side.label || 'Side ?';
    const label = (prefix ? `${prefix} ` : '') + T('chargingStationConsumption.series', {connector});
    const carCharger = side.smartDevice && side.smartDevice.carCharger;
    if (carCharger === undefined) continue;

    for (var phase_ of phases) {
      const phase = phase_;
      const channelIndex = carCharger.channelIndices[getPhaseIndex(phase)];
      if (channelIndex == null) continue;

      const labelForPhase = `${label} ${getPhaseLabel(phaseType, phase)}`;
      const id = `side${side.id}_${phase}`;

      ids.push(id);
      series.push({
        id,
        name: labelForPhase,
        type: getChartTypeBasedOnInterval(interval, 'area'),
        stacking: 'normal',
        stack: phase,
        yAxis: 0,
        data: consumption.map(item => {
          const value = (item.current || [])[channelIndex];
          return [
            // eslint-disable-line no-loop-func
            item.timestamp,
            value === undefined ? null : value
          ];
        }),
        tooltip: {
          pointFormatter: createPointFormatter(labelForPhase, 'A', 1)
        }
      } as SeriesAreaOptions | SeriesColumnOptions);
    }
  }

  return ids;
}

interface ChargingStationGraphProps {
  consumption: IConsumptionValue[];
  chargingStation: ChargingStation;
  period: ActivePeriod;
}
export const ChargingStationGraph = React.memo((props: ChargingStationGraphProps) => {
  const {consumption, chargingStation, period} = props;
  const {interval} = period;

  const bigConsumer = true;
  const unitAsPower = unitIsPowerInsteadOfConsumption(interval);
  const unit = unitAsPower ? (bigConsumer ? 'kW' : 'W') : bigConsumer ? 'kWh' : 'Wh';
  const yAxisAtLeast = bigConsumer ? 1 : 1000;

  const [series, max] = useMemo(() => {
    const series: SeriesAreaOptions[] = [];
    createChargingStationSeries(series, chargingStation, consumption, unit, '', bigConsumer, interval);

    let max: number = 0;
    for (var serie of series) {
      max = (serie.data as [number, number][]).reduce((x, value) => Math.max(x, value[1]), max);
    }

    return [series, max];
  }, [chargingStation, consumption, unit, bigConsumer, interval]);

  const [config, actualFrom, actualTo] = createIntervalChart({
    period,
    series,
    yAxis: [
      {
        title: {text: unit},
        opposite: false,
        showEmpty: false,
        startOnTick: false,
        max: max < yAxisAtLeast ? yAxisAtLeast : null
      }
    ],
    navigatorSeries: series.map(serie => serie.id!)
  });

  return consumption.length === 0 ? <div /> : <HighStockChart from={actualFrom} to={actualTo} config={config} />;
});
