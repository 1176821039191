import type {SVGProps} from 'react';
const SvgEyeInvisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M.251 1.463A.856.856 0 1 1 1.463.251l2.731 2.731c1.111-.729 2.404-1.268 3.805-1.268 1.783 0 3.391.874 4.671 1.899 1.287 1.03 2.305 2.262 2.921 3.089l.005.006c.267.369.403.83.403 1.292s-.137.923-.403 1.292l-.005.006c-.533.715-1.367 1.735-2.417 2.664l2.574 2.574a.856.856 0 1 1-1.212 1.212L.251 1.463Zm10.07 7.646a2.571 2.571 0 0 0-3.43-3.43l3.43 3.43ZM.408 6.702c.346-.465.82-1.058 1.397-1.671l8.699 8.699c-.779.341-1.62.556-2.504.556-1.783 0-3.391-.874-4.671-1.898-1.287-1.03-2.305-2.262-2.921-3.089l-.005-.006C.136 8.924 0 8.463 0 8.001s.137-.923.403-1.292l.005-.006Z" />
  </svg>
);
export default SvgEyeInvisible;
