import React, {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../../app/context';
import {Form, SingleActionModal} from '../../../components/bootstrap';
import {OrganizationInputGroup} from '../../../components/inputs/OrganizationInput';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {IActivationCode, IOrganizationActivationCode} from '../../../models/ActivationCode';
import {IOrganization} from '../../../models/Organization';
import {useOrganizations} from '../../../utils/FunctionalData';
import {T} from '../../../utils/Internationalization';

interface MoveToOrganizationModalProps extends IPromiseModalProps<IOrganizationActivationCode | undefined> {
  activationCode: IActivationCode;
  message?: string;
}

export default function MoveToLocationModal(props: MoveToOrganizationModalProps) {
  const {activationCode, message} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const [allOrganizations] = useOrganizations(props.fetch);
  const baseOrganization = allOrganizations.find(org => org.id === activationCode.organizationId);

  const organizations = baseOrganization
    ? [baseOrganization, ...allOrganizations.filter(x => x.parentId === baseOrganization.id)]
    : [];

  const [organization, setOrganization] = useState<IOrganization | undefined>(baseOrganization);
  useEffect(() => setOrganization(baseOrganization), [baseOrganization]);

  const handleClose = () => resolve(undefined);

  const handleConfirm = async () => {
    if (organization === undefined) return;

    const activationCodes = await api.activationCodes.getAll();
    const activationCodeOrganization = activationCodes.organizations.find(
      org => org.id === organization.id || org.id === organization.parentId
    );
    if (!activationCodeOrganization) {
      NotificationManager.error(T('locationConfiguration.moveToOrganization.organizationNotFound'));
      return;
    }

    const rootActivationCode =
      activationCode.subOrganizationId === undefined
        ? activationCode
        : activationCodeOrganization.activationCodes.find(x => x.name === activationCode.name);

    if (!rootActivationCode) {
      NotificationManager.error(T('locationConfiguration.moveToOrganization.parentActivationCodeNotFound'));
      return;
    }

    if (organization.parentId === undefined) {
      resolve(rootActivationCode);
      return;
    }

    const existing = activationCodeOrganization.activationCodes.find(
      x => x.name === rootActivationCode.name && x.subOrganizationId === organization.id
    );
    if (existing) {
      resolve(existing);
      return;
    }

    const childActivationCode = await api.activationCodes.createChild(rootActivationCode.code, organization.id);
    resolve(childActivationCode);
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      title={T('locationConfiguration.moveToOrganization.title')}
      action={handleConfirm}
      loading={allOrganizations.length === 0}
    >
      {message && <p style={{marginTop: '1em'}}>{message}</p>}
      <Form>
        <OrganizationInputGroup
          label={T('locationConfiguration.moveToOrganization.organization')}
          name="organisation"
          organizations={organizations}
          value={organization}
          onChange={setOrganization}
          onUpdateQuery={() => {}}
        />
      </Form>
    </SingleActionModal>
  );
}
