import {T} from '../utils/Internationalization';
import {TranslationKey} from '../utils/TranslationTerms';

import {ChargingStationModule} from './ChargingStation';
import {PhaseType} from './HighLevelConfiguration';
import {SmartDeviceTypeCategory} from './SmartDevice';

export const enum DeviceType {
  Energy = 'ENERGY',
  Solar = 'SOLAR',
  Plus = 'PLUS',
  Pro = 'PRO',
  Genius = 'SMAPPEE2',
  WifiConnect = 'WIFI_CONNECT',
  Bess = 'BESS_CONNECT',
  GasWater = 'FROGGEE',
  Switch = 'DEVICE_SWITCH',
  PowerBox = 'POWERBOX',
  CTHub = 'CTHUB_OPEN',
  CTHubLite = 'CTHUB_OPEN_LITE',
  ClosedCTHub = 'CTHUB_CLOSED',
  Hub16 = 'CTHUB_16', // not in production
  P1S1 = 'P1S1',
  P1S1Internal = 'P1S1_INTERNAL', // not a real device type; but used to upgrade the powerbox firmware
  EthernetConnect = 'ETHERNET_CONNECT', // or simply "Connect"
  FourGConnect = 'FOURG_CONNECT',

  InputModule = 'INPUT_MODULE',
  OutputModule = 'OUTPUT_MODULE',
  ACChargingController = 'AC_CAR_CHARGE_CONTROLLER',
  DCChargingController = 'DC_CAR_CHARGE_CONTROLLER',
  DCCarChargerControllerV4 = 'DC_CAR_CHARGE_CONTROLLER_V4',
  ACChargingControllerWithRFID = 'AC_CAR_CHARGE_CONTROLLER_RFID',
  DCCarChargeTripleDisplay = 'DC_CAR_CHARGE_TRIPLEDISPLAY',
  DCCarChargeRFIDTripleDisplay = 'DC_CAR_CHARGE_RFID_TRIPLEDISPLAY',

  LED = 'AC_CAR_CHARGE_LED',
  RFID = 'AC_CAR_CHARGE_RFID',
  MIDSingle = 'MID_SINGLE_PHASE',
  MIDPoly = 'MID_POLY_PHASE',
  MIDPolyISKRA = 'MID_ISKRA_POLY_PHASE',
  MIDPolyLEM = 'MID_LEM_POLY_PHASE',
  LEMDCMeter = 'MID_LEM_POLY_PHASE',
  RCM = 'AC_CAR_CHARGE_RCM',
  RelayBoard4P = 'AC_CAR_CHARGE_RELAY_BOARD_4P',
  RelayBoard2x2P = 'AC_CAR_CHARGE_RELAY_BOARD_2X2P',
  RelayBoardUS = 'AC_CAR_CHARGE_RELAY_BOARD_US',

  CarChargerSticker = 'AC_CAR_CHARGE_STICKER',

  UNKNOWN = '__UNKNOWN__'
}

export function isConnect(type: DeviceType) {
  return type === DeviceType.WifiConnect || type === DeviceType.EthernetConnect || type === DeviceType.FourGConnect;
}

export function isValidLocalityChild(type: DeviceType) {
  return isConnect(type) || type === DeviceType.P1S1 || type == DeviceType.Bess;
}

export function hasHighLevelConfig(type: DeviceType) {
  return isGatewayDevice(type);
}

export function isACChargingController(module: ChargingStationModule) {
  return (
    module.type === DeviceType.ACChargingController ||
    (module.type === DeviceType.ACChargingControllerWithRFID &&
      module.smartDevice?.type?.category == SmartDeviceTypeCategory.CarCharger)
  );
}

export function isDCchargingController(module: ChargingStationModule) {
  return module.type === DeviceType.DCChargingController || module.type === DeviceType.DCCarChargerControllerV4;
}

export function isChargingController(module: ChargingStationModule) {
  return isACChargingController(module) || isDCchargingController(module);
}

export function getDeviceTypeFromSerial(serial: string): DeviceType {
  if (serial.startsWith('10')) {
    return DeviceType.Energy;
  } else if (serial.startsWith('11')) {
    return DeviceType.Solar;
  } else if (serial.startsWith('20')) {
    return DeviceType.Pro;
  } else if (serial.startsWith('30')) {
    return DeviceType.GasWater;
  } else if (serial.startsWith('40')) {
    return DeviceType.Switch;
  } else if (serial.startsWith('50')) {
    return DeviceType.Genius;
  } else if (serial.startsWith('5110')) {
    return DeviceType.WifiConnect;
  } else if (serial.startsWith('5130')) {
    return DeviceType.EthernetConnect;
  } else if (serial.startsWith('5140')) {
    return DeviceType.FourGConnect;
  } else if (serial.startsWith('5700')) {
    return DeviceType.P1S1;
  } else if (serial.startsWith('5701')) {
    return DeviceType.P1S1Internal;
  } else if (serial.startsWith('5800')) {
    return DeviceType.OutputModule;
  } else if (serial.startsWith('5810')) {
    return DeviceType.InputModule;
  } else if (serial.startsWith('5820')) {
    return DeviceType.ACChargingController;
  } else if (serial.startsWith('5821')) {
    return DeviceType.ACChargingControllerWithRFID;
  } else if (serial.startsWith('5830')) {
    return DeviceType.LED;
  } else if (serial.startsWith('5840')) {
    return DeviceType.RFID;
  } else if (serial.startsWith('5842')) {
    return DeviceType.DCCarChargeTripleDisplay;
  } else if (serial.startsWith('5843')) {
    return DeviceType.DCCarChargeRFIDTripleDisplay;
  } else if (serial.startsWith('5850')) {
    return DeviceType.MIDSingle;
  } else if (serial.startsWith('5860')) {
    return DeviceType.MIDPoly;
  } else if (serial.startsWith('5861')) {
    return DeviceType.MIDPolyISKRA;
  } else if (serial.startsWith('5862')) {
    return DeviceType.LEMDCMeter;
  } else if (serial.startsWith('5870')) {
    // top panel
  } else if (serial.startsWith('5880')) {
    return DeviceType.DCChargingController;
  } else if (serial.startsWith('5881')) {
    return DeviceType.DCCarChargerControllerV4;
  }

  return DeviceType.UNKNOWN;
}

class DeviceTypeInfo {
  type: DeviceType;
  nameKey: TranslationKey;
  iconPath: string;
  nameInternalKey: TranslationKey;
  rebootable: boolean;
  restorable: boolean;
  hasFirmware: boolean;

  constructor(
    type: DeviceType,
    nameKey: TranslationKey,
    iconName: string,
    nameInternalKey?: TranslationKey,
    rebootable: boolean = true,
    restorable: boolean = true,
    hasFirmware: boolean = true
  ) {
    this.type = type;
    this.nameKey = nameKey;
    this.iconPath = `/assets/devices/${iconName}`;
    this.nameInternalKey = nameInternalKey || nameKey;
    this.rebootable = rebootable;
    this.restorable = restorable;
    this.hasFirmware = hasFirmware;
  }
}

const DEVICE_TYPES = [
  new DeviceTypeInfo(DeviceType.Energy, 'deviceType.Energy.name', 'smp_cell_smappee_energy.svg'),
  new DeviceTypeInfo(DeviceType.Solar, 'deviceType.Solar.name', 'smp_cell_smappee_energy.svg'),
  new DeviceTypeInfo(DeviceType.Plus, 'deviceType.Plus.name', 'smp_cell_smappee_pro.svg'),
  new DeviceTypeInfo(DeviceType.Pro, 'deviceType.Pro.name', 'smp_cell_smappee_pro.svg'),
  new DeviceTypeInfo(DeviceType.Switch, 'deviceType.Switch.name', 'smp_cell_smappee_switch.svg'),
  new DeviceTypeInfo(DeviceType.Genius, 'deviceType.Genius.name', 'smp_cell_smappee_genius.svg'),
  new DeviceTypeInfo(
    DeviceType.WifiConnect,
    'deviceType.WifiConnect.name',
    'smp_cell_smappee_wifi_connect.svg',
    undefined,
    false
  ),
  new DeviceTypeInfo(DeviceType.GasWater, 'deviceType.GasWater.name', 'smp_cell_smappee_gaswater.svg', undefined),
  new DeviceTypeInfo(
    DeviceType.PowerBox,
    'deviceType.PowerBox.name',
    'smp_cell_smappee_powerbox.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(DeviceType.CTHub, 'deviceType.CTHub.name', 'smp_cell_smappee_ctHub.svg', undefined, false, false),
  new DeviceTypeInfo(
    DeviceType.CTHubLite,
    'deviceType.CTHub.name',
    'smp_cell_smappee_ctHub.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.ClosedCTHub,
    'deviceType.ClosedCTHub.name',
    'smp_cell_smappee_solid_core_ct.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.Hub16,
    'deviceType.Hub16.name',
    'smp_cell_smappee_ctHub16.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.InputModule,
    'deviceType.InputModule.name',
    'smp_cell_smappee_module_in.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.OutputModule,
    'deviceType.OutputModule.name',
    'smp_cell_smappee_module_out.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(DeviceType.P1S1, 'deviceType.P1S1.name', 'smp_cell_smappee_connect.svg'),
  new DeviceTypeInfo(DeviceType.P1S1Internal, 'deviceType.P1S1InternalModule.name', 'smp_cell_smappee_connect.svg'),
  new DeviceTypeInfo(
    DeviceType.EthernetConnect,
    'deviceType.EthernetConnect.name',
    'smp_cell_smappee_connect.svg',
    undefined,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.FourGConnect,
    'deviceType.FourGConnect.name',
    'smp_cell_smappee_connect.svg',
    undefined,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.ACChargingController,
    'deviceType.ACChargingController.name',
    'smp_cell_car_charger.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.DCChargingController,
    'deviceType.DCChargingController.name',
    'smp_cell_car_charger.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.DCCarChargerControllerV4,
    'deviceType.DCChargingController.name',
    'smp_cell_smappee_powerbox.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.ACChargingControllerWithRFID,
    'deviceType.ACChargingControllerWithRFID.name',
    'smp_cell_smappee_powerbox.svg',
    'deviceType.ACChargingControllerWithRFID.internalName',
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.RelayBoard4P,
    'deviceType.RelayBoard4P.name',
    'smp_cell_smappee_powerbox.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.RelayBoard2x2P,
    'deviceType.RelayBoard2x2P.name',
    'smp_cell_smappee_powerbox.svg',
    undefined,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.RelayBoardUS,
    'deviceType.RelayBoardUS.name',
    'smp_cell_smappee_powerbox.svg',
    undefined,
    false,
    false
  ),

  new DeviceTypeInfo(DeviceType.LED, 'deviceType.LED.name', 'smp_cell_ev_led.svg'),
  new DeviceTypeInfo(DeviceType.RFID, 'deviceType.RFID.name', 'smp_cell_smappee_powerbox.svg'),
  new DeviceTypeInfo(
    DeviceType.MIDSingle,
    'deviceType.MIDSingle.name',
    'smp_cell_smappee_mid.svg',
    undefined,
    false,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.MIDPoly,
    'deviceType.MIDPoly.name',
    'smp_cell_smappee_mid.svg',
    undefined,
    false,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.MIDPolyISKRA,
    'deviceType.MIDPolyISKRA.name',
    'smp_cell_smappee_mid.svg',
    undefined,
    false,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.RCM,
    'deviceType.RCM.name',
    'smp_cell_smappee_powerbox.svg',
    undefined,
    false,
    false,
    false
  ),
  new DeviceTypeInfo(
    DeviceType.DCCarChargeTripleDisplay,
    'deviceType.DCCarChargeTripleDisplay.name',
    'smp_cell_smappee_unknown.svg'
  ),
  new DeviceTypeInfo(
    DeviceType.DCCarChargeRFIDTripleDisplay,
    'deviceType.DCCarChargeRFIDTripleDisplay.name',
    'smp_cell_smappee_unknown.svg'
  ),
  new DeviceTypeInfo(DeviceType.LEMDCMeter, 'deviceType.LEMDCMeter.name', 'smp_cell_smappee_unknown.svg'),
  new DeviceTypeInfo(DeviceType.CarChargerSticker, 'deviceType.CarChargerSticker.name', 'smp_cell_smappee_unknown.svg')
];
const DEVICE_TYPE_UNKNOWN = new DeviceTypeInfo(
  DeviceType.UNKNOWN,
  'deviceType.unknown',
  'smp_cell_smappee_unknown.svg'
);
const DEVICE_TYPES_BY_ID = DEVICE_TYPES.reduce((types: Map<DeviceType, DeviceTypeInfo>, type: DeviceTypeInfo) => {
  types.set(type.type, type);
  return types;
}, new Map<DeviceType, DeviceTypeInfo>());

export function getDeviceTypeInfo(deviceType: DeviceType): DeviceTypeInfo {
  return DEVICE_TYPES_BY_ID.get(deviceType) || DEVICE_TYPE_UNKNOWN;
}

export function getDeviceTypeLabelFor(type: DeviceType): string {
  return T(getDeviceTypeInfo(type).nameKey);
}

export function getInternalTypeLabelFor(type: DeviceType): string {
  return T(getDeviceTypeInfo(type).nameInternalKey);
}

export function getDeviceIconFor(type: DeviceType): string {
  return getDeviceTypeInfo(type).iconPath;
}

export function isHiddenModule(type: DeviceType): boolean {
  return type === DeviceType.P1S1Internal;
}

export function hasPhaseVoltages(type: DeviceType, phaseType: PhaseType): boolean {
  return true;
}

export function hasLineVoltages(type: DeviceType, phaseType: PhaseType): boolean {
  if (phaseType === PhaseType.Single) return false;

  return type !== DeviceType.P1S1 && type !== DeviceType.Pro && type !== DeviceType.Plus;
}

export function isCTHub(type: DeviceType): boolean {
  return (
    type === DeviceType.CTHub ||
    type === DeviceType.ClosedCTHub ||
    type === DeviceType.Hub16 ||
    type === DeviceType.CTHubLite
  );
}

export function getCTHubLocations(type: DeviceType): string[] {
  if (type === DeviceType.CTHub || type === DeviceType.CTHubLite) {
    return ['A', 'B', 'C', 'D'];
  } else if (type === DeviceType.ClosedCTHub) {
    return ['A', 'B', 'C'];
  } else if (type === DeviceType.Hub16) {
    return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
  } else {
    return [];
  }
}

export function getSerialPrefix(type: DeviceType): string | undefined {
  switch (type) {
    case DeviceType.ACChargingController:
      return '5820';
    case DeviceType.DCChargingController:
      return '5880';
    case DeviceType.ACChargingControllerWithRFID:
      return '5821';
    case DeviceType.LED:
      return '5830';
    case DeviceType.RFID:
      return '5840';
    case DeviceType.DCCarChargeTripleDisplay:
      return '5842';
    case DeviceType.DCCarChargeRFIDTripleDisplay:
      return '5843';
    case DeviceType.MIDSingle:
      return '5850';
    case DeviceType.MIDPoly:
      return '5860';
    case DeviceType.RCM:
      return undefined;
    case DeviceType.EthernetConnect:
      return '5130';
    case DeviceType.FourGConnect:
      return '5140';
    case DeviceType.PowerBox:
      return '5400';
    case DeviceType.CTHub:
      return '55';
    case DeviceType.ClosedCTHub:
      return '5600';
    default:
      return '9999';
  }
}

export function isGatewayDevice(type: DeviceType): boolean {
  return (
    type === DeviceType.Genius ||
    type === DeviceType.WifiConnect ||
    type === DeviceType.FourGConnect ||
    type === DeviceType.EthernetConnect
  );
}

export function isReplaceableEVComponent(type: DeviceType): boolean {
  return (
    type === DeviceType.ACChargingController ||
    type === DeviceType.ACChargingControllerWithRFID ||
    type === DeviceType.DCChargingController ||
    type === DeviceType.DCCarChargerControllerV4 ||
    type === DeviceType.LED ||
    type === DeviceType.RFID ||
    type === DeviceType.DCCarChargeTripleDisplay ||
    type === DeviceType.DCCarChargeRFIDTripleDisplay ||
    type === DeviceType.MIDSingle ||
    type === DeviceType.MIDPoly ||
    type === DeviceType.CarChargerSticker ||
    type === DeviceType.ClosedCTHub ||
    type === DeviceType.CTHub ||
    type === DeviceType.PowerBox ||
    type === DeviceType.RelayBoardUS ||
    type === DeviceType.RelayBoard2x2P ||
    type === DeviceType.RelayBoard4P ||
    isGatewayDevice(type)
  );
}

export function expandSerialNumber(serial: string): string | null {
  const starMatch = /^([0-9]+)\*([0-9]+)$/.exec(serial.trim());
  if (starMatch) {
    return starMatch[1].padEnd(10 - starMatch[2].length, '0') + starMatch[2];
  } else {
    return null;
  }
}
