// Find Greatest Common Divisor
export const gcd = (a: number, b: number): number => {
  return b == 0 ? a : gcd(b, a % b);
};

// Check if image matches the desired aspect ratio
export const isValidAspectRatio = (
  aspectWidth: number,
  aspectHeight: number,
  imageWidth: number,
  imageHeight: number
): boolean => {
  const gcdValue = gcd(imageWidth, imageHeight);
  const widthProportion = imageWidth / gcdValue;
  const heightProportion = imageHeight / gcdValue;
  return widthProportion == aspectWidth && heightProportion == aspectHeight;
};

export const b64toByteArrays = (b64Data: string, contentType: string) => {
  contentType = contentType || 'image/jpeg';
  var sliceSize = 512;

  var byteCharacters = window.atob(b64Data.replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, ''));
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return byteArrays;
};

export const base64toFile = (b64Data: string, fileName: string, contentType: string) => {
  const byteArrays = b64toByteArrays(b64Data, contentType);
  const epochTime = new Date().getTime();
  const file = new File(byteArrays, fileName, {type: contentType, lastModified: epochTime});
  return file;
};
