import React from 'react';

import {Button as RsButton} from '../../../components/bootstrap';
import {Bin} from '../../../components/ui-lib/icons/small';
import {IFluviusCampaign, FluviusCampaignStatus} from '../../../models/FluviusMeasurementCampaign';
import {IMeasuringCase, IOrganizationRegion, translateRegionName} from '../../../models/Organization';
import {ITableField, CalculatedStringField, StringField, ComponentField, TimestampField} from '../../../models/Table';

import {T} from '../../../utils/Internationalization';

import {SibelgaTranslationKey, SiT, translateMeasuringType} from './SibelgaModels';

interface Callbacks {
  onClickedView: (item: IFluviusCampaign) => void;
  onClickedViewPDF: (item: IFluviusCampaign) => void;
  onClickedStart: (item: IFluviusCampaign) => void;
  onClickedRemove: (item: IFluviusCampaign) => void;
}

export const rowKey = (item: IFluviusCampaign) => item.id;

export function getTableColumns(
  regions: IOrganizationRegion[],
  measuringCases: IMeasuringCase[],
  callbacks: Callbacks
): ITableField<IFluviusCampaign>[] {
  const {onClickedView, onClickedViewPDF, onClickedStart, onClickedRemove} = callbacks;
  return [
    new TimestampField('creationTimestamp', SiT('column.creationTimestamp')),
    new CalculatedStringField('type', SiT('column.type'), item => translateMeasuringType(item.configuration.type)),
    new CalculatedStringField('status', SiT('column.status'), item =>
      item.status === FluviusCampaignStatus.Active
        ? item.confirmActivation
          ? SiT('status.active')
          : SiT('status.awaiting')
        : SiT('status.inactive')
    ),
    new CalculatedStringField('region', SiT('field.department'), item => {
      const region = regions.find(r => r.id === item.configuration.region);
      if (!region) return undefined;

      return translateRegionName(region);
    }),
    new CalculatedStringField('set', SiT('field.measuringset.case'), item => {
      const measurementCase = measuringCases.find(c => c.id === item.configuration.measuringCase);
      if (!measurementCase) return undefined;
      return measurementCase.name;
    }),
    new StringField('name', SiT('field.name')),
    new CalculatedStringField(
      'vpkOrCabinId',
      SiT('field.cabinId'),
      item => item.configuration.vpkId || item.configuration.cabinId
    ),
    new CalculatedStringField('city', SiT('field.city'), item => item.configuration.city),
    new CalculatedStringField('address', SiT('field.street'), item => item.configuration.address),
    new CalculatedStringField('reason', SiT('field.reason'), item =>
      SiT(item.configuration.reason as SibelgaTranslationKey)
    ),
    new CalculatedStringField('notes', SiT('field.notes'), item => item.configuration.notes),
    new ComponentField(
      'actions',
      T('generic.actions'),
      item => {
        return (
          <>
            <RsButton
              size="sm"
              color="link"
              withoutPadding
              title={SiT('action.view')}
              onClick={() => onClickedView(item)}
              style={{marginRight: 10}}
            >
              <span className="far fa-eye" />
            </RsButton>
            <RsButton
              size="sm"
              color="link"
              withoutPadding
              title={SiT('action.openPDF')}
              onClick={() => onClickedViewPDF(item)}
              style={{marginRight: 10}}
            >
              <span className="fas fa-file-pdf" />
            </RsButton>
            {item.status === FluviusCampaignStatus.Inactive && (
              <RsButton
                size="sm"
                color="link"
                withoutPadding
                title={SiT('action.start')}
                onClick={() => onClickedStart(item)}
                style={{marginRight: 10}}
              >
                <span className="fas fa-play" />
              </RsButton>
            )}
            {item.status === FluviusCampaignStatus.Inactive && (
              <RsButton
                size="sm"
                color="link"
                withoutPadding
                title={SiT('action.remove')}
                onClick={() => onClickedRemove(item)}
              >
                <Bin className="!tw-w-4 !tw-h-4" />
              </RsButton>
            )}
          </>
        );
      },
      {autoInsert: 'end'}
    )
  ];
}
