import {useMemo} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button} from '../../components/bootstrap';
import {ButtonWithLoading} from '../../components/ButtonWithLoading';
import CenteredErrorView from '../../components/CenteredErrorView';
import {Icon, Icons} from '../../components/Icon';
import {SplitPane} from '../../components/SplitPane';
import Table, {IPersistedTableSettings} from '../../components/Table';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {ICardSettings} from '../../models/CardSettings';
import {IChargingSession} from '../../models/ChargingStation';
import {SplitBillingAgreement} from '../../models/SplitBillingAgreement';
import {CalculatedCurrencyField} from '../../models/Table';
import {reportError} from '../../utils/Errors';
import {plural, T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {useUser} from '../CardUtils';

import {getTableColumns} from '../ChargingSessions/Columns';
import {CardActions} from '../components';
import {ExportCsv} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, CardViewBaseProps, cardViewProps, ICardState} from '../components/CardView';

import CloseSplitBillingModal from './CloseSplitBillingModal';
import {getColumns} from './Columns';
import EditSplitBillingAgreementModal from './EditSplitBillingModal';

export interface ISplitBillingBaseCardSettings extends ICardSettings {
  agreementsTable: IPersistedTableSettings;
  sessionsTable: IPersistedTableSettings;
}

interface SplitBillingCardProps<S extends ISplitBillingBaseCardSettings> extends CardViewBaseProps<S> {
  readOnly: boolean;
  updateSettings: (updates: Partial<S>) => void;
  agreements: SplitBillingAgreement[];
  filters?: JSX.Element;
  actions?: JSX.Element;
  onClickedAdd?: () => void;
  onClickedOrder?: () => void;
  onRemoved?: (agreement: SplitBillingAgreement) => void;
  addLoading: boolean;
  isEmployer: boolean;
  refreshAgreements: () => void;
  selectedAgreement?: SplitBillingAgreement;
  setSelectedAgreement: (agreement: SplitBillingAgreement) => void;
  sessions: IChargingSession[];
}

function agreementRowKey(row: SplitBillingAgreement) {
  return row.id;
}
function sessionRowKey(row: IChargingSession) {
  return row.uuid;
}

export function SplitBillingCard<S extends ISplitBillingBaseCardSettings>(props: SplitBillingCardProps<S>) {
  const {
    readOnly,
    agreements,
    filters,
    actions,
    onClickedAdd,
    onClickedOrder,
    onRemoved,
    addLoading,
    isEmployer,
    settings,
    updateSettings,
    refreshAgreements,
    selectedAgreement,
    setSelectedAgreement,
    sessions
  } = props;
  const {api} = useAppContext();
  const modals = useModals();
  const me = useUser();

  const agreementColumns = useMemo(() => {
    const handleClickedEditAgreement = (row: SplitBillingAgreement) => {
      modals
        .show(props => (
          <EditSplitBillingAgreementModal
            organization={row.organization}
            agreement={row}
            existingAgreements={agreements}
            canEditAsEmployee={row.user?.id === me.userId || me.isServiceDesk()}
            canEditAsEmployer={isEmployer || me.isServiceDesk()}
            {...props}
          />
        ))
        .then(() => refreshAgreements());
    };

    const handleClickedRemoveAgreement = async (row: SplitBillingAgreement) => {
      try {
        await api.checkSplitBillingAgreementDeletable(row.id);
      } catch (err) {
        reportError(err, T('splitBilling.delete.failed'));
        return;
      }

      const result = await modals.show(props => (
        <ConfirmationPromiseModal
          title={T('splitBilling.remove.title')}
          message={T('splitBilling.remove.message')}
          {...props}
        />
      ));
      if (result === ConfirmationResult.Accept) {
        try {
          await api.deleteSplitBillingAgreement(row.id);
          onRemoved?.(row);
          refreshAgreements();
        } catch (err) {
          reportError(err, T('splitBilling.delete.failed'));
        }
      }
    };

    const handleClickedCloseAgreement = (agreement: SplitBillingAgreement) => {
      return modals
        .show(props => <CloseSplitBillingModal agreement={agreement} {...props} />)
        .then(result => {
          if (result) {
            onRemoved?.(agreement);
            refreshAgreements();
          }
        });
    };

    const handleClickedResendConfirmation = (agreement: SplitBillingAgreement) => {
      return api
        .resendSplitBillingAgreementConfirmation(agreement.id)
        .then(() => NotificationManager.success(T('splitBilling.actions.resend.success')))
        .catch(() => NotificationManager.error(T('splitBilling.actions.resend.failure')));
    };

    const canManage = !readOnly;
    return getColumns({
      canManage,
      onClickedEdit: handleClickedEditAgreement,
      onClickedRemove: handleClickedRemoveAgreement,
      onClickedClose: handleClickedCloseAgreement,
      onClickedResend: handleClickedResendConfirmation
    });
  }, [readOnly, modals, refreshAgreements, agreements, me, isEmployer, api, onRemoved]);

  const sessionColumns = useMemo(() => {
    const columns = getTableColumns([], false);
    columns.push(
      new CalculatedCurrencyField(
        'reinbursement',
        T('splitBilling.field.reinbursement'),
        item => (item.splitBillingRefund == null ? undefined : item.splitBillingRefund),
        () => 'EUR',
        {unit: selectedAgreement && selectedAgreement.refund.rate.currency}
      )
    );
    return columns;
  }, [selectedAgreement]);

  const cardActions = (state: ICardState) => (
    <CardActions>
      {filters}
      <Spring />
      {selectedAgreement && (
        <ExportCsv
          items={sessions}
          fields={sessionColumns}
          settings={settings.sessionsTable}
          name={plural('chargingSession')}
        />
      )}
      {actions}
      {onClickedAdd !== undefined && (
        <ButtonWithLoading
          onClick={onClickedAdd}
          className={testingClasses.addButton}
          loading={addLoading}
          data-testid={testingClasses.addButton}
        >
          {Icons.Add}
          &nbsp;{T('splitBilling.add')}
        </ButtonWithLoading>
      )}
      {onClickedOrder !== undefined && <Button onClick={onClickedOrder}>{T('publicChargingTokens.orderCards')}</Button>}
    </CardActions>
  );

  return (
    <CardView actions={cardActions} {...cardViewProps(props)}>
      {agreements.length === 0 ? (
        <CenteredErrorView>
          {T('generic.noDataOfType', {
            entity: plural('splitBillingAgreement')
          })}
          {onClickedAdd !== undefined && (
            <>
              <br />
              <br />
              <ButtonWithLoading onClick={onClickedAdd} color="primary" loading={addLoading}>
                <i className={Icon.Plus} style={{fontSize: 16, padding: 0}} />
                &nbsp;{T('splitBilling.add')}
              </ButtonWithLoading>
            </>
          )}
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.smappee.com/hc/en-gb/articles/4411544611732-Setting-up-Split-Billing"
            >
              {T('splitBilling.setup')}
            </a>
          </span>
        </CenteredErrorView>
      ) : (
        <SplitPane>
          <Table
            items={agreements}
            fields={agreementColumns}
            rowKey={agreementRowKey}
            settings={settings.agreementsTable}
            updateSettings={table => updateSettings({agreementsTable: table} as Partial<S>)}
            noun="splitBillingAgreement"
            onClickedRow={setSelectedAgreement}
          />
          <Table
            items={sessions}
            fields={sessionColumns}
            rowKey={sessionRowKey}
            settings={settings.sessionsTable}
            updateSettings={table => updateSettings({sessionsTable: table} as Partial<S>)}
            emptyMessage={selectedAgreement === undefined ? T('splitBilling.clickAgreementToSeeSessions') : undefined}
            noun="chargingSession"
          />
        </SplitPane>
      )}
    </CardView>
  );
}
