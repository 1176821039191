import {PricingPolicyFormState} from './FormState';

export function isTariffsValid(formState: PricingPolicyFormState) {
  return formState.startingFee || formState.costPerKwh || formState.timeComponents;
}

export function formatMinutes(minutes: number): string {
  if (minutes < 60) {
    return `${minutes}m`;
  } else if (minutes % 60 === 0) {
    return `${(minutes / 60) | 0}h`;
  } else {
    const hours = (minutes / 60) | 0;
    const mins = minutes % 60;
    return `${hours}h${mins}m`;
  }
}
