import React, {useState, useCallback, useEffect} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button as RsButton, Input} from '../../components/bootstrap';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import Table, {IPersistedTableSettings} from '../../components/Table';
import RowActions from '../../components/Table/RowActions';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IOrganization} from '../../models/Organization';
import {ITableField, StringField, ComponentField, FieldAlignment, CalculatedStringField} from '../../models/Table';
import {IUser} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {useUser} from '../CardUtils';

import styles from './UserOrganizationsModal.module.scss';

const FIXED_TABLE_SETTINGS: IPersistedTableSettings = {
  pageSize: 10,
  columns: [{name: 'name', visible: true}]
};

const rowKey = (row: IOrganization) => row.id;

interface UserOrganizationsProps extends IPromiseModalProps<void> {
  user: IUser;
}
export const UserOrganizationsModal = (props: UserOrganizationsProps) => {
  const {user} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  const modals = useModals();
  const {api} = useAppContext();

  const [userOrganizations, setUserOrganizations] = useState<IOrganization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>();
  const [selectedRole, setSelectedRole] = useState<string>('HELPDESK');
  const isBasicuser = useUser().isRegularUser();

  const [roles = None] = useLoader(api => api.getRoleTypes(), []);
  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const handleClose = () => resolve();

  const loadUserOrganizations = useCallback(() => {
    api
      .getUserOrganizations(user.id, true)
      .then(organizations =>
        setUserOrganizations(
          organizations.filter(org => !org.parentId || !organizations.map(org => org.id).includes(org.parentId))
        )
      );
  }, [api, user.id]);

  useEffect(loadUserOrganizations, [loadUserOrganizations]);

  const handleUnlinkClicked = async (organization: IOrganization) => {
    const confirmed = await modals.show(props => (
      <ConfirmationPromiseModal
        title={T('users.organizations.confirmUnlink.title')}
        message={T('users.organizations.confirmUnlink.message', {
          user: user.firstName || user.userName || user.lastName || '',
          organization: organization.name
        })}
        acceptLabel={T('users.organizations.confirmUnlink.accept')}
        rejectLabel={T('users.organizations.confirmUnlink.reject')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    if (!user.id || !organization) return;

    await api.organizations.removeOrganizationUser(organization.id, user.id);
    loadUserOrganizations();
    NotificationManager.success(T('users.removedFromOrganization'));
  };

  const handleClickedAdd = async () => {
    if (!selectedOrganization) return;

    await api.organizations.addOrganizationUser(selectedOrganization.id, user.emailAddress!, selectedRole);
    setSelectedOrganization(undefined);
    loadUserOrganizations();
    NotificationManager.success(T('users.addedToOrganization'));
  };

  const handleChangeOrganization = (org: IOrganization | undefined) => {
    setSelectedOrganization(org);
  };

  const tableFields: ITableField<IOrganization>[] = [new StringField('name', T('users.organizations.field.name'))];
  tableFields.push(
    new CalculatedStringField(
      'role',
      T('organizationUsers.field.role'),
      row => roles.find(r => r.id === row.role)?.name || '?'
    )
  );

  tableFields.push(
    new ComponentField(
      'actions',
      T('users.organizations.field.actions'),
      row => (
        <RowActions>
          <RowActionButton
            icon="Unlink"
            title={T('users.organizations.field.removeTooltip')}
            onClick={() => handleUnlinkClicked(row)}
          />
        </RowActions>
      ),
      {alwaysVisible: true, autoInsert: 'end', align: FieldAlignment.Center}
    )
  );

  return (
    <Modal isOpen={isOpen} toggle={handleClose} autoFocus={false} size="lg" style={{maxWidth: 800}}>
      <ModalHeader toggle={handleClose}>
        {T('users.organizations.title', {firstName: user.firstName || '', lastName: ''})}
      </ModalHeader>
      <ModalBody>
        <Table
          hasPaging={false}
          items={userOrganizations}
          fields={tableFields}
          settings={FIXED_TABLE_SETTINGS}
          rowKey={rowKey}
          updateSettings={() => {}}
        />
      </ModalBody>
      <ModalFooter className={styles.modalFooter}>
        <div className={styles.content}>
          {!isBasicuser && inputOrganizations.showInput && (
            <OrganizationInput
              name="organization"
              organizations={inputOrganizations.organizations}
              value={selectedOrganization}
              onChange={handleChangeOrganization}
              onUpdateQuery={updateOrganizationInputQuery}
            />
          )}

          <Input type="select" value={selectedRole} onChange={e => setSelectedRole(e.currentTarget.value)}>
            <option value="HELPDESK">{T('role.partnerAdmin')}</option>
            <option value="OBSERVER">{T('role.partnerReadOnly')}</option>
            <option value="MANAGED">{T('role.basic')}</option>
            {/*roles.map(role => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))*/}
          </Input>
        </div>
        <RsButton color="secondary" onClick={handleClickedAdd}>
          {T('users.organizations.add')}
        </RsButton>
      </ModalFooter>
    </Modal>
  );
};
