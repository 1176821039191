export const enum ChargingDisplayImageType {
  Configuration = 'CONFIGURATION',
  Custom = 'CUSTOM',
  Welcome = 'ULTRA_V2_WELCOME',
  Support = 'ULTRA_V2_SUPPORT',
  NotAvailable = 'ULTRA_V2_NOT_AVAILABLE',
  ConnectCable = 'ULTRA_V2_CONNECT_CABLE',
  SwipeOrScan = 'ULTRA_V2_SWIPE_OR_SCAN',
  Swipe = 'ULTRA_V2_SWIPE',
  Authenticating = 'ULTRA_V2_AUTHENTICATING',
  AuthenticationTimeout = 'ULTRA_V2_AUTHENTICATION_TIMEOUT',
  ChargingEnded = 'ULTRA_V2_CHARGING_ENDED',
  Error = 'ULTRA_V2_ERROR',
  Initializing = 'ULTRA_V2_INITIALIZING',
  ReInsertCable = 'ULTRA_V2_REINSERT_CABLE'
}

export const enum ChargingDisplayImageStatus {
  Default = 'DEFAULT',
  Uploaded = 'UPLOADED',
  Busy = 'BUSY',
  Confirmed = 'CONFIRMED',
  Failed = 'FAILED',
  Invalid = 'INVALID'
}

export interface ChargingDisplayImageUrl {
  value: string;
  validUntil: number; // timestamp in millis since epoch
}

export interface ChargingDisplayImage {
  id: number;
  type: ChargingDisplayImageType;
  url: ChargingDisplayImageUrl;
  isDefault: boolean;
  numberOfAttempts: number;
  lastAttempt: number;
  statusFlags: ChargingDisplayImageStatus[];
}
