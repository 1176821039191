import {useCallback, useEffect, useState} from 'react';

import {FluviusCampaignStatus, IFluviusCampaign, IFluviusCampaignFields} from '../models/FluviusMeasurementCampaign';
import {IMeasuringCase, IOrganizationRegion} from '../models/Organization';
import {None} from '../utils/Arrays';
import {Fetcher} from '../utils/Fetcher';
import {plural} from '../utils/Internationalization';

import {APIClient} from './APIClient';

export class FluviusAPI {
  client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  createMeasurementCampaign(campaign: IFluviusCampaignFields): Promise<IFluviusCampaign | undefined> {
    const url = `/api/v10/fluvius/campaigns`;
    return this.client.doPost(url, campaign);
  }

  getMeasurementCampaigns(status: FluviusCampaignStatus[] | undefined): Promise<IFluviusCampaign[]> {
    let url = `/api/v10/fluvius/campaigns`;
    if (status) url += `?status=${status}`;
    return this.client.doGet(url);
  }

  getMeasurementCampaign(id: number): Promise<IFluviusCampaign> {
    const url = `/api/v10/fluvius/campaigns/${id}`;
    return this.client.doGet(url);
  }

  deleteMeasurementCampaign(id: number): Promise<void> {
    const url = `/api/v10/fluvius/campaigns/${id}`;
    return this.client.doDelete(url);
  }

  activateMeasurementCampaign(id: number): Promise<IFluviusCampaign | undefined> {
    const url = `/api/v10/fluvius/campaigns/${id}/activate`;
    return this.client.doPost(url, {});
  }

  getRegions(): Promise<IOrganizationRegion[]> {
    const url = `/api/v10/fluvius/regions`;
    return this.client.doGet(url);
  }

  getMeasuringCases(): Promise<IMeasuringCase[]> {
    const url = `/api/v10/fluvius/measuringcases`;
    return this.client.doGet(url);
  }
}

export function useFluviusCampaigns(fetch: Fetcher, status: FluviusCampaignStatus): [IFluviusCampaign[], () => void] {
  const [campaigns, setCampaigns] = useState<IFluviusCampaign[]>(None);
  const refresh = useCallback(() => {
    const requestStatus =
      status === FluviusCampaignStatus.All ? [FluviusCampaignStatus.Active, FluviusCampaignStatus.Inactive] : [status];
    fetch(
      'campaigns',
      api => api.fluvius.getMeasurementCampaigns(requestStatus),
      plural('fluviusMeasurementCampaign')
    ).then(campaigns => setCampaigns(campaigns || []));
  }, [fetch, status]);
  useEffect(refresh, [refresh]);
  return [campaigns, refresh];
}
