import {singular} from '../utils/Internationalization';

export interface IEventList {
  events: IEvent[];
  cursor?: string;
}

export const enum EventType {
  On = 'ON',
  Off = 'OFF'
}

export const enum EventStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export const enum EventSourceType {
  Appliance = 'APPLIANCE',
  Switch = 'SWITCH',
  Plug = 'PLUG',
  OutputModule = 'OUTPUT_MODULE'
}

export function getSourceTypeName(type: EventSourceType) {
  switch (type) {
    case EventSourceType.Appliance:
      return singular('appliance');
    case EventSourceType.Switch:
      return 'Smappee Switch';
    case EventSourceType.Plug:
      return 'Comfort Plug';
    case EventSourceType.OutputModule:
      return 'Output Module';
    default:
      return type;
  }
}

export interface IEvent {
  id: string;
  timestamp: number;
  type: EventType;
  status?: EventStatus;
  source: IEventSource;
  duration?: number; // duration of ON time, in milliseconds
  typeLabel?: string;
}

export interface IEventSource {
  type: EventSourceType;
  id: string;
  loadId?: number;
  name: string;
  categoryDisplayName: string;
  category?: string;
}
