import React, {useMemo, useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Input} from '../../components/bootstrap';
import Table from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Checkbox} from '../../components/ui/checkbox';
import Plus from '../../components/ui-lib/icons/small/Plus';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {CustomApp} from '../../models/CustomApp';
import {None} from '../../utils/Arrays';
import {reportError} from '../../utils/Errors';
import {useCardLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {CardActions, ColumnChooser} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomSettings, ICardState} from '../components/CardView';

import {getColumns} from './Columns';
import CreateAPIKeyModal from './CreateAPIKeyModal';
import styles from './index.module.scss';
import SecretModal from './SecretModal';

interface AppManagementSettings extends ICardSettingsWithTable {
  includeObsolete: boolean;
}

function MyCard(props: ICardProps<AppManagementSettings>) {
  const {settings, updateSettings} = props;
  const {api} = useAppContext();
  const modals = useModals();

  const [apps, refresh] = useCardLoader(
    api => api.apps.getApps(settings.includeObsolete),
    [settings.includeObsolete],
    'apps',
    None
  );
  const [filter, setFilter] = useState('');
  const filteredApps = useMemo(() => {
    if (filter === '') return apps;

    const filterLower = filter.toLocaleLowerCase();
    return apps.filter(
      app =>
        app.appName.toLocaleLowerCase().includes(filterLower) || app.clientId.toLocaleLowerCase().includes(filterLower)
    );
  }, [apps, filter]);

  const columns = useMemo(() => {
    const handleViewSecret = (row: CustomApp) => {
      modals.show(props => <SecretModal app={row} {...props} />);
    };

    const handleDelete = (row: CustomApp) => {
      modals
        .show(props => (
          <ConfirmationPromiseModal
            title={T('appManagement.modal.label.removeAPIKey')}
            message={T('appManagement.modal.question', {appName: row.appName})}
            {...props}
          />
        ))
        .then(result => {
          if (result === ConfirmationResult.Accept) {
            api.apps
              .deleteApp(row.id)
              .then(() => {
                NotificationManager.success('API key removed');
                refresh();
              })
              .catch(reportError);
          }
        });
    };

    const handleRestore = (row: CustomApp) => {
      api.apps
        .restoreApp(row.id)
        .then(() => {
          NotificationManager.success(T('appManagement.keyRestored', {appName: row.appName}));
          refresh();
        })
        .catch(reportError);
    };

    return getColumns({
      onViewSecret: handleViewSecret,
      onDelete: handleDelete,
      onRestore: handleRestore
    });
  }, [api, modals, refresh]);

  const handleClickedCreate = () => {
    modals.show(props => <CreateAPIKeyModal {...props} />).then(changed => changed && refresh());
  };

  const actions = (state: ICardState) => {
    return (
      <CardActions>
        <Reload onReload={refresh} />
        <Input
          name="query"
          type="text"
          autoWidth={true}
          placeholder="Search apps"
          onChange={e => setFilter(e.currentTarget.value)}
          value={filter}
        />
        <Checkbox
          id="includeRemoved"
          name="includeRemoved"
          label={T('appManagement.label.includeRemoved')}
          checked={settings.includeObsolete}
          onCheckedChange={checked => updateSettings({includeObsolete: checked})}
          wrapperClassName="tw-m-0"
          testId="includeRemoved"
        />
        {/* <Spring /> */}
        <Button variant="secondary_default" onClick={handleClickedCreate} size="lg">
          <span className="tw-mr-2">
            <Plus width={16} height={16} />
          </span>
          {T('appManagement.label.addAPIKey')}
        </Button>
      </CardActions>
    );
  };

  const customSettings: CustomSettings<AppManagementSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser settings={settings.table} updateSettings={table => updateSettings({table})} fields={columns} />
    );
  };

  return (
    <CardView customSettings={customSettings} actions={actions} {...cardViewProps(props)}>
      <Table
        fields={columns}
        items={filteredApps}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        rowClass={row => (row.obsolete ? styles.obsolete : '')}
      />
    </CardView>
  );
}

const DEFAULT_SETTINGS: AppManagementSettings = {
  includeObsolete: false,
  table: {
    pageSize: 10,
    columns: [
      {name: 'appName', visible: true},
      {name: 'appDesc', visible: false},
      {name: 'callbackUrl', visible: false},
      {name: 'clientId', visible: true}
    ]
  }
};

const CARD: ICardType<AppManagementSettings> = {
  type: CardTypeKey.AppManagement,
  title: 'appManagement.title',
  description: 'appManagement.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: MyCard
};
export default CARD;
