import React, {useMemo} from 'react';

import PeriodSelector, {
  Period,
  PeriodRoundingMode,
  PeriodSettings,
  usePeriodRangeForTimezone
} from '../../components/PeriodSelector';
import Table, {migrateTableSettings, SortOrder} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {Interval} from '../../models/UsageValue';
import {hasPartnerFunctionality} from '../../models/User';
import {useChargingStationDiagnostics, useChargingStationForLocation} from '../../utils/FunctionalData';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardColumnSettings, useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {getColumns} from './Columns';

interface ChargingStationDiagnosticsSettings extends ICardSettingsWithTable {
  period: PeriodSettings;
}

function MyCardView(props: ICardProps<ChargingStationDiagnosticsSettings>) {
  const {fetch, settings, updateSettings} = props;

  const location = useCardLocation(settings);
  const locationTimezone = location && location.timeZoneId;
  const [chargingStation] = useChargingStationForLocation(fetch, location);

  const activePeriod = usePeriodRangeForTimezone(settings.period, locationTimezone, PeriodRoundingMode.INCLUSIVE);
  const [diagnostics, refresh] = useChargingStationDiagnostics(
    fetch,
    chargingStation && chargingStation.data.serialNumber,
    activePeriod
  );
  const columns = useMemo(() => getColumns(chargingStation), [chargingStation]);

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      <PeriodSelector
        settings={settings.period}
        onChanged={period => updateSettings({period})}
        withoutInterval={true}
      />
    </CardActions>
  );

  const customSettings = useCardColumnSettings(columns);

  return (
    <CardView actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      <Table
        fields={columns}
        items={diagnostics}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
}

const DefaultSettings: ChargingStationDiagnosticsSettings = {
  period: {
    interval: Interval.DAY,
    period: Period.DAYS_14
  },
  table: {
    pageSize: 10,
    sortColumn: 'registrationTimestamp',
    sortOrder: SortOrder.DESCENDING,
    columns: [
      {name: 'registrationTimestamp', visible: true},
      {name: 'code', visible: true},
      {name: 'controller', visible: true},
      {name: 'description', visible: true}
    ]
  }
};

const CARD: ICardType<ChargingStationDiagnosticsSettings> = {
  title: 'chargingStationDiagnostics.title',
  description: 'chargingStationDiagnostics.description',
  type: CardTypeKey.ChargingStationDiagnostics,
  locationAware: CardLocationAwareness.RequiresChargingStation,
  categories: [CardCategory.EV],
  rights: UserRights.User,
  isAvailable: hasPartnerFunctionality,
  width: 4,
  height: 2,
  cardClass: MyCardView,
  upgradeSettings: migrateTableSettings('table', DefaultSettings.table),
  defaultSettings: DefaultSettings
};
export default CARD;
