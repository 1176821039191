import type {SVGProps} from 'react';
const SvgChevronBackwardDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M7.87782 2.22221C8.30739 2.65178 8.30739 3.34826 7.87782 3.77784L3.65563 8.00004L7.87782 12.2222C8.30739 12.6518 8.30739 13.3483 7.87782 13.7778C7.44824 14.2074 6.75176 14.2074 6.32218 13.7778L1.32218 8.77786C1.11589 8.57157 1 8.29178 1 8.00004C1 7.7083 1.11589 7.42851 1.32218 7.22222L6.32218 2.22221C6.75176 1.79263 7.44824 1.79263 7.87782 2.22221Z" />
    <path d="M14.8778 2.22221C15.3074 2.65178 15.3074 3.34826 14.8778 3.77784L10.6556 8.00004L14.8778 12.2222C15.3074 12.6518 15.3074 13.3483 14.8778 13.7778C14.4482 14.2074 13.7518 14.2074 13.3222 13.7778L8.32218 8.77786C8.11589 8.57157 8 8.29178 8 8.00004C8 7.7083 8.11589 7.42851 8.32218 7.22222L13.3222 2.22221C13.7518 1.79263 14.4482 1.79263 14.8778 2.22221Z" />
  </svg>
);
export default SvgChevronBackwardDouble;
