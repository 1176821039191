import {CSSProperties} from 'react';

interface InfoProps {
  children: React.ReactNode;
}

const STYLE: CSSProperties = {color: 'gray'};

export function Info(props: InfoProps) {
  return <p style={STYLE}>{props.children}</p>;
}
