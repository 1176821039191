import React from 'react';

import {T} from '../../utils/Internationalization';

export default function CardDraggableHandle() {
  return (
    <div className="card-draggable-handle">
      <span className="fal fa-arrows card-draggable-handle-inner" title={T('card.dragToPosition')} />
    </div>
  );
}
