import React from 'react';
import {Input as RsInput, InputProps as RsInputProps} from 'reactstrap';

import {classes} from '../../utils/Styles';

import styles from './Input.module.scss';

interface InputProps extends RsInputProps {
  autoWidth?: boolean;
}

export const Input = React.memo((props: InputProps) => {
  const {bsSize, valid = true, invalid, plaintext, className, autoWidth = false, children, ...otherProps} = props;

  const fullClassName = classes(
    styles.input,
    bsSize ? styles[bsSize] : undefined,
    valid ? styles.value : undefined,
    invalid ? styles.invalid : undefined,
    plaintext ? styles.plaintext : undefined,
    autoWidth ? styles.autoWidth : undefined,
    className
  );

  return (
    <RsInput
      bsSize={bsSize}
      plaintext={plaintext}
      invalid={invalid}
      valid={valid ? undefined : false}
      className={fullClassName}
      {...otherProps}
    >
      {children}
    </RsInput>
  );
});
