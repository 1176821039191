import {T} from '../utils/Internationalization';

import {IConfigurationPropertySpec, IConfigurationProperty} from './SmartDevice';

export const enum AutomationType {
  Sun = 'SUN',
  Activity = 'ACTIVITY',
  Schedule = 'SCHEDULE',
  Location = 'LOCATION',
  Import = 'IMPORT',
  ImportExport = 'IMPORT_EXPORT',
  Power = 'POWER',
  PowerIncludingSolar = 'POWER_INCLUSIVE_SOLAR',
  ApplianceActivity = 'APPLIANCE_ACTIVITY',
  InputModuleInput = 'INPUT_MODULE_INPUT',
  ExcessAvailable = 'EXCESS_AVAILABLE'
}

export function translateAutomationType(type: AutomationType): string {
  switch (type) {
    case AutomationType.Sun:
      return T('automationType.sun');
    case AutomationType.Activity:
      return T('automationType.activity');
    case AutomationType.Schedule:
      return T('automationType.schedule');
    case AutomationType.Location:
      return T('automationType.location');
    case AutomationType.Import:
      return T('automationType.import');
    case AutomationType.ImportExport:
      return T('automationType.importExport');
    case AutomationType.Power:
      return T('automationType.power');
    case AutomationType.PowerIncludingSolar:
      return T('automationType.powerIncludingSolar');
    case AutomationType.ApplianceActivity:
      return T('automationType.applianceActivity');
    //case AutomationType.InputModuleInput: return T('automationType.inputModuleInput');
    default:
      return type;
  }
}

export const enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Holiday = 'HOLIDAY'
}

export interface IBaseAutomation {
  id: number;
  name: string;
  type: AutomationType;
  scene?: {
    id: number;
    name?: string;
    actions: AutomationAction[];
  };
  lastExecutionUTCStamp?: number;
  nextExecutionUTCStamp?: number;
}

export interface ISunAutomation extends IBaseAutomation {
  type: AutomationType.Sun;
  sun: {
    when: 'SUNRISE' | 'SUNSET';
    offsetMinutes: number;
  };
}

export interface IActivityAutomation extends IBaseAutomation {
  type: AutomationType.Activity;
  activity?: {
    type: 'PRESENCE' | 'ABSENCE';
  };
}

export interface IScheduleAutomation extends IBaseAutomation {
  type: AutomationType.Schedule;
  schedule: {
    days: DayOfWeek[];
    hours: number;
    minutes: number;
  };
}

export interface ILocationAutomation extends IBaseAutomation {
  type: AutomationType.Location;
  location: {
    direction: 'ARRIVING' | 'LEAVING';
    name: string;
    longitude: number;
    latitude: number;
    radius: number; // in meters
  };
}

export interface IImportAutomation extends IBaseAutomation {
  type: AutomationType.InputModuleInput;
}

export interface IImportExportAutomation extends IBaseAutomation {
  type: AutomationType.ImportExport;
  grid: {
    direction: 'IMPORT' | 'EXPORT';
    threshold: {
      type: 'ABOVE' | 'BELOW';
      numberOfWatts: number;
    };
  };
}

export interface IPowerAutomation extends IBaseAutomation {
  type: AutomationType.Power;
  power: {
    type: 'PRODUCTION' | 'CONSUMPTION' | 'LOAD';
    threshold: {
      type: 'ABOVE' | 'BELOW';
      numberOfWatts: number;
    };
  };
}

export interface IPowerIncludingSolarAutomation extends IBaseAutomation {
  type: AutomationType.PowerIncludingSolar;
}

export interface IApplianceActivityAutomation extends IBaseAutomation {
  type: AutomationType.ApplianceActivity;
  appliance: {
    id: number;
    name: string;
    type: string;
    eventType: 'ON' | 'OFF' | 'ANY';
  };
}

export interface IInputModuleInputAutomation extends IBaseAutomation {
  type: AutomationType.InputModuleInput;
  inputModule: {
    id: number;
    name: string;
    sensor: {
      id: number;
      serialNumber: string;
    };
    state: {
      value: string;
      displayValue: string;
    };
  };
}

export interface IExcessAvailableAutomation extends IBaseAutomation {
  type: AutomationType.ExcessAvailable;
  excessAvailable: {
    numberOfWatts: number;
    durationMinutes: number;
    days: DayOfWeek[];
    start: {
      hours: number;
      minutes: number;
    };
    end: {
      hours: number;
      minutes: number;
    };
  };
}

export type Automation =
  | ISunAutomation
  | IActivityAutomation
  | IScheduleAutomation
  | ILocationAutomation
  | IImportAutomation
  | IImportExportAutomation
  | IPowerAutomation
  | IPowerIncludingSolarAutomation
  | IApplianceActivityAutomation
  | IInputModuleInputAutomation
  | IExcessAvailableAutomation;

export enum AutomationActionType {
  Switch = 'SWITCH',
  State = 'STATE',
  Smart = 'SMART'
}

export type AutomationPossibleAction = IAutomationSwitchAction | IAutomationStateAction | IAutomationSmartAction;

interface IAutomationBaseAction {
  id: number;
  type: AutomationActionType;
  description?: string;
  target: {
    id: string;
    type: string;
    category?: string;
  };
}

export interface IAutomationSwitchAction extends IAutomationBaseAction {
  type: AutomationActionType.Switch;
  switchable: {
    value: string;
    displayValue: string;
  };
}

export interface IAutomationStateAction extends IAutomationBaseAction {
  type: AutomationActionType.State;
  stateful: {
    state: string;
    displayValue: string;
  };
}

export interface IAutomationSmartAction extends IAutomationBaseAction {
  type: AutomationActionType.Smart;
  smart: {
    name: string;
    displayName: string;
    properties?: IConfigurationProperty[];
    configurationProperties?: IConfigurationPropertySpec[];
  };
}

export type AutomationAction = IAutomationSwitchAction | IAutomationStateAction | IAutomationSmartAction;

export interface IAutomationTarget {
  type: 'SWITCH' | 'PLUG' | 'SMART_DEVICE' | 'OUTPUT_MODULE';
  id: string;
  name: string;
  possibleActions: AutomationPossibleAction[];
  category?: 'CHARGINGSTATION' | 'NOTIFICATION' | 'LED';
}

export function getActionSignature(action: AutomationPossibleAction) {
  switch (action.type) {
    case AutomationActionType.Switch:
      return `SW${action.switchable.value}`;
    case AutomationActionType.State:
      return `ST${action.stateful.state}`;
    case AutomationActionType.Smart:
      return `SM${action.smart.name}`;
    default:
      return 'XX';
  }
}

export function listUniqueActions(targets: IAutomationTarget[]): AutomationPossibleAction[] {
  const done = new Set<string>();
  const actions: AutomationPossibleAction[] = [];
  for (let target of targets) {
    for (let action of target.possibleActions) {
      const signature = getActionSignature(action);
      if (!done.has(signature)) {
        done.add(signature);
        actions.push(action);
      }
    }
  }
  return actions;
}

export function getTargetsWithAction(targets: IAutomationTarget[], action: AutomationPossibleAction) {
  const signature = getActionSignature(action);
  return targets.filter(target => target.possibleActions.some(action => signature === getActionSignature(action)));
}
