export interface IAppParameters {
  apiUrl: string;
  development: boolean;
  title: string;
}

export function getAppParameters(): IAppParameters {
  const {location} = window;
  const development = location.host.startsWith('local');
  return {
    apiUrl: `${location.protocol}//${location.host}`,
    development,
    title: 'Smappee Dashboard'
  };
}
