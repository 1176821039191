import React from 'react';

import {Button as RsButton} from '../../components/bootstrap';
import WarningView from '../../components/WarningView';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';

interface CompleteConfigViewProps {
  readOnly: boolean;
  disabled: boolean;
  onClickedComplete: () => void;
}

export default (props: CompleteConfigViewProps) => {
  const {readOnly, disabled, onClickedComplete} = props;

  const action = readOnly ? undefined : (
    <RsButton
      onClick={onClickedComplete}
      disabled={disabled}
      style={{marginRight: 10}}
      className={testingClasses.commitButton}
      data-testid={testingClasses.commitButton}
    >
      {T('liveElectricityValues.configuration.commit')}
    </RsButton>
  );

  return (
    <WarningView
      message={T('ctConfiguration.underConstruction.title')}
      info={T('ctConfiguration.underConstruction.message')}
      action={action}
    />
  );
};
