export function sumOfSeries(seriesData: [number, number][][]): [number, number][] {
  if (seriesData.length === 0) return [];

  const indices = seriesData.map(() => 0);
  const fromTimestamp = seriesData.reduce((min, serie) => Math.min(min, serie[0][0]), seriesData[0][0][0]);
  const toTimestamp = seriesData.reduce(
    (max, serie) => Math.max(max, serie[serie.length - 1][0]),
    seriesData[0][seriesData[0].length - 1][0]
  );

  let timestamp = fromTimestamp;
  const result: [number, number][] = [];

  while (timestamp <= toTimestamp) {
    let nextTimestamp = toTimestamp + 1;
    let value = 0;
    for (var i = 0; i < seriesData.length; i++) {
      let index = indices[i];
      if (index >= seriesData[i].length) continue;

      const entry = seriesData[i][index];
      if (entry[0] <= timestamp) {
        value += entry[1];
        index++;
        indices[i] = index;
        if (index >= seriesData[i].length) continue;
      }

      nextTimestamp = Math.min(nextTimestamp, seriesData[i][index][0]);
    }

    result.push([timestamp, value]);
    if (nextTimestamp === timestamp) break; // safeguard against logical errors causing infinite loop

    timestamp = nextTimestamp;
  }

  return result;
}
