import React, {useState, useEffect, useMemo} from 'react';

import {Input, Label, FormGroup} from '../../components/bootstrap';
import Table, {migrateTableSettings} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ILocationUser, ILocationSummary} from '../../models/Location';
import {IMessage} from '../../models/Message';
import {StringField, ITableField, TimestampFieldWithTimezone, CalculatedStringField} from '../../models/Table';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useUser, useCardLocation} from '../CardUtils';
import {Reload} from '../components/actions';
import {CardActions, Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import styles from './index.module.scss';

type IMessagesSettings = ICardSettingsWithTable;

function getTableColumns(location?: ILocationSummary): ITableField<IMessage>[] {
  return [
    new TimestampFieldWithTimezone(
      'timestamp',
      'timestamp',
      T('messages.field.timestamp'),
      location ? location.timeZoneId : 'UTC',
      {cellClassName: styles.dateCell, width: 100}
    ),
    new StringField('value', T('messages.field.value')),
    new CalculatedStringField(
      'location',
      T('messages.field.location'),
      item => item.context && item.context.serviceLocationName,
      {alwaysVisible: true, autoInsert: 'end'}
    )
  ];
}

const rowKey = (row: IMessage) => row.id;
const NoMessages: IMessage[] = [];

const Messages = (props: ICardProps<IMessagesSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const [users, setUsers] = useState<ILocationUser[]>([]);
  const [user, setUser] = useState<ILocationUser>();

  const me = useUser();
  const canSwitchUsers = me.isServiceDesk();
  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const userId = user && user.id;

  useEffect(() => {
    if (!canSwitchUsers || !locationId) return;

    fetch('users', api => api.locations.getUsers(locationId), plural('user')).then(users => {
      const user = users && users.length > 0 ? users[0] : undefined;
      setUsers(users);
      setUser(user);
    });
  }, [fetch, locationId, canSwitchUsers]);

  const [messages, refreshMessages] = useCardLoader(
    api => api.getMessages(userId),
    [userId],
    plural('message'),
    NoMessages
  );

  const handleChangeUser = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const selectedId = parseInt(event.currentTarget.value);
    setUser(users.find(user => user.id === selectedId));
  };

  const hasUserSelector = canSwitchUsers && users.length > 0;
  const options = useMemo(
    () =>
      users.map(user => (
        <option key={user.id} value={user.id}>
          {user.firstName || T('messages.noFirstName')} ({user.emailAddress})
        </option>
      )),
    [users]
  );

  const columns = useMemo(() => getTableColumns(location), [location]);

  const actions: CustomActions = () => (
    <CardActions>
      <Reload onReload={refreshMessages} />
      {hasUserSelector && <Spring />}
      {hasUserSelector && (
        <FormGroup row>
          <Label>{T('messages.user')}</Label>
          <Input type="select" name="user" value={userId} onChange={handleChangeUser} autoWidth>
            {options}
          </Input>
        </FormGroup>
      )}
    </CardActions>
  );

  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      <Table
        fields={columns}
        items={messages}
        rowKey={rowKey}
        hasPaging={true}
        noun="message"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IMessagesSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'value', visible: true}
    ]
  }
};
const CARD: ICardType<IMessagesSettings> = {
  type: CardTypeKey.Messages,
  title: 'messages.title',
  description: 'messages.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Aware,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: Messages
};
export default CARD;
