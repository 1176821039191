import React, {useRef, useState} from 'react';

import {InstallationFailureReason} from '../../api/InstallationAPI';
import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {DeviceType} from '../../models/DeviceType';
import {translateError} from '../../utils/Errors';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {validateChargingStationModuleSerial} from '../CreateChargingStations/ModuleInput';

import styles from './ReplaceComponentModal.module.scss';

interface ReplaceGatewayModalProps extends IPromiseModalProps<boolean> {
  locationId: number;
}

export default function ReplaceGatewayModal(props: ReplaceGatewayModalProps) {
  const {locationId} = props;

  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(false);

  const form = useFormState();
  const handleSaveRef = useRef<() => void>(() => {});
  const [error, setError] = useState<string>();
  const {api} = useAppContext();

  const [serialNumber, setSerialNumber] = useState('');

  const handleSave = () => {
    form.clearServerErrors();
    setError(undefined);
    if (form.hasErrors()) return Promise.resolve(undefined);

    return api.installation
      .performInstallation({
        serviceLocationId: locationId,
        serialNumber
      })
      .then(response => {
        if (response.success) {
          resolve(true);
        } else if (response.failureReason === InstallationFailureReason.NoSetupRecord) {
          setError(T('chargingStationComponents.replaceGateway.noSetupRecord'));
        } else {
          setError(T('chargingStationComponents.replaceGateway.failure'));
        }
        return undefined;
      })
      .catch(error => translateError(error));
  };
  handleSaveRef.current = handleSave;
  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      title={T('chargingStationComponents.replaceGateway.title')}
      error={error}
      action={handleSave}
      actionText={T('moduleReplacement.replace')}
    >
      <div className={styles.content}>
        <p>{T('chargingStationComponents.replaceGateway.message')}</p>
        <TextInputGroup
          name="serialNumber"
          // label={T('moduleReplacement.serialNumber')}
          value={serialNumber}
          onChange={setSerialNumber}
          validate={value => validateChargingStationModuleSerial(DeviceType.FourGConnect, value)}
          onEnter={() => handleSaveRef.current()}
          error={form.getServerError('serialNumber')}
          className={styles.colRight}
        />
      </div>
    </SingleActionModal>
  );
}
