import React from 'react';

import {UserRights, AuthUser} from '../models/AuthUser';
import {ICardSettings} from '../models/CardSettings';
import {ILocation} from '../models/Location';
import {IOrganization} from '../models/Organization';
import {FetcherProps} from '../utils/Fetcher';
import {T} from '../utils/Internationalization';
import {TranslationKey} from '../utils/TranslationTerms';

export const enum CardCategory {
  USERS = 'users',
  CONFIGURATION = 'configuration',
  HOMECONTROL = 'homecontrol',
  LOCATIONS = 'locations',
  ORGANIZATIONS = 'organizations',
  ELECTRICITY = 'electricity',
  GAS = 'gas',
  WATER = 'water',
  EV = 'ev',
  SERVICEDESK = 'servicedesk',
  ALL = 'all',
  BESS = 'bess'
}

const CATEGORY_DISPLAY_NAMES = new Map<CardCategory, TranslationKey>([
  [CardCategory.ALL, 'cardCategory.all'],
  [CardCategory.CONFIGURATION, 'cardCategory.configuration'],
  [CardCategory.ELECTRICITY, 'cardCategory.electricity'],
  [CardCategory.GAS, 'cardCategory.gas'],
  [CardCategory.HOMECONTROL, 'cardCategory.homeControl'],
  [CardCategory.LOCATIONS, 'cardCategory.locations'],
  [CardCategory.ORGANIZATIONS, 'cardCategory.organizations'],
  [CardCategory.USERS, 'cardCategory.users'],
  [CardCategory.WATER, 'cardCategory.water'],
  [CardCategory.EV, 'cardCategory.ev'],
  [CardCategory.SERVICEDESK, 'cardCategory.serviceDesk']
]);

export function getCardCategoryDisplayName(category: CardCategory): string {
  return T(CATEGORY_DISPLAY_NAMES.get(category) || 'cardCategory.unknown');
}

// Do NOT alter these values as they are serialized
export const enum CardTypeKey {
  ActivationCodeLocations = 'ActivationCodeLocations',
  ActivationCodes = 'ActivationCodes',
  Advenir = 'Advenir',
  Appliances = 'Appliances',
  AppliancesMini = 'AppliancesMini',
  AppManagement = 'AppManagement',
  ChargingConnectorQuickView = 'ChargingConnectorMini',
  ChargingRFIDWhitelist = 'ChargingRFIDWhitelist',
  ChargingSessions = 'ChargingSessions',
  ChargingSolarOptimization = 'ChargingSolarOptimization',
  ChargingStations = 'ChargingStations',
  ChargingStationAutomations = 'ChargingStationAutomations',
  ChargingStationComponents = 'ChargingStationComponents',
  ChargingStationConfiguration = 'ChargingStationConfiguration',
  ChargingStationConsumption = 'ChargingStationConsumption',
  ChargingStationDiagnostics = 'ChargingStationDiagnostics',
  ChargingStationOverview = 'ChargingStationOverview',
  ChargingStationPriorities = 'ChargingStationPriorities',
  ChargingStationTemperature = 'ChargingStationTemperature',
  ChargingTransactions = 'ChargingTransactions',
  Contracts = 'Contracts',
  CPOChargingSessions = 'CPOChargingSessions',
  CPOChargingSquares = 'CPOChargingSquares',
  CreateAPIKey = 'CreateAPIKey',
  CreateChargingStation = 'CreateChargingStation',
  CTConfiguration = 'CTConfiguration',
  DailyMeterReadings = 'DailyMeterReadings',
  DeviceActivationHistory = 'DeviceActivationHistory',
  DeviceConfiguration = 'DeviceConfigurationNew',
  DeviceConfigurationLegacy = 'DeviceConfiguration',
  DeviceConfigurationHistory = 'DeviceConfigurationHistory',
  DeviceIncidents = 'DeviceIncidents',
  DeviceOverview = 'Device overview',
  DeviceManagement = 'DeviceManagement',
  ElectricityValues = 'ElectricityValues',
  Events = 'Events',
  ExportChargingSessions = 'ExportChargingSessions',
  Firmware = 'Firmware',
  FluviusMeasurementCampaigns = 'fluviusMeasurementCampaign',
  FutechImporter = 'FutechImporter',
  GasValues = 'GasValues',
  GasWaterReadings = 'GasWaterReadings',
  Harmonics = 'Harmonics',
  InputModuleValues = 'InputModuleValues',
  Invoices = 'Invoices',
  LiveElectricityValues = 'LiveElectricityValues',
  LiveHarmonics = 'LiveHarmonics',
  LiveValues = 'LiveValues',
  LiveValuesMini = 'LiveValuesMini',
  LocationConfiguration = 'LocationConfiguration',
  Locations = 'Locations',
  LocationMapMini = 'LocationMapMini',
  ManageRegionUsers = 'ManageRegionUsers',
  Messages = 'Messages',
  Organizations = 'Organizations',
  OrganizationUsers = 'OrganizationUsers',
  OverloadProtection = 'OverloadProtection',
  PartnerInfo = 'PartnerInfo',
  PartnerInfoMini = 'PartnerInfoMini',
  PhasorDisplay = 'PhasorDisplay',
  Preconfiguration = 'Preconfiguration',
  PublicChargingTokens = 'PublicChargingTokens',
  RemoteConnect = 'RemoteConnect',
  RetentionPolicy = 'RetentionPolicy',
  PricingPolicies = 'PricingPolicies',
  PricingGroups = 'PricingGroups',
  SendPushMessage = 'SendPushMessage',
  Services = 'Services',
  SharedUsers = 'SharedUsers',
  Shipment = 'Shipment',
  SibelgaMeasurementCampaigns = 'SibelgaMeasurementCampaign',
  SmappeeBess = 'SmappeeBess',
  SmartDevices = 'SmartDevices',
  SpecialFunctions = 'SpecialFunctions',
  SplitBilling = 'SplitBilling',
  SplitBillingOnStation = 'SplitBillingOnStation',
  SuggestionsMini = 'SuggestionsMini',
  SurveyResults = 'SurveyResults',
  SwitchValues = 'SwitchValues',
  ThirdPartyChargingStation = 'ThirdPartyChargingStation',
  UserLocations = 'UserLocations',
  Users = 'Users',
  UserChargingSessions = 'UserChargingSessions',
  WaterValues = 'WaterValues',

  // Not really in database
  NotFound = 'NotFound'
}

export interface ICardProps<S extends ICardSettings> extends FetcherProps {
  settings: S;
  updateSettings: (updates: Partial<S>) => void;
}

export interface SettingsPanelProps<S> {
  editingSettings: S;
  updateSettings: (updates: Partial<S>) => void;
  locationId: number | undefined;
}

export enum CardLocationAwareness {
  Required = 'REQUIRED',
  RequiresRegular = 'REGULAR',
  RequiresRegularOrServiceDesk = 'REGULAR_OR_SD',
  RequiresChargingStation = 'CHARGING_STATION',
  RequiresBess = 'BESS_UNIT',
  Aware = 'AWARE',
  Unaware = 'UNAWARE'
}

export interface ICardType<S extends ICardSettings> {
  type: CardTypeKey;
  title: TranslationKey;
  description: TranslationKey;
  locationAware: CardLocationAwareness;
  hidden?: boolean;
  categories: CardCategory[];
  rights: UserRights;
  width: number;
  height: number;
  minWidth?: number;
  minHeight?: number;
  isVisible?: (location?: ILocation, user?: AuthUser) => boolean;
  isAvailable?: (user: AuthUser, organizations: IOrganization[]) => boolean;
  defaultSettings: S;
  cardClass: React.FunctionComponent<ICardProps<S>> | React.ComponentClass<ICardProps<S>>;
  initSettingsPanel?: React.FunctionComponent<SettingsPanelProps<S>> | React.ComponentClass<SettingsPanelProps<S>>;
  validateSettings?: (settings: S) => boolean;
  upgradeSettings?: (settings: Partial<S>) => Partial<S> | undefined;
  deprecated?: boolean;
}
