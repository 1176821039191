import {AppStore} from '..';
import {IUIState} from '../AppState';

export enum UIStateActions {
  UPDATE = 'UISTATE.UPDATE'
}

export interface IUIStateUpdateAction {
  type: UIStateActions.UPDATE;
  payload: Partial<IUIState>;
}

export type AnyUIStateAction = IUIStateUpdateAction;

export function updateUIState(store: AppStore, updates: Partial<IUIState>) {
  store.dispatch({
    type: UIStateActions.UPDATE,
    payload: updates
  });
}
