import * as React from 'react';
import {NotificationManager} from 'react-notifications';
import {DeepPartial} from 'redux';

import {useAppContext} from '../../../app/context';

import {TabbedModalError} from '../../../modals/TabbedModal';
import {IOrganization} from '../../../models/Organization';
import {ICustomPricingGroupMember, IPricingGroup} from '../../../models/PricingGroup';
import {translateErrorCode} from '../../../utils/Errors';
import {FormContextProvider} from '../../../utils/FormContext';
import {useFormState} from '../../../utils/FormState';
import {T} from '../../../utils/Internationalization';

interface PricingGroupContextProps {
  pricingGroup: IPricingGroup;
  onChange: OnChangeHandler;
  saveGroup: () => Promise<TabbedModalError | undefined>;
  organization: Pick<IOrganization, 'id' | 'name' | 'currency'>;
}

export type OnChangeHandler = (updates: DeepPartial<IPricingGroup>) => void;

const PricingGroupContext = React.createContext<PricingGroupContextProps | null>(null);
PricingGroupContext.displayName = 'PricingGroupContext';

interface PricingGroupProviderProps {
  group?: IPricingGroup;
  children: React.ReactNode;
  organization: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  onSave?: (group: IPricingGroup) => void;
}

const initialState: IPricingGroup = {
  name: '',
  type: 'CUSTOM',
  members: []
};

// import { PricingGroupProvider } from "path-to-context/PricingGroupContext"
// use <PricingGroupProvider> as a wrapper around the part you need the context for
function PricingGroupProvider({children, group, organization, onSave}: PricingGroupProviderProps) {
  const {api} = useAppContext();
  const [pricingGroup, setPricingGroup] = React.useState<IPricingGroup>(group ? group : initialState);
  const form = useFormState();

  const onChange = React.useCallback(
    (g: DeepPartial<IPricingGroup>) => {
      setPricingGroup({
        ...pricingGroup,
        ...g
      });
    },
    [pricingGroup]
  );

  React.useEffect(() => {
    if (group?.id) {
      api.pricingGroups.get(organization.id, group.id).then(p => setPricingGroup(p));
    }
  }, [api, organization.id, group?.id]);

  const handleSave = React.useCallback(async () => {
    form.clearServerErrors();
    try {
      pricingGroup.members?.forEach(member => (member.error = undefined));
      const result = await (pricingGroup.id
        ? api.pricingGroups.update(organization.id, pricingGroup)
        : api.pricingGroups.create(organization.id, pricingGroup));

      if (!result) {
        throw new Error('Error saving pricing group');
      }

      NotificationManager.success(T('pricingGroups.add.success'));
      onSave?.(result as IPricingGroup);
    } catch (error: any) {
      if (error?.code === 'rfid.token.validation.failed') {
        const details: {code: string; detail: string; entity: string}[] = error?.errors;
        const members: ICustomPricingGroupMember[] | undefined = pricingGroup?.members?.map(member => {
          const errorDetail = details.find(detail => detail.entity === member.token);
          const error = errorDetail
            ? translateErrorCode(errorDetail.code) || T('pricingGroups.add.errors.invalidToken')
            : undefined;
          return {...member, error};
        });
        setPricingGroup({...pricingGroup, members});
        form.setServerError('tokens', T('pricingGroups.add.errors.invalidTokens'));
        return {tab: 'details'};
      }
      if (error?.code === 'rfid.card.unknown') {
        form.setServerError('tokens', T('pricingGroups.add.errors.tokenNotActiveAtSmappee'));
        return {};
      }

      return {error: T('pricingGroups.add.errors.unknown')};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, onSave, organization.id, pricingGroup]);

  return (
    <PricingGroupContext.Provider
      value={{
        pricingGroup,
        onChange,
        saveGroup: handleSave,
        organization
      }}
    >
      <FormContextProvider value={form}>{children}</FormContextProvider>
    </PricingGroupContext.Provider>
  );
}

// import { usePricingGroup } fron "path-to-context/PricingGroupContext"
// within functional component
// const { sessionToken, ...PricingGroupContext } = usePricingGroup()
function usePricingGroup(): PricingGroupContextProps {
  const context = React.useContext(PricingGroupContext);

  if (!context) {
    throw new Error('You should use usePricingGroup within an PricingGroupContext');
  }

  return context;
}

export {PricingGroupProvider, usePricingGroup};
