import {BrandColors} from '../../utils/BrandColors';

interface IconProps {
  className?: string;
  width?: number;
  height?: number;
  overrideColor?: string;
}
const SmappeeSolidIcon = ({
  className = '',
  overrideColor = BrandColors.GreenAtlantis,
  width = 47,
  height = 64
}: IconProps) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 47 64">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={overrideColor}
        d="M44.14,16.75c-6.2-5.14-12.39-10.61-18.15-15.8
	c-1.43-1.26-3.57-1.26-5,0.01C15.24,6.15,9.06,11.61,2.86,16.75C1.36,18,0.07,20.47,0.04,22.26c-0.16,8.28,0.21,16.57,1.12,24.85
	c0.2,1.78,1.86,3.49,3.65,3.73c1.6,0.21,2.18,0.4,3.78,0.55l-2.37,9.52L2.39,64h15.72l2.49-11.92l0-0.02
	c1.09,0.02,5.2,0.02,6.29,0.01V64h15.73l-3.84-3.08l-0.02-9.47c1.82-0.17,1.62-0.37,3.43-0.61c1.79-0.24,3.44-1.95,3.65-3.73
	c0.91-8.28,1.28-16.57,1.12-24.85c-0.03-1.79-1.3-4.26-2.81-5.51"
      />
    </svg>
  );
};

export default SmappeeSolidIcon;
