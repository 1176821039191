import React, {useState, useEffect, useMemo} from 'react';

import {useAppContext} from '../../app/context';
import {Form, SingleActionModal} from '../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ISmartDevice, getPropertyStringValue, getPropertyValueFromString} from '../../models/SmartDevice';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';

import SmartDeviceField from './SmartDeviceField';

interface EditSmartDeviceModalProps extends IPromiseModalProps<void> {
  device: ISmartDevice;
  locationId: number;
}

export const EditSmartDeviceModal = (props: EditSmartDeviceModalProps) => {
  const {device, locationId} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  const {api} = useAppContext();
  const form = useFormState();

  const initialPropertyValues = useMemo(() => {
    const results: {[key: string]: string} = {};
    device.configurationProperties.forEach(property => {
      const value = getPropertyStringValue(property);
      results[property.spec.name] = value;
    });
    return results;
  }, [device]);

  const [propertyValues, setPropertyValues] = useState<{[key: string]: string}>(initialPropertyValues);
  const [error, setError] = useState<string>();

  const fields = useMemo(() => {
    const updatePropertyValue = (name: string, value: string) => {
      setPropertyValues(values => ({...values, [name]: value}));
    };

    return device.configurationProperties.map(property => (
      <SmartDeviceField
        property={property}
        updateValue={updatePropertyValue}
        value={propertyValues[property.spec.name]}
      />
    ));
  }, [device, form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const propertyValues: {[key: string]: string} = {};
    device.configurationProperties.forEach(
      property => (propertyValues[property.spec.name] = getPropertyStringValue(property))
    );
    setPropertyValues(propertyValues);
  }, [device]);

  const handleClickedSave = async () => {
    if (form.hasErrors()) return;

    const configurationProperties = device.configurationProperties.map((property, index) => {
      var newProperty = {...property};
      newProperty.values = getPropertyValueFromString(property.spec, propertyValues[property.spec.name]) || [];
      return newProperty;
    });
    try {
      await api.updateSmartDevice(locationId, device.id, {
        configurationProperties
      });
      resolve();
    } catch {
      setError(T('smartDevices.error.couldNotUpdate'));
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve()}
      title={T('smartDevices.editTitle', {name: device.name})}
      action={handleClickedSave}
      actionText={T('smartDevices.editSave')}
      error={error}
    >
      <Form>{fields}</Form>
    </SingleActionModal>
  );
};
