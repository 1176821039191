import React from 'react';

import {Button as RsButton} from '../../../components/bootstrap';
import {Icons} from '../../../components/Icon';
import {Badge as UiBadge} from '../../../components/ui/badge';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../../modals/ConfirmationPromiseModal';
import {useModals} from '../../../modals/ModalContext';
import {DeviceType} from '../../../models/DeviceType';
import {LocationFunctionType, translateFunctionType} from '../../../models/Location';
import {T} from '../../../utils/Internationalization';

interface LocationFunctionTypeEditorProps {
  value: LocationFunctionType;
  deviceType: DeviceType | undefined;
  update: (value: LocationFunctionType) => Promise<any>;
  upgrading: boolean;
  readOnly: boolean;
}
export default function LocationFunctionTypeEditor(props: LocationFunctionTypeEditorProps) {
  const {value, deviceType, upgrading, readOnly, update} = props;
  const canPromote =
    (value === LocationFunctionType.Standard || value === LocationFunctionType.ChargingPark) &&
    deviceType === DeviceType.Genius;
  const modals = useModals();

  const handleClickedPromote = async () => {
    const response = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locationConfiguration.property.functionType.promote.title')}
        message={T('locationConfiguration.property.functionType.promote.message')}
        {...props}
      />
    ));
    if (response === ConfirmationResult.Accept) {
      update(
        value === LocationFunctionType.ChargingPark
          ? LocationFunctionType.LocalityParentChargingPark
          : LocationFunctionType.LocalityParent
      );
    }
  };

  return (
    <span>
      {translateFunctionType(value)}
      &nbsp;
      {!readOnly && canPromote && (
        <RsButton size="sm" onClick={handleClickedPromote} style={{bottom: '2.5px', position: 'relative'}}>
          {T('locationConfiguration.property.functionType.promoteToMultiGateway')}
        </RsButton>
      )}
      {upgrading && (
        <UiBadge variant="secondary" className="tw-text-base tw-ml-2">
          {Icons.Loading} {T('locationConfiguration.property.functionType.upgrading')}
        </UiBadge>
      )}
    </span>
  );
}
