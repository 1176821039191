import dayjs from 'dayjs';
import React from 'react';
import {NotificationManager} from 'react-notifications';
import {Col, Form, Row} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {Alert} from '../../components/bootstrap';
import {SingleActionPromiseModal} from '../../components/bootstrap/SingleActionPromiseModal';
import {AddressInput} from '../../components/inputs/AddressInput';
import DateInput from '../../components/inputs/DateInput';
import {useNumberInput} from '../../components/inputs/NumberInput';
import SelectLanguageInputGroup from '../../components/inputs/SelectLanguageInputGroup';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {APIResponse} from '../../core/api';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {Contract} from '../../models/Contract';
import {Address} from '../../models/Countries';
import {IOrganization} from '../../models/Organization';
import {SplitBillingAgreement, SplitBillingAgreementCreateRequest} from '../../models/SplitBillingAgreement';
import {None} from '../../utils/Arrays';
import {getCurrencySymbol} from '../../utils/Currency';
import {ServerErrorCode, translateError} from '../../utils/Errors';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {Language, T} from '../../utils/Internationalization';
import {useObjectState} from '../../utils/ObjectState';
import {
  validateAtMost,
  validateBIC,
  validateEmail,
  validateIBAN,
  validateRequired,
  validateRFID
} from '../../utils/Validation';
import {useUser} from '../CardUtils';

interface ModalProps extends IPromiseModalProps<boolean> {
  organization: IOrganization;
  agreement?: SplitBillingAgreement;
  existingAgreements: SplitBillingAgreement[];
  canEditAsEmployer: boolean;
  canEditAsEmployee: boolean;
}

interface FormState {
  rfid: string;
  username: string;
  from: Date | null;
  to: Date | null;
  chargingStationSerial: string | null;
  rate: number | null;
  bankBIC: string;
  bankIBAN: string;
  employeeNumber: string;
  language: Language;
  invoiceEmail: string;
  invoiceCompanyName: string;
  invoiceCompanyVAT: string;
  invoiceFirstName: string;
  invoiceLastName: string;
  invoiceAddress: Address;
}

const defaultFormState: FormState = {
  rfid: '',
  username: '',
  from: new Date(),
  to: null,
  chargingStationSerial: null,
  rate: null,
  language: 'en',
  bankBIC: '',
  bankIBAN: '',
  employeeNumber: '',
  invoiceEmail: '',
  invoiceCompanyName: '',
  invoiceCompanyVAT: '',
  invoiceFirstName: '',
  invoiceLastName: '',
  invoiceAddress: {
    streetAndNumber: '',
    postalCode: '',
    city: '',
    countryCode: 'BE'
  }
};

function getFormStateForAgreement(agreement: SplitBillingAgreement): FormState {
  const {rate, invoice, bank} = agreement.refund;
  return {
    rfid: agreement.rfid,
    username: agreement?.user?.userName || '',
    from: new Date(agreement.period.from),
    to: agreement.period.to ? new Date(agreement.period.to) : null,
    chargingStationSerial: agreement?.chargingStation?.serialNumber || '',
    rate: agreement.pendingChanges?.refund?.value || rate.value,
    bankBIC: bank.bic,
    bankIBAN: bank.iban,
    language: invoice.language,
    employeeNumber: agreement.employeeNumber || '',
    invoiceEmail: invoice.email,
    invoiceCompanyName: invoice.companyName || '',
    invoiceCompanyVAT: invoice.vat || '',
    invoiceFirstName: invoice.firstName || '',
    invoiceLastName: invoice.lastName || '',
    invoiceAddress: {
      streetAndNumber: invoice.address.streetAndNumber,
      postalCode: invoice.address.postalCode,
      city: invoice.address.municipality,
      countryCode: invoice.address.country || ''
    }
  };
}

export default function EditSplitBillingAgreementModal(props: ModalProps) {
  const {organization, agreement, existingAgreements, canEditAsEmployee, canEditAsEmployer} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const hasContract = (organization.contracts || None).includes(Contract.SplitBilling);
  const canEditAll = agreement === undefined || !agreement.confirmed;
  const isConfirmed = agreement !== undefined && agreement.confirmed;

  const language = useUser().language as Language;

  const form = useFormState();
  const [state, updateFormState] = useObjectState(
    agreement
      ? getFormStateForAgreement(agreement)
      : {...defaultFormState, language, from: dayjs().startOf('day').toDate()}
  );

  const currency = 'EUR'; // TODO: get currency from odoo
  const [rateInput, rateValue] = useNumberInput(
    'rate',
    T('splitBilling.field.refund.rate'),
    state.rate,
    0.01,
    undefined,
    `${getCurrencySymbol(currency)}/kWh`,
    {
      disabled: !canEditAll && !canEditAsEmployer,
      info: T('splitBilling.field.refund.rate.info'),
      as: 'text'
    }
  );

  const handleClickedAdd = async () => {
    form.clearServerErrors();

    if (form.hasErrors() || rateValue === null) {
      form.showErrors();
      return '';
    }

    // check for duplicate agreements
    if (
      existingAgreements.some(existing => {
        if (agreement && agreement.id === existing.id) return false;

        return (
          existing.rfid === state.rfid &&
          existing.chargingStation?.serialNumber === state.chargingStationSerial &&
          existing.period.from === state.from!.valueOf()
        );
      })
    ) {
      form.setServerError('rfid', T('splitBilling.alreadyExists'));
      form.setServerError('chargingStation', T('splitBilling.alreadyExists'));
      form.setServerError('from', T('splitBilling.alreadyExists'));
      return '';
    }

    if (!state.from) return '';

    // make sure it's always the start of day (event if the timezone is negative)
    const from = dayjs.tz(state.from, 'UTC').add(12, 'hours').startOf('day').valueOf();
    const to = state.to ? dayjs.tz(state.to, 'UTC').add(12, 'hours').startOf('day').valueOf() : undefined;
    const request: SplitBillingAgreementCreateRequest = {
      rfid: state.rfid.replaceAll(':', ''),
      organization: {
        id: organization.id
      },
      period: {
        from,
        to
      },
      chargingStation: {
        serialNumber: state.chargingStationSerial || ''
      },
      employeeNumber: state.employeeNumber,
      refund: {
        rate: {
          value: rateValue,
          currency
        },
        bank: {
          bic: state.bankBIC,
          iban: state.bankIBAN
        },
        invoice: {
          language: state.language,
          email: state.invoiceEmail,
          companyName: state.invoiceCompanyName,
          vat: state.invoiceCompanyVAT,
          title: '',
          firstName: state.invoiceFirstName,
          lastName: state.invoiceLastName,
          address: {
            streetAndNumber: state.invoiceAddress.streetAndNumber,
            postalCode: state.invoiceAddress.postalCode,
            municipality: state.invoiceAddress.city,
            country: state.invoiceAddress.countryCode
          }
        }
      }
    };

    try {
      if (agreement) {
        await api.updateSplitBillingAgreement(agreement.id, request);
        NotificationManager.success(T('splitBilling.edit.success'));
      } else {
        await api.createSplitBillingAgreement(request);
        NotificationManager.success(T('splitBilling.add.success'));
      }
    } catch (err) {
      const error = err as APIResponse<unknown>;
      console.log(error);
      switch (error.code) {
        case ServerErrorCode.UserInvalid:
          form.setServerError('username', T('splitBilling.field.user.invalid'));
          return '';
        case 'user.notInOrganization':
          form.setServerError('username', T('splitBilling.field.user.notInOrganization'));
          return '';
        case ServerErrorCode.PeriodOverlaps:
          form.setServerError('from', T('splitBilling.overlaps'));
          form.setServerError('to', T('splitBilling.overlaps'));
          return '';
        default:
          return translateError(error, error.error || '');
      }
    }
    return undefined;
  };

  return (
    <SingleActionPromiseModal
      title={agreement ? T('splitBilling.edit.title') : T('splitBilling.add.title')}
      action={handleClickedAdd}
      actionText={agreement ? T('splitBilling.save') : T('splitBilling.add')}
      isOpen={isOpen}
      resolve={resolve}
      size="xl"
    >
      {!agreement && !hasContract && (
        <Alert color="warning" style={{marginTop: '1rem'}}>
          {T('splitBilling.agreementWarning')}
        </Alert>
      )}
      <FormContextProvider value={form}>
        <Form>
          <Row>
            <Col md={4}>
              <h5 style={{marginTop: '1rem'}}>{T('splitBilling.section.appliesTo')}</h5>
              <TextInputGroup
                form={form}
                name="rfid"
                label={T('splitBilling.field.rfid')}
                value={state.rfid}
                onChange={rfid => updateFormState({rfid})}
                validate={validateRFID}
                disabled={!canEditAll && !canEditAsEmployee}
                optional
              />
              <DateInput
                form={form}
                name="from"
                label={T('splitBilling.field.from')}
                minDate={new Date()}
                value={state.from}
                onChange={from => updateFormState({from})}
                disabled={!canEditAll}
                required
              />
              <DateInput
                form={form}
                name="to"
                label={T('splitBilling.field.to')}
                value={state.to}
                minDate={new Date()}
                onChange={to => updateFormState({to})}
                info={T('validatedInput.optional')}
                disabled={!canEditAll && !canEditAsEmployer}
              />
              {agreement?.confirmed && (
                <TextInputGroup
                  form={form}
                  label={T('splitBilling.field.chargingStation')}
                  name="chargingStation"
                  value={state.chargingStationSerial || ''}
                  onChange={chargingStationSerial => updateFormState({chargingStationSerial})}
                  disabled={!canEditAll && !canEditAsEmployee}
                  optional
                />
              )}
              {rateInput}
              {(canEditAll || canEditAsEmployer) && rateValue && rateValue > 2 && (
                <Alert color="warning">{T('splitBilling.field.refund.rate.high')}</Alert>
              )}
            </Col>
            <Col md={4}>
              <h5 style={{marginTop: '1rem'}}>{T('splitBilling.section.refundTo')}</h5>
              <TextInputGroup
                form={form}
                name="bic"
                label={T('splitBilling.field.refund.bic')}
                value={state.bankBIC}
                onChange={bankBIC => updateFormState({bankBIC})}
                validate={validateBIC}
                disabled={isConfirmed}
                optional
              />
              <TextInputGroup
                form={form}
                name="iban"
                label={T('splitBilling.field.refund.iban')}
                value={state.bankIBAN}
                onChange={bankIBAN => updateFormState({bankIBAN})}
                validate={validateIBAN}
                disabled={isConfirmed}
                optional
              />
              <TextInputGroup
                form={form}
                name="invoiceEmail"
                label={T('splitBilling.field.refund.invoice.email')}
                value={state.invoiceEmail}
                onChange={invoiceEmail => updateFormState({invoiceEmail})}
                validate={validateEmail}
                disabled={!canEditAll}
              />
              <TextInputGroup
                form={form}
                name="organization"
                label={T('splitBilling.field.refund.invoice.companyName')}
                value={organization?.name}
                onChange={() => {}}
                disabled={true}
              />
            </Col>
            <Col md={4}>
              <h5 style={{marginTop: '1rem'}}>{T('splitBilling.section.invoice')}</h5>
              <SelectLanguageInputGroup
                name="language"
                label={T('splitBilling.field.language')}
                value={state.language}
                onChange={language => updateFormState({language})}
                disabled={!canEditAll && !canEditAsEmployee}
              />
              <TextInputGroup
                form={form}
                name="employeeNumber"
                label={T('splitBilling.field.employeeNumber')}
                value={state.employeeNumber}
                onChange={value => updateFormState({employeeNumber: value})}
                disabled={!canEditAll && !canEditAsEmployer}
                validate={validateAtMost(15)}
                optional
              />
              <TextInputGroup
                form={form}
                name="invoiceFirstName"
                label={T('splitBilling.field.refund.invoice.firstName')}
                value={state.invoiceFirstName}
                onChange={value => updateFormState({invoiceFirstName: value})}
                disabled={!canEditAll && !canEditAsEmployee}
                validate={validateRequired}
              />
              <TextInputGroup
                form={form}
                name="invoiceLastName"
                label={T('splitBilling.field.refund.invoice.lastName')}
                value={state.invoiceLastName}
                onChange={value => updateFormState({invoiceLastName: value})}
                disabled={!canEditAll && !canEditAsEmployee}
                validate={validateRequired}
              />
              <AddressInput
                name="invoiceAddress"
                streetLabel={T('splitBilling.field.refund.invoice.street')}
                cityLabel={T('splitBilling.field.refund.invoice.municipality')}
                countryLabel={T('splitBilling.field.refund.invoice.country')}
                value={state.invoiceAddress}
                onChange={updates =>
                  updateFormState({
                    invoiceAddress: {...state.invoiceAddress, ...updates}
                  })
                }
                disabled={!canEditAll && !canEditAsEmployee}
                optional
              />
            </Col>
          </Row>
        </Form>
      </FormContextProvider>
    </SingleActionPromiseModal>
  );
}
