import {ICardSettings} from '../../models/CardSettings';
import {IConsumptionValue} from '../../models/ConsumptionValue';
import {ISensorReading} from '../../models/SensorReadings';

export enum LiveValuesMiniMode {
  Consumption = 'CONSUMPTION',
  AlwaysOn = 'ALWAYSON',
  Solar = 'SOLAR',
  Gas = 'GAS',
  Water = 'WATER',
  Submetering = 'SUBMETER'
}

export interface ILiveValuesMiniSettings extends ICardSettings {
  mode: LiveValuesMiniMode;
  loadId?: number;
  color?: string;
  customColor?: string;
}

export interface IMiniGraphData {
  from: number;
  to: number;
  electricityData: IConsumptionValue[];
  gasReadings: ISensorReading[];
  waterReadings: ISensorReading[];
}
