import {SeriesColumnOptions} from 'highcharts';
import React, {useMemo} from 'react';

import {ChartConfig, customFormatPoints} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import {IHarmonicsMessage} from '../../livedata/LiveDataModels';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {getPhaseLabel, PHASES} from '../../models/Phase';
import {createPointFormatter} from '../../utils/GraphUtils';
import {T, rank} from '../../utils/Internationalization';

import styles from './index.module.scss';

import {ILoadWithChannelIndices} from './Models';

export const HARMONICS = ['THD', 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 17, 19];

function createHarmonicsTooltipFormatter(labels: (string | number)[]) {
  return function (this: Highcharts.TooltipFormatterContextObject): string {
    let label =
      this.x === 0
        ? T('liveHarmonics.totalHarmonicDistortion')
        : T('liveHarmonics.xAxisLabel', {rank: rank(labels[this.x as number] as number)});
    let result = `<span style="font-size: 10px">${label}</span><br/>`;
    if (this.points) result += customFormatPoints(this.points);
    return result;
  };
}

function createXAxisFormatter(labels: (string | number)[]) {
  return function (this: Highcharts.AxisLabelsFormatterContextObject): string {
    return (labels[this.value as number] || `!${this.value}`).toString();
  };
}

interface LiveHarmonicsChartProps {
  values: IHarmonicsMessage;
  phaseType: PhaseType;
  loads: ILoadWithChannelIndices[];
  loadId: number;
  currentHarmonics: number[];
  voltageHarmonics: number[];
}
const VoltageColors = [
  '#5F3711', // brown
  '#000000', // black
  '#A9B0B3' // gray
];
const CurrentColors = [
  '#f28f43', // orange
  '#c42525', // red
  '#1aadce' // turquise
];
export const LiveHarmonicsChart = (props: LiveHarmonicsChartProps) => {
  const {values, phaseType, loads, loadId, currentHarmonics} = props;
  const load = loads.find(load => load.id === loadId);
  const currentHarmonicsWithTHD = useMemo(() => ['THD', ...currentHarmonics], [currentHarmonics]);
  /*const voltageHarmonicsWithTHD = useMemo(() => ['THD', ...voltageHarmonics], [voltageHarmonics]);*/

  if (!load) return <div>{T('liveHarmonics.loadNotFound')}</div>;

  const xAxisFormatter = createXAxisFormatter(currentHarmonicsWithTHD);

  const series: SeriesColumnOptions[] = [];
  load.actuals.sort((a, b) => a.phase.localeCompare(b.phase));
  load.actuals.forEach((channel, index) => {
    if (channel.index === undefined) return;

    const currentData = values.currentHarmonics[channel.index];
    if (currentData === null || currentData === undefined || values.currentThd[index] < 0) {
      return;
    }

    const tdi = values.currentThd[index] / 1000;
    const label = T('liveHarmonics.series.current', {
      phase: getPhaseLabel(phaseType, channel.phase)
    });
    series.push({
      name: label,
      color: CurrentColors[index],
      type: 'column',
      data: [
        [0, null],
        ...currentData.slice(1).map((current, index) => [index + 1, current === null ? null : current / 1000])
      ],
      tooltip: {
        pointFormatter: createPointFormatter(label, 'A', 1)
      },
      yAxis: 0
    });

    const labelTHD = T('liveHarmonics.series.currentTHD', {
      phase: getPhaseLabel(phaseType, channel.phase)
    });
    series.push({
      name: labelTHD,
      color: CurrentColors[index],
      type: 'column',
      data: [[0, tdi]],
      tooltip: {
        pointFormatter: createPointFormatter(label, '%', 2)
      },
      yAxis: 1
    });
  });
  values.phaseVoltageHarmonics.forEach((voltageData, index) => {
    if (voltageData === null || values.phaseVoltageThd[index] < 0) return;

    const thd = values.phaseVoltageThd[index] / 1000;
    const phase = PHASES[index];
    const label = T('liveHarmonics.series.voltage', {
      phase: getPhaseLabel(phaseType, phase)
    });
    series.push({
      name: label,
      color: VoltageColors[index],
      type: 'column',
      data: [
        [0, thd],
        ...voltageData
          .slice(1)
          .map((voltage, index) => [index + 1, voltage === null ? null : (voltage / (voltageData[0] || 230)) * 100])
      ],
      tooltip: {
        pointFormatter: createPointFormatter(label, '%', 2)
      },
      yAxis: 1
    });
  });
  const config: ChartConfig = {
    series,
    navigator: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    xAxis: {
      type: 'category', // ignored..?
      labels: {
        formatter: xAxisFormatter
      }
    },
    yAxis: [
      {
        title: {text: 'A'}
      },
      {
        title: {text: '%'},
        opposite: true
      }
    ],
    tooltip: {
      formatter: createHarmonicsTooltipFormatter(currentHarmonicsWithTHD),
      shared: true,
      split: false,
      enabled: true
    }
  };
  return <HighStockChart className={styles.stretch} config={config} />;
};
