import React from 'react';

import {Badge as UiBadge} from '../../components/ui/badge';

import {SwitchStatus, getSwitchStatusLabel} from '../../models/Switch';
import {T} from '../../utils/Internationalization';

export const OnlineStatus = ({isOnline}: {isOnline: boolean | undefined}) => {
  if (isOnline === undefined) return null;

  const color = isOnline ? 'success' : 'danger';
  const label = T(isOnline ? 'onlineStatus.online' : 'onlineStatus.offline');
  return (
    <UiBadge variant={color} className="tw-text-sm tw-rounded-full">
      {label}
    </UiBadge>
  );
};

export const OfflineStatus = () => <OnlineStatus isOnline={false} />;

export const SwitchConnectionStatus = ({status}: {status: SwitchStatus | undefined}) => {
  if (status === undefined) return null;

  const color = status === SwitchStatus.Connected ? 'success' : 'danger';
  const label = getSwitchStatusLabel(status);
  return (
    <UiBadge variant={color} className="tw-text-sm tw-rounded-full">
      {label}
    </UiBadge>
  );
};
