import dayjs from 'dayjs';
import React, {useRef, useMemo, useState, useEffect} from 'react';
import 'dayjs/plugin/duration';

import {useAppContext} from '../../app/context';
import {Button as RsButton, Input, Label, FormGroup} from '../../components/bootstrap';

import {Icons} from '../../components/Icon';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {useNumberInput} from '../../components/inputs/NumberInput';
import {RemoteType} from '../../core/api';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {FormProvider} from '../../utils/FormContext';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardView, cardViewProps} from '../components/CardView';

import styles from './index.module.scss';

// Remote connect options
const PORT_MIN = 18000;
const PORT_MAX = 18999;

const RemoteConnect = (props: ICardProps<ICardSettings>) => {
  const {settings} = props;
  const {api} = useAppContext();

  const randomPort = useMemo(() => Math.floor((PORT_MAX - PORT_MIN) * Math.random()), []);
  const [portInput, port, setPort] = useNumberInput('port', 'Port', randomPort, PORT_MIN, PORT_MAX);
  const [durationInput, duration] = useNumberInput('duration', 'Duration', 30, 1, 240);
  const [type, setType] = useState(RemoteType.HTTP);

  const [connecting, setConnecting] = useState(false);
  const [connectionUrl, setConnectionUrl] = useState<string>();
  const [remaining, setRemaining] = useState<string>();
  const [error, setError] = useState<string>();

  const countdownId = useRef<NodeJS.Timeout>();
  const connectTimeout = useRef<NodeJS.Timeout>();

  const location = useCardLocation(settings);
  const serialNumber = location && location.serialNumber;
  useEffect(() => {
    if (serialNumber) setPort(PORT_MIN + parseInt(serialNumber.slice(-3)));
  }, [serialNumber, setPort]);

  const handleConnectionChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setType(event.currentTarget.value as RemoteType);
  };

  const handleClickedConnect = async () => {
    if (port === null || duration === null) return;
    if (!location) return;

    if (!serialNumber) return; // no device installed here

    const timeoutSecs = duration * 60;
    const disconnectedAt = Date.now() + timeoutSecs * 1000;

    // Reset state
    if (countdownId.current !== undefined) {
      clearTimeout(countdownId.current);
      countdownId.current = undefined;
    }

    setConnecting(true);
    setConnectionUrl(undefined);
    setError(undefined);

    try {
      api.remoteConnect(serialNumber, port, timeoutSecs, type).then(({success, statusCode, connectionUrl}) => {
        if (connectTimeout.current !== undefined) {
          clearTimeout(connectTimeout.current);
          connectTimeout.current = undefined;
        }

        if (success) {
          setTimeout(() => {
            setConnecting(false);
            setConnectionUrl(connectionUrl);

            // Open remote connection URL in a new tab
            if (type === RemoteType.HTTP) {
              window.open(connectionUrl, '_blank');
            }

            // Keep counting down every second
            countdownId.current = setInterval(() => {
              if (!connecting && disconnectedAt !== undefined) {
                const remaining = dayjs.duration(dayjs(disconnectedAt).diff(dayjs()));
                const hours = remaining.asHours();
                setRemaining(remaining.format(hours >= 1 ? 'HH:mm:ss' : 'mm:ss'));
              } else {
                setRemaining(undefined);
              }

              if (disconnectedAt < Date.now()) {
                if (countdownId.current !== undefined) {
                  clearTimeout(countdownId.current);
                  countdownId.current = undefined;
                }

                setConnecting(false);
                setConnectionUrl(undefined);
                setRemaining(undefined);
              }
            }, 1000);
          }, 5000);
        } else {
          setConnecting(false);
          setConnectionUrl(undefined);
          if (statusCode === 409) {
            setError('Port already in use');
          } else {
            setError('Failed to connect');
          }
        }
      });

      // Only wait a certain period before timing out
      if (connectTimeout.current !== undefined) {
        clearTimeout(connectTimeout.current);
      }
      connectTimeout.current = setTimeout(() => {
        setConnecting(false);
        setConnectionUrl(undefined);
      }, 20 * 1000);
    } catch (error) {
      setError('Failed to connect');
    }
  };

  return (
    <CardView {...cardViewProps(props)}>
      <FormProvider>
        <div className={styles.formWrapper}>
          {portInput}
          {durationInput}

          <FormGroup>
            <Label for="type">Connection</Label>
            <Input type="select" name="type" value={type} onChange={handleConnectionChanged}>
              <option value={RemoteType.HTTP}>HTTP</option>
              <option value={RemoteType.NODERED}>Node-RED</option>
              <option value={RemoteType.SSH}>SSH</option>
              <option value={RemoteType.MQTT}>MQTT</option>
            </Input>
          </FormGroup>

          <div className={styles.formActions}>
            <FormSaveButton
              color={remaining ? 'secondary' : 'primary'}
              onSave={handleClickedConnect}
              disabled={connecting}
            >
              {remaining ? 'Reconnect' : 'Connect'}
            </FormSaveButton>

            {connecting && <div color="primary">Connecting...</div>}
            {error && <div color="primary">{error}</div>}

            {remaining && (
              <RsButton target="_blank" href={connectionUrl}>
                Connected {remaining}
                &nbsp;{Icons.ExternalLink}
              </RsButton>
            )}
          </div>
        </div>
      </FormProvider>
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.RemoteConnect,
  title: 'remoteConnect.title',
  description: 'remoteConnect.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Unaware,
  cardClass: RemoteConnect
};
export default CARD;
