import React, {useState} from 'react';
import {ModalFooter} from 'reactstrap';

import {useModals} from '../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../modals/PromiseModal';
import {onMarketingCookiesEnabled} from '../utils/GoogleAnalytics';
import {T} from '../utils/Internationalization';
import {classes} from '../utils/Styles';

import {Button as RsButton, Modal, ModalBody, ModalHeader} from './bootstrap';
import styles from './CookieNagger.module.scss';

interface ICookieSettings {
  accepted: boolean;
  allowMarketing: boolean;
}

export function getCookieSettings(): ICookieSettings {
  const settingsString = localStorage.getItem('cookies') || '{}';
  return {
    accepted: false,
    allowMarketing: false,
    ...JSON.parse(settingsString)
  };
}

function updateSettings(settings: ICookieSettings) {
  localStorage.setItem('cookies', JSON.stringify(settings));

  if (settings.allowMarketing) {
    onMarketingCookiesEnabled();
  }
}

export function CookieNagger() {
  const modals = useModals();

  const [settings, setSettings] = useState(getCookieSettings());

  const handleClickedAccept = () => {
    const settings: ICookieSettings = {accepted: true, allowMarketing: true};
    setSettings(settings);
    updateSettings(settings);
  };

  const handleClickedSettings = async () => {
    const settings = await modals.show<ICookieSettings | undefined>(props => <CookieSettingsModal {...props} />);
    if (!settings) return;

    setSettings(settings);
    updateSettings(settings);
  };

  return settings.accepted ? null : (
    <div className={styles.main}>
      <p style={{margin: 0}}>
        {T('cookies.shortNotice.websiteUsesCookies')}
        <br />
        <a href="https://www.smappee.com/privacy/" className={styles.link}>
          {T('cookies.linkText.privacyPolicy')}
        </a>
        &nbsp;-&nbsp;
        <a href="https://www.smappee.com/cookie/" className={styles.link}>
          {T('cookies.linkText.cookiePolicy')}
        </a>
      </p>
      <RsButton color="link" onClick={handleClickedSettings} style={{flexShrink: 0}}>
        {T('cookies.buttonText.cookieSettings')}
      </RsButton>
      <RsButton color="primary" onClick={handleClickedAccept} style={{flexShrink: 0}}>
        {T('cookies.buttonText.accept')}
      </RsButton>
    </div>
  );
}

function CookieSettingsModal(props: IPromiseModalProps<ICookieSettings | undefined>) {
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(undefined);

  const [necessaryOpen, setNecessaryOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);

  const handleToggleNecessary = () => setNecessaryOpen(!necessaryOpen);
  const handleToggleMarketing = () => setMarketingOpen(!marketingOpen);

  const [marketingEnabled, setMarketingEnabled] = useState(true);

  const handleMarketingChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMarketingEnabled(e.currentTarget.checked);
  };

  const handleClickedSave = () => {
    resolve({
      accepted: true,
      allowMarketing: marketingEnabled
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="lg" autoFocus={false}>
      <ModalHeader toggle={handleClose}>{T('cookies.title.privacyOverview')}</ModalHeader>
      <ModalBody>
        <p style={{marginTop: '1rem'}}>{T('cookies.longNotice.websiteUsesCookies')}</p>
        <div className="cli-col-12 cli-align-items-stretch cli-px-0 cli-tab-section-container">
          <div className="cli-tab-section">
            <div
              className={classes('cli-tab-header', necessaryOpen ? 'cli-tab-active' : '')}
              onClick={handleToggleNecessary}
            >
              <a role="button" tabIndex={0} className="cli-nav-link cli-settings-mobile">
                {T('cookies.text.necessary')}
              </a>
              <div className="wt-cli-necessary-checkbox">
                <input
                  type="checkbox"
                  className="cli-user-preference-checkbox"
                  id="wt-cli-checkbox-necessary"
                  data-id="checkbox-necessary"
                  checked={true}
                />
                <label className="form-check-label" htmlFor="wt-cli-checkbox-necessary">
                  {T('cookies.text.necessary')}
                </label>
              </div>
              <span className="cli-necessary-caption">{T('cookies.text.alwaysEnabled')}</span>
            </div>
            <div className="cli-tab-content" style={{display: necessaryOpen ? 'block' : 'none'}}>
              <div className="cli-tab-pane cli-fade" data-id="necessary">
                <p>{T('cookies.notice.necessaryCookies')}</p>
              </div>
            </div>
          </div>
          <div className="cli-tab-section">
            <div
              className={classes('cli-tab-header', marketingOpen ? 'cli-tab-active' : '')}
              onClick={handleToggleMarketing}
            >
              <a role="button" tabIndex={0} className="cli-nav-link cli-settings-mobile">
                {T('cookies.linkText.nonNecessary')}
              </a>
              <div className="cli-switch">
                <input
                  type="checkbox"
                  id="wt-cli-checkbox-non-necessary"
                  className="cli-user-preference-checkbox"
                  data-id="checkbox-non-necessary"
                  checked={marketingEnabled}
                  onChange={handleMarketingChanged}
                />
                <label
                  htmlFor="wt-cli-checkbox-non-necessary"
                  className="cli-slider"
                  data-cli-enable={T('cookies.optional.enabled')}
                  data-cli-disable={T('cookies.optional.disabled')}
                >
                  <span className="wt-cli-sr-only">{T('cookies.linkText.nonNecessary')}</span>
                </label>
              </div>
            </div>
            <div className="cli-tab-content" style={{display: marketingOpen ? 'block' : 'none'}}>
              <div className="cli-tab-pane cli-fade" data-id="non-necessary">
                <p>{T('cookies.notice.unneededCookies')}</p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <RsButton color="primary" onClick={handleClickedSave}>
          {T('modal.save')}
        </RsButton>
      </ModalFooter>
    </Modal>
  );
}
