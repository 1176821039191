import numeral from 'numeral';

export function undefinedToNull<T>(value: undefined | T): null | T {
  return value === undefined ? null : value;
}

export function smartFormat(value: number): string {
  if (value === 0) return '0';

  const absValue = Math.abs(value);
  if (absValue < 1) {
    return value.toFixed(3);
  } else if (absValue < 10) {
    return value.toFixed(2);
  } else if (absValue < 100) {
    return value.toFixed(1);
  } else {
    return value.toFixed(0);
  }
}

export function smartFormatNumeral(value: number): string {
  if (value === 0) return '0';

  const absValue = Math.abs(value);
  if (absValue < 1) {
    return numeral(value).format('0.000');
  } else if (absValue < 10) {
    return numeral(value).format('0.00');
  } else if (absValue < 100) {
    return numeral(value).format('0.0');
  } else {
    return numeral(value).format('0');
  }
}

export function determinePrecisionForValue(value: number | null | undefined) {
  if (value === undefined || value === null || isNaN(value)) return 0;
  value = Math.abs(value);
  if (value < 1) return 3;
  if (value < 10) return 2;
  if (value < 100) return 1;

  return 0;
}

export function determinePrecisionForData<T>(values: T[], projection: (row: T) => number | null | undefined): number {
  let max = values.reduce((result, value) => {
    const projected = projection(value);
    if (projected === undefined || projected === null || isNaN(projected)) {
      return result;
    }

    return Math.max(result, projected);
  }, 0);

  return determinePrecisionForValue(max);
}

export function toShort(number: number) {
  return numeral(number).format('0,0');
}

export function compareOptionalNumbers(a: number | null | undefined, b: number | null | undefined) {
  if (a === b) return 0;
  if (a === undefined || a === null) return -1;
  if (b === undefined || b === null) return 1;
  return a - b;
}

export function minutesToHoursMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return {hours, minutes: minutesLeft};
}

export function timePassedInMinutes(time: string) {
  let [hours, mins] = time.split(':');

  return parseInt(hours) * 60 + parseInt(mins);
}
