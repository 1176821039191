import {AuthUser} from '../../models/AuthUser';
import {AnyAppAction} from '../actions';
import {MeActions} from '../actions/me';
import {IMeState} from '../AppState';

export default function me(state: IMeState = {me: AuthUser.anonymous()}, action: AnyAppAction): IMeState {
  switch (action.type) {
    case MeActions.SET_ME_START:
      return state;

    case MeActions.SET_ME_SUCCESS:
      return {me: action.payload};

    case MeActions.SET_ME_ERROR:
    default:
      return state;
  }
}
