import type {SVGProps} from 'react';
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M3.817 14.036c-1.078.382-2.117-.657-1.735-1.736l.466-1.313a.89.89 0 0 0-.457-1.102l-1.258-.6c-1.032-.491-1.032-1.961 0-2.453l1.258-.6a.89.89 0 0 0 .457-1.102l-.466-1.313C1.7 2.74 2.74 1.7 3.817 2.082l1.313.466a.89.89 0 0 0 1.103-.457L6.832.833c.492-1.032 1.962-1.032 2.454 0l.599 1.258a.89.89 0 0 0 1.102.457l1.313-.466c1.079-.382 2.118.657 1.736 1.735L13.57 5.13a.89.89 0 0 0 .456 1.103l1.258.599c1.033.492 1.033 1.962 0 2.454l-1.258.599a.89.89 0 0 0-.457 1.102l.466 1.313c.383 1.079-.656 2.118-1.735 1.736l-1.313-.466a.89.89 0 0 0-1.102.456l-.6 1.258c-.491 1.033-1.961 1.033-2.453 0l-.6-1.258a.89.89 0 0 0-1.102-.457zM8.06 11.4a3.342 3.342 0 1 0 0-6.683 3.342 3.342 0 0 0 0 6.683" />
  </svg>
);
export default SvgSettings;
