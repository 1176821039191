import {BessStatus} from '../models/BessStatus';
import {T} from '../utils/Internationalization';

import {Icons} from './Icon';

import {Badge} from './ui/badge';

interface IBessStatusIndicatorProps {
  status: BessStatus;
}

function getStatusColor(status: BessStatus) {
  switch (status) {
    case BessStatus.Active:
      return 'success';
    case BessStatus.Offline:
      return 'danger';
    case BessStatus.Error:
      return 'danger';
    case BessStatus.Off:
      return 'secondary';
    default:
      return 'secondary';
  }
}

function getStatusMessage(status: BessStatus) {
  switch (status) {
    case BessStatus.Error:
      return 'Error';
    case BessStatus.Active:
      return 'Active';
    case BessStatus.Offline:
      return 'Offline';
    case BessStatus.Off:
      return 'Off';
    default:
      return undefined;
  }
}

export function BessStatusIndicator(props: IBessStatusIndicatorProps) {
  const {status} = props;
  const color = getStatusColor(status);
  const label = getStatusMessage(status);

  return (
    <Badge variant={color} className="tw-text-sm tw-rounded-full">
      {label || (
        <>
          {Icons.Loading} {T('card.loadingLive')}
        </>
      )}
    </Badge>
  );
}
