import React, {useEffect, useState} from 'react';

import {useAppContext} from '../../app/context';
import {Alert, Nav, NavItem, NavLink, SingleActionModal} from '../../components/bootstrap';
import {DateTimeTextInput, DateTimeValue} from '../../components/inputs/DateTimeTextInput';
import {NumberInputGroup, NumberValue} from '../../components/inputs/NumberInput';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ChargingStationPaymentType, IChargingSession} from '../../models/ChargingStation';
import {SplitBillingAgreement} from '../../models/SplitBillingAgreement';
import {None} from '../../utils/Arrays';
import {translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';
import {getStatus} from '../SplitBilling/Columns';

interface FixSessionModalProps extends IPromiseModalProps<boolean> {
  session: IChargingSession;
}

export function FixSessionModal(props: FixSessionModalProps) {
  const {session} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const [action, setAction] = useState<'fix' | 'updatePaymentSpecification'>('fix');

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState(inputOrganizations.defaultOrganization);
  const [splitBillingAgreements, setSplitBillingAgreements] = useState<SplitBillingAgreement[]>(None);
  const [splitBillingAgreementId, setSplitBillingAgreementId] = useState<number>();

  useEffect(() => {
    if (organization?.id) {
      api.organizations
        .getSplitBillingAgreements(organization.id)
        .then(agreements => {
          setSplitBillingAgreements(agreements);
          setSplitBillingAgreementId(agreements[0]?.id);
        })
        .catch(() => setSplitBillingAgreements(None));
    }
  }, [api, organization?.id]);

  const [error, setError] = useState<string>();
  const [amount, setAmount] = useState(
    session.stopMeterReading
      ? NumberValue.create(session.stopMeterReading - session.startMeterReading)
      : NumberValue.none()
  );

  const [stoppedAt, setStoppedAt] = useState(
    session.to === undefined ? DateTimeValue.none() : DateTimeValue.create(session.to)
  );

  const handleClickedSave = () => {
    let promise: Promise<unknown> = Promise.resolve('');
    if (action === 'fix') {
      const amountKwh = amount.numberValue;
      const stoppedAtMillis = stoppedAt.dtValue?.valueOf();
      if (amountKwh == null || !stoppedAtMillis) return Promise.resolve('');

      promise = api.chargingSessions.fix(session.id, amountKwh, stoppedAtMillis);
    } else if (action === 'updatePaymentSpecification') {
      if (!splitBillingAgreementId) return Promise.resolve('');

      promise = api.chargingSessions.updatePaymentSpecification(session.id, {
        newPaymentType: 'SPLITBILLING',
        splitBillingAgreementId
      });
    }

    return promise
      .then(() => {
        resolve(true);
        return undefined;
      })
      .catch(e => {
        const err = translateError(e);
        setError(err);
        return err;
      });
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('chargingSessions.fix.title')}
      action={handleClickedSave}
      error={error}
    >
      <div style={{marginTop: '0.5rem'}}>
        <Nav tabs>
          <NavItem>
            <NavLink active={action === 'fix'} onClick={() => setAction('fix')}>
              Fix session
            </NavLink>
          </NavItem>
          {(session.paymentType === ChargingStationPaymentType.Whitelist ||
            session.paymentType === ChargingStationPaymentType.Free) && (
            <NavItem>
              <NavLink
                active={action === 'updatePaymentSpecification'}
                onClick={() => setAction('updatePaymentSpecification')}
              >
                Update session
              </NavLink>
            </NavItem>
          )}
        </Nav>
        {action === 'fix' && (
          <div className="tw-mt-2">
            <NumberInputGroup name="energy" value={amount} onChange={setAmount} label="Energy" suffix="kWh" />
            <DateTimeTextInput
              name="from"
              value={DateTimeValue.create(session.from)}
              readOnly
              onChange={() => {}}
              label="Started at"
            />
            <DateTimeTextInput name="to" value={stoppedAt} onChange={setStoppedAt} label="Stopped at" />
            <Alert color="info">
              Fixing a charging session will update its energy consumption and stop time. The session cost will be
              recalculated and if it's a scan and charge session, the session will be charged using the corresponding
              payment method. If the session was already paid, it's not possible to fix it.
            </Alert>
          </div>
        )}
        {action === 'updatePaymentSpecification' && (
          <>
            <OrganizationInputGroup
              name="organization"
              value={organization}
              onChange={setOrganization}
              label="Organization"
              organizations={inputOrganizations.organizations}
              onUpdateQuery={updateOrganizationInputQuery}
            />
            <SelectInputGroup
              name="splitBillingAgreement"
              value={splitBillingAgreementId?.toString() || ''}
              onChange={v => setSplitBillingAgreementId(parseInt(v))}
              label="Split billing agreement"
            >
              {splitBillingAgreements.map(agreement => (
                <option key={agreement.id} value={agreement.id}>
                  {`${agreement.refund.invoice.firstName} ${agreement.refund.invoice.lastName} (${getStatus(
                    agreement
                  )})`}
                </option>
              ))}
            </SelectInputGroup>
            <Alert color="info">
              Updating the payment specification of a charging session will change the payment type to split billing and
              link it to a split billing agreement. The session rate will follow the current rate in the split billing
              agreement.
            </Alert>
          </>
        )}
      </div>
    </SingleActionModal>
  );
}
