import numeral from 'numeral';
import React from 'react';

import {Gas, Standby, Water} from '../../components/ui-lib/icons/medium';
import {ILocation} from '../../models/Location';
import {T} from '../../utils/Internationalization';
import {smartFormatNumeral} from '../../utils/NumberUtils';

import styles from './Summary.module.scss';

interface UsageValuesProps {
  location: ILocation | undefined;
  alwaysOn: number | undefined;
  water: number | undefined;
  gas: number | undefined;
  electricityUnit: string;
  waterUnit: string;
  gasUnit: string;
}
export class UsageValues extends React.PureComponent<UsageValuesProps, {}> {
  render() {
    let {location, alwaysOn, water, gas, electricityUnit, waterUnit, gasUnit} = this.props;
    const {hasWater, hasGas} = location || {hasWater: false, hasGas: false};

    return (
      <div className={styles.usageContainer}>
        {hasWater && (
          <div className={styles.water}>
            <Water width={32} height={32} />
            <h4>{T('liveValues.waterToday')}</h4>
            <span>
              {typeof water !== 'undefined' ? smartFormatNumeral(water) : '---'}
              &nbsp;{waterUnit}
            </span>
          </div>
        )}
        {hasGas && (
          <div className={styles.gas}>
            <Gas width={32} height={32} />
            <h4>{T('liveValues.gasToday')}</h4>
            <span>
              {typeof gas !== 'undefined' ? smartFormatNumeral(gas) : '---'}
              &nbsp;{gasUnit}
            </span>
          </div>
        )}
        <div className={styles.alwaysOn}>
          <Standby width={32} height={32} />
          <h4>{T('liveValues.alwaysOn')}</h4>
          <span>
            {typeof alwaysOn !== 'undefined' ? numeral(alwaysOn).format('0.0') : '---'}
            &nbsp;
            {electricityUnit}
          </span>
        </div>
      </div>
    );
  }
}
