import {useCallback, useMemo, useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button} from '../../components/bootstrap';
import CenteredErrorView from '../../components/CenteredErrorView';
import {Icons} from '../../components/Icon';
import {CardOrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import Table from '../../components/Table';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable, ICardWithOrganization} from '../../models/CardSettings';
import {Contract, ContractDetails, translateContract} from '../../models/Contract';
import {CalculatedStringField, ComponentField, DateCalculatedField, ITableField} from '../../models/Table';
import {hasPartnerAdminFunctionality, isReadOnlyInOrganization} from '../../models/User';
import {fetchBoards} from '../../redux/actions/boards';
import {None} from '../../utils/Arrays';
import {useOrganization} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useUser} from '../CardUtils';
import {CardActions} from '../components';
import {CardView, cardViewProps, ICardState} from '../components/CardView';
import {DeleteContractModal} from '../Contracts/DeleteContractModal';
import EditOrganization from '../Organizations/modals/EditOrganization';
import ActivateEMSPModal from '../PublicChargingTokens/ActivateEMSPModal';
import {ActivateSplitBillingModal} from '../SplitBilling/ActivateSplitBillingModal';

import ActivateCPOModal from './ActivateCPOModal';

type ServicesSettings = ICardSettingsWithTable & ICardWithOrganization;

interface Service {
  contract: Contract;
  existing?: ContractDetails;
  activeOnParent: boolean;
  inherited: boolean;
  unavailable: boolean;
}

const rowKey = (row: Service) => row.contract;

function MyCard(props: ICardProps<ServicesSettings>) {
  const {fetch, settings, updateSettings} = props;
  const {api, store} = useAppContext();

  const modals = useModals();
  const me = useUser();
  const [loadingOrganization, setLoadingOrganization] = useState(false);

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = inputOrganizations.getActualOrganizationId(settings.organization);
  const [organization = inputOrganizations.defaultOrganization, refreshOrganization] = useOrganization(
    fetch,
    organizationId
  );
  const [parent] = useOrganization(fetch, organization?.parentId);

  const readOnly = isReadOnlyInOrganization(me, organization, inputOrganizations.organizations);

  const parentContracts = parent?.contracts || None;

  const [contracts, refreshContracts] = useCardLoader<ContractDetails[]>(
    api => (organization ? api.contracts.getForOrganization(organization.id) : Promise.resolve(None)),
    [organization?.id],
    plural('contract'),
    None
  );

  const updateBoards = useCallback(() => fetchBoards(api, store, me), [api, store, me]);

  const handleClickedOrganizationSettings = () => {
    if (!organization) return;

    setLoadingOrganization(true);
    api.organizations
      .getById(organization.id, true)
      .then(details => {
        modals
          .show(props => <EditOrganization organization={details} {...props} />)
          .then(changed => changed && refreshOrganization(true));
      })
      .finally(() => setLoadingOrganization(false));
  };

  const actions = (state: ICardState) => (
    <CardActions>
      <CardOrganizationInput
        inputOrganizations={inputOrganizations}
        organization={organization}
        updateSettings={updateSettings}
        updateOrganizationInputQuery={updateOrganizationInputQuery}
      />
      <Button onClick={handleClickedOrganizationSettings} disabled={loadingOrganization}>
        {T('userMenu.editOrganizationOne')}
        {loadingOrganization && <> {Icons.Loading}</>}
      </Button>
    </CardActions>
  );

  const services = useMemo<Service[]>(() => {
    return [Contract.SplitBilling, Contract.eMSPBusiness, Contract.ChargingHost].map(contract => {
      const inherited = organization ? (organization.inherits || None).includes(contract) : false;
      const unavailable = !inherited && (organization?.inherits || None).length > 0;
      return {
        contract,
        existing: contracts.find(c => c.contract === contract),
        activeOnParent: parentContracts.includes(contract),
        inherited,
        unavailable
      };
    });
  }, [organization, contracts, parentContracts]);

  const columns = useMemo<ITableField<Service>[]>(() => {
    const handleSetupSplitBilling = () => {
      if (!organization) return;

      modals
        .show<boolean>(props => (
          <ActivateSplitBillingModal
            organizationId={organization.id}
            instantAvailable={contracts.some(c => c.contract === Contract.SEPA) && organization.linkedToOdoo}
            {...props}
          />
        ))
        .then(updated => {
          if (updated) {
            NotificationManager.success(T('splitBilling.activate.success'));
            refreshContracts();
            updateBoards();
          }
        });
    };

    const handleSetupEMSP = () => {
      if (!organization) return;

      modals
        .show<boolean>(props => (
          <ActivateEMSPModal
            organizationId={organization.id}
            instantAvailable={contracts.some(c => c.contract === Contract.SEPA) && organization.linkedToOdoo}
            {...props}
          />
        ))
        .then(updated => {
          if (updated) {
            NotificationManager.success(T('publicChargingTokens.activate.success'));
            refreshContracts();
            updateBoards();
          }
        });
    };

    const handleSetupCPO = () => {
      if (!organization) return;

      modals
        .show<boolean>(props => (
          <ActivateCPOModal
            organizationId={organization.id}
            instantAvailable={contracts.some(c => c.contract === Contract.SEPA) && organization.linkedToOdoo}
            {...props}
          />
        ))
        .then(updated => {
          if (updated) {
            NotificationManager.success(T('services.activate.cpo.success'));
            refreshContracts();
            updateBoards();
          }
        });
    };

    const handleClickedClose = (service: Service) => {
      if (!service.existing) return;

      modals
        .show(props => <DeleteContractModal contract={service.existing!} closeOnly={true} {...props} />)
        .then(updated => updated && refreshContracts());
    };

    const handleClickedSetup = (service: Service) => {
      switch (service.contract) {
        case Contract.ChargingHost:
          handleSetupCPO();
          break;
        case Contract.SplitBilling:
          handleSetupSplitBilling();
          break;
        case Contract.eMSPBusiness:
          handleSetupEMSP();
          break;
      }
    };

    const handleClickedReopen = (service: Service) => {
      if (!service.existing) return;

      api.contracts.reopen(service.existing.id).then(() => {
        NotificationManager.success(T('services.reopen.success'));
        refreshContracts();
      });
    };

    const handleClickedEnable = (service: Service) => {
      if (!organization) return;

      modals
        .show<ConfirmationResult>(props => (
          <ConfirmationPromiseModal
            title={T('services.activate.suborg.title')}
            message={T('services.activate.suborg.message', {parentName: organization.parentName || ''})}
            {...props}
          />
        ))
        .then(result => {
          if (result === ConfirmationResult.Accept) {
            organization.inherits = [...(organization.inherits || []), service.contract];
            api.organizations.update(organization).then(() => {
              NotificationManager.success(T('services.enable.success'));
              refreshOrganization(true);
            });
          }
        });
    };

    return [
      new CalculatedStringField('service', T('services.field.service'), service => translateContract(service.contract)),
      new DateCalculatedField('date', T('services.field.date'), '', service => service.existing?.signedAt),
      new ComponentField('actions', T('services.field.actions'), service => {
        if (readOnly) return '';

        if (service.existing) {
          if (service.existing.closed) {
            return <Button onClick={() => handleClickedReopen(service)}>{T('services.closed')} &middot; Reopen</Button>;
          } else {
            return <Button onClick={() => handleClickedClose(service)}>{T('services.close')}</Button>;
          }
        } else if (service.activeOnParent) {
          if (service.inherited) {
            return <Button disabled>{T('services.enabled')}</Button>;
          } else {
            return <Button onClick={() => handleClickedEnable(service)}>{T('services.enable')}</Button>;
          }
        } else if (!service.unavailable) {
          return <Button onClick={() => handleClickedSetup(service)}>{T('services.setup')}</Button>;
        } else {
          return '';
        }
      })
    ];
  }, [api, modals, readOnly, organization, contracts, refreshContracts, refreshOrganization, updateBoards]);

  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      {organization === undefined ? (
        <CenteredErrorView>
          {T('generic.noDataOfType', {entity: plural('contract')})}
          <br />
          <br />
          <Button onClick={() => (window.location.pathname = '/register-organization')}>
            {T('services.register')}
          </Button>
        </CenteredErrorView>
      ) : (
        <Table
          items={services}
          fields={columns}
          hasPaging={false}
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
          noDefaultSort={true}
          rowKey={rowKey}
        />
      )}
    </CardView>
  );
}

const defaultSettings: ServicesSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'service', visible: true},
      {name: 'date', visible: true}
    ]
  }
};

const CARD: ICardType<ServicesSettings> = {
  type: CardTypeKey.Services,
  title: 'services.title',
  description: 'services.description',
  locationAware: CardLocationAwareness.Unaware,
  categories: [CardCategory.EV, CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 3,
  height: 2,
  defaultSettings,
  cardClass: MyCard
};
export default CARD;
