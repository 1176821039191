import {Form} from 'reactstrap';

import {FormContextProvider, useFormContext} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';
import {validateRequired} from '../../../utils/Validation';
import {TextInputGroup} from '../../inputs/TextInput';

import {usePricingPolicy} from './PricingPolicyProvider';

function BasicPolicyConfig() {
  const form = useFormContext();

  const {formState, updateFormState} = usePricingPolicy();

  return (
    <Form>
      <FormContextProvider value={form}>
        <h2 className="m-0 p-0 pb-3 font-bold">{T('pricingPolicies.add.basic.title')}</h2>
        <TextInputGroup
          form={form}
          name="name"
          label={T('pricingPolicies.add.basic.name')}
          value={formState.name}
          onChange={name => updateFormState({name})}
          validate={validateRequired}
          info={T('pricingPolicies.add.basic.name.description')}
        />
      </FormContextProvider>
    </Form>
  );
}

export {BasicPolicyConfig};
