import type {SVGProps} from 'react';
const SvgClear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm3.673-11.673c.406.406.438 1.031.072 1.397L9.469 8l2.276 2.276c.366.366.333.991-.072 1.397-.406.406-1.031.438-1.397.072L8 9.469l-2.276 2.276c-.366.366-.991.333-1.397-.072-.406-.406-.438-1.031-.072-1.397L6.531 8 4.255 5.724c-.366-.366-.333-.991.072-1.397.406-.406 1.031-.438 1.397-.072L8 6.531l2.276-2.276c.366-.366.991-.333 1.397.072Z" />
  </svg>
);
export default SvgClear;
