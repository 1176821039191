import React, {useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Alert, Form, FormGroup, Label} from '../../components/bootstrap';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {useTextInput} from '../../components/inputs/TextInput';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {FormProvider} from '../../utils/FormContext';
import {T} from '../../utils/Internationalization';
import {generateRandomString} from '../../utils/StringUtils';
import {validateRequired} from '../../utils/Validation';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {CardView, cardViewProps} from '../components/CardView';

const CreateAPIKey = (props: ICardProps<ICardSettings>) => {
  const {api} = useAppContext();

  const [appNameInput, appName, resetAppName] = useTextInput(
    'appName',
    T('createAPIKey.field.appName'),
    '',
    validateRequired
  );
  const [clientIdInput, clientId, resetClientId] = useTextInput(
    'clientId',
    T('createAPIKey.field.clientId'),
    '',
    validateRequired
  );

  const [secret, setSecret] = useState(generateRandomString(10));
  const [confirmation, setConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [info, setInfo] = useState('');

  const handleClickedGenerate = () => setSecret(generateRandomString(10));

  const handleClickedSubmit = async () => {
    try {
      const result = await api.createAPIToken(appName, clientId, secret);
      if (result.success) {
        resetAppName();
        resetClientId();
        setSecret(generateRandomString(10));
        setConfirmation(T('createAPIKey.field.created'));
        setErrorMessage('');
        setInfo(T('createAPIKey.field.info', {clientId, secret}));
        NotificationManager.success(T('createAPIKey.field.created'));
      } else {
        setErrorMessage(T('createAPIKey.failed'));
      }
    } catch {
      setErrorMessage(T('createAPIKey.failed'));
    }
  };

  return (
    <CardView {...cardViewProps(props)}>
      <FormProvider>
        <Form>
          {appNameInput}
          {clientIdInput}
          <FormGroup>
            <Label>{T('createAPIKey.field.clientSecret')}</Label>
            <div>
              <Label style={{marginLeft: 8, marginTop: 0, marginBottom: 0}}>
                {secret}
                &nbsp;&nbsp;
                <i className="fal fa-sync-alt" style={{cursor: 'pointer'}} onClick={handleClickedGenerate} />
              </Label>
            </div>
          </FormGroup>
          <FormGroup>
            <FormSaveButton onSave={handleClickedSubmit}>{T('createAPIKey.add')}</FormSaveButton>
          </FormGroup>
        </Form>
        {confirmation}
        {errorMessage && (
          <Alert fixed={false} color="danger">
            {errorMessage}
          </Alert>
        )}
        <div>
          {info.split('\n').map(i => (
            <p style={{margin: 0}} key={i}>
              {i}
            </p>
          ))}
        </div>
      </FormProvider>
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.CreateAPIKey,
  title: 'createAPIKey.title',
  description: 'createAPIKey.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Unaware,
  cardClass: CreateAPIKey
};
export default CARD;
