import React, {useState} from 'react';
import {Input} from 'reactstrap';

import {Button} from '../../../components/bootstrap';

import {Icon, Icons} from '../../../components/Icon';
import {
  ISearchField,
  SearchFieldSpecies,
  translateFieldDescription,
  translateFieldName
} from '../../../models/Organization';

import {classes} from '../../../utils/Styles';

import {EditPossibleValuesModal} from './EditPossibleValues';

import {EditTranslationModal} from './EditTranslation';
import styles from './SearchFieldEditor.module.scss';

interface SearchFieldEditorProps {
  id: number;
  isNew: boolean;
  multilingual: boolean;
  field: ISearchField;
  onFieldChanged: (index: number, updates: Partial<ISearchField>) => void;
  onClickedRemove: (index: number) => void;
}

export function SearchFieldEditor(props: SearchFieldEditorProps) {
  const {id, isNew, multilingual, field, onFieldChanged, onClickedRemove} = props;

  const [possibleValuesOpened, setPossibleValuesOpened] = useState(false);
  const [nameTranslationsOpened, setNameTranslationsOpened] = useState(false);
  const [descriptionTranslationsOpened, setDescriptionTranslationsOpened] = useState(false);

  const handleNameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onFieldChanged(id, {name: e.currentTarget.value});
  };

  const handleDisplayNameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onFieldChanged(id, {displayName: e.currentTarget.value});
  };

  const handleDescriptionChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onFieldChanged(id, {description: e.currentTarget.value});
  };

  const handleSpeciesChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onFieldChanged(id, {species: e.currentTarget.value as SearchFieldSpecies});
  };

  const handlePossibleValuesChanged = (values: string[] | undefined, exhaustive: boolean) => {
    onFieldChanged(id, {
      possibleValues: values ? {values, exhaustive} : undefined
    });
    setPossibleValuesOpened(false);
  };

  const handleNameTranslationsChanged = (translations: {[key: string]: string}) => {
    onFieldChanged(id, {displayNames: translations});
    setNameTranslationsOpened(false);
  };

  const handleDescriptionTranslationsChanged = (translations: {[key: string]: string}) => {
    onFieldChanged(id, {descriptions: translations});
    setDescriptionTranslationsOpened(false);
  };

  const handleClickedRemove = () => {
    onClickedRemove(id);
  };

  const handleClickedEditPossibleValues = () => {
    setPossibleValuesOpened(true);
  };

  const handleToggleEditPossibleValues = () => {
    setPossibleValuesOpened(opened => !opened);
  };

  const handleClickedEditNameTranslations = () => {
    setNameTranslationsOpened(true);
  };

  const handleToggleEditNameTranslations = () => {
    setNameTranslationsOpened(opened => !opened);
  };

  const handleClickedEditDescriptionTranslations = () => {
    setDescriptionTranslationsOpened(true);
  };

  const handleToggleEditDescriptionTranslations = () => {
    setDescriptionTranslationsOpened(opened => !opened);
  };

  return (
    <tr data-id={id}>
      <td>
        <Input disabled={!isNew} type="text" value={field.name} onChange={handleNameChanged} />
      </td>
      <td>
        {multilingual ? (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Input type="text" readOnly={true} value={translateFieldName(field)} />
            <Button
              color="link"
              title="Set translations"
              onClick={handleClickedEditNameTranslations}
              withoutPadding
              style={{paddingLeft: 10, paddingRight: 10}}
            >
              {Icons.Translations}
            </Button>
            <EditTranslationModal
              isOpen={nameTranslationsOpened}
              title="Edit name translations"
              onToggle={handleToggleEditNameTranslations}
              values={field.displayNames || {}}
              onChange={handleNameTranslationsChanged}
            />
          </div>
        ) : (
          <Input type="text" value={field.displayName} onChange={handleDisplayNameChanged} />
        )}
      </td>
      <td>
        {multilingual ? (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Input
              type="textarea"
              value={translateFieldDescription(field)}
              onChange={handleDescriptionChanged}
              readOnly={true}
            />
            <Button
              color="link"
              title="Set translations"
              onClick={handleClickedEditDescriptionTranslations}
              withoutPadding
              style={{paddingLeft: 10, paddingRight: 10}}
            >
              {Icons.Translations}
            </Button>
            <EditTranslationModal
              isOpen={descriptionTranslationsOpened}
              title="Edit description translations"
              type="textarea"
              onToggle={handleToggleEditDescriptionTranslations}
              values={field.descriptions || {}}
              onChange={handleDescriptionTranslationsChanged}
            />
          </div>
        ) : (
          <Input type="textarea" value={field.description} onChange={handleDescriptionChanged} />
        )}
      </td>
      <td>
        <Input type="select" value={field.species} onChange={handleSpeciesChanged}>
          <option value={SearchFieldSpecies.String}>Text</option>
          <option value={SearchFieldSpecies.IntegerOptional}>Integer number</option>
          <option value={SearchFieldSpecies.BigDecimal}>Decimal number</option>
          <option value={SearchFieldSpecies.BooleanOptional}>Yes/No</option>
        </Input>
      </td>
      <td>
        {field.species === SearchFieldSpecies.String && (
          <>
            <Button
              color="link"
              title="Set possible values"
              onClick={handleClickedEditPossibleValues}
              withoutPadding
              style={{marginRight: 10}}
            >
              {Icons.List}
            </Button>
            <EditPossibleValuesModal
              exhaustive={field.possibleValues?.exhaustive || false}
              values={field.possibleValues?.values}
              isOpen={possibleValuesOpened}
              onChange={handlePossibleValuesChanged}
              onToggle={handleToggleEditPossibleValues}
            />
          </>
        )}
        <Button color="link" title="Remove field" onClick={handleClickedRemove} withoutPadding>
          <span className="fal fa-times" />
        </Button>
        <i className={classes(styles.arrows, Icon.ArrowsV)} />
      </td>
    </tr>
  );
}
