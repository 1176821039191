import React, {FormEvent} from 'react';
import Autosuggest, {SuggestionSelectedEventData} from 'react-autosuggest';

import API from '../core/api';
import {ITimezone} from '../models/Timezone';

import autocompleteTheme from './AutoComplete.module.scss';
import {Input} from './bootstrap';

interface TimezoneInputProps {
  api: API;
  value: string;
  onSelected: (timezone: ITimezone) => void;
  autoFocus: boolean;
  disabled?: boolean;
}
interface TimezoneInputState {
  editingValue: string;
  suggestions: ITimezone[];
  timezones: ITimezone[];
}
export class TimezoneInput extends React.PureComponent<TimezoneInputProps, TimezoneInputState> {
  fetching: Promise<any> | null;

  constructor(props: TimezoneInputProps) {
    super(props);

    this.state = {
      editingValue: '',
      suggestions: [],
      timezones: []
    };

    this.fetching = null;
  }

  componentDidMount() {
    this.setState({editingValue: this.props.value});
  }

  componentDidUpdate(lastProps: TimezoneInputProps) {
    if (lastProps.value !== this.props.value) {
      this.setState({editingValue: this.props.value});
    }
  }

  fetchTimezones() {
    if (this.fetching) return this.fetching;

    const {api} = this.props;
    this.fetching = api.getAvailableTimezones().then(timezones =>
      this.setState({timezones}, () => {
        this.onSuggestionsFetchRequested({value: this.state.editingValue});
      })
    );
  }

  handleChange = (e: FormEvent<HTMLElement>) => {
    const value = (e.currentTarget as HTMLInputElement).value;
    if (value === undefined) {
      // occurs when selecting a value in the list
      return;
    }

    this.setState({
      editingValue: value || ''
    });
  };

  onSuggestionsFetchRequested = (params: {value: string}) => {
    if (this.state.timezones.length === 0) this.fetchTimezones();

    const {timezones} = this.state;
    const queryLower = params.value.toLowerCase();
    this.setState({
      suggestions: timezones.filter(timezone => timezone.name.toLowerCase().includes(queryLower)).slice(0, 5)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event: React.FormEvent<any>, data: SuggestionSelectedEventData<ITimezone>) => {
    this.props.onSelected(data.suggestion) /*.then(result => {
      if (result) {
        this.setState({editing: false, editingValue: ''});
      }
    })*/; // I need promise cancellations to do this properly... Stan
  };

  renderTimezoneSuggestion = (suggestion: ITimezone) => {
    return <div>{suggestion.name}</div>;
  };

  render() {
    const {editingValue, suggestions} = this.state;
    const {disabled, autoFocus, value} = this.props;

    if (disabled) return <Input value={value} disabled={true} />;

    const inputProps = {
      value: editingValue,
      className: 'form-control',
      onChange: this.handleChange,
      autoFocus
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={timezone => timezone.id}
        renderSuggestion={this.renderTimezoneSuggestion}
        inputProps={inputProps}
        theme={autocompleteTheme}
      />
    );
  }
}
