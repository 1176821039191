export interface IUserPreference {
  key: string;
  value: string;
}

export const enum UserPreferenceKey {
  BatchCounter = 'batchCounter',
  DecimalSeparator = 'decimalSeparator',
  ThousandSeparator = 'thousandSeparator',
  Delimiter = 'delimiter',
  DemoMode = 'demoMode',
  EVBoardAdded = 'evBoardAdded',
  HourFormat = 'hourFormat'
}
