import React from 'react';

import {Button} from '../../../components/ui/button';

import {Refresh} from '../../../components/ui-lib/icons/small';

import {useAppSelector} from '../../../utils/Hooks';
import {T} from '../../../utils/Internationalization';
import {testingClasses} from '../../../utils/TestingClasses';

interface ReloadProps {
  onReload: () => void;
  tooltip?: string;
}

export default function Reload(props: ReloadProps) {
  const {onReload, tooltip = T('card.refresh.tooltip')} = props;
  const presenting = useAppSelector(state => state.uiState.presenting);
  if (presenting) return <div />;

  return (
    <Button
      variant="secondary_default"
      size="lg"
      title={tooltip}
      onClick={onReload}
      className="testingClasses.refresh"
      data-testid={testingClasses.refresh}
    >
      <Refresh className="tw-h-4 tw-w-4" />
    </Button>
  );
}
