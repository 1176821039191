import {
  FluviusMeasurementCampaignType,
  FluviusMeasurementTemplateCode
} from '../../../models/FluviusMeasurementCampaign';
import {getCurrentLanguage} from '../../../utils/Internationalization';
import {
  FormErrors,
  MeasurementCampaignFormState,
  noSubmeterErrors,
  TemplateSubmeterErrors
} from '../FluviusMeasurementCampaigns/CampaignFormState';
import {
  SubmeterName,
  Template,
  TemplateName,
  TemplateSubmeter,
  TemplateSubmeterType
} from '../FluviusMeasurementCampaigns/Models';

import fr from './translations.fr.json';
import nl from './translations.nl.json';

export type SibelgaTranslationKey = keyof typeof nl;

interface Department {
  code: string;
  name: SibelgaTranslationKey;
}

export const SIBELGA_DEPARTMENTS: Department[] = [
  {code: '__', name: 'department.choose'},
  {code: 'EX', name: 'department.exploitation'},
  {code: 'BM', name: 'department.construction'}
];

export function SiT(key: SibelgaTranslationKey) {
  return getCurrentLanguage() === 'fr' ? (fr as any)[key] || nl[key] : nl[key];
}

interface Reason {
  code: string;
  name: SibelgaTranslationKey;
}

export const SIBELGA_CAMPAIGN_REASONS: Reason[] = [
  {code: '__', name: 'reason.choose'},
  {code: 'SK', name: 'reason.voltageComplaint'},
  {code: 'BM', name: 'reason.loadMetering'},
  {code: 'PH', name: 'reason.profileRecognition'},
  {code: 'CS', name: 'reason.voltageCheck'},
  {code: 'CO', name: 'reason.inbalanceCheck'},
  {code: 'X', name: 'reason.other'}
];

export const SIBELGA_CAMPAIGN_CITIES = [
  'Anderlecht',
  'Auderghem / Oudergem',
  'Berchem-Sainte-Aghate / Sint-Agatha-Berchem',
  'Bruxelles 1 / Brussel 1',
  'Bruxelles 2 / Brussel 2',
  'Etterbeek',
  'Evere',
  'Forest / Vorst',
  'Ganshoren',
  'Ixelles / Elsene',
  'Jette',
  'Koekelberg',
  'Molenbeek-Saint-Jean / Sint-Jans-Molenbeek',
  'Saint-Gilles / Sint-Gillis',
  'Saint-Josse-Ten-Noode / Sint-Joost-Ten-Noode',
  'Schaerbeek / Schaarbeek',
  'Uccle / Ukkel',
  'Watermael-Boitsfort / Watermaal-Bosvoorde',
  'Woluwe-Saint-Lambert / Sint-Lambrechts-Woluwe',
  'Woluwe-Saint-Pierre / Sint-Pieters-Woluwe'
];

export function validateFormState(state: MeasurementCampaignFormState): [boolean, FormErrors] {
  let valid = true;
  const errors: FormErrors = {
    typeRequired: false,
    cityRequired: false,
    regionRequired: false,
    reasonRequired: false,
    setRequired: false,
    templateASubmeters: [],
    templateBSubmeters: []
  };

  if (state.name.length > 200) {
    errors.name = SiT('field.name.tooLong');
    valid = false;
  }
  if (state.type === FluviusMeasurementCampaignType.__unset__) {
    errors.typeRequired = true;
    valid = false;
  }
  if (state.cabinId === '') {
    errors.cabinId = SiT('field.cabinId.required');
    valid = false;
  } else if (state.cabinId.length > 25) {
    errors.cabinId = SiT('field.cabinId.tooLong');
    valid = false;
  }
  if (state.region === undefined) {
    errors.regionRequired = true;
    valid = false;
  }
  if (state.voltage === '') {
    errors.voltage = SiT('field.voltage.required');
    valid = false;
  }
  if (state.streetAndNumber.length > 200) {
    errors.streetAndNumber = SiT('field.street.tooLong');
    valid = false;
  }
  if (state.reason === '__') {
    errors.reasonRequired = true;
    valid = false;
  }
  if (state.reason === 'X' && state.notes === '') {
    errors.notes = SiT('field.reason.required');
    valid = false;
  }
  if (state.notes.length > 200) {
    errors.notes = SiT('field.notes.tooLong');
    valid = false;
  }
  if (state.set === undefined) {
    errors.setRequired = true;
    valid = false;
  }
  if (state.templateA === undefined) {
    errors.templateA = SiT('field.template.required');
    valid = false;
  }
  if (state.templateA) {
    const submeterNames = new Set<string>();
    for (let submeter of state.templateASubmeters) {
      const [submeterErrors, submeterValid] = validateSubmeter(submeterNames, submeter);
      valid = valid && submeterValid;
      errors.templateASubmeters.push(submeterErrors);
    }
  }
  if (state.templateB) {
    const submeterNames = new Set<string>();
    for (let submeter of state.templateBSubmeters) {
      const [validated, submeterValid] = validateSubmeter(submeterNames, submeter);
      valid = valid && submeterValid;
      errors.templateBSubmeters.push(validated);
    }
  }

  return [valid, errors];
}

function validateSubmeter(submeterNames: Set<string>, submeter: TemplateSubmeter): [TemplateSubmeterErrors, boolean] {
  if (!submeter.enabled) return [noSubmeterErrors, true];

  let isValid = true;
  const errors: TemplateSubmeterErrors = {phaseInvalid: false};
  if (submeter.name === '') {
    errors.nameError = SiT('field.submeter.name.required');
    isValid = false;
  }
  if (submeter.name.length > 60) {
    errors.nameError = SiT('field.submeter.name.tooLong');
    isValid = false;
  }
  const name = submeter.name;
  if (isValid && submeterNames.has(name)) {
    errors.nameError = SiT('field.submeter.name.unique');
    errors.numberError = '';
    isValid = false;
  }
  submeterNames.add(name);
  return [isValid ? noSubmeterErrors : errors, isValid];
}

export function translateMeasuringType(type: FluviusMeasurementCampaignType) {
  switch (type) {
    case FluviusMeasurementCampaignType.Cabine:
      return SiT('type.cabin');
    case FluviusMeasurementCampaignType.Voetpadkast:
      return SiT('type.sidewalkCase');
    case FluviusMeasurementCampaignType.OndergrondseKast:
      return SiT('type.undergroundCase');
    case FluviusMeasurementCampaignType.WoningKlant:
      return SiT('type.customerHouse');
    case FluviusMeasurementCampaignType.KlantenCabine:
      return SiT('type.customerCabin');
    default:
      return '';
  }
}

export const SIBELGA_TEMPLATE_NETONLY: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net,
  code: FluviusMeasurementTemplateCode.Net,
  submeters: [{type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true}]
};
export const SIBELGA_TEMPLATE_NET2X3F: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net2x3f,
  code: FluviusMeasurementTemplateCode.Net2x3F,
  submeters: [
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3}
  ]
};
export const SIBELGA_TEMPLATE_NET4X3F: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net4x3f,
  code: FluviusMeasurementTemplateCode.Net4x3F,
  submeters: [
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3},
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3}
  ]
};
export const SIBELGA_TEMPLATE_NET8X1F: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net8x1f,
  code: FluviusMeasurementTemplateCode.Net8x1F,
  submeters: [
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1}
  ]
};
export const SIBELGA_TEMPLATE_NET16X1F: Template = {
  type: FluviusMeasurementCampaignType.Cabine,
  name: TemplateName.Net16x1f,
  code: FluviusMeasurementTemplateCode.Net16x1F,
  submeters: [
    {type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1}
  ]
};
export const SIBELGA_TEMPLATE_3FN: Template = {
  type: FluviusMeasurementCampaignType.Voetpadkast,
  name: TemplateName.T3FNet,
  code: FluviusMeasurementTemplateCode.THREE_PHASE,
  submeters: [{type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3}]
};
export const SIBELGA_TEMPLATE_4X1F: Template = {
  type: FluviusMeasurementCampaignType.Voetpadkast,
  name: TemplateName.T1F,
  code: FluviusMeasurementTemplateCode.FOUR_SINGLE_PHASE,
  submeters: [
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1},
    {type: TemplateSubmeterType.Phase1, name: SubmeterName.Load1}
  ]
};
export const SIBELGA_TEMPLATE_3FN_NET: Template = {
  type: FluviusMeasurementCampaignType.Voetpadkast,
  name: TemplateName.T3FNet,
  code: FluviusMeasurementTemplateCode.THREE_PHASE_N,
  submeters: [{type: TemplateSubmeterType.Phase3, name: SubmeterName.Net, main: true}]
};
export const SIBELGA_TEMPLATE_3FN_VERTREK: Template = {
  type: FluviusMeasurementCampaignType.Voetpadkast,
  name: TemplateName.T3FLoad,
  code: FluviusMeasurementTemplateCode.THREE_PHASE_V,
  submeters: [{type: TemplateSubmeterType.Phase3, name: SubmeterName.Load3}]
};

export const SIBELGA_CAMPAIGN_TEMPLATES: Template[] = [
  SIBELGA_TEMPLATE_NET2X3F,
  SIBELGA_TEMPLATE_NET4X3F,
  SIBELGA_TEMPLATE_NET8X1F,
  SIBELGA_TEMPLATE_NET16X1F,
  SIBELGA_TEMPLATE_3FN,
  SIBELGA_TEMPLATE_4X1F
];
export const SIBELGA_CAMPAIGN_ALL_TEMPLATES = [
  ...SIBELGA_CAMPAIGN_TEMPLATES,
  SIBELGA_TEMPLATE_NETONLY,
  SIBELGA_TEMPLATE_3FN_NET,
  SIBELGA_TEMPLATE_3FN_VERTREK
];

export function getCabinIDLabel(type: FluviusMeasurementCampaignType) {
  switch (type) {
    case FluviusMeasurementCampaignType.Cabine:
      return SiT('field.cabinId');
    case FluviusMeasurementCampaignType.Voetpadkast:
      return SiT('field.sidewalkCaseId');
    case FluviusMeasurementCampaignType.OndergrondseKast:
      return SiT('field.undergroundCaseId');
    case FluviusMeasurementCampaignType.WoningKlant:
      return SiT('field.customerHouseId');
    case FluviusMeasurementCampaignType.KlantenCabine:
      return SiT('field.customerCaseId');
    default:
      return '?';
  }
}
