import {T} from '../utils/Internationalization';

import {CTType} from './CTType';
import {Phase} from './Phase';

export function getChannelName(channel: IChannel) {
  return channel.name === undefined ? T('defaultNames.channel') : channel.name;
}

export interface IChannel {
  id: number;
  type: string;
  phase: Phase;
  ctType: CTType;
  status?: string;
  sensor?: IChannelSensor;
  export?: ProductionExport;
  circuitType?: CircuitType;
  category?: string;
  name?: string;
  midBusAddress?: number;
}

export interface IChannelUpdates {
  type?: string;
  phase?: Phase;
  ctType?: CTType;
  status?: string;
  reversed?: boolean;
  location?: string;
  export?: ProductionExport;
  circuitType?: CircuitType;
  category?: string;
  name?: string;
  ctHubSerial?: string;
}

export interface IChannelUpdateResult {
  type: string;
  phase: string;
  ctType: CTType;
  sensor?: IChannelSensor;
  export?: ProductionExport;
  circuitType?: CircuitType;
  category?: string;
  name?: string;
  midBusAddress?: number;
}

export const enum ChannelSensorType {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export interface IChannelSensor {
  serialNumber: string;
  location: string; // 'A' - 'P'
  reversed: boolean;
  type: ChannelSensorType;
}

export const enum ProductionExport {
  All = 'ALL',
  Surplus = 'SURPLUS'
}

export enum CircuitType {
  Outlets = 'OUTLETS',
  Lights = 'LIGHTS'
}
export const CIRCUIT_TYPES = [CircuitType.Outlets, CircuitType.Lights];
const CIRCUIT_TYPE_LABELS = {
  [CircuitType.Outlets]: T('circuitType.outlets'),
  [CircuitType.Lights]: T('circuitType.lights')
};
export function getCircuitTypeLabel(type: CircuitType): string {
  return CIRCUIT_TYPE_LABELS[type];
}

export class Channel {
  id: number;
  highLevelId: number;
  type: string;
  phase: Phase | undefined;
  ctType: CTType;
  status?: string;
  sensor?: IChannelSensor;
  export?: ProductionExport;
  circuitType?: CircuitType;
  category?: string;
  name: string | undefined;
  midBusAddress?: number;

  constructor(highLevelId: number, spec: IChannel) {
    this.id = spec.id;
    this.highLevelId = highLevelId;
    this.type = spec.type;
    this.phase = spec.phase;
    this.ctType = spec.ctType;
    this.status = spec.status;
    this.sensor = spec.sensor;
    this.export = spec.export;
    this.circuitType = spec.circuitType;
    this.category = spec.category;
    this.name = spec.name;
    this.midBusAddress = spec.midBusAddress;

    if (this.sensor) {
      if (this.sensor.location === undefined) this.sensor.location = 'A';
      if (this.sensor.reversed === undefined) this.sensor.reversed = false;
    }
  }

  updateWith(updates: IChannelUpdates): Channel {
    let sensor = this.sensor;
    if (updates.reversed !== undefined || updates.location !== undefined || updates.ctHubSerial !== undefined) {
      const changes: Partial<IChannelSensor> = {};
      if (updates.reversed !== undefined) changes.reversed = updates.reversed;
      if (updates.location !== undefined) changes.location = updates.location;
      if (updates.ctHubSerial !== undefined) {
        changes.serialNumber = updates.ctHubSerial;
      }

      sensor = Object.assign({}, sensor, changes);
    }

    const result = new Channel(this.highLevelId, {
      id: this.id,
      type: updates.type || this.type,
      phase: updates.phase || this.phase || Phase.L1,
      ctType: updates.ctType === undefined ? this.ctType : updates.ctType,
      status: updates.status || this.status,
      sensor,
      export: updates.export || this.export,
      circuitType: updates.circuitType || this.circuitType,
      category: updates.category || this.category,
      name: updates.name || this.name,
      midBusAddress: this.midBusAddress
    });
    return result;
  }

  getUpdates(): IChannelUpdateResult {
    return {
      type: this.type,
      phase: this.phase || Phase.L1,
      ctType: this.ctType,
      sensor: this.sensor,
      midBusAddress: this.midBusAddress
    };
  }
}
