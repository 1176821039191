import {
  FluviusMeasurementTemplateCode,
  FluviusMeasurementCampaignType
} from '../../../models/FluviusMeasurementCampaign';
import {Phase} from '../../../models/Phase';

export enum MeasurementCampaignVoltage {
  __unset__ = '__',
  Delta = '3X230V',
  Star = '3N400V'
}

export interface MeasurementSet {
  type: FluviusMeasurementCampaignType;
  code: string;
}

export enum TemplateSubmeterType {
  Phase1,
  Phase3,
  PhaseN
}

export interface TemplateSubmeter {
  definition: TemplateSubmeterDefinition;
  enabled: boolean;
  name: string;
  number: string;
  phase: Phase | '_';
}

export const enum TemplateName {
  Net = 'template.net',
  Net2x3f = 'template.net2x3f',
  Net4x3f = 'template.net4x3f',
  Net8x1f = 'template.net8x1f',
  Net16x1f = 'template.net16x1f',
  T3F = 'template.3f',
  T1F = 'template.1f',
  T3FNet = 'template.3fnet',
  T3FLoad = 'template.3fload'
}

export const enum SubmeterName {
  Net = 'submeter.net',
  Load1 = 'submeter.load1',
  Load3 = 'submeter.load3'
}

export interface TemplateSubmeterDefinition {
  type: TemplateSubmeterType;
  name: SubmeterName;
  main?: boolean;
}
export interface Template {
  type: FluviusMeasurementCampaignType;
  name: TemplateName;
  code: FluviusMeasurementTemplateCode;
  submeters: TemplateSubmeterDefinition[];
}
