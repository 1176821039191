import React from 'react';

import {Channel, IChannelUpdates} from '../../models/Channel';
import {IDevice} from '../../models/Device';
import {PhaseType} from '../../models/HighLevelConfiguration';

import {LoadSource} from '../../models/Load';
import {Phase} from '../../models/Phase';

import styles from './EditConfig.module.scss';

import {CTHubInputSelector} from './inputs/CTHubInputSelector';
import {CTHubSelector} from './inputs/CTHubSelector';
import {CTSelector} from './inputs/CTSelector';
import {PhaseSelector} from './inputs/PhaseSelector';
import {ReversedInput} from './inputs/ReversedInput';

interface ChannelConfiguratorProps {
  source: LoadSource;
  readOnly: boolean;
  ctHubs: IDevice[];
  phaseType: PhaseType;
  channel: Channel;
  updateChannel: (loadId: number, channelId: number, channel: IChannelUpdates) => void;
}

export const ChannelConfigurator = (props: ChannelConfiguratorProps) => {
  const {source, readOnly, phaseType, channel, updateChannel, ctHubs} = props;

  const handleSelectedUpdatePhase = (phase: string) => {
    updateChannel(channel.highLevelId, channel.id, {
      phase: phase as Phase
    });
  };

  const handleUpdateCT = (e: React.SyntheticEvent<HTMLInputElement>) => {
    updateChannel(channel.highLevelId, channel.id, {
      ctType: parseInt(e.currentTarget.value)
    });
  };

  const handleCheckedUpdateReversed = (checked: boolean) => {
    updateChannel(channel.highLevelId, channel.id, {
      reversed: checked
    });
  };

  const handleSelectedUpdateSensor = (sensor: string) => {
    updateChannel(channel.highLevelId, channel.id, {
      ctHubSerial: sensor
    });
  };

  const handleSelectedUpdateLocation = (input: string) => {
    updateChannel(channel.highLevelId, channel.id, {
      location: input
    });
  };

  const {phase, ctType, sensor} = channel;
  const device = sensor && ctHubs.find(device => device.serialNumber === sensor.serialNumber);

  return (
    <tr>
      <td>
        {source !== LoadSource.MID && (
          <CTHubSelector
            readOnly={readOnly}
            value={sensor ? sensor.serialNumber : ''}
            onChange={handleSelectedUpdateSensor}
            ctHubs={ctHubs}
          />
        )}
      </td>
      <td>
        {device ? (
          <CTHubInputSelector
            readOnly={readOnly}
            value={sensor ? sensor.location : ''}
            device={device}
            invalid={false}
            onChange={handleSelectedUpdateLocation}
          />
        ) : sensor ? (
          sensor.location
        ) : (
          ''
        )}
      </td>
      <td>
        <PhaseSelector
          readOnly={readOnly}
          value={phase || Phase.L1}
          phaseType={phaseType}
          invalid={false}
          onChange={handleSelectedUpdatePhase}
        />
      </td>
      <td>{device && <CTSelector readOnly={readOnly} value={ctType} device={device} onChange={handleUpdateCT} />}</td>
      <td className={styles.sensorReversed}>
        {source !== LoadSource.MID && (
          <ReversedInput readOnly={readOnly} sensor={sensor} onChange={handleCheckedUpdateReversed} />
        )}
      </td>
    </tr>
  );
};
