import {useMemo} from 'react';

import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {TabbedModal} from '../../../modals/TabbedModal';
import {IOrganization} from '../../../models/Organization';
import {IPricingPolicy} from '../../../models/PricingPolicy';
import {FormProvider} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';

import {AssignPricingPolicy} from './AssignPricingPolicy';
import {BasicPolicyConfig} from './BasicPolicyConfig';
import {PricingGroupsPolicyConfig} from './PricingGroupsPolicyConfig';
import {PricingPolicyProvider, usePricingPolicy} from './PricingPolicyProvider';
import {TariffsPolicyConfig} from './TariffsPolicyConfig';

interface ConfigurePricingPolicyContentProps {
  isOpen: boolean;
  close: () => void;
}
function ConfigurePricingPolicyContent(props: ConfigurePricingPolicyContentProps) {
  const {isOpen, close} = props;

  const {editingPolicy, formState, savePolicy} = usePricingPolicy();

  const tabs = useMemo(
    () => [
      {
        id: 'tariffs',
        title: T('pricingPolicies.add.tariffs'),
        render: () => <TariffsPolicyConfig />
      },
      {
        id: 'groups',
        title: T('pricingPolicies.add.groups'),
        render: () => <PricingGroupsPolicyConfig />
      },
      {
        id: 'assign',
        title: T('pricingPolicies.add.assign'),
        render: () => <AssignPricingPolicy />
      },
      {
        id: 'general',
        title: T('pricingPolicies.add.basic'),
        render: () => <BasicPolicyConfig />
      }
    ],
    []
  );

  return (
    <TabbedModal
      title={editingPolicy ? editingPolicy.name : T('pricingPolicies.add.title')}
      isOpen={isOpen}
      tabs={tabs}
      toggle={close}
      wizard={formState.creating}
      saveButtonLabel={formState.creating ? T('pricingPolicies.add.save') : T('pricingPolicies.edit.save')}
      save={savePolicy}
    />
  );
}

interface ConfigurePricingPolicyProps {
  isOpen: boolean;
  policy?: IPricingPolicy;
  organization: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  onClose: () => void;
  onSave?: (policy: IPricingPolicy) => void;
}

export function ConfigurePricingPolicy(props: ConfigurePricingPolicyProps) {
  const {isOpen, policy, onClose, onSave, organization} = props;
  return (
    <FormProvider>
      <PricingPolicyProvider policy={policy} organization={organization} onSave={onSave}>
        <ConfigurePricingPolicyContent isOpen={isOpen} close={onClose} />
      </PricingPolicyProvider>
    </FormProvider>
  );
}

interface ConfigurePricingPolicyModalProps extends IPromiseModalProps<IPricingPolicy | null> {
  policy?: IPricingPolicy;
  organization: Pick<IOrganization, 'id' | 'name' | 'currency'>;
}

export function ConfigurePricingPolicyModal(props: ConfigurePricingPolicyModalProps) {
  const [isOpen, resolve] = usePromiseModal(props);
  const {policy, organization} = props;
  return (
    <FormProvider>
      <PricingPolicyProvider policy={policy} organization={organization} onSave={resolve}>
        <ConfigurePricingPolicyContent isOpen={isOpen} close={() => resolve(null)} />
      </PricingPolicyProvider>
    </FormProvider>
  );
}
