import {YAxisOptions, AxisLabelsFormatterContextObject, FormatterCallbackFunction} from 'highcharts';
import {
  SeriesLineOptions,
  SeriesColumnOptions,
  SeriesShowCallbackFunction,
  SeriesHideCallbackFunction,
  SeriesArearangeOptions
} from 'highcharts/highstock';
import numeral from 'numeral';
import React, {useEffect, useRef, useState} from 'react';

import {ChartConfig, createIntervalChart} from '../../components/Chart';

import HighStockChart from '../../components/HighStockChart';
import {areActivePeriodsEqual, ActivePeriod} from '../../components/PeriodSelector';
import {IPersistedTableSettings, areTableColumnsEqual, getEffectiveColumns} from '../../components/Table';
import {HighOrLowLevel} from '../../models/CardSettings';
import {IConsumptionValue} from '../../models/ConsumptionValue';
import {Device} from '../../models/Device';
import {DeviceType} from '../../models/DeviceType';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {IHistoricalDataChannel, getChannelsFromLoads} from '../../models/HistoricalChannel';
import {IChildLocation} from '../../models/Location';
import {ITableField} from '../../models/Table';
import {Interval, showAsLineGraph} from '../../models/UsageValue';
import {areArraysEqual} from '../../utils/Arrays';
import {useSeriesShowHideListeners} from '../../utils/GraphUtils';
import {T} from '../../utils/Internationalization';
import {log} from '../../utils/Logging';

import {ElectricityChartData} from './ElectricityChartData';

import {ColumnBuilder, electricityColumnIds} from './ElectricityValuesColumns';
import styles from './index.module.scss';

import {IElectricityCardSettings} from './Settings';

interface ElectricityChartProps {
  deviceType: DeviceType;
  bigConsumer: boolean;
  hasSolar: boolean;
  period: ActivePeriod;
  settings: IElectricityCardSettings;
  data: ElectricityChartData;
  channels: IHistoricalDataChannel[];
  fields: ITableField<IConsumptionValue>[];
  loads: IHistoricalDataChannel[];
  hasSolarValues: boolean;
  showMinMax: boolean;
  phaseType: PhaseType;
  onShowSeries: (id: string) => void;
  onHideSeries: (id: string) => void;
  adjustRangeToActualData: boolean;
  isDualUltraAssembly: boolean;
  childs: IChildLocation[];
}

function createYAxisFormatter(decimals: number): FormatterCallbackFunction<AxisLabelsFormatterContextObject> {
  const format = `0.${'0'.repeat(decimals)}`;
  return function (this) {
    return numeral(this.value).format(format);
  };
}

const buildChartConfig = (
  deviceType: DeviceType,
  bigConsumer: boolean,
  hasSolar: boolean,
  selectedSeries: string[],
  level: HighOrLowLevel,
  table: IPersistedTableSettings,
  data: ElectricityChartData,
  fields: ITableField<IConsumptionValue>[],
  channels: IHistoricalDataChannel[],
  loads: IHistoricalDataChannel[],
  hasSolarValues: boolean,
  showMinMax: boolean,
  onShowSeries: SeriesShowCallbackFunction,
  onHideSeries: SeriesHideCallbackFunction,
  period: ActivePeriod,
  phaseType: PhaseType,
  adjustRangeToActualData: boolean,
  isDualUltraAssembly: boolean,
  childs: IChildLocation[]
): [ChartConfig, number, number] => {
  const isPro = deviceType && Device.isPro(deviceType);
  const isInfinity = (deviceType && Device.isInfinity(deviceType)) || isDualUltraAssembly;
  const hasCTs = hasSolar || isPro || isInfinity;
  const series: (SeriesLineOptions | SeriesColumnOptions | SeriesArearangeOptions)[] = [];

  const type = showAsLineGraph(period.interval) ? 'line' : 'column';
  const builder = new ColumnBuilder(data, selectedSeries, type, period, deviceType, phaseType, bigConsumer);
  const unit = builder.unit;
  const varUnit = builder.varUnit;
  const apparentUnit = builder.apparentUnit;

  // Default to low level grouping
  if (!isInfinity) level = HighOrLowLevel.Low;
  if (isDualUltraAssembly) {
    channels = getChannelsFromLoads(loads);
  }

  const isLowLevel = level === HighOrLowLevel.Low;

  // Reduce selected columns to properties
  const columns = getEffectiveColumns(table, fields);
  const visibleColumnNames = columns.filter(column => column.visible).map(column => column.name);
  const properties = new Set(visibleColumnNames);

  // Consumption
  if (properties.has(electricityColumnIds.consumption)) {
    const [consumption] = builder.createConsumptionColumn();
    series.push(consumption);
  }

  // Solar
  const [solar, solarName] = builder.createSolarColumn();
  if (properties.has(electricityColumnIds.solar)) {
    if (hasSolar || isPro || hasSolarValues) {
      series.push(solar);
    }
  }
  if (properties.has(electricityColumnIds.solarForecast)) {
    if (hasSolar || isPro || hasSolarValues) {
      const solarForecast = builder.createSolarForecastColumn();
      series.push(solarForecast);
    }
  }
  if (properties.has(electricityColumnIds.consumptionForecast)) {
    if (hasSolar || isPro || hasSolarValues) {
      const consumptionForecast = builder.createConsumptionForecastColumn();
      series.push(consumptionForecast);
    }
  }

  // Always on
  const [alwaysOn] = builder.createAlwaysOnColumn();
  if (properties.has(electricityColumnIds.alwaysOn)) series.push(alwaysOn);

  // Extra series
  if (properties.has(electricityColumnIds.import)) {
    series.push(builder.createImportColumn());
  }
  if (properties.has(electricityColumnIds.export)) {
    series.push(builder.createExportColumn());
  }
  if (properties.has(electricityColumnIds.selfSufficiency)) {
    series.push(builder.createSelfSufficiencyColumn());
  }
  if (properties.has(electricityColumnIds.selfConsumption)) {
    series.push(builder.createSelfConsumptionColumn());
  }

  if (deviceType === DeviceType.P1S1) {
    const phases: number[] = phaseType === PhaseType.Single ? [0] : [0, 1, 2];
    phases.forEach(phase => {
      if (properties.has(electricityColumnIds.lineCurrent(phase))) {
        series.push(builder.createLineCurrentColumn(phase, phaseType));
      }
    });
  }

  // Channels or loads
  if (hasCTs && Array.isArray(channels) && isLowLevel) {
    for (let channel of channels) {
      if (properties.has(electricityColumnIds.activeForChannel(channel.id))) {
        series.push(builder.createChannelActiveColumn(channel));
      }

      if (properties.has(electricityColumnIds.reactiveForChannel(channel.id))) {
        series.push(builder.createChannelReactiveColumn(channel));
      }

      if (properties.has(electricityColumnIds.currentForChannel(channel.id))) {
        series.push(builder.createChannelCurrentColumn(channel));

        const enabled = selectedSeries.includes(electricityColumnIds.currentForChannel(channel.id)) && showMinMax;
        series.push(builder.createChannelCurrentMinMaxColumn(channel, !enabled));
      }

      if (properties.has(electricityColumnIds.powerFactorForChannel(channel.id))) {
        series.push(builder.createPowerFactorColumn(channel));
      }

      if (properties.has(electricityColumnIds.apparentForChannel(channel.id))) {
        series.push(builder.createChannelApparentColumn(channel));
      }

      if (properties.has(electricityColumnIds.voltageForChannel(channel.id))) {
        series.push(builder.createChannelVoltageColumn(channel));
      }
    }
  } else if (hasCTs && Array.isArray(loads) && loads.length > 0 && !isLowLevel) {
    for (let load of loads) {
      const activeName = `${load.name} [${unit}]`;

      if (properties.has(electricityColumnIds.activeForLoad(load.id)) && activeName !== solarName) {
        series.push(builder.createLoadActiveColumn(load));
      }

      if (properties.has(electricityColumnIds.reactiveForLoad(load.id))) {
        series.push(builder.createLoadReactiveColumn(load));
      }

      if (properties.has(electricityColumnIds.currentForLoad(load.id))) {
        series.push(builder.createLoadCurrentColumn(load));
      }

      if (properties.has(electricityColumnIds.apparentForLoad(load.id))) {
        series.push(builder.createLoadApparentColumn(load));
      }
    }
  }

  // Voltages
  for (let i = 0; i < 3; i++) {
    if (properties.has(electricityColumnIds.phaseVoltage(i))) {
      series.push(builder.createPhaseVoltageColumn(i));

      const enabled = showMinMax; // && selectedSeries.includes(electricityColumnIds.phaseVoltage(i));
      series.push(builder.createPhaseVoltageMinMaxColumn(i, !enabled));
    }

    if (properties.has(electricityColumnIds.lineVoltage(i))) {
      series.push(builder.createLineVoltageColumn(i));

      const enabled = showMinMax; // && selectedSeries.includes(electricityColumnIds.lineVoltage(i));
      series.push(builder.createLineVoltageMinMaxColumn(i, !enabled));
    }
  }

  for (var child of childs) {
    for (let i = 0; i < 3; i++) {
      if (properties.has(electricityColumnIds.phaseVoltageForChild(child.id, i))) {
        series.push(builder.createPhaseVoltageColumnForChild(child, i));
      }

      if (properties.has(electricityColumnIds.lineVoltageForChild(child.id, i))) {
        series.push(builder.createLineVoltageColumnForChild(child, i));
      }
    }
  }

  let noDataText = T('electricityValues.noDataForPeriod');
  if (properties.size === 0) {
    noDataText = T('electricityValues.noSeriesSelected');
  }
  const yAxis: YAxisOptions[] = [
    {
      title: {text: unit},
      opposite: false,
      showEmpty: false,
      startOnTick: false
    },
    {
      title: {text: varUnit},
      opposite: true,
      showEmpty: false
    },
    {
      title: {text: 'V'},
      opposite: true,
      showEmpty: false,
      labels: {formatter: createYAxisFormatter(1)}
    },
    {
      title: {text: 'A'},
      opposite: true,
      showEmpty: false,
      min: 0,
      labels: {formatter: createYAxisFormatter(1)}
    },
    {
      title: {text: '%'},
      opposite: true,
      showEmpty: false,
      labels: {formatter: createYAxisFormatter(2)}
    },
    {
      title: {text: 'PF'},
      opposite: true,
      showEmpty: false,
      labels: {formatter: createYAxisFormatter(2)}
    },
    {
      title: {text: apparentUnit},
      opposite: true,
      showEmpty: false
    }
  ];
  // NOTE: in past I attempted to for the Y axis by setting y axis bounds explicitly.
  // Don't do it - it breaks automatic Y axis scaling depending on the range of data selected

  const config = createIntervalChart({
    period,
    series,
    yAxis,
    noDataText,
    onShowSeries,
    onHideSeries,
    adjustRangeToActualData
  });
  return config;
};

const ElectricityChart = (props: ElectricityChartProps) => {
  const {
    deviceType,
    bigConsumer,
    hasSolar,
    period,
    settings,
    data,
    fields,
    channels,
    loads,
    hasSolarValues,
    showMinMax,
    onShowSeries,
    onHideSeries,
    phaseType,
    adjustRangeToActualData,
    isDualUltraAssembly,
    childs
  } = props;
  const {table, series, level} = settings;
  const [handleShowSeries, handleHideSeries] = useSeriesShowHideListeners(series, onShowSeries, onHideSeries);

  const [config, actualFrom, actualTo] = buildChartConfig(
    deviceType,
    bigConsumer,
    hasSolar,
    series,
    level,
    table,
    data,
    fields,
    channels,
    loads,
    hasSolarValues,
    showMinMax,
    handleShowSeries,
    handleHideSeries,
    period,
    phaseType,
    adjustRangeToActualData,
    isDualUltraAssembly,
    childs
  );
  return (
    <HighStockChart
      from={actualFrom}
      to={actualTo}
      className={styles.stretch}
      config={config}
      adjustRangeToActualData={adjustRangeToActualData}
    />
  );
};

function getChangedField(
  propsA: ElectricityChartProps,
  propsB: ElectricityChartProps
): keyof ElectricityChartProps | undefined {
  if (propsA.deviceType !== propsB.deviceType) return 'deviceType';
  if (propsA.bigConsumer !== propsB.bigConsumer) return 'bigConsumer';
  if (propsA.hasSolar !== propsB.hasSolar) return 'hasSolar';
  if (!areActivePeriodsEqual(propsA.period, propsB.period)) return 'period';
  if (!areTableColumnsEqual(propsA.settings.table.columns, propsB.settings.table.columns)) {
    return 'settings';
  }
  if (!areArraysEqual(propsA.fields, propsB.fields)) return 'fields';
  if (propsA.data !== propsB.data) return 'data';
  if (propsA.channels !== propsB.channels) return 'channels';
  if (propsA.loads !== propsB.loads) return 'loads';
  if (propsA.hasSolarValues !== propsB.hasSolarValues) return 'hasSolarValues';
  if (propsA.showMinMax !== propsB.showMinMax) return 'showMinMax';
  if (propsA.onShowSeries !== propsB.onShowSeries) return 'onShowSeries';
  if (propsA.onHideSeries !== propsB.onHideSeries) return 'onHideSeries';
  if (propsA.adjustRangeToActualData !== propsB.adjustRangeToActualData) {
    return 'adjustRangeToActualData';
  }
  if (propsA.childs !== propsB.childs) return 'childs';

  return undefined;
}

const DebugMode = false;
export const ElectricityChartWrapper = (props: ElectricityChartProps) => {
  const lastValue = useRef<ElectricityChartProps>();
  const [chart, setChart] = useState(<div />);
  useEffect(() => {
    if (lastValue.current !== undefined) {
      const changed = getChangedField(lastValue.current, props);
      if (changed === undefined) return;

      if (DebugMode) {
        log('redraw', `${changed} changed`, lastValue.current[changed], props[changed]);
      }
    }

    setChart(<ElectricityChart {...props} />);
    lastValue.current = props;
  }, [props]);
  return chart;
};
