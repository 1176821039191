export const testingClasses = {
  active: 't-active',
  board: 't-board',
  cardWrapper: 't-card-wrapper',
  card: 't-card',
  cardActions: 't-card-actions',
  cardLoading: 't-card-loading',
  cardTitle: 't-card-title',
  cardSubtitle: 't-card-subtitle',
  cardTitleInput: 't-card-title-input',
  cardRemove: 't-card-remove',
  cardSettingsHandle: 't-card-settings-handle',
  cardLockedLocationTitle: 't-card-lockedlocation-title',
  cardTypeInput: 't-cardtype-input',
  boardRemove: 't-board-remove',
  rangeSelector: 't-range-selector',
  rangeSelectorButton: 't-range-selector-button',
  rangeSelectorPeriod: 't-range-selector-period',
  rangeSelectorInterval: 't-range-selector-interval',
  rangeSelectorFrom: 't-range-selector-from',
  rangeSelectorTo: 't-range-selector-to',
  rangeSelectorApply: 't-range-selector-apply',
  refresh: 't-refresh',
  download: 't-download',
  search: 't-search',
  create: 't-create',
  searchUsers: 't-search-users',
  createUser: 't-create-user',
  addCardCentral: 't-add-card-central',
  errorMessage: 't-error-message',
  cardError: 't-card-error',
  boardLoading: 't-board-loading',
  loadRow: 't-load-row',
  loadCTsRow: 't-load-cts-row',
  addButton: 't-add-button',
  confirmButton: 't-confirm-button',
  saveButton: 't-save-button',
  commitButton: 't-commit-button',
  itemsPerPage: 't-items-per-page',
  loading: 't-loading',
  styledButton: 't-styled-button',
  remove: 't-remove',
  moduleDetails: 't-module-details',
  forceRemove: 't-force-remove'
};

export const testingIds = {
  navigation: 'tid-navigation',
  locationMenu: 'tid-location-menu',
  addCard: 'tid-add-card',
  boardSettings: 'tid-board-settings',
  organizeCards: 'tid-organize-cards',
  endOrganizeCards: 'tid-end-organize-cards',
  addBoard: 'tid-add-board',
  presentationModeButton: 'tid-presentation-mode-button',
  locationName: 'tid-location-name',
  recentMessages: 'tid-recent-messages',
  userMenu: 'tid-user-menu',
  userMenuLogout: 'tid-user-menu-logout',
  loginError: 'tid-login-error'
};
