import {useCallback} from 'react';

import {Modal, ModalBody, ModalHeader} from '../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {CustomApp} from '../../models/CustomApp';

interface SecretModalProps extends IPromiseModalProps<void> {
  app: CustomApp;
}

export default function SecretModal(props: SecretModalProps) {
  const {app} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = useCallback(() => resolve(), [resolve]);

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="md" autoFocus={false}>
      <ModalHeader toggle={handleClose}>View API secret</ModalHeader>
      <ModalBody>
        The API secret for {app.appName} is <code>{app.clientSecret}</code>
      </ModalBody>
    </Modal>
  );
}
