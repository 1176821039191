import type {SVGProps} from 'react';

const SvgPlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    fillRule="evenodd"
    {...props}
  >
    <path d="M12 1c-3.704 0-6.277.12-7.87.232-1.526.107-2.743 1.261-2.883 2.81-.124 1.368-.246 3.452-.246 6.354s.122 4.986.246 6.354c.14 1.549 1.357 2.703 2.883 2.81.758.053 1.738.108 2.956.151v2.113c0 1.003 1.173 1.545 1.932.892l3.399-2.924c3.482-.008 5.921-.124 7.454-.231 1.526-.107 2.743-1.261 2.883-2.81.124-1.368.246-3.452.246-6.354s-.122-4.986-.246-6.354c-.14-1.549-1.357-2.703-2.883-2.81-1.593-.112-4.166-.232-7.87-.232Zm3.31 6.578a.934.934 0 0 0-1.324 0L12 9.571l-1.986-1.993a.934.934 0 0 0-1.324 0 .942.942 0 0 0 0 1.329l1.986 1.993-1.986 1.993a.942.942 0 0 0 0 1.329.934.934 0 0 0 1.324 0L12 12.229l1.986 1.993a.934.934 0 0 0 1.324 0 .942.942 0 0 0 0-1.329L13.324 10.9l1.986-1.993a.942.942 0 0 0 0-1.329Z" />
  </svg>
);
export default SvgPlaceholder;
