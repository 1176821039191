import {IGasWaterReading} from '../../models/GasWaterReading';
import {IGasWaterDevice, getChannelTypeLabel, ILocation} from '../../models/Location';
import {CalculatedNumberField, ITableField, NumberField, TimestampFieldWithTimezone} from '../../models/Table';
import {getUnitLabel} from '../../models/UsageValue';
import {T} from '../../utils/Internationalization';

export function getTableColumns(location?: ILocation, sensor?: IGasWaterDevice): ITableField<IGasWaterReading>[] {
  if (!location) return [];

  const result: ITableField<IGasWaterReading>[] = [
    new TimestampFieldWithTimezone('time', 'time', T('gasOrWaterCard.field.timestamp'), location.timeZoneId, {
      alwaysVisible: true,
      autoInsert: 'start'
    }),
    new NumberField('hum', T('gasOrWaterCard.field.humidity'), {
      unit: '%',
      digitsAfterComma: 0
    }),
    new CalculatedNumberField('tmp', T('gasOrWaterCard.field.temperature'), item => item.tmp / 10, {
      unit: '°C',
      digitsAfterComma: 1
    }),
    new NumberField('bat', T('gasOrWaterCard.field.battery'), {
      unit: '%',
      digitsAfterComma: 0
    })
  ];

  if (sensor) {
    const channel1 = sensor.inputChannels.find(channel => channel.id === 1);
    const channel2 = sensor.inputChannels.find(channel => channel.id === 2);
    if (channel1 && channel1.enabled) {
      const name = getChannelTypeLabel(channel1.type);
      const unit = getUnitLabel(channel1.unit);
      result.push(new CalculatedNumberField('val1', name, item => item.val1 / channel1.pulsesPerUnit, {unit}));
    }
    if (channel2 && channel2.enabled) {
      const name = getChannelTypeLabel(channel2.type);
      const unit = getUnitLabel(channel2.unit);
      result.push(new CalculatedNumberField('val2', name, item => item.val2 / channel2.pulsesPerUnit, {unit}));
    }
  }
  return result;
}
