export const enum ChargingStationModel {
  BaseCable = 'BASE',
  BaseSocket = 'BASE_SOCKET',
  BaseSingleCable = 'BASE_SINGLE_CABLE',
  BaseSingleSocket = 'BASE_SINGLE_SOCKET',
  ThirdParty = 'THIRD_PARTY',
  WallBusinessCable = 'WALL',
  WallBusinessSocket = 'WALL_SOCKET',
  WallHomeCable = 'WALL_HOME_CABLE',
  WallHomeSocket = 'WALL_HOME_SOCKET',
  WallLiteSocket = 'WALL_LITE_SOCKET',
  UltraSingleCable = 'ULTRA_SINGLE_CABLE',
  UltraDualCable = 'ULTRA_DUAL_CABLE',
  OneLiteSocket = 'ONE_LITE_SOCKET'
}

export function isWallHome(model: ChargingStationModel) {
  return model === ChargingStationModel.WallHomeCable || model === ChargingStationModel.WallHomeSocket;
}
