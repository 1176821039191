import {AnyAppAction} from '../actions';
import {BoardsActions} from '../actions/boards';
import {IBoardsState, BoardsState} from '../AppState';

const initialState = {
  state: BoardsState.NOT_YET_LOADED,
  boards: []
};

export default function boards(state: IBoardsState = initialState, action: AnyAppAction): IBoardsState {
  switch (action.type) {
    case BoardsActions.FETCHING:
      return {...state, state: BoardsState.FETCHING};

    case BoardsActions.LOAD_SUCCESS:
      return {...state, state: BoardsState.LOADED, boards: action.payload};

    case BoardsActions.LOAD_ERROR:
      return state;

    case BoardsActions.UPDATE_BOARD: {
      const board = action.payload;
      return {
        ...state,
        boards: state.boards.map(b => (b.id === board.id ? board : b))
      };
    }

    case BoardsActions.UpdateCardSettings: {
      const {boardId, cardId, settings} = action.payload;
      const board = state.boards.find(b => b.id === boardId);
      if (!board) return state;

      const updatedCards = board.cards.map(card => {
        if (card.id !== cardId) return card;

        const updatedSettings = {...card.settings, ...settings};
        return {...card, settings: updatedSettings};
      });
      const updatedBoard = {...board, cards: updatedCards};
      return {
        ...state,
        boards: state.boards.map(b => (b.id === board.id ? updatedBoard : b))
      };
    }

    case BoardsActions.INITIALIZING_BOARDS:
      return {
        ...state,
        state: BoardsState.INITIALIZING,
        initializingBoard: action.payload.name
      };

    default:
      return state;
  }
}
