import dayjs from 'dayjs';
import React from 'react';

import {RowActionButton} from '../../components/bootstrap/RowActions';
import {FORMAT_EXCEL} from '../../core/constants';
import {
  Automation,
  translateAutomationType,
  AutomationType,
  DayOfWeek,
  AutomationActionType
} from '../../models/Automation';
import {ITableField, StringField, CalculatedStringField, ComponentField} from '../../models/Table';
import {T} from '../../utils/Internationalization';

import {mergeActionsSimple, ISimpleMultiTargetAction} from './edit/MultiTargetAction';

const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 4;
const THURSDAY = 8;
const FRIDAY = 16;
const SATURDAY = 32;
const SUNDAY = 64;
const HOLIDAYS = 128;

const WEEKDAYS = MONDAY + TUESDAY + WEDNESDAY + THURSDAY + FRIDAY;
const WEEKEND = SATURDAY + SUNDAY;
const ALWAYS = WEEKDAYS + WEEKEND;

function getDaysOfWeek(days: DayOfWeek[]) {
  let mask = 0;
  for (let day of days) {
    switch (day) {
      case DayOfWeek.Monday:
        mask |= MONDAY;
        break;
      case DayOfWeek.Tuesday:
        mask |= TUESDAY;
        break;
      case DayOfWeek.Wednesday:
        mask |= WEDNESDAY;
        break;
      case DayOfWeek.Thursday:
        mask |= THURSDAY;
        break;
      case DayOfWeek.Friday:
        mask |= FRIDAY;
        break;
      case DayOfWeek.Saturday:
        mask |= SATURDAY;
        break;
      case DayOfWeek.Sunday:
        mask |= SUNDAY;
        break;
      case DayOfWeek.Holiday:
        mask |= HOLIDAYS;
        break;
    }
  }

  if ((mask & ALWAYS) === ALWAYS) return T('dayOfWeek.all');

  const result: string[] = [];
  if ((mask & WEEKDAYS) === WEEKDAYS) {
    mask &= ~WEEKDAYS;
    result.push(T('dayOfWeek.weekdays'));
  }
  if ((mask & WEEKEND) === WEEKEND) {
    mask &= ~WEEKEND;
    result.push(T('dayOfWeek.weekend'));
  }
  if ((mask & MONDAY) === MONDAY) {
    mask &= ~MONDAY;
    result.push(T('dayOfWeek.monday'));
  }
  if ((mask & TUESDAY) === TUESDAY) {
    mask &= ~TUESDAY;
    result.push(T('dayOfWeek.tuesday'));
  }
  if ((mask & WEDNESDAY) === WEDNESDAY) {
    mask &= ~WEDNESDAY;
    result.push(T('dayOfWeek.wednesday'));
  }
  if ((mask & THURSDAY) === THURSDAY) {
    mask &= ~THURSDAY;
    result.push(T('dayOfWeek.thursday'));
  }
  if ((mask & FRIDAY) === FRIDAY) {
    mask &= ~FRIDAY;
    result.push(T('dayOfWeek.friday'));
  }
  if ((mask & SATURDAY) === SATURDAY) {
    mask &= ~SATURDAY;
    result.push(T('dayOfWeek.saturday'));
  }
  if ((mask & SUNDAY) === SUNDAY) {
    mask &= ~SUNDAY;
    result.push(T('dayOfWeek.sunday'));
  }
  if ((mask & HOLIDAYS) === HOLIDAYS) {
    mask &= ~HOLIDAYS;
    result.push(T('dayOfWeek.holidays'));
  }

  return result.join(', ');
}

interface IAutomationsListeners {
  onClickedEdit: (automation: Automation) => void;
  onClickedRemove: (automation: Automation) => void;
}

function renderActionColumn(automation: Automation, listeners: IAutomationsListeners, readOnly: boolean) {
  const {onClickedEdit, onClickedRemove} = listeners;

  return (
    <>
      {!readOnly && <RowActionButton title="Edit" icon="Pencil" onClick={() => onClickedEdit(automation)} />}
      {!readOnly && <RowActionButton action="delete" title="Delete" onClick={() => onClickedRemove(automation)} />}
    </>
  );
}

function describeAction(multiAction: ISimpleMultiTargetAction) {
  const suffix = multiAction.targets.length === 0 ? '' : ` (${multiAction.targets.length})`;
  const action = multiAction.action;
  switch (action.type) {
    case AutomationActionType.Smart:
      return action.smart.displayName + suffix;
    case AutomationActionType.State:
      return action.stateful.displayValue + suffix;
    case AutomationActionType.Switch:
      return action.switchable.displayValue + suffix;
    default:
      return '?';
  }
}

function describeActions(automation: Automation) {
  const grouped = mergeActionsSimple(automation);
  const descriptions: string[] = [];
  for (let action of grouped) {
    descriptions.push(describeAction(action));
  }
  return descriptions.join(', ');
}

export function getAutomationsColumns(listeners: IAutomationsListeners, readOnly: boolean): ITableField<Automation>[] {
  return [
    new StringField('name', T('automation.name')),
    new CalculatedStringField('trigger', T('automation.trigger'), item => {
      let result = translateAutomationType(item.type);
      if (item.type === AutomationType.Schedule) {
        result += `: ${getDaysOfWeek(item.schedule.days)}`;
        result += `, ${item.schedule.hours.toString().padStart(2, '0')}:${item.schedule.minutes
          .toString()
          .padStart(2, '0')}`;
      }
      return result;
    }),
    new CalculatedStringField('actions', T('automation.actions'), item => {
      return describeActions(item);
    }),
    new CalculatedStringField('lastExecutionUTCStamp', T('automation.lastExecution'), item => {
      if (item.lastExecutionUTCStamp === undefined) return undefined;

      return dayjs.utc(item.lastExecutionUTCStamp).local().format(FORMAT_EXCEL);
    }),
    new CalculatedStringField('nextExecutionUTCStamp', T('automation.nextExecution'), item => {
      if (item.lastExecutionUTCStamp === undefined) return undefined;

      return dayjs.utc(item.nextExecutionUTCStamp).local().format(FORMAT_EXCEL);
    }),
    new ComponentField('useractions', T('automation.useractions'), item => {
      return renderActionColumn(item, listeners, readOnly);
    })
  ];
}
