import React, {useEffect, useState} from 'react';

import {Modal, ModalBody, ModalHeader} from '../../components/bootstrap';
import {IPersistedTableSettings} from '../../components/Table';
import {usePromiseModal, IPromiseModalProps} from '../../modals/PromiseModal';
import {IAppliance} from '../../models/Appliance';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ITableField} from '../../models/Table';
import {T, plural} from '../../utils/Internationalization';
import {ApplianceList, getApplianceListTableColumns} from '../Appliances/ApplianceList';
import {useUser, useCardLocation} from '../CardUtils';

interface ApplianceListModalProps extends IPromiseModalProps<void> {
  appliances: IAppliance[];
  settings: ICardSettingsWithTable;
  onTableSettingsChanged: (table: IPersistedTableSettings) => void;
}

export function ApplianceListModal(props: ApplianceListModalProps) {
  const {fetch, appliances, settings, onTableSettingsChanged} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const location = useCardLocation(settings);

  const handleToggle = () => resolve();
  const me = useUser();

  const [fields, setFields] = useState<ITableField<IAppliance>[]>([]);
  const id = location && location.id;
  const timezone = location && location.timeZoneId;
  const electricityCurrency = (location && location.electricityCurrency) || 'EUR';

  useEffect(() => {
    fetch('applianceTypes', api => api.getApplianceTypes(), plural('applianceType')).then(applianceTypes => {
      setFields(getApplianceListTableColumns(applianceTypes, electricityCurrency, timezone, me));
    });
  }, [fetch, electricityCurrency, timezone, me, id]);

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} size="xl" autoFocus={false}>
      <ModalHeader toggle={handleToggle}>{T('appliancesMini.applianceList')}</ModalHeader>
      <ModalBody>
        <ApplianceList
          appliances={appliances}
          fields={fields}
          settings={settings}
          onTableSettingsChanged={onTableSettingsChanged}
        />
      </ModalBody>
    </Modal>
  );
}
