import dayjs from 'dayjs';
import React from 'react';

import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {Device} from '../../models/Device';
import {
  getFriendlyWorkingMode,
  getFriendlyInputsConnected,
  getFriendlyPhaseReverser,
  getFriendlyPhaseMapping
} from '../../models/DeviceConfiguration';
import {
  useHistoricalDeviceInfo,
  useLegacyDeviceQuestions,
  useLegacyDeviceConfiguration
} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardView, cardViewProps} from '../components/CardView';

import styles from './index.module.scss';

type DeviceConfigurationSettings = ICardSettings;

const DeviceConfiguration = (props: ICardProps<DeviceConfigurationSettings>) => {
  const {fetch, settings} = props;
  const location = useCardLocation(settings);
  const {serialNumber = undefined, type = undefined} = useHistoricalDeviceInfo(fetch, location) || {};

  const questions = useLegacyDeviceQuestions(fetch, location && location.id) as any;
  const configuration = useLegacyDeviceConfiguration(fetch, serialNumber);

  let error: string | undefined;
  if (serialNumber === undefined || type === undefined) {
    error = T('deviceConfigurationLegacy.notInstalled');
  } else if (!Device.isLegacy(type)) {
    error = T('deviceConfigurationLegacy.notAvailableForDeviceType');
  } else if (configuration && !configuration.actual) {
    error = T('deviceConfigurationLegacy.noData');
  }

  let configurationView: JSX.Element | null = null;
  if (configuration && configuration.actual) {
    const actual = configuration.actual;
    const pending = configuration.pending;

    let autoCommissioning = actual.autoCommissioningAction.toString();
    let acValidationRunning = T.generic.yesNo(actual.isACValidationRunning);
    let acInstantRunning = T.generic.yesNo(actual.isACInstantRunning);
    let is60Hz = T.generic.yesNo(actual.is60Hz);
    let isLoad = actual.isLoad ? T('deviceConfigurationLegacy.load') : T('deviceConfigurationLegacy.grid');
    let isDelta = actual.isDelta ? T('configuration.delta') : T('configuration.star');
    let monitorMode = getFriendlyWorkingMode(actual.monitorMode) || '';
    let monitorWorkingMode = getFriendlyWorkingMode(actual.monitorWorkingMode) || '';
    let phaseMapping = getFriendlyPhaseMapping(actual.phaseMapping) || '';
    let phaseReverser = getFriendlyPhaseReverser(actual.phaseReverser) || '';
    let inputsConnected =
      actual.inputsConnected === 0 ? T('generic.none') : getFriendlyInputsConnected(actual.inputsConnected) || '';
    let singleVoltageShift = actual.singleVoltageShift.toString();

    if (pending) {
      if (pending.autoCommissioningAction !== actual.autoCommissioningAction) {
        autoCommissioning += ` <b>=&gt; ${pending.autoCommissioningAction}</b>`;
      }
      if (pending.isACValidationRunning !== actual.isACValidationRunning) {
        acValidationRunning += ` <b>=&gt; ${T.generic.yesNo(pending.isACValidationRunning)}</b>`;
      }
      if (pending.isACInstantRunning !== actual.isACInstantRunning) {
        acInstantRunning += ` <b>=&gt; ${T.generic.yesNo(pending.isACInstantRunning)}</b>`;
      }
      if (pending.is60Hz !== actual.is60Hz) {
        is60Hz += ` <b>=&gt ${T.generic.yesNo(pending.is60Hz)}</b>`;
      }
      if (pending.isLoad !== actual.isLoad) {
        isLoad += ` <b>=&gt; ${
          pending.isLoad ? T('deviceConfigurationLegacy.load') : T('deviceConfigurationLegacy.grid')
        }</b>`;
      }
      if (pending.isDelta !== actual.isDelta) {
        isDelta += ` <b>=&gt; ${pending.isDelta ? T('configuration.delta') : T('configuration.star')}</b>`;
      }
      if (pending.monitorMode !== actual.monitorMode) {
        monitorMode += ` <b>=&gt; ${getFriendlyWorkingMode(pending.monitorMode)}</b>`;
      }
      if (pending.monitorWorkingMode !== actual.monitorWorkingMode) {
        monitorWorkingMode += ` <b>=&gt; ${
          pending.monitorWorkingMode === undefined ? T.generic.na() : getFriendlyWorkingMode(pending.monitorWorkingMode)
        }</b>`;
      }
      if (pending.phaseMapping !== actual.phaseMapping) {
        phaseMapping += ` <b>=&gt; ${getFriendlyPhaseMapping(pending.phaseMapping)}</b>`;
      }
      if (pending.phaseReverser !== actual.phaseReverser) {
        phaseReverser += ` <b>=&gt; ${getFriendlyPhaseReverser(pending.phaseReverser)}</b>`;
      }
      if (pending.inputsConnected !== actual.inputsConnected) {
        inputsConnected += ` <b>=&gt; ${
          pending.inputsConnected === 0 ? T('generic.none') : getFriendlyInputsConnected(pending.inputsConnected)
        }</b>`;
      }
      if (pending.singleVoltageShift !== actual.singleVoltageShift) {
        singleVoltageShift += ` <b>=&gt; ${pending.singleVoltageShift}</b>`;
      }
    }

    configurationView = (
      <dl className="row">
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.timestamp')}</dt>
        <dd className="col-sm-7">{dayjs(actual.createdUtcStamp).format('DD-MM-YY hh:mm')}</dd>
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.autocommissioning')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: autoCommissioning}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.acValidation')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: acValidationRunning}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.acInstantRunning')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: acInstantRunning}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.is60Hz')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: is60Hz}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.isLoad')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: isLoad}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.isDelta')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: isDelta}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.monitorMode')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: monitorMode}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.monitorWorkingMode')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: monitorWorkingMode}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.phaseMapping')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: phaseMapping}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.phaseReversal')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: phaseReverser}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.inputsConnected')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: inputsConnected}} />
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.singleVoltageShift')}</dt>
        <dd className="col-sm-7" dangerouslySetInnerHTML={{__html: singleVoltageShift}} />
      </dl>
    );
  }

  let questionsView: JSX.Element[] = [];
  if (questions) {
    questionsView = [
      <h2
        key="head"
        style={{
          fontSize: '1.5em',
          margin: '0.67em 0',
          textAlign: 'center',
          marginRight: '20%'
        }}
      >
        {T('deviceConfigurationLegacy.userQuestions')}
      </h2>,
      <dl key="body" className="row">
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.lastUpdated')}</dt>
        <dd className="col-sm-7">{dayjs(questions.updatedUtcStamp).format('DD-MM-YY hh:mm')}</dd>
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.isSolar')}</dt>
        <dd className="col-sm-7">{T.generic.yesNo(questions.isSolar)}</dd>
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.phase')}</dt>
        <dd className="col-sm-7">{questions.phase || T.generic.na()}</dd>
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.deltaOrStar')}</dt>
        <dd className="col-sm-7">
          {'isDelta' in questions
            ? questions.isDelta
              ? T('configuration.delta')
              : T('configuration.star')
            : T.generic.na()}
        </dd>
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.loadOrGrid')}</dt>
        <dd className="col-sm-7">
          {'isLoad' in questions
            ? questions.isLoad
              ? T('deviceConfigurationLegacy.load')
              : T('deviceConfigurationLegacy.grid')
            : T.generic.na()}
        </dd>
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.export')}</dt>
        <dd className="col-sm-7">{'isExport' in questions ? T.generic.yesNo(questions.isExport) : T.generic.na()}</dd>
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.hasSolarMeter')}</dt>
        <dd className="col-sm-7">
          {'hasSolarMeter' in questions ? T.generic.yesNo(questions.hasSolarMeter) : T.generic.na()}
        </dd>
        <dt className="col-sm-5">{T('deviceConfigurationLegacy.numberOfDials')}</dt>
        <dd className="col-sm-7">{'numberDials' in questions ? questions.numberDials : T.generic.na()}</dd>
      </dl>
    ];
  }

  return (
    <CardView error={error} {...cardViewProps(props)}>
      <div className={styles.configurationInfo}>
        {configurationView}
        {questionsView}
      </div>
    </CardView>
  );
};

const CARD: ICardType<DeviceConfigurationSettings> = {
  type: CardTypeKey.DeviceConfigurationLegacy,
  title: 'deviceConfigurationLegacy.title',
  description: 'deviceConfigurationLegacy.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: DeviceConfiguration
};
export default CARD;
