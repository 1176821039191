export function getPowerFactor(
  active: number | null | undefined,
  reactive: number | null | undefined
): number | undefined {
  if (
    active === undefined ||
    active === null ||
    reactive === undefined ||
    reactive === null ||
    (active === 0 && reactive === 0)
  ) {
    return undefined;
  }

  return Math.abs(active / Math.sqrt(active * active + reactive * reactive));
}
