import {T} from '../utils/Internationalization';

import {IUser} from './User';

export const enum Contract {
  SplitBilling = 'SPLIT_BILLING',
  SEPA = 'SEPA',
  eMSPBusiness = 'EMSP_BUSINESS',
  eMSPPersonal = 'EMSP_PERSONAL',
  ChargingHost = 'CHARGING_HOST'
}

export function translateContract(contract: Contract): string {
  switch (contract) {
    case Contract.SEPA:
      return T('organizations.contracts.sepa');
    case Contract.SplitBilling:
      return T('organizations.contracts.splitBilling');
    case Contract.ChargingHost:
      return T('organizations.contracts.chargingHost');
    case Contract.eMSPBusiness:
      return T('organizations.contracts.emspBusiness');
    case Contract.eMSPPersonal:
      return T('organizations.contracts.emspPersonal');
    default:
      return contract;
  }
}

export function translateContractVersion(contract: Contract, version: number): string {
  switch (contract) {
    case Contract.ChargingHost:
      switch (version) {
        case 0:
          return 'Scan & Charge free';
        case 1:
          return 'Scan & Charge paid';
        default:
          return 'invalid';
      }
    default:
      return version === 0 ? '' : 'invalid';
  }
}

export function getContractVersions(contract: Contract): number[] {
  if (contract === Contract.ChargingHost) {
    return [0, 1];
  } else {
    return [0];
  }
}

export interface ContractInfo {
  contract: Contract;
  signedAt: number;
}

export interface ContractDetails {
  id: number;
  contract: Contract;
  signedAt: number;
  version: number;
  billedUntil?: string;
  closed?: number;
  notes?: string;
  customerInOdoo: boolean;
  organization?: {
    id: number;
    name: string;
  };
  signedBy: IUser;
}
