import dayjs from 'dayjs';
import * as React from 'react';

enum DateFormatPreset {
  LocaleTime = 'LT',
  LocaleTimeWithSeconds = 'LTS',
  LocaleDate = 'L',
  LocaleDateWithMonth = 'LL',
  LocaleDateWithMonthAndTime = 'LLL',
  LocaleDateWithMonthAndTimeWithTime = 'L LTS',
  LocaleDateWithMonthAndTimeWithDay = 'LLLL',
  DayMonthYear = 'DD-MM-YYYY',
  MonthDayYear = 'MM-DD-YYYY',
  YearMonthDay = 'YYYY-MM-DD',
  YearDayMonth = 'YYYY-DD-MM',
  Month = 'MM',
  Day = 'DD',
  Year = 'YYYY',
  YearMonthDayWithTime = 'YYYY-MM-DD HH:mm'
}

interface DateFormatterConfigProps {
  timezoneId?: string;
  format?: DateFormatPreset | string;
}

type DateFormat = string | number | Date;

const initialConfig: DateFormatterConfigProps = {
  timezoneId: 'UTC',
  format: DateFormatPreset.LocaleDateWithMonthAndTime
};

function formatDate(value: DateFormat, customConfig: DateFormatterConfigProps = initialConfig) {
  const config = {...initialConfig, ...customConfig};

  return dayjs(value).tz(config.timezoneId).format(config.format);
}

function DateFormatter({
  value,
  config: customConfig = initialConfig,
  ...props
}: JSX.IntrinsicElements['time'] & {
  value: DateFormat;
  config: DateFormatterConfigProps;
}) {
  const config = {...initialConfig, ...customConfig};

  const formattedDate = formatDate(value, config);
  const dateTime = formatDate(value, {...config, format: DateFormatPreset.YearMonthDayWithTime});

  return (
    <time dateTime={dateTime} {...props}>
      {formattedDate}
    </time>
  );
}

export {DateFormatPreset, DateFormatter, formatDate};
