import React from 'react';

import {Button} from '../../components/ui/button';
import {cn} from '../../lib/utils';
import {T} from '../../utils/Internationalization';

import styles from './index.module.scss';

export interface RestartButtonProps {
  isRestarting: boolean;
  onClick: () => void;
}
export const RestartButton = (props: RestartButtonProps) => {
  const {isRestarting, onClick} = props;
  return (
    <Button
      size="lg"
      variant="secondary_default"
      title={isRestarting ? T('firmware.restarting') : T('firmware.restart')}
      className={cn(styles.actionsRestart, 'tw-py-[0.5rem]')}
      onClick={onClick}
      disabled={isRestarting}
    >
      {isRestarting ? T('firmware.restarting') : T('firmware.restart')}
    </Button>
  );
};
