import type {SVGProps} from 'react';
const SvgReport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    fillRule="evenodd"
    {...props}
  >
    <path d="M3.69 1.69A2.36 2.36 0 0 1 5.357 1h10.214c.209 0 .409.083.556.23l5.64 5.5c.287.147.37.347.23.556v13.357c.14.625-.248 1.225-.69 1.667a2.36 2.36 0 0 1-1.667.69H5.357A2.357 2.357 0 0 1 3 20.643V3.357c0-.625.248-1.225.69-1.667m13.362 6.29a.982.982 0 0 0-1.39-1.389L13.215 9.04l-1.662-1.663a.98.98 0 0 0-1.39 0L7.807 9.734a.982.982 0 1 0 1.388 1.389l1.663-1.663 1.663 1.663a.98.98 0 0 0 1.389 0l3.143-3.143Zm-.695 5.395H8.5a.982.982 0 1 0 0 1.964h7.857a.982.982 0 1 0 0-1.964M8.5 17.304h7.857a.982.982 0 1 1 0 1.964H8.5a.982.982 0 1 1 0-1.964" />
  </svg>
);
export default SvgReport;
