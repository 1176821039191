import React, {useState, useEffect, useCallback} from 'react';

import {InputGroup, InputGroupAddon} from 'reactstrap';

import {useFormContext} from '../../utils/FormContext';
import {FormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {Hotkeys} from '../../utils/Keys';
import {FieldValidator} from '../../utils/Validation';
import {Input} from '../bootstrap';

import FormInputGroup from './FormInputGroup';

interface TextInputGroupProps {
  form?: FormState;
  name: string;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  type?: 'text' | 'number' | 'password' | 'textarea' | 'time';
  min?: number;
  max?: number;
  validate?: FieldValidator;
  effect?: (form: FormState, value: string, valid: boolean) => void;
  autoFocus?: boolean;
  suffix?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  onBlur?: () => void;
  info?: string;
  invalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
  error?: string;
  className?: string;
}
export const TextInputGroup = (props: TextInputGroupProps) => {
  const {
    name,
    label,
    value,
    onChange,
    onBlur,
    readOnly,
    type = 'text',
    min,
    max,
    validate,
    effect,
    autoFocus,
    onKeyPress,
    onEnter,
    suffix,
    info,
    invalid,
    placeholder,
    disabled = false,
    optional = false,
    error: customError,
    className
  } = props;

  const form = useFormContext();
  const error = validate && validate(value, label ?? '', optional);
  form.setError(name, error);
  const actualInfo = info || (optional ? T('validatedInput.optional') : undefined);

  const actualOnKeyPress = onEnter
    ? (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === Hotkeys.ENTER) {
          if (form.hasErrors()) {
            form.setErrorVisible(name, error !== undefined);
            return;
          }
          onEnter();
        } else {
          onKeyPress && onKeyPress(e);
        }
      }
    : onKeyPress;

  useEffect(() => {
    effect && effect(form, value, error === undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effect, value, error]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => form.remove(name), [name]);

  const shownError = form.getShownError(name);
  const input = (
    <Input
      type={type}
      value={value}
      name={name}
      onChange={useCallback(e => onChange(e.currentTarget.value), [onChange])}
      onBlur={useCallback(() => {
        form.setErrorVisible(name, error !== undefined);
        onBlur && onBlur();
      }, [form, name, onBlur, error])}
      invalid={shownError !== undefined || invalid === true}
      readOnly={readOnly}
      min={min}
      max={max}
      innerRef={useCallback(ref => form.setRef(name, ref), [form, name])}
      autoFocus={autoFocus}
      onKeyPress={actualOnKeyPress}
      placeholder={placeholder}
      disabled={disabled}
    />
  );

  return (
    <FormInputGroup name={name} label={label} error={shownError || customError} info={actualInfo} className={className}>
      {suffix ? (
        <InputGroup>
          {input}
          <InputGroupAddon className={type === 'number' ? 'input-group-numerical' : undefined} addonType="append">
            {suffix}
          </InputGroupAddon>
        </InputGroup>
      ) : (
        input
      )}
    </FormInputGroup>
  );
};

interface TextInputProps {
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  type?: 'text' | 'number' | 'password' | 'textarea';
  min?: number;
  max?: number;
  validate?: FieldValidator;
  effect?: (form: FormState, value: string, valid: boolean) => void;
  autoFocus?: boolean;
  suffix?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  onBlur?: () => void;
  info?: string;
  invalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
}
export function TextInput(props: TextInputProps) {
  const {
    name,
    label,
    value,
    onChange,
    onBlur,
    readOnly,
    type = 'text',
    min,
    max,
    validate,
    effect,
    autoFocus,
    onKeyPress,
    onEnter,
    suffix,
    invalid,
    placeholder,
    disabled,
    optional = false
  } = props;

  const form = useFormContext();
  const error = validate && validate(value, label, optional);
  form.setError(name, error);

  const actualOnKeyPress = onEnter
    ? (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === Hotkeys.ENTER) {
          if (form.hasErrors()) {
            form.setErrorVisible(name, error !== undefined);
            return;
          }
          onEnter();
        } else {
          onKeyPress && onKeyPress(e);
        }
      }
    : onKeyPress;

  useEffect(() => {
    effect && effect(form, value, error === undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effect, value, error]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => form.remove(name), [name]);

  const shownError = form.getShownError(name);
  const input = (
    <Input
      type={type}
      value={value}
      name={name}
      onChange={useCallback(e => onChange(e.currentTarget.value), [onChange])}
      onBlur={useCallback(() => {
        form.setErrorVisible(name, error !== undefined);
        onBlur && onBlur();
      }, [form, name, onBlur, error])}
      invalid={shownError !== undefined || invalid === true}
      readOnly={readOnly}
      min={min}
      max={max}
      innerRef={useCallback(ref => form.setRef(name, ref), [form, name])}
      autoFocus={autoFocus}
      onKeyPress={actualOnKeyPress}
      placeholder={placeholder}
      disabled={disabled}
    />
  );

  return suffix ? (
    <InputGroup>
      {input}
      <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
    </InputGroup>
  ) : (
    input
  );
}

export function useTextInput(
  name: string,
  label: string,
  initialValue: string,
  validate?: FieldValidator,
  effect?: (form: FormState, value: string, valid: boolean) => void,
  props?: {
    autoFocus?: boolean;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onEnter?: () => void;
    onBlur?: () => void;
    suffix?: string;
    info?: string;
    invalid?: boolean;
    placeholder?: string;
    optional?: boolean;
    disabled?: boolean;
  }
): [JSX.Element, string, () => void, (value: string) => void] {
  const [value, setValue] = useState(initialValue);
  const clear = useCallback(() => setValue(initialValue), [initialValue]);

  const component = (
    <TextInputGroup
      name={name}
      label={label}
      value={value}
      onChange={setValue}
      validate={validate}
      effect={effect}
      info={props && props.optional ? T('validatedInput.optional') : undefined}
      {...props}
    />
  );

  return [component, value, clear, setValue];
}

export function useTextareaInput(
  name: string,
  label: string,
  initialValue: string,
  validate?: FieldValidator,
  effect?: (form: FormState, value: string, valid: boolean) => void,
  props?: {
    autoFocus?: boolean;
    onKeyPress?: (event: React.KeyboardEvent) => void;
    onBlur?: () => void;
    suffix?: string;
    info?: string;
    optional?: boolean;
  }
): [JSX.Element, string, () => void] {
  const [value, setValue] = useState(initialValue);
  const clear = useCallback(() => setValue(initialValue), [initialValue]);

  const component = (
    <TextInputGroup
      type="textarea"
      name={name}
      label={label}
      value={value}
      onChange={setValue}
      validate={validate}
      effect={effect}
      {...props}
    />
  );

  return [component, value, clear];
}

interface ReadOnlyTextInputGroupProps {
  name: string;
  label: string;
  value: string;
}
export const ReadOnlyTextInputGroup = (props: ReadOnlyTextInputGroupProps) => {
  const {name, label, value} = props;
  return <TextInputGroup name={name} label={label} value={value} onChange={() => {}} readOnly={true} />;
};

export function validateSerialNumber(value: string, prefix?: string, length?: number) {
  if (value.length === 0) return T('serialNumber.required');
  else if (value.length !== (length || 10)) return T('serialNumber.length');
  else if (prefix && !value.startsWith(prefix)) {
    return T('serialNumber.incorrectType');
  }
}
