import React, {useState} from 'react';

import {Form, SingleActionModal} from '../components/bootstrap';
import FormInputGroup from '../components/inputs/FormInputGroup';
import {PrinterInput} from '../components/inputs/PrinterInput';

import {IPromiseModalProps, usePromiseModal} from './PromiseModal';

interface SelectPrinterDialogProps extends IPromiseModalProps<string | undefined> {
  printer?: string;
}

export default function SelectPrinterDialog(props: SelectPrinterDialogProps) {
  const [isOpen, resolve] = usePromiseModal(props);
  const [printer, setPrinter] = useState(props.printer || 'jan');

  const handleClose = () => resolve(undefined);
  const handleSelect = async () => resolve(printer);

  return (
    <SingleActionModal isOpen={isOpen} onToggle={handleClose} title="Select label printer" action={handleSelect}>
      <Form>
        <FormInputGroup name="printer" label="Printer">
          <PrinterInput value={printer} onChange={setPrinter} />
        </FormInputGroup>
      </Form>
    </SingleActionModal>
  );
}
