import Highcharts, {SeriesHideCallbackFunction, SeriesShowCallbackFunction, Series} from 'highcharts/highstock';
import numeral from 'numeral';

import {useMemo} from 'react';

import {Interval} from '../models/UsageValue';

import {useUpdatedRef} from './Hooks';
import {smartFormatNumeral} from './NumberUtils';

export function getChartTypeBasedOnInterval(interval: string, fallback?: string) {
  switch (interval) {
    case Interval.YEAR:
      return 'column';
    case Interval.MONTH:
      return 'column';
    default:
      return fallback ?? 'line';
  }
}

export function createPointFormatter(name: string, unit: string, decimals: number): (this: Highcharts.Point) => string {
  const format = decimals === 0 ? '0' : `0.${'0'.repeat(decimals)}`;
  return function () {
    return `<br /><span style="color:${this.color};">\u25CF</span> <span> ${name}</span>: <b>${numeral(this.y).format(
      format
    )} ${unit}</b>`;
  };
}

export function createGasWaterPointFormatter(name: string, unit: string): (this: Highcharts.Point) => string {
  return function () {
    return `<br /><span style="color:${this.color};">\u25CF</span> <span> ${name}</span>: <b>${smartFormatNumeral(
      this.y || 0
    )} ${unit}</b>`;
  };
}

// TODO: what's the correct type of this?
export function createRangePointFormatter(
  name: string,
  unit: string,
  decimals: number
): (this: Highcharts.Point) => string {
  const format = decimals === 0 ? '0' : `0.${'0'.repeat(decimals)}`;
  return function () {
    const point = (this as any).point;
    return `<br /><span style="color:${this.color};">\u25CF</span> <span> ${name}</span>: <b>${numeral(
      point.low
    ).format(format)}</b> - <b>${numeral(point.high).format(format)} ${unit}</b>`;
  };
}

/*export function createRangePointFormatter2(name: string, unit: string, decimals: number): (this: Highcharts.Point) => string {
  const format = decimals === 0 ? '0' : '0.' + '0'.repeat(decimals);
  return function() {
    return `<span style="color:${this.color}">\u25CF</span> ${name}: <b>${numeral(this.point.low).format(format)}</b> - <b>${numeral(this.point.high).format(format)} ${unit}</b><br/>`
  };
}
*/

export function useSeriesShowHideListeners(
  series: string[],
  onShowSeries: (id: string) => void,
  onHideSeries: (id: string) => void
): [SeriesShowCallbackFunction, SeriesHideCallbackFunction] {
  // -- bug workaround: highstock doesn't unregister the series listeners correctly. This results in old listeners being
  // called, resulting in duplicate calls or other unexpected behavior.
  //
  // As a solution, we make sure hashShowSeries is created only once, and have it access its depenencies through refs.
  const seriesRef = useUpdatedRef(series);
  // -- end of bug workaround

  const handleShowSeries: SeriesShowCallbackFunction = useMemo(
    () =>
      function (this: Series, event: Event) {
        const id = this.userOptions.id;
        if (!id || id === 'highcharts-navigator-series' || seriesRef.current.includes(id)) {
          return;
        }

        onShowSeries(id);
      },
    [seriesRef, onShowSeries]
  );
  const handleHideSeries: SeriesHideCallbackFunction = useMemo(
    () =>
      function (this: Series, event: Event) {
        const id = this.userOptions.id;
        if (!id || id === 'highcharts-navigator-series' || !seriesRef.current.includes(id)) {
          return;
        }

        onHideSeries(id);
      },
    [seriesRef, onHideSeries]
  );

  return [handleShowSeries, handleHideSeries];
}
