import {IConsumptionValue} from '../../models/ConsumptionValue';

export interface IGeneratedDataEntry {
  timestamp: number;
  values: number[];
}

interface ISimulatedSession {
  side: number;
  from: number;
  to: number;
  targetConsumption: number;
}

function overlaps(a: ISimulatedSession, b: ISimulatedSession) {
  return a.from < b.to && b.from < a.to;
}

function hasOverlap(sessions: ISimulatedSession[], session: ISimulatedSession) {
  return sessions.some(s => s.side === session.side && overlaps(s, session));
}

function generateSessions(from: number, to: number, numberOfSides: number): ISimulatedSession[] {
  const result: ISimulatedSession[] = [];

  let startOfDay = from;
  while (startOfDay < to) {
    const sessionTargetToday = 8 + ((Math.random() * 8) | 0);
    let sessionCountToday = 0;
    let failedAttempts = 0;
    while (sessionCountToday < sessionTargetToday && failedAttempts < 10) {
      const duration = ((Math.random() * 5 * 3600) | 0) * 1000;
      const from = startOfDay + (((9 + Math.random() * 7) * 3600) | 0) * 1000;
      const session: ISimulatedSession = {
        from,
        to: from + duration,
        targetConsumption: 9000 + 2000 * Math.random(),
        side: (Math.random() * numberOfSides) | 0
      };
      if (!hasOverlap(result, session)) {
        result.push(session);
        sessionCountToday++;
        failedAttempts = 0;
      } else {
        failedAttempts++;
      }
    }

    startOfDay += 24 * 3600 * 1000;
  }

  return result;
}

// Een laadpaal beurt haalt maximum 11 kW, dus zorgen dat je daar niet over gaat.
// Pieken gaan tot rond de 85? En consumption load tot 25, we hebben dus 60 kW
// over, waar je dus een 6 tal sessies in krijgt die een random duur krijgen
// van 1 tot 5u

export function generateData(
  from: number,
  to: number,
  consumption: IConsumptionValue[],
  numberOfSides: number
): IGeneratedDataEntry[] {
  const sessions = generateSessions(from, to, numberOfSides);

  const template: number[] = [];
  for (let i = 0; i < numberOfSides; i++) template.push(0);

  return consumption.map(item => {
    const activeSessions = sessions.filter(session => item.timestamp >= session.from && item.timestamp <= session.to);
    const targetSessionConsumption = activeSessions.reduce((total, session) => total + session.targetConsumption, 0);
    const available = Math.max(0, (item.solar || 0) - (item.value || 0));
    const scale = Math.min(1, available / targetSessionConsumption);

    const values = [...template];
    activeSessions.forEach(
      session => (values[session.side] = session.targetConsumption * scale * (1 - Math.random() * 0.02))
    );
    return {
      timestamp: item.timestamp,
      values
    };
  });
}
