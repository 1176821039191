declare global {
  interface Navigator {
    msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export function showBlobInBrowser(blob: Blob, filename: string) {
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
