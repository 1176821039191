import {IHighLevelConfiguration} from '../../models/HighLevelConfiguration';
import {ILoadUpdateChannels, ILoad, getLoadName} from '../../models/Load';
import {Phase, getPhaseIndex} from '../../models/Phase';
import {getPathIndex} from '../../models/TrackingPath';

export interface IPhasorDisplayChannelInfo {
  id: number;
  location: string;
  phase: Phase;
  name: string;
  serialNumber: string;
  channelIndex: number;
  labelName: string;
  updateChannels: ILoadUpdateChannels;
  reversed: boolean;
}

export type LoadWithChannels = ILoad & {channels: IPhasorDisplayChannelInfo[]};

const LETTERS = 'abcdefghijklmnopqrstuvwxyz';
export function getLoadsWithChannels(configuration: IHighLevelConfiguration) {
  const loads: LoadWithChannels[] = [];

  // Sanitize data
  const items = configuration.measurements;

  for (let load of items) {
    let {actuals} = load;

    const channels: IPhasorDisplayChannelInfo[] = [];
    let valid = false;
    for (let i = 0; i < actuals.length; i++) {
      const actual = actuals[i];
      let {id, sensor, updateChannels, phase} = actual;
      if (!updateChannels || !sensor) continue;

      const {current} = updateChannels;
      if (!current.aspectPaths) continue;

      const channelsForPhase = actuals.filter(channel => channel.phase === phase);
      let labelName = `I${getPhaseIndex(phase) + 1}`;
      if (channelsForPhase.length > 1) {
        labelName += LETTERS[channelsForPhase.indexOf(actual)];
      }

      const {location, serialNumber, reversed} = sensor;
      valid = true;

      // Assign configuration objects
      const channelIndex = getPathIndex(current.aspectPaths[0]);
      channels.push({
        id,
        location,
        phase,
        name: getLoadName(load),
        labelName,
        serialNumber,
        reversed,
        channelIndex,
        updateChannels
      });
    }

    channels.sort((a, b) => getPhaseIndex(a.phase) - getPhaseIndex(b.phase));
    if (valid) {
      loads.push(Object.assign(load, {channels}));
    }
  }
  return loads;
}
