import * as React from 'react';

import {PricingPolicyLimits} from '../../models/PricingPolicy';
import {formatCurrency, formatCurrencyNumber, getCurrencySymbol} from '../../utils/Currency';
import {T} from '../../utils/Internationalization';

import {validateTimedComponent} from '../../utils/Validation';
import {IconButton} from '../IconButton';
import FormInputGroup from '../inputs/FormInputGroup';
import {NumberInputGroup, NumberValue} from '../inputs/NumberInput';

import {RoundButton} from '../RoundButton';

import {TariffState} from './configurePricingPolicy/FormState';
import {formatTimeComponentPricing} from './connectPricingGroup/DiscountedTariffView';
import styles from './TariffsSelector.module.scss';
import {TimeComponentsInput, TimeComponentsInputProps} from './TimeComponentsInput';

function TariffInput({
  description,
  oldPrice,
  onRemove,
  ...props
}: React.ComponentProps<typeof NumberInputGroup> & {description: string; oldPrice?: string; onRemove?: () => void}) {
  return (
    <div className={styles.tariffInputContainer}>
      <div className={styles.tariffInputInput}>
        <NumberInputGroup
          min={0}
          info={oldPrice ? <div className={styles.tariffInputOldPrice}>{oldPrice}</div> : description}
          {...props}
        />
      </div>
      {onRemove && <IconButton action="delete" color="link" onClick={onRemove} />}
    </div>
  );
}

interface ScrappedTariffInputProps {
  name: string;
  oldPrice: string[];
  label: string;
}
function ScrappedTariffInput(props: ScrappedTariffInputProps) {
  const {name, oldPrice, label} = props;
  return (
    <div className={styles.tariffInputContainer}>
      <div className={styles.tariffInputScrapped}>
        <FormInputGroup
          name={name}
          info={
            <>
              {oldPrice.map((element, index) => (
                <div key={index} className={styles.tariffInputOldPrice}>
                  {element}
                </div>
              ))}
            </>
          }
          label={label}
        >
          <span />
        </FormInputGroup>
      </div>
    </div>
  );
}

function TimedTariffInput(props: TimeComponentsInputProps) {
  return (
    <div className={styles.tariffInputContainer}>
      <div className={styles.tariffInputInput}>
        <TimeComponentsInput validate={validateTimedComponent} {...props} />
      </div>
    </div>
  );
}

interface PristineTariffsFormProps {
  updateFormState: (updates: Partial<TariffState>) => void;
}
function PristineTariffsForm(props: PristineTariffsFormProps) {
  const {updateFormState} = props;

  return (
    <div className={styles.tariffsCardContainer}>
      <h2 className="m-0 p-0 font-bold">{T('pricingPolicies.add.tariffs.pristine.title')}</h2>
      <p className="m-0 p-0">{T('pricingPolicies.add.tariffs.pristine.addPrice')}</p>
      <button
        className={styles.tariffsCard}
        type="button"
        onClick={() => updateFormState({startingFee: NumberValue.none()})}
      >
        <div className={styles.tariffsCardTitle}>{T('pricingPolicies.add.tariffs.fixedCost')}</div>
        <div className={styles.tariffsCardDescription}>{T('pricingPolicies.add.tariffs.fixedCost.description')}</div>
      </button>
      <button
        className={styles.tariffsCard}
        type="button"
        onClick={() => updateFormState({costPerKwh: NumberValue.none()})}
      >
        <div className={styles.tariffsCardTitle}>{T('pricingPolicies.add.tariffs.energyCost')}</div>
        <div className={styles.tariffsCardDescription}>{T('pricingPolicies.add.tariffs.energyCost.description')}</div>
      </button>
      <button
        className={styles.tariffsCard}
        type="button"
        onClick={() => updateFormState({timeComponents: [{cost: NumberValue.none(), afterMinutes: 0}]})}
      >
        <div className={styles.tariffsCardTitle}>{T('pricingPolicies.add.tariffs.hourlyCost')}</div>
        <div className={styles.tariffsCardDescription}>{T('pricingPolicies.add.tariffs.hourlyCost.description')}</div>
      </button>
    </div>
  );
}

interface AddTariffTypeSelectorProps {
  formState: TariffState;
  updateFormState: (updates: Partial<TariffState>) => void;
}
function AddTariffTypeSelector(props: AddTariffTypeSelectorProps) {
  const {formState, updateFormState} = props;

  return (
    <div className="btn-group mt-4">
      {formState.startingFee === undefined && (
        <RoundButton onClick={() => updateFormState({startingFee: NumberValue.none()})}>
          <i className="fa fa-plus mr-2" />
          {T('pricingPolicies.add.tariffs.fixedCost')}
        </RoundButton>
      )}
      {formState.costPerKwh === undefined && (
        <RoundButton onClick={() => updateFormState({costPerKwh: NumberValue.none()})}>
          <i className="fa fa-plus mr-2" />
          {T('pricingPolicies.add.tariffs.energyCost')}
        </RoundButton>
      )}
      {(formState.timeComponents === undefined || formState.timeComponents.length === 0) && (
        <RoundButton onClick={() => updateFormState({timeComponents: [{cost: NumberValue.none(), afterMinutes: 0}]})}>
          <i className="fa fa-plus mr-2" />
          {T('pricingPolicies.add.tariffs.hourlyCost')}
        </RoundButton>
      )}
    </div>
  );
}

function isPristine(formState: TariffState) {
  return (
    formState.startingFee === undefined &&
    formState.costPerKwh === undefined &&
    (formState.timeComponents === undefined || formState.timeComponents.length === 0)
  );
}

function allTariffsDefined(formState: TariffState) {
  return (
    formState.startingFee !== undefined &&
    formState.costPerKwh !== undefined &&
    !(formState.timeComponents === undefined || formState.timeComponents.length === 0)
  );
}

interface TariffsSelectorProps {
  baseTariff?: TariffState;
  state: TariffState;
  updateState: (updates: Partial<TariffState>) => void;
  currency: string;
  limits?: PricingPolicyLimits;
}

function TariffsSelector(props: TariffsSelectorProps) {
  const {baseTariff, state, updateState, currency, limits} = props;
  const pristine = isPristine(state);
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <div>
      {pristine ? (
        <PristineTariffsForm updateFormState={updateState} />
      ) : (
        <div className={styles.inputWrapper}>
          {state.startingFee !== undefined && (
            <TariffInput
              name="fixedCost"
              label={T('pricingPolicies.add.tariffs.fixedCost')}
              value={state.startingFee}
              onChange={startingFee => updateState({startingFee})}
              description={T('pricingPolicies.add.tariffs.fixedCost.description')}
              onRemove={() => updateState({startingFee: undefined})}
              max={limits?.maxFixedCost}
              maxError={T('pricingPolicies.error.priceTooHigh.fixed', {
                max: limits ? formatCurrency(currencySymbol, limits.maxFixedCost) : '...'
              })}
              suffix={`${currencySymbol}/${T('pricingPolicies.add.tariffs.fixedCost.suffix')}`}
              oldPrice={
                baseTariff &&
                `${currencySymbol} ${formatCurrencyNumber(currency, baseTariff.startingFee?.numberValue || 0)} / ${T(
                  'pricingPolicies.add.tariffs.fixedCost.suffix'
                )}`
              }
            />
          )}
          {state.startingFee === undefined && baseTariff?.startingFee !== undefined && (
            <ScrappedTariffInput
              name="fixedCost"
              label={T('pricingPolicies.add.tariffs.fixedCost')}
              oldPrice={[
                `${currencySymbol} ${formatCurrencyNumber(currency, baseTariff.startingFee.numberValue || 0)} / ${T(
                  'pricingPolicies.add.tariffs.fixedCost.suffix'
                )}`
              ]}
            />
          )}
          {state.costPerKwh !== undefined && (
            <TariffInput
              name="energyCost"
              label={T('pricingPolicies.add.tariffs.energyCost')}
              value={state.costPerKwh}
              onChange={costPerKwh => updateState({costPerKwh})}
              description={T('pricingPolicies.add.tariffs.energyCost.description')}
              onRemove={() => updateState({costPerKwh: undefined})}
              max={limits?.maxEnergyCost}
              maxError={T('pricingPolicies.error.priceTooHigh.energy', {
                max: limits ? formatCurrency(currencySymbol, limits.maxEnergyCost) : '...'
              })}
              suffix={`${currencySymbol}/kWh`}
              oldPrice={
                baseTariff &&
                `${currencySymbol} ${formatCurrencyNumber(currency, baseTariff.costPerKwh?.numberValue || 0)} / kWh`
              }
            />
          )}
          {state.timeComponents.length > 0 && (
            <TimedTariffInput
              name="hourlyCost"
              label={T('pricingPolicies.add.tariffs.hourlyCost')}
              value={state.timeComponents}
              onChange={timeComponents => updateState({timeComponents})}
              onRemove={() => updateState({timeComponents: []})}
              max={limits?.maxHourlyCost}
              maxError={T('pricingPolicies.error.priceTooHigh.hourly', {
                max: limits ? formatCurrency(currencySymbol, limits.maxHourlyCost) : '...'
              })}
              currency={currency}
              baseValue={baseTariff?.timeComponents}
            />
          )}
          {state.timeComponents.length === 0 && baseTariff && baseTariff.timeComponents.length > 0 && (
            <ScrappedTariffInput
              name="hourlyCost"
              label={T('pricingPolicies.add.tariffs.hourlyCost')}
              oldPrice={baseTariff.timeComponents.map((component, index) =>
                formatTimeComponentPricing(currency, baseTariff.timeComponents, index)
              )}
            />
          )}

          {!allTariffsDefined(state) && (
            <div>
              <AddTariffTypeSelector formState={state} updateFormState={updateState} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export {TariffsSelector, isPristine};
