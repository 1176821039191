import type {SVGProps} from 'react';
const SvgLockLocked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M8 0a4 4 0 0 0-4 4v.5h2V4a2 2 0 1 1 4 0v.5h2V4a4 4 0 0 0-4-4" />
    <path d="M2 7a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm7.5 2.5c0 .534-.28 1.003-.7 1.27v1.43a.8.8 0 0 1-1.6 0v-1.43A1.499 1.499 0 0 1 8 8a1.5 1.5 0 0 1 1.5 1.5" />
  </svg>
);
export default SvgLockLocked;
