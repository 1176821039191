import React, {useMemo, useEffect} from 'react';

import {Form, FormGroup, Input, Col, FormFeedback, ModalHeader, Modal, ModalBody} from 'reactstrap';

import {Label, Button} from '../../../components/bootstrap';

import {usePromiseModal, IPromiseModalProps} from '../../../modals/PromiseModal';
import {
  FluviusCampaignTemplate,
  FluviusMeasurementCampaignType,
  FluviusMeasurementTemplateCode,
  FluviusCampaignInput,
  FluviusCampaignConfiguration
} from '../../../models/FluviusMeasurementCampaign';
import {IOrganizationRegion, IMeasuringCase, translateRegionName} from '../../../models/Organization';
import {Phase} from '../../../models/Phase';
import {useObjectState} from '../../../utils/ObjectState';
import {
  defaultFormErrors,
  defaultFormState,
  useMeasurementCampaignFormState
} from '../FluviusMeasurementCampaigns/CampaignFormState';
import {Template, TemplateSubmeter, TemplateSubmeterType} from '../FluviusMeasurementCampaigns/Models';

import {CabinTemplateForm} from './CabinTemplateForm';
import {
  SIBELGA_CAMPAIGN_REASONS,
  SIBELGA_CAMPAIGN_TEMPLATES,
  SIBELGA_TEMPLATE_NETONLY,
  SIBELGA_TEMPLATE_NET2X3F,
  SIBELGA_TEMPLATE_NET8X1F,
  SIBELGA_CAMPAIGN_ALL_TEMPLATES,
  SiT,
  validateFormState,
  SIBELGA_CAMPAIGN_CITIES,
  getCabinIDLabel
} from './SibelgaModels';

const CITY_OPTIONS = SIBELGA_CAMPAIGN_CITIES.map(city => ({
  value: city,
  label: city
}));

interface CreateSibelgaMeasurementCampaignProps extends IPromiseModalProps<FluviusCampaignConfiguration | undefined> {
  regions: IOrganizationRegion[];
  measuringCases: IMeasuringCase[];

  existing?: FluviusCampaignConfiguration;
  readOnly: boolean;
}

function getSetType(set: IMeasuringCase): FluviusMeasurementCampaignType {
  return set.name.startsWith('M') ? FluviusMeasurementCampaignType.Cabine : FluviusMeasurementCampaignType.Voetpadkast;
}

function getNamePlaceholder(type: FluviusMeasurementCampaignType) {
  switch (type) {
    case FluviusMeasurementCampaignType.Cabine:
      return SiT('type.cabin');
    case FluviusMeasurementCampaignType.Voetpadkast:
      return SiT('type.sidewalkCase');
    case FluviusMeasurementCampaignType.OndergrondseKast:
      return SiT('type.undergroundCase');
    case FluviusMeasurementCampaignType.WoningKlant:
      return SiT('type.customerHouse');
    case FluviusMeasurementCampaignType.KlantenCabine:
      return SiT('type.customerCabin');
    default:
      return '';
  }
}

function getSecondaryTemplateOptions(primary: Template | undefined): Template[] {
  if (primary === undefined) return [];
  if (
    primary.code === FluviusMeasurementTemplateCode.Net4x3F ||
    primary.code === FluviusMeasurementTemplateCode.Net16x1F
  ) {
    return [SIBELGA_TEMPLATE_NETONLY];
  } else if (
    primary.code === FluviusMeasurementTemplateCode.Net2x3F ||
    primary.code === FluviusMeasurementTemplateCode.Net8x1F
  ) {
    return [SIBELGA_TEMPLATE_NET2X3F, SIBELGA_TEMPLATE_NET8X1F];
  } else return [];
}

function readTemplateType(
  set: IMeasuringCase | undefined,
  configuration: FluviusCampaignConfiguration,
  template: FluviusCampaignTemplate
): FluviusMeasurementTemplateCode {
  if (template.template !== FluviusMeasurementTemplateCode.THREE_PHASE || set === undefined) {
    return template.template;
  }

  const setType = getSetType(set);
  if (setType === FluviusMeasurementCampaignType.Cabine) {
    return FluviusMeasurementTemplateCode.Net;
  } else {
    return FluviusMeasurementTemplateCode.THREE_PHASE;
  }
}

function readSubmeter(template: Template, index: number, input: FluviusCampaignInput): TemplateSubmeter {
  const templateInput = template.submeters[index];
  return {
    definition: templateInput,
    enabled: input.enabled,
    number: input.nr ? input.nr.toString() : '',
    name: input.name || '',
    phase: input.phase || '_'
  };
}

function generateSubmetersFromTemplate(template: Template): TemplateSubmeter[] {
  const result: TemplateSubmeter[] = [];
  let loadNumber = 1;
  for (var submeter of template.submeters) {
    const name = submeter.main ? SiT('submeter.net') : `${SiT('submeter.load')} ${loadNumber++}`;
    result.push({
      definition: submeter,
      enabled: true,
      name,
      number: '',
      phase: Phase.L3
    });
  }
  return result;
}

function normalizeTemplateType(type: FluviusMeasurementTemplateCode) {
  switch (type) {
    case FluviusMeasurementTemplateCode.THREE_PHASE_N:
    case FluviusMeasurementTemplateCode.THREE_PHASE_V:
    case FluviusMeasurementTemplateCode.Net:
      return FluviusMeasurementTemplateCode.THREE_PHASE;
    default:
      return type;
  }
}

export function CreateSibelgaMeasurementCampaign(props: CreateSibelgaMeasurementCampaignProps) {
  const {existing, regions, measuringCases, readOnly} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  const [state, stateActor] = useMeasurementCampaignFormState(defaultFormState);
  const [errors, updateErrors] = useObjectState(defaultFormErrors);

  useEffect(() => {
    const set = existing && measuringCases.find(x => x.id === existing.measuringCase);
    const reason = existing && SIBELGA_CAMPAIGN_REASONS.find(r => r.name === existing.reason);
    const templateACode = existing && readTemplateType(set, existing, existing.geniusA);
    const templateBCode = existing && existing.geniusB && readTemplateType(set, existing, existing.geniusB);
    const templateA = SIBELGA_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === templateACode);
    const templateB = SIBELGA_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === templateBCode);

    if (existing) {
      stateActor.set({
        name: existing.name,
        type: existing.type,
        cabinId: existing.cabinId || '',
        vpkId: existing.vpkId || '',
        set,
        region: regions.find(x => x.id === existing.region),
        city: existing.city,
        streetAndNumber: existing.address,
        reason: reason ? reason.code : '__',
        notes: existing.notes,
        voltage: existing.voltage || '400V',
        transfoAId: existing.geniusA.transfoId || '',
        transfoBId: (existing.geniusB && existing.geniusB.transfoId) || '',
        templateA,
        templateABoardNumber: existing.geniusA.boardNr || '',
        templateABoardName: (existing && existing.geniusA.boardName) || '',
        templateASubmeters: ((templateA && existing.geniusA.inputs) || []).map((input, index) =>
          readSubmeter(templateA!, index, input)
        ),
        templateB,
        templateBBoardNumber: (existing.geniusB && existing.geniusB.boardNr) || '',
        templateBBoardName: (existing.geniusB && existing.geniusB.boardName) || '',
        templateBSubmeters: ((templateB && existing && existing.geniusB && existing.geniusB.inputs) || []).map(
          (input, index) => readSubmeter(templateB!, index, input)
        )
      });
    } else {
      stateActor.set(defaultFormState);
    }
  }, [existing, measuringCases, regions, stateActor]);

  const handleNameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({name: e.currentTarget.value});
    updateErrors({name: undefined});
  };

  const handleTypeChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({
      type: e.currentTarget.value as FluviusMeasurementCampaignType,
      templateA: undefined,
      templateB: undefined
    });
    updateErrors({typeRequired: false});
  };

  const handleCabinIdChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({cabinId: e.currentTarget.value});
    updateErrors({cabinId: undefined});
  };

  const handleSetChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const selectedId = parseInt(e.currentTarget.value);
    const selectedSet = measuringCases.find(c => c.id === selectedId);
    if (!state.set || (selectedSet && getSetType(state.set) !== getSetType(selectedSet))) {
      stateActor.update({
        set: selectedSet,
        templateA: undefined,
        templateB: undefined
      });
      updateErrors({setRequired: false});
    } else {
      stateActor.update({set: selectedSet});
    }
  };

  const handleRegionChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const selectedId = parseInt(e.currentTarget.value);
    const region = regions.find(region => region.id === selectedId);
    stateActor.update({region});
    updateErrors({regionRequired: false});
  };

  const handleVoltageChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({voltage: e.currentTarget.value as '400V' | '230V'});
    updateErrors({voltage: undefined});
  };

  const handleCityChanged = (value: string) => {
    stateActor.update({city: value});
    updateErrors({cityRequired: false});
  };

  const handleStreetAndNumberChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({streetAndNumber: e.currentTarget.value});
    updateErrors({streetAndNumber: undefined});
  };

  const handleReasonChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({reason: e.currentTarget.value});
    updateErrors({reasonRequired: false});
  };

  const handleNotesChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({notes: e.currentTarget.value});
    updateErrors({notes: undefined});
  };

  const handleTemplateASubmeterChanged = (index: number, submeter: TemplateSubmeter) => {
    const submeters = [...state.templateASubmeters];
    submeters[index] = submeter;
    stateActor.update({templateASubmeters: submeters});

    const submeterErrors = [...errors.templateASubmeters];
    submeterErrors[index] = {phaseInvalid: false};
    updateErrors({templateASubmeters: submeterErrors});
  };

  const handleTemplateBSubmeterChanged = (index: number, submeter: TemplateSubmeter) => {
    const submeters = [...state.templateBSubmeters];
    submeters[index] = submeter;
    stateActor.update({templateBSubmeters: submeters});

    const submeterErrors = [...errors.templateBSubmeters];
    submeterErrors[index] = {phaseInvalid: false};
    updateErrors({templateBSubmeters: submeterErrors});
  };

  const handleTemplateAChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const code = e.currentTarget.value;
    const template = SIBELGA_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === code);
    let submeters: TemplateSubmeter[] = [];
    if (template) submeters = generateSubmetersFromTemplate(template);

    stateActor.update({
      templateA: template,
      templateASubmeters: submeters
    });
    updateErrors({templateASubmeters: [], templateA: undefined});

    const availableSecondaries = getSecondaryTemplateOptions(template);
    if (state.templateB && availableSecondaries.find(t => t.code === state.templateB!.code) === undefined) {
      stateActor.update({
        templateB: undefined,
        templateBSubmeters: []
      });
      updateErrors({templateBSubmeters: [], templateB: undefined});
    }
  };

  const handleTemplateBChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const code = e.currentTarget.value;
    const template = SIBELGA_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === code);
    let submeters: TemplateSubmeter[] = [];
    if (template) submeters = generateSubmetersFromTemplate(template);

    stateActor.update({
      templateB: template,
      templateBSubmeters: submeters
    });
    updateErrors({templateBSubmeters: [], templateB: undefined});
  };

  const handleClickedSave = () => {
    const [valid, errors] = validateFormState(state);
    updateErrors(errors);
    if (!valid) return;

    const {region, set, templateA, templateB} = state;
    if (!templateA || !region || !set) return;

    const templateARequest: FluviusCampaignTemplate = {
      template: normalizeTemplateType(templateA.code),
      inputs: state.templateASubmeters.map((item, index) => {
        const input = templateA.submeters[index];
        return {
          grid: input.main || false,
          enabled: item.enabled,
          name: input.main ? 'Net' : item.name,
          phase:
            item.definition.type === TemplateSubmeterType.Phase3
              ? undefined
              : item.phase === '_'
                ? Phase.L3
                : item.phase
        };
      })
    };

    let templateBRequest: FluviusCampaignTemplate | undefined;
    if (templateB) {
      templateBRequest = {
        template: normalizeTemplateType(templateB.code),
        inputs: state.templateBSubmeters.map((item, index) => {
          const input = templateB.submeters[index];
          return {
            grid: input.main || false,
            enabled: item.enabled,
            name: input.main ? 'Net' : item.name,
            phase:
              item.definition.type === TemplateSubmeterType.Phase3
                ? undefined
                : item.phase === '_'
                  ? Phase.L3
                  : item.phase
          };
        })
      };
    }

    const request: FluviusCampaignConfiguration = {
      type: state.type,
      region: region.id,
      measuringCase: set.id,
      name: state.name,
      vpkId: state.vpkId,
      cabinId: state.cabinId,
      city: state.city,
      address: state.streetAndNumber,
      reason: SIBELGA_CAMPAIGN_REASONS.find(r => r.code === state.reason)!.name,
      voltage: state.voltage as '400V' | '230V',
      notes: state.notes,
      geniusA: templateARequest,
      geniusB: templateBRequest
    };
    resolve(request);
  };

  const availableSets = useMemo(() => {
    return measuringCases.filter(x => x.region === undefined || state.region?.id === x.region.id);
  }, [measuringCases, state.region]);

  const handleClose = () => resolve(undefined);

  const labelWidth = 4;
  const inputWidth = 8;
  let primaryTemplateOptions: JSX.Element[] = [];
  let secondaryTemplateOptions: JSX.Element[] = [];
  if (state.set) {
    const setType = getSetType(state.set);
    primaryTemplateOptions = SIBELGA_CAMPAIGN_TEMPLATES.filter(t => t.type === setType).map(value => (
      <option key={value.code} value={value.code}>
        {SiT(value.name)}
      </option>
    ));
    secondaryTemplateOptions = getSecondaryTemplateOptions(state.templateA).map(value => (
      <option key={value.code} value={value.code}>
        {SiT(value.name)}
      </option>
    ));
  }

  const form = (
    <Form
      style={{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        minHeight: 800
      }}
    >
      <div style={{maxWidth: 500}}>
        <FormGroup row>
          <Label sm={labelWidth}>{SiT('field.department')}</Label>
          <Col sm={inputWidth}>
            <Input
              type="select"
              disabled={readOnly}
              value={state.region === undefined ? '0' : state.region.id.toString()}
              onChange={handleRegionChanged}
              invalid={errors.regionRequired}
            >
              <option key="" value="0">
                {SiT('department.choose')}
              </option>
              {regions.map(region => (
                <option key={region.id} value={region.id.toString()}>
                  {translateRegionName(region)}
                </option>
              ))}
            </Input>
            {errors.regionRequired && <FormFeedback>{SiT('field.department.select')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>{SiT('field.type')}</Label>
          <Col sm={inputWidth}>
            <Input
              type="select"
              value={state.type}
              onChange={handleTypeChanged}
              invalid={errors.typeRequired}
              disabled={readOnly}
            >
              <option value={FluviusMeasurementCampaignType.__unset__}>{SiT('type.choose')}</option>
              <option value={FluviusMeasurementCampaignType.Cabine}>{SiT('type.cabin')}</option>
              <option value={FluviusMeasurementCampaignType.Voetpadkast}>{SiT('type.sidewalkCase')}</option>
              <option value={FluviusMeasurementCampaignType.OndergrondseKast}>{SiT('type.undergroundCase')}</option>
              <option value={FluviusMeasurementCampaignType.WoningKlant}>{SiT('type.customerHouse')}</option>
              <option value={FluviusMeasurementCampaignType.KlantenCabine}>{SiT('type.customerCabin')}</option>
            </Input>
            {errors.typeRequired && <FormFeedback>{SiT('field.type.select')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>{SiT('field.name')}</Label>
          <Col sm={inputWidth}>
            <Input
              type="text"
              value={state.name}
              onChange={handleNameChanged}
              invalid={errors.name !== undefined}
              placeholder={getNamePlaceholder(state.type)}
              disabled={readOnly}
            />
            {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
          </Col>
        </FormGroup>
        {state.type !== FluviusMeasurementCampaignType.__unset__ && (
          <FormGroup row>
            <Label sm={labelWidth}>{getCabinIDLabel(state.type)}</Label>
            <Col sm={inputWidth}>
              <Input
                type="text"
                value={state.cabinId}
                onChange={handleCabinIdChanged}
                invalid={errors.cabinId !== undefined}
                disabled={readOnly}
              />
              {errors.cabinId && <FormFeedback>{errors.cabinId}</FormFeedback>}
            </Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Label sm={labelWidth}>{SiT('field.voltage')}</Label>
          <Col sm={inputWidth}>
            <Input
              type="select"
              value={state.voltage}
              onChange={handleVoltageChanged}
              disabled={readOnly}
              invalid={errors.voltage !== undefined}
            >
              <option key="" value="">
                {SiT('field.voltage.choose')}
              </option>
              <option value="400V">400V</option>
              <option value="230V">230V</option>
            </Input>
            {errors.voltage && <FormFeedback>{errors.voltage}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>{SiT('field.city')}</Label>
          <Col sm={inputWidth}>
            <Input
              type="select"
              value={state.city}
              onChange={e => handleCityChanged(e.currentTarget.value)}
              invalid={errors.cityRequired}
              disabled={readOnly}
            >
              <option value="">{SiT('field.city.pleaseSelect')}</option>
              {CITY_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>{SiT('field.street')}</Label>
          <Col sm={inputWidth}>
            <Input
              type="text"
              value={state.streetAndNumber}
              onChange={handleStreetAndNumberChanged}
              invalid={errors.streetAndNumber !== undefined}
              disabled={readOnly}
            />
            {errors.streetAndNumber && <FormFeedback>{errors.streetAndNumber}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>{SiT('field.reason')}</Label>
          <Col sm={inputWidth}>
            <Input
              type="select"
              value={state.reason}
              onChange={handleReasonChanged}
              invalid={errors.reasonRequired}
              disabled={readOnly}
            >
              {SIBELGA_CAMPAIGN_REASONS.map(reason => (
                <option key={reason.code} value={reason.code}>
                  {SiT(reason.name)}
                </option>
              ))}
            </Input>
            {errors.reasonRequired && <FormFeedback>{SiT('field.reason.required')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>{SiT('field.notes')}</Label>
          <Col sm={inputWidth}>
            <Input
              type="textarea"
              value={state.notes}
              onChange={handleNotesChanged}
              invalid={errors.notes !== undefined}
              disabled={readOnly}
            />
            {errors.notes && <FormFeedback>{errors.notes}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>
            {state.type === FluviusMeasurementCampaignType.Voetpadkast
              ? SiT('field.measuringset.set')
              : SiT('field.measuringset.case')}
          </Label>
          <Col sm={inputWidth}>
            <Input
              type="select"
              value={state.set === undefined ? '0' : state.set.id.toString()}
              onChange={handleSetChanged}
              disabled={readOnly}
              invalid={errors.setRequired}
            >
              <option value="">{SiT('field.measuringset.select')}</option>
              {availableSets.map(set => (
                <option key={set.id} value={set.id.toString()}>
                  {set.name}
                </option>
              ))}
            </Input>
            {errors.setRequired && <FormFeedback>{SiT('field.measuringset.required')}</FormFeedback>}
          </Col>
        </FormGroup>
      </div>
      {state.set && <hr />}
      {state.set && (
        <div style={{maxWidth: 500}}>
          <FormGroup row>
            {getSetType(state.set) === FluviusMeasurementCampaignType.Cabine && (
              <Label sm={labelWidth}>
                <strong>{SiT('field.templateA')}</strong>
              </Label>
            )}
            {getSetType(state.set) === FluviusMeasurementCampaignType.Voetpadkast && (
              <Label sm={labelWidth}>
                <strong>{SiT('field.template')}</strong>
              </Label>
            )}
            <Col sm={inputWidth}>
              <Input
                type="select"
                value={state.templateA ? state.templateA.code : '_'}
                onChange={handleTemplateAChanged}
                disabled={readOnly}
                invalid={errors.templateA !== undefined}
              >
                <option value="_">{SiT('field.template.select')}</option>
                {primaryTemplateOptions}
              </Input>
              {errors.templateA && <FormFeedback>{errors.templateA}</FormFeedback>}
            </Col>
          </FormGroup>
        </div>
      )}
      {state.templateA && (
        <CabinTemplateForm
          submeters={state.templateASubmeters}
          errors={errors.templateASubmeters}
          onSubmeterChanged={handleTemplateASubmeterChanged}
          readOnly={readOnly}
        />
      )}
      {state.set && getSetType(state.set) === FluviusMeasurementCampaignType.Cabine && <hr />}
      {state.set && getSetType(state.set) === FluviusMeasurementCampaignType.Cabine && (
        <div style={{maxWidth: 500}}>
          <FormGroup row>
            <Label sm={labelWidth}>
              <strong>{SiT('field.templateB')}</strong>
            </Label>
            <Col sm={inputWidth}>
              <Input
                type="select"
                value={state.templateB ? state.templateB.code : '_'}
                onChange={handleTemplateBChanged}
                disabled={readOnly}
                invalid={errors.templateB !== undefined}
              >
                <option value="_">{SiT('field.template.unused')}</option>
                {secondaryTemplateOptions}
              </Input>
              {errors.templateB && <FormFeedback>{errors.templateB}</FormFeedback>}
            </Col>
          </FormGroup>
        </div>
      )}
      {state.templateB && (
        <CabinTemplateForm
          submeters={state.templateBSubmeters}
          errors={errors.templateBSubmeters}
          onSubmeterChanged={handleTemplateBSubmeterChanged}
          readOnly={readOnly}
        />
      )}
      {!readOnly && (
        <div style={{maxWidth: 500}}>
          <FormGroup row>
            <Col sm={{size: inputWidth, offset: labelWidth}}>
              <Button onClick={handleClickedSave}>{SiT('action.save')}</Button>
            </Col>
          </FormGroup>
        </div>
      )}
    </Form>
  );

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="xl">
      <ModalHeader toggle={handleClose}>{existing ? SiT('modal.title.view') : SiT('modal.title.create')}</ModalHeader>
      <ModalBody>{form}</ModalBody>
    </Modal>
  );
}
