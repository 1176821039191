export function printLabels(
  token: string,
  variant: string,
  serialNumbers: string[],
  printer: string
): Promise<boolean> {
  const hasSerials = Array.isArray(serialNumbers) && serialNumbers.length > 0;
  const serialsParam = hasSerials ? serialNumbers.join(',') : '';

  return fetch(`/device-labels/${serialsParam}?printer=${printer}&variant=${variant}&token=${token}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token
    }
  }).then(({status}) => {
    return status === 204;
  });
}
