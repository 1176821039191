import React from 'react';

import {CabinTemplateSubmeter} from './CabinTemplateSubmeter';
import {noSubmeterErrors, TemplateSubmeterErrors} from './CampaignFormState';
import {TemplateSubmeter} from './Models';

interface CabinTemplateFormProps {
  submeters: TemplateSubmeter[];
  errors: TemplateSubmeterErrors[];
  readOnly: boolean;

  onSubmeterChanged: (index: number, submeter: TemplateSubmeter) => void;
}
export const CabinTemplateForm = (props: CabinTemplateFormProps) => {
  const {submeters, errors, onSubmeterChanged, readOnly} = props;

  const submetersRendered = submeters.map((submeter, index) => (
    <CabinTemplateSubmeter
      key={index}
      index={index}
      submeter={submeter}
      errors={errors[index] || noSubmeterErrors}
      onChanged={onSubmeterChanged}
      readOnly={readOnly}
    />
  ));
  return <div>{submetersRendered}</div>;
};
