import {useState} from 'react';

import {SingleActionModal} from '../../../components/bootstrap';
import {TextInput} from '../../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {IOrganization} from '../../../models/Organization';
import {T} from '../../../utils/Internationalization';

interface PurgeModalProps extends IPromiseModalProps<boolean> {
  organization: IOrganization;
  purgeOrganization: () => void;
}

const ConfirmPurgeOrganizationInfoModal = (props: PurgeModalProps) => {
  const {organization, purgeOrganization} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const onToggle = () => resolve(false);
  const [confirmPurge, setConfirmPurge] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handlePurge = async () => {
    if (confirmPurge === organization.name) {
      purgeOrganization();
      onToggle();
    } else {
      setError(T('specialFunctions.purge.confirmation.noMatch'));
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={onToggle}
      title={T('specialFunctions.purge.confirmation.title')}
      cancelText={T('specialFunctions.purge.confirmation.cancel')}
      action={handlePurge}
      actionText={T('specialFunctions.purge.confirmation.delete')}
      error={error}
    >
      <div>
        <p>{T('specialFunctions.purge.confirmation.areYouSure')}</p>
        <TextInput
          name="confirmOrganizationId"
          label={T('specialFunctions.purge.organization')}
          value={confirmPurge}
          onChange={setConfirmPurge}
        />
      </div>
    </SingleActionModal>
  );
};

export default ConfirmPurgeOrganizationInfoModal;
