import {GoogleMap, GoogleMapProps, useJsApiLoader} from '@react-google-maps/api';
import * as React from 'react';

import {getMapsApiKey} from '../utils/AppParameters';

export type IMapProps = Omit<GoogleMapProps, 'onZoomChanged' | 'onCenterChanged' | 'onBoundsChanged'> & {
  onZoomChanged?: (zoom: number) => void;
  onCenterChanged?: (position?: {lat: number; lng: number}) => void;
  onBoundsChanged?: (bounds?: google.maps.LatLngBounds, zoom?: number) => void;
};

const Map = React.memo(function Map(props: IMapProps) {
  const [mapInstance, setMapInstance] = React.useState<google.maps.Map | null>(null);

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getMapsApiKey()
  });

  return isLoaded ? (
    <GoogleMap
      {...props}
      onLoad={map => {
        setMapInstance(map);

        props.onLoad && props.onLoad(map);
      }}
      onUnmount={map => {
        setMapInstance(null);

        props.onUnmount && props.onUnmount(map);
      }}
      onZoomChanged={() => {
        if (!mapInstance) return;
        props.onZoomChanged && props.onZoomChanged(mapInstance.getZoom() ?? 4);
      }}
      onCenterChanged={() => {
        if (!mapInstance || !props.onCenterChanged) return;
        const center = mapInstance.getCenter();

        // only call onCenterChanged if the center has changed
        if (JSON.stringify(center) === JSON.stringify(props.center)) return;

        props.onCenterChanged({
          lat: center?.lat() ?? 0,
          lng: center?.lng() ?? 0
        });
      }}
      onBoundsChanged={
        props.onBoundsChanged &&
        (() => {
          if (!mapInstance) return;
          props.onBoundsChanged && props.onBoundsChanged(mapInstance.getBounds(), mapInstance.getZoom() ?? 4);
        })
      }
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
});

export {Map};
