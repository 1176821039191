import React from 'react';

import {UserRights} from '../../models/AuthUser';
import {SensorChannelType} from '../../models/Location';
import {SensorReadingType, ISensorReading} from '../../models/SensorReadings';
import {ITableField, NumberField, TimestampFieldWithTimezone} from '../../models/Table';
import {UsageUnit, getUnitLabel} from '../../models/UsageValue';

import {BrandColors} from '../../utils/BrandColors';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationDetails} from '../CardUtils';

import {GasOrWaterCard, DEFAULT_GASWATER_SETTINGS} from './GasOrWaterCard';
import {IGasOrWaterSettings} from './GasOrWaterChart';

function getTableColumns(
  digitsAfterComma: number,
  unit: UsageUnit,
  timezone: string | undefined
): ITableField<ISensorReading>[] {
  return [
    new TimestampFieldWithTimezone('timestamp', 'timestamp', T('gasOrWaterCard.field.timestamp'), timezone || 'UTC'),
    new NumberField('value', T('gasOrWaterCard.field.gas'), {
      unit: getUnitLabel(unit),
      digitsAfterComma
    })
  ];
}

const GasValues = (props: ICardProps<IGasOrWaterSettings>) => {
  const {settings} = props;
  const location = useCardLocationDetails(settings);
  const available = (location && location.hasGas) || false;
  return (
    <GasOrWaterCard
      type={SensorReadingType.Gas}
      gasWaterType={SensorChannelType.Gas}
      available={available}
      getTableColumns={getTableColumns}
      seriesLabel={T('gasOrWaterCard.field.gas')}
      seriesColor={BrandColors.OrangeClementine}
      {...props}
    />
  );
};

const CARD: ICardType<IGasOrWaterSettings> = {
  type: CardTypeKey.GasValues,
  title: 'gasValues.title',
  description: 'gasValues.description',
  categories: [CardCategory.GAS],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_GASWATER_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: GasValues
};
export default CARD;
