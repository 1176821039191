import {useCallback} from 'react';

import {Nav, NavItem} from 'reactstrap';

import {PricingPolicyLimits} from '../../../models/PricingPolicy';
import {T} from '../../../utils/Internationalization';
import {NavLink} from '../../bootstrap';
import {NumberInputGroup, NumberValue} from '../../inputs/NumberInput';
import {SpecificPricingRuleState, TariffState} from '../configurePricingPolicy/FormState';
import {TariffsSelector} from '../TariffsSelector';

import {TariffView} from './TariffView';

interface SpecificPricingProps {
  baseTariff: TariffState;
  currentPrice: SpecificPricingRuleState;
  currency: string;
  onChange: (newPrice: SpecificPricingRuleState) => void;
  limits?: PricingPolicyLimits;
}
export function SpecifyPricing(props: SpecificPricingProps) {
  const {baseTariff, currentPrice, onChange, currency, limits} = props;

  const onUpdate = useCallback(
    (updates: Partial<SpecificPricingRuleState>) => {
      onChange({...currentPrice, ...updates});
    },
    [onChange, currentPrice]
  );

  return (
    <>
      <Nav tabs className="mt-2">
        <NavItem>
          <NavLink active={currentPrice.mode === 'discount'} onClick={() => onUpdate({mode: 'discount'})}>
            {T('pricingPolicies.specific.discount')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={currentPrice.mode === 'tariff'} onClick={() => onUpdate({mode: 'tariff'})}>
            {T('pricingPolicies.specific.tariff')}
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mt-2">
        <TariffView
          title={T('pricingPolicies.specific.publicPrice')}
          info={T('pricingPolicies.specific.publicPrice.info')}
          tariff={baseTariff}
          currency={currency}
        />
        {currentPrice.mode === 'discount' && (
          <NumberInputGroup
            label={T('pricingPolicies.specific.discount')}
            name="discountPercentage"
            value={currentPrice.discountPercentage ?? NumberValue.none()}
            min={0}
            max={100}
            onChange={discountPercentage => onUpdate({discountPercentage})}
            className="mt-3 mb-3"
            suffix="%"
          />
        )}
        {currentPrice.mode === 'discount' && currentPrice.discountPercentage.numberValue !== null && (
          <TariffView
            title={T('pricingPolicies.specific.discountedPrice')}
            tariff={baseTariff}
            currency={currency}
            discount={currentPrice.discountPercentage.numberValue}
          />
        )}
        {currentPrice.mode === 'tariff' && (
          <TariffsSelector
            state={
              currentPrice.tariff ?? {
                costPerKwh: NumberValue.none()
              }
            }
            updateState={tariff => {
              return tariff ? onUpdate({tariff: {...currentPrice.tariff, ...tariff}}) : undefined;
            }}
            currency={currency}
            limits={limits}
          />
        )}
      </div>
    </>
  );
}
