import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import * as React from 'react';

import {cn} from '../../lib/utils';

const buttonVariants = cva(
  'tw-inline-flex tw-items-center tw-justify-start tw-whitespace-nowrap tw-rounded-full tw-text-base tw-font-normal tw-leading-[1.375rem] tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-0 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-0 disabled:tw-pointer-events-none disabled:tw-opacity-50',
  {
    variants: {
      variant: {
        // default: '!tw-font-bold tw-bg-primary tw-text-white hover:tw-bg-neutral-800 hover:tw-text-white',
        primary_default:
          '!tw-font-bold tw-bg-primary !tw-px-[1.25rem] tw-text-white hover:tw-bg-neutral-800 hover:tw-text-white dark:hover:tw-bg-white dark:hover:tw-text-neutral-900 focus-visible:tw-outline focus-visible:tw-outline-0 focus-visible:tw-outline-offset-0 focus-visible:tw-outline-neutral-700 disabled:!tw-opacity-100 disabled:!tw-bg-white disabled:!tw-font-bold disabled:!tw-border disabled:!tw-border-neutral-100 disabled:!tw-text-muted',
        primary_negative:
          'tw-font-bold tw-bg-red-500 tw-text-white hover:tw-bg-red-700 dark:tw-bg-red-500 dark:hover:tw-bg-red-300 active:tw-bg-red-300',
        primary_disabled:
          'tw-font-bold tw-bg-white tw-border tw-border-neutral-300 tw-text-neutral-300 hover:tw-bg-white hover:tw-text-neutral-400 hover:tw-border-neutral-400 disabled:tw-opacity-1 disabled:tw-bg-white disabled:tw-font-bold disabled:tw-border-neutral-100 disabled:tw-text-muted',
        destructive: 'tw-bg-destructive tw-text-destructive-foreground hover:tw-bg-destructive/90',
        outline: 'tw-border tw-border-input tw-bg-background hover:tw-bg-accent hover:tw-text-accent-foreground',
        secondary: 'tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/80',
        secondary_icon_btn:
          'tw-flex tw-flex-col tw-justify-center !tw-w-11.5 !tw-px-[0.875rem] tw-bg-white tw-border tw-border-neutral-200 tw-text-neutral-700 hover:tw-bg-secondaryHover hover:tw-text-neutral-700 hover:tw-border-neutral-300 dark:tw-bg-neutral-600 dark:tw-border dark:tw-border-neutral-600 dark:tw-text-neutral-100 dark:hover:tw-bg-neutral-700 dark:hover:tw-text-neutral-100 dark:hover:tw-border-neutral-300',
        secondary_default:
          '!tw-font-bold !tw-py-[0.5rem] !tw-px-[1.25rem] tw-bg-white tw-border tw-border-neutral-200 tw-text-neutral-700 hover:tw-bg-secondaryHover hover:tw-text-neutral-700 hover:tw-border-neutral-300 data-[active=true]:tw-bg-primary data-[active=true]:tw-text-white dark:tw-bg-neutral-600 dark:tw-border dark:tw-border-neutral-600 dark:tw-text-neutral-100 dark:hover:tw-bg-neutral-700 dark:hover:tw-text-neutral-100 dark:hover:tw-border-neutral-300 disabled:tw-opacity-1 disabled:tw-bg-white disabled:tw-font-bold disabled:tw-border-neutral-100 disabled:tw-text-muted',
        secondary_negative:
          'tw-font-bold tw-bg-white tw-border tw-border-red-500 tw-text-red-500 hover:tw-bg-white hover:tw-text-red-700 hover:tw-border-red-700',
        secondary_disabled:
          '!tw-font-bold !tw-bg-white !tw-border !tw-border-neutral-100 !tw-text-neutral-300 hover:!tw-bg-white hover:!tw-text-neutral-400 hover:!tw-border-neutral-200 disabled:!tw-opacity-100',
        tertiary_default:
          'tw-font-semibold tw-bg-white tw-border-none tw-underline tw-text-neutral-600 hover:tw-no-underline hover:tw-text-neutral-700',
        tertiary_link:
          'tw-rounded-none tw-font-semibold tw-bg-transparent tw-border-none tw-underline tw-text-neutral-600 tw-p-0 hover:tw-no-underline hover:tw-text-neutral-700',
        tertiary_negative:
          'tw-font-semibold tw-bg-white tw-border-none tw-underline tw-text-red-500 hover:tw-no-underline hover:tw-text-red-700',
        tertiary_disabled:
          'tw-font-semibold tw-bg-white tw-border-none tw-underline tw-text-neutral-300 hover:tw-no-underline hover:tw-text-neutral-400',
        ghost: 'hover:tw-bg-accent hover:tw-text-accent-foreground',
        ghost_nav:
          'tw-justify-start tw-font-normal !tw-my-[0.1875rem] !tw-mx-[1rem] !tw-py-[0.5rem] !tw-px-[1rem] tw-text-[#595959] !tw-h-10 !tw-max-h-10 tw-text-ellipsis hover:tw-bg-naturalWhite active:tw-bg-neutral-300',
        ghost_action_btn:
          'tw-text-[1rem] tw-leading-[1rem] tw-bg-transparent tw-border-none tw-text-primary !tw-h-4 !tw-w-4 !tw-p-0 !tw-m-1 !tw-rounded-none',
        link: 'tw-text-primary tw-underline-offset-4 hover:tw-underline'
      },
      size: {
        default: 'tw-h-10 tw-px-4 tw-py-2',
        sm: 'tw-h-9 tw-rounded-md tw-px-3',
        md: 'tw-h-8 tw-px-2.5 tw-py-1.5',
        lg: 'tw-h-10 tw-px-8',
        xl: '!tw-h-11.5 !tw-py-2.75 !tw-px-3.5',
        icon: 'tw-h-10 tw-w-10 tw-justify-center tw-rounded-full'
      }
    },
    defaultVariants: {
      variant: 'primary_default',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  active?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({className, variant, size, asChild = false, active, ...props}, ref) => {
    const Comp = asChild ? Slot : 'button';
    const activeState = active ? 'true' : 'false';
    return (
      <Comp
        className={cn(buttonVariants({variant, size, className}))}
        type="button"
        ref={ref}
        data-active={activeState}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export {Button, buttonVariants};
