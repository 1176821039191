import type {SVGProps} from 'react';
const SvgDesktop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    fillRule="evenodd"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M21 4H3C2.44772 4 2 4.44772 2 5V15C2 15.5523 2.44772 16 3 16H21C21.5523 16 22 15.5523 22 15V5C22 4.44772 21.5523 4 21 4ZM3 2C1.34315 2 0 3.34315 0 5V15C0 16.6569 1.34315 18 3 18H10V22H8C7.44772 22 7 22.4477 7 23C7 23.5523 7.44772 24 8 24H16C16.5523 24 17 23.5523 17 23C17 22.4477 16.5523 22 16 22H14V18H21C22.6569 18 24 16.6569 24 15V5C24 3.34315 22.6569 2 21 2H3Z" />
  </svg>
);
export default SvgDesktop;
