import React, {useMemo, useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {IInputSensorDailyDeltas, IPowerMessage} from '../../livedata/LiveDataModels';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {IDevice} from '../../models/Device';
import {DeviceType, getDeviceIconFor, getDeviceTypeLabelFor} from '../../models/DeviceType';
import {ILocationSummary} from '../../models/Location';
import {T} from '../../utils/Internationalization';

import styles from './DeviceOverview.module.scss';
import {ModuleDeviceDetails, ModuleDeviceRow} from './ModuleDeviceRow';

function getInputModuleState(message: IPowerMessage) {
  const dailyDeltas = message.infinityInputSensorDailyDeltas as IInputSensorDailyDeltas[] | undefined;
  if (dailyDeltas === undefined) return new Map<number, number[]>();

  const inputModuleStates = new Map<number, number[]>();
  for (let deltas of dailyDeltas) {
    inputModuleStates.set(deltas.sensorId, deltas.states || []);
  }
  return inputModuleStates;
}
const NoInputModuleValues = new Map<number, number[]>();

interface LocationDevicesProps {
  onlineStatus?: JSX.Element;
  location: ILocationSummary;
  devices: IDevice[];
  readOnly: boolean;

  liveValues?: IPowerMessage;

  refresh: () => void;
}
export function LocationDevices(props: LocationDevicesProps) {
  const {onlineStatus, location, devices, readOnly, liveValues, refresh} = props;
  const modals = useModals();
  const {api} = useAppContext();
  const locationId = location.id;

  const [expanded, setExpanded] = useState<string[]>([]);

  const inputModuleStates = useMemo(
    () => (liveValues ? getInputModuleState(liveValues) : NoInputModuleValues),
    [liveValues]
  );

  const primaryDevice = location && (
    <tr key={`primary${location.id}`}>
      <td className={styles.tableCellDevice}>
        <img src={getDeviceIconFor(location.deviceType || DeviceType.UNKNOWN)} alt="" width="24" />
        {location.deviceType ? getDeviceTypeLabelFor(location.deviceType) : T('deviceOverview.noDeviceInstalled')}
      </td>
      <td className={styles.tableCellData}>
        <span className="tw-mr-2">{location.serialNumber || T.generic.na()}</span>
        {onlineStatus}
      </td>
    </tr>
  );
  const deviceType = location ? location.deviceType : undefined;
  const deviceRows: JSX.Element[] = useMemo(() => {
    if (deviceType === undefined) return [];

    const toggleCollapse = (serialNumber: string) => {
      const index = expanded.indexOf(serialNumber);
      if (index >= 0) {
        setExpanded(expanded.filter(serial => serial !== serialNumber));
      } else setExpanded([...expanded, serialNumber]);
    };

    const handleClickedDelete = async (device: IDevice) => {
      const confirmed = await modals.show(props => (
        <ConfirmationPromiseModal
          title={T('deviceOverview.deleteModule.title')}
          message={T('deviceOverview.deleteModule.message', {
            serial: device.serialNumber
          })}
          {...props}
        />
      ));
      if (confirmed !== ConfirmationResult.Accept) return;

      try {
        if (device.output !== undefined) {
          await api.deleteOutputModule(locationId, device.serialNumber);
        } else {
          await api.deleteDevice(locationId, device.serialNumber);
        }
        NotificationManager.success(T('deviceOverview.deleteModule.success'));
        refresh();
      } catch {
        NotificationManager.error(T('deviceOverview.deleteModule.failed'));
      }
    };

    const elements: JSX.Element[] = [];
    devices.forEach(module => {
      if (module.type === deviceType || module.type === DeviceType.GasWater) {
        return;
      }

      const moduleId = `M${module.serialNumber}`;
      elements.push(
        <ModuleDeviceRow
          key={`module-${module.serialNumber}`}
          module={module}
          expanded={expanded}
          readOnly={readOnly}
          onClickedDelete={() => handleClickedDelete(module)}
          onToggleCollapse={() => toggleCollapse(moduleId)}
        />
      );
      if (expanded.includes(moduleId)) {
        elements.push(<ModuleDeviceDetails key={moduleId} module={module} inputModuleStates={inputModuleStates} />);
      }
    });
    return elements;
  }, [api, modals, deviceType, devices, expanded, refresh, inputModuleStates, locationId, readOnly]);

  return (
    <>
      {primaryDevice}
      {deviceRows}
    </>
  );
}
