import {Interval} from './UsageValue';

export interface IConsumptionValue {
  timestamp: number;
  serviceLocationId: number;
  aggregation: Interval;
  delta?: number;

  value?: number;
  solar?: number;
  alwaysOn?: number;
  import?: number;
  export?: number;
  selfConsumption?: number;
  selfSufficiency?: number;

  solarForecast?: number;
  loadForecast?: number;

  activePower?: (number | null)[];
  reactivePower?: (number | null)[];
  phaseVoltages?: (number | null)[];
  midPhaseVoltages?: (number | null)[];
  lineVoltages?: (number | null)[];
  midLineVoltages?: (number | null)[];

  phaseVoltagesMin?: (number | null)[];
  phaseVoltagesMax?: (number | null)[];
  lineVoltagesMin?: (number | null)[];
  lineVoltagesMax?: (number | null)[];
  midPhaseVoltagesMin?: (number | null)[];
  midPhaseVoltagesMax?: (number | null)[];
  midLineVoltagesMin?: (number | null)[];
  midLineVoltagesMax?: (number | null)[];

  current?: (number | null)[];
  currentMin?: (number | null)[];
  currentMax?: (number | null)[];

  voltageHarmonics?: (number | null)[][];
  currentHarmonics?: (number | null)[][];

  childData?: IConsumptionValue[];
}

export function getConsumptionChannelMeasurement(
  consumption: IConsumptionValue,
  indexes: (number | null)[],
  powerMultiplier: number
) {
  let value: number | null = null;
  const active = consumption.activePower || [];
  for (let index of indexes) {
    if (index === null) continue;

    const activeValue = active[index];
    if (activeValue !== undefined && activeValue !== null) {
      value = (value || 0) + activeValue * powerMultiplier;
    }
  }
  return value;
}

export function getConsumptionChannelCurrent(consumption: IConsumptionValue, indexes: number[]) {
  let value: number | null = null;
  const current = consumption.current || [];
  const power = consumption.activePower || [];
  for (let index of indexes) {
    const activeValue = current[index];
    const activePower = power[index];
    if (activeValue !== undefined && activeValue !== null) {
      // connect values have no sign...
      const invert =
        activeValue < 0 ? 1 : activePower === undefined || activePower === null ? 1 : Math.sign(activePower);
      value = (value || 0) + activeValue * invert;
    }
  }
  return value;
}

export function getNonNullConsumptionChannelMeasurement(
  consumption: {
    activePower?: (number | null)[];
    childData?: {
      serviceLocationId: number;
      activePower?: (number | null)[];
    }[];
  },
  childLocationId: number | undefined,
  indexes: (number | null)[],
  powerMultiplier: number
) {
  let value: number = 0;
  const active =
    (childLocationId
      ? consumption.childData?.find(x => x.serviceLocationId === childLocationId)?.activePower
      : consumption.activePower) || [];
  for (let index of indexes) {
    if (index === null) continue;
    const activeValue = active[index];
    if (activeValue !== undefined && activeValue !== null) {
      value += activeValue * powerMultiplier;
    }
  }
  return value;
}

export function getNonNullConsumptionChannelMaxCurrentMeasurement(consumption: IConsumptionValue, indexes: number[]) {
  let value: number = 0;
  const current = consumption.current || [];
  for (let index of indexes) {
    const currentValue = current[index];
    if (currentValue !== undefined && currentValue !== null) {
      value = Math.max(value, currentValue);
    }
  }
  return value;
}

export function isForecastValue(value: IConsumptionValue) {
  return (
    value.value === undefined &&
    value.solar === undefined &&
    value.activePower === undefined &&
    value.solarForecast !== undefined
  );
}
