export const enum BessStatus {
  Active = 'active',
  Error = 'error',
  Off = 'off',
  Offline = 'offline',

  Unavailable = 'unavailable',
  Loading = 'loading'
}

export const enum BessMode {
  PeakShaving = 'peak-shaving',
  Optimizer = 'optimizer',
  Inbalance = 'imbalance'
}
