import React, {useMemo} from 'react';

import PeriodSelector, {
  getPeriodRangeForTimezone,
  Period,
  PeriodRoundingMode,
  PeriodSettings
} from '../../components/PeriodSelector';
import Table from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {LoadSource} from '../../models/Load';
import {Interval} from '../../models/UsageValue';
import {useHighLevelConfiguration} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions, ColumnChooser} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import {getColumns} from './Columns';

interface IDailyMeterReadingSettings extends ICardSettingsWithTable {
  period: PeriodSettings;
}

function MyCard(props: ICardProps<IDailyMeterReadingSettings>) {
  const {fetch, settings, updateSettings} = props;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationTimezone = location && location.timeZoneId;
  const [highLevelConfiguration] = useHighLevelConfiguration(fetch, locationId);
  const [data, refreshData] = useCardLoader(
    api => {
      if (locationTimezone === undefined || locationId === undefined) {
        return Promise.resolve(undefined);
      }

      const activePeriod = getPeriodRangeForTimezone(
        settings.period,
        locationTimezone,
        undefined,
        PeriodRoundingMode.EXCLUSIVE
      );
      return api
        .getDailyMeterReadings(locationId, activePeriod.from, activePeriod.to)
        .then(readings => ({...readings, period: activePeriod}));
    },
    [locationId, locationTimezone, settings.period],
    plural('dailyMeterReading'),
    undefined
  );

  const columns = useMemo(
    () => getColumns(locationTimezone || 'UTC', highLevelConfiguration),
    [locationTimezone, highLevelConfiguration]
  );

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refreshData} />
      <PeriodSelector
        settings={settings.period}
        onChanged={period => updateSettings({period})}
        withoutInterval={true}
      />
      {state.ready && data && <ExportCsv items={data.readings} fields={columns} settings={settings.table} />}
    </CardActions>
  );

  const customSettings: CustomSettings<IDailyMeterReadingSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser settings={settings.table} fields={columns} updateSettings={table => updateSettings({table})} />
    );
  };

  let error: string | undefined;
  if (highLevelConfiguration === undefined) {
    error = T('dailyMeterReadings.error.noconfig');
  } else if (!highLevelConfiguration.measurements.some(x => x.sourceType === LoadSource.MID)) {
    error = T('dailyMeterReadings.error.noloads');
  } else if (data === undefined || data.readings.length === 0) {
    error = T('dailyMeterReadings.error.nodata');
  }

  return (
    <CardView actions={actions} error={error} customSettings={customSettings} {...cardViewProps(props)}>
      {data && (
        <Table
          items={data.readings}
          fields={columns}
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
          noun="dailyMeterReading"
        />
      )}
    </CardView>
  );
}

const DEFAULT_SETTINGS: IDailyMeterReadingSettings = {
  period: {
    period: Period.DAYS_7,
    interval: Interval.DAY
  },
  table: {
    pageSize: 10,
    columns: [
      {name: 'time', visible: true},
      {name: 'activePower', visible: true}
    ]
  }
};
const CARD: ICardType<IDailyMeterReadingSettings> = {
  type: CardTypeKey.DailyMeterReadings,
  title: 'dailyMeterReadings.title',
  description: 'dailyMeterReadings.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Required,
  cardClass: MyCard
};
export default CARD;
