import {RowActions} from '../../components/bootstrap';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import {IActivation} from '../../models/ActivationCode';
import {
  CalculatedStringField,
  CheckboxField,
  ComponentField,
  ITableField,
  StringField,
  TimestampField
} from '../../models/Table';
import {T} from '../../utils/Internationalization';

export interface ISelectedSerial {
  code: string;
  serial: string;
}

export interface Listeners {
  onChecked: (activation: IActivation, checked: boolean) => void;
  onClickedRemove: (activation: IActivation) => void;
}

export function getColumns(selected: ISelectedSerial[], listeners: Listeners): ITableField<IActivation>[] {
  const {onChecked, onClickedRemove} = listeners;

  const isChecked = (row: IActivation) => {
    return selected.some(item => item.serial === row.serialNumber);
  };

  return [
    new CheckboxField('selected', '', isChecked, onChecked, {
      autoInsert: 'start',
      width: 20
    }),
    new TimestampField('timestamp', T('shipment.field.timestamp')),
    new CalculatedStringField('serialNumber', T('shipment.field.serialNumber'), item => {
      let result = item.serialNumber;
      if (item.stationSerialNumber) {
        result += ` (${item.stationSerialNumber})`;
      }
      return result;
    }),
    new CalculatedStringField(
      'gatewaySerialNumber',
      T('shipment.field.gatewaySerialNumber'),
      item => item.serialNumber
    ),
    new StringField('stationSerialNumber', T('shipment.field.stationSerialNumber')),
    new StringField('stationArticleCode', T('shipment.field.stationArticleCode')),
    new StringField('orderNumber', T('shipment.orderNr')),
    new ComponentField(
      'actions',
      T('shipment.field.actions'),
      item => {
        return (
          <RowActions>
            <RowActionButton
              action="delete"
              color="link"
              title={T('shipment.actions.remove')}
              onClick={() => onClickedRemove(item)}
            />
          </RowActions>
        );
      },
      {autoInsert: 'end'}
    )
  ];
}
