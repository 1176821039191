import {T} from '../utils/Internationalization';

export interface ICustomPricingGroupMember {
  id?: number;
  token: string;
  alias: string;
  error?: string; // filled in client-side by error handling code
}

export interface IPricingGroup {
  id?: number;
  name: string;
  type: 'CUSTOM' | 'ROAMING' | 'SMAPPEE' | 'DEFAULT'; // DEFAULT is not known server-side
  members?: ICustomPricingGroupMember[];
  policyCount?: number;
}

export const RoamingGroup: IPricingGroup = {
  type: 'ROAMING',
  name: T('pricingGroups.roaming')
};

export const SmappeeGroup: IPricingGroup = {
  type: 'SMAPPEE',
  name: T('pricingGroups.smappee')
};

export function getGroupByType(type: string) {
  switch (type) {
    case 'ROAMING':
      return RoamingGroup;
    case 'SMAPPEE':
      return SmappeeGroup;
    default:
      return null;
  }
}
