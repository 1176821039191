export const enum ValidationError {
  Required = 'REQUIRED',
  InvalidFormat = 'INVALID_FORMAT',
  NotFound = 'NOT_FOUND',
  NotConfigured = 'NOT_CONFIGURED'
}

export interface IValidationError {
  fieldName: string;
  error: ValidationError;
}
