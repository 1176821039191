import {ReactNode} from 'react';

import styles from './VerticalLayout.module.scss';

interface VerticalLayoutProps {
  children: ReactNode;
}
export function VerticalLayout(props: VerticalLayoutProps) {
  const {children} = props;
  return <div className={styles.wrapper}>{children}</div>;
}
