import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Modal, ModalBody, ModalHeader, ModalFooter, Form} from '../../../components/bootstrap';
import {Button} from '../../../components/ui/button';
import {Label} from '../../../components/ui/label';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../../components/ui/tabs';
import {Textarea} from '../../../components/ui/textarea';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {OrganizationLanguage} from '../../../models/Organization';
import {FormProvider} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';

import {EditOrganizationFormState, OrganizationFieldTranslation} from './EditOrganizationModels';

interface TranslationsModalProps extends IPromiseModalProps<void> {
  state: EditOrganizationFormState;
  onChange: (translations: OrganizationFieldTranslation[]) => void;
}
export const TranslationsModal = (props: TranslationsModalProps) => {
  const [isOpen, resolve] = usePromiseModal(props);
  const {state, onChange} = props;

  const [localTranslations, setLocalTranslations] = useState<OrganizationFieldTranslation[]>([]);

  const handleClose = useCallback(() => resolve(), [resolve]);

  const defaultLanguages = useMemo(() => ['nl', 'en', 'fr', 'de'], []);

  useEffect(() => {
    const translations: OrganizationFieldTranslation[] = defaultLanguages.map(language => ({
      language: language as OrganizationLanguage,
      value: state.descriptionTranslations.find(t => t.language === language)?.value || ''
    }));
    setLocalTranslations(translations);
  }, [state.descriptionTranslations, defaultLanguages]);

  const handleDescriptionTranslationChanged = (language: OrganizationLanguage, value: string) => {
    setLocalTranslations(prevTranslations =>
      prevTranslations.map(translation => (translation.language === language ? {...translation, value} : translation))
    );
  };

  const handleSave = () => {
    onChange(localTranslations);
    resolve();
  };

  return (
    <FormProvider>
      <Modal isOpen={isOpen} toggle={handleClose} size="lg" autoFocus={false}>
        <ModalHeader toggle={handleClose}>{T('organisation.description.translations')}</ModalHeader>
        <ModalBody>
          <Tabs defaultValue={OrganizationLanguage.Dutch} className="tw-w-full">
            <TabsList className="tw-grid tw-w-full tw-grid-cols-4">
              {defaultLanguages.map(language => (
                <TabsTrigger key={language} value={language}>
                  {language.toUpperCase()}
                </TabsTrigger>
              ))}
            </TabsList>
            {defaultLanguages.map(language => (
              <TabsContent key={language} value={language}>
                <Form>
                  <div className="tw-grid tw-w-full tw-gap-1.5">
                    <Label
                      htmlFor={`description-${language}`}
                      className="tw-flex tw-justify-between tw-items-center tw-font-bold tw-text-base"
                    >
                      <span>{T('organizations.field.description')}</span>
                    </Label>
                    <Textarea
                      id={`description-${language}`}
                      name={`description-${language}`}
                      value={localTranslations.find(dt => dt.language === language)?.value || ''}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setLocalTranslations(
                          localTranslations.map(t => (t.language === language ? {...t, value: e.target.value} : t))
                        )
                      }
                    />
                    <p className="tw-text-sm tw-text-muted">{T('validatedInput.optional')}</p>
                  </div>
                </Form>
              </TabsContent>
            ))}
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary_negative" onClick={handleClose}>
            {T('generic.action.cancel')}
          </Button>
          <Button type="button" variant="primary_default" onClick={handleSave}>
            {T('modal.save')}
          </Button>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
