import {T} from '../utils/Internationalization';

import {TranslationKey} from '../utils/TranslationTerms';

import {ILoad, ILoadChannel} from './Load';
import {Phase} from './Phase';

export const enum PhaseType {
  Single = 'ONE',
  Split = 'TWO',
  Star = 'THREE_STAR',
  Delta = 'THREE_DELTA'
}
const PHASE_TYPE_LABELS: {[key: string]: TranslationKey} = {
  [PhaseType.Single]: 'phaseType.single',
  [PhaseType.Split]: 'phaseType.split',
  [PhaseType.Star]: 'phaseType.star',
  [PhaseType.Delta]: 'phaseType.delta'
};

export function getPhaseTypeLabel(type: PhaseType) {
  return T(PHASE_TYPE_LABELS[type]);
}

export interface IHighLevelConfiguration {
  locationId: number;
  underConstruction: boolean;
  phaseType: PhaseType;
  nilm: boolean;
  measurements: ILoad[];
}

type IConfigurationChannel = ILoadChannel & {highLevelId: number};

export interface IConfiguration {
  underConstruction: boolean;
  phaseType: PhaseType;
  measurements: IConfigurationChannel[];
}

export function getPhasesForType(type: PhaseType) {
  if (type === PhaseType.Single) {
    return [Phase.L1];
  } else if (type === PhaseType.Split) {
    return [Phase.L1, Phase.L2];
  } else if (type === PhaseType.Delta) {
    return [Phase.L1, Phase.L2];
  } else {
    return [Phase.L1, Phase.L2, Phase.L3];
  }
}
