import React from 'react';

import {Input} from '../../../components/bootstrap';
import {LoadType, LOAD_TYPES, getLoadTypeLabel} from '../../../models/Load';

interface HighLevelTypeSelectorProps {
  readOnly: boolean;
  value: LoadType;
  invalid: boolean;
}
export const HighLevelTypeSelector = (props: HighLevelTypeSelectorProps) => {
  const {value, invalid} = props;
  const options = LOAD_TYPES.map(type => (
    <option key={type} value={type}>
      {getLoadTypeLabel(type)}
    </option>
  ));
  return (
    <Input type="select" name="type" value={value} invalid={invalid} disabled={true}>
      {options}
    </Input>
  );
};
