import React, {useState, useEffect} from 'react';

import {Input as RsInput} from 'reactstrap';

import {Button as RsButton, Input} from '../../../components/bootstrap';
import {Icon} from '../../../components/Icon';
import {Button} from '../../../components/ui/button';
import {Checkbox} from '../../../components/ui/checkbox';
import {Edit} from '../../../components/ui-lib/icons/small';
import {IOrganization, ISearchFieldValue, SearchFieldSpecies} from '../../../models/Organization';
import {T} from '../../../utils/Internationalization';
import {classes} from '../../../utils/Styles';

import {SibelgaTranslationKey, SiT} from '../../custom/SibelgaMeasurementCampaigns/SibelgaModels';

import styles from './SearchFieldEditor.module.scss';

function getType(value: ISearchFieldValue): 'text' | 'number' {
  const {spec} = value;
  switch (spec.species) {
    case SearchFieldSpecies.Integer:
    case SearchFieldSpecies.IntegerOptional:
    case SearchFieldSpecies.Long:
    case SearchFieldSpecies.LongOptional:
      return 'number';
    case SearchFieldSpecies.BigDecimal:
    default:
      return 'text';
  }
}

interface SearchFieldEditorProps {
  value: ISearchFieldValue;
  update: (name: string, value: string | number | boolean | undefined) => Promise<unknown>;
  readOnly: boolean;
  organization?: IOrganization;
}

export default function SearchFieldEditor(props: SearchFieldEditorProps) {
  const {readOnly, update, organization} = props;

  const initialValue = props.value ? props.value.value : '';
  const [originalValue, setOriginalValue] = useState(initialValue);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState((props.value.value || '').toString());
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    setOriginalValue(initialValue || '');
    setValue((initialValue || '').toString());
  }, [initialValue]);

  const handleClickedEdit = () => {
    setEditing(true);
    setValue((originalValue || '').toString());
  };

  const parseValue = () => {
    const {spec} = props.value;

    switch (spec.species) {
      case SearchFieldSpecies.String:
        return value;
      case SearchFieldSpecies.Integer:
        return parseInt(value);
      case SearchFieldSpecies.IntegerOptional:
        return value === '' ? undefined : parseInt(value);
      case SearchFieldSpecies.Long:
        return parseInt(value);
      case SearchFieldSpecies.LongOptional:
        return value === '' ? undefined : parseInt(value);
      case SearchFieldSpecies.BigDecimal:
        //return value === '' ? undefined : parseFloat(value);
        return value; // bigdecimal values are passed as string
      case SearchFieldSpecies.BooleanOptional:
        return value === 'true';
      default:
        return undefined; // not yet supported
    }
  };

  const handleClickedSave = () => {
    const {spec} = props.value;

    setLoading(true);
    const value = parseValue();
    props.update(spec.name, value).then(() => {
      setEditing(false);
      setLoading(false);
    });
  };

  const handleClickedCancel = () => {
    setEditing(false);
  };

  const handleClickedRemove = () => {
    const {spec} = props.value;

    setLoading(true);
    update(spec.name, undefined).then(() => setLoading(false));
  };

  const validateValue = (value: string): string | undefined => {
    const {spec} = props.value;

    switch (spec.species) {
      case SearchFieldSpecies.Integer:
      case SearchFieldSpecies.IntegerOptional:
      case SearchFieldSpecies.Long:
      case SearchFieldSpecies.LongOptional:
        return /^[0-9]+$/.test(value) ? undefined : T('locationConfiguration.property.searchField.integerExpected');
      case SearchFieldSpecies.BigDecimal:
        return /^[0-9]+(\.[0-9]+)?$/.test(value)
          ? undefined
          : T('locationConfiguration.property.searchField.decimalExpected');
      case SearchFieldSpecies.String:
        if (spec.possibleValues && spec.possibleValues.exhaustive) {
          return spec.possibleValues.values.includes(value)
            ? undefined
            : T('locationConfiguration.property.searchField.notAPossibleValue');
        }
    }
    return undefined;
  };

  const inputType = getType(props.value);
  const isValid = validateValue(value) === undefined;

  const handleBooleanChanged = (checked: boolean) => {
    setValue(checked.toString());
  };

  const handleChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const {value} = e.currentTarget;
    if (validationError) {
      const validationError = validateValue(value);
      if (!validationError) setValidationError(undefined);
    }
    setValue(value);
  };

  const handleBlur = () => {
    setValidationError(validateValue(value));
  };

  const {spec} = props.value;
  const isSibelgaTranslated =
    spec.name === 'reason' && organization !== undefined && organization.name.toLocaleLowerCase() === 'sibelga';

  if (editing) {
    let input;
    if (spec.possibleValues) {
      input = (
        <RsInput type="select" value={value} onChange={handleChanged}>
          <option value="" disabled>
            {T('locationConfiguration.property.searchField.choose')}
          </option>
          {spec.possibleValues.values.map(value => (
            <option key={value} value={value}>
              {isSibelgaTranslated ? SiT(value as SibelgaTranslationKey) : value}
            </option>
          ))}
        </RsInput>
      );
    } else if (spec.species === SearchFieldSpecies.BooleanOptional) {
      input = (
        <Checkbox
          id="boolean-optional"
          name="boolean-optional"
          checked={value === 'true'}
          onCheckedChange={handleBooleanChanged}
          wrapperClassName="tw-mb-0 tw-ml-0 tw-mt-4 tw-relative"
          testId="boolean-optional"
        />
      );
    } else {
      input = (
        <>
          <Input
            value={value}
            type={inputType}
            autoWidth={true}
            invalid={validationError !== undefined}
            onChange={handleChanged}
            onBlur={handleBlur}
            style={{display: 'inline-block'}}
          />
          {validationError && (
            <i
              className={classes(Icon.RegularExclamationTriangle, styles['tiny-dash-error'])}
              title={validationError}
            />
          )}
        </>
      );
    }
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {input}
        <RsButton
          color="primary"
          onClick={handleClickedSave}
          disabled={!isValid}
          style={{marginLeft: '0.3em', whiteSpace: 'nowrap'}}
        >
          {T('locationConfiguration.field.save')}
          {loading && <i style={{marginLeft: '0.3em'}} className="fas fa-circle-notch fa-spin" />}
        </RsButton>
        <RsButton onClick={handleClickedCancel} style={{marginLeft: '0.3em'}}>
          {T('locationConfiguration.field.cancelEdit')}
        </RsButton>
      </div>
    );
  } else {
    let element: string | JSX.Element | undefined | number | boolean = originalValue;
    if (spec.species === SearchFieldSpecies.BooleanOptional) {
      element = (
        <Checkbox
          id="sfs-boolean-optional"
          name="sfs-boolean-optional"
          checked={originalValue === 'true'}
          readOnly
          disabled
          wrapperClassName="tw-mt-4 tw-ml-0 tw-mb-0 tw-relative"
          testId="sfs-boolean-optional"
        />
      );
    }
    return (
      <span>
        {isSibelgaTranslated ? SiT(element as SibelgaTranslationKey) : element}{' '}
        {!readOnly && (
          <>
            <Button variant="ghost_action_btn" size="icon" onClick={handleClickedEdit}>
              <Edit />
            </Button>
            {originalValue !== undefined && (
              <RsButton
                color="edit"
                withoutPadding
                title={T('locationConfiguration.searchField.clear')}
                onClick={handleClickedRemove}
                style={{paddingLeft: 7}}
              >
                <span className="fal fa-times" />
              </RsButton>
            )}
          </>
        )}
      </span>
    );
  }
}
