import {Form} from 'reactstrap';

import {FormContextProvider, useFormContext} from '../../../utils/FormContext';

import {T} from '../../../utils/Internationalization';
import {Alert} from '../../bootstrap';
import {TariffsSelector} from '../TariffsSelector';

import {usePricingPolicy} from './PricingPolicyProvider';

function TariffsPolicyConfig() {
  const {formState, updateFormState, currency, organization, limits} = usePricingPolicy();
  const form = useFormContext();

  const vatPercentage =
    formState.taxes.numberValue === null
      ? organization.cpo?.defaultVATPercentage ?? undefined
      : formState.taxes.numberValue;

  return (
    <Form>
      <FormContextProvider value={form}>
        <h2 className="m-0 p-0 pb-3 font-bold">{T('pricingPolicies.add.tariffs.pristine.title')}</h2>

        <Alert color="info" className="mb-2">
          <p>
            {T('pricingPolicies.add.tariffs.description', {
              vat: vatPercentage === undefined ? '?' : vatPercentage.toString()
            })}
          </p>
        </Alert>

        <TariffsSelector state={formState} updateState={updateFormState} currency={currency} limits={limits} />
      </FormContextProvider>
    </Form>
  );
}

export {TariffsPolicyConfig};
