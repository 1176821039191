import type {SVGProps} from 'react';
const SvgChevronForwardDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M1.12217 2.22221C0.692594 2.65178 0.692593 3.34826 1.12217 3.77784L5.34435 8.00004L1.12217 12.2222C0.692594 12.6518 0.692594 13.3483 1.12217 13.7778C1.55175 14.2074 2.24823 14.2074 2.6778 13.7778L7.6778 8.77786C7.88409 8.57157 7.99999 8.29178 7.99999 8.00004C7.99999 7.7083 7.8841 7.42851 7.67781 7.22222L2.67781 2.22221C2.24823 1.79263 1.55175 1.79263 1.12217 2.22221Z" />
    <path d="M8.32218 2.22221C7.89261 2.65178 7.89261 3.34826 8.32218 3.77784L12.5444 8.00004L8.32218 12.2222C7.89261 12.6518 7.89261 13.3483 8.32218 13.7778C8.75176 14.2074 9.44824 14.2074 9.87782 13.7778L14.8778 8.77786C15.0841 8.57157 15.2 8.29178 15.2 8.00004C15.2 7.7083 15.0841 7.42851 14.8778 7.22222L9.87782 2.22221C9.44824 1.79263 8.75176 1.79263 8.32218 2.22221Z" />
  </svg>
);
export default SvgChevronForwardDouble;
