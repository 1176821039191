import React, {useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Form, FormGroup, Label, SingleActionModal} from '../../components/bootstrap';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {useTextInput} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {FormProvider} from '../../utils/FormContext';
import {T} from '../../utils/Internationalization';
import {generateRandomString} from '../../utils/StringUtils';
import {validateRequired} from '../../utils/Validation';

type CreateAPIKeyProps = IPromiseModalProps<boolean>;

export default function CreateAPIKeyModal(props: CreateAPIKeyProps) {
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const [appNameInput, appName, resetAppName] = useTextInput(
    'appName',
    T('createAPIKey.field.appName'),
    '',
    validateRequired
  );
  const [clientIdInput, clientId, resetClientId] = useTextInput(
    'clientId',
    T('createAPIKey.field.clientId'),
    '',
    validateRequired
  );

  const [secret, setSecret] = useState(generateRandomString(10));
  const [confirmation, setConfirmation] = useState('');
  const [info, setInfo] = useState('');

  const handleClickedGenerate = () => setSecret(generateRandomString(10));

  const handleClickedSubmit = async () => {
    try {
      const result = await api.createAPIToken(appName, clientId, secret);
      if (result.success) {
        resetAppName();
        resetClientId();
        setSecret(generateRandomString(10));
        setConfirmation(T('createAPIKey.field.created'));
        setInfo(T('createAPIKey.field.info', {clientId, secret}));
        NotificationManager.success(T('createAPIKey.field.created'));
        resolve(true);
        return undefined;
      } else {
        return T('createAPIKey.failed');
      }
    } catch {
      return T('createAPIKey.failed');
    }
  };

  return (
    <SingleActionModal isOpen={isOpen} onToggle={() => resolve(false)} title="Add API key" action={handleClickedSubmit}>
      <FormProvider>
        <Form>
          {appNameInput}
          {clientIdInput}
          <FormGroup>
            <Label>{T('createAPIKey.field.clientSecret')}</Label>
            <div>
              <Label style={{marginLeft: 0, marginTop: 0, marginBottom: 0}}>
                {secret}
                &nbsp;&nbsp;
                <i className="fal fa-sync-alt" style={{cursor: 'pointer'}} onClick={handleClickedGenerate} />
              </Label>
            </div>
          </FormGroup>
          <FormGroup>
            <FormSaveButton onSave={handleClickedSubmit}>{T('createAPIKey.add')}</FormSaveButton>
          </FormGroup>
        </Form>
        {confirmation}
        <div>
          {info.split('\n').map(i => (
            <p style={{margin: 0}} key={i}>
              {i}
            </p>
          ))}
        </div>
      </FormProvider>
    </SingleActionModal>
  );
}
