import React from 'react';

import {useAppContext} from '../../app/context';
import {Button as RsButton} from '../../components/bootstrap';
import {ILocationSummary} from '../../models/Location';
import {setContextLocationId} from '../../redux/actions/location';
import {T} from '../../utils/Internationalization';

interface ComponentProps {
  location?: ILocationSummary;
}
export default (props: ComponentProps) => {
  const {location} = props;
  const {store, api} = useAppContext();

  const handleClickedParent = () => {
    if (location === undefined) return;

    setContextLocationId(store, api, location.id);
  };

  return (
    <span>
      {` ${T('chargingStations.parent')} `}
      <RsButton
        color="link"
        withoutPadding
        onClick={handleClickedParent}
        style={{fontSize: 'inherit', position: 'relative'}}
      >
        {(location && location.name) || '...'}
      </RsButton>
    </span>
  );
};
