import React, {useEffect, useCallback} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button} from '../../components/bootstrap';
import {Flex} from '../../components/Flex';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {NumberValue, NumberInputGroup} from '../../components/inputs/NumberInput';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {IRetentionPolicyEntry} from '../../models/RetentionPolicy';
import {FormProvider} from '../../utils/FormContext';
import {useRetentionPolicy} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {useObjectState} from '../../utils/ObjectState';
import {classes} from '../../utils/Styles';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationId} from '../CardUtils';
import {cardViewProps, CardView} from '../components/CardView';

import styles from './index.module.scss';

function getPolicyDays(entry: IRetentionPolicyEntry | undefined): NumberValue {
  return entry === undefined || entry.days === undefined || entry.unlimited
    ? NumberValue.none()
    : NumberValue.create(entry.days);
}

function getStoredDays(days: NumberValue): IRetentionPolicyEntry {
  return {
    unlimited: days.numberValue === null,
    days: days.numberValue === null ? undefined : days.numberValue
  };
}

interface FormState {
  fiveMinuteDays: NumberValue;
  hourlyDays: NumberValue;
  dailyDays: NumberValue;
  monthlyDays: NumberValue;
  homeControlEventsDays: NumberValue;
}

function RetentionPolicy(props: ICardProps<ICardSettings>) {
  const {fetch, settings} = props;
  const locationId = useCardLocationId(settings);
  const {api} = useAppContext();

  const [formState, updateFormState] = useObjectState<FormState>({
    fiveMinuteDays: NumberValue.none(),
    hourlyDays: NumberValue.none(),
    dailyDays: NumberValue.none(),
    monthlyDays: NumberValue.none(),
    homeControlEventsDays: NumberValue.none()
  });

  const [retentionPolicy, refresh] = useRetentionPolicy(fetch, locationId);
  const resetValues = useCallback(() => {
    if (retentionPolicy === undefined) return;

    updateFormState({
      fiveMinuteDays: getPolicyDays(retentionPolicy.fiveMinuteValues),
      hourlyDays: getPolicyDays(retentionPolicy.hourlyValues),
      dailyDays: getPolicyDays(retentionPolicy.dailyValues),
      monthlyDays: getPolicyDays(retentionPolicy.monthlyValues),
      homeControlEventsDays: getPolicyDays(retentionPolicy.homeControlEvents)
    });
  }, [retentionPolicy, updateFormState]);
  useEffect(resetValues, [resetValues]);

  const handleClickedSave = async () => {
    if (!locationId) return;

    try {
      await api.updateRetentionPolicy(locationId, {
        fiveMinuteValues: getStoredDays(formState.fiveMinuteDays),
        hourlyValues: getStoredDays(formState.hourlyDays),
        dailyValues: getStoredDays(formState.dailyDays),
        monthlyValues: getStoredDays(formState.monthlyDays),
        homeControlEvents: getStoredDays(formState.homeControlEventsDays)
      });
      refresh();
      NotificationManager.success(T('retentionPolicy.save.success'));
    } catch {
      NotificationManager.error(T('retentionPolicy.save.failed'));
    }
  };

  return (
    <CardView {...cardViewProps(props)}>
      <FormProvider>
        <div className={classes(styles.form, styles.stretch)}>
          <NumberInputGroup
            name="fiveMinuteValues"
            label="5-minute values"
            value={formState.fiveMinuteDays}
            onChange={fiveMinuteDays => updateFormState({fiveMinuteDays})}
            min={0}
            suffix="Days"
            className={styles.daysInput}
            optional={true}
          />
          <NumberInputGroup
            name="hourlyValues"
            label="Hourly values"
            value={formState.hourlyDays}
            onChange={hourlyDays => updateFormState({hourlyDays})}
            min={0}
            suffix="Days"
            className={styles.daysInput}
            optional={true}
          />
          <NumberInputGroup
            name="dailyValues"
            label="Daily values"
            value={formState.dailyDays}
            onChange={dailyDays => updateFormState({dailyDays})}
            min={0}
            suffix="Days"
            className={styles.daysInput}
            optional={true}
          />
          <NumberInputGroup
            name="monthlyDays"
            label="Monthly values"
            value={formState.monthlyDays}
            onChange={monthlyDays => updateFormState({monthlyDays})}
            min={0}
            suffix="Days"
            className={styles.daysInput}
            optional={true}
          />
          <NumberInputGroup
            name="homeControlEvents"
            label="Events"
            value={formState.homeControlEventsDays}
            onChange={homeControlEventsDays => updateFormState({homeControlEventsDays})}
            min={0}
            suffix="Days"
            className={styles.daysInput}
            optional={true}
          />
        </div>
        <Flex justifyContent="start" className={styles.footerActions}>
          <FormSaveButton onSave={handleClickedSave}>Save retention policy</FormSaveButton>
          <Button onClick={resetValues}>Reset</Button>
        </Flex>
      </FormProvider>
    </CardView>
  );
}

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.RetentionPolicy,
  title: 'retentionPolicy.title',
  description: 'retentionPolicy.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: RetentionPolicy
};
export default CARD;
