import React, {useState, useEffect, useCallback, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {LocationOnlineStatusIndicator} from '../../components/OnlineStatusIndicator';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {Device, IDevice} from '../../models/Device';
import {DeviceType, isGatewayDevice} from '../../models/DeviceType';
import {hasPartnerFunctionality} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useChildLocations, useLocationFirmwares} from '../../utils/FunctionalData';
import {T, plural} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useUser} from '../CardUtils';
import {Reload} from '../components/actions';
import {CardActions, Spring} from '../components/CardActions';

import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {DevicePinger} from './DevicePinger';
import styles from './index.module.scss';

import LocationFirmware from './LocationFirmware';
import {RestartButton} from './RestartButton';
import {UpgradeButton} from './UpgradeButton';

function isGatewayUpgradePending(devices: IDevice[]) {
  return devices.some(
    device =>
      isGatewayDevice(device.type) &&
      device.firmware !== undefined &&
      device.firmware.target !== null &&
      device.firmware.target !== device.firmware.current
  );
}

const Firmware = (props: ICardProps<ICardSettings>): JSX.Element => {
  const {fetch, settings} = props;

  const me = useUser();
  const {api, mqtt} = useAppContext();

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const serialNumber = location && location.serialNumber;
  const isServiceDesk = me.isServiceDesk();

  const firmwares = useLocationFirmwares(fetch, locationId);
  const [devices, setDevices] = useState<IDevice[]>(None);
  const [childs, refreshChilds] = useChildLocations(fetch, location);

  const [isRestarting, setRestarting] = useState(false);
  const [upgrading, setUpgrading] = useState(false);

  const fetchData = useCallback(() => {
    if (!locationId) return;

    setRestarting(false);
    setUpgrading(false);

    // Retrieve modules
    fetch('modules', api => api.locations.getModules(locationId, true), plural('module')).then(setDevices);
  }, [fetch, locationId]);

  const refresh = useCallback(() => {
    fetchData();
    refreshChilds();
  }, [fetchData, refreshChilds]);

  const restartDevice = useCallback(async () => {
    if (!locationId) return;

    setRestarting(true);
    try {
      await api.locations.restart(locationId);
      setTimeout(() => setRestarting(false), 10000);
      NotificationManager.success(T('firmware.restart.success'));
    } catch {
      NotificationManager.error(T('firmware.restart.failed'));
    }
  }, [api, locationId]);

  const handleUpdateFirmware = useCallback(
    (locationId: number, type: DeviceType, firmware: string, restart: boolean) => {
      api.updateLocationFirmware(locationId, type, firmware).then(response => {
        const {success} = response;

        // Update every property after success
        if (success) {
          refresh();
          if (restart) restartDevice();
        }
      });
    },
    [api, refresh, restartDevice]
  );

  const handleClickedClearTarget = useCallback(
    (locationId: number, type: DeviceType) => {
      api
        .clearLocationFirmwareTarget(locationId, type)
        .then(() => {
          refresh();
          NotificationManager.success(T('firmware.clearTarget.success'));
        })
        .catch(() => {
          NotificationManager.error(T('firmware.clearTarget.failed'));
        });
    },
    [api, refresh]
  );

  const handleClickedUpgrade = useCallback(
    async (locationId: number) => {
      try {
        await api.locations.upgrade(locationId);
        setUpgrading(true);
        NotificationManager.success(T('firmware.upgrade.success'));
      } catch {
        NotificationManager.error(T('firmware.upgrade.failed'));
      }
    },
    [api]
  );

  useEffect(fetchData, [fetchData]);

  const hasUpgradePending = isGatewayUpgradePending(devices);

  const deviceType = location && location.deviceType;
  const isRebootable = deviceType && Device.isRebootable(deviceType);
  const hasUpgradeNow =
    deviceType === DeviceType.WifiConnect ||
    deviceType === DeviceType.EthernetConnect ||
    deviceType === DeviceType.FourGConnect;

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      {state.ready && location && <LocationOnlineStatusIndicator location={location} />}
      <Spring />
      {state.ready && location && isServiceDesk && (
        <DevicePinger location={location} mqtt={mqtt} mqttSubscriptionIdentifier={me.userId.toString()} />
      )}
      {state.ready && isServiceDesk && isRebootable && (
        <RestartButton isRestarting={isRestarting} onClick={restartDevice} />
      )}
      {state.ready && isServiceDesk && hasUpgradeNow && hasUpgradePending && locationId && (
        <UpgradeButton isUpgrading={upgrading} onClick={() => handleClickedUpgrade(locationId)} />
      )}
    </CardActions>
  );

  let error: string | undefined;
  if (!serialNumber && location?.chargingStation === undefined) error = T('firmware.error.noDevice');

  const childFirmware = useMemo(() => {
    return childs.map(child => (
      <LocationFirmware
        key={child.id}
        isChild={true}
        title={child.name}
        locationId={child.id}
        devices={child.devices || None}
        firmwares={firmwares}
        upgrading={false}
        onUpgrade={() => {}}
        onClearTarget={handleClickedClearTarget}
        onUpdateFirmware={handleUpdateFirmware}
      />
    ));
  }, [childs, firmwares, handleClickedClearTarget, handleUpdateFirmware]);

  return (
    <CardView error={error} actions={actions} {...cardViewProps(props)}>
      <div className={styles.overview}>
        <div className={styles.devices}>
          {locationId && (
            <LocationFirmware
              isChild={false}
              devices={devices}
              locationId={locationId}
              firmwares={firmwares}
              upgrading={upgrading}
              onUpgrade={handleClickedUpgrade}
              onClearTarget={handleClickedClearTarget}
              onUpdateFirmware={handleUpdateFirmware}
            />
          )}
          {childFirmware}
        </div>
      </div>
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.Firmware,
  title: 'firmware.title',
  description: 'firmware.description',
  categories: [CardCategory.CONFIGURATION, CardCategory.LOCATIONS],
  rights: UserRights.User,
  isAvailable: hasPartnerFunctionality,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Required,
  cardClass: Firmware
};
export default CARD;
