import {UsageUnit} from './UsageValue';

export const enum TariffType {
  Flat = 'REGULAR',
  Dynamic = 'TIMESERIES'
}

export interface DynamicTariff {
  market: SpotPriceMarket;
  cost?: number;
  multiplier?: number;
  taxMultiplier?: number;
}

export interface FlatRateTariff {
  type: 'GAS' | 'WATER';
  value: number;
  currency: string;
  unit: UsageUnit;
}

export interface SpotPriceMarket {
  id: number;
  market: string;
  currency: string;
  country: string;
  name: string;
}
