import React from 'react';

import {FormGroup, Input, Col, FormFeedback} from 'reactstrap';

import {Checkbox} from '../../../components/ui/checkbox';
import {Phase} from '../../../models/Phase';

import {TemplateSubmeterErrors} from './CampaignFormState';
import {translateSubmeterNameFluvius} from './FluviusModels';
import {TemplateSubmeter, TemplateSubmeterType} from './Models';

interface CabinTemplateSubmeterProps {
  index: number;
  submeter: TemplateSubmeter;
  errors: TemplateSubmeterErrors;
  readOnly: boolean;

  onChanged: (index: number, submeter: TemplateSubmeter) => void;
}
export const CabinTemplateSubmeter = (props: CabinTemplateSubmeterProps) => {
  const {
    index,
    submeter,
    errors,
    readOnly,

    onChanged
  } = props;

  const {definition, enabled, name, number, phase} = submeter;

  const handleNameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const updated = {
      ...submeter,
      name: e.currentTarget.value,
      nameError: undefined
    };
    onChanged(index, updated);
  };
  const handleNumberChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const updated = {
      ...submeter,
      number: e.currentTarget.value,
      numberError: undefined
    };
    onChanged(index, updated);
  };
  const handlePhaseChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const updated = {
      ...submeter,
      phase: e.currentTarget.value as Phase,
      phaseError: undefined
    };
    onChanged(index, updated);
  };
  const handleEnabledChanged = (checked: boolean) => {
    const updated = {
      ...submeter,
      enabled: checked,
      nameError: undefined,
      numberError: undefined,
      phaseError: undefined
    };
    onChanged(index, updated);
  };

  return (
    <FormGroup row>
      <Checkbox
        name="submeter-name"
        id="submeter-name"
        label={translateSubmeterNameFluvius(definition.name)}
        onCheckedChange={handleEnabledChanged}
        disabled={readOnly}
        wrapperClassName="tw-w-max-[11rem]"
        testId="submeter-name"
      />
      {!definition.main && (
        <Col sm={2}>
          <Input
            placeholder="Nr vertrek"
            type="text"
            disabled={!enabled || readOnly}
            value={number}
            onChange={handleNumberChanged}
            invalid={errors.numberError !== undefined}
          />
          {errors.numberError !== undefined && <FormFeedback>{errors.numberError}</FormFeedback>}
        </Col>
      )}
      {!definition.main && (
        <Col sm={5}>
          <Input
            placeholder="Naam vertrek"
            type="text"
            disabled={!enabled || readOnly}
            value={name}
            onChange={handleNameChanged}
            invalid={errors.nameError !== undefined}
          />
          {errors.nameError !== undefined && <FormFeedback>{errors.nameError}</FormFeedback>}
        </Col>
      )}
      {definition.type === TemplateSubmeterType.Phase1 && (
        <Col sm={2}>
          <Input
            type="select"
            disabled={!enabled || readOnly}
            value={phase}
            onChange={handlePhaseChanged}
            invalid={errors.phaseInvalid}
          >
            <option value={Phase.L1}>Fase 1</option>
            <option value={Phase.L2}>Fase 2</option>
            <option value={Phase.L3}>Fase 3</option>
          </Input>
        </Col>
      )}
    </FormGroup>
  );
};
