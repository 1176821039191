import {IBilling} from '../models/Billing';

import {APIClient} from './APIClient';

export class BillingAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getBillings(organizationId: number): Promise<IBilling[]> {
    return this.client.doGet(`/api/v10/user/bills?organization=${organizationId}`);
  }
}
