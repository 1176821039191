import {Modal, ModalBody, ModalHeader} from '../../components/bootstrap';
import MarkdownView from '../../components/MarkdownView';
import {cn} from '../../lib/utils';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';

import {ChargingStationModule} from '../../models/ChargingStation';
import {T} from '../../utils/Internationalization';

import styles from './InfoMIDModal.module.scss';

interface InfoMIDModalProps extends IPromiseModalProps<boolean> {
  module: ChargingStationModule;
}

const InfoMIDModal = (props: InfoMIDModalProps) => {
  const {module} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(true);

  const MIDBody1 = T('moduleReplacement.installMidMeterSetupDetail.body');

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={handleClose}>{T('moduleReplacement.installMidMeterSetupDetail.subTitle')}</ModalHeader>
      <ModalBody className={cn(styles.content, 'tw-list-decimal')}>
        <MarkdownView content={MIDBody1} />
        <h2>{module?.position?.toString().padStart(3, '0')}</h2>
        <MarkdownView content={T('moduleReplacement.installMidMeterSetupDetail.body2')} />
        <MarkdownView content={T('moduleReplacement.installMidMeterSetupDetail.body3')} />
      </ModalBody>
    </Modal>
  );
};
export default InfoMIDModal;
