import {
  SeriesLineOptions,
  SeriesColumnOptions,
  SeriesArearangeOptions,
  SeriesTooltipOptionsObject
} from 'highcharts/highstock';

import {ActivePeriod} from '../../components/PeriodSelector';
import {KW_DIGITS_AFTER_COMMA} from '../../core/constants';
import {HighOrLowLevel} from '../../models/CardSettings';
import {IConsumptionValue} from '../../models/ConsumptionValue';
import {Device} from '../../models/Device';
import {DeviceType, hasLineVoltages} from '../../models/DeviceType';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {IHistoricalDataChannel, getChannelsFromLoads} from '../../models/HistoricalChannel';
import {IChildLocation, isLocalityParent, LocationFunctionType} from '../../models/Location';
import {getLineVoltageLabel, getPhaseVoltageLabel} from '../../models/Phase';
import {CalculatedNumberField, ColumnGroupKey, ITableField, TimestampFieldWithTimezone} from '../../models/Table';
import {Interval, unitIsPowerInsteadOfConsumption} from '../../models/UsageValue';
import {BrandColors} from '../../utils/BrandColors';
import {getPowerFactor} from '../../utils/Calculations';

import {createPointFormatter, createRangePointFormatter} from '../../utils/GraphUtils';
import {T} from '../../utils/Internationalization';

import {getMonthIndex} from '../../utils/TimestampUtils';

import {CreateDateColumn} from './CreateDateColumn';
import {DelayColumn} from './DelayColumn';
import {ElectricityChartData} from './ElectricityChartData';

import {Series} from './Series';

function getPowerDisplaySettings(bigConsumer: boolean, interval: Interval) {
  const unitAsPower = unitIsPowerInsteadOfConsumption(interval);
  const activeUnit = unitAsPower ? (bigConsumer ? 'kW' : 'W') : bigConsumer ? 'kWh' : 'Wh';
  const reactiveUnit = unitAsPower ? (bigConsumer ? 'kvar' : 'var') : bigConsumer ? 'kvarh' : 'varh';
  const apparentUnit = unitAsPower ? (bigConsumer ? 'kVA' : 'VA') : bigConsumer ? 'kVAh' : 'VAh';
  const digitsAfterComma = bigConsumer ? KW_DIGITS_AFTER_COMMA : 0;
  const powerMultiplier = bigConsumer ? 0.001 : 1;

  return {
    activeUnit,
    reactiveUnit,
    apparentUnit,
    digitsAfterComma,
    powerMultiplier
  };
}

// do not change these. they are stored in the column selector settings
export const electricityColumnIds = {
  consumption: 'value',
  solar: 'solar',
  alwaysOn: 'alwaysOn',
  import: 'import',
  export: 'export',
  selfSufficiency: 'self_sufficiency',
  selfConsumption: 'self_consumption',
  solarForecast: 'solar_forecast',
  consumptionForecast: 'load_forecast',

  phaseVoltage: (phaseIndex: number) => `voltage_${phaseIndex + 1}`,
  phaseVoltageForChild: (childId: number, phaseIndex: number) => `child_voltage_${childId}_${phaseIndex + 1}`,
  phaseVoltageMin: (phaseIndex: number) => `voltage_${phaseIndex + 1}_min`,
  phaseVoltagesMax: (phaseIndex: number) => `voltage_${phaseIndex + 1}_max`,
  phaseVoltagesMinMax: (phaseIndex: number) => `voltage_${phaseIndex + 1}_minmax`,
  lineVoltage: (phaseIndex: number) => `line_${phaseIndex + 1}`,
  lineVoltageForChild: (childId: number, phaseIndex: number) => `child_line_${childId}_${phaseIndex + 1}`,
  lineVoltageMin: (phaseIndex: number) => `line_${phaseIndex + 1}_min`,
  lineVoltageMax: (phaseIndex: number) => `line_${phaseIndex + 1}_max`,
  lineVoltageMinMax: (phaseIndex: number) => `line_${phaseIndex + 1}_minmax`,
  lineCurrent: (phaseIndex: number) => `current_${phaseIndex + 1}`,

  activeForChannel: (id: number) => `channel_id_${id}`,
  reactiveForChannel: (id: number) => `channel_id_${id}_reactive`,
  currentForChannel: (id: number) => `channel_id_${id}_current`,
  apparentForChannel: (id: number) => `channel_id_${id}_apparent`,
  powerFactorForChannel: (id: number) => `channel_id_${id}_pf`,

  currentMinForChannel: (id: number) => `channel_id_${id}_current_min`,
  currentMaxForChannel: (id: number) => `channel_id_${id}_current_max`,
  currentMinMaxForChannel: (id: number) => `channel_id_${id}_current_minmax`,

  voltageForChannel: (id: number) => `channel_id_${id}_voltage`,

  activeForLoad: (id: number) => `load_${id}`,
  reactiveForLoad: (id: number) => `load_${id}_reactive`,
  currentForLoad: (id: number) => `load_${id}_current`,
  apparentForLoad: (id: number) => `load_${id}_apparent`
};

export function getTableColumns(
  functionType: LocationFunctionType,
  deviceType: DeviceType | undefined,
  phaseType: PhaseType,
  bigConsumer: boolean,
  timeZoneId: string | undefined,
  hasSolar: boolean,
  isHelpdesk: boolean,
  isDualUltraAssembly: boolean,
  level: HighOrLowLevel,
  channels: IHistoricalDataChannel[],
  loads: IHistoricalDataChannel[],
  withMinMax: boolean,
  withSolarForecast: boolean,
  hasStorage: boolean,
  interval: Interval,
  childs: IChildLocation[]
): ITableField<IConsumptionValue>[] {
  const isPro = deviceType !== undefined && Device.isPro(deviceType);
  const isInfinity = (deviceType !== undefined && Device.isInfinity(deviceType)) || isDualUltraAssembly;
  const hasCTs = isPro || isInfinity;

  if (isDualUltraAssembly) {
    channels = getChannelsFromLoads(loads);
  }

  // Default to low level grouping
  if (!isInfinity) level = HighOrLowLevel.Low;

  const isLowLevel = level === HighOrLowLevel.Low;
  const {activeUnit, reactiveUnit, apparentUnit, powerMultiplier, digitsAfterComma} = getPowerDisplaySettings(
    bigConsumer,
    interval
  );

  const columns: ITableField<IConsumptionValue>[] = [
    new TimestampFieldWithTimezone(
      'timestamp',
      'timestamp',
      T('electricityValues.field.timestamp'),
      timeZoneId || 'UTC',
      {alwaysVisible: true, autoInsert: 'start'}
    )
  ];
  if (isHelpdesk) {
    columns.push(new CreateDateColumn(timeZoneId || 'UTC'));
    columns.push(new DelayColumn());
  }

  columns.push(
    new CalculatedNumberField(
      electricityColumnIds.consumption,
      deviceType === DeviceType.P1S1 ? T('defaultNames.gridLoad') : T('electricityValues.field.consumption'),
      row => (row.value === undefined ? undefined : row.value * powerMultiplier),
      {unit: activeUnit, digitsAfterComma}
    )
  );
  if (hasSolar && deviceType !== DeviceType.P1S1) {
    columns.push(
      new CalculatedNumberField(
        'solar',
        T('electricityValues.field.solar'),
        row => (row.solar === undefined ? undefined : row.solar * powerMultiplier),
        {unit: activeUnit, digitsAfterComma}
      )
    );
  }
  if (hasSolar && withSolarForecast && deviceType !== DeviceType.P1S1) {
    columns.push(
      new CalculatedNumberField(
        'solar_forecast',
        T('electricityValues.field.solarForecast'),
        row => {
          const value = row.solarForecast;
          return value === undefined ? undefined : value * powerMultiplier;
        },
        {unit: activeUnit, digitsAfterComma}
      )
    );
    columns.push(
      new CalculatedNumberField(
        'load_forecast',
        T('electricityValues.field.loadForecast'),
        row => {
          const value = row.loadForecast;
          return value === undefined ? undefined : value * powerMultiplier;
        },
        {unit: activeUnit, digitsAfterComma}
      )
    );
  }

  columns.push(
    new CalculatedNumberField(
      electricityColumnIds.alwaysOn,
      T('electricityValues.field.alwaysOn'),
      row => (row.alwaysOn === undefined ? undefined : row.alwaysOn * powerMultiplier),
      {unit: activeUnit, digitsAfterComma}
    )
  );

  if (hasSolar || hasStorage || deviceType === DeviceType.P1S1) {
    columns.push(
      new CalculatedNumberField(
        electricityColumnIds.export,
        T('electricityValues.field.export'),
        row => (row.export === undefined ? undefined : row.export * powerMultiplier),
        {unit: activeUnit, digitsAfterComma}
      )
    );
    columns.push(
      new CalculatedNumberField(
        electricityColumnIds.import,
        T('electricityValues.field.import'),
        row => (row.import === undefined ? undefined : row.import * powerMultiplier),
        {unit: activeUnit, digitsAfterComma}
      )
    );
    if (deviceType !== DeviceType.P1S1) {
      columns.push(
        new CalculatedNumberField(
          electricityColumnIds.selfSufficiency,
          T('electricityValues.field.selfSufficiency'),
          row => row.selfSufficiency,
          {unit: '%', digitsAfterComma: 0}
        )
      );
      columns.push(
        new CalculatedNumberField(
          electricityColumnIds.selfConsumption,
          T('electricityValues.field.selfConsumption'),
          row => row.selfConsumption,
          {unit: '%', digitsAfterComma: 0}
        )
      );
    }
  }

  if (deviceType === DeviceType.P1S1) {
    if (phaseType === PhaseType.Single) {
      let name = T('electricityValues.field.current.single');
      columns.push(
        new CalculatedNumberField(electricityColumnIds.lineCurrent(0), name, row => (row.current || [])[0] || undefined)
      );
    } else {
      const phases = [0, 1, 2];
      phases.forEach(phase => {
        let name = T('electricityValues.field.current', {
          index: (phase + 1).toString()
        });
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.lineCurrent(phase),
            name,
            row => (row.current || [])[phase] || undefined
          )
        );
      });
    }
  }

  if (hasCTs && isLowLevel) {
    // Add columns for each channel
    for (let channel of channels) {
      const {name = T('defaultNames.gridLoad'), id} = channel;

      columns.push(
        new CalculatedNumberField(
          electricityColumnIds.activeForChannel(id),
          name,
          row => {
            const value = channel.getActivePower(row);
            return value === null ? undefined : value;
          },
          {
            byline: T('electricityValues.field.byline.active'),
            group: ColumnGroupKey.ChannelsActive,
            unit: activeUnit,
            digitsAfterComma
          }
        )
      );
      if (channel.hasReactive) {
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.reactiveForChannel(id),
            name,
            row => {
              const value = channel.getReactivePower(row);
              return value === null ? undefined : value;
            },
            {
              byline: T('electricityValues.field.byline.reactive'),
              group: ColumnGroupKey.ChannelsReactive,
              unit: reactiveUnit,
              digitsAfterComma
            }
          )
        );
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.powerFactorForChannel(id),
            name,
            row => {
              const activePower = channel.getActivePower(row);
              const reactivePower = channel.getReactivePower(row);
              return activePower === null || reactivePower === null
                ? undefined
                : getPowerFactor(activePower, reactivePower);
            },
            {
              byline: T('electricityValues.field.byline.powerFactor'),
              tooltip: T('electricityValues.field.tooltip.powerFactor'),
              unit: 'PF',
              group: ColumnGroupKey.ChannelsPowerFactor,
              digitsAfterComma: 2
            }
          )
        );
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.apparentForChannel(id),
            name,
            row => {
              const value = channel.getApparentPower(row);
              return value === null ? undefined : value;
            },
            {
              byline: T('electricityValues.field.byline.apparent'),
              group: ColumnGroupKey.ChannelsApparent,
              unit: apparentUnit,
              digitsAfterComma
            }
          )
        );
      }
      if (isInfinity) {
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.currentForChannel(id),
            name,
            row => {
              const value = channel.getCurrent(row);
              return value === null ? undefined : value;
            },
            {
              byline: T('electricityValues.field.byline.current'),
              group: ColumnGroupKey.ChannelsCurrent,
              unit: 'A',
              digitsAfterComma: 1
            }
          )
        );
      }

      if (withMinMax) {
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.currentMinForChannel(id),
            `${name} (${T('electricityValues.field.suffix.min')})`,
            row => {
              const value = channel.getCurrentMin(row);
              return value === null ? undefined : value;
            },
            {
              follows: electricityColumnIds.currentForChannel(id),
              unit: 'A',
              digitsAfterComma: 1
            }
          )
        );
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.currentMaxForChannel(id),
            `${name} (${T('electricityValues.field.suffix.max')})`,
            row => {
              const value = channel.getCurrentMax(row);
              return value === null ? undefined : value;
            },
            {
              follows: electricityColumnIds.currentForChannel(id),
              unit: 'A',
              digitsAfterComma: 1
            }
          )
        );
      }
      if (channel.hasVoltages) {
        // MID load also provides voltages
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.voltageForChannel(id),
            name,
            row => {
              const value = channel.getPhaseVoltage(row);
              return value === null ? undefined : value;
            },
            {
              byline: T('electricityValues.field.byline.voltage'),
              group: ColumnGroupKey.PhaseVoltage,
              unit: 'V',
              digitsAfterComma: 1
            }
          )
        );
      }
    }
  }
  if (hasCTs && !isLowLevel) {
    for (let load of loads) {
      const name = load.name.trim();

      // Construct columns
      columns.push(
        new CalculatedNumberField(
          electricityColumnIds.activeForLoad(load.id),
          name,
          row => {
            const value = load.getActivePower(row);
            return value === null ? undefined : value;
          },
          {
            byline: T('electricityValues.field.byline.active'),
            group: ColumnGroupKey.LoadsActive,
            unit: activeUnit,
            digitsAfterComma
          }
        )
      );
      if (load.hasReactive) {
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.reactiveForLoad(load.id),
            name,
            row => {
              const value = load.getReactivePower(row);
              return value === null ? undefined : value;
            },
            {
              byline: T('electricityValues.field.byline.reactive'),
              group: ColumnGroupKey.LoadsReactive,
              unit: reactiveUnit,
              digitsAfterComma
            }
          )
        );
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.apparentForLoad(load.id),
            name,
            row => {
              const value = load.getApparentPower(row);
              return value === null ? undefined : value;
            },
            {
              byline: T('electricityValues.field.byline.apparent'),
              group: ColumnGroupKey.LoadsApparent,
              unit: apparentUnit,
              digitsAfterComma
            }
          )
        );
      }
    }
  }

  // Add voltages columns
  if (hasCTs || deviceType === DeviceType.P1S1) {
    const numberOfPhases = phaseType === PhaseType.Single ? 1 : 3;
    for (let i = 0; i < numberOfPhases; i++) {
      if (!isLocalityParent(functionType)) {
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.phaseVoltage(i),
            numberOfPhases === 1
              ? T('electricityValues.field.phaseVoltageSingle')
              : T('electricityValues.field.phaseVoltage', {
                  phase: getPhaseVoltageLabel(i)
                }),
            row => {
              if (!row.phaseVoltages) return undefined;

              const value = row.phaseVoltages[i];
              return value === null ? undefined : value;
            },
            {
              group: ColumnGroupKey.PhaseVoltage,
              unit: 'V',
              digitsAfterComma: 1
            }
          )
        );
        if (withMinMax) {
          columns.push(
            new CalculatedNumberField(
              electricityColumnIds.phaseVoltageMin(i),
              T('electricityValues.field.phaseVoltageMin', {
                phase: getPhaseVoltageLabel(i)
              }),
              row => {
                if (!row.phaseVoltagesMin) return undefined;

                const value = row.phaseVoltagesMin[i];
                return value === null ? undefined : value;
              },
              {
                follows: electricityColumnIds.phaseVoltage(i),
                unit: 'V',
                digitsAfterComma: 1
              }
            )
          );
          columns.push(
            new CalculatedNumberField(
              electricityColumnIds.phaseVoltagesMax(i),
              T('electricityValues.field.phaseVoltageMax', {
                phase: getPhaseVoltageLabel(i)
              }),
              row => {
                if (!row.phaseVoltagesMax) return undefined;

                const value = row.phaseVoltagesMax[i];
                return value === null ? undefined : value;
              },
              {
                follows: electricityColumnIds.phaseVoltage(i),
                unit: 'V',
                digitsAfterComma: 1
              }
            )
          );
        }
      }
      if (deviceType !== undefined && hasLineVoltages(deviceType, phaseType)) {
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.lineVoltage(i),
            T('electricityValues.field.lineVoltage', {
              phase: getLineVoltageLabel(i)
            }),
            row => {
              const value = row.lineVoltages === undefined ? undefined : row.lineVoltages[i];
              return value === null ? undefined : value;
            },
            {group: ColumnGroupKey.LineVoltage, unit: 'V', digitsAfterComma: 1}
          )
        );
        if (withMinMax) {
          columns.push(
            new CalculatedNumberField(
              electricityColumnIds.lineVoltageMin(i),
              T('electricityValues.field.lineVoltageMin', {
                phase: getLineVoltageLabel(i)
              }),
              row => {
                if (!row.lineVoltagesMin) return undefined;

                const value = row.lineVoltagesMin[i - 1];
                return value === null ? undefined : value;
              },
              {
                follows: electricityColumnIds.lineVoltage(i),
                unit: 'V',
                digitsAfterComma: 1
              }
            )
          );
          columns.push(
            new CalculatedNumberField(
              electricityColumnIds.lineVoltageMax(i),
              T('electricityValues.field.lineVoltageMax', {
                phase: getLineVoltageLabel(i)
              }),
              row => {
                if (!row.lineVoltagesMax) return undefined;

                const value = row.lineVoltagesMax[i];
                return value === null ? undefined : value;
              },
              {
                follows: electricityColumnIds.lineVoltage(i),
                unit: 'V',
                digitsAfterComma: 1
              }
            )
          );
        }
      }
    }
  }

  for (var child of childs) {
    const numberOfPhases = phaseType === PhaseType.Single ? 1 : 3;
    const childLocationId = child.id;
    const childName = child.name || `#${child.serialNumber || child.id}`;
    for (let i = 0; i < numberOfPhases; i++) {
      columns.push(
        new CalculatedNumberField(
          electricityColumnIds.phaseVoltageForChild(childLocationId, i),
          numberOfPhases === 1
            ? T('electricityValues.field.phaseVoltageForChildSingle', {
                name: childName
              })
            : T('electricityValues.field.phaseVoltageForChild', {
                name: childName,
                phase: getPhaseVoltageLabel(i)
              }),
          row => {
            const child = (row.childData || []).find(x => x.serviceLocationId === childLocationId);
            if (!child || !child.phaseVoltages) return undefined;

            const value = child.phaseVoltages[i];
            return value === null ? undefined : value;
          },
          {group: ColumnGroupKey.PhaseVoltage, unit: 'V', digitsAfterComma: 1}
        )
      );
      if (deviceType !== undefined && hasLineVoltages(deviceType, phaseType)) {
        columns.push(
          new CalculatedNumberField(
            electricityColumnIds.lineVoltageForChild(childLocationId, i),
            numberOfPhases === 1
              ? T('electricityValues.field.lineVoltageForChildSingle', {
                  name: childName
                })
              : T('electricityValues.field.lineVoltageForChild', {
                  name: childName,
                  phase: getLineVoltageLabel(i)
                }),
            row => {
              const child = (row.childData || []).find(x => x.serviceLocationId === childLocationId);
              if (!child || !child.lineVoltages) return undefined;

              const value = child.lineVoltages[i];
              return value === null ? undefined : value;
            },
            {group: ColumnGroupKey.LineVoltage, unit: 'V', digitsAfterComma: 1}
          )
        );
      }
    }
  }

  return columns;
}

export type SeriesType = 'line' | 'column';
export type SeriesOptionsType = SeriesLineOptions | SeriesColumnOptions;

export class ColumnBuilder {
  unit: string;
  varUnit: string;
  apparentUnit: string;
  data: ElectricityChartData;
  series: string[];
  type: SeriesType;
  interval: Interval;
  startMonthIndex: number;
  timezone: string;
  deviceType: DeviceType;
  phaseType: PhaseType;
  valueDecimals: number;

  colorIndex: number = 0;
  colors: string[] = [
    '#2f7ed8',
    '#0d233a',
    '#8bbc21',
    '#910000',
    '#1aadce',
    '#492970',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a'
  ];

  constructor(
    data: ElectricityChartData,
    series: string[],
    type: SeriesType,
    period: ActivePeriod,
    deviceType: DeviceType,
    phaseType: PhaseType,
    bigConsumer: boolean
  ) {
    const {activeUnit, reactiveUnit, apparentUnit, digitsAfterComma} = getPowerDisplaySettings(
      bigConsumer,
      period.interval
    );
    // note: all data is pre-multiplied in ElectricityChartData

    this.deviceType = deviceType;
    this.phaseType = phaseType;
    this.unit = activeUnit;
    this.varUnit = reactiveUnit;
    this.apparentUnit = apparentUnit;
    this.valueDecimals = digitsAfterComma;
    this.data = data;
    this.series = series;
    this.type = type;
    this.interval = period.interval;
    this.startMonthIndex = getMonthIndex(period.from, period.timezone);
    this.timezone = period.timezone;
  }

  createConsumptionColumn(): [SeriesOptionsType, string] {
    const name =
      this.deviceType === DeviceType.P1S1 ? T('defaultNames.gridLoad') : T('electricityValues.field.consumption');
    const column: SeriesOptionsType = {
      id: electricityColumnIds.consumption,
      name: name + this._getUnitSuffix(),
      color: BrandColors.OrangeSunglow, // Sunglow
      type: this.type,
      yAxis: 0,
      data: this._getSeriesData(this.data.getConsumption()),
      tooltip: {
        pointFormatter: createPointFormatter(name, this.unit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(electricityColumnIds.consumption)
    } as SeriesOptionsType;
    return [column, name];
  }

  createSolarColumn(): [SeriesOptionsType, string] {
    const name = T('electricityValues.field.solar') + this._getUnitSuffix();
    const column: SeriesOptionsType = {
      id: electricityColumnIds.solar,
      name,
      color: BrandColors.GreenAtlantis, // Atlantis
      type: this.type,
      yAxis: 0,
      data: this._getSeriesData(this.data.getSolar()),
      tooltip: {
        pointFormatter: createPointFormatter(T('electricityValues.field.solar'), this.unit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(electricityColumnIds.solar)
    } as SeriesOptionsType;
    return [column, name];
  }

  createSolarForecastColumn(): SeriesOptionsType {
    const name = T('electricityValues.field.solarForecast') + this._getUnitSuffix();
    return {
      id: electricityColumnIds.solarForecast,
      name,
      color: BrandColors.GreenAtlantis, // Atlantis
      type: this.type,
      dashStyle: 'ShortDash',
      yAxis: 0,
      data: this._getSeriesData(this.data.getSolarForecast()),
      tooltip: {
        pointFormatter: createPointFormatter(T('electricityValues.field.solarForecast'), this.unit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(electricityColumnIds.solarForecast)
    } as SeriesOptionsType;
  }

  createConsumptionForecastColumn(): SeriesOptionsType {
    const name = T('electricityValues.field.loadForecast') + this._getUnitSuffix();
    return {
      id: electricityColumnIds.consumptionForecast,
      name,
      color: BrandColors.OrangeSunglow, // Sunglow
      type: this.type,
      dashStyle: 'ShortDash',
      yAxis: 0,
      data: this._getSeriesData(this.data.getConsumptionForecast()),
      tooltip: {
        pointFormatter: createPointFormatter(T('electricityValues.field.loadForecast'), this.unit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(electricityColumnIds.consumptionForecast)
    } as SeriesOptionsType;
  }

  createAlwaysOnColumn(): [SeriesLineOptions, string] {
    const name = T('electricityValues.field.alwaysOn') + this._getUnitSuffix();
    const column: SeriesLineOptions = {
      id: electricityColumnIds.alwaysOn,
      name,
      color: BrandColors.GreenEden, // Eden
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 0,
      data: this._getSeriesData(this.data.getAlwaysOn()),
      tooltip: {
        pointFormatter: createPointFormatter(T('electricityValues.field.alwaysOn'), this.unit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(electricityColumnIds.alwaysOn)
    };
    return [column, name];
  }

  createImportColumn(): SeriesLineOptions {
    return {
      id: electricityColumnIds.import,
      name: T('electricityValues.field.import') + this._getUnitSuffix(),
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 0,
      data: this._getSeriesData(this.data.getImport()),
      tooltip: {
        pointFormatter: createPointFormatter(T('electricityValues.field.import'), this.unit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(electricityColumnIds.import)
    };
  }

  createExportColumn(): SeriesLineOptions {
    return {
      id: electricityColumnIds.export,
      name: T('electricityValues.field.export') + this._getUnitSuffix(),
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 0,
      data: this._getSeriesData(this.data.getExport()),
      tooltip: {
        pointFormatter: createPointFormatter(T('electricityValues.field.export'), this.unit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(electricityColumnIds.export)
    };
  }

  createSelfSufficiencyColumn(): SeriesLineOptions {
    return {
      id: electricityColumnIds.selfSufficiency,
      name: `${T('electricityValues.field.selfSufficiency')} [%]`,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 4,
      data: this._getSeriesData(this.data.getSelfSufficiency()),
      tooltip: {
        pointFormatter: createPointFormatter(T('electricityValues.field.selfSufficiency'), '%', 0)
      },
      visible: this._isSeriesSelected(electricityColumnIds.selfSufficiency)
    };
  }

  createSelfConsumptionColumn(): SeriesLineOptions {
    return {
      id: electricityColumnIds.selfConsumption,
      name: `${T('electricityValues.field.selfConsumption')} [%]`,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 4,
      data: this._getSeriesData(this.data.getSelfConsumption()),
      tooltip: {
        pointFormatter: createPointFormatter(T('electricityValues.field.selfConsumption'), '%', 0)
      },
      visible: this._isSeriesSelected(electricityColumnIds.selfConsumption)
    };
  }

  createLineCurrentColumn(phase: number, phaseType: PhaseType): SeriesLineOptions {
    const id = electricityColumnIds.lineCurrent(phase);
    const name =
      phaseType === PhaseType.Single
        ? T('electricityValues.field.current.single')
        : T('electricityValues.field.current', {index: (phase + 1).toString()});
    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      yAxis: 3,
      data: this._getSeriesData(this.data.getLineCurrent(phase)),
      tooltip: {
        pointFormatter: createPointFormatter(name, 'A', 1)
      },
      visible: this._isSeriesSelected(id)
    };
  }

  createChannelActiveColumn(channel: IHistoricalDataChannel): SeriesLineOptions {
    const name = `${channel.name}${this._getUnitSuffix()}`;
    const id = electricityColumnIds.activeForChannel(channel.id);
    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 0,
      data: this._getSeriesData(this.data.getActive(channel)),
      tooltip: {
        pointFormatter: createPointFormatter(channel.name, this.unit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(id)
    };
  }

  createChannelReactiveColumn(channel: IHistoricalDataChannel): SeriesLineOptions {
    const id = electricityColumnIds.reactiveForChannel(channel.id);
    const name = `${channel.name} [${this.varUnit}]`;
    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 1,
      data: this._getSeriesData(this.data.getReactive(channel)),
      tooltip: {
        pointFormatter: createPointFormatter(channel.name, this.varUnit, this.valueDecimals)
      },
      visible: this._isSeriesSelected(id)
    };
  }

  createChannelCurrentColumn(channel: IHistoricalDataChannel): SeriesLineOptions {
    const id = electricityColumnIds.currentForChannel(channel.id);
    const name = `${channel.name} [A]`;
    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 3,
      data: this._getSeriesData(this.data.getCurrent(channel)),
      tooltip: {
        pointFormatter: createPointFormatter(channel.name, 'A', 1)
      },
      visible: this._isSeriesSelected(id)
    };
  }

  createChannelCurrentMinMaxColumn(channel: IHistoricalDataChannel, dummy: boolean): SeriesArearangeOptions {
    const id = electricityColumnIds.currentMinMaxForChannel(channel.id);
    const name = `${channel.name} [A] min-max`;
    const data = dummy ? [] : this._getSeriesData(this.data.getCurrentMinMaxForChannel(channel));
    return {
      id,
      name,
      color: this._getLastColor(),
      opacity: 0.4,
      type: 'arearange',
      yAxis: 3,
      data,
      tooltip: {
        pointFormatter: createRangePointFormatter(
          `${channel.name}, ${T('electricityValues.field.suffix.minMax')}`,
          'A',
          1
        )
      },
      showInLegend: false,
      visible: this._isSeriesSelected(electricityColumnIds.currentForChannel(channel.id))
    };
  }

  createLoadActiveColumn(load: IHistoricalDataChannel): SeriesLineOptions {
    const id = `load_${load.id}`;
    return {
      id,
      name: load.name + this._getUnitSuffix(),
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 0,
      data: this._getSeriesData(this.data.getActive(load)),
      tooltip: {
        pointFormatter: createPointFormatter(load.name, this.unit, this.valueDecimals),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createLoadReactiveColumn(load: IHistoricalDataChannel): SeriesLineOptions {
    const id = `load_${load.id}_reactive`;
    return {
      id,
      name: `${load.name} [${this.varUnit}]`,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 1,
      data: this._getSeriesData(this.data.getReactive(load)),
      tooltip: {
        pointFormatter: createPointFormatter(load.name, this.varUnit, this.valueDecimals),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createLoadCurrentColumn(load: IHistoricalDataChannel): SeriesLineOptions {
    const id = electricityColumnIds.currentForLoad(load.id);
    return {
      id,
      name: `${load.name} [A]`,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 3,
      data: this._getSeriesData(this.data.getCurrent(load)),
      tooltip: {
        pointFormatter: createPointFormatter(load.name, 'A', 1),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createLoadApparentColumn(load: IHistoricalDataChannel): SeriesLineOptions {
    const name = `${load.name} [${this.apparentUnit}]`;
    const id = `load_${load.id}_apparent`;
    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      yAxis: 6,
      data: this._getSeriesData(this.data.getApparent(load)),
      tooltip: {
        valueDecimals: this.valueDecimals,
        pointFormatter: createPointFormatter(load.name, this.apparentUnit, this.valueDecimals),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createPowerFactorColumn(channel: IHistoricalDataChannel): SeriesLineOptions {
    const id = electricityColumnIds.powerFactorForChannel(channel.id);
    const name = `${channel.name} [PF]`;
    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 5,
      data: this._getSeriesData(this.data.getPowerFactor(channel)),
      tooltip: {
        pointFormatter: createPointFormatter(`${channel.name} [PF]`, '', 2)
      },
      visible: this._isSeriesSelected(id)
    };
  }

  createChannelApparentColumn(channel: IHistoricalDataChannel): SeriesLineOptions {
    const id = electricityColumnIds.apparentForChannel(channel.id);
    const name = `${channel.name} [${this.apparentUnit}]`;
    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      yAxis: 6,
      data: this._getSeriesData(this.data.getApparent(channel)),
      tooltip: {
        pointFormatter: createPointFormatter(channel.name, this.apparentUnit, this.valueDecimals),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createChannelVoltageColumn(channel: IHistoricalDataChannel): SeriesLineOptions {
    const id = electricityColumnIds.voltageForChannel(channel.id);
    const name = `${channel.name} [V]`;
    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      yAxis: 2,
      data: this._getSeriesData(this.data.getChannelPhaseVoltage(channel)),
      tooltip: {
        pointFormatter: createPointFormatter(channel.name || T('defaultNames.gridLoad'), 'V', 1),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createPhaseVoltageColumn(phaseIndex: number): SeriesLineOptions {
    const id = electricityColumnIds.phaseVoltage(phaseIndex);
    const name =
      this.phaseType === PhaseType.Single
        ? T('electricityValues.field.phaseVoltageSingle')
        : T('electricityValues.field.phaseVoltage', {
            phase: getPhaseVoltageLabel(phaseIndex)
          });

    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 2,
      data: this._getSeriesData(this.data.getPhaseVoltage(phaseIndex)),
      tooltip: {
        pointFormatter: createPointFormatter(name, 'V', 1),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createPhaseVoltageColumnForChild(child: IChildLocation, phaseIndex: number): SeriesLineOptions {
    const id = electricityColumnIds.phaseVoltageForChild(child.id, phaseIndex);
    const childName = child.name || `(${child.serialNumber || child.id})`;
    const name =
      this.phaseType === PhaseType.Single
        ? T('electricityValues.field.phaseVoltageForChildSingle', {
            name: childName
          })
        : T('electricityValues.field.phaseVoltageForChild', {
            name: childName,
            phase: getPhaseVoltageLabel(phaseIndex)
          });

    return {
      id,
      name,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 2,
      data: this._getSeriesData(this.data.getPhaseVoltageForChild(child.id, phaseIndex)),
      tooltip: {
        pointFormatter: createPointFormatter(name, 'V', 1),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createPhaseVoltageMinMaxColumn(phaseIndex: number, dummy: boolean): SeriesArearangeOptions {
    const phaseVoltageId = electricityColumnIds.phaseVoltage(phaseIndex);
    const id = electricityColumnIds.phaseVoltagesMinMax(phaseIndex);
    const name = T('electricityValues.field.phaseVoltageMinMax', {
      phase: getPhaseVoltageLabel(phaseIndex)
    });

    return {
      id,
      name: `${name} [V]`,
      color: this._getLastColor(),
      opacity: 0.4,
      type: 'arearange',
      dashStyle: 'ShortDash',
      showInLegend: false,
      yAxis: 2,
      data: dummy ? [] : this._getSeriesData(this.data.getPhaseVoltageMinMax(phaseIndex)),
      tooltip: {
        pointFormatter: createRangePointFormatter(name, 'V', 1),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(phaseVoltageId)
    };
  }

  createLineVoltageColumn(phaseIndex: number): SeriesLineOptions {
    const id = electricityColumnIds.lineVoltage(phaseIndex);
    const name = T('electricityValues.field.lineVoltage', {
      phase: getLineVoltageLabel(phaseIndex)
    });

    return {
      id,
      name: `${name} [V]`,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 2,
      data: this._getSeriesData(this.data.getLineVoltage(phaseIndex)),
      tooltip: {
        pointFormatter: createPointFormatter(name, 'V', 1),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createLineVoltageColumnForChild(child: IChildLocation, phaseIndex: number): SeriesLineOptions {
    const id = electricityColumnIds.lineVoltageForChild(child.id, phaseIndex);
    const childName = child.name || `(${child.serialNumber || child.id})`;
    const name = T('electricityValues.field.lineVoltageForChild', {
      name: childName,
      phase: getLineVoltageLabel(phaseIndex)
    });

    return {
      id,
      name: `${name} [V]`,
      color: this._generateColor(),
      type: 'line',
      dashStyle: 'ShortDash',
      yAxis: 2,
      data: this._getSeriesData(this.data.getLineVoltageForChild(child.id, phaseIndex)),
      tooltip: {
        pointFormatter: createPointFormatter(name, 'V', 1),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(id)
    };
  }

  createLineVoltageMinMaxColumn(phaseIndex: number, dummy: boolean): SeriesArearangeOptions {
    const lineVoltageId = electricityColumnIds.lineVoltage(phaseIndex);
    const id = electricityColumnIds.lineVoltageMinMax(phaseIndex);
    const name = T('electricityValues.field.lineVoltageMinMax', {
      phase: getLineVoltageLabel(phaseIndex)
    });

    return {
      id,
      name: `${name} [V, ${T('electricityValues.field.suffix.minMax')}]`,
      color: this._getLastColor(),
      opacity: 0.3,
      type: 'arearange',
      dashStyle: 'ShortDash',
      showInLegend: false,
      yAxis: 2,
      data: dummy ? [] : this._getSeriesData(this.data.getLineVoltageMinMax(phaseIndex)),
      tooltip: {
        pointFormatter: createRangePointFormatter(name, 'V', 1),
        shared: true
      } as SeriesTooltipOptionsObject,
      visible: this._isSeriesSelected(lineVoltageId)
    };
  }

  _getUnitSuffix(): string {
    return this.unit ? ` [${this.unit}]` : ``;
  }

  _isSeriesSelected(id: string): boolean {
    return this.series.includes(id);
  }

  _generateColor() {
    const result = this.colors[this.colorIndex];
    this.colorIndex = (this.colorIndex + 1) % this.colors.length;
    return result;
  }

  _getLastColor() {
    return this.colors[(this.colorIndex + this.colors.length - 1) % this.colors.length];
  }

  _getSeriesData(series: Series) {
    return series.data;
  }
}
