import React, {useMemo} from 'react';

import MiniGraph from '../../components/graph/MiniGraph';
import {IHistoricalDataChannel} from '../../models/HistoricalChannel';
import {isBigConsumer, ILocationSummary} from '../../models/Location';
import {unitIsPowerInsteadOfConsumption} from '../../models/UsageValue';
import {Interval} from '../../models/UsageValue';
import {T} from '../../utils/Internationalization';
import {SeriesElement} from '../ElectricityValues/Series';

import {LiveValuesMiniMode, IMiniGraphData} from './LiveValuesMiniModels';

interface LiveValuesMiniGraphProps {
  location: ILocationSummary;
  interval: Interval;
  backgroundColor?: string;
  seriesColor: string;
  className?: string;
  gasUnit: string;
  waterUnit: string;
  mode: LiveValuesMiniMode;
  load: IHistoricalDataChannel | undefined;
  data: IMiniGraphData;
}

export const LiveValuesMiniGraph = React.memo((props: LiveValuesMiniGraphProps) => {
  const {data, gasUnit, waterUnit, mode, load, className, backgroundColor, seriesColor, location, interval} = props;

  const {from, to} = data;
  const bigConsumer = isBigConsumer(location);
  const unitAsPower = unitIsPowerInsteadOfConsumption(interval);
  let unit = unitAsPower ? (bigConsumer ? 'kW' : 'W') : bigConsumer ? 'kWh' : 'Wh';
  const powerMultiplier = bigConsumer ? 0.001 : 1;

  if (mode === LiveValuesMiniMode.Gas) unit = gasUnit;
  else if (mode === LiveValuesMiniMode.Water) unit = waterUnit;

  const dataExtract: SeriesElement[] = useMemo(() => {
    const {electricityData, gasReadings, waterReadings} = data;
    switch (mode) {
      default:
      case LiveValuesMiniMode.Consumption:
        return electricityData.map(entry => [
          entry.timestamp,
          entry.value === undefined ? null : entry.value * powerMultiplier
        ]);
      case LiveValuesMiniMode.Solar:
        return electricityData.map(entry => [
          entry.timestamp,
          entry.solar === undefined ? null : entry.solar * powerMultiplier
        ]);
      case LiveValuesMiniMode.AlwaysOn:
        return electricityData.map(entry => [
          entry.timestamp,
          entry.alwaysOn === undefined ? null : entry.alwaysOn * powerMultiplier
        ]);
      case LiveValuesMiniMode.Gas:
        return gasReadings.map(entry => [entry.timestamp, entry.value === undefined ? null : entry.value]);
      case LiveValuesMiniMode.Water:
        return waterReadings.map(entry => [entry.timestamp, entry.value === undefined ? null : entry.value]);
      case LiveValuesMiniMode.Submetering:
        if (!load) return [];

        return electricityData.map(entry => [entry.timestamp, load.getActivePower(entry)]);
    }
  }, [data, mode, load, powerMultiplier]);
  let label: string = '';
  switch (mode) {
    case LiveValuesMiniMode.Consumption:
      label = T('electricityValues.field.consumption');
      break;
    case LiveValuesMiniMode.Solar:
      label = T('electricityValues.field.solar');
      break;
    case LiveValuesMiniMode.AlwaysOn:
      label = T('electricityValues.field.alwaysOn');
      break;
    case LiveValuesMiniMode.Gas:
      label = T('gasOrWaterCard.field.gas');
      break;
    case LiveValuesMiniMode.Water:
      label = T('gasOrWaterCard.field.water');
      break;
  }

  return (
    <MiniGraph
      from={from}
      to={to}
      timezone={location.timeZoneId}
      backgroundColor={backgroundColor}
      data={dataExtract}
      color={seriesColor}
      bigConsumer={bigConsumer}
      gasWaterMode={mode === LiveValuesMiniMode.Gas || mode === LiveValuesMiniMode.Water}
      label={label}
      unit={unit}
      className={className}
    />
  );
});
