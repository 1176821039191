import React from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../app/context';
import {SingleActionPromiseModal} from '../components/bootstrap/SingleActionPromiseModal';
import API from '../core/api';
import {IPromiseModalProps, usePromiseModal} from '../modals/PromiseModal';

import {translateError} from '../utils/Errors';
import {T} from '../utils/Internationalization';

interface RemoveModalProps extends IPromiseModalProps<boolean> {
  title: string;
  message: string;
  buttonLabel?: string;
  execute: (api: API) => Promise<unknown>;
  successMessage: string;
  failureMessage: string;
}
export default (props: RemoveModalProps) => {
  const {title, message, buttonLabel, execute, successMessage, failureMessage} = props;
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);

  const handleConfirm = () => {
    return execute(api)
      .then(() => {
        NotificationManager.success(successMessage);
        return undefined;
      })
      .catch(error => translateError(error, failureMessage));
  };

  return (
    <SingleActionPromiseModal
      isOpen={isOpen}
      resolve={resolve}
      title={title}
      cancelText={T('generic.action.cancel')}
      action={handleConfirm}
      actionText={buttonLabel || T('generic.action.remove')}
    >
      <div style={{marginTop: '1rem'}}>{message}</div>
    </SingleActionPromiseModal>
  );
};
