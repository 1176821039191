import styles from './RoundButton.module.scss';

interface RoundButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export function RoundButton(props: RoundButtonProps) {
  const {children, onClick} = props;
  return (
    <button type="button" className={styles.button} onClick={onClick}>
      {children}
    </button>
  );
}
