import React from 'react';

import {RowActionButton} from '../../components/bootstrap/RowActions';
import RowActions from '../../components/Table/RowActions';
import {CustomApp} from '../../models/CustomApp';
import {ComponentField, ITableField, StringField} from '../../models/Table';

interface CustomAppColumnListeners {
  onViewSecret: (app: CustomApp) => void;
  onDelete: (app: CustomApp) => void;
  onRestore: (app: CustomApp) => void;
}

export function getColumns(listeners: CustomAppColumnListeners): ITableField<CustomApp>[] {
  return [
    new StringField('appName', 'Name'),
    new StringField('appDesc', 'Description'),
    new StringField('callbackUrl', 'Callback URL'),
    new StringField('clientId', 'Client ID'),
    new ComponentField('actions', 'Actions', row => {
      return (
        <RowActions>
          <RowActionButton title="View secret" icon="Key" onClick={() => listeners.onViewSecret(row)} />
          {row.obsolete ? (
            <RowActionButton title="Restore" icon="Undo" onClick={() => listeners.onRestore(row)} />
          ) : (
            <RowActionButton action="delete" title="Delete" onClick={() => listeners.onDelete(row)} />
          )}
        </RowActions>
      );
    })
  ];
}
