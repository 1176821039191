import type {ExtractProps} from '../utils/Types';

import {Button} from './bootstrap';
import {Icon, SMAPPEE_ICON_MAP} from './Icon';
import {Help} from './ui-lib/icons/small';

interface IconButtonProps extends ExtractProps<typeof Button> {
  icon?: keyof typeof Icon;
  action?: 'default' | 'delete';
}

function getIcon(action: IconButtonProps['action'], icon?: IconButtonProps['icon']) {
  switch (action) {
    case 'delete':
      return 'Trash';
    default:
      return icon ?? 'QuestionCircle';
  }
}

function IconButton({action, icon, children, ...props}: IconButtonProps) {
  const iconKey = getIcon(action, icon);
  const actualIcon = iconKey ? SMAPPEE_ICON_MAP[iconKey] || <i className={Icon[iconKey]} /> : <Help />;
  return (
    <Button {...props}>
      {actualIcon}
      {children && <span className="ml-2">{children}</span>}
    </Button>
  );
}

export {IconButton};
