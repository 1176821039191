import {T} from '../utils/Internationalization';

import {FormText} from './bootstrap';

interface NoteProps {
  bottomSpacing?: boolean;
}

export function PricesInclVat(props: NoteProps) {
  const {bottomSpacing} = props;
  return (
    <FormText color="muted" style={{marginTop: 0, marginBottom: bottomSpacing ? '1rem' : 0}}>
      {T('generic.priceInclVat')}
    </FormText>
  );
}

export function PricesExclVat(props: NoteProps) {
  const {bottomSpacing} = props;
  return (
    <FormText color="muted" style={{marginTop: 0, marginBottom: bottomSpacing ? '1rem' : 0}}>
      {T('generic.priceExclVat')}
    </FormText>
  );
}
