import {Phase} from './Phase';

export interface FluviusCampaignPDFRequest {
  language?: 'nl' | 'fr';
  type: FluviusMeasurementCampaignType;
  region: {id: number; name: string};
  measuringCase: {id: number; code: string};
  name: string;
  vpkId?: string;
  cabinId?: string;
  voltage?: string;
  city: string;
  address: string;
  reason: string;
  notes: string;
  geniusA: FluviusCampaignTemplate;
  geniusB?: FluviusCampaignTemplate;
}

export interface IFluviusCampaignFields {
  name: string;
  configuration: FluviusCampaignConfiguration;
}
export interface IFluviusCampaign extends IFluviusCampaignFields {
  id: number;
  name: string;
  creationTimestamp: number;
  status: FluviusCampaignStatus;
  confirmActivation: boolean;
  serviceLocationIds?: number[];
}

export const enum FluviusCampaignStatus {
  All = '__ALL__',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export interface FluviusCampaignConfiguration {
  type: FluviusMeasurementCampaignType;
  region: number;
  measuringCase: number;
  name: string;
  vpkId?: string;
  cabinId?: string;
  city: string;
  address: string;
  reason: string;
  notes: string;
  voltage?: '400V' | '230V';
  geniusA: FluviusCampaignTemplate;
  geniusB?: FluviusCampaignTemplate;
}

export const enum FluviusMeasurementCampaignType {
  __unset__ = '__',
  Cabine = 'CABIN',
  Voetpadkast = 'VPK',

  // Sibelga only
  OndergrondseKast = 'UGCASE',
  WoningKlant = 'HOUSE',
  KlantenCabine = 'CCABIN'
}

export interface FluviusCampaignTemplate {
  template: FluviusMeasurementTemplateCode;
  transfoId?: string;
  boardNr?: string; // not yet processed by cloud
  boardName?: string; // not yet processed by cloud
  inputs: FluviusCampaignInput[];
}

export interface FluviusCampaignInput {
  grid: boolean;
  enabled: boolean;
  nr?: string;
  name: string;
  phase?: Phase;
}

export const enum FluviusMeasurementTemplateCode {
  Net2x3F = 'Net2x3F',
  Net4x3F = 'Net4x3F',
  Net8x1F = 'Net8x1F',
  Net16x1F = 'Net16x1F',
  THREE_PHASE = 'THREE_PHASE',
  FOUR_SINGLE_PHASE = 'FOUR_SINGLE_PHASE',

  // pseudo codes
  Net = 'Net',
  THREE_PHASE_N = 'THREE_PHASE::Net',
  THREE_PHASE_V = 'THREE_PHASE::Vertrek'
}
