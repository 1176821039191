import {T} from '../utils/Internationalization';

import {IConsumptionValue} from './ConsumptionValue';
import {ILoad, ILoadChannel, LoadType} from './Load';

export interface IHistoricalDataChannel {
  id: number;
  name: string;
  hasReactive: boolean;
  hasVoltages: boolean;
  getActivePower(value: IConsumptionValue): number | null;
  getReactivePower(value: IConsumptionValue): number | null;
  getApparentPower(value: IConsumptionValue): number | null;
  getCurrent(value: IConsumptionValue): number | null;
  getCurrentMin(value: IConsumptionValue): number | null;
  getCurrentMax(value: IConsumptionValue): number | null;
  getCurrentMinMax(value: IConsumptionValue): [number | null, number | null];
  getPhaseVoltage(value: IConsumptionValue): number | null;
  getLineVoltage(value: IConsumptionValue): number | null;

  actuals: ILoadChannel[];
}

export function getChannelsFromLoads(loads: IHistoricalDataChannel[]): IHistoricalDataChannel[] {
  return loads.flatMap(load => load.actuals.map(actual => getHistoricalChannel(load.id, actual)));
}

export function getHistoricalLoad(
  locationId: number,
  load: ILoad,
  powerMultiplier: number = 1
): IHistoricalDataChannel {
  const publishIndices = load.actuals.map(actual => actual.publishIndex);
  const name = load.name || getDefaultName(load.type);
  const hasReactive = load.actuals.some(actual => actual.midBusAddress === undefined);
  if (load.serviceLocationId === undefined || load.serviceLocationId === locationId) {
    return new HistoricalMainLoad(load, name, hasReactive, publishIndices, powerMultiplier);
  } else {
    return new HistoricalChildLoad(load, name, hasReactive, load.serviceLocationId, publishIndices, powerMultiplier);
  }
}

function getDefaultName(type: LoadType) {
  switch (type) {
    case LoadType.Grid:
      return T('defaultNames.gridLoad');
    case LoadType.Production:
      return T('defaultNames.productionLoad');
    case LoadType.Storage:
      return T('defaultNames.storageLoad');
    default:
      return '?';
  }
}

class HistoricalMainLoad implements IHistoricalDataChannel {
  id: number;
  name: string;
  hasReactive: boolean;
  hasVoltages: boolean;
  publishIndices: number[];
  multiplier: number;
  actuals: ILoadChannel[];

  constructor(load: ILoad, name: string, hasReactive: boolean, publishIndices: number[], multiplier: number) {
    this.id = load.id;
    this.actuals = load.actuals;
    this.name = name;
    this.hasReactive = hasReactive;
    this.hasVoltages = false;
    this.publishIndices = publishIndices;
    this.multiplier = multiplier;
  }

  getActivePower(value: IConsumptionValue): number | null {
    if (value.activePower === undefined) return null;

    let sum = 0;
    let hasValues = false;
    for (let index of this.publishIndices) {
      const power = value.activePower[index];
      if (power !== null && power !== undefined) {
        sum += power;
        hasValues = true;
      }
    }

    return hasValues ? sum * this.multiplier : null;
  }

  getReactivePower(value: IConsumptionValue): number | null {
    if (value.reactivePower === undefined) return null;

    let sum = 0;
    let hasValues = false;
    for (let index of this.publishIndices) {
      const power = value.reactivePower[index];
      if (power !== null && power !== undefined) {
        sum += power;
        hasValues = true;
      }
    }

    return hasValues ? sum * this.multiplier : null;
  }

  getApparentPower(value: IConsumptionValue): number | null {
    if (value.activePower === undefined || value.reactivePower === undefined) {
      return null;
    }

    let sum = 0;
    let hasValues = false;
    for (let index of this.publishIndices) {
      const activePower = value.activePower[index];
      const reactivePower = value.reactivePower[index];
      if (activePower !== null && activePower !== undefined && reactivePower !== null && reactivePower !== undefined) {
        sum += Math.sqrt(activePower * activePower + reactivePower * reactivePower);
        hasValues = true;
      }
    }

    return hasValues ? sum * this.multiplier : null;
  }

  getCurrent(value: IConsumptionValue): number | null {
    if (value.current === undefined) return null;

    let sum = 0;
    let numValues = 0;
    for (let index of this.publishIndices) {
      const current = value.current[index];
      if (current !== null && current !== undefined) {
        sum += current;
        numValues++;
      }
    }
    // NOTE: this calculates average current, not sum
    return numValues === 0 ? null : sum / numValues;
  }

  getCurrentMin(value: IConsumptionValue): number | null {
    return null;
  }

  getCurrentMax(value: IConsumptionValue): number | null {
    return null;
  }

  getCurrentMinMax(value: IConsumptionValue): [number | null, number | null] {
    return [null, null];
  }

  getPhaseVoltage(value: IConsumptionValue): number | null {
    return null;
  }

  getLineVoltage(value: IConsumptionValue): number | null {
    return null;
  }
}

class HistoricalChildLoad implements IHistoricalDataChannel {
  id: number;
  name: string;
  hasReactive: boolean;
  hasVoltages: boolean;
  locationId: number;
  publishIndices: number[];
  multiplier: number;
  actuals: ILoadChannel[];

  constructor(
    load: ILoad,
    name: string,
    hasReactive: boolean,
    locationId: number,
    publishIndices: number[],
    multiplier: number
  ) {
    this.id = load.id;
    this.name = name;
    this.actuals = load.actuals;
    this.hasReactive = hasReactive;
    this.hasVoltages = false;
    this.locationId = locationId;
    this.publishIndices = publishIndices;
    this.multiplier = multiplier;
  }

  getActivePower(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;
    if (childValue.activePower === undefined) return null;

    let sum = 0;
    let hasValues = false;
    for (let index of this.publishIndices) {
      const power = childValue.activePower[index];
      if (power !== null && power !== undefined) {
        sum += power;
        hasValues = true;
      }
    }

    return hasValues ? sum * this.multiplier : null;
  }

  getReactivePower(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;
    if (childValue.reactivePower === undefined) return null;

    let sum = 0;
    let hasValues = false;
    for (let index of this.publishIndices) {
      const power = childValue.reactivePower[index];
      if (power !== null && power !== undefined) {
        sum += power;
        hasValues = true;
      }
    }

    return hasValues ? sum * this.multiplier : null;
  }

  getApparentPower(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;
    if (childValue.activePower === undefined || childValue.reactivePower === undefined) {
      return null;
    }

    let sum = 0;
    let hasValues = false;
    for (let index of this.publishIndices) {
      const activePower = childValue.activePower[index];
      const reactivePower = childValue.reactivePower[index];
      if (activePower !== null && activePower !== undefined && reactivePower !== null && reactivePower !== undefined) {
        sum += Math.sqrt(activePower * activePower + reactivePower * reactivePower);
        hasValues = true;
      }
    }

    return hasValues ? sum * this.multiplier : null;
  }

  getCurrent(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;
    if (childValue.current === undefined) return null;

    let sum = 0;
    let numValues = 0;
    for (let index of this.publishIndices) {
      const current = childValue.current[index];
      if (current !== null && current !== undefined) {
        sum += current;
        numValues++;
      }
    }
    // NOTE: this calculates average current, not sum
    return numValues === 0 ? null : sum / numValues;
  }

  getCurrentMin(value: IConsumptionValue): number | null {
    return null;
  }

  getCurrentMax(value: IConsumptionValue): number | null {
    return null;
  }

  getCurrentMinMax(value: IConsumptionValue): [number | null, number | null] {
    return [null, null];
  }

  getPhaseVoltage(value: IConsumptionValue): number | null {
    return null;
  }

  getLineVoltage(value: IConsumptionValue): number | null {
    return null;
  }
}

export function getHistoricalChannel(
  locationId: number,
  channel: ILoadChannel,
  multiplier: number = 1
): IHistoricalDataChannel {
  const hasReactive = channel.midBusAddress === undefined;
  const hasVoltages = channel.midBusAddress !== undefined;
  if (channel.serviceLocationId === undefined || channel.serviceLocationId === locationId) {
    return new HistoricalMainChannel(channel, channel.name, hasReactive, hasVoltages, channel.publishIndex, multiplier);
  } else {
    return new HistoricalChildChannel(
      channel,
      channel.name,
      hasReactive,
      hasVoltages,
      channel.serviceLocationId,
      channel.publishIndex,
      multiplier
    );
  }
}

class HistoricalMainChannel implements IHistoricalDataChannel {
  id: number;
  name: string;
  actuals: ILoadChannel[];
  hasReactive: boolean;
  hasVoltages: boolean;
  publishIndex: number;
  multiplier: number;

  constructor(
    channel: ILoadChannel,
    name: string | undefined,
    hasReactive: boolean,
    hasVoltages: boolean,
    publishIndex: number,
    multiplier: number
  ) {
    this.id = channel.id;
    this.name = name || T('defaultNames.channel');
    this.actuals = [channel];
    this.hasReactive = hasReactive;
    this.hasVoltages = hasVoltages;
    this.publishIndex = publishIndex;
    this.multiplier = multiplier;
  }

  getActivePower(value: IConsumptionValue): number | null {
    const baseValue = (value.activePower || [])[this.publishIndex];
    return baseValue === null || baseValue === undefined ? null : baseValue * this.multiplier;
  }

  getReactivePower(value: IConsumptionValue): number | null {
    const baseValue = (value.reactivePower || [])[this.publishIndex];
    return baseValue === null || baseValue === undefined ? null : baseValue * this.multiplier;
  }

  getApparentPower(value: IConsumptionValue): number | null {
    const activePower = (value.activePower || [])[this.publishIndex];
    const reactivePower = (value.reactivePower || [])[this.publishIndex];
    if (activePower === null || activePower === undefined || reactivePower === null || reactivePower === undefined) {
      return null;
    }

    return Math.sqrt(activePower * activePower + reactivePower * reactivePower) * this.multiplier;
  }

  getCurrent(value: IConsumptionValue): number | null {
    const baseValue = (value.current || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }

  getCurrentMin(value: IConsumptionValue): number | null {
    const baseValue = (value.currentMin || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }

  getCurrentMax(value: IConsumptionValue): number | null {
    const baseValue = (value.currentMax || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }

  getCurrentMinMax(value: IConsumptionValue): [number | null, number | null] {
    const baseValueMin = (value.currentMin || [])[this.publishIndex];
    const baseValueMax = (value.currentMax || [])[this.publishIndex];
    return [baseValueMin === undefined ? null : baseValueMin, baseValueMax === undefined ? null : baseValueMax];
  }

  getPhaseVoltage(value: IConsumptionValue): number | null {
    const baseValue = (value.midPhaseVoltages || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }

  getLineVoltage(value: IConsumptionValue): number | null {
    const baseValue = (value.midLineVoltages || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }
}

class HistoricalChildChannel implements IHistoricalDataChannel {
  id: number;
  name: string;
  actuals: ILoadChannel[];
  hasReactive: boolean;
  hasVoltages: boolean;
  locationId: number;
  publishIndex: number;
  multiplier: number;

  constructor(
    channel: ILoadChannel,
    name: string | undefined,
    hasReactive: boolean,
    hasVoltages: boolean,
    locationId: number,
    publishIndex: number,
    multiplier: number
  ) {
    this.id = channel.id;
    this.name = name || T('defaultNames.channel');
    this.actuals = [channel];
    this.hasReactive = hasReactive;
    this.hasVoltages = hasVoltages;
    this.locationId = locationId;
    this.publishIndex = publishIndex;
    this.multiplier = multiplier;
  }

  getActivePower(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;

    const baseValue = (childValue.activePower || [])[this.publishIndex];
    return baseValue === null || baseValue === undefined ? null : baseValue * this.multiplier;
  }

  getReactivePower(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;

    const baseValue = (childValue.reactivePower || [])[this.publishIndex];
    return baseValue === null || baseValue === undefined ? null : baseValue * this.multiplier;
  }

  getApparentPower(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;

    const activePower = (childValue.activePower || [])[this.publishIndex];
    const reactivePower = (childValue.reactivePower || [])[this.publishIndex];
    if (activePower === null || activePower === undefined || reactivePower === null || reactivePower === undefined) {
      return null;
    }

    return Math.sqrt(activePower * activePower + reactivePower * reactivePower) * this.multiplier;
  }

  getCurrent(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;

    const baseValue = (childValue.current || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }

  getCurrentMin(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;

    const baseValue = (childValue.currentMin || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }

  getCurrentMax(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;

    const baseValue = (childValue.currentMax || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }

  getCurrentMinMax(value: IConsumptionValue): [number | null, number | null] {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return [null, null];

    const baseValueMin = (childValue.currentMin || [])[this.publishIndex];
    const baseValueMax = (childValue.currentMax || [])[this.publishIndex];
    return [baseValueMin === undefined ? null : baseValueMin, baseValueMax === undefined ? null : baseValueMax];
  }

  getPhaseVoltage(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;

    const baseValue = (childValue.midPhaseVoltages || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }

  getLineVoltage(value: IConsumptionValue): number | null {
    const childValue = (value.childData || []).find(x => x.serviceLocationId === this.locationId);
    if (childValue === undefined) return null;

    const baseValue = (childValue.midLineVoltages || [])[this.publishIndex];
    return baseValue === undefined ? null : baseValue;
  }
}
