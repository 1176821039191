import * as React from 'react';

import {APISpecificError} from '../../../api/APIClient';
import {useModals} from '../../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {IPricingGroup, SmappeeGroup} from '../../../models/PricingGroup';
import {PricingPolicyLimits} from '../../../models/PricingPolicy';
import {getCurrencySymbol} from '../../../utils/Currency';
import {ServerErrorCode} from '../../../utils/Errors';
import {FormContextProvider} from '../../../utils/FormContext';
import {useFormState} from '../../../utils/FormState';
import {T} from '../../../utils/Internationalization';
import {Alert, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, SingleActionModal} from '../../bootstrap';
import {buttonColors} from '../../bootstrap/Button';
import {Closeable} from '../../Closeable';

import {IconButton} from '../../IconButton';
import {Info} from '../../Info';
import {NumberValue} from '../../inputs/NumberInput';

import {RoundButton} from '../../RoundButton';
import {SpecificPricingRuleState, TariffState} from '../configurePricingPolicy/FormState';

import {usePricingPolicy} from '../configurePricingPolicy/PricingPolicyProvider';

import styles from './ConnectPricingGroup.module.scss';
import {useConnectPricingGroup} from './ConnectPricingGroupProvider';
import {SpecifyPricing} from './SpecifyPricing';

function SelectedSpecificPricingItem({
  pricingGroup,
  editable = true,
  builtIn = false,
  selectable = true,
  ...props
}: React.ComponentProps<typeof DropdownItem> & {
  pricingGroup: IPricingGroup;
  editable?: boolean;
  builtIn?: boolean;
  selectable?: boolean;
}) {
  const {editGroup} = useConnectPricingGroup();

  return (
    <div className={styles.selectPricingGroupItem}>
      <DropdownItem disabled={!selectable} {...props} type="button" className={styles.connectPricingGroupItem}>
        {getPricingGroupName(pricingGroup)}
        {builtIn ? <span className={styles.builtInBadge}>{T('pricingGroups.builtIn')}</span> : null}
      </DropdownItem>

      {editable ? (
        <button type="button" onClick={() => editGroup(pricingGroup)} className={styles.connectPricingGroupAction}>
          <i className="fas fa-edit" />
        </button>
      ) : null}
    </div>
  );
}

function getGroupKey(group: IPricingGroup): string {
  return group.type + (group.id || 0);
}

function SelectedSpecificPricingButton({
  pricingGroup,
  disabled,
  onSelect,
  quickAdd,
  className
}: {
  pricingGroup?: IPricingGroup;
  disabled?: boolean;
  onSelect: (pricingGroup: IPricingGroup) => void;
  quickAdd?: boolean;
  className?: string;
}) {
  const [open, setOpen] = React.useState<boolean>(false);
  const {formState} = usePricingPolicy();
  const {pricingGroups, createNewGroup, groupsInUse} = useConnectPricingGroup();
  const actualPricingGroup = pricingGroups.find(g => g.id === pricingGroup?.id) || pricingGroup;

  const toggleOpen = React.useCallback(() => {
    setOpen(o => !o);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const hasSmappeeCommunity = React.useMemo(() => {
    return formState.specificPricingRules.some(group => group.discountGroup.type === 'SMAPPEE');
  }, [formState.specificPricingRules]);

  const handleAddNewGroup = React.useCallback(() => {
    createNewGroup().then(group => group && onSelect(group));
  }, [onSelect, createNewGroup]);

  const handleSelect = (pricingGroup: IPricingGroup) => {
    if (groupsInUse.includes(pricingGroup.id!)) {
      return; // don't add the same group twice
    }

    onSelect(pricingGroup);
  };

  return (
    <>
      <Closeable isOpen={open} onClose={handleClose} className={className}>
        <Dropdown isOpen={open} toggle={toggleOpen} style={{position: 'relative'}} disabled={disabled}>
          {actualPricingGroup ? (
            <ChangePricingGroupButton disabled={disabled} pricingGroup={actualPricingGroup} />
          ) : (
            <AddPricingGroupButton pricingGroup={actualPricingGroup} />
          )}
          <DropdownMenu>
            {!hasSmappeeCommunity && (
              <SelectedSpecificPricingItem
                onClick={!disabled ? () => onSelect(SmappeeGroup) : undefined}
                pricingGroup={SmappeeGroup}
                editable={false}
                builtIn
              />
            )}

            {pricingGroups.map(group => (
              <SelectedSpecificPricingItem
                onClick={!disabled ? () => handleSelect(group) : undefined}
                key={getGroupKey(group)}
                pricingGroup={group}
                builtIn={group.type !== 'CUSTOM'}
              />
            ))}

            <div className={styles.selectPricingGroupItem}>
              <DropdownItem onClick={handleAddNewGroup} type="button" className={styles.connectPricingGroupItem}>
                <i className="fas fa-plus mr-2" />
                {T('pricingGroups.add.title')}
              </DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
      </Closeable>
      {quickAdd && (
        <div className="btn-group mt-4">
          {!hasSmappeeCommunity && (
            <RoundButton onClick={!disabled ? () => onSelect(SmappeeGroup) : undefined}>
              <i className="fa fa-plus mr-2" />
              {T('pricingGroups.smappee')}*
            </RoundButton>
          )}
        </div>
      )}
    </>
  );
}

interface SpecificPricingModalProps extends IPromiseModalProps<SpecificPricingRuleState | null> {
  groupName: string;
  baseTariff: TariffState;
  currentPrice: SpecificPricingRuleState;
  currency: string;
  error?: APISpecificError;
  limits?: PricingPolicyLimits;
}
function SpecificPricingModal(props: SpecificPricingModalProps) {
  const {baseTariff, currentPrice, currency, groupName, error, limits} = props;

  const form = useFormState();
  const [price, setPrice] = React.useState<SpecificPricingRuleState>(currentPrice);
  const [isOpen, resolve] = usePromiseModal(props);
  const onToggle = () => resolve(null);

  React.useEffect(() => {
    if (price.mode === 'pristine') {
      setPrice({...price, mode: 'discount'});
    }
  }, [price]);

  React.useEffect(() => {
    form.clearServerErrors();
    if (error) {
      if (error.code === ServerErrorCode.RoamingPriceFixedTooHigh) {
        form.setServerError('fixedCost', T('chargingStationConfiguration.fixedTariff.tooHigh'));
      } else if (error.code === ServerErrorCode.RoamingPriceEnergyTooHigh) {
        form.setServerError('energyCost', T('chargingStationConfiguration.energyTariff.tooHigh'));
      } else if (error.code === ServerErrorCode.RoamingPriceHourlyTooHigh) {
        form.setServerError('hourlyCost', T('chargingStationConfiguration.timeTariff.tooHigh'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleClickSave = React.useCallback(async () => {
    if (form.hasErrors()) {
      form.showErrors();
      return;
    }
    resolve(price);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, resolve]);

  const onChange = React.useCallback((updates: SpecificPricingRuleState) => {
    if (updates.discountPercentage) {
      setPrice(p => ({
        ...p,
        ...updates,
        tariff: {
          ...p.tariff,
          ...updates.tariff
        }
      }));
    }
  }, []);

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={onToggle}
      title={T('pricingGroups.setPriceOn', {name: groupName})}
      action={handleClickSave}
    >
      <FormContextProvider value={form}>
        <SpecifyPricing
          baseTariff={baseTariff}
          currentPrice={price}
          onChange={onChange}
          currency={currency}
          limits={limits}
        />
      </FormContextProvider>
    </SingleActionModal>
  );
}

interface SelectedSpecificPricingProps {
  baseTariff: TariffState;
  specificPricing: SpecificPricingRuleState;
  disabled?: boolean;
  className?: string;
  onRemove?: () => void;
  onUpdate?: (specificPricing: SpecificPricingRuleState) => void;
  error?: APISpecificError;
}
function SelectedSpecificPricing({
  baseTariff,
  specificPricing,
  disabled,
  className,
  onRemove,
  onUpdate,
  error
}: SelectedSpecificPricingProps) {
  const modals = useModals();
  const {organization, limits} = usePricingPolicy();
  const currency = organization?.currency || 'EUR';
  const currencySymbol = getCurrencySymbol(currency);

  const handleClickedSetPrice = React.useCallback(() => {
    modals
      .show<SpecificPricingRuleState | null>(props => (
        <SpecificPricingModal
          {...props}
          groupName={specificPricing.discountGroup.name}
          baseTariff={baseTariff}
          currentPrice={specificPricing}
          currency={currency}
          error={error}
          limits={limits}
          {...props}
        />
      ))
      .then(price => price && onUpdate?.(price));
  }, [modals, specificPricing, baseTariff, currency, error, limits, onUpdate]);

  return (
    <div className={`${styles.selectedPricingGroupItem} ${className} ${error ? styles.error : ''}`}>
      <div className={styles.connectPricingGroupNameLocationGroup}>
        <SelectedSpecificPricingButton
          onSelect={group => onUpdate?.({...specificPricing, discountGroup: group})}
          disabled={disabled}
          pricingGroup={specificPricing?.discountGroup}
        />
      </div>
      <div className={onUpdate ? styles.pricingGroupActions : styles.pricingGroupDefaultPrice}>
        {specificPricing.mode === 'pristine' && (
          <span onClick={handleClickedSetPrice} className={styles.pricingGroupPrice}>
            <span className={styles.pricingGroupPrice}>{T('pricingGroups.setPrice')}</span>
          </span>
        )}
        {specificPricing.mode === 'discount' && (
          <span onClick={handleClickedSetPrice} className={styles.pricingGroupPrice}>
            <span className={styles.pricingGroupPrice}>
              {`${T('pricingPolicies.discount')}: ${specificPricing?.discountPercentage?.inputValue}%`}
            </span>
          </span>
        )}
        {specificPricing.mode === 'tariff' && (
          <span onClick={onUpdate && handleClickedSetPrice} className="text-right">
            {specificPricing.tariff.startingFee && (
              <span className={styles.pricingGroupPrice}>
                {`${T('pricingPolicies.add.tariffs.fixedCost')}: ${
                  specificPricing.tariff.startingFee.inputValue
                } ${currencySymbol}`}{' '}
              </span>
            )}
            {specificPricing.tariff.costPerKwh && (
              <span className={styles.pricingGroupPrice}>
                {`${T('pricingPolicies.add.tariffs.energyCost')}: ${
                  specificPricing.tariff.costPerKwh.inputValue
                } ${currencySymbol} / kWh`}{' '}
              </span>
            )}
            {specificPricing.tariff.timeComponents && specificPricing.tariff.timeComponents.length > 0 && (
              <span className={styles.pricingGroupPrice}>
                {`${T('pricingPolicies.add.tariffs.hourlyCost')}: ${
                  specificPricing.tariff.timeComponents?.[0]?.cost?.inputValue
                } ${currencySymbol} / h`}{' '}
              </span>
            )}
          </span>
        )}
        {specificPricing.mode === 'blocked' && (
          <span onClick={handleClickedSetPrice} className={styles.pricingGroupPrice}>
            <span className={styles.pricingGroupBlocked}>{T('pricingGroups.blocked')}</span>
          </span>
        )}
        {onRemove && !disabled ? (
          <IconButton action="delete" color="link" onClick={onRemove} />
        ) : (
          <div className={styles.buttonSpacer} />
        )}
      </div>
    </div>
  );
}

function AddPricingGroupButton({pricingGroup}: {pricingGroup?: IPricingGroup}) {
  return (
    <DropdownToggle caret className={buttonColors.secondary}>
      {pricingGroup?.name || T('pricingGroups.select')}
    </DropdownToggle>
  );
}

function getPricingGroupName(pricingGroup?: IPricingGroup) {
  if (!pricingGroup) return T('pricingGroups.select');

  if (pricingGroup.name) return pricingGroup.name;

  if (pricingGroup.type === 'ROAMING') {
    return T('pricingGroups.roaming');
  } else if (pricingGroup.type === 'SMAPPEE') {
    return T('pricingGroups.smappee');
  }

  return T('pricingGroups.select.unknown');
}

function ChangePricingGroupButton({pricingGroup, disabled}: {pricingGroup?: IPricingGroup; disabled?: boolean}) {
  return (
    <DropdownToggle disabled={disabled} caret={!disabled} className={styles.selectPricingGroupDropdown}>
      <span>{getPricingGroupName(pricingGroup)}</span>
      {pricingGroup?.type === 'SMAPPEE' && '*'}
    </DropdownToggle>
  );
}

interface ConnectPricingGroupProps {
  baseTariff: TariffState;
  currency: string;
  rules: SpecificPricingRuleState[];
  updateRules: (groups: SpecificPricingRuleState[]) => void;
  errors: APISpecificError[];
}

function getSortIndex(type: 'CUSTOM' | 'ROAMING' | 'SMAPPEE' | 'DEFAULT') {
  switch (type) {
    case 'CUSTOM':
      return 1;
    case 'ROAMING':
      return 2;
    case 'SMAPPEE':
      return 3;
    default:
      return 4;
  }
}

export function ConnectPricingGroup(props: ConnectPricingGroupProps) {
  const {baseTariff, currency, rules, updateRules, errors} = props;
  const modals = useModals();
  const {limits} = usePricingPolicy();

  const sortedRules = React.useMemo(
    () =>
      rules.sort((a, b) =>
        a.discountGroup.type === b.discountGroup.type
          ? a.discountGroup.name.localeCompare(b.discountGroup.name)
          : getSortIndex(a.discountGroup.type) - getSortIndex(b.discountGroup.type)
      ),
    [rules]
  );

  const handleSelected = (discountGroup: IPricingGroup) => {
    modals
      .show<SpecificPricingRuleState | null>(props => (
        <SpecificPricingModal
          groupName={discountGroup.name}
          baseTariff={baseTariff}
          currentPrice={{
            mode: 'pristine',
            discountGroup,
            discountPercentage: NumberValue.none(),
            tariff: {costPerKwh: NumberValue.none(), timeComponents: []}
          }}
          currency={currency}
          limits={limits}
          {...props}
        />
      ))
      .then(pricing => pricing && updateRules([...rules, pricing]));
  };

  return (
    <div className={styles.specificPrices}>
      <h2 className="m-0 p-0 pb-3 font-bold">{T('pricingPolicies.add.groups.info')}</h2>
      <Alert color="info">
        <p>{T('pricingGroups.info')}</p>
      </Alert>
      <Label>{T('pricingGroups.title')}</Label>
      {sortedRules.map((rule, index) => (
        <SelectedSpecificPricing
          key={index}
          baseTariff={baseTariff}
          onRemove={() => updateRules(rules.filter(r => r !== rule))}
          onUpdate={updatedRule => updateRules(rules.map(r => (r == rule ? updatedRule : r)))}
          specificPricing={rule}
          error={getErrorForGroup(errors, rule.discountGroup)}
        />
      ))}
      <SelectedSpecificPricing
        disabled
        baseTariff={baseTariff}
        specificPricing={{
          mode: 'tariff',
          discountGroup: {
            name: rules.length === 0 ? T('pricingGroups.default') : T('pricingGroups.others'),
            type: 'DEFAULT'
          },
          discountPercentage: NumberValue.none(),
          tariff: baseTariff
        }}
      />
      <SelectedSpecificPricingButton className="mt-4" quickAdd onSelect={handleSelected} />
      <Info>*{T('pricingPolicies.smappeeCommunity.info')}</Info>
    </div>
  );
}

function getErrorForGroup(errors: APISpecificError[], group: IPricingGroup): APISpecificError | undefined {
  return errors.find(e => isSpecificGroup(group, e.entity!));
}

function isSpecificGroup(group: IPricingGroup, id: string) {
  const parts = id.split('-');
  if (parts[0] !== group.type) return false;
  if (group.type === 'CUSTOM') return parts[1] === group.id?.toString();
  return true;
}
