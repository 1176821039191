import {useState} from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button as RsButton,
  Nav,
  NavItem,
  NavLink
} from '../components/bootstrap';
import {useFormContext} from '../utils/FormContext';
import {T} from '../utils/Internationalization';

export interface ModalTab {
  id: string;
  title: string;
  render: () => JSX.Element;
}

export interface TabbedModalError {
  error?: string;
  tab?: string;
}

interface TabbedModalProps {
  isOpen: boolean;
  toggle: () => void;
  wizard: boolean;
  title: string;
  saveButtonLabel: string;
  tabs: ModalTab[];
  save: () => Promise<TabbedModalError | undefined>;
}

export function TabbedModal(props: TabbedModalProps) {
  const {isOpen, toggle, wizard, title, saveButtonLabel, tabs, save} = props;
  const [error, setError] = useState<string>();
  const [tabErrors, setTabErrors] = useState<{[key: string]: boolean}>({});
  const form = useFormContext();

  const [currentStep, setCurrentStep] = useState(0);
  const currentStepId = tabs[currentStep].id;

  const handleClickedNext = () => {
    const newTabErrors = {...tabErrors};
    newTabErrors[currentStepId] = false;

    if (form.hasErrors()) {
      form.showErrors();
      newTabErrors[currentStepId] = true;
    }

    setTabErrors(newTabErrors);

    if (currentStep < tabs.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleClickedSave = () => {
    const newTabErrors = {...tabErrors};
    newTabErrors[currentStepId] = false;

    if (form.hasErrors()) {
      form.showErrors();
      newTabErrors[currentStepId] = true;
    } else if (!tabs.some(tab => newTabErrors[tab.id])) {
      save().then(error => {
        if (error) {
          setError(error.error);

          const tab = error.tab;
          if (tab) {
            setTabErrors(errors => ({...errors, [tab]: true}));
            const tabIndex = tabs.findIndex(t => t.id === error.tab);
            if (tabIndex >= 0) setCurrentStep(tabIndex);
          }
        }
      });
    }
  };

  const handleClickedStep = (index: number) => {
    const newTabErrors = {...tabErrors};
    newTabErrors[currentStepId] = false;

    if (form.hasErrors()) {
      form.showErrors();
      newTabErrors[currentStepId] = true;
    }

    setCurrentStep(index);
  };

  const hasSave = !wizard || currentStep === tabs.length - 1;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" autoFocus={false}>
      <ModalHeader toggle={toggle} style={{borderBottom: 'none'}}>
        {title}
      </ModalHeader>
      <Nav tabs style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
        {tabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink active={currentStep === index} onClick={() => handleClickedStep(index)}>
              {tabErrors[tab.id] && <i className="fa fa-exclamation-circle text-danger mr-1" />}
              {tab.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <ModalBody>
        {tabs.map((tab, index) => (
          <div className="mt-2" key={index}>
            {currentStep === index && tab.render()}
          </div>
        ))}
      </ModalBody>
      <ModalFooter error={error}>
        <RsButton color="primary" type="button" onClick={hasSave ? handleClickedSave : handleClickedNext}>
          {hasSave ? saveButtonLabel : T('pricingGroups.next')}
        </RsButton>
      </ModalFooter>
    </Modal>
  );
}
