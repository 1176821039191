import type {SVGProps} from 'react';
const SvgDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M4.8 8a1.6 1.6 0 1 1-3.2 0 1.6 1.6 0 0 1 3.2 0m4.8 0a1.6 1.6 0 1 1-3.2 0 1.6 1.6 0 0 1 3.2 0m3.2 1.6a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2" />
  </svg>
);
export default SvgDots;
