import {DynamicTariff} from '../models/Tariff';

import {APIClient} from './APIClient';

export class TariffAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getDynamicTariff(locationId: number): Promise<DynamicTariff | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/tariffs/dynamic`;
    return this.client.doGet(url);
  }

  setDynamicTariff(locationId: number, tariff: DynamicTariff) {
    const url = `/api/v10/servicelocation/${locationId}/tariffs/dynamic`;
    return this.client.doPost(url, tariff);
  }

  updateDynamicTariff(locationId: number, tariff: Partial<DynamicTariff>) {
    const url = `/api/v10/servicelocation/${locationId}/tariffs/dynamic`;
    return this.client.doPatch(url, tariff);
  }

  switchTariff(locationId: number): Promise<unknown> {
    return this.client.doPost(`/api/v10/servicelocation/${locationId}/tariffs/switch`, {});
  }
}
