import {T} from '../utils/Internationalization';
import {TranslationKey} from '../utils/TranslationTerms';

const enum SurveyBuildingType {
  House = 'HOUSE',
  Apartment = 'APARTMENT'
}

export interface ILocationSurvey {
  serviceLocationId: number;
  buildingType?: SurveyBuildingType;
  numberOfAdults?: number;
  numberOfChildren?: number;
  answers?: {[key: string]: number};
}

const ANSWER_NAMES: {[key: string]: TranslationKey} = {
  buildingType: 'survey.buildingType',

  CENTRAL_AC: 'deviceType.centralAC',
  CERAMIC_STOVE: 'deviceType.ceramicStove',
  COFFEE_MAKER: 'deviceType.coffeeMaker', // is this still in use anywhere?
  COFFEE_MAKER_ADVANCED: 'deviceType.coffeeMaker.advanced',
  COFFEE_MAKER_TRADITIONAL: 'deviceType.coffeeMaker.traditional',
  COMBI_REFRIGERATOR_FREEZER: 'deviceType.combiFridge',
  DEHUMIDIFIER: 'deviceType.dehumidifier',
  DESKTOP_COMPUTER: 'deviceType.desktopComputer',
  DISH_WASHER: 'deviceType.dishwasher',
  ELECTRIC_CAR: 'deviceType.electricCar',
  ELECTRIC_CENTRAL_HEATING: 'deviceType.electricCentralHeating',
  ELECTRIC_GARAGE_DOOR: 'deviceType.electricGarageDoor',
  ELECTRIC_OVEN: 'deviceType.electricOven',
  ELECTRIC_STOVE: 'deviceType.electricStove',
  ELECTRIC_TUMBLE_DRYER: 'deviceType.electricTumbleDryper',
  ELECTRIC_TUMBLE_DRYER_WITH_HEAT_PUMP: 'deviceType.tumbleDryerWithHeatPump',
  ELECTRIC_WATER_BOILER: 'deviceType.electricWaterBoiler',
  FREEZER: 'deviceType.freezer',
  GAME_CONSOLE: 'deviceType.gameConsole',
  GAS_CENTRAL_HEATING: 'deviceType.gasCentralHeating',
  GAS_OVEN: 'deviceType.gasOven',
  GAS_STOVE: 'deviceType.gasStove',
  GAS_TUMBLE_DRYER: 'deviceType.gasTumbleDryer',
  GAS_WATER_BOILER: 'deviceType.gasWaterBoiler',
  HOME_CINEMA: 'deviceType.homeCinema',
  INDUCTION_STOVE: 'deviceType.inductionStove',
  IRON: 'deviceType.iron',
  KETTLE: 'deviceType.kettle',
  LAPTOP: 'deviceType.laptop',
  MICROWAVE: 'deviceType.microwave',
  POOL_PUMP: 'deviceType.poolPump',
  PRINTER: 'deviceType.printer',
  REFRIGERATOR: 'deviceType.refrigerator',
  ROOM_AC: 'deviceType.roomAC',
  ROOM_HEATER: 'deviceType.roomHeater',
  TV: 'deviceType.tv',
  VACUUM_CLEANER: 'deviceType.vacuumCleaner',
  WASHING_MACHINE: 'deviceType.washingMachine',
  WATER_PUMP: 'deviceType.waterPump'
};
export function getSurveyAnswerName(key: string) {
  return T(ANSWER_NAMES[key]) || key;
}
