import {IPricingPolicy, PricingPolicyLimits} from '../models/PricingPolicy';

import {APIClient} from './APIClient';

export class PricingPolicyAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getForOrganization(organizationId: number): Promise<IPricingPolicy[]> {
    const url = `/api/v10/organizations/${organizationId}/pricingpolicies`;
    return this.client.doGet(url);
  }

  get(organizationId: number, policyId: number): Promise<IPricingPolicy> {
    const url = `/api/v10/organizations/${organizationId}/pricingpolicies/${policyId}`;
    return this.client.doGet(url);
  }

  create(organizationId: number, policy: IPricingPolicy) {
    const url = `/api/v10/organizations/${organizationId}/pricingpolicies`;
    return this.client.doPost(url, policy);
  }

  update(organizationId: number, policy: IPricingPolicy) {
    const url = `/api/v10/organizations/${organizationId}/pricingpolicies/${policy.id}`;
    return this.client.doPatch(url, policy);
  }

  delete(organizationId: number, policyId: number) {
    const url = `/api/v10/organizations/${organizationId}/pricingpolicies/${policyId}`;
    return this.client.doDelete(url);
  }

  getLimits(organizationId: number): Promise<PricingPolicyLimits> {
    const url = `/api/v10/organizations/${organizationId}/pricingpolicies/limits`;
    return this.client.doGet(url);
  }
}
