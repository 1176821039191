import type {SVGProps} from 'react';
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M11.111 12.666a7 7 0 1 1 1.555-1.555l.011.011 3 3a1.1 1.1 0 1 1-1.556 1.556l-3-3-.011-.011ZM11.8 7a4.8 4.8 0 1 1-9.6 0 4.8 4.8 0 0 1 9.6 0Z" />
  </svg>
);
export default SvgSearch;
