import {T} from '../utils/Internationalization';
import {TranslationKey} from '../utils/TranslationTerms';

import {ILoadUpdateChannel, LoadType} from './Load';

export const enum LegacyConnectionType {
  Grid = 0,
  Offgrid = 1,
  Submeter = 2
}

export const LEGACY_CONNECTION_TYPES = [
  LegacyConnectionType.Grid,
  LegacyConnectionType.Offgrid,
  LegacyConnectionType.Submeter
];
const CHANNEL_CONNECTION_TYPE_NAMES: {[key: string]: TranslationKey} = {
  [LegacyConnectionType.Grid]: 'connectionType.grid',
  [LegacyConnectionType.Offgrid]: 'connectionType.offgrid',
  [LegacyConnectionType.Submeter]: 'connectionType.submeter'
};
export function getLegacyConnectionTypeName(type: LegacyConnectionType): string {
  return T(CHANNEL_CONNECTION_TYPE_NAMES[type] || 'connectionType.unknown');
}

export const enum LegacyConsumptionType {
  Consumption = 0,
  Production = 1,
  Unused = 2,
  Battery = 3
}
export const LEGACY_CONSUMPTION_TYPES = [
  LegacyConsumptionType.Consumption,
  LegacyConsumptionType.Production,
  LegacyConsumptionType.Battery,
  LegacyConsumptionType.Unused
];
const LEGACY_CONSUMPTION_TYPE_NAMES: {[key: string]: TranslationKey} = {
  [LegacyConsumptionType.Consumption]: 'consumptionType.consumption',
  [LegacyConsumptionType.Production]: 'consumptionType.production',
  [LegacyConsumptionType.Battery]: 'consumptionType.battery',
  [LegacyConsumptionType.Unused]: 'consumptionType.unused'
};
export function getLegacyConsumptionTypeName(type: LegacyConsumptionType): string {
  return T(LEGACY_CONSUMPTION_TYPE_NAMES[type] || 'generic.unknown');
}

export function getLoadTypeFromConsumptionType(type: LegacyConsumptionType): LoadType {
  switch (type) {
    case LegacyConsumptionType.Consumption:
      return LoadType.Grid;
    case LegacyConsumptionType.Production:
      return LoadType.Production;
    case LegacyConsumptionType.Battery:
      return LoadType.Storage;
    default:
      return LoadType.Grid;
  }
}

export interface ILegacyChannel {
  balanced: boolean;
  channel: number;
  connection: LegacyConnectionType;
  consumption: LegacyConsumptionType;
  cttype: number;
  name: string;
  nilm: boolean;
  phase: number;
  reversed: boolean;
  updates: ILegacyChannelUpdates;
}

export interface ILegacyChannelUpdates {
  active: ILoadUpdateChannel;
  reactive: ILoadUpdateChannel;
  powerFactor: ILoadUpdateChannel;
}
