import {SeriesElement} from '../cards/ElectricityValues/Series';
import {IConsumptionValue} from '../models/ConsumptionValue';

export function extractLoadFromConsumption(
  consumption: IConsumptionValue[],
  indices: number[],
  multiplier: number
): SeriesElement[] {
  return consumption.map(row => {
    if (row.activePower === undefined) return [row.timestamp, null];

    let sum = 0;
    let hasValues = false;
    for (let index of indices) {
      const value = row.activePower[index];
      if (value !== null) {
        sum += value;
        hasValues = true;
      }
    }
    return [row.timestamp, hasValues ? sum * multiplier : null];
  });
}
