import React, {CSSProperties, useCallback, useMemo} from 'react';

import {Countries, Country} from '../../models/Countries';
import {FormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {ISelectOption, SearchableSelectInput} from '../SearchableSelectInput';

import FormInputGroup from './FormInputGroup';

interface CountryInputProps {
  value: string | undefined;
  onChange: (value: Country | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
  name?: string;
  noneLabel?: string;
  onBlur?: () => void;
}

export function CountryInput(props: CountryInputProps) {
  const {value, onChange, placeholder, disabled, style, name, noneLabel, onBlur} = props;

  const options: ISelectOption[] = useMemo(() => {
    const result = Countries.map(country => ({
      value: country.code,
      label: country.translate()
    }));
    result.sort((a, b) => a.label.localeCompare(b.label));
    return result;
  }, []);

  const country = useMemo(() => Countries.find(x => x.code === value), [value]);

  const handleCountryChanged = useCallback(
    (value: string) => {
      if (value === '') {
        onChange(undefined);
      } else {
        const country = Countries.find(x => x.code === value);
        if (country) onChange(country);
      }
    },
    [onChange]
  );

  return (
    <SearchableSelectInput
      name={name}
      value={country ? country.code : ''}
      onChange={handleCountryChanged}
      options={options}
      placeholder={placeholder || T('countryInput.placeholder')}
      disabled={disabled}
      invalid={options.length === 0 && noneLabel === undefined}
      style={style}
      noneLabel={noneLabel}
      onBlur={onBlur}
    />
  );
}

interface CountryInputGroupProps {
  form: FormState;
  label: string;
  name: string;
  value: string | undefined;
  onChange: (user: Country | undefined) => void;
  inputRef?: (element: HTMLInputElement | null) => void;
  disabled?: boolean;
  info?: string;
  placeholder?: string;
  error?: string;
  required?: boolean;
}

export function CountryInputGroup(props: CountryInputGroupProps) {
  const {form, name, label, info, required, ...otherProps} = props;

  const error = required && !props.value ? T('validator.required', {name: label}) : undefined;
  form.setError(name, error);
  const shownError = form.getShownError(name);

  return (
    <FormInputGroup name={name} label={label} error={props.error || shownError} info={info}>
      <CountryInput
        onBlur={useCallback(() => {
          form.setErrorVisible(name, error !== undefined);
        }, [form, name, error])}
        name={name}
        {...otherProps}
      />
    </FormInputGroup>
  );
}
