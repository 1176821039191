import * as React from 'react';
import {Col, Form, Row} from 'reactstrap';

import ImportCSVModal, {ImportableField} from '../../../modals/ImportCSVModal';
import {useModals} from '../../../modals/ModalContext';
import {ICustomPricingGroupMember} from '../../../models/PricingGroup';
import {ComponentField, DEFAULT_OPTIONS, IFieldOptions, ITableField, StringField} from '../../../models/Table';
import {None} from '../../../utils/Arrays';
import {BrandColors} from '../../../utils/BrandColors';
import {FormContextProvider, useFormContext} from '../../../utils/FormContext';

import {useFormState} from '../../../utils/FormState';
import {T} from '../../../utils/Internationalization';
import {testingClasses} from '../../../utils/TestingClasses';
import {validateAtLeast, validateMatching, validateRFID} from '../../../utils/Validation';
import {Alert, Button as RsButton, FormFeedback} from '../../bootstrap';
import {RowActionButton} from '../../bootstrap/RowActions';
import {Icon} from '../../Icon';
import {TextInput} from '../../inputs/TextInput';
import Table, {IPersistedTableSettings} from '../../Table';
import RowActions from '../../Table/RowActions';

import styles from './ConfigurePricingGroup.module.scss';
import {usePricingGroup} from './PricingGroupProvider';

interface ImportingTag {
  token: string;
  alias: string;
}

interface NewTokenFormProps {
  onTokenAdd: (token: {alias: string; token: string}) => string | undefined;
}

interface ConfigureCustomGroupProps {
  settings: IPersistedTableSettings;
  onTableSettingsChanged: (settings: IPersistedTableSettings) => void;
}

function NewTokenForm(props: NewTokenFormProps) {
  const {onTokenAdd} = props;
  const form = useFormState();

  const [alias, setAlias] = React.useState<string>('');
  const [token, setToken] = React.useState<string>('');

  const handleClickedAdd = () => {
    form.clearServerErrors();
    if (form.hasErrors()) {
      form.showErrors();
      return;
    }

    const error = onTokenAdd({alias, token});
    if (error) {
      form.setServerError('token', error);
    } else {
      setToken('');
      setAlias('');
    }
  };

  return (
    <FormContextProvider value={form}>
      <Form>
        <Row>
          <Col md={4}>
            <TextInput
              name="token"
              label={T('pricingGroups.addMember.token')}
              placeholder={T('pricingGroups.addMember.token')}
              validate={validateRFID}
              value={token}
              onChange={setToken}
            />
          </Col>
          <Col md={4}>
            <TextInput
              name="alias"
              label={T('pricingGroups.addMember.alias')}
              placeholder={T('pricingGroups.addMember.alias')}
              validate={validateAtLeast(1)}
              value={alias}
              onChange={setAlias}
            />
          </Col>
          <Col md={2}>
            <RsButton onClick={handleClickedAdd}>{T('pricingGroups.custom.add')}</RsButton>
          </Col>
        </Row>
        {form.getShownError('token') && (
          <FormFeedback name="token-error" valid={false} style={{display: 'block'}}>
            {form.getShownError('token')}
          </FormFeedback>
        )}
      </Form>
    </FormContextProvider>
  );
}

function ConfigureCustomGroup(props: ConfigureCustomGroupProps) {
  const {settings, onTableSettingsChanged} = props;
  const {pricingGroup, onChange} = usePricingGroup();
  const modals = useModals();

  const handleRemove = React.useCallback(
    (member: ICustomPricingGroupMember) => {
      onChange({
        ...pricingGroup,
        members: pricingGroup.members?.filter(m => m.token !== member.token)
      });
    },
    [onChange, pricingGroup]
  );

  const handleAddToken = React.useCallback(
    (item: ICustomPricingGroupMember) => {
      if (pricingGroup?.members?.some(member => member.token.toLocaleUpperCase() === item.token.toLocaleUpperCase())) {
        return T('pricingGroups.add.errors.tokenDuplicate');
      }
      onChange({
        ...pricingGroup,
        members: [...(pricingGroup?.members || []), item]
      });
    },
    [onChange, pricingGroup]
  );

  const handleClickedImport = async () => {
    const fields: ImportableField[] = [
      {
        field: 'target',
        label: T('chargingStationPrivileges.column.rfidTag'),
        required: true,
        validator: validateMatching(/^[0-9A-Fa-f:]+$/, T('chargingRFIDWhitelist.invalidRFID'))
      },
      {
        field: 'alias',
        label: T('chargingStationPrivileges.column.comment'),
        required: true
      }
    ];

    const handleImport = (values: {[key: string]: string}[]) => {
      const tags: ImportingTag[] = values
        .filter(value => {
          const token = value.target.trimEnd();
          return !pricingGroup?.members?.some(member => member.token === token);
        })
        .map(value => ({
          token: value.target.trim().toLocaleUpperCase(),
          alias: value.alias
        }));

      onChange({
        ...pricingGroup,
        members: [...(pricingGroup?.members || []), ...tags]
      });

      return Promise.resolve(undefined);
    };

    await modals.show<boolean>(props => (
      <ImportCSVModal
        fields={fields}
        noun="rfidCard"
        info={T('pricingGroups.tokenGroup.tableInfo')}
        importer={handleImport}
        {...props}
      />
    ));
  };

  const fields: ITableField<ICustomPricingGroupMember>[] = React.useMemo(() => {
    return [
      new TokenField(),
      new StringField('alias', T('pricingGroups.addMember.alias')),
      new ComponentField('actions', T('generic.actions'), member => {
        return (
          <RowActions>
            <RowActionButton
              action="delete"
              title={T('sharedUsers.remove.tooltip')}
              onClick={() => handleRemove(member)}
              className={testingClasses.remove}
              data-testid={testingClasses.remove}
            />
          </RowActions>
        );
      })
    ];
  }, [handleRemove]);

  return (
    <>
      <p>{T('pricingGroups.custom.description')}</p>
      <NewTokenForm onTokenAdd={handleAddToken} />
      <div className={styles.bulkImportContainer}>
        <button type="button" className="btn-link btn" onClick={handleClickedImport}>
          {T('pricingGroups.bulkImport')}
        </button>
      </div>
      <div className={styles.pricingGroupCustomContent}>
        <Table
          style={{height: 400}}
          fields={fields}
          items={pricingGroup.members || None}
          settings={settings!}
          updateSettings={table => onTableSettingsChanged(table)}
        />
      </div>
    </>
  );
}

function DetailGroupConfig() {
  const [tableSettings, setTableSettings] = React.useState<IPersistedTableSettings>({
    pageSize: 10,
    columns: [
      {name: 'token', visible: true},
      {name: 'alias', visible: true}
    ]
  });
  const form = useFormContext();

  const {pricingGroup} = usePricingGroup();
  const onChangedTableSettings = (settings: IPersistedTableSettings) => {
    setTableSettings(settings);
  };

  return (
    <Form>
      <FormContextProvider value={form}>
        <h2 className="m-0 p-0 pb-3 font-bold">{T('pricingGroups.add.details.title')}</h2>
        {form.getServerError('tokens') && (
          <Alert color="danger" className="mb-2">
            <p>{form.getServerError('tokens')}</p>
          </Alert>
        )}
        {pricingGroup.type === 'CUSTOM' ? (
          <ConfigureCustomGroup settings={tableSettings} onTableSettingsChanged={onChangedTableSettings} />
        ) : null}
      </FormContextProvider>
    </Form>
  );
}

export {DetailGroupConfig};

class TokenField implements ITableField<ICustomPricingGroupMember> {
  name: string;
  label: string;
  options: IFieldOptions;

  constructor() {
    this.name = 'token';
    this.label = T('pricingGroups.addMember.token');
    this.options = {...DEFAULT_OPTIONS};
  }

  renderCellContent(item: ICustomPricingGroupMember): React.ReactNode {
    return (
      <span style={{color: item.error === undefined ? undefined : BrandColors.Red}}>
        {item.token}
        {item.error && (
          <>
            {' '}
            <i
              className={Icon.Exclamation}
              title={item.error}
              style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}
            />
          </>
        )}
      </span>
    );
  }

  getExportCSVValue(item: ICustomPricingGroupMember): string {
    return item.token;
  }

  getExportExcelValue(item: ICustomPricingGroupMember): string | number | Date {
    return item.token;
  }

  sort(a: ICustomPricingGroupMember, b: ICustomPricingGroupMember): number {
    return a.token.localeCompare(b.token);
  }
}
