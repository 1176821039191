import React, {useState, useMemo} from 'react';

import {useAppContext} from '../app/context';
import API from '../core/api';

import {T} from './Internationalization';
import {RepeatableAbortController} from './RepeatableAbortController';

export enum FetchBehavior {
  Normal,
  SkipErrorHandling,
  Silent
}

export type Fetcher = <T>(
  key: string,
  call: (api: API) => Promise<T>,
  name: string,
  behavior?: FetchBehavior
) => Promise<T>;

export interface IBaseFetchingItem {
  name: string;
  loading: boolean;
  error?: string;
}

export interface IFetchingItem extends IBaseFetchingItem {
  key: string;
  abort?: RepeatableAbortController;
}

export interface FetcherProps {
  fetch: Fetcher;
  fetching: IFetchingItem[];
  loading: boolean;
  abortFetching: RepeatableAbortController;
}

export function withFetcher<P>(Component: React.ComponentClass<P & FetcherProps>): React.FunctionComponent<P> {
  return (props: P) => {
    const [fetching, setFetching] = useState<IFetchingItem[]>([]);
    const context = useAppContext();
    const {api, abort} = useMemo(() => {
      const abort = new RepeatableAbortController();
      const api = context.api.withAbort(abort);
      return {abort, api};
    }, [context.api]);

    function fetch<T>(
      key: string,
      call: (api: API) => Promise<T>,
      name: string,
      behavior: FetchBehavior = FetchBehavior.Normal
    ): Promise<T> {
      const newFetching = fetching.filter(entry => entry.key !== key);
      if (behavior !== FetchBehavior.Silent) {
        newFetching.push({key, name, loading: true});
      }

      setFetching(newFetching);
      return new Promise((resolve, reject) => {
        call(api)
          .then(result => {
            setFetching(fetching.filter(entry => entry.key !== key));
            resolve(result);
          })
          .catch(error => {
            if (behavior === FetchBehavior.Normal) {
              setFetching(
                fetching.map(entry =>
                  entry.key === key
                    ? {
                        key,
                        name,
                        loading: false,
                        error: T('card.couldNotLoad', {entity: name})
                      }
                    : entry
                )
              );
            } else if (behavior === FetchBehavior.SkipErrorHandling) {
              setFetching(fetching.filter(entry => entry.key !== key));
              reject(error);
            }
          });
      });
    }

    return (
      <Component fetch={fetch} fetching={fetching} abortFetching={abort} loading={fetching.length > 0} {...props} />
    );
  };
}
