import {deepEqual} from 'fast-equals';
import React, {useEffect, useMemo, useReducer} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {BessStatusIndicator} from '../../components/BessStatusIndicator';
import SelectLocationButton from '../../components/SelectLocationButton';
import {migrateTableSettings, SortOrder} from '../../components/Table';
import {Alert, AlertDescription} from '../../components/ui/alert';
import {Button} from '../../components/ui/button';
import {TabsList, Tabs, TabsContent, TabsTrigger} from '../../components/ui/tabs';
import {useLiveBessPower} from '../../livedata/LiveBessStatus';
import {UserRights} from '../../models/AuthUser';
import {BessUnit} from '../../models/BessUnit';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {DeviceType} from '../../models/DeviceType';
import {IOverloadProtectionConfiguration} from '../../models/OverloadProtection';
import {IBaseSmartDevice, ICapacityProtectionConfiguration} from '../../models/SmartDevice';
import {useChildLocations, useLocation, useSmartDevices} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {TranslationKey} from '../../utils/TranslationTerms';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardLocation, useCardLocationParent} from '../CardUtils';
import {CardActions} from '../components';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {getSpecificationData} from './data/specificationData';
import {getModesAndTreshold} from './data/usageConfigurationData';
import {
  bessCapacity,
  BessData,
  BessHealthLimit,
  bessMaxChargeSpeed,
  bessMaxDischargeSpeed,
  bessMaxInverterPower,
  bessMaxStateOfCharge,
  bessMaxSurplusReserveStateOfCharge,
  bessMinPeakShavingStateOfCharge,
  bessMinStateOfCharge,
  BessModeType,
  bessModi,
  BessSpecificationData,
  BessTreshold,
  BessUsageConfigurationData,
  DEFAULT_BESS_DATA_STATE
} from './models/BessUnitConfiguration.model';
import AuditTrailSection from './sections/AuditTrailSection';
import HistoricSection from './sections/HistoricSection';
import LiveSection from './sections/LiveSection';
import LogbookSection from './sections/LogbookSection';
import PerformanceSection from './sections/PerformanceSection';
import SlaSection from './sections/SlaSection';
import SpecificationSection from './sections/SpecificationSection';
import UsageConfigurationSection from './sections/UsageConfigurationSection';

type SmappeeBessSettings = ICardSettingsWithTable;

const bessTabs = [
  // 'performance', // wait with this tab
  // 'historic', // wait with this tab
  'live',
  'usageConfiguration',
  // 'logbook', // wait with this tab
  // 'auditTrail', // wait with this tab
  // 'sla', // wait with this tab, phrase key: T('bess.tab.sla')
  'specification' // @todo: rename this later to 'Commissioning'
];

const capacityProtectionConfigReducer = (
  state: ICapacityProtectionConfiguration,
  newState: Partial<ICapacityProtectionConfiguration>
) => {
  return {...state, ...newState};
};

const initialCapacityProtectionConfiguration: ICapacityProtectionConfiguration = {
  active: false,
  capacityMaximumPower: 0,
  capacitySuggestedPower: 0
};

const SmappeeBess = (props: ICardProps<SmappeeBessSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const [loadingState, setLoadingState] = React.useState<'saving' | 'idle'>('idle');
  const [saveError, setSaveError] = React.useState<string | undefined>();
  const [isDirty, setIsDirty] = React.useState(false);

  // Data loading
  const locationSummary = useCardLocation(settings);
  const {api} = useAppContext();
  const [location] = useLocation(fetch, locationSummary?.id);
  const parentId = location?.parentId;
  const locationId = locationSummary?.id;
  const deviceType = locationSummary?.deviceType as DeviceType;
  const uuid = locationSummary?.uuid as string;
  const [smartDevices, refreshSmartDevices] = useSmartDevices(fetch, locationId);
  const bessDevice = smartDevices?.find(d => d.type.name === 'smappee-bess');
  const [childLocations] = useChildLocations(fetch, location);
  const bessChildren = useMemo(() => childLocations.filter(l => l.deviceType === DeviceType.Bess), [childLocations]);

  const markFormDirty = React.useCallback(() => {
    setIsDirty(true);
  }, []);

  const [highLevelConfiguration, refreshHighLevelConfig] = useCardLoader(
    api => {
      if (parentId === undefined) {
        return Promise.resolve(undefined);
      } else {
        return api.getHighLevelConfiguration(parentId);
      }
    },
    [parentId],
    T('phasorDisplay.loading.configuration'),
    undefined
  );

  const [maximumLoadConfiguration, refreshMaximumLoadConfiguration] = useCardLoader(
    api => {
      if (parentId === undefined) {
        return Promise.resolve(undefined);
      } else {
        return api.getOverloadProtection(parentId);
      }
    },
    [parentId],
    'maxLoadConfiguration',
    undefined
  );

  const isLocalProductionAvailable = useMemo(() => {
    return highLevelConfiguration?.measurements.find(m => m.type === 'PRODUCTION')?.type === 'PRODUCTION';
  }, [highLevelConfiguration?.measurements]);

  const actualUsageConfigurationData = useMemo(() => {
    if (!bessDevice) return;
    return getModesAndTreshold(bessDevice);
  }, [bessDevice]);

  const [bessFormData, setBessFormData] = React.useState<BessData>(DEFAULT_BESS_DATA_STATE);

  useEffect(() => {
    if (!actualUsageConfigurationData) return;
    if (!actualUsageConfigurationData.modes && !actualUsageConfigurationData.treshold) return;
    const upperHealthValue = actualUsageConfigurationData.treshold.upperHealthStatus
      ? +actualUsageConfigurationData.treshold.upperHealthStatus
      : 0;
    const lowerHealthValue = actualUsageConfigurationData.treshold.lowerHealthStatus
      ? +actualUsageConfigurationData.treshold.lowerHealthStatus
      : 0;
    const surplusReserveValue = actualUsageConfigurationData.treshold.surplusReserve
      ? +actualUsageConfigurationData.treshold.surplusReserve
      : 0;
    const peakShavingSafetyValue = actualUsageConfigurationData.treshold.peakShavingSafety
      ? +actualUsageConfigurationData.treshold.peakShavingSafety
      : 0;
    setBessFormData(prevData => ({
      ...prevData,
      usageConfiguration: {
        ...prevData.usageConfiguration,
        modes: (actualUsageConfigurationData.modes as BessModeType[]) || ([] as unknown as BessModeType),
        localProductionAvailable: isLocalProductionAvailable,
        treshold: {
          upperHealthStatus: upperHealthValue,
          lowerHealthStatus: lowerHealthValue,
          surplusReserve: surplusReserveValue,
          peakShavingSafety: peakShavingSafetyValue
        }
      }
    }));
  }, [actualUsageConfigurationData, isLocalProductionAvailable]);

  const bessUnit: BessUnit = {
    data: {type: deviceType, supportsMID: false, serialNumber: locationSummary?.serialNumber}
  };

  const [liveData, status] = useLiveBessPower(uuid, bessUnit);
  const [{active, capacityMaximumPower, capacitySuggestedPower}, setCapacityConfiguration] = useReducer(
    capacityProtectionConfigReducer,
    initialCapacityProtectionConfiguration
  );

  useEffect(() => {
    async function fetchCapacitySettings() {
      if (!parentId) return;
      const configuration = await api.getCapacityProtection(parentId);

      setCapacityConfiguration(configuration);
    }

    fetchCapacitySettings();
  }, [api, parentId]);

  const specificationData = useMemo<BessSpecificationData | undefined>(() => {
    if (!bessDevice || !locationSummary) {
      return undefined;
    }
    return getSpecificationData(bessDevice, locationSummary, maximumLoadConfiguration, capacityMaximumPower);
  }, [bessDevice, locationSummary, maximumLoadConfiguration, capacityMaximumPower]);

  // Update bessFormData.specification when specificationData changes
  useEffect(() => {
    if (specificationData) {
      setBessFormData(prevData => {
        if (!deepEqual(prevData.specification, specificationData)) {
          return {
            ...prevData,
            specification: specificationData
          };
        }
        return prevData;
      });
    }
  }, [specificationData]);

  useEffect(() => {
    if (isDirty) {
      NotificationManager.warning(
        T('bess.notification.unsavedChanges.body'),
        T('bess.notification.unsavedChanges.title'),
        5000
      );
    }
  }, [isDirty]);

  // update a whole section (form data from specific tabs)
  const handleSectionUpdate = <T extends keyof BessData>(section: T, updatedData: BessData[T]) => {
    setBessFormData(prev => ({
      ...prev,
      [section]: updatedData
    }));
  };

  // Property Saving functions
  const saveCapacityConfig = async (parentId: number, capacityConfig: ICapacityProtectionConfiguration) => {
    if (!parentId || !capacityConfig.capacityMaximumPower) return;
    await api.updateCapacityProtection(parentId, capacityConfig);
  };

  const saveMaximumCurrent = async (parentId: number, overloadConfig: IOverloadProtectionConfiguration) => {
    if (!parentId || !overloadConfig) return;
    await api.updateOverloadProtection(parentId, overloadConfig);
  };

  const saveModesConfig = async (
    locationId: number,
    smartDevice: IBaseSmartDevice,
    modesConfig: {modes: BessModeType[]}
  ) => {
    if (!locationId || !smartDevice || !modesConfig.modes.length) return;

    // No undefined values allowed
    const filteredModes = modesConfig.modes.filter(el => el !== undefined);
    const modesList = filteredModes.map(m => {
      return {String: m as string};
    });
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessModi, {
      List: modesList
    });
  };

  const saveBessCapacityConfig = async (
    locationId: number,
    smartDevice: IBaseSmartDevice,
    bessCapacityConfig: {value: number}
  ) => {
    if (!locationId || !smartDevice || bessCapacityConfig.value === undefined) return;
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessCapacity, {
      BigDecimal: bessCapacityConfig.value
    });
  };

  const saveMaxDischargeSpeedConfig = async (
    locationId: number,
    smartDevice: IBaseSmartDevice,
    maxDischargeSpeedConfig: {value: number}
  ) => {
    if (!locationId || !smartDevice || maxDischargeSpeedConfig.value === undefined) return;
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessMaxDischargeSpeed, {
      BigDecimal: maxDischargeSpeedConfig.value
    });
  };

  const saveMaxChargeSpeedConfig = async (
    locationId: number,
    smartDevice: IBaseSmartDevice,
    maxChargeSpeedConfig: {value: number}
  ) => {
    if (!locationId || !smartDevice || maxChargeSpeedConfig.value === undefined) return;
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessMaxChargeSpeed, {
      BigDecimal: maxChargeSpeedConfig.value
    });
  };

  const saveSurplusConfig = async (
    locationId: number,
    smartDevice: IBaseSmartDevice,
    surplusConfig: {value: number}
  ) => {
    if (!locationId || !smartDevice || surplusConfig.value === undefined) return;
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessMaxSurplusReserveStateOfCharge, {
      BigDecimal: surplusConfig.value
    });
  };

  const savePeakShavingConfig = async (
    locationId: number,
    smartDevice: IBaseSmartDevice,
    peakShavingConfig: {value: number}
  ) => {
    if (!locationId || !smartDevice || peakShavingConfig.value === undefined) return;
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessMinPeakShavingStateOfCharge, {
      BigDecimal: peakShavingConfig.value
    });
  };

  const saveMaxStateOfCharge = async (locationId: number, smartDevice: IBaseSmartDevice, config: {value: number}) => {
    if (!locationId || !smartDevice || config.value === undefined) return;
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessMaxStateOfCharge, {
      BigDecimal: config.value
    });
  };

  const saveMinStateOfCharge = async (locationId: number, smartDevice: IBaseSmartDevice, config: {value: number}) => {
    if (!locationId || !smartDevice || config.value === undefined) return;
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessMinStateOfCharge, {
      BigDecimal: config.value
    });
  };

  const saveMaxInvertedPower = async (locationId: number, smartDevice: IBaseSmartDevice, config: {value: number}) => {
    if (!locationId || !smartDevice || config.value === undefined) return;
    await api.smartDevices.updateConfigurationProperty(locationId, smartDevice, bessMaxInverterPower, {
      BigDecimal: config.value
    });
  };

  const handleSave = async (data: BessData) => {
    if (!locationId || !parentId || !bessDevice) return;

    setLoadingState('saving');
    setSaveError(undefined);

    // GRID
    const capacityConfig: ICapacityProtectionConfiguration = {
      active: true, // this needs to be set to true for updating!
      capacityMaximumPower: data?.specification?.capacityMaximumPower || undefined
    };

    const overloadConfig: IOverloadProtectionConfiguration = {
      active: true,
      maximumLoad: data?.specification?.maxCurrent || undefined
    };

    // BESS SPECS
    const modesConfig: any = {
      modes: data?.usageConfiguration?.modes || []
    };

    const surplusConfig = {
      value: data?.usageConfiguration?.treshold?.surplusReserve || 0
    };

    const peakShavingConfig = {
      value: data?.usageConfiguration?.treshold?.peakShavingSafety || 0
    };

    const bessCapacityConfig = {
      value: data?.specification?.maxCapacity || 0
    };

    const maxDischargeSpeedConfig = {
      value: data?.specification?.maxDischargeSpeed || 0
    };

    const maxChargeSpeedConfig = {
      value: data?.specification?.maxChargeSpeed || 0
    };

    const maxInverterPowerConfig = {
      value: data?.specification?.maxInverterPower || 0
    };

    const upperHealthConfig = {
      value: data?.usageConfiguration?.treshold?.upperHealthStatus || 0
    };

    const lowerHealthConfig = {
      value: data?.usageConfiguration?.treshold?.lowerHealthStatus || 0
    };

    // @todo: refactor later to save only the 'dirty' values
    //        for now, save all savable values in sequentially
    //
    try {
      const saveFunctions = [
        // Grid saving properties:
        () => saveCapacityConfig(parentId, capacityConfig),
        () => saveMaximumCurrent(parentId, overloadConfig),
        // Bess saving properties:
        () => saveModesConfig(locationId, bessDevice, modesConfig),
        () => saveBessCapacityConfig(locationId, bessDevice, bessCapacityConfig),
        () => saveMaxDischargeSpeedConfig(locationId, bessDevice, maxDischargeSpeedConfig),
        () => saveMaxChargeSpeedConfig(locationId, bessDevice, maxChargeSpeedConfig),
        () => saveMaxInvertedPower(locationId, bessDevice, maxInverterPowerConfig),
        () => saveSurplusConfig(locationId, bessDevice, surplusConfig),
        () => savePeakShavingConfig(locationId, bessDevice, peakShavingConfig),
        () => saveMaxStateOfCharge(locationId, bessDevice, upperHealthConfig),
        () => saveMinStateOfCharge(locationId, bessDevice, lowerHealthConfig)
      ];
      for (const saveFunction of saveFunctions) {
        await saveFunction(); // Execute each save function sequentially
      }
      NotificationManager.success(
        T('bess.notification.save.success.body'),
        T('bess.notification.save.success.title'),
        5000
      );
      setIsDirty(false);
    } catch (error: any) {
      setSaveError(error.message || T('bess.errors.save'));
    } finally {
      setLoadingState('idle');
    }
  };

  const handleSectionChange = <T extends keyof BessData, K extends keyof BessData[T]>(
    section: T,
    field: K,
    value: BessData[T][K]
  ) => {
    // Deal with nested (object) fields (e.g. healthLimits in SpecificationSection)
    // and regular fields
    setBessFormData(prev => {
      const updatedSection = {...prev[section]};

      if (typeof updatedSection[field] === 'object' && !Array.isArray(value) && updatedSection[field] !== null) {
        updatedSection[field] = {...(updatedSection[field] as object), ...value};
      } else {
        updatedSection[field] = value;
      }

      if (section === 'specification' && field === 'healthLimits') {
        return {
          ...prev,
          specification: updatedSection as BessSpecificationData,
          usageConfiguration: {
            ...prev.usageConfiguration,
            treshold: {
              ...prev.usageConfiguration.treshold,
              upperHealthStatus: (value as BessHealthLimit).upperHealthStatus,
              lowerHealthStatus: (value as BessHealthLimit).lowerHealthStatus
            }
          }
        };
      } else if (section === 'usageConfiguration' && field === 'treshold') {
        return {
          ...prev,
          usageConfiguration: updatedSection as BessUsageConfigurationData,
          specification: {
            ...prev.specification,
            healthLimits: {
              upperHealthStatus: (value as BessTreshold).upperHealthStatus,
              lowerHealthStatus: (value as BessTreshold).lowerHealthStatus
            }
          }
        };
      }

      return {
        ...prev,
        [section]: updatedSection
      };
    });
  };

  const actions: CustomActions = state => (
    <CardActions>
      {state.ready && status !== undefined && <BessStatusIndicator status={status} />}
      <span className="tw-flex-1" />
      {isDirty && (
        <Alert variant="warning" className="!tw-w-auto !tw-max-w-[20rem] !tw-max-h-[2.5rem] !tw-py-2">
          <AlertDescription>{T('bess.notification.unsavedChanges.body')}</AlertDescription>
        </Alert>
      )}
      <div className="tw-flex tw-justify-end tw-items-center">
        <Button variant="primary_default" onClick={() => handleSave(bessFormData)}>
          {T('generic.save')}
        </Button>
      </div>
    </CardActions>
  );

  let error: string | JSX.Element | undefined;
  if (location && location.deviceType !== DeviceType.Bess) {
    error = (
      <span>
        {T(bessChildren.length > 0 ? 'bess.errors.invalidLocation.hasChildren' : 'bess.errors.invalidLocation')}
        <br />
        {bessChildren.map((location, index) => (
          <span key={location.id}>
            {index > 0 && <>&middot;</>}
            <SelectLocationButton location={location} />
          </span>
        ))}
      </span>
    );
  }

  return (
    <CardView error={error} actions={actions} {...cardViewProps(props)}>
      <Tabs defaultValue="live" className="tw-w-full">
        <TabsList className="!tw-w-full !tw-max-w-full !tw-overflow-x-auto !tw-whitespace-nowrap !tw-p-0 !tw-justify-start">
          {bessTabs.map(title => (
            <TabsTrigger key={title} value={title}>
              {T(`bess.tab.${title}` as TranslationKey)}
            </TabsTrigger>
          ))}
        </TabsList>
        {bessTabs.map(tab => (
          <TabsContent key={tab} value={tab} className="tw-mt-6">
            {tab === 'live' && (
              <LiveSection
                liveData={liveData}
                maxCapacity={bessFormData.specification.maxCapacity}
                data={bessFormData.live}
                markFormDirty={markFormDirty}
                onChange={(field, value) => handleSectionChange('live', field, value)}
              />
            )}
            {tab === 'historic' && <HistoricSection />}
            {tab === 'logbook' && <LogbookSection />}
            {tab === 'usageConfiguration' && (
              <UsageConfigurationSection
                data={bessFormData.usageConfiguration}
                markFormDirty={markFormDirty}
                onChange={(field, value) => handleSectionChange('usageConfiguration', field, value)}
              />
            )}
            {tab === 'specification' && (
              <SpecificationSection
                data={bessFormData.specification}
                markFormDirty={markFormDirty}
                onChange={(field, value) => handleSectionChange('specification', field, value)}
              />
            )}
            {tab === 'auditTrail' && <AuditTrailSection />}
            {tab === 'sla' && <SlaSection />}
            {tab === 'performance' && <PerformanceSection />}
          </TabsContent>
        ))}
      </Tabs>
    </CardView>
  );
};

const DEFAULT_CARD_SETTINGS: SmappeeBessSettings = {
  table: {
    pageSize: 20,
    sortColumn: 'order',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'order', visible: false},
      {name: 'key', visible: true}
    ]
  }
};

const CARD: ICardType<SmappeeBessSettings> = {
  type: CardTypeKey.SmappeeBess,
  title: 'smappeeBess.title',
  description: 'smappeeBess.description',
  categories: [CardCategory.CONFIGURATION, CardCategory.BESS],
  rights: UserRights.User,
  width: 4,
  height: 3,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  upgradeSettings: migrateTableSettings('table', DEFAULT_CARD_SETTINGS.table),
  cardClass: SmappeeBess
};
export default CARD;
