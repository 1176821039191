import {
  DeviceType,
  getDeviceTypeInfo,
  getDeviceTypeFromSerial,
  getDeviceTypeLabelFor,
  getInternalTypeLabelFor,
  isGatewayDevice
} from './DeviceType';
import {ILoadUpdateChannel} from './Load';
import {SensorChannelType} from './Location';
import {UsageUnit} from './UsageValue';

export interface IDevice {
  id?: string;
  type: DeviceType;
  serialNumber: string;
  firmware?: IDeviceFirmware;
  expertPortalUrl: string;
  active: boolean;
  input?: {
    channels: IDeviceInputChannel[];
  };
  output?: {
    channels: IDeviceOutputChannel[];
    controls: IDeviceOutputControl[];
  };
}

export interface IDeviceInputChannel {
  id: number;
  name: string;
  type: SensorChannelType;
  valueType: ChannelValueType;
  unit: UsageUnit;
  enabled: boolean;
  pulsesPerUnit: number;
  customUnitName?: string;
  customStateTextOn?: string;
  customStateTextOff?: string;
  sensor: {
    id: number;
    serialNumber: string;
  };
  updateChannel: ILoadUpdateChannel;
}

export interface IDeviceOutputChannel {
  id: number;
  stateConfigIndex: number;
  sensorId: number;
  type: SensorChannelType;
  enabled: boolean;
}

export interface IDeviceOutputControl {
  id: number;
  name: string;
  serialNumber: string;
  favourite: boolean;
  active: boolean;
  updateChannel: ILoadUpdateChannel;
  channels: number[];
  configurations: IDeviceOutputConfiguration[];
}

export interface IDeviceOutputConfiguration {
  id: string;
  name: string;
  current: boolean;
  channelStates: OutputChannelState[];
}

export const enum OutputChannelState {
  On = 'ON',
  Off = 'OFF',
  NotIncluded = 'NOT_INCLUDED'
}

export const enum ChannelValueType {
  Cumulative = 'CUMULATIVE',
  State = 'STATE'
}

export interface IDeviceFirmware {
  current: string;
  target?: string;
}

export interface IFirmwareVersion {
  version: string;
  isDefault: boolean;
}

export class Device {
  static getTypeLabelFor(type: DeviceType): string {
    return getDeviceTypeLabelFor(type);
  }

  static getInternalTypeLabelFor(type: DeviceType): string {
    return getInternalTypeLabelFor(type);
  }

  static getLabelForSerial(serial: string): string {
    return getDeviceTypeFromSerial(serial);
  }

  static getInternalLabelForSerial(serial: string): string {
    return Device.getInternalTypeLabelFor(getDeviceTypeFromSerial(serial));
  }

  static hasLiveValues(type: DeviceType): boolean {
    return type !== DeviceType.Solar && type !== DeviceType.Energy && type !== DeviceType.P1S1;
  }

  static isInfinity(type: DeviceType): boolean {
    return (
      type === DeviceType.Genius ||
      type === DeviceType.WifiConnect ||
      type === DeviceType.EthernetConnect ||
      type === DeviceType.FourGConnect
    );
  }

  static isPro(type: DeviceType): boolean {
    return type === DeviceType.Pro || type === DeviceType.Plus;
  }

  static isGasWater(type: DeviceType): boolean {
    return type === DeviceType.GasWater;
  }

  static isWifiConnect(type: DeviceType): boolean {
    return type === DeviceType.WifiConnect;
  }

  static isRebootable(type: DeviceType): boolean {
    return getDeviceTypeInfo(type).rebootable;
  }

  static isLegacy(type: DeviceType): boolean {
    return (
      type === DeviceType.Energy || type === DeviceType.Solar || type === DeviceType.Pro || type === DeviceType.Plus
    );
  }

  static fromJSON(data: IDevice): Device {
    return new Device(
      data.id,
      data.type,
      data.serialNumber,
      data.firmware || {current: ''},
      data.active,
      data.expertPortalUrl
    );
  }

  id: string | undefined;
  type: DeviceType;
  serialNumber: string;
  firmware: IDeviceFirmware;
  active: boolean;
  expertPortalUrl: string;

  constructor(
    id: string | undefined,
    type: DeviceType,
    serialNumber: string,
    firmware: IDeviceFirmware,
    active: boolean,
    expertPortalUrl: string
  ) {
    this.id = id;
    this.type = type;
    this.serialNumber = serialNumber;
    this.firmware = firmware;
    this.active = active;
    this.expertPortalUrl = expertPortalUrl;
  }

  isMainDevice() {
    return [
      DeviceType.Solar,
      DeviceType.Energy,
      DeviceType.Plus,
      DeviceType.Pro,
      DeviceType.Genius,
      DeviceType.GasWater
    ].includes(this.type);
  }

  isInfinityDevice() {
    return [
      DeviceType.Genius,
      DeviceType.WifiConnect,
      DeviceType.PowerBox,
      DeviceType.CTHub,
      DeviceType.ClosedCTHub,
      DeviceType.CTHubLite,
      DeviceType.Hub16
    ].includes(this.type);
  }

  isChargerDevice() {
    return [
      DeviceType.ACChargingController,
      DeviceType.ACChargingControllerWithRFID,
      DeviceType.DCChargingController,
      DeviceType.DCCarChargerControllerV4,
      DeviceType.LED,
      DeviceType.RFID
    ].includes(this.type);
  }

  isInactive() {
    return !this.active && !isGatewayDevice(this.type);
  }
}
