import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../../app/context';
import {SingleActionModal, Button} from '../../../components/bootstrap';
import {ReactSortable} from '../../../components/sortable/Sortable';

import {ConfirmationResult, ConfirmationPromiseModal} from '../../../modals/ConfirmationPromiseModal';
import {useModals} from '../../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {ISearchField, SearchFieldSpecies} from '../../../models/Organization';

import {T} from '../../../utils/Internationalization';

import {SearchFieldEditor} from './SearchFieldEditor';

interface IEditingProperty {
  id: number; // used to internally track who's who
  isNew: boolean;
  field: ISearchField;
}

interface EditSearchFieldsProps extends IPromiseModalProps<void> {
  organizationId: number;
  fields: ISearchField[];
  multilingual: boolean;
}

const EditSearchFields = (props: EditSearchFieldsProps) => {
  const {organizationId, fields, multilingual} = props;
  const {api} = useAppContext();
  const modals = useModals();
  const [isOpen, resolve] = usePromiseModal(props);

  const changedProperties = useRef(new Set<number>());
  const [error, setError] = useState<string>();
  const [editingFields, setEditingFields] = useState<IEditingProperty[]>([]);

  useEffect(() => {
    setEditingFields(
      fields.map((field, index) => ({
        id: index,
        isNew: false,
        field
      }))
    );
  }, [fields]);

  const handleClickedAddField = useCallback(() => {
    setEditingFields(oldFields => {
      const fields = [...oldFields];
      const newId = fields.reduce((result, field) => Math.max(result, field.id), 1) + 1;
      fields.push({
        id: newId,
        isNew: true,
        field: {
          name: '',
          description: '',
          displayName: '',
          sequenceNumber: fields.length,
          species: SearchFieldSpecies.String
        }
      });
      return fields;
    });
  }, []);

  const handleClickSave = async () => {
    try {
      let index = 0;
      for (let field of editingFields) {
        const changed = changedProperties.current.has(field.id) || field.id > index;
        index++;
        if (!changed) continue;

        let savedField = field.field;
        field.field.sequenceNumber = index;
        await api.setOrganizationSearchField(organizationId, savedField);
      }

      setError(undefined);
      NotificationManager.success(T('organizations.searchFields.save.success'));
      resolve();
    } catch (err) {
      NotificationManager.error(T('organizations.searchFields.save.failed'));
      setError(T('organizations.searchFields.save.failed'));
    }
  };

  const fieldViews = useMemo(() => {
    const updateField = (id: number, updates: Partial<ISearchField>) => {
      const oldField = editingFields.find(f => f.id === id);
      if (oldField === undefined) return;

      const newField = {...oldField.field, ...updates};
      const updatedFields = editingFields.map(f =>
        f.id === id
          ? {
              id: f.id,
              isNew: f.isNew,
              field: newField
            }
          : f
      );

      changedProperties.current.add(id);
      setEditingFields(updatedFields);
    };

    const handleRemoveCustomField = async (id: number) => {
      const confirmation = await modals.show(props => (
        <ConfirmationPromiseModal
          title="Remove search field"
          message="Are you sure you want to remove this search field? All associated values on locations will be removed as well."
          {...props}
        />
      ));
      if (confirmation !== ConfirmationResult.Accept) return;

      const index = editingFields.findIndex(f => f.id === id);
      if (index < 0) return editingFields;

      const field = editingFields[index];
      if (!field.isNew) {
        await api.deleteOrganizationSearchField(organizationId, field.field.name);
      }

      setEditingFields(oldFields => {
        const updatedFields = [...oldFields];
        updatedFields.splice(index, 1);
        return updatedFields;
      });
    };

    return editingFields.map(field => {
      const {id, isNew} = field;
      return (
        <SearchFieldEditor
          key={id}
          id={id}
          isNew={isNew}
          multilingual={multilingual}
          field={field.field}
          onFieldChanged={updateField}
          onClickedRemove={handleRemoveCustomField}
        />
      );
    });
  }, [editingFields, modals, api, organizationId, multilingual]);
  const table = (
    <table className="table">
      <thead>
        <tr>
          <th>Field name</th>
          <th>Display name</th>
          <th>Description</th>
          <th>Type</th>
          <th />
        </tr>
      </thead>
      <ReactSortable tag="tbody" list={editingFields} setList={setEditingFields}>
        {fieldViews}
      </ReactSortable>
    </table>
  );

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve()}
      title="Edit search fields"
      action={handleClickSave}
      actionText="Save"
      size="xl"
      error={error}
    >
      {table}
      <Button color="primary" onClick={handleClickedAddField}>
        Add field
      </Button>
    </SingleActionModal>
  );
};

export default EditSearchFields;
