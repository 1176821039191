import React from 'react';

interface BatteryIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const BatteryIcon: React.FC<BatteryIconProps> = ({width = 180, height = 468, fillColor = 'black'}) => (
  <svg
    id="bess-battery"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 180 468"
    fill={fillColor}
  >
    <path d="M138.131,12.189h-19.401V0h-59.379v12.189h-17.486C18.777,12.189,0,30.967,0,54.05v372.092c0,23.077,18.782,41.859,41.866,41.859h96.265c23.096,0,41.869-18.782,41.869-41.859V54.048c0-23.081-18.773-41.86-41.869-41.86h0ZM152,426.144c0,7.69-6.257,13.95-13.949,13.95H41.941c-7.684,0-13.941-6.248-13.941-13.95V54.048c0-7.69,6.257-13.954,13.941-13.954h96.11c7.692,0,13.949,6.263,13.949,13.954v372.095h0Z" />
  </svg>
);

export default BatteryIcon;
