import React from 'react';

import {SMAPPEE_FONT_ICON_MAP} from '../../components/Icon';
import {T} from '../../utils/Internationalization';

import {classes} from '../../utils/Styles';
import {testingClasses} from '../../utils/TestingClasses';

import {ICardSuggestion} from './CardSuggestions';
import styles from './CardSuggestionView.module.scss';

interface CardSuggestionViewProps {
  suggestion: ICardSuggestion;
  onClick: () => void;
}
export const CardSuggestionView = (props: CardSuggestionViewProps) => {
  const {suggestion, onClick} = props;
  const IconComponent = suggestion?.icon ? SMAPPEE_FONT_ICON_MAP[suggestion.icon] : null;
  return (
    <div className={styles.outer}>
      <div
        className={classes(styles.wrapper, testingClasses.styledButton)}
        style={{backgroundColor: suggestion.color, color: suggestion.textColor}}
        onClick={onClick}
        data-testid={testingClasses.styledButton}
      >
        <div className={styles.inner}>
          {IconComponent && (
            <span className="tw-inline-flex">
              <IconComponent width={18} height={18} />
            </span>
          )}
          <br />
          {suggestion.title || T(suggestion.cardType.title)}
        </div>
      </div>
    </div>
  );
};
