export enum BrandColors {
  // SmappeeGreen = '#155a55', // new green
  SmappeeGreen = '#1f3731', // --green-800
  GreenAtlantis = '#7AB3A4', // --green-500, old: "smappee green"
  GreenEden = '#7AB3A4',
  OrangeClementine = '#F5BC4D',
  Blue = '#6C8AC1',
  BlueAlwaysOn = '#6C8AC1',
  Pumice = '#d0d3d2',
  Aztec = '15241f',

  /* Secondary colors */
  GreenSushi = '#99c031',
  OrangeSunglow = '#F5BC4D',
  GreenDeepSea = '#99c031',
  White = '#FFFAF6',
  Red = '#FF5C75',
  BlueEVBase = '#adc6d5',
  BlueCharging = '#2B78E4',

  /* Charging status */
  /* ChargerAvailable = '#135A54', */
  ChargerAvailable = '#cce2dc',
  /* ChargerInUse = '#FEC331', */
  ChargerInUse = '#ffe5b2',
  /* ChargerUnavailable = '#929292', */
  ChargerUnavailable = '#ece1dc',
  /* ChargerError = '#D13A3A' */
  ChargerError = '#f9dae3'
}
/* BlueAlwaysOn = '#2C718F' */
/* GreenAtlantis = '#99c031' */
/* GreenSushi = '#7fa32f' */
/* GreenDeepSea = '#074b46' */
/* GreenEden = '#135a55' */
/* OrangeClementine = '#eb6d01' */
/* Blue = '#009fc7' */
/* Red = '#d13b3b' */
/* White = '#f8f8f8' */
