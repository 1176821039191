import {IConfigurationProperty} from './SmartDevice';

export interface IAppDashboard {
  id: number;
  cards: IAppDashboardCard[];
}

export const enum AppDashboardCardType {
  FeaturesVideo = 'FEATURES_VIDEO',
  Bubbles = 'BUBBLES',
  Scoreboard = 'SCOREBOARD',
  Spendings = 'SPENDINGS',
  Partnership = 'PARTNERSHIP',
  Events = 'EVENTS',
  ActiveApplianceCount = 'ACTIVE_APPLIANCE_COUNT',
  ViewHistoricData = 'VIEW_HISTORIC_DATA',
  InstallNewMonitor = 'INSTALL_NEW_MONITOR',
  MqttUpgradeAwaiting = 'MQTT_UPGRADE_AWAITING',
  Battery = 'BATTERY',
  SubmeterLiveValues = 'SUBMETER_LIVE_VALUES',
  Custom = 'CUSTOM'
}

export interface IAppDashboardCard {
  id: string;
  type: AppDashboardCardType;
  canBeRemoved: boolean;
  configurationProperties: IConfigurationProperty[];
  properties: IConfigurationProperty[];
}
