import {DeviceType} from '../../models/DeviceType';
import {EVBoxKitType, KitCTType} from '../../models/PreconfigurationKit';

export const HardwareCTHub = 1;
export const HardwareSolidCoreGrid = 2;
export const HardwareSolidCoreCharger = 4;
export const HardwareCTHubGrid = 8;
export const HardwareCTHubCharger = 16;

export interface IPreconfigurationKit {
  id: string;
  gridCTType?: KitCTType;
  type: EVBoxKitType;
  name: string;
  ctHubLabel: string;
  gridCTHubLabel?: string;
  carChargerCTHubLabel?: string;
  phase: number;
  gateway: DeviceType;
  hasCTHub: boolean;
  hardware: number;
  bom: string[];
}
export const PreconfigurationKits: IPreconfigurationKit[] = [
  {
    id: 'SMARTC-1P50A-2',
    type: EVBoxKitType.SinglePhase,
    name: 'SMARTC-1P50A-2 (Single Phase)',
    ctHubLabel: 'EV-IAC-1-1P (CT Hub Serial Number)',
    phase: 1,
    gateway: DeviceType.EthernetConnect,
    hasCTHub: true,
    hardware: HardwareCTHub,
    bom: ['2 * SCT02-T10/50A (40-0008)', 'Connector zakje SMARTC-CONX', 'Quick install - SMARTC-1P50A-2 (Single Phase)']
  },
  {
    id: 'SMARTC-3P50A-2',
    type: EVBoxKitType.ThreePhase,
    name: 'SMARTC-3P50A-2 (Three Phase)',
    ctHubLabel: '--unused--',
    gridCTHubLabel: 'EV-IAC-1-3PG (Grid - CT Hub Serial Number)',
    carChargerCTHubLabel: 'EV-IAC-1-3PEV (Car Charger - CT Hub Serial Number)',
    phase: 3,
    gateway: DeviceType.EthernetConnect,
    hasCTHub: true,
    hardware: HardwareCTHubGrid + HardwareCTHubCharger,
    bom: ['6 * SCT02-T10/50A (40-0008)', 'Connector zakje SMARTC-CONX', 'Quick install - SMARTC-3P50A-2 (Three Phase)']
  },
  {
    id: 'SMARTC-3P50A-2-WC',
    type: EVBoxKitType.ThreePhase,
    name: 'SMARTC-3P50A-2-WC (Three Phase)',
    ctHubLabel: '--unused--',
    gridCTHubLabel: 'EV-IAC-1-3PG (Grid - CT Hub Serial Number)',
    carChargerCTHubLabel: 'EV-IAC-1-3PEV (Car Charger - CT Hub Serial Number)',
    phase: 3,
    gateway: DeviceType.WifiConnect,
    hasCTHub: true,
    hardware: HardwareCTHubGrid + HardwareCTHubCharger,
    bom: ['6 * SCT02-T10/50A (40-0008)', 'Connector zakje SMARTC-CONX', 'Quick install - SMARTC-3P50A-2 (Three Phase)']
  },
  {
    id: 'SMARTCPLUS-1P50A-2',
    type: EVBoxKitType.SinglePhaseSolar,
    name: 'SMARTCPLUS-1P50A-2 (Single Phase + Solar)',
    ctHubLabel: 'EV-IAC-1-1PS (CT Hub Serial Number)',
    phase: 1,
    gateway: DeviceType.Genius,
    hasCTHub: true,
    hardware: HardwareCTHub,
    bom: [
      '3 * SCT02-T10/50A (40-0008)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTCPLUS-1P50A-2 (Single Phase + Solar)',
      'RJ10 Splitter cable (30-0042)'
    ]
  },
  {
    id: 'SMARTCPLUS-3P50A-2',
    type: EVBoxKitType.ThreePhaseSolar,
    name: 'SMARTCPLUS-3P50A-2 (Three Phase + Solar)',
    ctHubLabel: 'EV-IAC-1-S3P (CT Hub Serial Number)',
    gridCTHubLabel: 'EV-IAC-1-3PG (Grid - CT Hub Serial Number)',
    carChargerCTHubLabel: 'EV-IAC-1-3PEV (Car Charger - CT Hub Serial Number)',
    phase: 3,
    gateway: DeviceType.Genius,
    hasCTHub: true,
    hardware: HardwareCTHubGrid + HardwareCTHubCharger + HardwareCTHub,
    bom: [
      '9 * SCT02-S-T10/50A (40-0013)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTCPLUS-3P50A-2 (Three Phase + Solar)',
      'RJ10 Splitter cable (30-0042)'
    ]
  },
  {
    id: 'SMARTC-1P100A-2',
    type: EVBoxKitType.SinglePhase,
    name: 'SMARTC-1P100A-2 (Single Phase UK)',
    ctHubLabel: 'EV-IAC-1-1P (CT Hub Serial Number)',
    gridCTType: KitCTType.SCT2_100A,
    phase: 1,
    gateway: DeviceType.EthernetConnect,
    hasCTHub: true,
    hardware: HardwareCTHub,
    bom: [
      'SCT02-T10/50A (40-0008)',
      'SCT02-T10/100A (40-0009)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTC-1P100A-2 (Single Phase UK)'
    ]
  },
  {
    id: 'SMARTC-3P100A-2',
    type: EVBoxKitType.ThreePhase,
    name: 'SMARTC-3P100A-2 (Three Phase)',
    ctHubLabel: '--unused--',
    gridCTHubLabel: 'EV-IAC-1-3PG (Grid - CT Hub Serial Number)',
    gridCTType: KitCTType.SCT2_100A,
    carChargerCTHubLabel: 'EV-IAC-1-3PEV (Car Charger - CT Hub Serial Number)',
    phase: 3,
    gateway: DeviceType.EthernetConnect,
    hasCTHub: true,
    hardware: HardwareCTHubGrid + HardwareCTHubCharger,
    bom: [
      '3 * SCT02-T10/50A (40-0008)',
      '3 * SCT02-T10/100A (40-0009)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTC-3P50A-2 (Three Phase)'
    ]
  },
  {
    id: 'SMARTCPLUS-1P100A-2',
    type: EVBoxKitType.SinglePhaseSolar,
    name: 'SMARTCPLUS-1P100A-2 (Single Phase + Solar)',
    ctHubLabel: 'EV-IAC-1-1PS (CT Hub Serial Number)',
    gridCTType: KitCTType.SCT2_100A,
    phase: 1,
    gateway: DeviceType.Genius,
    hasCTHub: true,
    hardware: HardwareCTHub,
    bom: [
      '3 * SCT02-T10/50A (40-0008)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTCPLUS-1P100A-2 (Single Phase + Solar)',
      'RJ10 Splitter cable (30-0042)'
    ]
  },
  {
    id: 'SMARTCPLUS-3P100A-2',
    type: EVBoxKitType.ThreePhaseSolar,
    name: 'SMARTCPLUS-3P100A-2 (Three Phase + Solar)',
    ctHubLabel: 'EV-IAC-1-S3P (CT Hub Serial Number)',
    gridCTHubLabel: 'EV-IAC-1-3PG (Grid - CT Hub Serial Number)',
    gridCTType: KitCTType.SCT2_100A,
    carChargerCTHubLabel: 'EV-IAC-1-3PEV (Car Charger - CT Hub Serial Number)',
    phase: 3,
    gateway: DeviceType.Genius,
    hasCTHub: true,
    hardware: HardwareCTHubGrid + HardwareCTHubCharger + HardwareCTHub,
    bom: [
      '6 * SCT02-S-T10/50A (40-0013)',
      '3 * SCT02-T10/100A (40-0009)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTCPLUS-3P100A-2 (Three Phase + Solar)',
      'RJ10 Splitter cable (30-0042)'
    ]
  },
  {
    id: 'SMARTC-1P50A',
    type: EVBoxKitType.SinglePhase,
    name: 'SMARTC-1P50A (Single Phase)',
    ctHubLabel: 'EV-IAC-1-1P (CT Hub Serial Number)',
    phase: 1,
    gateway: DeviceType.WifiConnect,
    hasCTHub: true,
    hardware: HardwareCTHub,
    bom: ['2 * SCT02-T10/50A (40-0008)', 'Connector zakje SMARTC-CONX', 'Quick install - SMARTC-1P50A (Single Phase)']
  },
  {
    id: 'SMARTC-1P100A',
    type: EVBoxKitType.SinglePhase,
    name: 'SMARTC-1P100A (Single Phase UK)',
    ctHubLabel: 'EV-IAC-1-1P (CT Hub Serial Number)',
    gridCTType: KitCTType.SCT2_100A,
    phase: 1,
    gateway: DeviceType.WifiConnect,
    hasCTHub: true,
    hardware: HardwareCTHub,
    bom: [
      'SCT02-T10/50A (40-0008)',
      'SCT02-T10/100A (40-0009)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTC-1P100A (Single Phase UK)'
    ]
  },
  {
    id: 'SMARTCPLUS-1P50A',
    type: EVBoxKitType.SinglePhaseSolar,
    name: 'SMARTCPLUS-1P50A (Single Phase + Solar)',
    ctHubLabel: 'EV-IAC-1-1PS (CT Hub Serial Number)',
    phase: 1,
    gateway: DeviceType.Genius,
    hasCTHub: true,
    hardware: HardwareCTHub,
    bom: [
      '3 * SCT02-T10/50A (40-0008)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTCPLUS-1P50A (Single Phase + Solar)',
      'RJ10 Splitter cable (30-0042)'
    ]
  },
  {
    id: 'SMARTC-3P50A',
    type: EVBoxKitType.ThreePhase,
    name: 'SMARTC-3P50A (Three Phase)',
    ctHubLabel: 'CT Hub Serial Number',
    phase: 3,
    gateway: DeviceType.WifiConnect,
    hasCTHub: false,
    hardware: HardwareSolidCoreGrid + HardwareSolidCoreCharger,
    bom: ['Connector zakje SMARTC-CONX', 'Quick install - SMARTC-3P50A (Three Phase)']
  },
  {
    id: 'SMARTCPLUS-3P50A',
    type: EVBoxKitType.ThreePhaseSolar,
    name: 'SMARTCPLUS-3P50A (Three Phase + Solar)',
    ctHubLabel: 'EV-IAC-1-S3P (CT Hub Serial Number)',
    phase: 3,
    gateway: DeviceType.Genius,
    hasCTHub: true,
    hardware: HardwareSolidCoreGrid + HardwareSolidCoreCharger + HardwareCTHub,
    bom: [
      '3 * SCT02-S-T10/50A (40-0013)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTCPLUS-3P50A (Three Phase + Solar)',
      'RJ10 Splitter cable (30-0042)'
    ]
  },
  {
    id: 'SMARTC-NO-1P100A',
    type: EVBoxKitType.ThreePhaseDelta,
    name: 'SMARTC-NO-1P100A (Three Phase Delta)',
    ctHubLabel: 'EV-IAC-1-1P (CT Hub Serial Number)',
    gridCTType: KitCTType.SCT2_100A,
    phase: 2, // delta doesn't count as 3 phase (doesn't use closed CT hubs)
    gateway: DeviceType.WifiConnect,
    hasCTHub: true,
    hardware: HardwareCTHub,
    bom: [
      'SCT02-T10/50A (40-0008)',
      '2 * SCT02-T10/100A (40-0009)',
      'Connector zakje SMARTC-CONX',
      'Quick install - SMARTC-NO-1P100A (Norway Kit)'
    ]
  }
];

const preconfigurationKitsByID = PreconfigurationKits.reduce(
  (result, kit) => {
    result[kit.id] = kit;
    return result;
  },
  {} as {[key: string]: IPreconfigurationKit}
);

export function getPreconfigurationKit(id: string): IPreconfigurationKit {
  return preconfigurationKitsByID[id];
}
