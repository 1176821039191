import React, {useMemo} from 'react';

import Table, {migrateTableSettings} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {Device} from '../../models/Device';
import {useHistoricalDeviceInfo, useDeviceConfigurationHistory} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useCardColumnSettings} from '../CardUtils';
import {CardView, cardViewProps} from '../components/CardView';

import {getTableFields} from './Columns';

type DeviceConfigurationHistorySettings = ICardSettingsWithTable;

const DeviceConfigurationHistory = (props: ICardProps<DeviceConfigurationHistorySettings>) => {
  const {fetch, settings, updateSettings} = props;

  const location = useCardLocation(settings);
  const timezone = location && location.timeZoneId;
  const fields = useMemo(() => getTableFields(timezone), [timezone]);
  const info = useHistoricalDeviceInfo(fetch, location);
  const configurationHistory = useDeviceConfigurationHistory(fetch, info);

  let error: string | undefined;
  if (info === null) {
    error = T('deviceConfigurationLegacy.notInstalled');
  } else if (info && !Device.isLegacy(info.type)) {
    error = T('deviceConfigurationHistory.onlyForLegacy');
  }

  const customSettings = useCardColumnSettings(fields);

  return (
    <CardView customSettings={customSettings} error={error} {...cardViewProps(props)}>
      <Table
        items={configurationHistory}
        fields={fields}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noun="configuration"
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: DeviceConfigurationHistorySettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'createdUtcStamp', visible: true},
      {name: 'autoCommissioningAction', visible: false},
      {name: 'serialNumber', visible: false},
      {name: 'serviceLocationId', visible: false},
      {name: 'is60Hz', visible: true},
      {name: 'isLoad', visible: true},
      {name: 'isDelta', visible: true},
      {name: 'mode', visible: true},
      {name: 'workingMode', visible: true},
      {name: 'phaseMapping', visible: true},
      {name: 'inputsConnected', visible: true},
      {name: 'phaseReverser', visible: true},
      {name: 'singleVoltageShift', visible: true},
      {name: 'sequence', visible: false},
      {name: 'createdBy', visible: true},
      {name: 'status', visible: true},
      {name: 'inputMask', visible: true},
      {name: 'isACValidationRunning', visible: true},
      {name: 'isACInstantRunning', visible: true}
    ]
  }
};
export const CARD: ICardType<DeviceConfigurationHistorySettings> = {
  type: CardTypeKey.DeviceConfigurationHistory,
  title: 'deviceConfigurationHistory.title',
  description: 'deviceConfigurationHistory.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: DeviceConfigurationHistory,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table)
};
export default CARD;
