import {combineReducers} from 'redux';

import {AnyAppAction} from '../actions';
import {IAppState} from '../AppState';

import boards from './boards';
import location from './location';
import locations from './locations';
import me from './me';
import preferences from './preferences';
import uiState from './uiState';

export default function createRootReducer() {
  return combineReducers<IAppState, AnyAppAction>({
    boards,
    location,
    locations,
    me,
    preferences,
    uiState
  });
}
