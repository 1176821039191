import React from 'react';

import {LockLocked} from '../../components/ui-lib/icons/small';
import {ICardSettings} from '../../models/CardSettings';
import {T} from '../../utils/Internationalization';
import {classes} from '../../utils/Styles';
import {testingClasses} from '../../utils/TestingClasses';
import {useCardLocation} from '../CardUtils';

import styles from './Card.module.scss';

interface CardLocationNameViewProps<S extends ICardSettings> {
  settings: S;
}

export default function CardLocationName<S extends ICardSettings>(props: CardLocationNameViewProps<S>) {
  const {settings} = props;
  const location = useCardLocation(settings);

  let lockedTitle = T('card.locked.title');
  if (location && (location.name || location.serialNumber)) {
    lockedTitle = `${location.name || location.serialNumber}`;
  }

  return (
    <>
      {location && (
        <div
          key="locked-title"
          className={classes(styles.lockedLocation, testingClasses.cardLockedLocationTitle)}
          data-testid={testingClasses.cardLockedLocationTitle}
        >
          {lockedTitle}
        </div>
      )}
      {location && (
        <span key="locked-location" title={T('card.locked.title')} className="tw-inline-flex tw-ml-4">
          <LockLocked height={16} width={16} />
        </span>
      )}
    </>
  );
}
