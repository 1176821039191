import {ButtonProps} from 'reactstrap';

import {classes} from '../utils/Styles';

import {Button} from './bootstrap';
import styles from './ButtonWithLoading.module.scss';
import {Icons} from './Icon';

interface ButtonWithLoadingProps extends ButtonProps {
  loading: boolean;
}

export function ButtonWithLoading(props: ButtonWithLoadingProps) {
  const {loading, children, className, ...otherProps} = props;
  return (
    <Button className={classes(className, styles.button)} {...otherProps}>
      {children}
      {loading && <>&nbsp;{Icons.Loading}</>}
    </Button>
  );
}
