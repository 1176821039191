import {HarmonicsInterval, HarmonicsValue} from '../../models/Harmonics';
import {UsageUnit} from '../../models/UsageValue';

export interface HarmonicsStatistics {
  harmonicsPhaseACurrent: (HarmonicsStatisticsEntry | undefined)[];
  harmonicsPhaseBCurrent: (HarmonicsStatisticsEntry | undefined)[];
  harmonicsPhaseCCurrent: (HarmonicsStatisticsEntry | undefined)[];
  harmonicsPhaseAVoltage: (HarmonicsStatisticsEntry | undefined)[];
  harmonicsPhaseBVoltage: (HarmonicsStatisticsEntry | undefined)[];
  harmonicsPhaseCVoltage: (HarmonicsStatisticsEntry | undefined)[];
}

export interface HarmonicsStatisticsEntry {
  min: number;
  percentile5: number;
  median: number;
  percentile95: number;
  max: number;
}

function addCurrentsToRearranged(rearranged: number[][], data: HarmonicsValue[]) {
  data.forEach(current => {
    const {index, value} = current;
    if (value === undefined) return;
    while (index >= rearranged.length) rearranged.push([]);

    rearranged[index].push(value);
  });
}

function addVoltagesToRearranged(rearranged: number[][], data: HarmonicsValue[], voltage: number) {
  data.forEach(current => {
    const {index, value} = current;
    if (value === undefined) return;
    while (index >= rearranged.length) rearranged.push([]);

    rearranged[index].push(index === 0 ? value : (value * 100) / voltage);
  });
}

function calculateStatisticsEntry(data: number[]): HarmonicsStatisticsEntry | undefined {
  if (data.length === 0) return undefined;

  data.sort((a, b) => a - b);
  return {
    min: data[0],
    percentile5: data[(data.length / 20) | 0],
    median: data[(data.length / 2) | 0],
    percentile95: data[((data.length * 19) / 20) | 0],
    max: data[data.length - 1]
  };
}

export function calculateStatistics(intervals: HarmonicsInterval[], loadId: number): HarmonicsStatistics {
  const rearrangedPhaseACurrent: number[][] = [];
  const rearrangedPhaseAVoltage: number[][] = [];
  const rearrangedPhaseBCurrent: number[][] = [];
  const rearrangedPhaseBVoltage: number[][] = [];
  const rearrangedPhaseCCurrent: number[][] = [];
  const rearrangedPhaseCVoltage: number[][] = [];

  intervals.forEach(interval => {
    const load = interval.loads.find(load => load.id === loadId);
    if (!load) return;

    if (load.phaseA) {
      addCurrentsToRearranged(rearrangedPhaseACurrent, load.phaseA.current || []);
      const phaseVoltage = (load.phaseA.phaseVoltage || []).find(x => x.index === 0);
      if (phaseVoltage && phaseVoltage.value !== undefined) {
        addVoltagesToRearranged(rearrangedPhaseAVoltage, load.phaseA.voltage || [], phaseVoltage.value);
      }
    }
    if (load.phaseB) {
      addCurrentsToRearranged(rearrangedPhaseBCurrent, load.phaseB.current || []);
      const phaseVoltage = (load.phaseB.phaseVoltage || []).find(x => x.index === 1);
      if (phaseVoltage && phaseVoltage.value !== undefined) {
        addVoltagesToRearranged(rearrangedPhaseBVoltage, load.phaseB.voltage || [], phaseVoltage.value);
      }
    }
    if (load.phaseC) {
      addCurrentsToRearranged(rearrangedPhaseCCurrent, load.phaseC.current || []);
      const phaseVoltage = (load.phaseC.phaseVoltage || []).find(x => x.index === 2);
      if (phaseVoltage && phaseVoltage.value !== undefined) {
        addVoltagesToRearranged(rearrangedPhaseCVoltage, load.phaseC.voltage || [], phaseVoltage.value);
      }
    }
  });

  return {
    harmonicsPhaseACurrent: rearrangedPhaseACurrent.map(x => calculateStatisticsEntry(x)),
    harmonicsPhaseAVoltage: rearrangedPhaseAVoltage.map(x => calculateStatisticsEntry(x)),
    harmonicsPhaseBCurrent: rearrangedPhaseBCurrent.map(x => calculateStatisticsEntry(x)),
    harmonicsPhaseBVoltage: rearrangedPhaseBVoltage.map(x => calculateStatisticsEntry(x)),
    harmonicsPhaseCCurrent: rearrangedPhaseCCurrent.map(x => calculateStatisticsEntry(x)),
    harmonicsPhaseCVoltage: rearrangedPhaseCVoltage.map(x => calculateStatisticsEntry(x))
  };
}

function addCurrentInterval(
  timestamp: number,
  values: HarmonicsValue[] | undefined,
  result: [number, number][][],
  seriesIndices: (number | undefined)[]
) {
  (values || []).forEach(item => {
    const index = seriesIndices[item.index];
    if (index === undefined || item.value === undefined) return;

    result[index].push([timestamp, item.value]);
  });
}

function addVoltageInterval(
  timestamp: number,
  voltage: number,
  values: HarmonicsValue[] | undefined,
  result: [number, number][][],
  seriesIndices: (number | undefined)[]
) {
  (values || []).forEach(item => {
    const index = seriesIndices[item.index];
    if (index === undefined || item.value === undefined) return;

    result[index].push([timestamp, item.unit === UsageUnit.Volt ? (item.value / voltage) * 100 : item.value]);
  });
}

export interface HarmonicsHistoryLoadData {
  load: number;
  seriesIndices: (number | undefined)[];
  currentsForL1: [number, number][][];
  currentsForL2: [number, number][][];
  currentsForL3: [number, number][][];
  voltagesForL1: [number, number][][];
  voltagesForL2: [number, number][][];
  voltagesForL3: [number, number][][];
}
export function extractLoad(intervals: HarmonicsInterval[], loadId: number, harmonics: number[]) {
  const harmonicsWithTHD = [0, ...harmonics];
  const currentsForL1: [number, number][][] = harmonicsWithTHD.map(() => []);
  const currentsForL2: [number, number][][] = harmonicsWithTHD.map(() => []);
  const currentsForL3: [number, number][][] = harmonicsWithTHD.map(() => []);
  const voltagesForL1: [number, number][][] = harmonicsWithTHD.map(() => []);
  const voltagesForL2: [number, number][][] = harmonicsWithTHD.map(() => []);
  const voltagesForL3: [number, number][][] = harmonicsWithTHD.map(() => []);
  const maxIndex = harmonics.reduce((result, harmonic) => Math.max(result, harmonic), 0);
  const seriesIndices: (number | undefined)[] = [];
  for (let i = 0; i <= maxIndex; i++) seriesIndices.push(undefined);
  harmonicsWithTHD.forEach((harmonic, index) => (seriesIndices[harmonic] = index));

  intervals.forEach(interval => {
    const timestamp = interval.timestamp;
    const load = interval.loads.find(load => load.id === loadId);
    if (!load) return;

    if (load.phaseA) {
      addCurrentInterval(timestamp, load.phaseA.current, currentsForL1, seriesIndices);
      const phaseVoltage = (load.phaseA.phaseVoltage || []).find(x => x.index === 0);
      if (phaseVoltage && phaseVoltage.value !== undefined) {
        addVoltageInterval(timestamp, phaseVoltage.value, load.phaseA.voltage, voltagesForL1, seriesIndices);
      }
    }
    if (load.phaseB) {
      addCurrentInterval(timestamp, load.phaseB.current, currentsForL2, seriesIndices);
      const phaseVoltage = (load.phaseB.phaseVoltage || []).find(x => x.index === 1);
      if (phaseVoltage && phaseVoltage.value !== undefined) {
        addVoltageInterval(timestamp, phaseVoltage.value, load.phaseB.voltage, voltagesForL2, seriesIndices);
      }
    }
    if (load.phaseC) {
      addCurrentInterval(timestamp, load.phaseC.current, currentsForL3, seriesIndices);
      const phaseVoltage = (load.phaseC.phaseVoltage || []).find(x => x.index === 2);
      if (phaseVoltage && phaseVoltage.value !== undefined) {
        addVoltageInterval(timestamp, phaseVoltage.value, load.phaseC.voltage, voltagesForL3, seriesIndices);
      }
    }
  });

  return {
    load: loadId,
    seriesIndices,
    currentsForL1,
    currentsForL2,
    currentsForL3,
    voltagesForL1,
    voltagesForL2,
    voltagesForL3
  };
}
