import React, {useState, useCallback} from 'react';

import {SelectInput} from '../../components/inputs/SelectInput';
import ValueEditor from '../LocationConfiguration/fields/ValueEditor';

const labels: {[key: string]: string} = {
  '012': 'L1-L2-L3',
  '021': 'L1-L3-L2',
  '102': 'L2-L1-L3',
  '120': 'L2-L3-L1',
  '201': 'L3-L1-L2',
  '210': 'L3-L2-L1'
};

interface PhaseSelectorProps {
  value: string;
  update: (value: string) => Promise<boolean>;
  readOnly: boolean;
}
export default (props: PhaseSelectorProps) => {
  const {value, update, readOnly} = props;

  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const handleClickedSave = useCallback(async () => {
    setLoading(true);
    const success = await update(inputValue);
    setLoading(false);

    if (success) setEditMode(false);
  }, [update, inputValue]);

  return (
    <ValueEditor
      editing={editMode}
      setEditing={setEditMode}
      readOnly={readOnly}
      loading={loading}
      onClickedSave={handleClickedSave}
    >
      {editMode ? (
        <SelectInput value={inputValue} onChange={setInputValue} disabled={readOnly} style={{width: 150}}>
          <option value="012">L1-L2-L3</option>
          <option value="021">L1-L3-L2</option>
          <option value="102">L2-L1-L3</option>
          <option value="120">L2-L3-L1</option>
          <option value="201">L3-L1-L2</option>
          <option value="210">L3-L2-L1</option>
        </SelectInput>
      ) : (
        labels[value]
      )}
    </ValueEditor>
  );
};
