import {Icon} from '../components/Icon';
import {
  Award,
  BarChart,
  Bell,
  Bookmark,
  Car,
  Charging,
  Cube,
  AreaChart,
  Gas,
  LineChart,
  Location,
  SmappeeLogoMark,
  SolarPanel,
  Sun,
  Tachometer,
  Threshold,
  Water,
  PieChart,
  Usage
} from '../components/ui-lib/icons/medium';
import {TranslationKey} from '../utils/TranslationTerms';

import {SVGComponent} from './SvgComponent';

export const enum BoardIconKey {
  ChartArea = 'chart-area',
  Award = 'award',
  ChartBar = 'chart-bar',
  Bell = 'bell',
  Bookmark = 'bookmark',
  Car = 'car',
  Cube = 'cube',
  Electricity = 'electricity',
  Gas = 'gas',
  ChartLine = 'chart-line',
  Location = 'location',
  ChartPie = 'chart-pie',
  Smappee = 'smappee',
  SolarPanels = 'solarpanels',
  Sun = 'sun',
  Tachometer = 'tachometer',
  Threshold = 'threshold',
  Usage = 'usage',
  Water = 'water'
}

export const BOARD_ICONS = new Map<BoardIconKey, {nameKey: TranslationKey; icon: Icon}>([
  [BoardIconKey.ChartArea, {nameKey: 'icon.areaChart', icon: Icon.ChartArea}],
  [BoardIconKey.Award, {nameKey: 'icon.award', icon: Icon.Award}],
  [BoardIconKey.ChartBar, {nameKey: 'icon.barChart', icon: Icon.ChartBar}],
  [BoardIconKey.Bell, {nameKey: 'icon.bell', icon: Icon.Bell}],
  [BoardIconKey.Bookmark, {nameKey: 'icon.bookmark', icon: Icon.Bookmark}],
  [BoardIconKey.Car, {nameKey: 'icon.car', icon: Icon.Car}],
  [BoardIconKey.Cube, {nameKey: 'icon.cube', icon: Icon.Cube}],
  [BoardIconKey.Electricity, {nameKey: 'icon.electricity', icon: Icon.Electricity}],
  [BoardIconKey.Gas, {nameKey: 'icon.gas', icon: Icon.Gas}],
  [BoardIconKey.ChartLine, {nameKey: 'icon.lineChart', icon: Icon.ChartLine}],
  [BoardIconKey.Location, {nameKey: 'icon.mapMarker', icon: Icon.Location}],
  [BoardIconKey.ChartPie, {nameKey: 'icon.pieChart', icon: Icon.ChartPie}],
  [BoardIconKey.Smappee, {nameKey: 'icon.smappee', icon: Icon.Smappee}],
  [BoardIconKey.SolarPanels, {nameKey: 'icon.solarPanels', icon: Icon.SolarPanels}],
  [BoardIconKey.Sun, {nameKey: 'icon.sun', icon: Icon.Sun}],
  [BoardIconKey.Tachometer, {nameKey: 'icon.tachometer', icon: Icon.Tachometer}],
  [BoardIconKey.Threshold, {nameKey: 'icon.threshold', icon: Icon.Threshold}],
  [BoardIconKey.Usage, {nameKey: 'icon.usage', icon: Icon.Usage}],
  [BoardIconKey.Water, {nameKey: 'icon.water', icon: Icon.Water}]
]);

export const BOARD_SVG_ICONS = new Map<BoardIconKey, {nameKey: TranslationKey; icon: SVGComponent}>([
  [BoardIconKey.ChartArea, {nameKey: 'icon.areaChart', icon: AreaChart}], // New
  [BoardIconKey.Award, {nameKey: 'icon.award', icon: Award}], // New
  [BoardIconKey.ChartBar, {nameKey: 'icon.barChart', icon: BarChart}], // New
  [BoardIconKey.Bell, {nameKey: 'icon.bell', icon: Bell}], // New
  [BoardIconKey.Bookmark, {nameKey: 'icon.bookmark', icon: Bookmark}], // New
  [BoardIconKey.Car, {nameKey: 'icon.car', icon: Car}], // New
  [BoardIconKey.Cube, {nameKey: 'icon.cube', icon: Cube}], // New
  [BoardIconKey.Electricity, {nameKey: 'icon.electricity', icon: Charging}], // New
  [BoardIconKey.Gas, {nameKey: 'icon.gas', icon: Gas}], // New
  [BoardIconKey.ChartLine, {nameKey: 'icon.lineChart', icon: LineChart}], // New
  [BoardIconKey.Location, {nameKey: 'icon.mapMarker', icon: Location}], // New
  [BoardIconKey.ChartPie, {nameKey: 'icon.pieChart', icon: PieChart}],
  [BoardIconKey.Smappee, {nameKey: 'icon.smappee', icon: SmappeeLogoMark}], // New
  [BoardIconKey.SolarPanels, {nameKey: 'icon.solarPanels', icon: SolarPanel}], // New
  [BoardIconKey.Sun, {nameKey: 'icon.sun', icon: Sun}], // New
  [BoardIconKey.Tachometer, {nameKey: 'icon.tachometer', icon: Tachometer}], // New
  [BoardIconKey.Threshold, {nameKey: 'icon.threshold', icon: Threshold}], // New
  [BoardIconKey.Usage, {nameKey: 'icon.usage', icon: Usage}], // New
  [BoardIconKey.Water, {nameKey: 'icon.water', icon: Water}] // New
]);

export const BOARD_DEFAULT_ICON = BoardIconKey.Cube;
export const BOARD_DEFAULT_SVG_ICON = BoardIconKey.Cube;
