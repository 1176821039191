import React, {useCallback, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {APISpecificError} from '../../api/APIClient';
import {useAppContext} from '../../app/context';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import Table, {migrateTableSettings} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Link} from '../../components/ui-lib/icons/small';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights, AuthUser} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {InvitationType, LocationInvitation} from '../../models/Invitation';
import {ILocationUser} from '../../models/Location';
import {ITableField, StringField, ComponentField, FieldAlignment, CalculatedStringField} from '../../models/Table';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {None} from '../../utils/Arrays';
import {useCardLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {validateSpecialChars} from '../../utils/Validation';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useUser, useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {cardViewProps, CardView, CustomActions} from '../components/CardView';

import {AddUserModal} from './AddUserModal';
import {getShareEntries, SharingEntry} from './model';

interface IUserActionsProps {
  me: AuthUser;
  entry: SharingEntry;
  onRemove: (entry: SharingEntry) => void;
}
function UserActions(props: IUserActionsProps) {
  const {me, entry, onRemove} = props;
  const canManageUsers = !me.isReadOnly();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {canManageUsers && (
        <RowActionButton
          action="delete"
          title={T('sharedUsers.remove.tooltip')}
          onClick={() => onRemove(entry)}
          className={testingClasses.remove}
          data-testid={testingClasses.remove}
        />
      )}
    </>
  );
}

function renderActionColumn(me: AuthUser, onClickedRemove: (entry: SharingEntry) => void, entry: SharingEntry) {
  return <UserActions me={me} entry={entry} onRemove={onClickedRemove} />;
}

function getTableColumns(me: AuthUser, onClickedRemove: (entry: SharingEntry) => void): ITableField<SharingEntry>[] {
  const result: ITableField<SharingEntry>[] = [];

  if (!hasFeature(AppFeature.HideUsernames)) {
    result.push(new StringField('userName', T('sharedUsers.field.username')));
  }

  result.push(new StringField('firstName', T('sharedUsers.field.firstName')));
  result.push(new StringField('lastName', T('sharedUsers.field.lastName')));

  if (me.isServiceDesk()) {
    result.push(new StringField('emailAddress', T('sharedUsers.field.email')));
  }

  result.push(
    new CalculatedStringField('role', T('users.field.role'), entry => {
      return entry.type === InvitationType.ShareReadWrite
        ? T('sharedUsers.field.fullAccess')
        : T('sharedUsers.field.readOnlyAccess');
    })
  );

  result.push(
    new CalculatedStringField('status', T('invitation.field.status'), entry => {
      switch (entry.status) {
        case 'SHARED':
          return T('invitation.status.shared');
        case 'INVITED':
          return T('invitation.status.invited');
        case 'EXPIRED':
          return T('invitation.status.expired');
      }
    })
  );

  result.push(
    new ComponentField(
      'actions',
      T('sharedUsers.field.actions'),
      user => renderActionColumn(me, onClickedRemove, user),
      {autoInsert: 'end', align: FieldAlignment.Center}
    )
  );
  return result;
}

const rowKey = (item: SharingEntry) => item.id;

type ISharedUsersSettings = ICardSettingsWithTable;

function SharedUsers(props: ICardProps<ISharedUsersSettings>) {
  const {settings, updateSettings, loading} = props;
  const {api} = useAppContext();
  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const modals = useModals();
  const me = useUser();

  const [entries, refresh] = useCardLoader(
    api => {
      if (!locationId) return Promise.resolve(None);

      const users = api.locations.getUsers(locationId);
      const invitations = api.locations.getInvitations(locationId);
      return Promise.all([users, invitations]).then(([users, invitations]) => getShareEntries(users, invitations));
    },
    [locationId],
    'invitation',
    None
  );

  const handleClickedAddUser = () => {
    if (!locationId) return;

    modals
      .show(props => <AddUserModal api={api} locationId={locationId} {...props} />)
      .then(success => {
        if (success) refresh();
      });
  };

  const canManageUsers = !me.isReadOnly();
  const fields = useMemo(() => {
    const handleClickedRemove = (entry: SharingEntry) => {
      if (entry.sharedUser) {
        const user = entry.sharedUser;
        const name = user.firstName;

        modals
          .show(props => (
            <ConfirmationPromiseModal
              title={T('sharedUsers.remove.confirmTitle', {name})}
              message={T('sharedUsers.remove.confirmMessage', {name})}
              acceptLabel={T('sharedUsers.remove.confirm')}
              rejectLabel={T('sharedUsers.remove.cancel')}
              {...props}
            />
          ))
          .then(status => {
            if (status === ConfirmationResult.Accept) removeUser(user);
          });
      } else if (entry.invitation) {
        const invitation = entry.invitation;
        const name = invitation.invitee.emailAddress;

        modals
          .show(props => (
            <ConfirmationPromiseModal
              title={T('sharedUsers.remove.confirmTitle', {name})}
              message={T('sharedUsers.remove.confirmMessage', {name})}
              acceptLabel={T('sharedUsers.remove.confirm')}
              rejectLabel={T('sharedUsers.remove.cancel')}
              {...props}
            />
          ))
          .then(status => {
            if (status === ConfirmationResult.Accept) removeInvitation(invitation);
          });
      }
    };

    const removeUser = async (user: ILocationUser) => {
      if (!locationId || !user.emailAddress) return;

      try {
        let message;
        await api.locations.removeUserById(locationId, user.id);
        message = T('sharedUsers.removed', {name: user.firstName});

        refresh();
        NotificationManager.success(message);
      } catch (err: unknown) {
        const error = err as APISpecificError;
        let message: string;
        if (error && error.code === 'no.owner.remains') {
          message = T(error?.code);
        } else {
          message = T('sharedUsers.error.couldNotRemove', {
            name: user.firstName
          });
        }
        NotificationManager.error(message);
      }
    };

    const removeInvitation = (invitation: LocationInvitation) => {
      api.locations
        .removeInvitation(locationId!, invitation.id)
        .then(() => {
          NotificationManager.success(T('sharedUsers.removed', {name: invitation.invitee.emailAddress}));
          refresh();
        })
        .catch(() =>
          NotificationManager.error(T('sharedUsers.error.couldNotRemove', {name: invitation.invitee.emailAddress}))
        );
    };

    return getTableColumns(me, handleClickedRemove);
  }, [modals, me, locationId, refresh, api]);

  const error = location && (location.parentId === undefined ? undefined : T('sharedUsers.error.notForChildLocations'));
  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      {state.ready && <ExportCsv name={state.title} fields={fields} items={entries} settings={settings.table} />}
      {/* <Spring /> */}
      {canManageUsers && state.ready && (
        <Button
          variant="secondary_default"
          title={T('sharedUsers.share')}
          size="lg"
          onClick={handleClickedAddUser}
          className={testingClasses.addButton}
          data-testid={testingClasses.addButton}
        >
          <span className="tw-mr-2">
            <Link />
          </span>
          {T('sharedUsers.share')}
        </Button>
      )}
    </CardActions>
  );

  return (
    <CardView error={error} actions={actions} {...cardViewProps(props)}>
      {!loading && (
        <Table
          fields={fields}
          items={entries}
          rowKey={rowKey}
          noun="user"
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
        />
      )}
    </CardView>
  );
}

const DEFAULT_SETTINGS: ISharedUsersSettings = {
  table: {
    pageSize: 10,
    columns: [
      ...(hasFeature(AppFeature.HideUsernames) ? [] : [{name: 'userName', visible: true}]),
      {name: 'firstName', visible: true},
      {name: 'lastName', visible: true},
      {name: 'emailAddress', visible: true},
      {name: 'role', visible: true},
      {name: 'status', visible: true}
    ]
  }
};
const CARD: ICardType<ISharedUsersSettings> = {
  type: CardTypeKey.SharedUsers,
  title: 'sharedUsers.title',
  description: 'sharedUsers.description',
  categories: [CardCategory.USERS],
  rights: UserRights.User,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: SharedUsers
};
export default CARD;
