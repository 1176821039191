import {SensorChannelType} from './Location';
import {UsageUnit, Interval} from './UsageValue';

export const enum SensorReadingType {
  Electricity = 'ELECTRICITY',
  AlwaysOn = 'ALWAYS_ON',
  Solar = 'SOLAR',
  GridImport = 'GRID_IMPORT',
  SolarExport = 'SOLAR_EXPORT',
  SelfSufficiency = 'SELF_SUFFICIENCY',
  SelfConsumption = 'SELF_CONSUMPTION',
  Gas = 'GAS',
  Water = 'WATER',
  Channels = 'CHANNELS',
  InputModuleCustom = 'INPUT_MODULE_CUSTOM'
}

export interface ISensorReading {
  timestamp: number;
  value?: number | null;
  unit?: UsageUnit;
}

export interface ISensorReadingTotals {
  value: number;
  unit: UsageUnit;
  cost: number;
  currency: string;
}

export interface ISensorReadingList {
  type: SensorReadingType;
  intervals: ISensorReading[];
  total?: ISensorReadingTotals;
}

export interface ISensorReadingsMulti {
  usages: ISensorReadingList[];
  intervalLength: Interval;
  showItemizedBill: boolean;
  showEvents: boolean;
}

export function getReadingType(type: SensorChannelType): SensorReadingType | undefined {
  switch (type) {
    case SensorChannelType.Gas:
      return SensorReadingType.Gas;
    case SensorChannelType.Water:
      return SensorReadingType.Water;
    case SensorChannelType.Electricity:
      return SensorReadingType.Electricity;
    case SensorChannelType.Custom:
      return SensorReadingType.InputModuleCustom;
  }
  return undefined;
}
