import {NotificationModal} from '../../components/bootstrap/NotificationModal';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ContractDetails} from '../../models/Contract';
import {T} from '../../utils/Internationalization';

interface NotesModalProps extends IPromiseModalProps<void> {
  contract: ContractDetails;
}

export function NotesModal(props: NotesModalProps) {
  const {contract} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  return (
    <NotificationModal title={T('contracts.notes.title')} isOpen={isOpen} onToggle={() => resolve()}>
      {contract.notes}
    </NotificationModal>
  );
}
