import React, {useMemo} from 'react';

import {ITableField, CalculatedStringField, ComponentField} from '../../models/Table';
import {T} from '../../utils/Internationalization';

import Table, {IPersistedTableSettings} from '.';

export interface PropertyEntry<S> {
  key: string;
  label: string;
  value: (state: S) => string | JSX.Element | undefined;
  available?: (state: S) => boolean;
  tab?: string;
}

function getTableColumns<S>(state: S): ITableField<PropertyEntry<S>>[] {
  return [
    new CalculatedStringField('key', T('locationConfiguration.column.key'), entry => entry.label, {width: '50%'}),
    new ComponentField('value', T('locationConfiguration.column.value'), item => item.value(state), {
      width: '50%',
      overflow: true
    })
  ];
}

const rowKey = (item: PropertyEntry<any>) => item.key;

interface SettingsTableProps<S> {
  filter: string;
  settings: IPersistedTableSettings;
  state: S;
  updateSettings: (settings: IPersistedTableSettings) => void;
  properties: PropertyEntry<S>[];
}
export const SettingsTable = <S,>(props: SettingsTableProps<S>) => {
  const {filter, settings, state, updateSettings, properties} = props;
  const columns = useMemo(() => getTableColumns(state), [state]);

  const filterLower = filter.toLocaleLowerCase();
  const filtered = properties.filter(
    p => p.label.toLocaleLowerCase().includes(filterLower) && (p.available === undefined || p.available(state))
  );

  return (
    <Table
      fields={columns}
      items={filtered}
      rowKey={rowKey}
      noun="property"
      settings={settings}
      updateSettings={updateSettings}
      noDefaultSort={true}
    />
  );
};
