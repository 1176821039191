import type {SVGProps} from 'react';
const SvgBin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M5.854.667h4.292a.668.668 0 0 1 .646.505l.207.829h4.333v1.333h-1.333v10a1.999 1.999 0 0 1-2 2H4a2 2 0 0 1-2-2V3.333H.667V2H5l.207-.829a.666.666 0 0 1 .647-.505Zm-.521 12v-8h1.333v8H5.333Zm4-8v8h1.333v-8H9.333Z" />
  </svg>
);
export default SvgBin;
