import {
  IAssignActivationCodesResponse,
  AssignActivationCodeGeneralFailure,
  AssignActivationCodeDeviceFailure
} from '../../models/ActivationCode';

function getFailure(failure: {serialNumber: string; reason: AssignActivationCodeDeviceFailure}) {
  const {serialNumber, reason} = failure;
  if (reason === AssignActivationCodeDeviceFailure.DoesNotExist) {
    return `${serialNumber} does not exist`;
  } else if (reason === AssignActivationCodeDeviceFailure.AlreadyAssigned) {
    return `${serialNumber} is already assigned`;
  } else {
    return `${serialNumber} could not be assigned`;
  }
}

export function getErrorForAssignmentResponse(result: IAssignActivationCodesResponse | undefined) {
  if (result === undefined) return undefined;

  if (result.deviceFailures !== undefined) {
    return result.deviceFailures.map(failure => getFailure(failure)).join(', ');
  } else if (result.generalFailure !== undefined) {
    switch (result.generalFailure) {
      case AssignActivationCodeGeneralFailure.ActivationCodeNotFound:
        return 'Activation code not found';
      case AssignActivationCodeGeneralFailure.ActivationCodeNotReusable:
        return 'Activation code not reusable';
      case AssignActivationCodeGeneralFailure.SQLError:
        return 'Internal server error (sql error)';
    }
  } else {
    return undefined;
  }
}
