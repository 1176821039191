import React, {useMemo} from 'react';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../components/ui/select';
import {IDevice} from '../../../models/Device';
import {T} from '../../../utils/Internationalization';

interface CTHubSelectorProps {
  readOnly: boolean;
  value: string;
  onChange: (sensor: string) => void;
  ctHubs: IDevice[];
}
export const CTHubSelector = (props: CTHubSelectorProps) => {
  const {readOnly, value, onChange, ctHubs} = props;
  const selectedCtHubOptions = useMemo(
    () =>
      ctHubs.map(ctHub => (
        <SelectItem key={ctHub.serialNumber} value={ctHub.serialNumber}>
          {ctHub.serialNumber}
        </SelectItem>
      )),
    [ctHubs]
  );
  const selected = ctHubs.find(hub => hub.serialNumber === value);

  const handleSelectedCtHub = (ctHub: string) => {
    onChange(ctHub);
  };

  return (
    <Select name="cthub" value={value} onValueChange={ctHub => handleSelectedCtHub(ctHub)} disabled={readOnly}>
      <SelectTrigger className="tw-pl-1 !tw-text-base">
        <SelectValue placeholder={T('ctConfiguration.chooseCTHub')} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {value && selected === undefined && (
            <SelectItem value={value}>
              {value} {T('ctConfiguration.chooseCTHub.removed')}
            </SelectItem>
          )}
          {selectedCtHubOptions}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
