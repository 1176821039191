import React from 'react';

import {Badge as UiBadge} from '../components/ui/badge';

import {useLiveValues} from '../livedata/LiveData';
import {ILocationSummary} from '../models/Location';
import {OnlineStatus} from '../models/OnlineStatus';
import {T} from '../utils/Internationalization';

import {Icons} from './Icon';

interface OnlineStatusIndicatorProps {
  status: OnlineStatus;
}

function getStatusColor(status: OnlineStatus) {
  switch (status) {
    case OnlineStatus.Online:
      return 'success';
    case OnlineStatus.Offline:
      return 'danger';
    default:
      return 'secondary';
  }
}

function getStatusMessage(status: OnlineStatus) {
  switch (status) {
    case OnlineStatus.Loading:
      return undefined;
    case OnlineStatus.Online:
      return T('onlineStatus.online');
    case OnlineStatus.Offline:
      return T('onlineStatus.offline');
    case OnlineStatus.Unavailable:
      return T('onlineStatus.unavailable');
    default:
      return undefined;
  }
}

export function OnlineStatusIndicator(props: OnlineStatusIndicatorProps) {
  const {status} = props;
  const color = getStatusColor(status);
  const label = getStatusMessage(status);

  return (
    <UiBadge variant={color} className="tw-text-sm tw-rounded-full">
      {label || (
        <>
          {Icons.Loading} {T('card.loadingLive')}
        </>
      )}
    </UiBadge>
  );
}

interface LocationOnlineStatusIndicatorProps {
  location: ILocationSummary;
}

export function LocationOnlineStatusIndicator(props: LocationOnlineStatusIndicatorProps) {
  const {location} = props;
  const [, status] = useLiveValues(location);
  return <OnlineStatusIndicator status={status} />;
}
