import React, {useContext} from 'react';

import {ICard} from '../models/Board';

import {ICardSettings} from '../models/CardSettings';
import {Fetcher} from '../utils/Fetcher';
import {RepeatableAbortController} from '../utils/RepeatableAbortController';

import {ICardType} from './CardType';

export interface ICardContext<S extends ICardSettings> {
  card: ICard<S>;
  cardType: ICardType<S>;
  cardDimensions: string;
  fetch: Fetcher;
  abortFetching: RepeatableAbortController;
  updateSettings: (updates: Partial<S>) => void;
  onLoadStarted: (id: string, abort: RepeatableAbortController, name: string) => void;
  onLoadFinished: (id: string) => void;
  onLoadErrored: (id: string) => void;
  onLoadCanceled: (id: string) => void;
}

const context = React.createContext<ICardContext<any> | undefined>(undefined);

export function useCardContext<S extends ICardSettings = ICardSettings>() {
  const result = useContext(context) as ICardContext<S>;
  if (result === undefined) throw Error('Not in a card');
  return result;
}

export const CardContextProvider = context.Provider;
