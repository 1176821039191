import {Fragment} from 'react';

interface HandleProps {
  domain: [number, number];
  handle: {
    id: string;
    value: number;
    percent: number;
  };
  tooltip: string;
  getHandleProps: (id: string) => React.HTMLProps<HTMLDivElement>;
}

export const Handle: React.FC<HandleProps> = ({
  domain: [min, max],
  handle: {id, value, percent},
  tooltip,
  getHandleProps
}) => (
  <Fragment>
    <div
      style={{
        top: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        zIndex: 5,
        width: 42,
        height: 28,
        cursor: 'pointer',
        backgroundColor: 'none'
      }}
      {...getHandleProps(id)}
    >
      {/* Tooltip */}
      <div
        style={{
          position: 'absolute',
          left: '170px', // Positioning the tooltip to the right of the handle
          top: '16px',
          transform: 'translateY(-50%)',
          whiteSpace: 'nowrap',
          backgroundColor: '#000',
          color: '#fff',
          padding: '4px 8px',
          borderRadius: '4px',
          fontSize: '12px'
        }}
      >
        {tooltip}: {value}%
      </div>
    </div>
    <div
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        top: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
        width: 24,
        height: 24,
        borderRadius: '50%',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#ffffff' // #ffc400
      }}
    />
  </Fragment>
);
