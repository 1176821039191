import React, {useContext} from 'react';

import {IPromiseModalTarget, IPromiseModalProps} from './PromiseModal';

function dummyShow<T>(render: (props: IPromiseModalProps<T>) => JSX.Element): Promise<T> {
  return Promise.reject();
}

const context = React.createContext<IPromiseModalTarget>({show: dummyShow});

export const ModalContextProvider = context.Provider;

export function useModals(): IPromiseModalTarget {
  return useContext(context);
}
