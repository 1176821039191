import React, {useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {usePasswordInput} from '../../components/inputs/PasswordInputGroup';
import {usePromiseModal, IPromiseModalProps} from '../../modals/PromiseModal';
import {User} from '../../models/User';
import {T} from '../../utils/Internationalization';
import {validateRequired} from '../../utils/Validation';

interface ResetPasswordProps extends IPromiseModalProps<void> {
  user: User;
}

export const ResetPassword = (props: ResetPasswordProps) => {
  const {user} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleToggle = () => resolve();

  const {api} = useAppContext();
  const [passwordInput, password] = usePasswordInput(
    'password',
    T('users.newPassword'),
    '',
    validateRequired,
    undefined,
    {
      autoFocus: true
    }
  );
  const [resetError, setResetError] = useState<string>();

  const handleClickedReset = async () => {
    const {id} = user;
    if (!id) return;

    try {
      await api.resetUserPassword(id, password);
      NotificationManager.success(T('users.updatedPassword'));
      resolve();
    } catch {
      setResetError(T('users.password.couldNotBeReset'));
    }
  };

  const label = user.userName || user.email || T('users.unknownUser');

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleToggle}
      title={T('resetPassword.title')}
      cancelText={T('card.cancel')}
      action={handleClickedReset}
      actionText={T('resetPassword.title')}
      error={resetError}
    >
      {T(`users.resetPasswordFor`, {label: `${label}`})} {passwordInput}
    </SingleActionModal>
  );
};
