import {getCookieSettings} from '../components/CookieNagger';

const APP = 'G-0DQX73WMSW';

const pendingQueue: any[] = [];

let marketingEnabled = getCookieSettings().allowMarketing;
if (marketingEnabled) {
  onMarketingCookiesEnabled();
}

function gtag(type: 'js' | 'config' | 'get' | 'set' | 'event', ...p: any[]) {
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push(arguments);
  } else {
    pendingQueue.push(arguments);
  }
}

export function registerAnalyticsEvent(event: string, parameters: object) {
  gtag('event', event, parameters);
}

export function setGAField(name: string, value: string) {
  gtag('config', 'GA_MEASUREMENT_ID', {
    [name]: value
  });
}

export function getClientId(): Promise<string> {
  return new Promise(resolve => gtag('get', APP, 'client_id', resolve));
}

export function onMarketingCookiesEnabled() {
  marketingEnabled = true;

  gtag('js', new Date());
  gtag('config', APP);

  if ((window as any).dataLayer) {
    pendingQueue.forEach(item => (window as any).dataLayer.push(item));
  }
}
