import type {SVGProps} from 'react';
const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    fillRule="evenodd"
    {...props}
  >
    <path d="M2.222 10.878a1.1 1.1 0 0 0 1.556 0L8 6.656l4.222 4.222a1.1 1.1 0 1 0 1.556-1.556l-5-5a1.1 1.1 0 0 0-1.556 0l-5 5a1.1 1.1 0 0 0 0 1.556" />
  </svg>
);
export default SvgChevronUp;
