import React, {useState, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Input, RowActions} from '../../components/bootstrap';
import {useCardOrganizations} from '../../components/inputs/OrganizationInput';
import Table from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Logout} from '../../components/ui-lib/icons/medium';
import {Bin} from '../../components/ui-lib/icons/small';
import {useModals} from '../../modals/ModalContext';
import {IActivation, IOrganizationActivationCode} from '../../models/ActivationCode';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable, ICardWithOrganization} from '../../models/CardSettings';
import {IOrganization} from '../../models/Organization';
import {ITableField, TimestampField, ComponentField, CheckboxField, CalculatedStringField} from '../../models/Table';
import {hasPartnerAdminFunctionality} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useActivationCodeHistory, useOrganizationActivationCodes} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {CardActions} from '../components';

import {Reload, ExportCsv} from '../components/actions';
import {ActivationCodeSelector} from '../components/actions/ActivationCodeSelector';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import MoveToOrganizationModal from '../LocationConfiguration/fields/MoveToOrganizationModal';
import {getErrorForAssignmentResponse} from '../Shipment/ErrorHandling';

interface IDeviceManagementSettings extends ICardSettingsWithTable, ICardWithOrganization {
  activationCodeId?: number;
}

interface ISelectedSerial {
  code: string;
  serial: string;
}

const rowKey = (item: IActivation) => `${item.serialNumber}#${item.timestamp}`;

const DeviceManagement = (props: ICardProps<IDeviceManagementSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const modals = useModals();
  const {api} = useAppContext();
  const {activationCodeId} = settings;
  const [query, setQuery] = useState('');

  const [inputOrganizations, updateOrganizationInputQuery, organization] = useCardOrganizations(fetch, settings);

  const organizationId = organization?.id;
  const [activationCodes, refreshActivationCodes] = useOrganizationActivationCodes(fetch, organizationId);
  const activationCode = activationCodes.find(code => code.id === activationCodeId) || activationCodes[0];

  const [items, refreshItems] = useActivationCodeHistory(fetch, activationCode && activationCode.code);

  const [selected, setSelected] = useState<ISelectedSerial[]>([]);
  const [tableKey, setTableKey] = useState(1);

  const filteredItems = useMemo(() => {
    if (query === '' || items === null) return items;

    return items.filter(
      item =>
        item.serialNumber.includes(query) ||
        (item.stationSerialNumber !== undefined && item.stationSerialNumber.includes(query))
    );
  }, [items, query]);

  const handleClickedRefresh = () => {
    refreshItems();
    refreshActivationCodes();
  };

  const handleChangeOrganization = (organization?: IOrganization) => {
    updateSettings({organization: organization?.id});
  };

  const handleChangeCode = (activationCode: IOrganizationActivationCode) => {
    updateSettings({activationCodeId: activationCode.id});
  };

  const handleMoveSelected = async () => {
    if (!activationCode || !organization) return;
    if (selected.some(device => device.code !== activationCode.code)) {
      NotificationManager.error('All devices must belong to the same activation code');
      return;
    }

    const result = await modals.show<IOrganizationActivationCode | undefined>(props => (
      <MoveToOrganizationModal
        activationCode={activationCode}
        message={T('deviceManagement.move.message', {
          serialNumbers: selected.map(item => item.serial).join(', ')
        })}
        {...props}
      />
    ));
    if (!result) return;

    const fromActivationCode = activationCode;
    const toActivationCode = result;

    const moveResponse = await api.activationCodes.move(
      fromActivationCode.code,
      toActivationCode.code,
      selected.map(item => item.serial)
    );
    const error = getErrorForAssignmentResponse(moveResponse);
    if (error === undefined) {
      setSelected([]);
      refreshItems();
    } else {
      NotificationManager.error(error);
    }
  };

  const handleClearSelection = () => {
    setSelected([]);
    setTableKey(tableKey + 1);
  };

  const tableColumns: ITableField<IActivation>[] = useMemo(() => {
    const isChecked = (row: IActivation) => {
      return selected.some(item => item.serial === row.serialNumber);
    };

    const setChecked = (row: IActivation, checked: boolean) => {
      const currentlyChecked = isChecked(row);
      if (checked === currentlyChecked) return;

      const selectedFiltered = selected.filter(item => item.serial !== row.serialNumber);
      if (checked) {
        if (!activationCode) return;

        selectedFiltered.push({
          code: activationCode.code,
          serial: row.serialNumber
        });
      }
      setSelected(selectedFiltered);
    };

    const handleClickedMove = async (item: IActivation) => {
      if (!activationCode || !organization) return;

      const fullActivationCode = {
        ...activationCode,
        organizationId: organization.parentId || organization.id,
        organization
      };
      const result = await modals.show<IOrganizationActivationCode | undefined>(props => (
        <MoveToOrganizationModal
          activationCode={fullActivationCode}
          message={T('deviceManagement.move.message', {
            serialNumbers: item.serialNumber
          })}
          {...props}
        />
      ));

      if (!result) return;

      const fromActivationCode = activationCode;
      const toActivationCode = result;

      const moveResponse = await api.activationCodes.move(fromActivationCode.code, toActivationCode.code, [
        item.serialNumber
      ]);
      const error = getErrorForAssignmentResponse(moveResponse);
      if (error === undefined) {
        refreshItems();
      } else {
        NotificationManager.error(error);
      }
    };

    return [
      new CheckboxField('selected', '', isChecked, setChecked, {
        autoInsert: 'start',
        width: 20
      }),
      new TimestampField('timestamp', T('shipment.field.timestamp')),
      new CalculatedStringField('serialNumber', T('shipment.field.serialNumber'), item => {
        let result = item.serialNumber;
        if (item.stationSerialNumber) {
          result += ` (${item.stationSerialNumber})`;
        }
        return result;
      }),
      new ComponentField(
        'actions',
        T('shipment.field.actions'),
        item => {
          return (
            <RowActions>
              <Button
                variant="ghost_action_btn"
                size="icon"
                onClick={() => handleClickedMove(item)}
                title={T('deviceManagement.actions.move')}
              >
                <Logout width={16} height={16} />
              </Button>
            </RowActions>
          );
        },
        {autoInsert: 'end'}
      )
    ];
  }, [modals, selected, activationCode, refreshItems, api, organization]);

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={handleClickedRefresh} />
      {state.ready && <ExportCsv fields={tableColumns} settings={settings.table} items={items || []} name="devices" />}
      <ActivationCodeSelector
        organizations={inputOrganizations.organizations}
        activationCodes={activationCodes}
        organization={organization}
        activationCode={activationCode}
        searchable={true}
        onChangeOrganization={handleChangeOrganization}
        onChangeCode={handleChangeCode}
        updateQuery={updateOrganizationInputQuery}
      />
      <Input
        name="query"
        type="text"
        style={{
          width: 150,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 8,
          paddingBottom: 8,
          lineHeight: 22,
          height: 40,
          maxHeight: 40,
          margin: 0
        }}
        placeholder={T('deviceManagement.search')}
        onChange={event => setQuery(event.currentTarget.value)}
        value={query}
        className={testingClasses.search}
        data-testid={testingClasses.search}
      />
      <Spring />
      {selected.length > 0 && (
        <>
          <Button
            variant="secondary_default"
            size="lg"
            title={T('shipment.actions.clear')}
            onClick={handleClearSelection}
          >
            <Bin width={16} height={16} />
          </Button>
          <Button
            variant="secondary_default"
            size="lg"
            title={T('deviceManagement.actions.move')}
            onClick={handleMoveSelected}
          >
            <Logout width={16} height={16} />
          </Button>
        </>
      )}
    </CardActions>
  );

  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      <Table
        fields={tableColumns}
        items={filteredItems || None}
        rowKey={rowKey}
        noun="deviceActivation"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        key={tableKey}
        emptyMessage={T('shipment.noDevices')}
        selected={selected.length}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IDeviceManagementSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'serialNumber', visible: true}
    ]
  }
};
const CARD: ICardType<IDeviceManagementSettings> = {
  type: CardTypeKey.DeviceManagement,
  title: 'deviceManagement.title',
  description: 'deviceManagement.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.User,
  isAvailable: hasPartnerAdminFunctionality,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: DeviceManagement
};
export default CARD;
