import Popper from 'popper.js';
import React from 'react';
import {Popover as RsPopover} from 'reactstrap';
import {v1 as uuidv1} from 'uuid';

import styles from './Popover.module.scss';

interface PopoverProps {
  isOpen: boolean;
  toggle?: () => void;
  id?: string;
  className?: string;
  placement?: Popper.Placement;
  target: string;
  fade?: boolean;
  delay?: number;
}

export class Popover extends React.Component<PopoverProps, {}> {
  id: string = `p${uuidv1()}`;

  componentDidMount() {
    if (this.props.isOpen) {
      window.document.addEventListener('click', this.windowClickListener);
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.windowClickListener);
  }

  componentDidUpdate(lastProps: PopoverProps) {
    if (lastProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        window.document.addEventListener('click', this.windowClickListener);
      } else {
        window.document.removeEventListener('click', this.windowClickListener);
      }
    }
  }

  windowClickListener = (ev: MouseEvent) => {
    if (this.isPopover(ev.target as HTMLElement | null)) return;

    if (this.props.toggle) this.props.toggle();
  };

  isPopover(target: HTMLElement | null): boolean {
    if (!target) return false;

    const popoverId = this.props.id || this.id;
    const targetElement = target as HTMLElement;
    if (targetElement.id === this.id || targetElement.id === popoverId) {
      return true;
    }

    return this.isPopover(targetElement.parentElement);
  }

  render() {
    let {className, ...props} = this.props;

    // Hook extra classes into the component
    className = [styles.popover, className ? className : ''].join(' ');

    return (
      <RsPopover id={props.id || this.id} className={className} {...props}>
        {this.props.children}
      </RsPopover>
    );
  }
}
