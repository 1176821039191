import React, {useState} from 'react';

import {Col} from 'reactstrap';

import {SingleActionModal, FormGroup, Input, Button} from '../../../components/bootstrap';
import {Icon} from '../../../components/Icon';
import {Checkbox} from '../../../components/ui/checkbox';
import {Hotkeys} from '../../../utils/Keys';

interface EditPossibleValueProps {
  isOpen: boolean;
  onToggle: () => void;

  exhaustive: boolean;
  values: string[] | undefined;
  onChange: (values: string[] | undefined, exhaustive: boolean) => void;
}
export const EditPossibleValuesModal = (props: EditPossibleValueProps) => {
  const {isOpen, onToggle, onChange} = props;

  const [wasOpen, setWasOpen] = useState(props.isOpen);
  const [enabled, setEnabled] = useState(props.values !== undefined);
  const [exhaustive, setExhaustive] = useState(props.exhaustive);
  const [addingValue, setAddingValue] = useState('');
  const [values, setValues] = useState(props.values || []);
  if (isOpen !== wasOpen) {
    if (isOpen && !wasOpen) {
      setEnabled(props.values !== undefined);
      setExhaustive(props.exhaustive);
      setValues(props.values || []);
    }
    setWasOpen(isOpen);
  }

  const handleClickedSave = async () => onChange(enabled ? values : undefined, exhaustive);
  const handleClickedAdd = () => {
    const newValues = addingValue.split(';').map(value => value.trim());
    setValues([...values, ...newValues]);
    setAddingValue('');
  };
  const handleClickedRemove = (index: number) => {
    setValues(values.filter((v, i) => i !== index));
  };
  const handleKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Hotkeys.ENTER) handleClickedAdd();
  };

  const valueList = values.map((value, index) => (
    <li key={index}>
      {value}
      {}
      <i
        className={Icon.RegularTimesCircle}
        style={{cursor: 'pointer', marginLeft: 10}}
        onClick={() => handleClickedRemove(index)}
      />
    </li>
  ));

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={onToggle}
      title="Edit possible values"
      actionText="OK"
      action={handleClickedSave}
      cancelText="Cancel"
    >
      <Checkbox
        id="possible-values-enabled"
        name="possible-values-enabled"
        checked={enabled}
        onCheckedChange={setEnabled}
        label="Set a list of possible values"
        className="tw-mt-2.5"
        testId="possible-values-enabled"
      />
      <Checkbox
        id="possible-values-exhaustive"
        name="possible-values-exhaustive"
        checked={enabled}
        onCheckedChange={setExhaustive}
        disabled={!enabled}
        label="Exhaustive"
        className="tw-mt-1.25"
        testId="possible-values-exhaustive"
      />
      {enabled && (
        <>
          <p style={{marginBottom: 0, marginTop: 10}}>Values:</p>
          <ul>{valueList}</ul>
        </>
      )}
      {enabled && (
        <FormGroup row>
          <Col md={9}>
            <Input
              type="text"
              value={addingValue}
              onChange={e => setAddingValue(e.currentTarget.value)}
              onKeyPress={handleKeyPressed}
            />
            <span style={{color: 'gray'}}>Add multiple values by separating them with a semicolon (;)</span>
          </Col>
          <Col md={3}>
            <Button onClick={handleClickedAdd}>Add</Button>
          </Col>
        </FormGroup>
      )}
    </SingleActionModal>
  );
};
