import React from 'react';
import {Dropdown as RsDropdown, DropdownProps} from 'reactstrap';

import {classes} from '../../utils/Styles';

import styles from './Dropdown.module.scss';

interface IDropdownProps extends DropdownProps {
  className?: string;
}

export const Dropdown = React.memo((props: IDropdownProps) => {
  const {className, children, ...otherProps} = props;

  return (
    <RsDropdown className={classes(styles['dash-dropdown'], className)} {...otherProps}>
      {props.children}
    </RsDropdown>
  );
});
