import {AnyAppAction} from '../actions';
import {UIStateActions} from '../actions/uiState';
import {IUIState} from '../AppState';

const defaultState: IUIState = {
  presenting: false,
  initialNow: Date.now(),
  organizing: false,
  inMaintenance: false,
  organizingState: {},
  organizingLastSaved: null,
  menuCollapsed: false,
  availableLocales: ['en']
};

export default function update(state: IUIState = defaultState, action: AnyAppAction): IUIState {
  switch (action.type) {
    case UIStateActions.UPDATE: {
      const updates = action.payload;
      return {...state, ...updates};
    }
    default:
      return state;
  }
}
