import React from 'react';
import {Form} from 'reactstrap';

import {Checkbox} from '../../components/ui/checkbox';
import {ChargingStationPaymentTypeFilter} from '../../models/ChargingStation';
import {T} from '../../utils/Internationalization';
import {FilterPanelButton} from '../components/actions/FilterPanel';

interface SessionFilterProps {
  paymentTypes: ChargingStationPaymentTypeFilter[];
  onChangePaymentType: (type: ChargingStationPaymentTypeFilter, checked: boolean) => void;
}
export function SessionFilter(props: SessionFilterProps) {
  const {paymentTypes, onChangePaymentType} = props;

  return (
    <FilterPanelButton hasFilter={paymentTypes.length > 0}>
      <Form>
        <Checkbox
          id="filter-paymentmethod-free"
          name="filter-paymentmethod-free"
          label={T('paymentMethod.free')}
          checked={paymentTypes.includes(ChargingStationPaymentTypeFilter.Free)}
          onCheckedChange={checked => onChangePaymentType(ChargingStationPaymentTypeFilter.Free, checked)}
          testId="filter-paymentmethod-free"
        />
        <Checkbox
          id="filter-paymentmethod-app"
          name="filter-paymentmethod-app"
          label={T('paymentMethod.app')}
          checked={paymentTypes.includes(ChargingStationPaymentTypeFilter.App)}
          onCheckedChange={checked => onChangePaymentType(ChargingStationPaymentTypeFilter.App, checked)}
          testId="filter-paymentmethod-app"
        />
        <Checkbox
          id="filter-paymentmethod-rfid"
          name="filter-paymentmethod-rfid"
          label={T('paymentMethod.rfid.any')}
          checked={paymentTypes.includes(ChargingStationPaymentTypeFilter.RFID)}
          onCheckedChange={checked => onChangePaymentType(ChargingStationPaymentTypeFilter.RFID, checked)}
          testId="filter-paymentmethod-rfid"
        />
        <Checkbox
          id="filter-paymentmethod-rfid-whitelist"
          name="filter-paymentmethod-rfid-whitelist"
          label={T('paymentMethod.rfid.whitelist')}
          checked={paymentTypes.includes(ChargingStationPaymentTypeFilter.RFIDWhitelist)}
          onCheckedChange={checked => onChangePaymentType(ChargingStationPaymentTypeFilter.RFIDWhitelist, checked)}
          testId="filter-paymentmethod-rfid-whitelist"
        />
        <Checkbox
          id="filter-paymentmethod-rfid-smappee"
          name="filter-paymentmethod-rfid-smappee"
          label={T('paymentMethod.rfid.public.smappee')}
          checked={paymentTypes.includes(ChargingStationPaymentTypeFilter.RFIDSmappee)}
          onCheckedChange={checked => onChangePaymentType(ChargingStationPaymentTypeFilter.RFIDSmappee, checked)}
          testId="filter-paymentmethod-rfid-smappee"
        />
        <Checkbox
          id="filter-paymentmethod-rfid-external"
          name="filter-paymentmethod-rfid-external"
          label={T('paymentMethod.rfid.public.other')}
          checked={paymentTypes.includes(ChargingStationPaymentTypeFilter.RFIDExternal)}
          onCheckedChange={checked => onChangePaymentType(ChargingStationPaymentTypeFilter.RFIDExternal, checked)}
          testId="filter-paymentmethod-rfid-external"
        />
        <Checkbox
          id="filter-paymentmethod-rfid-splitbilling"
          name="filter-paymentmethod-rfid-splitbilling"
          label={T('paymentMethod.rfid.splitBilling')}
          checked={paymentTypes.includes(ChargingStationPaymentTypeFilter.RFIDSplitBilling)}
          onCheckedChange={checked => onChangePaymentType(ChargingStationPaymentTypeFilter.RFIDSplitBilling, checked)}
          testId="filter-paymentmethod-rfid-splitbilling"
        />
      </Form>
    </FilterPanelButton>
  );
}
