export interface IPreconfigurationKit {
  maximumCurrent: number;
  evBox: IEVBoxProperties;
}

export interface IEVBoxProperties {
  kit: IEVBoxKit;
  gatewaySerialNumber: string;
  wifiConnectMacAddress?: string;
  powerBoxSerialNumber?: string;
  gridClosedCTSerialNumber?: string;
  carChargerClosedCTSerialNumber?: string;
  ctHubSerialNumber?: string;
  maximumCurrent: number;
  minimumCurrent: number;
}

export const enum EVBoxKitType {
  SinglePhase = 'SINGLE_PHASE',
  SinglePhaseSolar = 'SINGLE_PHASE_SOLAR',
  ThreePhase = 'THREE_PHASE',
  ThreePhaseSolar = 'THREE_PHASE_SOLAR',
  ThreePhaseDelta = 'THREE_PHASE_DELTA'
}

export const enum KitCTType {
  CT50_100_200 = 'CT50_100_200',
  CT400_800 = 'CT400_800',
  SCT2_50A = 'SCT2_50A',
  SCT2_100A = 'SCT2_100A',
  SCT2_200A = 'SCT2_200A',
  SCT2_400A = 'SCT2_400A',
  SCT3_50A = 'SCT3_50A',
  SCT3_100A = 'SCT3_100A',
  SCT3_200A = 'SCT3_200A',
  ROG_RSCT_400 = 'ROG_RSCT_400',
  ROG_RSCT_600 = 'ROG_RSCT_600'
}

export interface IEVBoxKit {
  type: EVBoxKitType;
  id: string;
  gridCTType?: KitCTType;
}
