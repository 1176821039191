import React from 'react';

import {Button} from '../../components/ui/button';
import {cn} from '../../lib/utils';
import {T} from '../../utils/Internationalization';

import styles from './index.module.scss';

interface UpgradeButtonProps {
  isUpgrading: boolean;
  onClick: () => void;
}
export const UpgradeButton = (props: UpgradeButtonProps) => {
  const {isUpgrading, onClick} = props;
  return (
    <Button
      variant="secondary_default"
      size="md"
      title={isUpgrading ? T('firmware.upgradeRequested') : T('firmware.upgradeNow')}
      className={cn(styles.actionsRestart, 'tw-flex tw-items-center')}
      onClick={onClick}
      disabled={isUpgrading}
    >
      {isUpgrading ? T('firmware.upgradeRequested') : T('firmware.upgradeNow')}
    </Button>
  );
};
