import React from 'react';
import {ModalBody as RsModalBody, ModalBodyProps} from 'reactstrap';

import './ModalBody.scss';

export class ModalBody extends React.Component<ModalBodyProps> {
  render() {
    let {className, ...props} = this.props;

    // Hook extra classes into the component
    className = ['dash-modal-body', className ? className : ''].join(' ');

    return (
      <RsModalBody className={className} {...props}>
        {this.props.children}
      </RsModalBody>
    );
  }
}
