import {Form} from 'reactstrap';

import {FormContextProvider, useFormContext} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';
import {validateRequired} from '../../../utils/Validation';
import {TextInputGroup} from '../../inputs/TextInput';

import {usePricingGroup} from './PricingGroupProvider';

function BasicGroupConfig() {
  const form = useFormContext();

  const {pricingGroup, onChange} = usePricingGroup();

  return (
    <Form>
      <FormContextProvider value={form}>
        <h2 className="m-0 p-0 pb-3 font-bold">{T('pricingGroups.add.basic.title')}</h2>
        <TextInputGroup
          form={form}
          name="name"
          label={T('pricingGroups.add.basic.name')}
          value={pricingGroup?.name ?? ''}
          onChange={name => onChange({name})}
          validate={validateRequired}
          info={T('pricingGroups.add.basic.name.description')}
        />

        <input type="hidden" name="type" value={pricingGroup?.type} />
        {/* TODO: When more groups are added replace the above with the below */}
        {/* <SelectInputGroup
          name="type"
          label={T('pricingGroups.add.basic.type')}
          value={pricingGroup?.type ?? ''}
          onChange={type => onChange({type: type as typeof pricingGroup['type']})}
        >
          <option value="CUSTOM">{T('pricingGroups.types.custom')}</option>
        </SelectInputGroup> */}
      </FormContextProvider>
    </Form>
  );
}

export {BasicGroupConfig};
