import React, {useCallback} from 'react';

import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {T} from '../../../utils/Internationalization';

interface ValueEditorProps {
  readOnly: boolean;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  loading: boolean;
  children: React.ReactNode;
  onClickedSave: () => void;
}
export default (props: ValueEditorProps) => {
  const {readOnly, editing, setEditing, loading, children, onClickedSave} = props;

  const handleClickedEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleClickedCancel = useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  if (editing) {
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {children}
        <Button variant="primary_default" onClick={onClickedSave} style={{marginLeft: '1.5rem'}}>
          {T('locationConfiguration.field.save')}
          {loading && <i style={{marginLeft: '0.3em'}} className="fas fa-circle-notch fa-spin" />}
        </Button>
        <Button variant="secondary_default" onClick={handleClickedCancel} style={{marginLeft: '0.75rem'}}>
          {T('locationConfiguration.field.cancelEdit')}
        </Button>
      </div>
    );
  } else {
    return (
      <span>
        {children}{' '}
        {!readOnly && (
          <Button variant="ghost_action_btn" size="icon" onClick={handleClickedEdit}>
            <Edit />
          </Button>
        )}
      </span>
    );
  }
};
