// @todo: full implementation

import {useCallback, useEffect, useRef, useState} from 'react';

import {useAppContext} from '../app/context';
import {useUser} from '../cards/CardUtils';
import {BessStatus} from '../models/BessStatus';
import {BessUnit} from '../models/BessUnit';

import {IBessPowerMessage} from './LiveDataModels';
import {MqttSubscription} from './MqttConnector';

export function useLiveBessPower(
  bessUuid: string | undefined,
  bessUnit: BessUnit | undefined
): [IBessPowerMessage | undefined, BessStatus | undefined] {
  const {mqtt, api} = useAppContext();
  const serialNumber = bessUnit?.data?.serialNumber;
  const me = useUser();

  const [lastMessage, setLastMessage] = useState<IBessPowerMessage>();
  const [offline, setOffline] = useState<boolean>(false);
  const [unavailable, setUnavailable] = useState<boolean>(false);
  const subscription = useRef<MqttSubscription | undefined>();

  // clear last message after 65s
  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (lastMessage !== undefined) setOffline(false);

    timeout.current = setTimeout(() => {
      setLastMessage(undefined);
      setOffline(true);
    }, 65000);
    return () => timeout.current && clearTimeout(timeout.current);
  }, [lastMessage]);
  useEffect(() => setOffline(false), [serialNumber]);

  const setSubscription = useCallback(
    (value: MqttSubscription | undefined) => {
      if (subscription.current) subscription.current.close();

      subscription.current = value;
    },
    [subscription]
  );

  useEffect(() => {
    const disconnect = () => {
      setSubscription(undefined);
      setLastMessage(undefined);
    };

    disconnect();

    if (!serialNumber || !bessUuid) {
      setUnavailable(true);
      return;
    }

    setUnavailable(false);

    mqtt.subscribe(
      me.userId.toString(),
      bessUuid,
      bessUuid,
      `servicelocation/${bessUuid}/bess/actuals`,
      undefined,
      false,
      setSubscription,
      message => setLastMessage(message)
    );

    return disconnect;
  }, [api, mqtt, bessUuid, serialNumber, setSubscription, setLastMessage, me.userId]);

  return [lastMessage, getBessStatus(lastMessage, offline, unavailable)];
}

export function getBessStatus<T>(message: T | undefined, offline: boolean, unavailable: boolean) {
  if (unavailable) return BessStatus.Unavailable;
  else if (offline) return BessStatus.Offline;
  else if (message === undefined) return BessStatus.Loading;
  else return BessStatus.Active;
}
