import React from 'react';

interface CollapseCaretProps {
  expanded: boolean;
  onClicked: () => void;
}
export const CollapseCaret = (props: CollapseCaretProps) => {
  const {expanded, onClicked} = props;
  const icon = expanded ? 'fa-caret-down' : 'fa-caret-right';
  return <span style={{cursor: 'pointer', padding: 4}} onClick={onClicked} className={`fal ${icon}`} />;
};
