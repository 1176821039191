import React from 'react';

import {ILocation, ILocationSearchResult} from '../../models/Location';
import {T} from '../../utils/Internationalization';
import {Button as RsButton, FormFeedback, FormGroup, Label} from '../bootstrap';

import LocationInput from './LocationInput';

interface LocationInputGroupProps {
  label: string | JSX.Element;
  location?: ILocation;
  locationId?: number;
  onSelected: (locationId: number) => Promise<unknown>;
  onClear?: () => void;
  error?: string;
  allowChargingStations?: boolean;
  filter?: (location: ILocationSearchResult) => boolean;
}

export default function LocationInputGroup(props: LocationInputGroupProps) {
  const {label, location, locationId, onSelected, onClear, error, allowChargingStations = false, filter} = props;

  return (
    <FormGroup>
      <div style={{display: 'flex'}}>
        <Label size="lg">{label}</Label>
        <div style={{flex: 1}} />
        {onClear && (
          // <Button color="link" /* disabled={location === undefined} */ onClick={onClear} withoutPadding>
          <RsButton color="link" onClick={onClear} withoutPadding disabled={locationId === undefined}>
            ({T('card.settings.location.clear')})
          </RsButton>
        )}
      </div>

      <LocationInput
        id="changeParent"
        inputId="changeParentInput"
        maxDropdownHeight={500}
        location={location}
        onLocationSelected={onSelected}
        allowChargingStations={allowChargingStations}
        invalid={error !== undefined}
        filter={filter}
      />
      {error && (
        <FormFeedback valid={false} style={{display: 'block'}}>
          {error}
        </FormFeedback>
      )}
    </FormGroup>
  );
}
