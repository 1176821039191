import React, {useEffect, useState} from 'react';
import {TreeItem} from 'react-sortable-tree';

import {NumberValue} from '../../components/inputs/NumberInput';
import {Button} from '../../components/ui/button';
import {Input} from '../../components/ui/input';
import {Bin, Plus} from '../../components/ui-lib/icons/small';
import {IChargingStation} from '../../models/ChargingStation';
import {getLoadName, ILoad, LoadType} from '../../models/Load';
import {None} from '../../utils/Arrays';

import {LoadWithStation} from './LoadWithStation';
import styles from './OverloadProtection.module.scss';

export interface LoadTreeItem extends TreeItem {
  load: ILoad & {chargingStation?: IChargingStation};
}

export function buildTree(
  loads: ILoad[],
  collapsed: number[],
  onCurrentChanged: (load: ILoad, current: number | null) => void,
  onClickedAdd: (load: ILoad) => void,
  onClickedRemove: (load: ILoad) => void
): LoadTreeItem[] {
  const virtualGrid = loads.find(load => load.type === LoadType.VirtualGrid);
  const grids = loads
    .filter(load => load.type === LoadType.Grid)
    .map(grid => buildTreeNode(grid, loads, collapsed, onCurrentChanged, onClickedAdd, onClickedRemove));

  if (virtualGrid) {
    return [buildVirtualGridTreeNode(virtualGrid, grids, collapsed, onCurrentChanged)];
  } else {
    return grids;
  }
}

function buildVirtualGridTreeNode(
  virtualGrid: ILoad,
  grids: LoadTreeItem[],
  collapsed: number[],
  onCurrentChanged: (load: ILoad, current: number | null) => void
): LoadTreeItem {
  return {
    title: (
      <TreeNode
        load={virtualGrid}
        onCurrentChanged={current => onCurrentChanged(virtualGrid, current)}
        onClickedAdd={() => {}}
        onClickedRemove={() => {}}
      />
    ),
    load: virtualGrid,
    children: grids,
    expanded: !collapsed.includes(virtualGrid.id)
  };
}

function buildTreeNode(
  load: ILoad,
  loads: ILoad[],
  collapsed: number[],
  onCurrentChanged: (load: ILoad, current: number | null) => void,
  onClickedAdd: (load: ILoad) => void,
  onClickedRemove: (load: ILoad) => void
): LoadTreeItem {
  const children = loads
    .filter(l => l.parentId === load.id)
    .map(l => buildTreeNode(l, loads, collapsed, onCurrentChanged, onClickedAdd, onClickedRemove));

  return {
    title: (
      <TreeNode
        load={load}
        onCurrentChanged={current => onCurrentChanged(load, current)}
        onClickedAdd={() => onClickedAdd(load)}
        onClickedRemove={() => onClickedRemove(load)}
      />
    ),
    load,
    children,
    expanded: !collapsed.includes(load.id)
  };
}

export function decomposeTree(nodes: LoadTreeItem[], original: ILoad[]): ILoad[] {
  const result: ILoad[] = [];
  nodes.forEach(node => decomposeTreeNode(result, node, undefined));
  // return nodes in original order. we can't rearrange the order
  return original.map(load => result.find(l => l.id === load.id) || load);
}

function decomposeTreeNode(result: ILoad[], node: LoadTreeItem, parentId: number | undefined) {
  if (node.load.parentId !== parentId) {
    result.push({...node.load, parentId});
  } else {
    result.push(node.load);
  }

  for (let child of (node.children || None) as LoadTreeItem[]) {
    decomposeTreeNode(result, child, node.load.id);
  }
}

interface TreeNodeProps {
  load: LoadWithStation;
  onCurrentChanged: (current: number | null) => void;
  onClickedAdd: () => void;
  onClickedRemove: () => void;
}

function TreeNode(props: TreeNodeProps) {
  const {load, onCurrentChanged, onClickedAdd, onClickedRemove} = props;
  const [value, setValue] = useState<NumberValue>(NumberValue.create(load.overloadMaxAmpere));
  useEffect(() => setValue(NumberValue.create(load.overloadMaxAmpere)), [load.overloadMaxAmpere]);

  const handleCurrentChanged = (inputValue: string) => {
    const newValue = value.withInputValue(inputValue);
    onCurrentChanged(newValue.numberValue);
  };

  return (
    <div className={styles.load}>
      <div className={styles.loadName}>{getLoadName(load)}</div>
      <div className={styles.loadCurrent}>
        {load.chargingStation === undefined && (
          <>
            <Input
              value={value.inputValue}
              onChange={e => handleCurrentChanged(e.currentTarget.value)}
              append="A"
              className="!tw-w-21 !tw-font-normal"
            />
            <Button variant="secondary_icon_btn" size="icon" onClick={onClickedAdd} className="!tw-ml-3">
              <Plus className="tw-h-4 tw-w-4" />
            </Button>
          </>
        )}
        {load.type !== LoadType.Grid && load.type !== LoadType.VirtualGrid && (
          <Button variant="secondary_negative" size="icon" onClick={onClickedRemove}>
            <Bin className="tw-h-4 tw-w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
