import {AppStore} from '..';
import API from '../../core/api';
import {UserPreferenceKey} from '../../models/UserPreference';
import {HourFormat, IAppState, IPreferencesState, OnboardingHint} from '../AppState';

export enum PreferencesActions {
  FETCHING = 'PREFERENCES.FETCHING',
  UPDATE = 'PREFERENCES.UPDATE',
  LOAD = 'PREFERENCES.LOAD'
}

export interface IUpdatePreferencesAction {
  type: PreferencesActions.UPDATE;
  payload: Partial<IPreferencesState>;
}

export interface IFetchingPreferencesAction {
  type: PreferencesActions.FETCHING;
  payload: {};
}

export interface ILoadPreferencesAction {
  type: PreferencesActions.LOAD;
  payload: Partial<IPreferencesState>;
}

export type AnyPreferencesAction = IUpdatePreferencesAction | IFetchingPreferencesAction | ILoadPreferencesAction;

function getPreferencesFromState(state: IAppState) {
  return state.preferences;
}

function setUpdate(updates: Partial<IPreferencesState>, key: string, value: string): void {
  switch (key) {
    case 'batchCounter':
      updates.batchCounter = parseInt(value);
      break;
    case 'decimalSeparator':
      updates.decimalSeparator = value;
      break;
    case 'thousandSeparator':
      updates.thousandSeparator = value;
      break;
    case 'delimiter':
      updates.delimiter = value;
      break;
    case 'locationId':
      updates.locationId = parseInt(value);
      break;
    case 'dashboardDefaultsVersion':
      updates.dashboardDefaultsVersion = parseInt(value);
      break;
    case 'shownHints':
      updates.shownHints = value.split(',').filter(x => x !== '') as OnboardingHint[];
      break;
    case 'demoMode':
      updates.demoMode = value === 'true';
      break;
    case 'navigationWidth':
      updates.navigationWidth = parseInt(value);
      break;
    case UserPreferenceKey.EVBoardAdded:
      updates.evBoardAdded = value === 'true';
      break;
    case UserPreferenceKey.HourFormat:
      updates.hourFormat = value as HourFormat;
      break;
  }
}

export async function fetchPreferences(store: AppStore, api: API): Promise<IPreferencesState> {
  store.dispatch({type: PreferencesActions.FETCHING, payload: {}});

  try {
    const preferences = await api.getUserPreferences();
    const updates: Partial<IPreferencesState> = {};
    preferences.forEach(preference => setUpdate(updates, preference.key, preference.value));

    store.dispatch({
      type: PreferencesActions.LOAD,
      payload: updates
    });
  } catch (err) {
    console.error(err);
  }

  return getPreferencesFromState(store.getState());
}

export async function setPreference<K extends keyof IPreferencesState>(
  context: {api: API; store: AppStore},
  key: K,
  value: string
) {
  await context.api.setUserPreference(key, value);

  const updates: Partial<IPreferencesState> = {};
  setUpdate(updates, key, value);
  context.store.dispatch({
    type: PreferencesActions.UPDATE,
    payload: updates
  });
}

export function updatePreferences(updates: Partial<IPreferencesState>): IUpdatePreferencesAction {
  return {
    type: PreferencesActions.UPDATE,
    payload: updates
  };
}
