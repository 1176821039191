import * as LabelPrimitive from '@radix-ui/react-label';
import {cva, type VariantProps} from 'class-variance-authority';
import * as React from 'react';

import {cn} from '../../lib/utils';

const labelVariants = cva(
  'tw-text-base tw-font-bold tw-mb-0 tw-leading-[136%] peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70'
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({className, ...props}, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export {Label};
