import {useAppContext} from '../../app/context';
import {Form, SingleActionModal} from '../../components/bootstrap';
import {useNumberInput} from '../../components/inputs/NumberInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {SplitBillingAgreement} from '../../models/SplitBillingAgreement';
import {getCurrencySymbol} from '../../utils/Currency';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';

interface UpdateAllAgreementsModalProps extends IPromiseModalProps<SplitBillingAgreement[] | undefined> {
  organizationId: number;
}

const currency = 'EUR'; // TODO: support other currencies
export function UpdateAllAgreementsModal(props: UpdateAllAgreementsModalProps) {
  const {organizationId} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const form = useFormState();

  const [rateInput, rateValue] = useNumberInput(
    'rate',
    T('splitBilling.field.refund.rate'),
    null,
    0.01,
    undefined,
    `${getCurrencySymbol(currency)}/kWh`,
    {info: T('splitBilling.field.refund.rate.info'), as: 'text'}
  );

  const handleSave = async () => {
    if (form.hasErrors()) return;
    if (rateValue === null) return;

    const updated = await api.organizations.updateAllSplitBillingAgreements(organizationId, {
      refund: {
        rate: {
          value: rateValue,
          currency
        }
      }
    });
    if (updated !== undefined) {
      resolve(updated);
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      title={T('splitBilling.updateAll.title')}
      onToggle={() => resolve(undefined)}
      action={handleSave}
      actionText={T('splitBilling.updateAll.save')}
    >
      <p>{T('splitBilling.updateAll.message')}</p>
      <Form>{rateInput}</Form>
    </SingleActionModal>
  );
}
