import dayjs from 'dayjs';

import {Interval} from './UsageValue';

export interface IRetentionPolicy {
  id: number;
  fiveMinuteValues: IRetentionPolicyEntry;
  hourlyValues: IRetentionPolicyEntry;
  dailyValues: IRetentionPolicyEntry;
  monthlyValues: IRetentionPolicyEntry;
  homeControlEvents: IRetentionPolicyEntry;
}

export interface IUpdateRetentionPolicyRequest {
  fiveMinuteValues: IRetentionPolicyEntry;
  hourlyValues: IRetentionPolicyEntry;
  dailyValues: IRetentionPolicyEntry;
  monthlyValues: IRetentionPolicyEntry;
  homeControlEvents: IRetentionPolicyEntry;
}

export interface IRetentionPolicyEntry {
  unlimited: boolean;
  days?: number;
}

function getRetention(interval: Interval, retention: IRetentionPolicy | undefined): IRetentionPolicyEntry {
  if (retention === undefined) return {unlimited: true};

  switch (interval) {
    case Interval.INTERVAL:
    case Interval.MINUTES_5:
    case Interval.X_MINUTES_10:
    case Interval.X_MINUTES_15:
    case Interval.X_MINUTES_20:
    case Interval.X_MINUTES_30:
      return retention.fiveMinuteValues;
    case Interval.HOUR:
      return retention.hourlyValues;
    case Interval.DAY:
    case Interval.WEEK:
      return retention.dailyValues;
    case Interval.MONTH:
      return retention.monthlyValues;
    default:
      return {unlimited: true};
  }
}

export function getLimitsFromRetention(
  interval: Interval,
  retention: IRetentionPolicy | undefined
): {minTime: dayjs.Dayjs | undefined; maxTime: dayjs.Dayjs} {
  const policy = getRetention(interval, retention);
  const maxTime = dayjs().endOf('day').add(1, 'days'); // add 1 day (forecast)
  if (policy.unlimited) return {minTime: undefined, maxTime};

  const minTime = dayjs().startOf('day').add(-policy.days!, 'days');
  return {minTime, maxTime};
}
