export class DataCache<K, T> {
  name: string;
  requests: Map<K, Promise<T>>;
  results: Map<K, T>;
  requester: (id: K) => Promise<T>;

  constructor(name: string, requester: (id: K) => Promise<T>) {
    this.name = name;
    this.requests = new Map<K, Promise<T>>();
    this.results = new Map<K, T>();
    this.requester = requester;
  }

  fetch(id: K, force = false): Promise<T> {
    if (force) this.invalidate(id);

    const result = this.results.get(id);
    if (result) return Promise.resolve(result);

    const promise = this.requests.get(id);
    if (promise) return promise;

    const load = this.requester(id);
    this.requests.set(id, load);
    return load.then(response => {
      this.results.set(id, response);
      this.requests.delete(id);
      return response;
    });
  }

  invalidate(id: K) {
    this.requests.delete(id);
    this.results.delete(id);
  }

  update(id: K, value: T) {
    this.results.set(id, value);
  }
}
