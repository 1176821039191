import React, {useState} from 'react';

import {Modal, ModalBody, ModalFooter, ModalHeader} from '../../components/bootstrap';
import {Button} from '../../components/ui/button';
import {Checkbox} from '../../components/ui/checkbox';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IActivationCode} from '../../models/ActivationCode';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';

export interface IRemoveActivationCodeResult {
  confirmed: boolean;
  withHistory: boolean;
}

interface RemoveActivationCodeModalProps extends IPromiseModalProps<IRemoveActivationCodeResult> {
  code: IActivationCode;
}

export function RemoveActivationCodeModal(props: RemoveActivationCodeModalProps) {
  const {code} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  const codeValue = code.name ? `'${code.name}'` : T('activationCodes.remove.nocode');

  const [withHistory, setWithHistory] = useState(false);
  const handleCancel = () => resolve({confirmed: false, withHistory: false});
  const handleConfirm = () => resolve({confirmed: true, withHistory});

  return (
    <Modal isOpen={isOpen} toggle={handleCancel} size="md" autoFocus={false}>
      <ModalHeader toggle={handleCancel}>{T('activationCodes.remove.title')}</ModalHeader>
      <ModalBody>
        {T('activationCodes.remove.confirmation', {code: codeValue})}
        <Checkbox
          id="remove-history"
          name="remove-history"
          label="Remove history"
          checked={withHistory}
          onCheckedChange={setWithHistory}
          className={testingClasses.forceRemove}
          testId={testingClasses.forceRemove}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          variant="tertiary_negative"
          size="lg"
          title={T('activationCodes.remove.cancel')}
          onClick={handleConfirm}
          className={testingClasses.confirmButton}
          data-testid={testingClasses.confirmButton}
        >
          {T('activationCodes.remove.action')}
        </Button>
        <Button
          variant="primary_default"
          size="lg"
          title={T('activationCodes.remove.cancel')}
          onClick={handleCancel}
          className="t-confirm-reject"
        >
          {T('activationCodes.remove.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
