import React, {useCallback, useMemo, useState} from 'react';

import {CenteredText} from '../../components/CenteredText';
import {SelectInput} from '../../components/inputs/SelectInput';
import PeriodSelector, {
  PeriodSettings,
  Period,
  PeriodRoundingMode,
  usePeriodRangeForTimezone
} from '../../components/PeriodSelector';
import Table, {IPersistedTableSettings, migrateTableSettings, SortOrder} from '../../components/Table';
import {Button} from '../../components/ui/button';
import Clear from '../../components/ui-lib/legacy-icons/Clear';
import Hammer from '../../components/ui-lib/legacy-icons/Hammer';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {IChargingRule} from '../../models/ChargingPriorities';
import {ChargingStationPaymentType, IChargingSession} from '../../models/ChargingStation';
import {isChargingParent} from '../../models/Location';
import {Interval} from '../../models/UsageValue';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {processWhitelist} from '../../utils/DemoMode';
import {
  useChargingStationTransactions,
  useChargingStationForLocation,
  useChargingParkTransactions,
  useChargingStations
} from '../../utils/FunctionalData';
import {useAppSelector, useAutoRefresh, useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';

import {useCardLocation, useCardChargingStationGroup, useCardColumnSettings, useUser} from '../CardUtils';

import {CardActions} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {getTableColumns} from './Columns';

import {FixSessionModal} from './FixSessionModal';
import {FixSessionsModal} from './FixSessionsModal';

interface IChargingTransactionsSettings extends ICardSettings {
  table: IPersistedTableSettings;
  period: PeriodSettings;
  paymentType?: ChargingStationPaymentType;
}

const rowKey = (session: IChargingSession) => session.uuid;

const ChargingTransactions = (props: ICardProps<IChargingTransactionsSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {paymentType} = settings;

  const modals = useModals();
  const location = useCardLocation(settings);
  const stationGroup = useCardChargingStationGroup(settings);
  const stationGroupId = stationGroup && stationGroup.id;
  const demoMode = useAppSelector(state => state.preferences.demoMode);
  const isServiceDesk = useUser().isServiceDesk();

  const [connector, setConnector] = useState<string>('');
  const [rfidFilter, setRFIDFilter] = useState<string>('');
  const [filterStation, setFilterStation] = useState<string>('');
  const [selected, setSelected] = useState<IChargingSession[]>([]);
  const [tableKey, setTableKey] = useState(0);

  const activePeriod = usePeriodRangeForTimezone(
    settings.period,
    location && location.timeZoneId,
    PeriodRoundingMode.INCLUSIVE
  );

  const [whitelist] = useCardLoader<IChargingRule[]>(
    api =>
      stationGroupId
        ? api.getRFIDChargingSettings(stationGroupId).then(whitelist => processWhitelist(whitelist, demoMode))
        : Promise.resolve(undefined),
    [stationGroupId, demoMode],
    plural('privilege'),
    []
  );
  const [chargingStation] = useChargingStationForLocation(fetch, location);
  const [chargingStations, refreshStations] = useChargingStations(fetch, stationGroupId);
  const isChargingPark = location && isChargingParent(location.functionType);
  const serialNumber = chargingStation && chargingStation.data.serialNumber;
  const [stationTransactions, refreshStation] = useChargingStationTransactions(
    fetch,
    serialNumber,
    activePeriod,
    demoMode
  );
  const [parkTransactions, refreshPark] = useChargingParkTransactions(
    fetch,
    isChargingPark ? stationGroupId : undefined,
    activePeriod,
    demoMode
  );

  const refresh = () => {
    refreshStations();
    refreshStation();
    refreshPark();
  };

  const handleClearSelection = useCallback(() => {
    setSelected([]);
    setTableKey(key => key + 1);
  }, []);

  const handleFixSelected = useCallback(() => {
    modals
      .show(props => <FixSessionsModal sessions={selected} {...props} />)
      .then(() => {
        setSelected([]);
        setTableKey(key => key + 1);
        refreshStation();
      });
  }, [modals, refreshStation, selected]);

  useAutoRefresh(refresh);

  const columns = useMemo(() => {
    const handleFixSession = (session: IChargingSession) => {
      modals
        .show(props => <FixSessionModal session={session} {...props} />)
        .then(success => {
          if (success) {
            refreshStation();
            refreshPark();
          }
        });
    };

    const onChecked = (session: IChargingSession, checked: boolean) => {
      setSelected(selected => {
        if (checked) {
          return [...selected, session];
        } else {
          return selected.filter(x => x.id !== session.id);
        }
      });
    };

    return getTableColumns(
      whitelist,
      stationGroupId !== undefined,
      isServiceDesk,
      handleFixSession,
      selected,
      onChecked
    );
  }, [whitelist, stationGroupId, isServiceDesk, modals, refreshStation, refreshPark, selected]);
  const transactions = isChargingPark ? parkTransactions : stationTransactions;

  const filteredTransactions = useMemo(() => {
    let result = transactions;

    if (connector !== '') {
      const position = parseInt(connector);
      result = result.filter(session => session.controller && session.controller.position === position);
    }
    if (filterStation !== '') {
      result = result.filter(session => session.station && session.station.serialNumber === filterStation);
    }
    if (rfidFilter !== '') {
      result = result.filter(session => session.rfid?.toLocaleUpperCase() === rfidFilter.toLocaleUpperCase());
    }
    if (paymentType) {
      result = result.filter(x => x.paymentType === paymentType);
    }
    return result;
  }, [transactions, connector, rfidFilter, filterStation, paymentType]);

  const customSettings = useCardColumnSettings(columns);

  const title = chargingStation
    ? T('chargingStations.sessions.titleOf', {
        name: chargingStation.data.name || ''
      })
    : T('chargingStations.sessions.title');

  const handleChangePaymentType = useCallback(
    (type: string) => {
      const paymentType = type === '' ? undefined : (type as ChargingStationPaymentType);
      updateSettings({paymentType});
    },
    [updateSettings]
  );

  const actions: CustomActions = state => (
    <CardActions justifyContent="end">
      <Reload onReload={refresh} />
      {state.ready && (
        <ExportCsv fields={columns} settings={settings.table} items={filteredTransactions} name={title} />
      )}
      {state.ready && isChargingPark && (
        <SelectInput value={filterStation} onChange={setFilterStation} style={{width: 200}}>
          <option value="">{T('chargingSession.station.any')}</option>
          {chargingStations.map(station => (
            <option key={station.data.serialNumber} value={station.data.serialNumber}>
              {station.data.name}
            </option>
          ))}
        </SelectInput>
      )}
      <SelectInput value={connector} onChange={setConnector} style={{width: 200}}>
        <option value="">{T('chargingSession.side.any')}</option>
        <option value="1">{T('chargingSession.side.right')}</option>
        <option value="2">{T('chargingSession.side.left')}</option>
      </SelectInput>
      <SelectInput value={rfidFilter || ''} onChange={setRFIDFilter}>
        <option value="">{T('chargingSession.rfid.any')}</option>
        {whitelist
          .filter(x => x.rfid !== undefined)
          .map(rule => (
            <option key={rule.rfid!.value || ''} value={rule.rfid!.value}>
              {rule.rfid!.comment || rule.rfid!.value}
            </option>
          ))}
      </SelectInput>
      <PeriodSelector
        settings={settings.period}
        onChanged={period => updateSettings({period})}
        withoutInterval={true}
      />
      <SelectInput value={settings.paymentType || ''} onChange={handleChangePaymentType}>
        <option value="">{T('paymentMethod.any')}</option>
        <option value={ChargingStationPaymentType.Free}>{T('paymentMethod.free')}</option>
        <option value={ChargingStationPaymentType.App}>{T('paymentMethod.app')}</option>
        <option value={ChargingStationPaymentType.Whitelist}>{T('paymentMethod.whitelist')}</option>
        <option value={ChargingStationPaymentType.RFID}>{T('paymentMethod.rfid')}</option>
        <option value={ChargingStationPaymentType.SplitBilling}>{T('paymentMethod.splitBilling')}</option>
        <option value={ChargingStationPaymentType.PaymentTerminal}>{T('paymentMethod.paymentTerminal')}</option>
      </SelectInput>
      {/* <Spring /> */}
      {selected.length > 0 && (
        <>
          <Button
            variant="secondary_default"
            title={T('shipment.actions.clear')}
            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-11 tw-h-8 tw-mx-1.5"
            onClick={handleClearSelection}
          >
            <Clear width={16} height={16} />
          </Button>
          <Button
            variant="secondary_default"
            title={T('shipment.actions.move')}
            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-11 tw-h-8 tw-ml-1.5 tw-mr-3"
            onClick={handleFixSelected}
          >
            <Hammer width={16} height={16} />
          </Button>
        </>
      )}
    </CardActions>
  );

  return (
    <CardView customSettings={customSettings} title={title} actions={actions} {...cardViewProps(props)}>
      {location ? (
        <Table
          key={tableKey}
          style={{flexGrow: 1}}
          items={filteredTransactions}
          fields={columns}
          rowKey={rowKey}
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
          noun="chargingSession"
          emptyMessage={T('chargingTransactions.none')}
          selected={selected.length}
        />
      ) : (
        <CenteredText>{T('chargingStations.pleaseSelect')}</CenteredText>
      )}
    </CardView>
  );
};

const defaultTableSettings: IPersistedTableSettings = {
  pageSize: 20,
  sortColumn: 'from',
  sortOrder: SortOrder.DESCENDING,
  columns: [
    {name: 'stationSerial', visible: false},
    {name: 'stationName', visible: true},
    {name: 'from', visible: true},
    {name: 'to', visible: true},
    {name: 'duration', visible: true},
    {name: 'energy', visible: true},
    {name: 'paymentType', visible: true},
    {name: 'cost', visible: true},
    {name: 'hostCost', visible: true},
    {name: 'commissionFee', visible: true},
    {name: 'side', visible: true},
    {name: 'rfid', visible: true},
    ...(hasFeature(AppFeature.HideUsernames) ? [] : [{name: 'userName', visible: true}]) // TODO: DEPRECATED username field
  ]
};

const CARD: ICardType<IChargingTransactionsSettings> = {
  title: 'chargingTransactions.title',
  description: 'chargingTransactions.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  type: CardTypeKey.ChargingTransactions,
  cardClass: ChargingTransactions,
  width: 4,
  height: 2,
  locationAware: CardLocationAwareness.Required,
  upgradeSettings: migrateTableSettings('table', defaultTableSettings),
  defaultSettings: {
    table: defaultTableSettings,
    period: {
      interval: Interval.DAY, // unused
      period: Period.DAYS_7
    }
  }
};

export default CARD;
