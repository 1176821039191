import React from 'react';
import {Modal as RsModal, ModalProps} from 'reactstrap';

import './Modal.scss';

import {T} from '../../utils/Internationalization';

import {ModalBody} from './ModalBody';
import {ModalFooter} from './ModalFooter';
import {ModalHeader} from './ModalHeader';

export class Modal extends React.Component<ModalProps> {
  static Header = ModalHeader;
  static Body = ModalBody;
  static Footer = ModalFooter;

  static defaultProps = {
    autoFocus: false,
    clickaway: false
  };

  render() {
    let {size, className, contentClassName, loading, clickaway, ...props} = this.props;

    // Hook extra classes into the component
    className = ['dash-modal', size ? size : '', className || ''].join(' ');

    contentClassName = ['dash-content', contentClassName ? contentClassName : ''].join(' ');

    return (
      <RsModal
        size={size}
        className={className}
        contentClassName={contentClassName}
        backdrop={clickaway ? true : 'static'}
        {...props}
      >
        {/* Loading indicator */}
        {loading && (
          <div className="loading">
            <span className="fal fa-sync fa-spin" title={T('modal.loading')} />
          </div>
        )}

        {this.props.children}
      </RsModal>
    );
  }
}
